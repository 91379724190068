import { Text } from '@farmersdog/corgi-x';

import styles from './Steps.module.css';

import type { StepData } from '../hooks/useSteps';

interface StepsProps {
  stepData: StepData;
}

export function Steps(props: StepsProps) {
  const { activeStep, activeStepLabel } = props.stepData;

  return (
    <div className={styles.container}>
      {activeStep && (
        <Text variant="heading-16" color="carrot-2">
          {activeStepLabel}
        </Text>
      )}
    </div>
  );
}
