import { Application, pixelOptions } from './types';

import type { PixelConfig, PixelSettings } from './types';

interface PixelMigratorArgs {
  application: Application;
  config: PixelConfig;
}

export function pixelMigrator({ application, config }: PixelMigratorArgs) {
  const defaultSetting = Boolean(application === Application.Website);

  return pixelOptions.reduce((pixelConfigObject, pixelName) => {
    const currentSetting = config.includes(pixelName)
      ? !defaultSetting
      : defaultSetting;
    return { ...pixelConfigObject, [pixelName]: currentSetting };
  }, {} as PixelSettings);
}
