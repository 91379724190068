import classNames from 'classnames';

import { Text, Picture, useViewport } from '@farmersdog/corgi-x';

import { Quote } from './Quote';
import styles from './SplitSection.module.css';

import type { ColumnCopy } from './data';

interface SplitSectionProps extends ColumnCopy {
  className?: string;
  isInverted: boolean;
}

export const SplitSection = ({
  title,
  body,
  secondParagraph,
  quote,
  author,
  sources,
  className,
  isInverted,
}: SplitSectionProps) => {
  const wrapperClassnames = classNames(styles.section, {
    [styles.inverted]: isInverted,
  });

  const viewport = useViewport();
  const isMobile = !viewport.md;

  const imageAndTextClassnames = classNames(styles.imageAndText, className);

  return (
    <section className={wrapperClassnames}>
      <div className={imageAndTextClassnames}>
        <Picture className={styles.image} sources={sources} alt={body} />
        <div className={styles.card}>
          <Text
            topSpacing="none"
            as="h3"
            bold
            color="charcoal-3"
            variant="heading-28"
            bottomSpacing={{
              xs: 'xs',
              lg: 'sm',
            }}
            align={{
              lg: 'left',
            }}
          >
            {title}
          </Text>
          <Text
            topSpacing="none"
            as="p"
            variant={isMobile ? 'article-16' : 'article-20'}
            align={{
              lg: 'left',
            }}
            bottomSpacing="none"
          >
            {body}
          </Text>
          {secondParagraph && (
            <Text
              topSpacing="md"
              as="p"
              variant={isMobile ? 'article-16' : 'article-20'}
              align={{
                lg: 'left',
              }}
              bottomSpacing="none"
            >
              {secondParagraph}
            </Text>
          )}
        </div>
      </div>
      <div className={styles.quoteBlock}>
        <Quote quote={quote} author={author} />
      </div>
    </section>
  );
};
