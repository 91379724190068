import { getFirstPetFormStep } from './getFirstPetFormStep';

import type { BranchNode } from '../blueprint/types';

interface GetIsFirstPetsPageArgs {
  current: string | undefined;
  formSteps: BranchNode[];
}

export function getIsFirstPetsPage({
  current,
  formSteps,
}: GetIsFirstPetsPageArgs) {
  const firstPetFormStep = getFirstPetFormStep({ formSteps });
  return current && firstPetFormStep?.__self__
    ? firstPetFormStep?.__self__ === current
    : false;
}
