import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from 'src/reducers/signup/user';
import { fetchDiySignupQuote } from 'src/actions/signup';

import useAsyncFunction from 'src/utils/useAsyncFunction';

import { SubscriptionType } from 'src/graphql/types';

import { reporter } from 'src/services/reporter';

interface DiyQuoteSubscriptionArg {
  type: SubscriptionType.Diy;
}

type FetchDiySignupQuoteArgs = Pick<
  Reducer.User,
  'shippingAddress' | 'pets'
> & { subscription: DiyQuoteSubscriptionArg };

export interface SubscriptionProduct {
  productId: number;
  quantity: number;
  variantSku: string;
}

interface DiyRecommendation {
  cadence: number;
  products: SubscriptionProduct[];
}

export interface DiyQuote {
  subscription: {
    recommended: DiyRecommendation;
    accepted: DiyRecommendation;
  };
  price: {
    trial: null;
    regular: {
      netAmount: number;
      baseAmount: 0;
      discountAmount: number;
      taxAmount: number;
      itemsTotalAmount: number;
      shippingAmount: number;
      totalAmount: number;
      weeklyAmount: number;
    };
  };
}

export interface OnCompletedArg {
  data: DiyQuote;
}

function validateZip(zip: Reducer.Address['zip'] | null) {
  return zip?.length === 5 || zip?.length === 9;
}

function onFetchQuoteError(e: Error) {
  reporter.error(e);
}

export function useFetchDiyQuote(
  onCompleted: (response: OnCompletedArg) => void
) {
  const dispatch = useDispatch();
  const { pets, shippingAddress } = useSelector(selectUser);
  const [shouldFetchQuote, setShouldFetchQuote] = useState(true);

  const { zip: shippingZip, state } = shippingAddress;
  const shippingStateId = state?.id;

  const lastValidZip = useRef<string | null>();
  const lastValidStateId = useRef<number | undefined>();

  const fetchQuote = useCallback(
    (quotePayload: FetchDiySignupQuoteArgs) =>
      dispatch(fetchDiySignupQuote(quotePayload)),
    [dispatch]
  );

  const [call, { loading, error }] = useAsyncFunction(fetchQuote, {
    onCompleted,
    onError: onFetchQuoteError,
  });

  useEffect(() => {
    if (!validateZip(shippingZip)) {
      return;
    }

    if (shouldFetchQuote) {
      void call({
        pets,
        shippingAddress,
        subscription: { type: SubscriptionType.Diy },
      });

      lastValidZip.current = shippingZip;
      lastValidStateId.current = shippingStateId;

      setShouldFetchQuote(false);
    }
  }, [
    call,
    pets,
    shippingAddress,
    shouldFetchQuote,
    shippingZip,
    shippingStateId,
  ]);

  useEffect(() => {
    if (!lastValidZip.current) {
      return;
    }

    if (!validateZip(shippingZip)) {
      return;
    }

    if (
      shippingZip === lastValidZip.current &&
      shippingStateId === lastValidStateId.current
    ) {
      return;
    }

    setShouldFetchQuote(true);
  }, [shippingZip, shippingStateId]);

  return {
    loading,
    error,
  };
}
