import { PATH_HOME } from '@farmersdog/constants/paths';

import { getSignupRoute } from '../utils/getSignupRoute';

import { useCachedLead } from './useCachedLead';

import type { BranchNode, NodeRegistry } from '../blueprint/types';
import type {
  UseFormNavigateReturn,
  UseForm,
  RestoreCachedLeadArgs,
} from '../types';
import type { SyntheticEvent } from 'react';

export interface UsePreviousLinkNavigationReturn {
  navigatePreviousLinkLabel: string;
  handleNavigatePrevious: (e: SyntheticEvent) => void;
  previousSignupRoute: string;
}

export interface UsePreviousLinkNavigationArgs extends RestoreCachedLeadArgs {
  formNavigation: UseFormNavigateReturn;
  formSteps: BranchNode[];
  registry: NodeRegistry;
  getValues: UseForm['getValues'];
}

export enum PreviousLinkNavigationLabel {
  homePage = 'The Farmer’s Dog Homepage',
  previousSignupPage = 'the previous signup page',
}

export function usePreviousLinkNavigation({
  formNavigation,
  formSteps,
  registry,
  getValues,
  blueprint,
  reset,
  setFormSteps,
  experiments,
}: UsePreviousLinkNavigationArgs): UsePreviousLinkNavigationReturn {
  const { getIsOnFirstBranch, findPrevious } = formNavigation;
  const restoreCachedLead = useCachedLead();

  const isOnFirstBranch = getIsOnFirstBranch({ formSteps });
  const navigatePreviousLinkLabel = isOnFirstBranch
    ? PreviousLinkNavigationLabel.homePage
    : PreviousLinkNavigationLabel.previousSignupPage;
  const previousBranchNodeId = findPrevious({ formSteps });
  const previousSignupRoute = getSignupRoute({
    registry,
    current: previousBranchNodeId,
    getValues,
  });

  const handleNavigatePrevious = () => {
    formNavigation.setPrevious({
      formSteps,
    });
    restoreCachedLead({ blueprint, reset, setFormSteps, experiments });
  };

  return {
    navigatePreviousLinkLabel: `Return to ${navigatePreviousLinkLabel}`,
    handleNavigatePrevious,
    previousSignupRoute: previousSignupRoute || PATH_HOME,
  };
}
