import type { Request } from 'express';

import type { ReferrerCookie } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { Logger } from '@farmersdog/logger';

import { REFERRAL_COOKIE } from 'src/constants/cookie';
import { getCookiesFromRequest } from 'src/server/utils/getCookiesFromRequest';

export function getReferralCookieFromRequest(
  request: Request
): ReferrerCookie | undefined {
  const cookies = getCookiesFromRequest(request);
  const referralCookie = cookies[REFERRAL_COOKIE] as string | undefined;

  if (referralCookie) {
    try {
      const cookieData = JSON.parse(referralCookie) as ReferrerCookie;
      return cookieData;
    } catch (error) {
      const log = new Logger('app:server:experimentsMiddleware');
      log.error('Failed to parse referral cookie', {
        error,
        anonymousId: request.anonymousId,
        referralCookie,
      });
    }
  }

  return undefined;
}
