import { useEffect } from 'react';

import type { CustomerAccountData } from '../useCustomerAccountQuery';
import { GladlyService } from '../../../cx';

/**
 * Hide Gladly Sidekick from DIY customers.
 */
export function useHideGladlySidekickForDIY(
  customerAccountData:
    | Pick<CustomerAccountData, 'isSubscriptionFresh'>
    | undefined
) {
  useEffect(() => {
    if (!customerAccountData) {
      return;
    }
    if (!customerAccountData.isSubscriptionFresh) {
      GladlyService.hide();
    }
  }, [customerAccountData, customerAccountData?.isSubscriptionFresh]);
}
