import type { TreatSize } from 'src/graphql/tosa.types';
import events from '../eventNames';
import track from '../track';

type TrackSignupFreeTreatsRemoveTreatFromCartArgs = {
  name: string;
  size: TreatSize;
};

/** A Segment tracking event for when a user removes a treat from their cart in the checkout page. */

export function trackSignupFreeTreatsRemoveTreatFromCart({
  name,
  size,
}: TrackSignupFreeTreatsRemoveTreatFromCartArgs) {
  track(events.signup_free_treats_remove_treat_from_cart, {
    name,
    size,
    page: 'checkout',
  });
}
