/* eslint-disable @typescript-eslint/no-shadow */
import { ComponentName } from '../blueprint/types';
import { GetStarted, GetStartedCopyExperiment } from '../components/creative';
import {
  FormWrapper,
  GetStartedWrapper,
  FormWithContinueButton,
  PetFormWrapper,
  ContactInfoForm,
  PetWeightForm,
  RecipesFormWrapper,
  RecipesForm,
  PetCurrentFoodForm,
  Form,
} from '../components/forms';
import { HealthForm } from '../components/forms/HealthForm/HealthForm';
import { FreshFoodConfidenceInput } from '../components/FreshFoodConfidenceInput';
import { PetDetailsImage, NumberOfPetsImage } from '../components/images';
import {
  NumPetInput,
  BodyConditionInput,
  PetNameInput,
  BirthdayUnitInput,
  ActivityLevelInput,
  EatingStyleInput,
  EmailInput,
  BreedsInput,
  PetAgeSkipButton,
  PetHealthCheckboxes,
  PetHealthInput,
  PetWeightInput,
  FoodTypeInput,
  GrainsPreferenceInput,
  TreatsQuantityInput,
  ZipInput,
  FoodBrandInput,
} from '../components/inputs';
import {
  BasicInput,
  DropdownInput,
  CheckboxInput,
  InlineError,
  SpeechBubble,
  ZipEmailErrorWrapper,
} from '../components/inputs/base';
import { MeWhitespaceNode, PetWhitespaceNode } from '../components/layout';
import { RecipesPage } from '../components/RecipesPage';
import { SignupTreatsPage } from '../components/SignupTreatsPage';

import type { TOSAComponent } from '../types';

type TOSAComponentMap = {
  [K in ComponentName]: TOSAComponent;
};

export const ComponentMap: TOSAComponentMap = {
  [ComponentName.BasicInput]: BasicInput,
  [ComponentName.DropdownInput]: DropdownInput,
  [ComponentName.CheckboxInput]: CheckboxInput,
  [ComponentName.InlineError]: InlineError,
  [ComponentName.GetStarted]: GetStarted,
  [ComponentName.GetStartedCopyExperiment]: GetStartedCopyExperiment,
  [ComponentName.FormWrapper]: FormWrapper,
  [ComponentName.GetStartedWrapper]: GetStartedWrapper,
  [ComponentName.FormWithContinueButton]: FormWithContinueButton,
  [ComponentName.PetFormWrapper]: PetFormWrapper,
  [ComponentName.ContactInfoForm]: ContactInfoForm,
  [ComponentName.PetWeightForm]: PetWeightForm,
  [ComponentName.NumPetInput]: NumPetInput,
  [ComponentName.BodyConditionInput]: BodyConditionInput,
  [ComponentName.FreshFoodConfidenceInput]: FreshFoodConfidenceInput,
  [ComponentName.PetNameInput]: PetNameInput,
  [ComponentName.BirthdayUnitInput]: BirthdayUnitInput,
  [ComponentName.ActivityLevelInput]: ActivityLevelInput,
  [ComponentName.EatingStyleInput]: EatingStyleInput,
  [ComponentName.EmailInput]: EmailInput,
  [ComponentName.BreedsInput]: BreedsInput as TOSAComponent,
  [ComponentName.PetHealthCheckboxes]: PetHealthCheckboxes,
  [ComponentName.PetHealthInput]: PetHealthInput,
  [ComponentName.MeWhitespaceNode]: MeWhitespaceNode,
  [ComponentName.PetWhitespaceNode]: PetWhitespaceNode,
  [ComponentName.PetDetailsImage]: PetDetailsImage,
  [ComponentName.NumberOfPetsImage]: NumberOfPetsImage,
  [ComponentName.PetWeightInput]: PetWeightInput,
  [ComponentName.HealthForm]: HealthForm,
  [ComponentName.PetCurrentFoodForm]: PetCurrentFoodForm,
  [ComponentName.PetAgeSkipButton]: PetAgeSkipButton,
  [ComponentName.FoodTypeInput]: FoodTypeInput,
  [ComponentName.GrainsPreferenceInput]: GrainsPreferenceInput,
  [ComponentName.TreatsQuantityInput]: TreatsQuantityInput,
  [ComponentName.RecipesFormWrapper]: RecipesFormWrapper,
  [ComponentName.RecipesPage]: RecipesPage,
  [ComponentName.RecipesForm]: RecipesForm,
  [ComponentName.SpeechBubble]: SpeechBubble,
  [ComponentName.TreatsPage]: SignupTreatsPage,
  [ComponentName.Form]: Form,
  [ComponentName.ZipEmailErrorWrapper]: ZipEmailErrorWrapper,
  [ComponentName.ZipInput]: ZipInput,
  [ComponentName.FoodBrandInput]: FoodBrandInput,
};

export type GetComponent = (name: ComponentName) => TOSAComponent;

export function getComponent(name: ComponentName): TOSAComponent {
  const Component = ComponentMap[name];

  if (!Component) {
    throw new Error(`Cannot find component with name ${name}`);
  }

  return Component;
}
