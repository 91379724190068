import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Link, FormControl } from '@farmersdog/corgi';
import { Button, Input, Text } from '@farmersdog/corgi-x';

import Slider from 'src/components/Slider';
import SuccessCheckmark from 'src/components/SuccessCheckmark';
import Form from 'src/components/Form';
import AuthPage from 'src/components/AuthPage';
import { TOKEN_EXPIRED } from 'src/errors/errorCodes';

import { showError } from 'src/actions/ui';

import useInputValidation from 'src/validation/useInputValidation';
import {
  newPasswordValidationRules,
  newPasswordConfirmationValidationRules,
} from './ChangePassword.validation';
import {
  PASSWORD_MATCH_VALIDATION_MESSAGE,
  PAGE_TITLE_DEFAULT,
  PAGE_TITLE_SUCCESS,
  PAGE_HEADER_CHANGE,
  PAGE_HEADER_CREATE,
  PAGE_SUBHEADER,
} from './ChangePassword.copy';
import { useResetPassword } from './hooks';
import { ValidationError } from 'src/errors';

import parseQueryString from 'src/utils/parseQueryString';

import { PATH_LOGIN, PATH_PASSWORD_RESET } from '@farmersdog/constants/paths';
import { QueryParameter } from '@farmersdog/constants';

import styles from './ChangePassword.module.scss';

const PASSWORD_FIELD_NAME = 'password';
const CONFIRM_PASSWORD_FIELD_NAME = 'confirm-password';

function ChangePassword({ create }) {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  // TODO: Deprecate path-based tokens in favor or query params
  // This is a temporary solution to support both path-based and query-based tokens
  const token = params.token ?? parseQueryString(location.search, 'token');

  const email = parseQueryString(location.search, 'email') || '';

  const [error, setError] = useState();
  const [isExpiredToken, setIsExpiredToken] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();
  const newPasswordRef = useRef();

  const passwordValidation = useInputValidation(newPasswordValidationRules, {
    test: () => newPassword !== newPasswordConfirmation,
    message: PASSWORD_MATCH_VALIDATION_MESSAGE,
  });

  const handlePasswordBlur = e => {
    passwordValidation.checkValidity(e);
  };

  const passwordConfirmationValidation = useInputValidation(
    newPasswordConfirmationValidationRules
  );

  const handlePasswordConfirmationBlur = e => {
    passwordValidation.checkValidity({ target: newPasswordRef.current });
    passwordConfirmationValidation.checkValidity(e);
  };

  const {
    callResetPassword,
    resetPasswordState: { loading },
  } = useResetPassword({
    onCompleted: data => {
      setSuccess(data.resetPassword.email);
    },
    onError: err => {
      const errorCode = err?.cause?.extensions?.code;

      if (errorCode === TOKEN_EXPIRED) {
        setIsExpiredToken(true);
        setError(new ValidationError('', '', TokenExpiredText));
      } else {
        setError(new ValidationError(err?.message));
      }
    },
  });

  const handleSubmit = () => {
    callResetPassword({
      newPassword,
      resetToken: token,
    });
  };

  useEffect(() => {
    newPasswordRef.current.focus();
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    dispatch(
      showError(error, {
        title: isExpiredToken ? tokenExpiredTitle : '',
      })
    );
  }, [error, dispatch, isExpiredToken]);

  const backToLoginLink = email
    ? `${PATH_LOGIN}?${QueryParameter.Email}=${encodeURIComponent(email)}`
    : PATH_LOGIN;

  const passwordsMatch = newPassword === newPasswordConfirmation;

  const tokenExpiredTitle = 'Looks like that link expired!';
  const TokenExpiredText = (
    <>
      <div>
        Please check your inbox and use the link in the most recent password
        reset email you received from us, or request a new link{' '}
        <Link to={PATH_PASSWORD_RESET}>here.</Link>
      </div>
      <br />
      <div>
        If you’re still having trouble, please reach out using the link below.
      </div>
    </>
  );

  return (
    <AuthPage
      title={success ? PAGE_TITLE_SUCCESS : PAGE_TITLE_DEFAULT}
      header={create ? PAGE_HEADER_CREATE : PAGE_HEADER_CHANGE}
      subHeader={PAGE_SUBHEADER}
    >
      <Slider className={styles.slider} currentSlide={success ? 1 : 0}>
        <Slider.Slide>
          <Form
            className={styles.form}
            onSubmit={handleSubmit}
            enableSubmit={!loading}
          >
            {email && (
              <Input
                name={email}
                label={email}
                type="email"
                autoComplete="email"
                className={styles.field}
                disabled
              />
            )}
            <FormControl
              className={styles.field}
              message={passwordValidation.validationError}
              invalid={Boolean(passwordValidation.validationError)}
            >
              <Input
                {...passwordValidation.inputProps}
                ref={newPasswordRef}
                name={PASSWORD_FIELD_NAME}
                type="password"
                autoComplete="new-password"
                label="Choose a password"
                withRevealButton
                value={newPassword}
                onChange={e => {
                  setError();
                  setNewPassword(e.target.value);
                }}
                onBlur={handlePasswordBlur}
              />
            </FormControl>
            <FormControl
              className={styles.field}
              message={passwordConfirmationValidation.validationError}
              invalid={Boolean(passwordConfirmationValidation.validationError)}
            >
              <Input
                {...passwordConfirmationValidation.inputProps}
                name={CONFIRM_PASSWORD_FIELD_NAME}
                label="Confirm password"
                type="password"
                withRevealButton
                value={newPasswordConfirmation}
                onChange={e => {
                  setError();
                  setNewPasswordConfirmation(e.target.value);
                }}
                onBlur={handlePasswordConfirmationBlur}
              />
            </FormControl>
            <Button
              className={styles.submitButton}
              type="submit"
              loading={loading}
              disabled={
                !newPassword || !newPasswordConfirmation || !passwordsMatch
              }
            >
              Set password
            </Button>
          </Form>
        </Slider.Slide>
        <Slider.Slide>
          {success && (
            <div className={styles.success}>
              <SuccessCheckmark />
              <Text as="p" variant="heading-16" color="kale-3">
                Your password has been changed.
              </Text>
              <Link className={styles.loginLink} to={backToLoginLink}>
                <Text as="p" variant="heading-16" bold>
                  Back to log in
                </Text>
              </Link>
            </div>
          )}
        </Slider.Slide>
      </Slider>
    </AuthPage>
  );
}

ChangePassword.propTypes = {
  create: PropTypes.bool,
};

export default ChangePassword;
