import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useSignupUpdatedAssets } from '../../../hooks/experiments';
import { useLabel } from '../../../hooks/useLabel';
import { RadioSelectorGroup } from '../base';

import {
  activityLevelAssets,
  ActivityLevelAssets,
  activityLevelNewAssets,
} from './assets';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function ActivityLevelInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature } = props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const activityLevelField = `${NodeNames.ActivityLevel}-${position}` as const;
  const currentValue = getValues(activityLevelField) ?? null;
  const { frontLabel } = useLabel({ node, getValues });

  const options =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });

  return (
    <>
      <span>{frontLabel}</span>
      <RadioSelectorGroup
        registerProps={registerProps}
        options={options}
        icons={
          isSignupUpdatedAssetsOn ? activityLevelNewAssets : activityLevelAssets
        }
        defaultMobileIconIndex={ActivityLevelAssets.Active}
        legend="Activity Level"
        currentValue={currentValue}
        defaultDescription="Select the activity level closest to your dog's."
      />
    </>
  );
}
