import type { CancellationResponsesEnumType } from '../flow-state-management';
import {
  getPrescriptionDiets,
  getReplacementFoodTypeStates,
} from '../flow-state-management';

export function getFilteredCancellationReasons(
  reasonsHistory: CancellationResponsesEnumType[]
): CancellationResponsesEnumType[] {
  const prescriptionDiets = getPrescriptionDiets();
  const replacementFoodTypes = getReplacementFoodTypeStates();

  const filteredCancellationReasons = reasonsHistory.filter(
    reason =>
      !replacementFoodTypes.includes(reason) &&
      !prescriptionDiets.includes(reason)
  );

  return filteredCancellationReasons;
}
