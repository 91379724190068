import type { HomepageHeroImageTreatments } from 'src/abTesting';
import type { CVR_HOMEPAGE_HERO_IMAGE } from 'src/abTesting/features';

export enum HomepageVetOptimizationTreatments {
  Control = 'control',
  Off = 'off',
  PillAndCard = 'pill_and_card',
  PillOnly = 'pill_only',
}

export const HEALTH_VET_OPTIMIZATION_HOMEPAGE =
  'HEALTH_VET_OPTIMIZATION_HOMEPAGE';

export interface AnonymousExperimentFlags {
  [CVR_HOMEPAGE_HERO_IMAGE]: {
    config: void;
    treatment: HomepageHeroImageTreatments;
  };
  [HEALTH_VET_OPTIMIZATION_HOMEPAGE]: {
    config: void;
    treatment: HomepageVetOptimizationTreatments;
  };
}

export type AnonymousExperimentFlag = keyof AnonymousExperimentFlags;
