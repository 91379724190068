import { useQuery } from '@apollo/client';
import type {
  FetchHomepageDataQuery,
  FetchHomepageDataQueryVariables,
} from './fetchHomepageData.cgs.generated-types';
import fetchHomepageData from './fetchHomepageData.cgs.graphql';

interface UseFetchHomepageDataProps {
  onError: (error: unknown) => void;
}

export function useFetchHomepageData(props: UseFetchHomepageDataProps) {
  const SHOULD_EXCLUDE_TRIAL_ORDER = true;
  const NO_CONTACT = false;
  return useQuery<FetchHomepageDataQuery, FetchHomepageDataQueryVariables>(
    fetchHomepageData,
    {
      context: { endpoint: 'cgs' },
      variables: {
        petsInput: {
          noContact: NO_CONTACT,
        },
        treatsInput: {
          excludeTrialOrder: SHOULD_EXCLUDE_TRIAL_ORDER,
        },
      },
      onError: props.onError,
    }
  );
}
