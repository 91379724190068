import { reconstructBlueprintFromLeadState } from '../schema/actions/reconstructBlueprintFromLeadState';

import type {
  Experiments,
  FormFieldsType,
  UseForm,
  UseSchemaReturn,
} from '../types';
import type { KeepStateOptions } from 'react-hook-form';

interface RestoreSignupFlowArgs {
  data: FormFieldsType;
  reset: UseForm['reset'];
  resetOptions: KeepStateOptions;
  setFormSteps: UseSchemaReturn['setFormSteps'];
  blueprint: UseSchemaReturn['schema'];
  experiments: Experiments;
}
export function restoreSignupFlow({
  data,
  blueprint,
  reset,
  resetOptions,
  setFormSteps,
  experiments,
}: RestoreSignupFlowArgs) {
  if (!blueprint) {
    throw new Error('Cannot reconstruct blueprint');
  }
  // reset the form state with the data
  reset(data, resetOptions);

  // rebuild the blueprint based on data
  const reconstructedBlueprint = reconstructBlueprintFromLeadState({
    baseBlueprint: blueprint,
    recoveredLeadFormValues: data,
    experiments,
  });

  // re-render the reconstructed blueprint and get an updated form steps
  return setFormSteps(reconstructedBlueprint);
}
