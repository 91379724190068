/* CH#49502: Used by CVR */
import type { TextV2Props } from '@farmersdog/corgi';
import { Modal, TextV2, Button, Link } from '@farmersdog/corgi';
import { Divider } from '@farmersdog/corgi-x';
import useGlobalLightbox from 'src/utils/useGlobalLightbox';
import { useCorgiViewport } from 'src/screen';
import { PATH_SIGNUP_CHECKOUT } from '@farmersdog/constants/paths';
import {
  MODAL_TITLE,
  NOT_NOW_BUTTON_COPY,
  LOGIN_BUTTON_COPY,
} from './FinishCheckoutModal.copy';

import styles from './FinishCheckoutModal.module.scss';

interface FinishCheckoutModalProps {
  email: string;
  onCancelClick?: () => void;
}

export const FINISH_CHECKOUT_MODAL_ID = 'FinishCheckoutModalId';

interface ResponsiveVariants {
  body: TextV2Props['variant'];
  heading: TextV2Props['variant'];
}

export function FinishCheckoutModal({
  email = '',
  onCancelClick,
}: FinishCheckoutModalProps) {
  const { rootProps, labelProps, close } = useGlobalLightbox({
    id: FINISH_CHECKOUT_MODAL_ID,
  });

  const textVariants = useCorgiViewport<ResponsiveVariants>({
    xs: {
      heading: 'heading-28',
      body: 'body-16',
    },
    md: {
      heading: 'heading-40',
      body: 'body-21',
    },
  });

  return (
    // @ts-expect-error TODO: Fix these types in corgi
    <Modal {...rootProps}>
      <TextV2 as="h2" variant={textVariants.heading} bold {...labelProps}>
        {MODAL_TITLE}
      </TextV2>
      <TextV2 as="p" variant={textVariants.body} className={styles.text}>
        Please finish checking out to login into your account.
      </TextV2>
      <Divider orientation="horizontal" color="Oyster0" margin={0} />
      <div className={styles.buttonContainer}>
        <Button
          asLink
          onClick={() => {
            onCancelClick?.();
            close();
          }}
        >
          <TextV2 bold variant="body-16">
            {NOT_NOW_BUTTON_COPY}
          </TextV2>
        </Button>
        <Link asButton onClick={close} to={`${PATH_SIGNUP_CHECKOUT}/${email}`}>
          {LOGIN_BUTTON_COPY}
        </Link>
      </div>
    </Modal>
  );
}
