import { v4 as uuidv4 } from 'uuid';

import { updateSession } from './update-session';
import type { SessionData } from './is-valid-session-data';

export function createSession(currentTrackerVersion: number): SessionData {
  const newSession = {
    id: uuidv4(),
    version: currentTrackerVersion,
  };
  return updateSession(newSession);
}
