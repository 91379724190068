import { assign, createMachine } from 'xstate';
import type { SuggestOrderSizeEventMachineEvent } from './SuggestOrderSizeEvent';
import { SuggestOrderSizeEvent } from './SuggestOrderSizeEvent';
import { SuggestOrderSizeState } from './SuggestOrderSizeState';

export type SuggestOrderSizeMachineContext = {
  daysOfFood?: number;
  earliestDesiredArrivalDate?: string;
  latestDesiredArrivalDate?: string;
};

/**
 * Wrapper to create xState machine. Sets initial context.
 *
 * @returns xState machine
 */
export const suggestOrderSizeMachine = createMachine({
  id: 'suggestOrderSize',
  initial: SuggestOrderSizeState.Suggest,
  states: {
    [SuggestOrderSizeState.Suggest]: {
      on: {
        [SuggestOrderSizeEvent.Accept]: {
          actions: [
            assign(({ event }) => {
              return {
                daysOfFood: event.daysOfFood,
                earliestDesiredArrivalDate: event.earliestDesiredArrivalDate,
                latestDesiredArrivalDate: event.latestDesiredArrivalDate,
              };
            }),
          ],
          target: SuggestOrderSizeState.AcceptConfirmation,
        },
        [SuggestOrderSizeEvent.Reject]: {
          actions: [
            assign(({ event }) => {
              return {
                daysOfFood: event.daysOfFood,
                earliestDesiredArrivalDate: event.earliestDesiredArrivalDate,
                latestDesiredArrivalDate: event.latestDesiredArrivalDate,
              };
            }),
          ],
          target: SuggestOrderSizeState.RejectConfirmation,
        },
        [SuggestOrderSizeEvent.Close]: {
          target: SuggestOrderSizeState.Close,
        },
      },
    },
    [SuggestOrderSizeState.AcceptConfirmation]: {
      on: {
        [SuggestOrderSizeEvent.Close]: {
          target: SuggestOrderSizeState.Close,
        },
        [SuggestOrderSizeEvent.ContinueToPlan]: {
          target: SuggestOrderSizeState.ContinueToPlan,
        },
      },
    },
    [SuggestOrderSizeState.RejectConfirmation]: {
      on: {
        [SuggestOrderSizeEvent.Close]: {
          target: SuggestOrderSizeState.Close,
        },
        [SuggestOrderSizeEvent.ContinueToPlan]: {
          target: SuggestOrderSizeState.ContinueToPlan,
        },
      },
    },
    [SuggestOrderSizeState.Close]: {
      type: 'final',
    },
    [SuggestOrderSizeState.ContinueToPlan]: {
      type: 'final',
    },
  },
  types: {} as {
    events: SuggestOrderSizeEventMachineEvent;
  },
});
