import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { ApplicationError } from '../../../errors';
import { getUnavailableDate } from '../../utils';
import type { FetchTemporaryPauseQuery } from './FetchTemporaryPause.cgs.generated-types';
import temporaryPauseQuery from './FetchTemporaryPause.cgs.graphql';

export type UseFetchTemporaryPause = {
  loading: boolean;
  error?: ApolloError | ApplicationError;
  data?: {
    /** The available the dates to resume delivery. */
    availablePauseResumeDates: string[];
    /** The shipping date the next order is scheduled to. */
    currentNextDate: string;
    /** A date that should appear as "unavailable" */
    unavailableDate?: string;
    previousNextDate?: string | null;
  };
};

/** Query the subscription and more details from backend */
export function useFetchTemporaryPause(): UseFetchTemporaryPause {
  const { loading, data, error, previousData } =
    useQuery<FetchTemporaryPauseQuery>(temporaryPauseQuery, {
      context: { endpoint: 'cgs' },
    });

  if (!data) {
    return { loading, error };
  }

  const previousNextDate = previousData?.customer.freshSubscription.nextDate;

  const currentNextDate = data.customer.freshSubscription.nextDate;

  if (!currentNextDate) {
    return {
      loading,
      error: new ApplicationError('fresh subscription is missing next date', {
        displayMessage:
          'We seem to be missing some of your information. Please contact customer support for assistance.',
      }),
    };
  }

  const availablePauseResumeDates =
    data.customer.freshSubscription.availablePauseResumeDates;

  const unavailableDate = getUnavailableDate(
    data.customer.freshSubscription.availablePauseResumeDates
  );

  return {
    loading,
    error,
    data: {
      availablePauseResumeDates,
      currentNextDate,
      unavailableDate,
      previousNextDate,
    },
  };
}
