export enum RecipeNames {
  TURKEY = 'TURKEY',
  BEEF = 'BEEF',
  PORK = 'PORK',
  CHICKEN_AND_GREENS = 'CHICKEN_AND_GREENS',
  CHICKEN_CHIA = 'CHICKEN_CHIA',
  CHICKENL = 'CHICKENL',
  BEEF_MLENTILS = 'BEEF_MLENTILS',
  CHICKEN_OATS = 'CHICKEN_OATS',
  CHICKEN_OATS_COLLARDS = 'CHICKEN_OATS_COLLARDS',
  PORK_GRAIN = 'PORK_GRAIN',
}

export const LPF_RECIPES = [
  RecipeNames.CHICKEN_OATS,
  RecipeNames.CHICKEN_OATS_COLLARDS,
  RecipeNames.PORK_GRAIN,
] as const;
export type LpfRecipes = (typeof LPF_RECIPES)[number];
