import { LightboxId, useGlobalLightbox, useLeadState } from '../../../../hooks';
import { FeatureName } from '../../../../utils/features';

import { useRecoverLeadProgress } from './useRecoverLeadProgress';

import type { UseRecoverLeadProgressProps } from './useRecoverLeadProgress';

interface useRecoveryProps extends UseRecoverLeadProgressProps {
  recoveryOnClose: () => void;
}
export function useRecovery({
  reset,
  setFormSteps,
  formValidationSchema,
  blueprint,
  recoveryOnClose,
  experiments,
}: useRecoveryProps) {
  const { createLead, createLeadState } = useLeadState();

  const [recoverLeadProgress, recoverLeadProgressState] =
    useRecoverLeadProgress({
      reset,
      setFormSteps,
      formValidationSchema,
      blueprint,
      experiments,
    });

  const recoveryModalControl = useGlobalLightbox({
    id: LightboxId.RecoveryModal,
    onClose: () => {
      recoveryOnClose();
    },
  });
  const hasAccountModalControl = useGlobalLightbox({
    id: LightboxId.HasAccountModal,
  });

  async function initiateRecovery(email: string) {
    const isDaisyModeEnabled =
      experiments?.[FeatureName.CVR_DAISY_MODE_ENABLED]?.treatment === 'on';
    const { isRecoverable, isAuthenticated } = await createLead({
      email,
      daisyModeEnabled: isDaisyModeEnabled,
    });

    const leadAlreadyHasAccount = !isAuthenticated && !isRecoverable;

    return { leadIsRecoverable: isRecoverable, leadAlreadyHasAccount };
  }

  return {
    recoverLeadProgress,
    recoverLeadProgressState,
    recoveryModalControl,
    hasAccountModalControl,
    initiateRecovery,
    createLeadState,
  };
}
