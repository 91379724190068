import { RichSelectorGroup } from '@farmersdog/corgi';

import { Picture } from '../../../shared';
import { RadioSelector } from '../RadioSelector';

import { ActiveImage } from './ActiveImage';
import styles from './RadioSelectorGroup.module.css';
import { getIconAltTag } from './utils';

import type { ChangeEvent, FocusEvent } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

type TOSAChangeHandler = (e: ChangeEvent<HTMLInputElement>) => void;
type TOSABlurHandler = (e: FocusEvent<HTMLFieldSetElement>) => void;
type RadioSelectorGroupChangeHandler = TOSAChangeHandler;
type RadioSelectorGroupBlurHandler = TOSABlurHandler;

interface RadioOption {
  /** text that appears under the radio button **/
  label: string;
  /** value that is set in form state when option is selected **/
  value: string | number;
  /** extended description of the option **/
  description?: string;
}

export interface RadioSelectorGroupProps {
  registerProps: UseFormRegisterReturn;
  options: RadioOption[];
  legend: string;
  onChange?: RadioSelectorGroupChangeHandler;
  onBlur?: RadioSelectorGroupBlurHandler;
  currentValue: string | number | null;
  icons?: {
    small?: string[][];
    large: string[][];
  };
  defaultMobileIconIndex?: number;
  defaultDescription?: string;
}

export function RadioSelectorGroup({
  registerProps,
  options,
  legend,
  onChange,
  currentValue,
  icons,
  defaultMobileIconIndex,
  defaultDescription,
}: RadioSelectorGroupProps) {
  if (!options || !options.length) {
    return null;
  }

  const selectedOptionIndex = options.findIndex(
    option => option.value === currentValue
  );

  const activeOptionIndex =
    selectedOptionIndex > -1 ? selectedOptionIndex : defaultMobileIconIndex;
  const hasActiveOption =
    activeOptionIndex !== undefined && activeOptionIndex > -1;

  const activeIcon = hasActiveOption ? icons?.large[activeOptionIndex] : null;

  return (
    <>
      {ActiveImage && (
        <div className={styles.iconSelected}>
          <ActiveImage
            icon={activeIcon}
            alt={getIconAltTag(options[selectedOptionIndex]?.value)}
          />
        </div>
      )}
      <RichSelectorGroup
        name={registerProps.name}
        type="radio"
        legend={legend}
        aria-live="polite"
        onChange={onChange ?? registerProps.onChange}
        fullWidthDescription={true}
        defaultFullWidthDescription={defaultDescription}
        value={currentValue}
        className={styles.selectorGroup}
      >
        {options.map(({ label, value, description }, index) => {
          const icon = icons?.large[index] ? (
            <Picture
              className={styles.picture}
              sources={icons.large[index]}
              alt={getIconAltTag(value)}
            />
          ) : null;
          const smallIcon =
            !hasActiveOption && icons?.small && icons?.small[index] ? (
              <Picture
                className={styles.picture}
                sources={icons.small[index]}
                alt={getIconAltTag(value)}
              />
            ) : null;
          return (
            <RadioSelector
              key={value}
              ref={registerProps.ref}
              label={label}
              checked={currentValue === value}
              value={value}
              description={description}
              icon={icon}
              iconSmall={smallIcon}
            />
          );
        })}
      </RichSelectorGroup>
    </>
  );
}
