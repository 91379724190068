import { Grid, GridItem } from '@farmersdog/corgi-x';
import type { FetchAddDogFormDataQuery } from '../../network/fetchAddDogFormData.cgs.generated-types';
import { AddDogContent } from '../AddDogContent';
import type {
  AddDogFormChangeHandler,
  BreedOptions,
  UseAddDogFormFields,
} from '../../hooks';
import {
  AgeInput,
  AgeUnitInput,
  GenderInput,
  SterilizationInput,
} from './components';
import { BreedInput } from './components/BreedInput';
import { reporter } from '../../../../../errors/services';

interface EssentialSceneProps {
  name: string;
  ageNumber: number;
  ageUnit: UseAddDogFormFields['ageUnit'];
  sterilization: boolean;
  sex: UseAddDogFormFields['sex'];
  breed: BreedOptions[];
  breedFormOptions:
    | FetchAddDogFormDataQuery['petCharacteristics']['breeds'][number][]
    | undefined;
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

export const EssentialScene = (props: EssentialSceneProps) => {
  const {
    onCtaClick,
    name,
    breedFormOptions,
    onChange,
    ageNumber,
    ageUnit,
    breed,
    sterilization,
    sex,
  } = props;

  if (!breedFormOptions) {
    reporter.error('Breed form options not defined');
    throw new Error('Breeds form options are required for Add Pet form');
  }

  const disabledCTA = breed.length === 0 || !ageNumber;

  return (
    <AddDogContent
      heading={`Tell us about ${name}`}
      onCtaClick={onCtaClick}
      ctaDisabled={disabledCTA}
    >
      <Grid rowGap="sm" columnGap="sm" topSpacing="lg">
        <GridItem xs={6}>
          <AgeInput ageNumber={ageNumber} onChange={onChange} />
        </GridItem>
        <GridItem xs={6}>
          <AgeUnitInput ageUnit={ageUnit} onChange={onChange} />
        </GridItem>
        <GridItem xs={12}>
          <GenderInput value={sex} onChange={onChange} />
        </GridItem>
        <GridItem>
          <SterilizationInput value={sterilization} onChange={onChange} />
        </GridItem>
        <GridItem xs={12}>
          <BreedInput
            name={name}
            breedFormOptions={breedFormOptions}
            value={breed}
            onChange={onChange}
          />
        </GridItem>
      </Grid>
    </AddDogContent>
  );
};
