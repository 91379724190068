import { useHistory, useLocation } from 'react-router';
import type { ReactNode } from 'react';
import { PATH_PROFILE } from '@farmersdog/constants/paths';
import { useRetryPaymentIsUnlocked } from '../network';
import { ActivityIndicator, Page } from '@farmersdog/corgi-x';
import styles from './PaymentRetry.module.css';
import { reporter } from '../../errors/services';
import { trackPaymentRetryOpened } from '../analytics';

/**
 * Determines whether a user should see the Payment Retry Experience.
 * Redirects to Homepage if not.
 */
export interface PaymentRetryWrapperProps {
  children: ReactNode;
}

export function PaymentRetryWrapper(props: PaymentRetryWrapperProps) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectAction = () => {
    history.push(PATH_PROFILE);
  };
  const { data, loading, error } = useRetryPaymentIsUnlocked();
  if (loading) {
    return (
      <Page layout="base-centered" className={styles.root}>
        <ActivityIndicator mode="dark" />
      </Page>
    );
  }

  if (error) {
    reporter.error(new Error('Error loading PaymentRetry Data'), {
      sourceError: error,
    });
  } else if (!data) {
    reporter.error(new Error('Unknown exception occurred while loading data'), {
      sourceError: error,
    });
  }

  if (error || !data || data.customer.retryPaymentIsUnlocked === false) {
    redirectAction();
    return;
  }

  // only if pushed from Profile Page should we track that the payment retry
  // page opened
  if (history.action === 'PUSH' && params.get('prevPath') === PATH_PROFILE) {
    trackPaymentRetryOpened();
  }
  return <>{props.children}</>;
}
