import { formatCadenceInWeeks } from 'src/utils/formatters';

import type { SubscriptionProduct } from 'src/pages/Signup/DIY/hooks/useFetchDiyQuote';
import { useDiyCheckoutQuote } from 'src/pages/SignupCheckoutPage/hooks/useDiyCheckoutQuote';

export interface UseDiyRecommendationReturn {
  products: SubscriptionProduct[];
  recommendedCadence: number;
  recommendedCadenceInWeeks: number;
  recommendedNumberOfProducts: number;
}

export function useDiyRecommendation(): UseDiyRecommendationReturn {
  const { recommendedSubscription } = useDiyCheckoutQuote();

  const { products, cadence: recommendedCadence } = recommendedSubscription;

  const recommendedCadenceInWeeks = formatCadenceInWeeks(recommendedCadence);

  const recommendedNumberOfProducts = products.length
    ? products[0].quantity
    : 0;

  return {
    products,
    recommendedCadence,
    recommendedCadenceInWeeks,
    recommendedNumberOfProducts,
  };
}
