import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PATH_HOME } from '@farmersdog/constants/paths';

import type {
  UseFormNavigateReturn,
  FindCurrentProps,
  FormNavigationListener,
  Unsubscribe,
  GoToStepOptions,
} from '../types';

export function useFormNavigation(): UseFormNavigateReturn {
  const listeners = useRef<FormNavigationListener[]>([]);
  const [current, setCurrent] = useState<string>();
  const history = useHistory();

  function subscribe(listener: FormNavigationListener): Unsubscribe {
    listeners.current = [...listeners.current, listener];

    const unsubscribe = () => {
      listeners.current = listeners.current.filter(storedListener => {
        return storedListener !== listener;
      });
    };

    return unsubscribe;
  }

  function goToStep(
    stepId: string,
    { propagate = true, routingAction = 'push' }: GoToStepOptions = {}
  ) {
    if (propagate) {
      listeners.current.forEach(listener => {
        listener(stepId, { routingAction });
      });
    }

    setCurrent(stepId);
  }

  function findCurrentIndex({ formSteps }: FindCurrentProps) {
    return formSteps.findIndex(branch => branch.__self__ === current);
  }

  function findNext({ formSteps }: FindCurrentProps) {
    const currentIndex = findCurrentIndex({ formSteps });

    if (currentIndex >= 0 && formSteps.length > currentIndex + 1) {
      return formSteps[currentIndex + 1].__self__;
    }
    return undefined;
  }

  function findPrevious({ formSteps }: FindCurrentProps) {
    const currentIndex = findCurrentIndex({ formSteps });
    if (currentIndex > 0) {
      return formSteps[currentIndex - 1].__self__;
    }
    return undefined;
  }

  function setNext({ formSteps }: FindCurrentProps) {
    const nextFormStepId = findNext({ formSteps });

    if (nextFormStepId) {
      goToStep(nextFormStepId);
    }
  }

  function setPrevious({ formSteps }: FindCurrentProps) {
    const previousFormStepId = findPrevious({ formSteps });
    if (previousFormStepId) {
      goToStep(previousFormStepId);
    } else {
      // if no previous form step, already at 0 index; previous navigation returns to homepage.
      history.push(PATH_HOME);
    }
  }

  function getIsOnFirstBranch({ formSteps }: FindCurrentProps) {
    return Boolean(
      current && formSteps.length > 0 && current === formSteps[0]?.__self__
    );
  }

  return {
    subscribe,
    goToStep,
    current,
    setNext,
    setPrevious,
    findCurrentIndex,
    findNext,
    findPrevious,
    getIsOnFirstBranch,
  };
}
