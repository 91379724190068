/** A mutation or query whose variables do not match the schema requirements */
export const INVALID_GRAPHQL_INPUT = 'INVALID_GRAPHQL_INPUT';
/** A mutation or query that is malformed */
export const INVALID_GRAPHQL_SYNTAX = 'INVALID_GRAPHQL_SYNTAX';
/** A mutation or query that contains a field that does not match the schema */
export const UNRECOGNIZED_GRAPHQL_FIELD = 'UNRECOGNIZED_GRAPHQL_FIELD';
/** We are unable to obtain shipping rates from our shipping service */
export const SHIPPING_SERVICE_UNAVAILABLE = 'SHIPPING_SERVICE_UNAVAILABLE';
/** The data submitted is missing a required field */
export const MISSING_FIELD = 'MISSING_FIELD';
/** The data submitted contains an invalid value */
export const INVALID_VALUE = 'INVALID_VALUE';
/** The data submitted contains a value below the minimum required */
export const VALUE_IS_BELOW_MIN_VALUE = 'VALUE_IS_BELOW_MIN_VALUE';
/** The data submitted contains a value above the minimum required */
export const VALUE_IS_ABOVE_MAX_VALUE = 'VALUE_IS_ABOVE_MAX_VALUE';
/** The address cannot be shipped to */
export const CANNOT_SHIP_TO_LOCATION = 'CANNOT_SHIP_TO_LOCATION';
/** The stripe credit card charge was declined */
export const CHARGE_DECLINED = 'CHARGE_DECLINED';
/** The action is forbidden */
export const FORBIDDEN = 'FORBIDDEN';
/** The requested resource cannot be found */
export const NOT_FOUND = 'NOT_FOUND';
/** The provided jwt token is no longer valid */
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
/** Requesting an action that can only be performed on pending users */
export const USER_ALREADY_ACTIVE = 'USER_ALREADY_ACTIVE';
/** Generic non specific address validation error */
export const ADDRESS_VALIDATION_ERROR = `ADDRESS_VALIDATION_ERROR`;
/** The state is valid but TFD cannot deliver to it */
export const ADDRESS_INACCESSIBLE_STATE_ERROR = `ADDRESS_INACCESSIBLE_STATE_ERROR`;
/** The state is not valid state */
export const ADDRESS_INVALID_STATE_ERROR = `ADDRESS_INVALID_STATE_ERROR`;
/** The zip code is not valid */
export const ADDRESS_INVALID_ZIP_ERROR = `ADDRESS_INVALID_ZIP_ERROR`;
/** The address is a PO box and is not deliverable by TFD */
export const ADDRESS_PO_BOX_ERROR = `ADDRESS_PO_BOX_ERROR`;
/** There is already an account with this address */
export const ADDRESS_DUPLICATE_ERROR = `ADDRESS_DUPLICATE_ERROR`;
/** The address contains incorrect data that could be automatically corrected */
export const ADDRESS_INCORRECT_DATA_ERROR = `ADDRESS_INCORRECT_DATA_ERROR`;
/** The address contains incorrect data that cannot be automatically corrected */
export const ADDRESS_POTENTIAL_BAD_DATA_ERROR = `ADDRESS_POTENTIAL_BAD_DATA_ERROR`;
/** The address contains data that is valid separately but not in combination */
export const ADDRESS_DATA_MISMATCH_ERROR = `ADDRESS_DATA_MISMATCH_ERROR`;
/** The address cannot be found in the validation dataset */
export const ADDRESS_NOT_FOUND_ERROR = `ADDRESS_NOT_FOUND_ERROR`;
/** The address is not precise enough to be deliverable */
export const ADDRESS_AMBIGUOUS_ERROR = `ADDRESS_AMBIGUOUS_ERROR`;
/** Address is invalid for an unspecified reason */
export const ADDRESS_INVALID_ERROR = `ADDRESS_INVALID_ERROR`;
/** The address validation service is experiencing downtime */
export const ADDRESS_SERVICE_UNAVAILABLE_ERROR = `ADDRESS_SERVICE_UNAVAILABLE_ERROR`;
/** The zip code does not exist */
export const ADDRESS_NONEXISTENT_ZIPCODE_ERROR = `ADDRESS_NONEXISTENT_ZIPCODE_ERROR`;
/** A third party service request failed because of rate limiting */
export const PAYMENT_SERVICE_UNAVAILABLE = 'PAYMENT_SERVICE_UNAVAILABLE';
/** There was a unexpected price delta between the front-end quote and backend-end quote */
export const CHECKOUT_PRICE_NOT_MATCHING = 'CHECKOUT_PRICE_NOT_MATCHING';
/** Mismatched discounts at checkout */
export const CHECKOUT_DISCOUNT_MISMATCH = 'CHECKOUT_DISCOUNT_MISMATCH';
