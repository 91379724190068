import { useMutation } from '../../services/apollo';

import createLead from './createLead.graphql';

import type { CreateLeadMutationVariables, CreateLeadMutation } from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.MutationHookOptions<
  CreateLeadMutation,
  CreateLeadMutationVariables
>;

export function useCreateLead(options?: Options) {
  return useMutation<CreateLeadMutation, CreateLeadMutationVariables>(
    createLead,
    options
  );
}
