import cloneDeep from 'lodash/cloneDeep';

import { cookie } from '@farmersdog/lead-browser-storage';

import { blueprintTraverseApply } from '../utils';

import { phoneSalesAgentNodeExclusionList } from './constants/phoneSalesAgentNodeExclusions';

import type { Blueprint, CompiledBlueprint } from '../types';

export interface FilterBlueprintForPhoneSalesAgentArgs<
  B extends Blueprint | CompiledBlueprint,
> {
  blueprint: B;
}

export function filterBlueprintForPhoneSalesAgent<
  B extends Blueprint | CompiledBlueprint,
>({ blueprint }: FilterBlueprintForPhoneSalesAgentArgs<B>): B {
  const isPhoneSalesAgent = cookie.isPhoneSalesAgent();

  if (!isPhoneSalesAgent) {
    return blueprint;
  }

  const clonedBlueprint = cloneDeep(blueprint);

  blueprintTraverseApply(clonedBlueprint.root, (node, parent) => {
    // @ts-expect-error node.name is type string and exclusion list only contains NodeNames
    if (phoneSalesAgentNodeExclusionList.has(node.name)) {
      const index = parent?.children.findIndex(childNode => {
        return childNode.name === node.name;
      });
      if (parent && typeof index === 'number' && index > -1) {
        parent?.children.splice(index, 1);
      }
    }
  });

  return clonedBlueprint;
}
