import config from 'src/config';

import type { Referrer } from 'src/hooks/useReferrer';
import { DiscountType } from 'src/graphql/enums';

/**
 * Get the discount percentage referral object
 */
export function getDiscountPercentage(referrer: Referrer | undefined): number {
  if (
    referrer &&
    !referrer.invalid &&
    typeof referrer.discountPercentage === 'number'
  ) {
    return referrer.discountPercentage;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return config('features.defaultTrialDiscount');
}

export const DEFAULT_DISCOUNT: DiscountTypeAndAmount = {
  discountAmount: config('features.defaultTrialDiscount') as number,
  discountType: DiscountType.percentage,
};

export interface DiscountTypeAndAmount {
  discountAmount: number;
  discountType: DiscountType;
}

/**
 * Get the discount type and amount from the referral object.
 */
export function getDiscountTypeAndAmount(
  referrer: Referrer | undefined
): DiscountTypeAndAmount {
  if (!referrer || referrer.invalid) {
    return DEFAULT_DISCOUNT;
  }

  if (referrer.discountType && typeof referrer.discountAmount === 'number') {
    return {
      discountAmount: referrer.discountAmount,
      discountType: referrer.discountType,
    };
  }

  if (
    (!referrer.discountType || typeof referrer.discountAmount !== 'number') &&
    typeof referrer.discountPercentage === 'number'
  ) {
    return {
      discountAmount: referrer.discountPercentage,
      discountType: DiscountType.percentage,
    };
  }

  return DEFAULT_DISCOUNT;
}

/**
 * Get the referrer validation status
 */
export function isReferrerValid(referrer: Referrer | undefined): boolean {
  return referrer ? !referrer.invalid : true;
}

/**
 * Get the copy for the discount amount ('20% off' or '$30 off')
 */
export function getDiscountAmountCopy({
  discountAmount,
  discountType,
}: DiscountTypeAndAmount): string {
  return discountType === DiscountType.fixed
    ? `$${discountAmount} off`
    : `${discountAmount}% off`;
}
