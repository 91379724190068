import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

import type { TreatSize } from '../../graphql/types';

type TrackSignupFreeTreatsOpenNutritionFactsArgs = {
  name: string;
  size: TreatSize;
};

/** A Segment tracking event for when a user opens the nutrition facts modal in the treats page. */

export function trackSignupFreeTreatsOpenNutritionFacts({
  name,
  size,
}: TrackSignupFreeTreatsOpenNutritionFactsArgs) {
  track(segmentTrackEvents.signup_free_treats_open_nutrition_facts, {
    name,
    size,
  });
}
