import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { Fragment, useMemo, useState } from 'react';

import { useIntersection, useHtmlElement } from '@farmersdog/corgi';
import {
  AccessibleContent,
  Button,
  Divider,
  Grid,
  GridItem,
  Strikeout,
  Text,
} from '@farmersdog/corgi-x';
import { usePreviousEffect } from '@farmersdog/utils';

import { trackPriceValueModalClick } from '../../../analytics';
import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { useFetchQuote } from '../../../hooks/useFetchQuote';
import { convertToPetInputsForQuote } from '../../../utils/convertToPetInputForQuote/convertToPetInputForQuote';
import { formatCommaSeparatedList } from '../../../utils/formatCommaSeparatedList';
import { PriceValueModal } from '../../PriceValueModal';
import { HiddenPreSelectedRecommendation } from '../HiddenPreSelectedRecommendation';
import { useCheapestQuotes } from '../hooks/useCheapestQuotes';
import { RecipesStack } from '../RecipesStack';
import { getQuoteData } from '../utils/getQuoteData';

import { RecommendedPlanCta } from './components/cta';
import { StickyCta } from './components/cta/StickyCta';
import { usePriceValueModalExperiment } from './hooks';
import styles from './RecommendedPlan.module.css';
import {
  getFirstBoxAccessibleText,
  getRecipesStartingAtAccessibleText,
} from './utils';

import type { FetchQuoteQuery } from '../../../graphql/types';
import type {
  FormFieldsType,
  PetRecipes,
  UseForm,
  UseFeatureHook,
} from '../../../types';
import type { PriceValueModalTreatments } from '../../../utils';
import type { RecommendedRecipe } from '../types';
import type * as yup from 'yup';

interface RecommendedPlanProps {
  useFeature: UseFeatureHook;
  className?: string;
  hasNonRecommendedLpfRecipes: boolean;
  lpfRecipeRecommended: boolean;
  recommendedRecipes: RecommendedRecipe[];
  customizePlan: () => void;
  registerProps: ReturnType<UseForm['register']>;
  onSubmit: (quote: FetchQuoteQuery['fetchQuote'] | null) => void;
  formSubmitting: boolean;
  petName: string;
  formValues: FormFieldsType;
  formValidationSchema: yup.AnySchema;
  petRecipes: PetRecipes;
  recommendationIsValid: boolean;
  priceValueModalTreatment: PriceValueModalTreatments;
  recommendedRecipeCopy: string | JSX.Element;
}

export const RecommendedPlan = ({
  useFeature,
  className,
  hasNonRecommendedLpfRecipes,
  lpfRecipeRecommended,
  recommendedRecipes,
  customizePlan,
  registerProps,
  onSubmit,
  formSubmitting,
  petName,
  formValues,
  formValidationSchema,
  petRecipes,
  recommendationIsValid,
  priceValueModalTreatment,
  recommendedRecipeCopy,
}: RecommendedPlanProps) => {
  const [hasRecommendationChanged, setHasRecommendationChanged] =
    useState(false);
  const petsInput = useMemo(() => {
    return recommendationIsValid
      ? convertToPetInputsForQuote({
          data: formValidationSchema.cast(formValues, {
            assert: 'ignore-optionality',
          }) as FormFieldsType,
          isCustomizingRecipes: false,
          currentPetName: petName,
          petRecipes,
        })
      : null;
  }, [
    recommendationIsValid,
    petName,
    petRecipes,
    formValues,
    formValidationSchema,
  ]);

  usePreviousEffect(
    (prevRecommendation, nextRecommendation) => {
      const hasChanged = !isEqual(
        [...prevRecommendation].sort(),
        [...nextRecommendation].sort()
      );
      setHasRecommendationChanged(hasChanged);
    },
    recommendedRecipes.filter(r => r.recommended).map(r => r.name)
  );

  const { cheapestLpfQuote } = useCheapestQuotes({
    currentPetName: petName,
    petRecipes,
    petsInput,
  });

  const { quote } = useFetchQuote({
    currentPetName: petName,
    shouldFetchQuote: hasRecommendationChanged && recommendationIsValid,
    petsInput,
    isRecommendedPlan: true,
  });

  const {
    petDailySubtotal: cheapestLpfPetDailySubtotal,
    petDailyDiscountedSubtotal: cheapestPetDailyDiscountedSubtotal,
  } = getQuoteData({
    quote: cheapestLpfQuote,
    currentPetName: petName,
  });

  const { petDailySubtotal, petDailyDiscountedSubtotal, discountPercentage } =
    getQuoteData({
      quote,
      currentPetName: petName,
    });

  const displayPetName = petName ?? 'Your Dog';
  const recommendedRecipeDisplayNames = recommendedRecipes.map(
    recipe => recipe.displayName
  );
  const formattedRecipeNames = formatCommaSeparatedList(
    recommendedRecipeDisplayNames
  );

  const { open } = useGlobalLightbox({
    id: LightboxId.MultiRecipeDetailModal,
  });

  const { open: openPriceValue } = useGlobalLightbox({
    id: LightboxId.PriceValueModal,
  });

  const [recipesContinueElement, recipesContinueRef] = useHtmlElement();

  const intersection = useIntersection(recipesContinueElement);
  const shouldRevealStickyCta = Boolean(
    intersection && !intersection.isIntersecting && intersection.rootBounds
  );

  const {
    shouldShowPriceValue,
    shouldShowKibbleBashContent,
    shouldShowProFreshContent,
  } = usePriceValueModalExperiment(priceValueModalTreatment);

  const firstBoxAccessibleText = getFirstBoxAccessibleText({
    petDailySubtotal,
    petDailyDiscountedSubtotal,
  });

  const recipesStartingAtAccessibleText = getRecipesStartingAtAccessibleText({
    cheapestLpfPetDailySubtotal,
    cheapestPetDailyDiscountedSubtotal,
  });

  const handleOnClickPriceValueModal = () => {
    openPriceValue();
    trackPriceValueModalClick();
  };

  return (
    <>
      {shouldShowPriceValue && (
        <PriceValueModal
          shouldShowKibbleBashContent={shouldShowKibbleBashContent}
          shouldShowProFreshContent={shouldShowProFreshContent}
        />
      )}
      <Grid
        className={classNames([styles.container, className])}
        columnGap="xl"
        justifyContent="center"
      >
        <GridItem
          alignItems={{ xs: 'center', md: 'flex-start' }}
          className={styles.infoContainer}
          md={6}
          lg={5}
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            variant="heading-28"
            bold
            color="charcoal-3"
            as="h1"
            className={styles.recipePageTitle}
          >
            <span className={styles.newLine}>{displayPetName}’s</span>
            <span className={styles.newLine}>Recommended Plan</span>
          </Text>
          <div className={styles.recipesInfoContainer}>
            {recommendedRecipes.map(recipe => {
              return (
                <Fragment key={recipe.name}>
                  <HiddenPreSelectedRecommendation
                    recipe={recipe}
                    registerProps={registerProps}
                  />
                </Fragment>
              );
            })}
            <div className={styles.recommendedRecipesListContainer}>
              {!shouldShowPriceValue && (
                <Text
                  variant="heading-16"
                  color="charcoal-3"
                  as="p"
                  className={styles.recommendedRecipesList}
                >
                  {formattedRecipeNames}
                  <Button
                    variant="plain-text"
                    type="button"
                    onClick={() => open()}
                    className={styles.seeDetailsButtonContainer}
                  >
                    <Text
                      variant="heading-12"
                      color="carrot-2"
                      className={styles.seeDetailsButton}
                    >
                      Nutrition Facts
                    </Text>
                  </Button>
                </Text>
              )}
            </div>
            {quote && (
              <AccessibleContent
                accessibleText={firstBoxAccessibleText}
                visualContent={
                  <Text
                    variant="heading-16"
                    bold
                    color="charcoal-3"
                    as="p"
                    className={styles.pricePerDay}
                  >
                    First Box:{' '}
                    <>
                      <Strikeout className={styles.strikeoutPrice}>
                        <Text
                          variant="heading-16"
                          className={styles.pricePerDay}
                        >
                          {petDailySubtotal}/day
                        </Text>
                      </Strikeout>{' '}
                    </>
                    <Text
                      variant="heading-16"
                      as="span"
                      color="kale-2"
                      bold
                      className={styles.pricePerDay}
                    >
                      {petDailyDiscountedSubtotal}/day{' '}
                    </Text>
                    <Text
                      variant="heading-16"
                      as="span"
                      color="kale-2"
                      bold
                      className={styles.pricePerDay}
                      id="recipes-recommended-plan-discount"
                    >
                      ({discountPercentage ?? ''}% OFF)
                    </Text>
                  </Text>
                }
              />
            )}
            {shouldShowPriceValue && (
              <div className={styles.whyPriceButtonContainer}>
                <Button
                  variant="plain-text"
                  type="button"
                  onClick={handleOnClickPriceValueModal}
                  className={styles.whyPriceButton}
                >
                  <Text
                    as="span"
                    variant="heading-12"
                    color="carrot-2"
                    className={styles.buttonText}
                  >
                    Why this Price?
                  </Text>
                </Button>
              </div>
            )}
          </div>
          <Divider
            className={styles.divider}
            variant="red-squiggly"
            spacing={0}
            align="start"
          />
          <GridItem alignItems="center" className={styles.mobileRecipeStack}>
            <RecipesStack
              petName={displayPetName}
              foodPlanRecipes={recommendedRecipes}
              useFeature={useFeature}
            />
          </GridItem>
          <div className={styles.planDescriptionContainer}>
            {shouldShowPriceValue && (
              <Text
                variant="heading-16"
                color="charcoal-3"
                as="p"
                bold
                className={styles.recommendedRecipesListForPriceValueVariant}
              >
                {formattedRecipeNames}
                <Button
                  variant="plain-text"
                  type="button"
                  onClick={() => open()}
                  className={styles.seeDetailsButtonContainer}
                >
                  <Text
                    variant="heading-12"
                    color="carrot-2"
                    className={styles.seeDetailsButton}
                  >
                    Nutrition Facts
                  </Text>
                </Button>
              </Text>
            )}
            <div className={styles.planCopyContainer}>
              <Text
                variant="article-16"
                color="charcoal-3"
                as="p"
                className={styles.planDescription}
              >
                {recommendedRecipeCopy}
              </Text>
            </div>
          </div>
          {/* // establish signup header fade element when user scrolls past the CTA */}
          <div ref={recipesContinueRef} className={styles.ctaContainer}>
            <RecommendedPlanCta
              recommendationDisabled={recommendedRecipes.length === 0}
              recommendationLoading={recommendedRecipes.length === 0}
              formSubmitting={formSubmitting}
              onClick={() => onSubmit(quote)}
            />

            <div>
              <Button
                disabled={formSubmitting}
                type="button"
                variant="plain-text"
                onClick={customizePlan}
              >
                <Text
                  bold
                  variant="heading-16"
                  color="carrot-2"
                  className={styles.selectPlanButton}
                >
                  Or Select Your Own Plan
                </Text>
              </Button>
              {hasNonRecommendedLpfRecipes && !lpfRecipeRecommended && (
                <div className={styles.cheapestRecipesInfoContainer}>
                  <AccessibleContent
                    accessibleText={recipesStartingAtAccessibleText}
                    visualContent={
                      <Text
                        variant="heading-12"
                        as="p"
                        className={styles.cheapestRecipesPriceContainer}
                      >
                        <Text
                          variant="heading-12"
                          className={styles.cheapestRecipesText}
                        >
                          Recipes from{' '}
                          {
                            <Strikeout className={styles.strikeoutPrice}>
                              {cheapestLpfPetDailySubtotal}/day
                            </Strikeout>
                          }{' '}
                        </Text>
                        <Text
                          variant="heading-12"
                          color="kale-2"
                          bold={true}
                          className={styles.cheapestRecipesText}
                        >
                          {cheapestPetDailyDiscountedSubtotal}/day
                        </Text>
                      </Text>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </GridItem>
        <GridItem
          md={6}
          lg={7}
          alignItems="center"
          className={styles.recipeStack}
        >
          <RecipesStack
            petName={displayPetName}
            foodPlanRecipes={recommendedRecipes}
            useFeature={useFeature}
          />
        </GridItem>
      </Grid>
      <StickyCta
        discountPercentage={discountPercentage}
        petDailySubtotal={petDailySubtotal}
        petDailyDiscountedSubtotal={petDailyDiscountedSubtotal}
        accessibleText={firstBoxAccessibleText}
        shouldReveal={shouldRevealStickyCta}
        onClick={() => onSubmit(quote)}
        disabled={recommendedRecipes.length === 0 || formSubmitting}
        loading={recommendedRecipes.length === 0 || formSubmitting}
      />
    </>
  );
};
