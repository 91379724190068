import { useMemo } from 'react';
import classNames from 'classnames';

import { useHomepageCtaHeaderCopy } from 'src/hooks/useHomepageCtaHeaderCopy';

import { MetaHeroGrid } from '../MetaHeroGrid';
import { FullScreenImageBackground } from '../FullScreenImageBackground';
import {
  LovedByHumansConfig,
  LifestyleBowlConfig,
  MeatAndVeggiesConfig,
  BlackFridayConfig,
  CyberWeekConfig,
  CtaOnly50OffConfig,
  CtaOnlySeePlansAndPricingConfig,
} from './HeroContent.config';
import styles from './HeroContent.module.css';
import { HomepageHeroImageTreatments } from 'src/abTesting';

interface HeroContentProps {
  variant: HomepageHeroImageTreatments;
  isHeadlineLink: boolean;
  linkToFreshSignup: string;
  isAccessibilityThemeOn: boolean;
}

const DEFAULT_CONFIG = LovedByHumansConfig;

const HERO_CONFIG = {
  [HomepageHeroImageTreatments.Control]: DEFAULT_CONFIG,
  [HomepageHeroImageTreatments.Off]: DEFAULT_CONFIG,
  [HomepageHeroImageTreatments.LovedByHumans]: LovedByHumansConfig,
  [HomepageHeroImageTreatments.LifestyleBowl]: LifestyleBowlConfig,
  [HomepageHeroImageTreatments.MeatAndVeggies]: MeatAndVeggiesConfig,
  [HomepageHeroImageTreatments.BlackFriday]: BlackFridayConfig,
  [HomepageHeroImageTreatments.CyberWeek]: CyberWeekConfig,
  [HomepageHeroImageTreatments.CtaOnly50Off]: CtaOnly50OffConfig,
  [HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing]:
    CtaOnlySeePlansAndPricingConfig,
};

export const HeroContent = ({
  isHeadlineLink,
  linkToFreshSignup,
  isAccessibilityThemeOn,
  variant,
}: HeroContentProps) => {
  const isHomepageCtaHeaderCopyEnabled = useHomepageCtaHeaderCopy();
  const heroConfig = HERO_CONFIG[variant](isHomepageCtaHeaderCopyEnabled);

  const { isLifestyleBowl, isMeatAndVeggies, isBlackFriday, isCyberWeek } =
    useMemo(() => {
      return {
        isLifestyleBowl: variant === HomepageHeroImageTreatments.LifestyleBowl,
        isMeatAndVeggies:
          variant === HomepageHeroImageTreatments.MeatAndVeggies,
        isBlackFriday: variant === HomepageHeroImageTreatments.BlackFriday,
        isCyberWeek: variant === HomepageHeroImageTreatments.CyberWeek,
      };
    }, [variant]);

  return (
    <MetaHeroGrid
      isTextOnBottom
      withRightOverlay
      isHeadlineLink={isHeadlineLink}
      linkToFreshSignup={linkToFreshSignup}
      isAccessibilityThemeOn={isAccessibilityThemeOn}
      headlineText={heroConfig.title}
      subtitle={heroConfig.subtitle}
      ctaString={heroConfig.cta}
      disclaimer={heroConfig.disclaimer}
      desktopColumnWidth={isBlackFriday ? 10 : 8}
      isBlackFriday={isBlackFriday}
      gridItemClassname={classNames(styles.cta, {
        [styles.lifestyleBowlGridItem]: isLifestyleBowl,
        [styles.meatAndVeggiesGridItem]: isMeatAndVeggies,
        [styles.blackFridayGridItem]: isBlackFriday || isCyberWeek,
      })}
      titleClassname={classNames(styles.title, {
        [styles.lifestyleBowlTitle]: isLifestyleBowl,
        [styles.blackFridayTitle]: isBlackFriday || isCyberWeek,
        [styles.cyberWeekTitle]: isCyberWeek,
      })}
      subtitleClassname={classNames(styles.subtitle, {
        [styles.blackFridaySubtitle]: isBlackFriday || isCyberWeek,
      })}
      buttonClassname={classNames(styles.button, {
        [styles.lifestyleBowlButton]: isLifestyleBowl,
        [styles.blackFridayButton]: isBlackFriday,
        [styles.cyberWeekButton]: isCyberWeek,
      })}
      containerClassname={classNames(styles.container, {
        [styles.lifestyleBowlContainer]: isLifestyleBowl,
        [styles.meatAndVeggiesContainer]: isMeatAndVeggies,
        [styles.blackFridayContainer]: isBlackFriday || isCyberWeek,
      })}
      copyContainerClassname={classNames({
        [styles.lifestyleBowlCopyContainer]: isLifestyleBowl,
      })}
    >
      <FullScreenImageBackground
        moveBackgroundLower={heroConfig.lowerBackground}
        sources={heroConfig.assets}
        alt={heroConfig.alt}
      />
    </MetaHeroGrid>
  );
};
