import { useRouteMatch } from 'react-router';
import {
  PATH_HOME,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_PLANS,
  PATH_SIGNUP_CHECKOUT,
  PATH_SIGNUP,
  PATH_SIGNUP_TOSA_LAST_PET_OVERRIDE,
  PATH_SIGNUP_TREATS,
} from '@farmersdog/constants/paths';
import { PreviousLinkNavigationLabel } from '@farmersdog/tosa/src/hooks/usePreviousLinkNavigation';
import { isDiySignup } from '@farmersdog/lead-browser-storage';
import { useTreatsInCheckoutUi } from '@farmersdog/tosa/src/hooks';
import { useFeature } from 'src/abTesting';
import type { UseFeatureHook } from '@farmersdog/tosa/src/types';

interface UseBackButtonNavigationReturn {
  navigatePreviousLinkLabel: string;
  previousSignupRoute: string;
}
export function useBackButtonNavigation(): UseBackButtonNavigationReturn {
  const isMePage = useRouteMatch(PATH_SIGNUP_ME);
  const isPetsPage = useRouteMatch(PATH_SIGNUP_PETS);
  const isRecipesPage = useRouteMatch(PATH_SIGNUP_RECIPES);
  const isPlansPage = useRouteMatch(PATH_SIGNUP_PLANS);
  const isCheckoutPage = useRouteMatch(PATH_SIGNUP_CHECKOUT);

  const { shouldShowTreatsPage } = useTreatsInCheckoutUi({
    useFeature: useFeature as UseFeatureHook,
  });

  if (isMePage) {
    return {
      navigatePreviousLinkLabel: PreviousLinkNavigationLabel.homePage,
      previousSignupRoute: PATH_HOME,
    };
  }
  if (isPetsPage) {
    return {
      navigatePreviousLinkLabel: PreviousLinkNavigationLabel.previousSignupPage,
      previousSignupRoute: PATH_SIGNUP_ME,
    };
  }
  if (isRecipesPage) {
    /**
     * As of 3/2023, fresh recipes page is part of tosa whereas diy recipes page is not.
     * If the back button points to PATH_SIGNUP_PETS without query params,
     * tosa progress sends it to the last completed tosa page (i.e. recipes).
     * Also see https://github.com/farmersdog/beethoven/pull/4512
     *
     * TODO - clean up when diy recipes page is moved to tosa
     */
    if (isDiySignup()) {
      return {
        navigatePreviousLinkLabel:
          PreviousLinkNavigationLabel.previousSignupPage,
        previousSignupRoute: PATH_SIGNUP_TOSA_LAST_PET_OVERRIDE,
      };
    }
    return {
      navigatePreviousLinkLabel: PreviousLinkNavigationLabel.previousSignupPage,
      previousSignupRoute: PATH_SIGNUP_PETS,
    };
  }

  if (isPlansPage || isCheckoutPage) {
    const lastSignupPage = shouldShowTreatsPage
      ? PATH_SIGNUP_TREATS
      : PATH_SIGNUP_RECIPES;

    return {
      navigatePreviousLinkLabel: PreviousLinkNavigationLabel.previousSignupPage,
      previousSignupRoute: lastSignupPage,
    };
  }

  // default just return to /signup where TOSA routing should take over or go to /me page in v1
  return {
    navigatePreviousLinkLabel: PreviousLinkNavigationLabel.previousSignupPage,
    previousSignupRoute: PATH_SIGNUP,
  };
}
