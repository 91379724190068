import { removePetByPosition } from '../utils';

import { useLeadState } from './useLeadState';

import type { Position, Node } from '../blueprint/types';
import type { FormFieldsType, TOSAComponentInput } from '../types';

interface RemovePetArgs {
  position: Position;
  componentProps: TOSAComponentInput<Node>;
}

export function useRemovePet() {
  const { convertFormStateToLeadInput, writeLead } = useLeadState();

  const removePet = async ({ position, componentProps }: RemovePetArgs) => {
    const { formMethods, formValidationSchema, reloadApp } = componentProps;
    const prevFormValues = formMethods.getValues();

    const [nextFormValues, fieldsToRemove] = removePetByPosition({
      position,
      formValues: formValidationSchema.cast(prevFormValues, {
        assert: 'ignore-optionality',
      }) as FormFieldsType,
    });

    if (!fieldsToRemove.length) {
      return;
    }

    const leadInput = convertFormStateToLeadInput({
      formValidationSchema,
      formData: nextFormValues,
    });

    await writeLead({ lead: leadInput });

    reloadApp();
  };

  return { removePet };
}
