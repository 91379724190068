import type { ReactNode } from 'react';
import { SplitProvider } from '@farmersdog/ab-testing';
import { anonymousFeatures, userFeatures } from './features';
import { splitConfig } from './config';
import { anonymousId } from '@farmersdog/lead-browser-storage';
import config from '../../../config/config';
import type { SplitImpressionListener } from '@farmersdog/ab-testing/src/utils';
import { getTokenFromCookie, getUserIdFromToken } from '../../../utils';

interface PawSplitProviderProps {
  children: ReactNode;
  impressionListener?: SplitImpressionListener;
  userFeatures?: Partial<typeof userFeatures>;
  anonymousFeatures?: Partial<typeof anonymousFeatures>;
  userId?: string;
  anonymousId?: string;
}

export function PawSplitProvider(props: PawSplitProviderProps) {
  const anonymous = anonymousId.get();
  const userId = getUserIdFromToken(getTokenFromCookie());
  return (
    <SplitProvider<object, object, Record<string, unknown>>
      userFeatures={props.userFeatures || userFeatures}
      anonymousFeatures={props.anonymousFeatures || anonymousFeatures}
      splitConfig={splitConfig}
      anonymousId={props.anonymousId || anonymous}
      userId={props.userId || userId}
      allowOverride={config.get('splitio.override')}
      trackImpression={props.impressionListener}
    >
      {props.children}
    </SplitProvider>
  );
}
