import classNames from 'classnames';

import { paths } from '@farmersdog/constants';
import type { LinkProps } from '@farmersdog/corgi-x';
import { Link, Text, PageWidth, useViewport } from '@farmersdog/corgi-x';

import styles from './LegalDetails.module.css';
import { LINK_PRIVACY_OPTOUT } from '@farmersdog/constants/links';

interface Link {
  label: string;
  props: LinkProps;
}

export const links: Link[] = [
  {
    label: 'Privacy',
    props: {
      to: paths.PATH_PRIVACY,
    },
  },
  {
    label: 'Terms',
    props: {
      to: paths.PATH_TERMS,
    },
  },
  {
    label: 'Accessibility',
    props: {
      to: paths.PATH_ACCESSIBILITY,
    },
  },
  {
    label: 'Do Not Sell My Personal Information',
    props: {
      href: LINK_PRIVACY_OPTOUT,
    },
  },
];

interface LegalDetailsProps {
  mode?: 'light' | 'dark';
}

export function LegalDetails(props: LegalDetailsProps) {
  const mode = props?.mode ? props?.mode : 'dark';
  const color = mode === 'light' ? 'white' : 'kale-3';

  const { large: isLarge } = useViewport();

  const containerClassName = classNames(
    { [styles.light]: mode === 'light' },
    styles.container,
    isLarge ? styles.lg : styles.xs
  );

  return (
    <PageWidth>
      <div className={containerClassName}>
        <div className={styles.copyright}>
          <Text
            variant="heading-16"
            color={color}
            className={styles.copyrightText}
          >
            © {new Date().getFullYear()} The Farmer’s Dog, Inc.
          </Text>
          {!isLarge && <br />}
          <Text
            variant="heading-16"
            color={color}
            className={styles.longLiveDogs}
          >
            Long Live Dogs™
          </Text>
        </div>
        <ul aria-label="Legal details" className={styles.legalDetails}>
          {links.map(link => (
            <Text
              key={link.label}
              className={styles.link}
              variant="heading-16"
              as="li"
            >
              <Link {...link.props} baseColor="white">
                {link.label}
              </Link>
            </Text>
          ))}
        </ul>
      </div>
    </PageWidth>
  );
}
