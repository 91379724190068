import { Text } from '@farmersdog/corgi-x';
import Annotation from '../../../../assets/annotation.svg';

import CyberWeekDesktopAnnotation from '../../../../assets/cyber-week-desktop-annotation.svg';
import CyberWeekMobileAnnotation from '../../../../assets/cyber-week-mobile-annotation.svg';

import classNames from 'classnames';

import {
  mobileLovedByHumans,
  desktopLovedByHumans,
} from '../../../../../AssetPreloader/assets/loved-by-humans';

import {
  mobileLifestyleBowl,
  desktopLifestyleBowl,
} from '../../../../../AssetPreloader/assets/lifestyle-bowl';
import {
  desktopMeatAndVeggies,
  mobileMeatAndVeggies,
} from '../../../../../AssetPreloader/assets/meat-and-veggies';
import {
  desktopBlackFriday,
  mobileBlackFriday,
} from '../../../../../AssetPreloader/assets/black-friday';

import type { MobileAndDesktopSources } from '../../../../../AssetPreloader/assets/shared';

import styles from './HeroContent.module.css';

interface HeroConfigReturn {
  title: JSX.Element;
  subtitle: JSX.Element;
  assets: MobileAndDesktopSources;
  alt: string;
  lowerBackground: boolean;
  cta: string;
  disclaimer?: string;
}

const lovedByHumansTitleCtaHeaderEnabled = (
  <>
    Real food.
    <br />
    Made fresh.
    <br />
    Delivered.
  </>
);

const lovedByHumansTitleCtaHeaderDisabled = (
  <>
    Supported by vets.
    <br />
    Loved by dog people.
  </>
);

const lovedByHumansSubtitleCtaHeaderEnabled = (
  <>
    Recommended by vets and formulated
    <br />
    by board-certified nutritionists.
  </>
);

const lovedByHumansSubtitleCtaHeaderDisabled = (
  <>
    Fresh food formulated by
    <br />
    board-certified nutritionists.
  </>
);

const lovedByHumansCopy = (isHomepageCtaHeaderCopyEnabled: boolean) =>
  isHomepageCtaHeaderCopyEnabled
    ? {
        title: lovedByHumansTitleCtaHeaderEnabled,
        subtitle: lovedByHumansSubtitleCtaHeaderEnabled,
      }
    : {
        title: lovedByHumansTitleCtaHeaderDisabled,
        subtitle: lovedByHumansSubtitleCtaHeaderDisabled,
      };

export const LovedByHumansConfig = (
  isHomepageCtaHeaderCopyEnabled: boolean
): HeroConfigReturn => {
  const copy = lovedByHumansCopy(isHomepageCtaHeaderCopyEnabled);

  return {
    title: copy.title,
    subtitle: copy.subtitle,
    assets: {
      mobileSources: mobileLovedByHumans,
      desktopSources: desktopLovedByHumans,
    },
    alt: 'Dog looking at a bowl of food',
    lowerBackground: true,
    cta: 'Shop Fresh',
  };
};

// ########################################################

const lifestyleBowlTitle = (
  <>
    Real food.
    <br />
    Made fresh.
    <br />
    Delivered.
  </>
);

const lifestyleBowlSubtitle = (
  <>
    Vet-approved fresh food made
    <br />
    with real meat and veggies.
  </>
);

export const LifestyleBowlConfig = (): HeroConfigReturn => ({
  title: lifestyleBowlTitle,
  subtitle: lifestyleBowlSubtitle,
  assets: {
    mobileSources: mobileLifestyleBowl,
    desktopSources: desktopLifestyleBowl,
  },
  alt: 'Bowl of food',
  lowerBackground: false,
  cta: 'Shop Fresh',
});

const meatAndVeggiesTitle = (
  <>
    Meat and
    <br className={styles.mobileBreakline} /> veggies
    <br className={styles.desktopBreakline} /> you
    <br className={styles.mobileBreakline} /> can see
  </>
);

const meatAndVeggiesSubtitle = (
  <>
    Dog food should be food.
    <br />
    Not burnt brown balls.
  </>
);

export const MeatAndVeggiesConfig = (): HeroConfigReturn => ({
  title: meatAndVeggiesTitle,
  subtitle: meatAndVeggiesSubtitle,
  assets: {
    mobileSources: mobileMeatAndVeggies,
    desktopSources: desktopMeatAndVeggies,
  },
  alt: 'Dogs snout close to a bowl of food',
  lowerBackground: false,
  cta: 'Shop Fresh',
});

const blackFridayTitle = <>It’s Black Friday!</>;

const blackFridaySubtitle = (
  <>
    First <br className={styles.mobileBreakline} />
    Box <br className={styles.mobileBreakline} />
    <span className={styles.annotationWrapper}>
      <Annotation className={styles.annotation} />
      FREE
    </span>
  </>
);

export const BlackFridayConfig = (): HeroConfigReturn => ({
  title: blackFridayTitle,
  subtitle: blackFridaySubtitle,
  assets: {
    mobileSources: mobileBlackFriday,
    desktopSources: desktopBlackFriday,
  },
  alt: 'Dog and bowl of food',
  lowerBackground: false,
  cta: 'Get 100% Off',
  disclaimer: 'While supplies last. Terms apply.',
});

const cyberWeekTitle = <>It’s Cyber Week!</>;

const cyberWeekSubtitle = (
  <>
    First <br className={styles.mobileBreakline} />
    Box <br />
    <div
      className={classNames(
        styles.annotationWrapper,
        styles.percentageOffMobile
      )}
    >
      <CyberWeekMobileAnnotation className={styles.cyberWeekMobileAnnotation} />
      <Text as="div" variant="heading-72" className={styles.fifty} bold>
        50
      </Text>
      <div className={styles.percentageOffMobileWrapper}>
        <Text
          as="div"
          className={styles.cyberWeekPercentageOff}
          variant="heading-72"
          bold
          align={'left'}
        >
          %
        </Text>
        <Text
          as="div"
          className={styles.off}
          variant="heading-28"
          bold
          align={'left'}
        >
          OFF
        </Text>
      </div>
    </div>
    <div
      className={classNames(
        styles.annotationWrapper,
        styles.percentageOffDesktop
      )}
    >
      <CyberWeekDesktopAnnotation className={styles.cyberWeekAnnotation} />
      50% Off
    </div>
  </>
);

export const CyberWeekConfig = (): HeroConfigReturn => ({
  title: cyberWeekTitle,
  subtitle: cyberWeekSubtitle,
  assets: {
    mobileSources: mobileBlackFriday,
    desktopSources: desktopBlackFriday,
  },
  alt: 'Dog and bowl of food',
  lowerBackground: false,
  cta: 'Get 50% Off',
});

export const CtaOnly50OffConfig = (
  isHomepageCtaHeaderCopyEnabled: boolean
): HeroConfigReturn => ({
  ...LovedByHumansConfig(isHomepageCtaHeaderCopyEnabled),
  cta: 'Get 50% Off',
});

export const CtaOnlySeePlansAndPricingConfig = (
  isHomepageCtaHeaderCopyEnabled: boolean
): HeroConfigReturn => ({
  ...LovedByHumansConfig(isHomepageCtaHeaderCopyEnabled),
  cta: 'See Plans & Pricing',
});
