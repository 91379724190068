import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { ApplicationError } from '../../../errors';
import type { FetchPacksAndPortionsQuery } from './FetchPacksAndPortions.core-api.generated-types';
import fetchPacksAndPortionsQuery from './FetchPacksAndPortions.core-api.graphql';

export interface UseFetchPacksAndPortions {
  loading: boolean;
  error?: ApolloError | ApplicationError;
  data?: FetchPacksAndPortionsQuery;
}

interface UseFetchPacksAndPortionsProps {
  onError: (error: unknown) => void;
}

export function useFetchPacksAndPortions(props: UseFetchPacksAndPortionsProps) {
  return useQuery<FetchPacksAndPortionsQuery>(fetchPacksAndPortionsQuery, {
    context: { endpoint: 'core-api' },
    onError: props.onError,
  });
}
