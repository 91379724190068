import type { Maybe } from 'graphql/jsutils/Maybe';
import type { UseFeature } from '../../../../experiments/ExperimentsContext';

// Experiment: New Picky Day 1 Experience
export const SplitKey = 'onb_homepage_personalization_picky';

export enum Treatment {
  /* Variant 1 */
  PickyNudge = 'picky_nudge',
  /* Variant 2 */
  PickyNudgeWithProgression = 'picky_nudge_with_progression',
  /* Control */
  Off = 'off',
}

export const Feature: FeatureType = {
  treatments: Object.values(Treatment),
  track: true,
  config: null,
  mock: {
    treatment: Treatment.Off,
    config: null,
  },
};

export interface Attributes extends SplitIO.Attributes {
  isEligible: boolean;
  activatedAt: number;
}

export type Config = object;

export interface ExperimentAttributes {
  hasOverweightPet: boolean;
  hasPickyEater: boolean;
  regularOrdersDeliveredCount: number;
}

export interface FeatureType {
  treatments: Treatment[];
  config?: Maybe<Config>;
  track: boolean;
  mock?: {
    treatment: Treatment;
    config?: Maybe<Config>;
  };
}

export type UseFeatureType = UseFeature<Treatment, Config, Attributes>;
