import isEqual from 'lodash/isEqual';
import { useMemo, useState } from 'react';

import {
  AccessibleContent,
  Button,
  Divider,
  GridItem,
  Strikeout,
  Text,
  useHtmlElement,
  useIntersection,
  useViewport,
} from '@farmersdog/corgi-x';
import { usePreviousEffect } from '@farmersdog/utils';

import { LightboxId, useGlobalLightbox } from '../../../../hooks';
import { useFetchQuote } from '../../../../hooks/useFetchQuote';
import { convertToPetInputsForQuote } from '../../../../utils/convertToPetInputForQuote/convertToPetInputForQuote';
import { formatCommaSeparatedList } from '../../../../utils/formatCommaSeparatedList';
import { HiddenPreSelectedRecommendation } from '../../HiddenPreSelectedRecommendation';
import { useCheapestQuotes } from '../../hooks/useCheapestQuotes';
import { RecipesStack } from '../../RecipesStack';
import { getQuoteData } from '../../utils/getQuoteData';
import { RecommendedPlanCta } from '../components/cta';
import { StickyCta } from '../components/cta/StickyCta';
import {
  getFirstBoxAccessibleText,
  getRecipesStartingAtAccessibleText,
} from '../utils';

import styles from './PersonalizeRecommendedPlan.module.css';

import type { FetchQuoteQuery } from '../../../../graphql/types';
import type {
  FormFieldsType,
  PetRecipes,
  UseForm,
  UseFeatureHook,
} from '../../../../types';
import type { RecommendedRecipe } from '../../types';
import type * as yup from 'yup';

interface PersonalizeRecommendedPlanProps {
  useFeature: UseFeatureHook;
  hasNonRecommendedLpfRecipes: boolean;
  lpfRecipeRecommended: boolean;
  recommendedRecipes: RecommendedRecipe[];
  customizePlan: () => void;
  registerProps: ReturnType<UseForm['register']>;
  onSubmit: (quote: FetchQuoteQuery['fetchQuote'] | null) => void;
  formSubmitting: boolean;
  petName: string;
  formValues: FormFieldsType;
  formValidationSchema: yup.AnySchema;
  petRecipes: PetRecipes;
  recommendationIsValid: boolean;
  recommendedRecipeCopy: string | JSX.Element;
}

export const PersonalizeRecommendedPlan = ({
  useFeature,
  hasNonRecommendedLpfRecipes,
  lpfRecipeRecommended,
  recommendedRecipes,
  customizePlan,
  registerProps,
  onSubmit,
  formSubmitting,
  petName,
  formValues,
  formValidationSchema,
  petRecipes,
  recommendationIsValid,
  recommendedRecipeCopy,
}: PersonalizeRecommendedPlanProps) => {
  const { lg, xs } = useViewport();
  const isMobile = xs && !lg;

  const [hasRecommendationChanged, setHasRecommendationChanged] =
    useState(false);
  const petsInput = useMemo(() => {
    return recommendationIsValid
      ? convertToPetInputsForQuote({
          data: formValidationSchema.cast(formValues, {
            assert: 'ignore-optionality',
          }) as FormFieldsType,
          isCustomizingRecipes: false,
          currentPetName: petName,
          petRecipes,
        })
      : null;
  }, [
    recommendationIsValid,
    petName,
    petRecipes,
    formValues,
    formValidationSchema,
  ]);

  usePreviousEffect(
    (prevRecommendation, nextRecommendation) => {
      const hasChanged = !isEqual(
        [...prevRecommendation].sort(),
        [...nextRecommendation].sort()
      );
      setHasRecommendationChanged(hasChanged);
    },
    recommendedRecipes.filter(r => r.recommended).map(r => r.name)
  );

  const { cheapestLpfQuote } = useCheapestQuotes({
    currentPetName: petName,
    petRecipes,
    petsInput,
  });

  const { quote } = useFetchQuote({
    currentPetName: petName,
    shouldFetchQuote: hasRecommendationChanged && recommendationIsValid,
    petsInput,
  });

  const {
    petDailySubtotal: cheapestLpfPetDailySubtotal,
    petDailyDiscountedSubtotal: cheapestPetDailyDiscountedSubtotal,
  } = getQuoteData({
    quote: cheapestLpfQuote,
    currentPetName: petName,
  });

  const { petDailySubtotal, petDailyDiscountedSubtotal, discountPercentage } =
    getQuoteData({
      quote,
      currentPetName: petName,
    });

  const displayPetName = petName ?? 'Your Dog';
  const recommendedRecipeDisplayNames = recommendedRecipes.map(
    recipe => recipe.displayName
  );

  const formattedRecipeNames = formatCommaSeparatedList(
    recommendedRecipeDisplayNames
  );

  const { open } = useGlobalLightbox({
    id: LightboxId.MultiRecipeDetailModal,
  });

  const firstBoxAccessibleText = getFirstBoxAccessibleText({
    petDailySubtotal,
    petDailyDiscountedSubtotal,
  });

  const recipesStartingAtAccessibleText = getRecipesStartingAtAccessibleText({
    cheapestLpfPetDailySubtotal,
    cheapestPetDailyDiscountedSubtotal,
  });

  const [recipesContinueElement, recipesContinueRef] = useHtmlElement();

  const ctaButtonIntersection = useIntersection(recipesContinueElement);

  const shouldRevealStickyCta = Boolean(
    ctaButtonIntersection &&
      !ctaButtonIntersection.isIntersecting &&
      ctaButtonIntersection.rootBounds &&
      isMobile
  );

  const FirstBoxSection = () => (
    <>
      <Text
        bold
        as="p"
        color="charcoal-3"
        variant="heading-16"
        className={styles.pricePerDay}
        topSpacing="none"
        bottomSpacing="sm"
      >
        First Box:{' '}
        <Text variant="heading-16" className={styles.pricePerDay}>
          <Strikeout className={styles.strikeoutPrice}>
            {petDailySubtotal}/day
          </Strikeout>
        </Text>{' '}
        <Text
          bold
          as="span"
          color="kale-2"
          variant="heading-16"
          className={styles.pricePerDay}
        >
          {petDailyDiscountedSubtotal}/day{' '}
        </Text>
        <Text
          bold
          as="span"
          color="kale-2"
          variant="heading-16"
          className={styles.pricePerDay}
          id="recipes-personalize-recommended-plan-discount"
        >
          ({discountPercentage ?? ''}% OFF)
        </Text>
      </Text>
    </>
  );

  const RecommendedRecipe = () => {
    return (
      <Text
        as="p"
        color="charcoal-3"
        variant="article-16"
        topSpacing={{ xs: 'none', lg: 'sm' }}
        bottomSpacing="sm"
        className={styles.planDescription}
      >
        {recommendedRecipeCopy}
      </Text>
    );
  };

  return (
    <>
      <div className={styles.infoContainer}>
        <div>
          <Text
            variant="heading-28"
            bold
            color="kale-3"
            as="h1"
            bottomSpacing="none"
            className={styles.recipePageTitle}
          >
            <span>
              Our plan for {isMobile ? <br /> : ' '}
              {displayPetName}
            </span>
          </Text>
          {quote && isMobile && <FirstBoxSection />}
          {isMobile && <RecommendedRecipe />}
        </div>
        <div>
          <div className={styles.hidden}>
            {recommendedRecipes.map(recipe => {
              return (
                <HiddenPreSelectedRecommendation
                  key={recipe.name}
                  recipe={recipe}
                  registerProps={registerProps}
                />
              );
            })}
          </div>
          {quote && !isMobile && <FirstBoxSection />}
        </div>
        <Divider
          spacing={0}
          align="start"
          variant="red-squiggly"
          className={styles.divider}
        />

        <GridItem alignItems="center" className={styles.mobileRecipeStack}>
          <RecipesStack
            petName={displayPetName}
            foodPlanRecipes={recommendedRecipes}
            useFeature={useFeature}
          />
        </GridItem>

        <div className={styles.planDescriptionContainer}>
          <div className={styles.planCopyContainer}>
            {!isMobile && <RecommendedRecipe />}
            <div className={styles.recommendedRecipesListContainer}>
              <Text
                as="p"
                color="charcoal-3"
                bottomSpacing="none"
                variant="heading-16"
                topSpacing="none"
                className={styles.recommendedRecipesList}
              >
                <strong>{formattedRecipeNames}</strong>
                <Button
                  type="button"
                  variant="plain-text"
                  onClick={() => open()}
                  className={styles.seeDetailsButtonContainer}
                >
                  <Text
                    color="carrot-2"
                    variant="heading-12"
                    className={styles.seeDetailsButton}
                  >
                    Nutrition Facts
                  </Text>
                </Button>
              </Text>
            </div>
          </div>
        </div>

        <div className={styles.ctaContainer}>
          <RecommendedPlanCta
            recommendationDisabled={recommendedRecipes.length === 0}
            recommendationLoading={recommendedRecipes.length === 0}
            formSubmitting={formSubmitting}
            onClick={() => onSubmit(quote)}
          />
          <div>
            <div ref={recipesContinueRef} />
            <Button
              disabled={formSubmitting}
              type="button"
              variant="plain-text"
              onClick={customizePlan}
            >
              <Text
                bold={!isMobile}
                variant="heading-16"
                color="carrot-2"
                className={styles.selectRecipesButton}
              >
                Or Select Your Recipes
              </Text>
            </Button>
            {hasNonRecommendedLpfRecipes && !lpfRecipeRecommended && (
              <div className={styles.cheapestRecipesInfoContainer}>
                <AccessibleContent
                  accessibleText={recipesStartingAtAccessibleText}
                  visualContent={
                    <Text
                      variant="heading-12"
                      as="p"
                      className={styles.cheapestRecipesPriceContainer}
                    >
                      <Text
                        variant="heading-12"
                        className={styles.cheapestRecipesText}
                      >
                        Recipes from{' '}
                        {
                          <Strikeout className={styles.strikeoutPrice}>
                            {cheapestLpfPetDailySubtotal}/day
                          </Strikeout>
                        }{' '}
                      </Text>
                      <Text
                        variant="heading-12"
                        color="kale-2"
                        bold={true}
                        className={styles.cheapestRecipesText}
                      >
                        {cheapestPetDailyDiscountedSubtotal}/day
                      </Text>
                    </Text>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <StickyCta
          onClick={() => onSubmit(quote)}
          petDailySubtotal={petDailySubtotal}
          shouldReveal={shouldRevealStickyCta}
          accessibleText={firstBoxAccessibleText}
          discountPercentage={discountPercentage}
          petDailyDiscountedSubtotal={petDailyDiscountedSubtotal}
          loading={recommendedRecipes.length === 0 || formSubmitting}
          disabled={recommendedRecipes.length === 0 || formSubmitting}
        />
      )}
    </>
  );
};
