import events from '../eventNames';
import track from '../track';
import type {
  MyPetView,
  SubscriptionSuspensionReason,
} from 'src/graphql/types';

export type TrackCancelDiySubscriptionInput = {
  userId: number;
  cancellationReason: SubscriptionSuspensionReason;
  newFood: string | undefined;
  desiredPrice: string | undefined;
  cadence: number;
  quantity: number;
  activePets: Pick<MyPetView, 'name'>[];
};

export function trackCancelDiySubscription({
  userId,
  cancellationReason,
  newFood,
  desiredPrice,
  cadence,
  quantity,
  activePets,
}: TrackCancelDiySubscriptionInput) {
  track(events.cancel_diy_subscription_success, {
    userId,
    cancellationReason,
    newFood,
    desiredPrice,
    cadence,
    quantity,
    activePets: activePets.map(pet => pet.name),
  });
}
