import { RecipeNames } from '@farmersdog/constants';

import type { PetInputForQuote } from '../../../graphql/types';
import type { PetRecipes } from '../../../types';

export enum FRESH_PRODUCT_LINES {
  core = 'core',
  lpf = 'lpf',
}

export const CHEAPEST_RECIPES = [
  {
    name: RecipeNames.BEEF,
    productLine: FRESH_PRODUCT_LINES.core,
  },

  {
    name: RecipeNames.CHICKEN_OATS,
    productLine: FRESH_PRODUCT_LINES.lpf,
  },
];

export interface OverwriteWithCheapestSelectionParams {
  petRecipes: PetRecipes;
  currentPetName: string;
  petsInput: Omit<PetInputForQuote, 'requestedCalories'>[] | null;
}

export interface OverwriteWithCheapestSelectionReturn {
  pets: PetInputForQuote[] | null;
  productLine: FRESH_PRODUCT_LINES | null;
}

export const overwriteWithCheapestSelection = ({
  petRecipes,
  currentPetName,
  petsInput,
}: OverwriteWithCheapestSelectionParams):
  | OverwriteWithCheapestSelectionReturn[]
  | null => {
  const currentRecipes = petRecipes ? petRecipes[currentPetName] : [];

  if (currentRecipes.length > 0) {
    const shouldShowCheapestQuotes = currentRecipes.some(
      recipe => recipe.content?.productLine === FRESH_PRODUCT_LINES.lpf
    );

    const cheapestRecipes = shouldShowCheapestQuotes ? CHEAPEST_RECIPES : null;

    const cheapestRecipesSelection =
      cheapestRecipes &&
      cheapestRecipes.map(recipe => {
        const persistCheapestSelection = petsInput?.map(petInput =>
          petInput.name === currentPetName
            ? {
                ...petInput,
                selection: {
                  ...petInput?.selection,
                  fresh: {
                    ...petInput?.selection.fresh,
                    options: {
                      recipes: [recipe?.name],
                    },
                  },
                },
              }
            : petInput
        );

        const petsInputWithProductLine = {
          pets: persistCheapestSelection,
          productLine: recipe?.productLine || null,
        } as OverwriteWithCheapestSelectionReturn;

        return petsInputWithProductLine;
      });
    return cheapestRecipesSelection;
  }
  return null;
};
