import { useState, useCallback, useEffect, useMemo, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';
import { useViewport } from '@farmersdog/corgi-x';

import {
  trackSignupFreeTreatAddTreatToCart,
  trackSignupFreeTreatsRemovedFromCart,
  trackSignupFreeTreatsSkipped,
  trackSignupFreeTreatsSuccess,
} from '../../analytics';
import { useFetchAvailableProducts } from '../../graphql/queries';

import { Hero, Cta, HumanGradeTreats, FAQ } from './components';
import styles from './SignupTreatsPage.module.css';

import type { Treat, TreatInput } from '../../graphql/types';
import type { TOSAComponentInput, TOSALeafNode } from '../../types';

export function SignupTreatsPage({
  formMethods,
  formSubmitRequest,
}: TOSAComponentInput<TOSALeafNode>) {
  const viewport = useViewport();
  const {
    data: availableProductsData,
    loading: isFetchAvailableProductsLoading,
  } = useFetchAvailableProducts();
  const availableTreats = useMemo(
    () => availableProductsData?.availableProducts?.treats ?? [],
    [availableProductsData?.availableProducts?.treats]
  );
  const [selectedTreat, setSelectedTreat] = useState<Treat | null>(null);
  const [currentlyLoadingButton, setCurrentlyLoadingButton] = useState<
    'primary' | 'secondary'
  >();

  // Initialize the treats a single time upon landing on the page;
  // if treats are initially selected we use a different UI structure for that visit to the treats page
  const initialAddedTreats = useRef(
    formMethods.getValues(NodeNames.Treats)
  ).current;

  useEffect(() => {
    if (!selectedTreat && availableTreats.length === 1) {
      setSelectedTreat(availableTreats[0]);
    }
  }, [availableTreats, selectedTreat]);

  const isLoading =
    isFetchAvailableProductsLoading || formSubmitRequest.loading;

  const handleAddTreat = useCallback(() => {
    if (isLoading || !selectedTreat) {
      return;
    }

    const treatWithQuantity: TreatInput = {
      name: selectedTreat.name,
      size: selectedTreat.size,
      quantity: 1, // hardcoded to only allow one treat bag at checkout
    };

    formMethods.setValue(NodeNames.Treats, [treatWithQuantity]);
    setCurrentlyLoadingButton('primary');
    trackSignupFreeTreatAddTreatToCart(selectedTreat);
    trackSignupFreeTreatsSuccess();
  }, [isLoading, selectedTreat, formMethods]);

  const onSkip = useCallback(() => {
    if (isLoading) {
      return;
    }

    formMethods.setValue(NodeNames.Treats, []);
    setCurrentlyLoadingButton('secondary');

    const currentTreat = initialAddedTreats?.[0];
    if (currentTreat) {
      trackSignupFreeTreatsRemovedFromCart(currentTreat);
    } else {
      trackSignupFreeTreatsSkipped();
    }

    trackSignupFreeTreatsSuccess();
  }, [isLoading, formMethods, initialAddedTreats]);

  const ctaElement = useMemo(
    () => (
      <Cta
        areAnyAddedTreats={!!initialAddedTreats?.length}
        onAddToPlan={handleAddTreat}
        onSkip={onSkip}
        currentlyLoadingButton={currentlyLoadingButton}
      />
    ),
    [currentlyLoadingButton, handleAddTreat, onSkip, initialAddedTreats]
  );

  return (
    <div className={styles.container}>
      <Hero
        availableTreats={availableTreats}
        selectedTreat={selectedTreat}
        onSelectTreat={setSelectedTreat}
        ctaElement={ctaElement}
        alreadyAddedTreats={initialAddedTreats ?? []}
      />
      <HumanGradeTreats />
      <FAQ />
      {!viewport.md && ctaElement}
    </div>
  );
}
