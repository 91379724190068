import { useState, useEffect } from 'react';
import { useIntersection, useHtmlElement } from '@farmersdog/corgi';

import type { ViewportProps } from 'src/screen';
import { useCorgiViewport } from 'src/screen';
import {
  type NativeWistiaOptions,
  type NativeWistiaPlayer,
  VideoSection,
} from '@farmersdog/corgi-x';

const defaultOptions: NativeWistiaOptions = {
  autoPlay: false,
  playsinline: true,
  popover: false,
  controlsVisibleOnLoad: false,
  endVideoBehavior: 'loop',
  fullscreenButton: false,
  playbackRateControl: false,
  playbar: false,
  playButton: false,
  qualityControl: false,
  settingsControl: false,
  smallPlayButton: false,
  volumeControl: false,
  videoFoam: true,
};

const observerOptions = {
  rootMargin: '0px',
  threshold: 0,
};

export interface ScrollVideoProps {
  videoIds: ViewportProps<string>;
  options?: NativeWistiaOptions;
}

export function ScrollVideo({ videoIds, options }: ScrollVideoProps) {
  const videoId = useCorgiViewport(videoIds);
  const [video, setVideo] = useState<NativeWistiaPlayer>();
  const [videoEl, videoElRef] = useHtmlElement();
  const intersection = useIntersection(videoEl, observerOptions);

  useEffect(() => {
    if (!video || !intersection) {
      return;
    }
    if (intersection.isIntersecting) {
      video.play();
    } else if (!intersection.isIntersecting) {
      video.pause();
      video.time(0);
    }
  }, [intersection, video]);

  const handleReady = (videoApi: NativeWistiaPlayer) => {
    // Videos that have autoplay triggered by scroll or other user actions
    // should not have volume
    videoApi.mute();
    setVideo(videoApi);
  };

  return (
    <VideoSection
      ref={videoElRef}
      onReady={handleReady}
      hideControls
      wistiaOptions={{ ...defaultOptions, ...options }}
      wistiaId={videoId}
    />
  );
}
