import type { FAQSection } from './content';
import { sections } from './content';
import type { PageSectionProps } from '../../DIY';

import Details from 'src/components/Details';

import styles from './FAQ.module.css';

import { Divider, GridContainer, Text } from '@farmersdog/corgi-x';

import CXContactInfo from 'src/components/CXContactInfo';
import { EMAIL_DIY_HELP } from 'src/constants/emails';
import { DIY_FAQ } from './constants/ids';

interface QuestionsProps {
  questions: FAQSection['questions'];
}

function Questions({ questions }: QuestionsProps) {
  return (
    <>
      {questions.map(({ text, answer }, index) => {
        return (
          <Details summary={text} key={index}>
            <Text variant="article-16" as="div">
              {answer}
            </Text>
          </Details>
        );
      })}
    </>
  );
}

interface SectionProps {
  section: FAQSection;
}

function Section({ section }: SectionProps) {
  const { title, questions } = section;

  return (
    <>
      <Text variant="heading-28" key={title} as="h3" color="kale-3" bold>
        {title}
      </Text>
      <Questions questions={questions} />
    </>
  );
}

export function FAQ({ className }: PageSectionProps) {
  return (
    <section className={className} id={DIY_FAQ}>
      <GridContainer>
        <div className={styles.headingContainer}>
          <Text variant="heading-40" as="h2" color="kale-3" bold>
            Frequently Asked Questions
          </Text>
        </div>
        {sections && (
          <div className={styles.questionContainer}>
            {sections.map(section => {
              return <Section section={section} key={section.title} />;
            })}
            <Divider spacing={48} width={1} color="Oyster1" />
            <Text variant="heading-22" bold as="h3" color="kale-3">
              Can’t find what you are looking for? Reach out:
            </Text>
            <CXContactInfo
              showPhone={false}
              showEmail={true}
              email={EMAIL_DIY_HELP}
            />
          </div>
        )}
      </GridContainer>
    </section>
  );
}
