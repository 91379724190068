import { hashSha } from '@farmersdog/utils';

import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import type { TikTokHashedData } from './TikTokScript';

export interface TikTokData {
  email?: string;
  // NOTE: event_id used for deduplicating web pixel events with segment events
  event_id?: string;
}

async function _mountTikTokEvent(
  window: Window,
  event: string,
  data: TikTokData = {}
) {
  if (typeof window.ttq !== 'undefined') {
    try {
      const hashedData = await hashTikTokData(data);
      window.ttq.identify(hashedData);
      window.ttq.track(event, {
        event_id: data.event_id,
        // hardcoded event data for every event type
        content_type: 'product',
        content_id: 'fresh_food',
      });
    } catch {
      window.ttq.track(event, {
        event_id: data.event_id,
        content_type: 'product',
        content_id: 'fresh_food',
      });
    }
  }
}

export const mountTikTokEvent = mountVendorWindowEvent(
  'TikTok',
  _mountTikTokEvent
);

async function hashTikTokData(data: TikTokData): Promise<TikTokHashedData> {
  const hashedData: TikTokHashedData = {};

  if (data.email) {
    hashedData.sha256_email = await hashSha({
      stringToHash: data.email,
      algorithm: 'SHA-256',
    });
  }

  return hashedData;
}
