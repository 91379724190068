import { AdditionalTerms } from 'src/components/AdditionalTerms';

export function OfferTerms() {
  return (
    <AdditionalTerms title="Offer Terms">
      <p>
        Offer valid from November 28th, 2024 4:00pm ET while supplies last
        online at thefarmersdog.com in the contiguous 48 United States only.
        Limit one per household; offer applicable to new customers only with an
        active automatic recurring subscription service. Pause, reschedule, or
        cancel anytime. Offer applies to The Farmer’s Dog fresh food products
        only, not to applicable taxes, shipping & handling charges. Offer cannot
        be combined with any other offers, credits or discounts. Offer is
        non-transferable and not valid for cash or cash equivalent. No
        adjustments on previous purchases. The Farmer’s Dog reserves the right
        to modify, terminate or cancel the offer at any time. For inquiries,
        contact care@thefarmersdog.com.
      </p>
    </AdditionalTerms>
  );
}
