import {
  Grid,
  GridItem,
  Input,
  visuallyHidden,
  Text,
} from '@farmersdog/corgi-x';
import { AddDogContent } from '../AddDogContent';
import type { AddDogFormChangeHandler, UseAddDogFormFields } from '../../hooks';

interface WeightSceneProps {
  name: string;
  currentWeight: UseAddDogFormFields['currentWeight'];
  targetWeight?: UseAddDogFormFields['targetWeight'];
  bodyCondition: string;
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

export const WeightScene = (props: WeightSceneProps) => {
  const {
    onCtaClick,
    name,
    currentWeight,
    bodyCondition,
    targetWeight,
    onChange,
  } = props;

  const showTargetWeight = bodyCondition !== 'Just Right';

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('currentWeight', Number(e.target.value));
  };

  const handleTargetWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('targetWeight', Number(e.target.value));
  };

  const ctaDisabled = showTargetWeight
    ? !currentWeight || !targetWeight
    : !currentWeight;

  return (
    <AddDogContent
      heading={`What is ${name}'s weight?`}
      ctaDisabled={ctaDisabled}
      onCtaClick={onCtaClick}
    >
      <Grid rowGap="sm" topSpacing="md">
        <GridItem>
          <label htmlFor="weight" className={visuallyHidden}>
            Your dog&apos;s current weight in pounds
          </label>
          <Input
            type="number"
            id="weight"
            name="weight"
            label="Current Weight (lbs)"
            min={0}
            value={currentWeight}
            onChange={handleWeightChange}
            withSpinButtons={false}
          />
        </GridItem>

        {showTargetWeight && (
          <GridItem>
            <label htmlFor="target-weight" className={visuallyHidden}>
              Your dog&apos;s ideal weight in pounds
            </label>
            <Input
              type="number"
              id="target-weight"
              name="weight"
              min={0}
              label="Ideal Weight (lbs)"
              value={targetWeight}
              onChange={handleTargetWeightChange}
              withSpinButtons={false}
            />
            <Text
              as="p"
              variant="article-16"
              fontStyle="italic"
              color="charcoal-2"
            >
              Your dog’s ideal weight is used to calculate their recommended
              daily calories.
            </Text>
          </GridItem>
        )}
      </Grid>
    </AddDogContent>
  );
};
