import moment from 'moment';

import {
  PICKY,
  PUPPY,
  OVERWEIGHT,
  OTHER,
  overweightBodyConditions,
  pickyEatingStyles,
} from './constants';

import type { LeadView } from '../../../graphql/types';

export interface GetTosaFacebookPixelAttributesArgs {
  lead: LeadView;
}

/**
 * Get the facebook content id associated with a lead.
 */
export function getTosaFacebookPixelAttributes({
  lead,
}: GetTosaFacebookPixelAttributesArgs) {
  const pets = lead?.pets ?? [];
  // We only want to return a single content ID. That is why we reassign this
  // variable in order to give preference to some IDs.
  let contentId = OTHER;

  const fourteenMonthsAgo = moment().subtract(14, 'months');
  if (
    pets.some(
      pet =>
        pet.birthday && moment(pet.birthday).isAfter(fourteenMonthsAgo, 'day')
    )
  ) {
    contentId = PUPPY;
  }

  if (
    pets.some(pet => {
      return (
        pet.bodyCondition &&
        overweightBodyConditions.has(pet.bodyCondition.toLowerCase())
      );
    })
  ) {
    contentId = OVERWEIGHT;
  }

  if (
    pets.some(pet => {
      return (
        pet.eatingStyle && pickyEatingStyles.has(pet.eatingStyle.toLowerCase())
      );
    })
  ) {
    contentId = PICKY;
  }

  return {
    content_ids: [contentId],
    content_type: 'product',
  };
}
