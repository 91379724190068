import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { paths } from '@farmersdog/constants';

import { ComponentName } from '../../../../blueprint/types';

import { getStepPercentageComplete } from './getStepPercentageComplete';

import type { BranchNode } from '../../../../blueprint/types';
import type { UseFormNavigateReturn } from '../../../../types';

/**
 * Check if a path contains any of the paths
 *
 * @param pathname - pathname the target pathname to check
 * @param pathsToSearch - paths the path arguments to check for
 *
 */
function pathContains(pathname: string, ...pathsToSearch: string[]): boolean {
  return pathsToSearch.some(path => pathname.search(path) > -1);
}

export type StepData = ReturnType<typeof useSteps>;

interface UseStepsArgs {
  signupMeUrl: string;
  signupPetsUrl: string;
  formSteps: BranchNode[];
  current: UseFormNavigateReturn['current'];
}

export function useSteps({
  signupMeUrl,
  signupPetsUrl,
  formSteps,
  current,
}: UseStepsArgs) {
  const { pathname, search } = useLocation();

  return useMemo(() => {
    // We deliberately exclude the GetStarted route from the active routes in
    // the Me section
    const isGetStartedPath = search.includes(ComponentName.GetStarted);
    const getStartedStep = formSteps.find(
      formStep => formStep.name === ComponentName.GetStarted
    );
    const isGetStartedStep = getStartedStep
      ? getStartedStep.__self__ === current
      : false;
    const isGetStarted = isGetStartedPath || isGetStartedStep;

    const baseSteps = [
      {
        path: signupMeUrl,
        label: 'Me',
        completed: !pathContains(pathname, paths.PATH_SIGNUP_ME),
        active: pathContains(pathname, paths.PATH_SIGNUP_ME) && !isGetStarted,
        percentageComplete: getStepPercentageComplete({
          formSteps,
          current,
          path: paths.PATH_SIGNUP_ME,
        }),
      },
      {
        path: signupPetsUrl,
        label: 'My Dogs',
        completed: !pathContains(
          pathname,
          paths.PATH_SIGNUP_ME,
          paths.PATH_SIGNUP_PETS
        ),
        active: pathContains(pathname, paths.PATH_SIGNUP_PETS),
        percentageComplete: getStepPercentageComplete({
          formSteps,
          current,
          path: paths.PATH_SIGNUP_PETS,
        }),
      },
      {
        path: paths.PATH_SIGNUP_RECIPES,
        label: 'Your Plan',
        completed: !pathContains(
          pathname,
          paths.PATH_SIGNUP_ME,
          paths.PATH_SIGNUP_PETS,
          paths.PATH_SIGNUP_RECIPES,
          paths.PATH_SIGNUP_PLANS,
          paths.PATH_SIGNUP_TREATS
        ),
        active: pathContains(
          pathname,
          paths.PATH_SIGNUP_RECIPES,
          paths.PATH_SIGNUP_PLANS,
          paths.PATH_SIGNUP_TREATS
        ),
        percentageComplete: 0,
      },
      {
        path: paths.PATH_SIGNUP_CHECKOUT,
        label: 'Checkout',
        completed: !pathContains(
          pathname,
          paths.PATH_SIGNUP_ME,
          paths.PATH_SIGNUP_PETS,
          paths.PATH_SIGNUP_RECIPES,
          paths.PATH_SIGNUP_PLANS,
          paths.PATH_SIGNUP_TREATS
        ),
        active: pathContains(pathname, paths.PATH_SIGNUP_CHECKOUT),
        percentageComplete: 0,
      },
    ];

    const steps = baseSteps.map((step, index) => ({
      ...step,
      number: index + 1,
      disabled: !baseSteps.slice(0, index + 1).every(s => s.completed),
    }));

    const activeStep = steps.find(step => step.active);

    const activeStepLabel = activeStep
      ? `${activeStep.label} (${activeStep.number}/${steps.length})`
      : '';

    return {
      steps,
      activeStep,
      activeStepLabel,
    };
  }, [pathname, signupMeUrl, signupPetsUrl, current, formSteps, search]);
}
