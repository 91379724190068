import type { TextProps } from '@farmersdog/corgi-x';
import { Text } from '@farmersdog/corgi-x';

export interface OrderSizeUpDismissibleCTACopyProps {
  subscriptionFrequency: number;
  yearlySavings: string;
}

export const headline = 'ORDER SIZES';
export const subhead = 'Your Plan is Flexible';
export const cta = 'Compare Plans';
export const componentTitle = 'Order Size Nudge';
export const dismissCta = 'Maybe Later';

export const maybeLaterBody =
  'If you’d like to adjust your order size in the future you can do so on the ‘plan’ page of your account.';
export const maybeLaterHeadline = 'Okay, Maybe Later';
export const maybeLaterCloseCta = 'Got it';

export const colors: {
  maybeLaterHeadline: TextProps['color'];
  maybeLaterBodyText: TextProps['color'];
} = {
  maybeLaterHeadline: 'kale-3',
  maybeLaterBodyText: 'kale-3',
};

export const templates = {
  body: function ({
    subscriptionFrequency,
    yearlySavings,
  }: OrderSizeUpDismissibleCTACopyProps) {
    return (
      <Text variant="article-16" color="white">
        You’re getting {subscriptionFrequency} days of food per order, our most
        popular option. By switching to a larger order size you can save up to{' '}
        {yearlySavings} a year!
      </Text>
    );
  },
};
