import type { ChangeEvent, FormEvent } from 'react';
import {
  Button,
  Page,
  Text,
  Grid,
  GridItem,
  RadioButton,
  Form,
  Select,
} from '@farmersdog/corgi-x';

import type {
  CancellationResponse,
  UseCancellationProgress,
  CancellationResponsesEnumType,
} from '../flow-state-management';
import {
  InitialStatesEnum,
  finalQuestionStates,
  CancellationResponsesEnum,
  getCookAtHomeReplacementRecipes,
  REPLACEMENT_FOOD,
} from '../flow-state-management';
import type { FoodBrandFieldsFragment, PetFieldsFragment } from '../network';
import styles from './CancellationReasons.module.css';
export interface CancellationReasonsProps {
  question: string | undefined;
  selectedReason: UseCancellationProgress['selectedReason'];
  reasons: CancellationResponse[];
  onReasonChange: UseCancellationProgress['onReasonChange'];
  onNext: UseCancellationProgress['onNext'];
  onPrevious: UseCancellationProgress['onPrevious'];
  onClose: UseCancellationProgress['onClose'];
  isState: UseCancellationProgress['isState'];
  onSubmit: (finalResponse: CancellationResponsesEnumType) => void;
  pets: PetFieldsFragment[];
  foodBrands: FoodBrandFieldsFragment[];
  selectedFoodBrand: string | undefined;
  onFoodBrandChange: (brand: string) => void;
  selectedCookAtHomeReplacementRecipe: string | undefined;
  onCookAtHomeReplacementRecipeChange: (brand: string) => void;
}

export function CancellationReasons({
  question,
  reasons,
  selectedReason,
  onReasonChange,
  onNext,
  onPrevious,
  onClose,
  isState,
  onSubmit,
  pets,
  foodBrands,
  selectedFoodBrand,
  onFoodBrandChange,
  selectedCookAtHomeReplacementRecipe,
  onCookAtHomeReplacementRecipeChange,
}: CancellationReasonsProps) {
  const isInitialState = isState(InitialStatesEnum.Primary);
  const isReplacementFoodState = isState(REPLACEMENT_FOOD);
  const isFinalQuestion = finalQuestionStates.some(state => isState(state));

  const dogWord = pets.length > 1 ? 'dogs' : 'dog';
  const foodBrandSelectOptions = [{ id: 0, name: undefined }, ...foodBrands];
  const cookAtHomeReplacementRecipeSelectOptions = [
    { id: 0, name: undefined },
    ...getCookAtHomeReplacementRecipes(),
  ];

  const hasCustomerSelectedReason = selectedReason !== undefined;

  // TODO: Accommodate multiple dropdown states better with a categorizer for replacement reasons

  const shouldShowReplacementDropdown =
    selectedReason &&
    isReplacementFoodState &&
    selectedReason !== CancellationResponsesEnum.StillTheFarmersDog;

  const shouldShowFoodBrandDropdown =
    shouldShowReplacementDropdown &&
    selectedReason !== CancellationResponsesEnum.IllCookAtHome;

  const shouldShowCookAtHomeReplacementRecipeDropdown =
    shouldShowReplacementDropdown &&
    selectedReason === CancellationResponsesEnum.IllCookAtHome;

  const isContinueDisabled = !hasCustomerSelectedReason;

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    // submit is disabled if there is not a selected reason
    if (!selectedReason) {
      return;
    }
    onSubmit(selectedReason);
  }

  const handleFoodBrandChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onFoodBrandChange(event.target.value);
  };

  const handleCookAtHomeReplacementRecipeChange = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    onCookAtHomeReplacementRecipeChange(event.target.value);
  };

  return (
    <Page
      className={styles.page}
      layout="base"
      onBackClick={onPrevious}
      onCloseClick={onClose}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Cancel My Subscription
      </Text>
      {isInitialState && (
        <Text
          variant="article-20"
          as="p"
          color="charcoal-3"
          topSpacing="sm"
          bottomSpacing="lg"
        >
          Thank you for trying our fresh food for your {dogWord}. Before you go,
          please share a little about your experience (takes 1 minute!) so we
          can improve things for you and fellow dog people!
        </Text>
      )}
      <Form className={styles.form}>
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>
            <Text variant="heading-16" color="kale-3" bold as="span">
              {question}
            </Text>
          </legend>
          <Grid className={styles.radios} flexDirection="column" rowGap="md">
            <GridItem
              md={5}
              className={styles.gridItem}
              justifyContent="center"
              flexDirection="column"
            >
              {reasons.map(reason => {
                return (
                  <RadioButton
                    key={reason.value}
                    value={reason.value}
                    label={reason.label}
                    name={question}
                    className={styles.radio}
                    onChange={onReasonChange}
                  />
                );
              })}
            </GridItem>
            {shouldShowFoodBrandDropdown && (
              <GridItem
                md={5}
                className={styles.gridItem}
                justifyContent="center"
                flexDirection="column"
              >
                <Select
                  label="What brand?"
                  value={selectedFoodBrand}
                  onChange={handleFoodBrandChange}
                >
                  {foodBrandSelectOptions.map(({ id, name }) => {
                    return (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    );
                  })}
                </Select>
              </GridItem>
            )}
            {shouldShowCookAtHomeReplacementRecipeDropdown && (
              <GridItem
                md={5}
                className={styles.gridItem}
                justifyContent="center"
                flexDirection="column"
              >
                <Select
                  label="Where did you find the recipe?"
                  value={selectedCookAtHomeReplacementRecipe}
                  onChange={handleCookAtHomeReplacementRecipeChange}
                >
                  {cookAtHomeReplacementRecipeSelectOptions.map(
                    ({ id, name }) => {
                      return (
                        <option value={name} key={id}>
                          {name}
                        </option>
                      );
                    }
                  )}
                </Select>
              </GridItem>
            )}
          </Grid>
        </fieldset>
        <Grid>
          <GridItem md={5} className={styles.gridItem}>
            {isFinalQuestion ? (
              <Button
                type="button"
                className={styles.button}
                disabled={isContinueDisabled}
                onClick={handleSubmit}
              >
                Cancel My Subscription
              </Button>
            ) : (
              <Button
                type="button"
                className={styles.button}
                disabled={isContinueDisabled}
                onClick={onNext}
              >
                Continue
              </Button>
            )}
          </GridItem>
        </Grid>
      </Form>
    </Page>
  );
}
