import { PATH_RESCHEDULE } from '@farmersdog/constants/paths';
import { Button, Page, Text } from '@farmersdog/corgi-x';
import { useHistory, useLocation } from 'react-router';
import { SubscriptionType } from '../../../graphql/types.core-api';
import { formatDateToMonthAndDay } from '../../../utils';
import { formatDateRange } from '../../../utils/formatting/formatDateRange';
import type { CancelSubscriptionFlowSavePayload } from '../../analytics';
import type { CancellationResponsesEnumType } from '../../flow-state-management';
import type { NextOrderToBeDeliveredFieldsFragment } from '../../network';
import styles from './DelaySavePrompt.module.css';
import { QueryParameter } from '@farmersdog/constants/queryParameter';
import { setPathQueries } from '../../../utils/path/setPathQueries';

interface DelaySavePromptProps {
  nextOrder: NextOrderToBeDeliveredFieldsFragment;
  onConfirmCancellation: () => void;
  onClose: () => void;
  onPrevious: () => void;
  cancellationReasonsHistory: CancellationResponsesEnumType[];
}

export function DelaySavePrompt(props: DelaySavePromptProps) {
  const history = useHistory<{
    cancelSubscriptionFlowSavePayload: CancelSubscriptionFlowSavePayload;
  }>();
  const location = useLocation();

  function handleDelay() {
    history.push(
      setPathQueries(PATH_RESCHEDULE, {
        [QueryParameter.EntryPoint]: location.pathname,
      }),
      // Save tracking payload in router state to be fired on reschedule completion
      {
        cancelSubscriptionFlowSavePayload: {
          saveReason: 'delay-shipment',
          subscriptionType: SubscriptionType.Fresh,
          cancellationReasonsHistory: props.cancellationReasonsHistory,
        },
      }
    );
  }

  let formattedCopy = '';

  if (props.nextOrder.isUnlocked) {
    formattedCopy = `It may take over a week to process a new order if you choose to reactivate. If you have too much food at home or just need a pause, you can delay the next shipment instead.`;
  } else if (props.nextOrder.deliveryWindow.estimatedDeliveryDate) {
    const formattedDate = formatDateToMonthAndDay(
      props.nextOrder.deliveryWindow.estimatedDeliveryDate
    );

    formattedCopy = `Traveling soon? Too much food? Your current order is estimated to arrive on ${formattedDate}, but you can easily delay future shipments!`;
  } else {
    const formattedDate = formatDateRange(
      props.nextOrder.deliveryWindow.earliestDesiredArrivalDate,
      props.nextOrder.deliveryWindow.latestDesiredArrivalDate,
      { abbreviatedMonth: false }
    );
    formattedCopy = `Traveling soon? Too much food? Your order scheduled to arrive ${formattedDate} has already begun prepping, but you can easily delay future shipments!`;
  }

  return (
    <Page
      layout="base"
      onBackClick={props.onPrevious}
      onCloseClick={props.onClose}
      className={styles.page}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Cancel My Subscription
      </Text>
      <Text as="p" variant="article-20" vSpacing="sm">
        {formattedCopy}
      </Text>
      <div className={styles.ctas}>
        <Button
          type="button"
          onClick={handleDelay}
          className={styles.delayButton}
        >
          Delay My Shipment
        </Button>
        <Button
          type="button"
          variant="plain-text"
          onClick={props.onConfirmCancellation}
          className={styles.submitButton}
        >
          <Text variant="heading-16">Confirm Cancellation</Text>
        </Button>
      </div>
    </Page>
  );
}
