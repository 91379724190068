import { ContentReveal, Section, Text } from '@farmersdog/corgi-x';

import styles from './PersonalizedEducation.module.css';

import type { ReactElement } from 'react';

interface PersonalizedEducationProps {
  information: ReactElement | string;
  heading: ReactElement | string;
}

export function PersonalizedEducation({
  information,
  heading,
}: PersonalizedEducationProps) {
  return (
    <ContentReveal in={true} inAnimation="slideUp" inDelay={0}>
      <div>
        <Section
          vSpacing={{
            xs: 'sm',
            lg: 'md',
          }}
          hSpacing="lg"
          variant="default"
          backgroundColor="Oyster0"
          className={styles.section}
          aria-label="Personalized Education"
        >
          <Text
            bold
            as="h2"
            color="kale-3"
            topSpacing="none"
            variant="heading-12"
            className={styles.title}
            bottomSpacing={{
              xs: 'xs',
              lg: 'sm',
            }}
          >
            {heading}
          </Text>
          <Text
            as="p"
            color="kale-3"
            vSpacing="none"
            variant="article-12"
            className={styles.paragraph}
          >
            {information}
          </Text>
        </Section>
      </div>
    </ContentReveal>
  );
}
