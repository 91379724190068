import track from '../track';
import events from '../eventNames';
import type { RecommendedTreatsPackage } from '../../pages/SnapSticksDetails/hooks/useSnapSticksDetailsData/useSnapSticksDetailsData';

export function trackTreatsRecommendation(
  recommendedTreatsPackages: RecommendedTreatsPackage[]
): void {
  track(events.treats_recommendation, {
    recommendedTreatsPackages,
  });
}
