/* CH#49502: Used by RET and CVR */
// Styles must be the first import so that
// css modules can have a higher precedence
// in the css cascade
import '@farmersdog/corgi/build/components.css';
import '@farmersdog/corgi-x/build/styles.css';
import 'src/styles/main.scss';

import { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Logger } from '@farmersdog/logger';

import { ToastRenderer } from '@farmersdog/corgi';
import { paths } from '@farmersdog/constants';

import { sanitizeCode } from 'src/utils/referrer';
import ShowErrorModal from 'src/errors/ShowErrorModal';
import { GladlySidekick } from '../GladlySidekick';
import { DebugModal } from 'src/debug';
import config from 'src/config';

import Head from 'src/components/Head';
import { CityTakeover, cityTakeoverPaths } from 'src/components/CityTakeover';
import {
  OfflineCoupons,
  offlineCouponPaths,
} from 'src/components/OfflineCoupons';
import ErrorBoundary from 'src/errors/ErrorBoundary';

import AboutUs from 'src/pages/AboutUs';
import Careers from 'src/pages/Careers';
import ChangePassword from 'src/pages/ChangePassword';
import CustomerAccount from 'src/account/app';
import { DIY } from 'src/pages/DIY';
import FAQ from 'src/pages/FAQ';
import Home from 'src/pages/Home';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import Partner from 'src/pages/Partner';
import Privacy from 'src/pages/Privacy';
import ResetPassword from 'src/pages/ResetPassword';
import { Reviews } from 'src/pages/Reviews';
import { SignupTosa } from 'src/pages/SignupTosa';
import Terms from 'src/pages/Terms';
import AccessibilityStatement from 'src/pages/AccessibilityStatement';
import { WhyFresh } from 'src/pages/WhyFresh';

import { RedirectWithSearch } from 'src/components/RedirectWithSearch';
import { ROOT_REDIRECTS } from 'src/constants/redirects';
import usePageViewTracker from 'src/hooks/usePageViewTracker';
import usePathRestoration from 'src/hooks/usePathRestoration';
import { useSessionSetUtmTags } from 'src/hooks/useSessionSetUtmTags';
import useTokenExpiration from 'src/hooks/useTokenExpiration';
import { refreshSession } from '@farmersdog/sessions';
import { useUserEngagementHandler } from '@farmersdog/utils';
import { OfferTerms } from 'src/pages/OfferTerms';
import { BonusDiscountOfferTerms } from 'src/pages/BonusDiscountReferralTerms';

import { useFeature } from 'src/abTesting';
import { CVR_SIGNUP_FLOW_DOWN } from 'src/abTesting/features';

import { SignupSiteUnavailablePage } from '../../pages/SignupSiteUnavailablePage';
import { cookie } from '@farmersdog/lead-browser-storage';
import { AccessibilityThemeContextProvider } from '../../hooks/useAccessibilityThemeContext';
import { ReferralTerms } from '../../pages/ReferralTerms';
import { UpdatePaymentMethod } from 'src/pages/UpdatePaymentMethod';
import { useObserveAnonymousIdStorage } from 'src/hooks/useObserveAnonymousIdStorage';
import { useObserveSplitClientCache } from 'src/hooks/useObserveSplitClientCache';
import { useGetTargetingAttributesInQueryParams } from 'src/hooks/useGetTargetingAttributesInQueryParams';

const log = new Logger('app:components:Application');
function Application() {
  useEffect(() => {
    log.debug('App has been mounted');
  }, []);

  useTokenExpiration();
  useGetTargetingAttributesInQueryParams();
  usePathRestoration();
  usePageViewTracker();
  useSessionSetUtmTags();
  useUserEngagementHandler({ onUserEngagement: refreshSession });

  useObserveAnonymousIdStorage();
  useObserveSplitClientCache();

  const signupSiteShutoffIsOn =
    useFeature(CVR_SIGNUP_FLOW_DOWN).treatment === 'on' ||
    !cookie.isSignupShutoffCooldownComplete();

  return (
    <ErrorBoundary>
      <Head />

      <AccessibilityThemeContextProvider>
        <Switch>
          <Route exact path={paths.PATH_HOME} component={Home} />

          <Route exact path={paths.PATH_LOGIN} component={Login} />
          <Route
            path={[paths.PAW_PATH_ACCOUNT, paths.PATH_CUSTOMER_ACCOUNT]}
            component={CustomerAccount}
          />

          <Route exact path={paths.PATH_ABOUT_US} component={AboutUs} />
          <Route exact path={paths.PATH_TERMS} component={Terms} />
          <Route exact path={paths.PATH_OFFER_TERMS} component={OfferTerms} />
          <Route
            exact
            path={paths.PATH_REFERRAL_TERMS}
            component={ReferralTerms}
          />
          <Route
            exact
            path={paths.PATH_BONUS_DISCOUNT_OFFER_TERMS}
            component={BonusDiscountOfferTerms}
          />
          <Route exact path={paths.PATH_PRIVACY} component={Privacy} />
          <Route
            exact
            path={paths.PATH_ACCESSIBILITY}
            component={AccessibilityStatement}
          />
          <Route exact path={paths.PATH_DIY} component={DIY} />
          <Route exact path={paths.PATH_CAREERS} component={Careers} />
          <Route exact path={paths.PATH_FAQ} component={FAQ} />
          <Route exact path={paths.PATH_REVIEWS} component={Reviews} />
          <Route exact path={paths.PATH_WHY_FRESH} component={WhyFresh} />

          <Route exact path={paths.PATH_UPDATE_PAYMENT_METHOD}>
            <UpdatePaymentMethod />
          </Route>

          <Route exact path="/p/:referrerCode" component={Partner} />
          <Route exact path={`${paths.PATH_PASSWORD_CHANGE}/:token?`}>
            <ChangePassword />
          </Route>
          <Route exact path={`${paths.PATH_PASSWORD_CREATE}/:token?`}>
            <ChangePassword create />
          </Route>
          <Route
            exact
            path={paths.PATH_PASSWORD_RESET}
            component={ResetPassword}
          />
          {signupSiteShutoffIsOn && (
            <Route
              exact
              path={paths.PATH_SIGNUP_SITE_UNAVAILABLE}
              component={SignupSiteUnavailablePage}
            />
          )}
          <Route
            path={[
              `${paths.PATH_SIGNUP_PRODUCT_ENABLE}/:enablementId?/:email?`,
              `${paths.PATH_SIGNUP}/:pageOrEmail?/:email?`,
            ]}
            component={SignupTosa}
          />
          <Route exact path={offlineCouponPaths} component={OfflineCoupons} />
          <Route exact path={cityTakeoverPaths} component={CityTakeover} />
          {/* Redirects partners from /:anycode to /p/:anycode */}
          <Route
            exact
            path="/:referrerCode"
            render={({ match, location }) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              let { referrerCode } = match.params;
              referrerCode = sanitizeCode(referrerCode);
              if (referrerCode) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const redirectUrl = `/p/${referrerCode}${location.search}`;
                log.debug('Using referrer code, redirecting', {
                  referrerCode,
                });
                return <Redirect to={redirectUrl} />;
              }
              return <Redirect to={`/${location.search}`} />;
            }}
          />
          {ROOT_REDIRECTS.map(redirect => (
            <RedirectWithSearch key={redirect.from} {...redirect} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </AccessibilityThemeContextProvider>
      {config('app.env') !== 'production' && <DebugModal />}
      <ShowErrorModal />
      <ToastRenderer />
      <GladlySidekick />
    </ErrorBoundary>
  );
}

export default Application;
