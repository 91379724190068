import isEqual from 'lodash/isEqual';
import { useMemo, useState, useCallback } from 'react';

import {
  Button,
  Divider,
  Grid,
  GridItem,
  Text,
  ToggleCard,
} from '@farmersdog/corgi-x';
import { usePreviousEffect } from '@farmersdog/utils';

import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { useFetchQuote } from '../../../hooks/useFetchQuote';
import { convertToPetInputsForQuote } from '../../../utils/convertToPetInputForQuote/convertToPetInputForQuote';
import { bowlPictureSources } from '../../images/RecipeBowlPicture/assets';
import RecipeModal from '../../RecipeModal/RecipeModal';
import { useCheapestQuotes } from '../hooks/useCheapestQuotes';
import { getQuoteData } from '../utils/getQuoteData';
import { FRESH_PRODUCT_LINES } from '../utils/overwriteWithCheapestSelection';

import { HeadingText } from './HeadingText';
import styles from './RecipesSelection.module.css';
import { SubmitButton } from './SubmitButton';

import type { IndexedPetFreshSelectionFields } from '../../../blueprint/types';
import type {
  FetchQuoteQuery,
  FreshRecipeRecommendation,
} from '../../../graphql/types';
import type {
  PetRecipes,
  FormFieldsType,
  UseFeatureHook,
} from '../../../types';
import type { ChangeEvent } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import type * as yup from 'yup';

export interface RecipesSelectionProps {
  recipes: FreshRecipeRecommendation[];
  returnToRecommendedPlan: () => void;
  currentSelection: string[];
  onSubmit: (quote: FetchQuoteQuery['fetchQuote'] | null) => void;
  formSubmitting: boolean;
  handleSelectionChange: (e: ChangeEvent<HTMLInputElement>) => void;
  freshSelectionControlledField: ControllerRenderProps<
    FormFieldsType,
    keyof IndexedPetFreshSelectionFields
  >;
  petName: string;
  formValidationSchema: yup.AnySchema;
  formValues: FormFieldsType;
  petRecipes: PetRecipes;
  selectionIsValid: boolean;
  recommendationIsValid: boolean;
  useFeature: UseFeatureHook;
  userId?: string;
}

export const MAX_SELECTED_RECIPES = 3;

export function RecipesSelection({
  recipes,
  returnToRecommendedPlan,
  currentSelection,
  onSubmit,
  formSubmitting,
  freshSelectionControlledField,
  handleSelectionChange,
  petName,
  formValidationSchema,
  formValues,
  petRecipes,
  selectionIsValid,
  recommendationIsValid,
}: RecipesSelectionProps) {
  const { open } = useGlobalLightbox({
    id: LightboxId.RecipeDetailModal,
  });

  const recipeInstructionsText = `Choose up to ${MAX_SELECTED_RECIPES} Recipes`;

  const [currentRecipeDetails, setCurrentRecipeDetails] =
    useState<FreshRecipeRecommendation | null>(null);
  const [hasSelectionChanged, setHasSelectionChanged] = useState(false);

  const handleSeeDetails = (recipe: FreshRecipeRecommendation) => {
    setCurrentRecipeDetails(recipe);
    open();
  };

  const petsInput = useMemo(() => {
    return selectionIsValid
      ? convertToPetInputsForQuote({
          data: formValidationSchema.cast(formValues, {
            assert: 'ignore-optionality',
          }) as FormFieldsType,
          isCustomizingRecipes: true,
          currentPetName: petName,
          petRecipes,
        })
      : null;
  }, [selectionIsValid, petName, petRecipes, formValues, formValidationSchema]);

  const initialPetsState = useMemo(() => {
    return recommendationIsValid
      ? convertToPetInputsForQuote({
          data: formValidationSchema.cast(formValues, {
            assert: 'ignore-optionality',
          }) as FormFieldsType,
          isCustomizingRecipes: true,
          currentPetName: petName,
          petRecipes,
        })
      : null;
  }, [
    formValidationSchema,
    formValues,
    petName,
    petRecipes,
    recommendationIsValid,
  ]);

  const compareSelections = useCallback(
    (prevSelection: string[], nextSelection: string[]) => {
      const selectionHasChanged = !isEqual(
        [...prevSelection].sort(),
        [...nextSelection].sort()
      );

      setHasSelectionChanged(selectionHasChanged);
    },
    []
  );

  usePreviousEffect(compareSelections, currentSelection);

  const { cheapestCoreQuote, cheapestLpfQuote } = useCheapestQuotes({
    currentPetName: petName,
    petRecipes,
    petsInput: initialPetsState,
  });

  const { quote } = useFetchQuote({
    currentPetName: petName,
    shouldFetchQuote: hasSelectionChanged && selectionIsValid,
    petsInput,
  });

  const { petDailySubtotal, petDailyDiscountedSubtotal, discountPercentage } =
    getQuoteData({
      quote,
      currentPetName: petName,
    });

  const { petDailySubtotal: cheapestCorePetDailySubtotal } = getQuoteData({
    quote: cheapestCoreQuote,
    currentPetName: petName,
  });
  const { petDailySubtotal: cheapestLpfPetDailySubtotal } = getQuoteData({
    quote: cheapestLpfQuote,
    currentPetName: petName,
  });

  const lpfRecipes = recipes.filter(
    recipe => recipe.content.productLine === FRESH_PRODUCT_LINES.lpf
  );

  const nonLpfRecipes = recipes.filter(
    recipe => recipe.content.productLine !== FRESH_PRODUCT_LINES.lpf
  );

  const areMoreThanOneLpfRecipes = lpfRecipes.length > 1;

  const PriceDivider = ({ text }: { text: string }) => (
    <div className={styles.priceWrapper}>
      <Text bold variant="heading-16">
        {text}
      </Text>
      <div className={styles.priceDivider}>
        <Divider
          width={4}
          spacing={11}
          color="Charcoal0"
          borderStyle="dotted"
        />
      </div>
    </div>
  );

  const ClickableRecipe = (recipe: FreshRecipeRecommendation) => (
    <GridItem
      xl={12}
      md={3}
      xs={6}
      key={recipe.name}
      className={styles.recipeToggleCard}
      alignItems="stretch"
      growContent
    >
      <ToggleCard
        {...freshSelectionControlledField}
        heading={recipe.content.displayName}
        description={recipe.content.mainIngredients}
        imageSources={bowlPictureSources[recipe.name]}
        imageAlt={`bowl of ${recipe.content.displayName} recipe food`}
        badge={recipe.recommended ? 'Our Pick' : undefined}
        label="Add"
        checkedLabel={'Added'}
        onChange={handleSelectionChange}
        checked={currentSelection.includes(recipe.name)}
        value={recipe.name}
        buttonLabel={'See Details'}
        buttonProps={{
          className: styles.detailsButton,
          type: 'button',
        }}
        onButtonClick={() => handleSeeDetails(recipe)}
        contentClassName={styles.contentContainer}
        controlClassName={styles.controlContainer}
      />
    </GridItem>
  );

  return (
    <>
      {currentRecipeDetails && (
        <RecipeModal recipe={currentRecipeDetails} petName={petName} />
      )}
      <Grid
        justifyContent="flex-start"
        flexDirection={'column'}
        vSpacing="lg"
        className={styles.recipesBackgroundContainer}
      >
        <HeadingText
          header={`Build ${petName}’s Plan`}
          subheader={recipeInstructionsText}
        />
        <Text className={styles.recipesCopy} variant="article-12">
          The recipe(s) we picked are most popular for dog profiles similar to{' '}
          {petName}, but all the options below are recommended.
        </Text>

        {lpfRecipes.length > 0 ? (
          <Grid
            className={styles.gridContainer}
            columnGap={areMoreThanOneLpfRecipes ? 'lg' : 'xs'}
          >
            <GridItem
              className={styles.gridItem}
              xl={areMoreThanOneLpfRecipes ? 5 : 3}
            >
              <div
                data-testid="lpf-recipes-container"
                className={
                  !areMoreThanOneLpfRecipes
                    ? styles.oneRecipeDividerWrapper
                    : styles.dividerWrapper
                }
              >
                <PriceDivider
                  text={`Starting at ${cheapestLpfPetDailySubtotal}/day`}
                />
              </div>
              <Grid
                columnGap="md"
                className={styles.recipeOptionsContainer}
                justifyContent={{ xs: 'flex-start', md: 'center' }}
              >
                {lpfRecipes.map(recipe => {
                  return <ClickableRecipe {...recipe} key={recipe.name} />;
                })}
              </Grid>
            </GridItem>
            <GridItem
              className={styles.gridItem}
              xl={areMoreThanOneLpfRecipes ? 7 : 9}
            >
              <PriceDivider
                text={`Starting at ${cheapestCorePetDailySubtotal}/day`}
              />
              <Grid
                justifyContent={{
                  md: 'center',
                  xl: 'flex-start',
                }}
                columnGap="md"
                className={styles.recipeOptionsContainer}
              >
                {nonLpfRecipes.map(recipe => {
                  return <ClickableRecipe {...recipe} key={recipe.name} />;
                })}
              </Grid>
            </GridItem>
          </Grid>
        ) : (
          <Grid
            columnGap="sm"
            className={styles.recipeOptionsContainer}
            justifyContent="center"
          >
            {recipes.map(recipe => {
              return <ClickableRecipe {...recipe} key={recipe.name} />;
            })}
          </Grid>
        )}

        <GridItem md={12} justifyContent="center" alignItems={'center'}>
          <Button
            type="button"
            variant="plain-text"
            onClick={returnToRecommendedPlan}
            className={styles.returnButton}
          >
            <Text as="h3" variant="heading-16" color="carrot-2">
              Back to Recommended Plan
            </Text>
          </Button>
        </GridItem>
        <SubmitButton
          hasSelectedRecipes={Boolean(currentSelection?.length > 0)}
          onClick={() => onSubmit(quote)}
          formSubmitting={formSubmitting}
          petDailySubtotal={petDailySubtotal}
          petDailyDiscountedSubtotal={petDailyDiscountedSubtotal}
          discountPercentage={discountPercentage}
        />
      </Grid>
    </>
  );
}
