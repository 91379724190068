import { Grid, GridItem, Picture, Text } from '@farmersdog/corgi-x';
import type { ReactElement } from 'react';
import styles from './DailyPriceSection.module.css';

export interface DailyPriceSectionProps {
  heading: string;
  image: string;
  children: ReactElement;
}

export function DailyPriceSection({
  heading,
  image,
  children,
}: DailyPriceSectionProps) {
  return (
    <Grid columnGap="sm">
      <GridItem sm={3}>
        <Picture
          sources={[image]}
          alt={`${heading} illustration`}
          className={styles.picture}
        />
      </GridItem>
      <GridItem sm={9}>
        <Grid flexDirection={'column'} rowGap={'xs'}>
          <GridItem>
            <Text variant={'heading-16'} color={'kale-3'} bold>
              {heading}
            </Text>
          </GridItem>
          <GridItem>{children}</GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
}
