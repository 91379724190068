import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import type { BranchNode } from '../blueprint/types';

interface getLastMeFormStepArgs {
  formSteps: BranchNode[];
}

export function getFirstMeFormStep({ formSteps }: getLastMeFormStepArgs) {
  // NOTE: returns the first formStep that is not "getStarted" card
  return formSteps.find(step => {
    // TODO - should replace 'GetStarted' explicit name check with nodeNames https://app.shortcut.com/farmersdog/story/84510/export-node-names-from-tosa-shared
    return step.route === PATH_SIGNUP_ME && step.name !== 'GetStarted';
  });
}
