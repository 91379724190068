import { getNodeNameAndPosition } from '../blueprint/utils';

import { SearchParams } from './getSignupRoute';

import type { BranchNode } from '../blueprint/types';

interface GetFullSignupRouteArgs {
  formSteps: BranchNode[];
  route: string;
  petName?: string;
}

export function getFullSignupRoute({
  formSteps,
  route,
  petName,
}: GetFullSignupRouteArgs) {
  const firstStepInRoute = formSteps.find(step => {
    return step.route === route;
  });

  if (!firstStepInRoute) {
    return route;
  }

  const { name } = getNodeNameAndPosition(firstStepInRoute.name);

  const params = new URLSearchParams();
  params.append(SearchParams.Card, name);

  if (petName) {
    params.append(SearchParams.Pet, petName);
  }

  return `${route}?${params.toString()}`;
}
