import { useErrorMessage } from '../../../../hooks/useErrorMessage';
import { getErrorMessage } from '../../../../validation';
import { InlineError as InlineErrorComponent } from '../../../shared/InlineError';

import { InlineErrorTemplate } from './InlineErrorTemplate';

import type { TOSAComponentInput, TOSALeafNode } from '../../../../types';

export function InlineError(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods } = props;
  const {
    formState: { errors },
  } = formMethods;

  if (!node.input || !node.input.errorMap) {
    throw new Error('The error component needs an error map definition');
  }

  if (!node.input.excludeFromProgress) {
    throw new Error('The error component needs to be excluded from progress');
  }

  const { errorMap } = node.input;

  const errorCodeToDisplay = Object.values(errors)
    .map(error => getErrorMessage(error))
    .find(message => message && message in errorMap);

  const errorMessage = useErrorMessage({
    nodeName: node.name,
    getValues: formMethods.getValues,
    errorCode: errorCodeToDisplay,
    errorMap,
  });

  return (
    <InlineErrorComponent in={errorCodeToDisplay !== undefined}>
      <InlineErrorTemplate>{errorMessage}</InlineErrorTemplate>
    </InlineErrorComponent>
  );
}
