import classNames from 'classnames';

import styles from './getLayoutClass.css';

import type { LayoutType } from '../../blueprint/types';

interface GetLayoutClassArgs {
  layout?: LayoutType;
  show?: boolean;
}

export function getLayoutClass({ layout, show }: GetLayoutClassArgs) {
  return classNames(
    layout ? styles[layout] : '',
    show === false ? styles['hiddenNode'] : ''
  );
}
