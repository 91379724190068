/* CH#49502: Used by RET and CVR */
/* global google */
import './AddressAutocomplete.scss';

import type { KeyboardEvent } from 'react';
import { useEffect, useState } from 'react';
import { ENTER } from 'src/constants/keycodes';
import { useStatesByIsoCode } from 'src/graphql/queries/useCountries';
import interceptDOMEvent from 'src/utils/interceptDOMEvent';
import { GoogleMapsScript } from 'src/vendors/googleMaps';
import type { InputProps } from '@farmersdog/corgi-x';
import {
  Input,
  useHtmlElement,
  useIsomorphicLayoutEffect,
} from '@farmersdog/corgi-x';
import type { Address } from './types';
import { getAddressFromPlaceResult } from './getAddressFromPlaceResult';
import { reporter } from '../../services/reporter';

const GoogleAutoCompleteOptions = {
  types: ['address'],
  componentRestrictions: { country: 'us' },
};

type Autocomplete = google.maps.places.Autocomplete;
interface AddressAutocompleteProps extends Omit<InputProps, 'ref'> {
  onSuggestionSelect: (address: Address) => void;
}

function AddressAutocomplete({
  onSuggestionSelect,
  onChange,
  onFocus,
  autoComplete,
  ...props
}: AddressAutocompleteProps) {
  const states = useStatesByIsoCode();
  const [inputEl, inputRef] = useHtmlElement();
  const [autocompleteInstance, setAutocompleteInstance] =
    useState<Autocomplete>();
  const [google, setGoogle] = useState<typeof window.google>();

  // Attach google services
  useIsomorphicLayoutEffect(() => {
    if (!inputEl) {
      return;
    }

    if (!google) {
      return;
    }

    try {
      const instance = new google.maps.places.Autocomplete(
        inputEl as HTMLInputElement,
        GoogleAutoCompleteOptions
      );

      setAutocompleteInstance(() => instance);
    } catch (e) {
      reporter.error(e);
    }
  }, [google, inputEl]);

  useEffect(() => {
    if (!autoComplete || !inputEl) {
      return;
    }

    inputEl.setAttribute('autocomplete', autoComplete);
    // We need to ovverride the default google placeholder
    inputEl.setAttribute('placeholder', ' ');
  }, [inputEl, autoComplete]);

  useEffect(() => {
    if (!autocompleteInstance || !google || !states || !states.length) {
      return;
    }

    const listener = autocompleteInstance.addListener('place_changed', () => {
      const place = autocompleteInstance.getPlace();
      if (!place.geometry) {
        // No details available for input
        return;
      }
      const addressInfo = getAddressFromPlaceResult(place, states);

      if (!onSuggestionSelect) {
        return;
      }

      onSuggestionSelect(addressInfo);
    });

    return () => google.maps.event.removeListener(listener);
  }, [autocompleteInstance, states, google, onSuggestionSelect]);

  const updateAutoCompleteAttribute = () => {
    if (!inputEl) {
      return;
    }
    // See this answer on stackoverflow: https://stackoverflow.com/a/49161445
    // This is here because Chrome autofill overlaps the Google Places autocomplete which the business doesn't want.
    // Normally setting autocomplete to "new-password" disables autofill in Chrome, but the Places autocomplete
    // changes it to "off" when it attaches to an input element which Chrome autofill ignores.
    // We need to ovverride the default google placeholder
    inputEl.setAttribute('placeholder', ' ');
  };

  /**
   * We do not want to submit the form on interactions with the dropdown.
   *
   * TODO: Find a reliable even from google autocomplete that indicates the
   * dropdown is open.
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!google || e.keyCode !== ENTER) {
      return;
    }

    e.preventDefault();
  };

  return (
    <>
      <GoogleMapsScript onLoad={setGoogle} />
      <Input
        ref={inputRef}
        {...props}
        autoComplete={autoComplete}
        onKeyDown={handleKeyDown}
        onChange={interceptDOMEvent(updateAutoCompleteAttribute, onChange)}
        onFocus={interceptDOMEvent(updateAutoCompleteAttribute, onFocus)}
        type="text"
      />
    </>
  );
}

export default AddressAutocomplete;
