import { Loading } from '../../../../../components';
import type { PetFieldsFragment } from '../../../../network';
import { useSetLPFDownsellEligibility } from '../../../../network';
import { CostAssessment } from '../CostAssessment';
import {
  IntroMixingPlan,
  LpfEducation,
  LpfSuccess,
  MixingPlanEducation,
} from './components';
import { LpfDownsellState } from './useLpfDownsellProgress';

interface LpfDownsellNavigation {
  onClose: () => void;
  onPrevious: () => void;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  pet: PetFieldsFragment;
}

interface LpfDownsellProps extends LpfDownsellNavigation {
  currentState: LpfDownsellState;
  currentWeeklyPrice: number;
}

export interface LpfRecipe {
  id: number;
  displayName: string;
  name: string;
}

/**
 * Determines which part of the LPF downsell flow to show based on the current state.
 */
export function LpfDownsell(props: LpfDownsellProps) {
  const { currentState, ...navigation } = props;

  const addLPFDownsellEligibility = useSetLPFDownsellEligibility();

  // Update state after running LPF Eligibility Mutation
  if (addLPFDownsellEligibility.called === false) {
    addLPFDownsellEligibility.setLPFDownsellEligibility();
  }

  if (addLPFDownsellEligibility.loading) {
    return <Loading />;
  } else if (addLPFDownsellEligibility.error) {
    throw addLPFDownsellEligibility.error;
  } else if (
    (currentState === LpfDownsellState.LpfEducation ||
      currentState === LpfDownsellState.LpfSuccess) &&
    addLPFDownsellEligibility.data?.setLPFDownsellEligibility.eligibleProduct
  ) {
    const stateToComponent = {
      [LpfDownsellState.LpfEducation]: LpfEducationWrapper,
      [LpfDownsellState.LpfSuccess]: LpfSuccessWrapper,
    };

    const Component = stateToComponent[currentState];
    return (
      <Component
        {...{
          ...navigation,
          downsellProduct:
            addLPFDownsellEligibility.data?.setLPFDownsellEligibility
              .eligibleProduct,
        }}
      />
    );
  } else if (
    currentState === LpfDownsellState.CostAssessment ||
    currentState === LpfDownsellState.IntroMixingPlan ||
    currentState === LpfDownsellState.MixingPlanEducation
  ) {
    const stateToComponent = {
      [LpfDownsellState.CostAssessment]: CostAssessmentWrapper,
      [LpfDownsellState.IntroMixingPlan]: IntroMixingPlanWrapper,
      [LpfDownsellState.MixingPlanEducation]: MixingPlanEducationWrapper,
    };

    const Component = stateToComponent[currentState];
    return <Component {...navigation} />;
  } else {
    throw new Error(
      `Unable to cancel subscription, please contact Customer Support.`
    );
  }
}

function CostAssessmentWrapper(
  props: LpfDownsellNavigation & { currentWeeklyPrice: number }
) {
  return (
    <CostAssessment
      currentWeeklyPrice={props.currentWeeklyPrice}
      onClose={props.onClose}
      onContinue={props.onPrimaryClick}
      onPrevious={props.onPrevious}
    />
  );
}

function LpfEducationWrapper(
  props: LpfDownsellNavigation & { downsellProduct: LpfRecipe }
) {
  return (
    <LpfEducation
      onClose={props.onClose}
      onAccept={props.onPrimaryClick}
      onReject={props.onSecondaryClick}
      onPrevious={props.onPrevious}
      pet={props.pet}
      recipe={props.downsellProduct}
    />
  );
}

function LpfSuccessWrapper(
  props: LpfDownsellNavigation & { downsellProduct: LpfRecipe }
) {
  return (
    <LpfSuccess
      recipeDisplayName={props.downsellProduct.displayName}
      onClose={props.onClose}
    />
  );
}

function IntroMixingPlanWrapper(props: LpfDownsellNavigation) {
  return (
    <IntroMixingPlan
      onClose={props.onClose}
      onPrevious={props.onPrevious}
      onStartMixingPlan={props.onSecondaryClick}
      onContinueCancelling={props.onPrimaryClick}
    />
  );
}

function MixingPlanEducationWrapper(props: LpfDownsellNavigation) {
  return (
    <MixingPlanEducation
      onClose={props.onClose}
      onBack={props.onPrevious}
      onContinue={props.onPrimaryClick}
    />
  );
}
