import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type {
  SetLpfDownsellEligibilityMutation,
  SetLpfDownsellEligibilityMutationVariables,
} from './setLPFDownsellEligibility.cgs.generated-types';
import setLPFDownsellEligibilityMutation from './setLPFDownsellEligibility.cgs.graphql';

export type UseSetLPFDownsellEligibility = {
  /** Submit the lpf downsell eligibility mutation and callback */
  setLPFDownsellEligibility: () => void;
  /** Data returned by the mutation, including the product made eligible */
  data?: SetLpfDownsellEligibilityMutation | null;
  /** Whether the mutation is still running */
  loading: boolean;
  /** An error while submitting */
  error?: Error | ApolloError;
  /** Whether the mutation has been called */
  called: boolean;
};

export function useSetLPFDownsellEligibility(): UseSetLPFDownsellEligibility {
  const [setLPFDownsellEligibility, submitState] = useMutation<
    SetLpfDownsellEligibilityMutation,
    SetLpfDownsellEligibilityMutationVariables
  >(setLPFDownsellEligibilityMutation, {
    context: { endpoint: 'cgs' },
  });
  return {
    setLPFDownsellEligibility,
    data: submitState.data,
    error: submitState.error,
    loading: submitState.loading,
    called: submitState.called,
  };
}
