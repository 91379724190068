import { useQuery } from '@apollo/client';

import { determineSubscription } from 'src/utils/determine-subscription';

import { customerAccountQuery } from '@/account/app/graphql';
import type { CustomerAccountPetFragment } from '@/account/app/graphql/core/fragments/CustomerAccountPet.generated-types';
import type { CustomerAccountReferralStatsFragment } from '@/account/app/graphql/core/fragments/CustomerAccountReferralStats.generated-types';
import type { CustomerAccountSubscriptionFragment } from '@/account/app/graphql/core/fragments/CustomerAccountSubscription.generated-types';
import type {
  CustomerAccountQuery,
  CustomerAccountQueryVariables,
} from '@/account/app/graphql/core/queries/CustomerAccount.generated-types';
import { OrderType, SubscriptionStatus } from '@/account/graphql/core/types';

import type {
  ApolloError,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';

export type CustomerAccountResult = QueryResult<
  CustomerAccountQuery,
  CustomerAccountQueryVariables
>;

export type CustomerAccountQueryOptions = QueryHookOptions<
  CustomerAccountQuery,
  CustomerAccountQueryVariables
>;

export type UseCustomerAccountData = {
  loadingError: ApolloError | undefined;
  isLoading: boolean;
  userId: number | undefined;
  subscription: CustomerAccountSubscriptionFragment | undefined;
  activePets: CustomerAccountPetFragment[] | undefined;
  referralStats: CustomerAccountReferralStatsFragment | undefined;

  withRegularOrder: boolean;
  isSubscriptionActive: boolean;

  hasTreatsInSubscription: boolean;
};

export function useCustomerAccountData(
  options?: CustomerAccountQueryOptions
): UseCustomerAccountData {
  const queryResult: CustomerAccountResult = useQuery<
    CustomerAccountQuery,
    CustomerAccountQueryVariables
  >(customerAccountQuery, options);

  const subscriptions = queryResult.data?.me.subscriptions ?? undefined;
  const activePets = queryResult.data?.me.pets ?? undefined;
  const referralStats = queryResult.data?.me.referralStats ?? undefined;

  const subscription = subscriptions && determineSubscription(subscriptions);

  // As the query returns the delivered orders descending, we can use the first edge here.
  // TODO: use subscription.isTrial from https://app.clubhouse.io/farmersdog/story/60211
  const withRegularOrder =
    subscription?.ordersConnection.edges?.some(
      edge => edge.node.type === OrderType.Regular
    ) ?? false;

  const isSubscriptionActive =
    subscription?.status === SubscriptionStatus.Active;

  const data: UseCustomerAccountData = {
    isLoading: queryResult.loading,
    loadingError: queryResult.error,
    userId: queryResult.data?.me.id,
    activePets,
    subscription,
    referralStats,
    withRegularOrder,
    isSubscriptionActive,
    hasTreatsInSubscription: Boolean(subscription?.addOns?.length),
  };

  return data;
}
