import { useValidateBreedsWeight } from '../../../../graphql/queries';
import { useTosaUserFeature, useThrowToErrorBoundary } from '../../../../hooks';
import { FeatureName } from '../../../../utils/features';
import { isNotNullUndefinedOrEmptyString } from '../../../../utils/isNotNullUndefinedOrEmptyString';

import { getKnownErrorMessage } from './utils/getKnownErrorMessage';

import type {
  FormFieldsType,
  UseFeatureHook,
  UseForm,
} from '../../../../types';
import type { GraphQLFormattedError } from 'graphql';

interface ValidateWeightArgs {
  breeds: string[];
  weight: number;
  targetWeight: number | undefined | null;
}

interface HandleWeightValidationErrorArgs {
  weightError: GraphQLFormattedError | undefined;
  targetWeightError: GraphQLFormattedError | undefined;
}

interface UsePetWeightValidationArgs {
  weightField: keyof FormFieldsType;
  targetWeightField: keyof FormFieldsType;
  setError: UseForm['setError'];
  useFeature: UseFeatureHook;
}

export function usePetWeightValidation({
  weightField,
  targetWeightField,
  setError,
  useFeature,
}: UsePetWeightValidationArgs) {
  const { refetch: refetchValidateBreedsWeight } = useValidateBreedsWeight({
    errorPolicy: 'all',
    skip: true,
  });
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const { treatment: weightOverrideTreatment } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_WEIGHT_OVERRIDE,
  });

  async function validateWeight({
    breeds,
    weight,
    targetWeight,
  }: ValidateWeightArgs) {
    const weightValidationPromises = [
      refetchValidateBreedsWeight({ breeds, weight }),
    ];
    if (isNotNullUndefinedOrEmptyString(targetWeight)) {
      weightValidationPromises.push(
        refetchValidateBreedsWeight({ breeds, weight: targetWeight })
      );
    }
    const [weightResponse, targetWeightResponse] = await Promise.all(
      weightValidationPromises
    );

    const weightError = weightResponse.errors?.[0];
    const targetWeightError = targetWeightResponse?.errors?.[0];

    if (
      (!weightError && !targetWeightError) ||
      weightOverrideTreatment == 'on'
    ) {
      return { isValid: true };
    }

    return { isValid: false, weightError, targetWeightError };
  }

  function handleWeightValidationError({
    weightError,
    targetWeightError,
  }: HandleWeightValidationErrorArgs) {
    if (weightError) {
      const knowErrorMessage = getKnownErrorMessage({ error: weightError });
      if (!knowErrorMessage) {
        return throwToErrorBoundary(new Error(weightError.message));
      }
      setError(weightField, { message: knowErrorMessage });
    }

    if (targetWeightError) {
      const knownErrorMessage = getKnownErrorMessage({
        error: targetWeightError,
        isTargetWeight: true,
      });
      if (!knownErrorMessage) {
        return throwToErrorBoundary(new Error(targetWeightError.message));
      }
      setError(targetWeightField, { message: knownErrorMessage });
    }
  }

  return { validateWeight, handleWeightValidationError };
}
