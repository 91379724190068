import { getNodeNameAndPosition } from '../../blueprint/utils';
import { removeNodeValueFromState } from '../../utils';
import { fragmentConfig } from '../fragmentConfig';

import { updateSchemaWithFragments } from './updateSchemaWithFragments';

import type {
  LeafNode,
  CompiledBlueprint,
  NodeRegistry,
} from '../../blueprint/types';
import type {
  FormValidationSchema,
  SetFormStepsType,
  UseForm,
} from '../../types';

export interface ToggleFragmentArgs {
  value: unknown;
  schema: CompiledBlueprint;
  setFormSteps: SetFormStepsType;
  node: LeafNode;
  formValidationSchema: FormValidationSchema;
  unregister: UseForm['unregister'];
  registry: NodeRegistry;
}

export function toggleFragment({
  value,
  node,
  schema,
  setFormSteps,
  formValidationSchema,
  unregister,
  registry,
}: ToggleFragmentArgs): void {
  const { name, position } = getNodeNameAndPosition(node.name);
  const castedValue = formValidationSchema.cast(
    {
      [name]: value,
    },
    {
      assert: 'ignore-optionality',
    }
  ) as unknown as Record<string, unknown>;

  const fragmentCount = fragmentConfig[name].getFragmentCount(
    castedValue[name]
  );
  const fragments = fragmentConfig[name].fragments;

  const clonedSchema = updateSchemaWithFragments({
    fragmentCount,
    fragments,
    index: parseInt(position),
    schema,
  });

  // Fields of removed fragment persists in form state even if the fragment nodes have been unmounted.
  // This could cause the fields of removed fragment to be regarded as invalid and results in incorrect form progress computation.
  // We remove the relevant entries from form state to get the correct progress
  if (fragmentCount === 0) {
    for (const fragment of fragments) {
      let fragmentName = fragment.source;
      if (parseInt(position)) {
        fragmentName = `${fragmentName}-${position}`;
      }

      removeNodeValueFromState({
        registry,
        unregister,
        nodeName: fragmentName,
      });
    }
  }

  setFormSteps(clonedSchema);
}
