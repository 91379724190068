import { ApplicationError } from '../ApplicationError';
import type { AddressError } from './isAddressError';
import { ValidationError } from '../ValidationError';
import * as copy from '../copy';
import * as errorCodes from '../errorCodes';
import { createAddressValidationError } from '../../components/AddressValidationError';

export function getShippingError(
  addressError: AddressError
): ApplicationError | ValidationError {
  const shippingErrorCode = addressError.extensions.code;

  if (shippingErrorCode === errorCodes.SHIPPING_SERVICE_UNAVAILABLE) {
    return new ApplicationError(addressError.message, {
      title: copy.SHIPPING_SERVICE_UNAVAILABLE_TITLE,
      displayMessage: copy.SHIPPING_SERVICE_UNAVAILABLE,
    });
  }

  switch (shippingErrorCode) {
    case errorCodes.ADDRESS_DATA_MISMATCH_ERROR:
    case errorCodes.ADDRESS_AMBIGUOUS_ERROR:
    case errorCodes.ADDRESS_NOT_FOUND_ERROR:
    case errorCodes.ADDRESS_INCORRECT_DATA_ERROR:
    case errorCodes.ADDRESS_POTENTIAL_BAD_DATA_ERROR:
      return new ValidationError(addressError.message, {
        title: copy.VERIFY_ADDRESS_TITLE,
      });

    case errorCodes.ADDRESS_NONEXISTENT_ZIPCODE_ERROR:
    case errorCodes.ADDRESS_INVALID_ZIP_ERROR:
      return createAddressValidationError({
        title: copy.CANNOT_SHIP_TITLE,
        copy1: copy.AMBIGUOUS_ADDRESS_COPY_1,
        copy2: copy.AMBIGUOUS_ADDRESS_COPY_2,
      });

    default:
      return new ApplicationError(addressError.message, {
        title: copy.CANNOT_SHIP_TITLE,
      });
  }
}
