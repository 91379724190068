import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import chickenSquashAndGreenBeansWebp from '../assets/chicken-squash-and-green-beans.webp';

export const chickenSquashAndGreenBeans: DiyRecipe = {
  name: 'Chicken, Squash, and Green Beans',
  primaryProtein: PrimaryProtein.Chicken,
  ingredients: [
    {
      name: 'Ground Chicken (7% Fat)',
      quantity: 2.974,
      unit: 'lbs',
    },
    {
      name: 'Squash (Fresh or frozen)',
      quantity: 267,
      unit: 'grams',
    },
    {
      name: 'Green Beans (Fresh or frozen)',
      quantity: 247,
      unit: 'grams',
    },
    {
      name: 'Cauliflower rice (Fresh or frozen)',
      quantity: 179,
      unit: 'grams',
    },
    {
      name: 'Egg (Yolk and white)',
      quantity: 148.4,
      unit: 'grams',
    },
    {
      name: 'DIY Nutrient Mix',
      quantity: 25.2,
      unit: 'grams',
    },
    {
      name: 'Sunflower Oil (Linoleic 65%)',
      quantity: 22.5,
      unit: 'grams',
    },
    {
      name: 'Salmon Oil',
      quantity: 9.2,
      unit: 'grams',
    },
  ],
  id: 'chicken-squash-and-green-beans',
  yieldWeight: 5,
  cookTime: '30 mins',
  calories: 2430,
  utensils: [],
  image: chickenSquashAndGreenBeansWebp,
  instructions: [],
};
