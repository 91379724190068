import classNames from 'classnames';

import type { PictureProps } from 'src/components/Picture';
import Picture from 'src/components/Picture';

import styles from './Background.module.scss';

export function Background({ className, ...props }: PictureProps) {
  return (
    <Picture className={classNames(styles.background, className)} {...props} />
  );
}
