import ContentPage from 'src/components/ContentPage';

import { paths } from '@farmersdog/constants';
import config from 'src/config';
import { EMAIL_HELP, EMAIL_INFO } from '@farmersdog/constants/emails';

export const productAndSubscriptionSectionId =
  'our-products-and-your-subscription';

const publicUrl = String(config('app.publicUrl'));
export default function Terms({
  page = true,
  className,
}: {
  /** Do not render as a full page. */
  page?: boolean;
  className?: string;
}) {
  const Container = page ? ContentPage : 'div';

  return (
    <Container title="Terms of Use" className={className}>
      <h1>The Farmer’s Dog Terms of Use</h1>
      <p>Last Updated December 10, 2024.</p>
      <p>
        These Terms of Use (“<strong>Terms</strong>”) are a legal agreement
        between The Farmer’s Dog, Inc. (“
        <strong>The Farmer’s Dog</strong>,” “<strong>we</strong>,” “
        <strong>us</strong>,” or “<strong>our</strong>”) and you as a user of
        The Farmer’s Dog’s Services or Products (as hereinafter defined)(“
        <strong>you</strong>” or “<strong>your</strong>”). We make our website{' '}
        <a href="https://www.thefarmersdog.com">
          https://www.thefarmersdog.com
        </a>{' '}
        (“
        <strong>Website</strong>”) available in order to provide you with
        information about our company, Products and Services, and also so that
        you can order our Products. Your use of the Website, any related mobile
        applications, our owned and operated social media channels, and our dog
        food delivery service and any services we provide in connection
        therewith, including the Farmer’s Dog Referral Program (as defined
        below) (collectively, the “<strong>Services</strong>”), as well as your
        purchase of The Farmer’s Dog products through the Services (“
        <strong>Products</strong>”), are subject to these Terms. BY USING THE
        WEBSITE, SERVICES AND/OR PURCHASING PRODUCTS, YOU AGREE TO AND WILL BE
        DEEMED TO BE BOUND BY THESE TERMS. If you do not want to be bound by
        these Terms do not use the Website, Services or Products.
      </p>
      <p>
        THESE TERMS CONTAIN A DISPUTE RESOLUTION PROCEDURE AND AN ARBITRATION
        AGREEMENT (SECTION 13), INCLUDING A WAIVER OF CLASS, REPRESENTATIVE, AND
        COLLECTIVE ACTIONS, THAT AFFECT YOUR LEGAL RIGHTS, YOUR ABILITY TO GO TO
        A COURT FOR DISPUTES, AND THE WAYS IN WHICH YOU CAN BRING DISPUTES
        AGAINST US.
      </p>
      <p>
        We may occasionally update these Terms. When we do update these Terms,
        we will also revise the “Last Updated” date at the top of these Terms.
        If we make changes to these Terms that, in our discretion, we consider
        significant, in addition to posting the updated Terms on the Website, we
        may also send emails to our subscribers containing a link to the revised
        Terms or provide alternative notice of changes to the Terms in our
        discretion. It is your responsibility to check these Terms for changes
        prior to use of the Services or purchase of the Products. Your continued
        use of the Website, Services or Products after we post an update to
        these Terms indicates your acceptance of the updated Terms.
      </p>
      <p>
        We are based in the United States and we provide the Website, Services,
        and Products for use only by persons located in the United States. We
        make no claims that the Website or any of its content is accessible or
        appropriate outside of the United States or that the Services or
        Products are available or appropriate for use outside of the United
        States. Access to the Website or use of the Services or Products may not
        be legal by certain persons or in certain countries. Any use of the
        Website, Products or Services including from outside the United States,
        is at your own risk and you are responsible for compliance with all
        applicable local laws, rules and regulations.
      </p>
      <h2>1. Membership and account security.</h2>
      <p>
        <strong>1.1</strong> The Website is offered and available to users who
        are 13 years of age or older and reside in the United States or any of
        its territories or possessions. An individual or household may create
        only one (1) customer account on the Website. By using the Website, you
        represent and warrant that you meet all of the foregoing eligibility
        requirements. If you do not meet all of these requirements, you must not
        access or use the Website. While you may browse the Website and order
        some Products as a guest user, to purchase our dog food Products or
        access certain Services, you must submit an application to become a
        subscriber to our dog food delivery Services. You must be 18 years of
        age or older and legally capable of entering into binding contracts in
        order to apply, and we may accept or reject your application in our sole
        discretion. If approved, when you create an account, you agree you are
        responsible for maintaining the confidentiality and security of your
        account by, among other things: (i) ensuring that your registration
        details are true, up to date, and accurate; and (ii) not sharing your
        password, letting anyone else access your account, transferring your
        account to any other person, or taking other actions that might
        jeopardize the security of your account.
      </p>
      <p>
        <strong>1.2</strong> You agree to comply with these Terms and all
        applicable laws, statutes, ordinances, and regulations regarding your
        use of our Website, Services and your purchase of Products through them.
        We reserve the right to disable or terminate your account in our sole
        discretion for any and no reason, including if, in our opinion, you
        violate these Terms.
      </p>
      <p>
        <strong>1.3</strong> When you visit the Website or send SMS text
        messages, e-mails to us, or online chat with us, you consent to receive
        communications from us electronically. Additional information regarding
        your communications with us is available in our Privacy Policy found at{' '}
        <a href={`https://www.thefarmersdog.com${paths.PATH_PRIVACY}`}>
          https://www.thefarmersdog.com/content/privacy
        </a>{' '}
        <strong>(“Privacy Policy”)</strong>. You agree that all agreements,
        notices, disclosures, and other communications that we provide to you
        electronically satisfy any legal requirement that such communications be
        in writing. In addition, by using our Services or Products, you consent
        to receive SMS text messages sent through an automatic telephone dialing
        system on the phone number associated with your account. You understand
        that you are not required to provide this consent to receive SMS text
        messages as a condition of purchasing any Products from us. You also
        understand that you may opt out of receiving text messages from us at
        any time, by contacting{' '}
        <a href={`mailto:${EMAIL_HELP}`}>{EMAIL_HELP}</a> or by replying “STOP”
        to any text messages received from us. You can also manage your
        communication preferences in your account. Please note that our Privacy
        Policy contains additional information regarding our use of information
        provided by you or collected by us in connection with your use of the
        Website, Services and Products.
      </p>
      <h2>2. Content and links.</h2>
      <p>
        <strong>2.1</strong> The text, images, photographs, graphics, logos,
        illustrations, descriptions, data, and other material provided by us or
        otherwise through the Services, as well as the selection, assembly, and
        arrangement thereof, are referred to collectively as the “
        <strong>Content</strong>.” We may change, delete, or update any Content
        at any time, in our sole discretion and without prior notice. The
        Content is provided for informational purposes only and is not binding
        on us in any way except as provided in these Terms.
      </p>
      <p>
        <strong>2.2</strong> The Farmer’s Dog name, trademark, service mark and
        logo and all related names, logos, Product and Service names, designs
        and slogans (“
        <strong>Marks</strong>”), and all Content are protected under copyright,
        trademark, and other intellectual property laws. You agree that we
        and/or our licensors exclusively own all worldwide right, title and
        interest in and to the Marks, Content and Services (including any and
        all intellectual property rights therein) and you agree not to take any
        action(s) inconsistent with such ownership interests. We and our
        licensors reserve all rights in connection with the Marks, Services and
        Content including, without limitation, the exclusive right to create
        derivative works. You may view and use the Content only for your
        personal information and for using the Services, and for no other
        purpose, including but not limited to, any commercial purpose. You shall
        not challenge, contest, or otherwise impair our ownership of the
        Website, Marks, Content and Services or the validity or enforceability
        of our intellectual property rights therein. Any use of the Content,
        except as specifically permitted in these Terms or as otherwise
        expressly permitted in the Content or in a writing signed by us, is
        strictly prohibited. If you print, copy, modify, download, or otherwise
        use or provide any other person with access to any part of the Website
        or Services in breach of these Terms, your right to use the Website, and
        the Services will stop immediately and you must, at our option, return
        or destroy any copies of the materials you have made.
      </p>
      <p>
        <strong>2.3</strong> Links to other Internet sites operated by third
        parties, including Website vendors, do not constitute our sponsorship,
        endorsement, or approval of the content, policies, or practices of such
        linked sites. Linked sites are not operated, controlled, or maintained
        by the Services or by us, and we are not responsible for the
        availability, content, security, policies, or practices of linked sites,
        including, without limitation, privacy policies and practices. Links to
        other sites are provided for your convenience only, and you access them
        at your own risk.
      </p>
      <h2>3. Services access.</h2>
      <p>
        <strong>3.1</strong> We hereby grant you a limited right to access and
        make personal use of the Services. However you may not download (other
        than page caching) or modify the Services, or any portion of them,
        except with our express prior written consent. This access right does
        not include: any resale or commercial use of the Services, including the
        Website or its contents; any collection and use of any Product listings,
        descriptions, or prices; any derivative use of the Services, including
        the Website or its contents; any downloading or copying of account
        information for the benefit of another merchant; or any use of data
        mining, robots, or similar data gathering and extraction tools. Neither
        the Services nor any portion of the Services may be reproduced,
        duplicated, copied, sold, resold, visited, or otherwise exploited for
        any purpose other than the personal, non-commercial use described above,
        without our express written consent.
      </p>
      <p>
        <strong>3.2</strong> You may not frame or utilize framing techniques or
        use crawlers, spiders or automated data mining or artificial
        intelligence tools to access the Website or Services, including any
        Content, trademark, logo, or other proprietary information (including
        images, text, page layout, or form) of the Services without our express
        prior written consent. You may not use any meta tags or any other
        “hidden text” utilizing the Website’s name or any Marks without our
        express prior written consent. Any unauthorized use terminates the
        permission granted in Section 3.1. Any violation of these Terms by you
        may subject you to civil and/or criminal penalties.
      </p>
      <p>
        <strong>3.3</strong> You may use the Services only for lawful purposes
        and in accordance with these Terms.
      </p>
      <p>
        You agree not to use the Website, (i) in any way that violates any
        applicable federal, state, local, or international law or regulation
        (including, without limitation, any laws regarding the export of data or
        software to and from the US or other countries), (ii) for the purpose of
        exploiting, harming, or attempting to exploit or harm minors in any way
        by exposing them to inappropriate content, asking for personally
        identifiable information, or otherwise, (iii) to transmit, or procure
        the sending of, any advertising or promotional material, except as
        specifically provided for herein or with our prior written consent,
        including any &quot;junk mail,&quot; &quot;chain letter,&quot;
        &quot;spam,&quot; or any other similar solicitation, (iv) to impersonate
        or attempt to impersonate us, our employees, another user, or any other
        person or entity (including, without limitation, by using email
        addresses or user names associated with any of the foregoing), (v) to
        engage in any other conduct that restricts or inhibits anyone&apos;s use
        or enjoyment of the Website, or which, as determined by us, may harm us
        or users of the Website, or expose them to liability, (vi) in any manner
        that could disable, overburden, damage, or impair the Website or
        interfere with any other party&apos;s use of the Website, including
        their ability to engage in real time activities through the Website,
        (vii) with any device, software, or routine that interferes with the
        proper working of the Website, (viii) to introduce any viruses, Trojan
        horses, worms, logic bombs, or other material that is malicious or
        harmful, (ix) to attempt to gain unauthorized access to, interfere with,
        damage, or disrupt any parts of the Website, the servers on which the
        Website is stored or operated, any server, computer, or database
        connected to the Website, or any customer accounts and services, or (x)
        in any way that otherwise attempts to interfere with the proper working
        of the Website.
      </p>
      <div id={productAndSubscriptionSectionId}>
        <h2>4. Subscriptions, Renewals and Cancellations.</h2>
        <p>
          <strong>4.1 </strong>{' '}
          <strong>Recurring Subscription Payments. </strong>
          When you sign up to one of our subscription plans, you will be
          subscribed to recurring orders of Products from us. Our subscription
          plan consists of an initial charge followed by recurring periodic
          charges at the intervals set forth in{' '}
          <a href={`https://www.thefarmersdog.com${paths.PATH_LOGIN}`}>
            your subscription page
          </a>
          .{' '}
          <strong>
            You acknowledge that your subscription has an initial and recurring
            payment feature and you accept responsibility for all recurring
            charges prior to cancellation of your subscription. The recurring
            price of your subscription depends on your selected plan, and can be
            found at your account under the &quot;Orders&quot; section.
            Following the conclusion of any free or discounted trial or any
            additional discounted boxes at our discretion, you agree that you
            will be charged the full applicable price for your subscription
            until you cancel. You must cancel in accordance with the
            cancellation policy described in section 4.2 below to avoid being
            charged the full price. We may submit periodic charges for fees due
            under your subscription without further authorization from you until
            you cancel your subscription. You agree that (a) we or our third
            party payment processor may store and continue billing your payment
            method (e.g. credit card) to avoid interruption of your subscription
            (including attempted rebilling of a failed payment method), and (b)
            we may calculate taxes payable by you based on the billing
            information that you provide us at the time of purchase. Please
            refer to our{' '}
            <a href={`https://www.thefarmersdog.com${paths.PATH_FAQ}`}>
              The Farmer’s Dog FAQ
            </a>{' '}
            for further instructions. You will not receive your order until
            payment is successfully processed.
          </strong>
        </p>
        <p>
          <strong>4.2 </strong>
          <strong>
            Automatic Renewal and Cancellation. You agree that if you purchase a
            subscription through the Services, your subscription will
            automatically renew at the subscription period frequency that you
            selected and that is referenced in your account, and your payment
            method will automatically be charged at the start of each new
            subscription period for the fees and taxes applicable to that
            period, until you cancel. Each charge will be submitted for payment
            on or around the next day following your subscription&apos;s
            cancellation deadline. In the event that an attempt to charge your
            payment method fails, we reserve the right to retry billing your
            payment method on subsequent dates. To avoid future subscription
            charges, you must cancel your subscription on or before the
            Wednesday prior to the start day of the delivery window indicated in{' '}
            <a href={`https://www.thefarmersdog.com${paths.PATH_LOGIN}`}>
              your account
            </a>
            . To cancel, navigate to{' '}
            <a href={`https://www.thefarmersdog.com${paths.PATH_LOGIN}`}>
              your account
            </a>{' '}
            and under the “Settings” page, scroll to the Subscription section at
            the bottom of page, and click “Cancel Subscription.” You may also
            contact customer service for cancellation assistance at (646)
            780-7957. Subscription fees are not refundable, in whole or in part.
            If you cancel your subscription, you will continue to enjoy your
            subscription benefits until the expiration of the then-current
            subscription term for which you have paid, and your subscription
            benefits will expire at the end of the then-current subscription
            term. In lieu of canceling your subscription, you may pause your
            subscription at any time by navigating to{' '}
            <a href={`https://www.thefarmersdog.com${paths.PATH_LOGIN}`}>
              your account
            </a>{' '}
            under the “Settings” page, scrolling to the Subscription section at
            the bottom of the page, and clicking “Pause Deliveries.” You may
            select the date upon which your subscription will restart and we
            will notify you before we begin preparing your next order.
          </strong>
        </p>
      </div>
      <h2>5. Products, Orders, Shipment, Pricing and Payment.</h2>
      <p>
        <strong>5.1 </strong> <strong>Payment Processor.</strong> We partner
        with a third-party payment provider (the &quot;Payment Processor&quot;)
        to handle billing for your subscription. Payment processing is governed
        by both the Payment Processor’s terms, conditions, and privacy policies
        as well as our Terms of Use. Currently, we use Stripe, Inc. as our
        Payment Processor. You can review Stripe’s Terms of Service at{' '}
        <a href="https://stripe.com/us/checkout/legal">
          https://stripe.com/us/checkout/legal
        </a>{' '}
        and their Privacy Policy at{' '}
        <a href="https://stripe.com/us/privacy">
          https://stripe.com/us/privacy
        </a>
        . We are not liable for any errors, omissions, or actions by the Payment
        Processor. By subscribing, you agree to pay the subscription fees
        through the Payment Processor at the current rates, according to the
        applicable payment terms. You also authorize us, via the Payment
        Processor, to charge your selected payment method (your &quot;Payment
        Method&quot;). It is your responsibility to ensure that payments are
        made using the correct Payment Method. We retain the right to correct
        any billing errors or discrepancies, even if the Payment Processor has
        already processed your payment.
      </p>
      <p>
        <strong>5.2 </strong> <strong>Pricing Changes.</strong> Product pricing
        and our delivery charges, if any, will be as quoted through the Website
        and Services from time to time, and may not include applicable taxes. We
        reserve the right to change our subscription plans or adjust pricing for
        our Products in any manner and at any time as we may determine in our
        sole and absolute discretion. Except as otherwise provided in these
        Terms, any price changes or changes to your subscription plan will take
        effect following reasonable notice to you. If you have not canceled your
        subscription within the specified time after receiving notice of a price
        change, your subscription will auto-renew at the price indicated in your
        notice.
      </p>
      <p>
        <strong>5.3 </strong> <strong>Payments.</strong> If you purchase a
        subscription to our Products, you agree to pay us the applicable fees
        and taxes in U.S. Dollars. Failure to pay these fees and taxes will
        result in the termination of your subscription. Payment for all Products
        must be by credit or debit card. We accept payment with American
        Express, Visa and Mastercard. We reserve the right to change the payment
        methods we accept at any time. You represent and warrant that all
        information you provide with regards to a purchase of our Products,
        including, without limitation, credit card or other payment information,
        is accurate, current and complete. You represent and warrant that you
        have the legal right to use the payment method you provide to us or our
        Payment Processor, including, without limitation, any credit card you
        provide when completing a transaction.
      </p>
      <p>
        <strong>5.4 </strong> <strong>Credit Card Authorization.</strong> You
        may see credit card authorization holds when you (i) add or update your
        method of payment, (ii) purchase a paid, recurring subscription, or
        (iii) sign up for a free trial subscription. Authorization holds,
        pre-authorization holds, and pending charges are common anti-fraud
        practices that do not represent actual charges. The amount and duration
        of the hold varies depending on your credit card company’s policies, but
        generally speaking, this activity appears as a $1 (USD) charge that is
        released once your credit card is validated. Feel free to contact your
        credit card company with any questions.
      </p>
      <p>
        <strong>5.5</strong> <strong>Order Acceptance; Shipment.</strong> Once
        we receive your order for Products, we will provide you with an order
        confirmation. Your receipt of an order confirmation, however, does not
        signify our acceptance of your order, nor does it constitute
        confirmation of our offer to sell; we are simply confirming that we
        received your order. We reserve the right, in our sole discretion to
        accept or decline your order, cancel your subscription or order, or
        limit Product quantities for any or no reason. We will confirm our
        acceptance of your order by sending a communication that confirms that
        your order has been processed. If we cancel an order after you have
        already been billed, then we will refund the billed amount. For most
        orders, you will be charged on the Thursday prior to your upcoming order
        delivery date. In the event of cancellation, we will refund your
        payment. Title and risk of loss for any purchases of Products pass to
        you upon our delivery to our carrier. We reserve the right to ship
        partial orders (at no additional cost to you), and the portion of any
        order that is partially shipped may be charged at the time of shipment.
        All orders are shipped using one of our third party couriers. Online
        tracking may be available at our courier’s website (for example, FedEx),
        though we make no warranties regarding its availability because it is
        not under our control. While deliveries may be scheduled for a specified
        arrival, any delivery timelines we provide are estimates only and we
        cannot guarantee delivery by any specific date or time.
      </p>
      <p>
        <strong>5.6</strong> All Products available through the Services are for
        personal use only. You may not resell any of the Products that you
        purchase or receive from us. If you are a wholesaler or retailer
        interested in purchasing items in bulk, please email{' '}
        <a href="mailto:partners@thefarmersdog.com">
          partners@thefarmersdog.com.
        </a>
      </p>
      <p>
        <strong>5.7</strong> All offers of Products as they are displayed via
        the Services are subject to availability. We reserve the right to modify
        the items offered via the Services at any time. If a Product is not
        available for shipping promptly after you place your order, or upon the
        next delivery date under your subscription, you will be notified, and
        you may choose to order a different item that is then-currently
        available, to wait until the Product is available (provided it will be
        restocked within a reasonable timeframe) or to cancel your order. We
        will not be liable if Products are not in stock or otherwise not
        available. If a Product is listed at an incorrect price or with
        incorrect information due to typographical error or otherwise, we
        reserve the right to not process or to cancel any orders placed for such
        Product.
      </p>
      <p>
        <strong>5.8</strong> We may change our Products, in our sole discretion,
        to reflect changes in relevant laws and regulatory requirements which
        impact either the pet food market or the natural products markets in the
        US or other relevant countries, or to implement minor technical
        adjustments and improvements. As required by applicable law and
        regulatory requirements, we will advise you if there is likely to be any
        significant difference in the Products.
      </p>
      <h2>6. The Farmer’s Dog Referral Program Terms And Conditions.</h2>
      <p>
        We offer a referral program (“
        <strong>The Farmer’s Dog Referral Program</strong>”) that provides
        eligible The Farmer’s Dog customers (each, a “<strong>Referrer</strong>
        ”) with the opportunity to refer eligible individuals to purchase
        Products from us (each, and as further defined below, a “
        <strong>Referred Customer</strong>”). Occasionally, The Farmer’s Dog
        Referral Program may offer temporary promotions, discounts, incentives,
        or store credit (collectively, “<strong>TFD Credit</strong>”), which may
        be adjusted or discontinued by us, at any time in our sole discretion.
        This Section 6 governs your participation in The Farmer’s Dog Referral
        Program. To the extent this Section 6 contains any terms or conditions
        inconsistent with the remainder of these Terms, this Section 6 will
        control with respect to The Farmer&apos;s Dog Referral Program only.
      </p>
      <p>
        We will send Referrers a notice (an “<strong>Invitation</strong>”) with
        a unique referral link (“
        <strong>Referral Link</strong>”), which can be shared with the
        Referrer’s friends and family. Invitations are provided by us in our
        sole discretion and customers are not guaranteed to receive an
        Invitation. The Invitation will specify the TFD Credit the Referrer will
        receive for each Referred Customer (as defined below), if any (the “
        <strong>Referrer Credit</strong>”) and the TFD Credit each Referred
        Customer will receive, if any (the “<strong>Referred Credit</strong>”).
        Referrers must follow any and all terms, conditions and guidance set
        forth in the Invitation to qualify for Referrer Credit, including with
        respect to the manner in which the Referrer can share the Referral Link
        and the date when the Referral Link will expire. By participating in The
        Farmer’s Dog Referral Program, you represent and warrant that (i) you
        are 18 years of age or older; (ii) you are a legal resident of the
        United States; (iii) you have the necessary consent from each such
        potential customer to whom you send a message regarding The Farmer’s Dog
        Referral Program or the Referral Link; and (iv) you will at all times
        remain compliant with CAN-SPAM, the TCPA and any other applicable laws.
      </p>
      <p>
        You understand and agree that any communications you send with respect
        to The Farmer’s Dog Referral Program are being sent by you as an
        individual, on your own behalf, and not on behalf of us. You are not
        acting as our agent, partner, or contractor or acting in any
        representative capacity, nor will you be deemed our employee. Therefore,
        while, as a Referrer, you can and should include your Referral Link in
        communications about The Farmer’s Dog Referral Program, you must not,
        without our written permission, include any of our Marks or other
        materials in such communications. The content of all of your
        communications about The Farmer’s Dog Referral Program must be yours
        alone. You will not misrepresent or embellish your relationship with us
        or write, say or post anything you know is untrue or may be misleading,
        or make any representations or warranties about the Services or Products
        other than those expressly listed by us on the Website or otherwise
        provided to you in writing. And, if someone has asked you to stop
        sending them messages about us, you will respect that wish.
      </p>
      <p>
        A “<strong>Referred Customer</strong>” means an individual who:
      </p>
      <p>
        (a) clicks on a Referral Link to place an order on the Website or
        through the Services before The Farmer’s Dog Referral Program or
        campaign end date;
      </p>
      <p>
        (b) does not cancel such order prior to shipment and does not return or
        seek a refund for such order within ninety (90) calendar days (the “
        <strong>Return Period</strong>”); and
      </p>
      <p>
        (c) is a new (and not a returning or existing) The Farmer’s Dog customer
        who is a legal resident of the United States and at least eighteen (18)
        years of age. For the avoidance of doubt, any individual or household
        that has previously created a customer account through the Services
        cannot be a Referred Customer.
      </p>
      <p>
        In order to be eligible for a Referral Reward, a Referrer must refer a
        minimum of one (1) Referred Customer to us, or the minimum number as may
        be designated in the rules specific to each campaign, which may be
        adjusted at any time in our sole discretion. When a Referred Customer
        has used a Referrer&apos;s Referral Link to satisfy the above
        conditions, the applicable Referrer is eligible to receive the Referrer
        Credit, which IF AVAILABLE will be specified in the Invitation. Referrer
        Credit and Referred Credit will be sent to the applicable Referrer or
        Referred Customer within thirty (30) days subject to (i) each individual
        being a qualified Referrer and Referred Customer; and (ii) the Referrer
        and Referred Customer meeting all of the required terms and conditions
        of this Section 6. Unless otherwise set forth in an Invitation or other
        communication from us, each Referrer is eligible to receive only up to
        Six Hundred US Dollars ($600) in TFD Credit per calendar year (i.e.,
        after such Referrer has earned Six Hundred US Dollars ($600) of TFD
        Credit in a single calendar year, such Referrer will not be eligible for
        any additional Referral Credits even if additional Referred Customers
        satisfy the above conditions using such Referrer&apos;s Referral Link).
        The amount and availability of the Referral Reward are subject to change
        at any time in our sole discretion. We may, in our sole discretion, send
        Referrers who have reached their Referral Credit limit another
        Invitation, which may or may not contain the same terms and conditions.
      </p>
      <p>
        Each Referred Customer will also receive the Referred Credit or
        promotion specified in the relevant Referral Link. An individual may
        create only one (1) customer account through the Services and may
        receive only one (1) Referred Reward for becoming a Referred Customer,
        even if the individual receives and attempts to click through multiple
        Referral Links.
      </p>
      <p>
        We will apply any TFD Credit to the next purchase made by the Referrer
        or Referred Customer (or any other recipient of TFD Credit), as
        applicable. TFD Credit is not transferable or exchangeable. TFD Credit
        can only be used on the Website to purchase Services or Products that
        are made available at that time from us. TFD Credits cannot be redeemed
        for cash or cash equivalents. Unauthorized or fraudulent use, resale,
        acquisition, or distribution of TFD Credit is prohibited. We may cancel,
        revoke, or otherwise prevent the issuance of TFD Credit if we suspect
        any unauthorized or fraudulent use of TFD Credit and/or if we mistakenly
        credit a Referrer or Referred Customer’s account with TFD Credits. TFD
        CREDIT MUST BE REDEEMED WITHIN TWELVE (12) MONTHS OF RECEIPT BY THE
        REFERRER OR REFERRED CUSTOMER OR ANY OTHER RECIPIENT. Referral Rewards
        and Referred Rewards may contain additional limitations, as set forth in
        the applicable Invitation.
      </p>
      <p>
        In addition to the conditions set forth in Section 14.1, we may
        terminate your eligibility to earn a Referral Reward or Referred Reward
        or terminate your account for the Services if you take any of the
        following actions:
      </p>
      <p>
        (a) Open multiple accounts, including with different e-mail addresses,
        in order to generate additional Referral Rewards or Referred Rewards;
      </p>
      <p>
        (b) Refer customers using spam, display advertising, sponsored links,
        unsolicited e-mails, automated systems or bots, scripts or automatic
        dialers or links on message boards, groups or forums.
      </p>
      <p>
        (c) Use false names, refer yourself, impersonate other people, or
        otherwise provide false or misleading information to us;
      </p>
      <p>
        (d) Offer incentives beyond those specified in the relevant Invitation
        to others who register new accounts;
      </p>
      <p>
        (e) Use The Farmer’s Dog brand in connection with disparaging or
        defamatory content, content that promotes racism, bigotry, hatred,
        discrimination or physical harm against any group or individual,
        offensive, abusive, intimidating or harassing content, content that is
        sexually explicit, obscene and/or contains nudity, any political and/or
        religious statement, any content containing images or reference to
        illegal drugs, alcohol, tobacco, weapons or ﬁrearms or content that
        violates someone else’s privacy;
      </p>
      <p>(f) Sell or otherwise attempt to transfer your Referral Link;</p>
      <p>(g) Violate or infringe the rights of any third party; or</p>
      <p>
        (h) Pay to advertise your Referral Link or The Farmer’s Dog Referral
        Program, including via Google, Facebook, Instagram, Twitter, Bing and
        Craigslist.
      </p>
      <p>
        By using a referral code during the sign-up process, you agree that the
        code has been legitimately obtained and is used in accordance with our
        Terms. Misuse of referral codes, including but not limited to using
        compromised, flagged, or unauthorized codes, is strictly prohibited and
        may result in the immediate cancellation of any related Referrer
        Rewards, Referral Rewards, Promotions (as further defined below),
        trials, or other offers.
      </p>
      <p>
        In the event that we determine a referral code has been compromised or
        misused, we reserve the right to take the following actions without
        prior notice in our sole discretion:
      </p>
      <ol>
        <li>
          <strong>Cancellation:</strong> Any pending trials, subscriptions,
          Promotions, discounts or offers associated with the flagged referral
          code may be canceled immediately.
        </li>
        <li>
          <strong>Account Holds:</strong> If there is an authorization hold or
          pending charge on your payment account due to the trial sign-up, it
          will be released or voided in accordance with your payment
          provider&apos;s standard procedures. You acknowledge that this process
          may take several business days depending on your financial
          institution.
        </li>
        <li>
          <strong>Account Review:</strong> We may review your account for
          further breaches of our Terms and take any necessary actions,
          including restricting or terminating your access to our Website,
          Services and/or Products.
        </li>
      </ol>
      <p>
        If you believe a related action was taken in error, or if you have
        questions about the status of your trial or account, please contact our
        customer service team for assistance.
      </p>
      <p>
        TFD Credit may also be issued at our discretion for general returns or
        as part of promotional offers. TFD Credit will be provided in the form
        of a digital credit applied to your account on our Website. If a
        purchase made with TFD Credit is returned, the amount of the original
        purchase will be credited back to your account. No cash refunds will be
        provided. To utilize TFD Credit, you must have an active account with
        us. TFD Credit cannot be applied to purchases made before the TFD Credit
        was issued or to orders placed through any other channel. We reserve the
        right to modify or discontinue the TFD Credit policy at any time without
        prior notice. Any changes to the policy will not affect store TFD Credit
        that has already been issued. For any questions or concerns regarding
        store credit, please contact our customer service team for assistance.
      </p>
      <p>
        We may at any time rescind or cancel The Farmer’s Dog Referral Program
        for all of our customers, in our sole discretion. In the event of our
        general withdrawal of The Farmer’s Dog Referral Program for all our
        customers, any unused TFD Credits (including all unused Referred Credits
        and Referred Credits) outstanding at that time will be canceled and
        deemed to be void, subject to applicable law.
      </p>
      <h2>7. Promotional discount links.</h2>
      <p>
        The following terms and conditions, together with the Terms, govern the
        use of our promotional links that may be redeemed for discounts on
        Products or other features or benefits related to the Services (“
        <strong>Promotion</strong>”):
      </p>
      <p>
        (a) Promotions: (i) must be used in a lawful manner; (ii) must be used
        for the intended audience and purpose; (iii) may not be duplicated, sold
        or transferred in any manner, or made available by you to the general
        public (whether posted to a public forum, coupon collecting service, or
        otherwise), unless expressly permitted by us; (iv) may be disabled or
        have additional conditions applied to them by us at any time for any
        reason without liability to you; (v) may be used only pursuant to the
        specific terms that we establish for such Promotion; (vi) are not valid
        for cash or other credits or points; and (vii) may expire prior to your
        use.
      </p>
      <p>(b) Promotions cannot be combined.</p>
      <p>
        (c) Promotions may have an expiration date, as indicated at the time of
        issuance. We may adjust expiration and offer amount at any time in our
        sole discretion.
      </p>
      <p>
        (d) Promotions can be redeemed only through the Services toward the
        purchase of Products available thereon. They cannot be applied to any
        orders previously placed.
      </p>
      <p>
        (e) Promotions are non-transferable and may not be returned or redeemed
        for cash (except as required by law).
      </p>
      <p>
        (f) To redeem a Promotion you must click the promotional link before
        purchase. Additional restrictions may apply, as indicated at the time of
        issuance.
      </p>
      <p>
        (g) All remaining balances exceeding the value of your Promotion must be
        paid by another acceptable form of payment.
      </p>
      <p>
        (h) Delivery of an electronic Promotion to an incorrect or non-existent
        email address is the sole responsibility of the purchaser. Delivery of a
        physical Promotion to an incorrect or non-existent shipping address is
        the sole responsibility of the purchaser. We are not responsible if a
        Promotion is lost, stolen, destroyed or used without permission.
        Promotions will not be replaced if lost or stolen.
      </p>
      <p>
        (i) Sales tax and shipping charges are applicable to any items purchased
        with a Promotion.
      </p>
      <p>
        (j) In addition to the conditions set forth in Section 14 we may
        terminate your eligibility to redeem a Promotion or terminate your
        account for the Services if you take any of the following actions:
        <ol type="i">
          <li>
            Open multiple accounts, including with different e-mail addresses or
            other information
          </li>
          <li>
            Use false names, impersonate other people, or otherwise provide
            false or misleading information about you or the dog(s) to us;
          </li>
        </ol>
      </p>
      <p>
        (k) We reserve the right, in our sole discretion, to refuse, modify,
        cancel or hold for review any Promotion and orders for suspected fraud,
        for Promotions mistakenly issued in an incorrect denomination, or for
        other violations of the Promotion or Services policies, in whole or in
        part for any reason or no reason to the extent permitted by law. If we
        suspect misuse of a Promotion, the Promotion and the account may be
        suspended or terminated. We may cancel or rescind any Promotion at any
        time, in our sole discretion.
      </p>
      <h2>8. User Contributions.</h2>
      <p>
        The Services may contain message boards, personal web pages or profiles,
        forums, bulletin boards, review sections, and other interactive features
        (collectively, <strong>“Interactive Services”</strong>) that allow users
        to post, submit, publish, display, or transmit to other users or other
        persons (hereinafter, “post”) content or materials (collectively,{' '}
        <strong>“User Contributions”</strong>) on or through the Services.
      </p>
      <p>
        All User Contributions must comply with the Content Standards set out in
        these Terms.
      </p>
      <p>
        Any User Contribution you post to the Services will be considered
        non-confidential and non-proprietary and you acknowledge and agree that
        you have no expectation of privacy with regard to such User
        Contributions. By providing any User Contribution on the Services, you
        grant us and our affiliates, and each of our respective licensees,
        successors, and assigns a perpetual, irrevocable, world-wide,
        transferable, sublicensable, royalty-free, and fully-paid (with no
        further payment or consideration to you) right and license to use,
        reproduce, modify, perform, display, distribute, create derivative works
        and otherwise disclose to third parties any such material for any
        purpose.
      </p>
      <p>
        You represent and warrant that (i) you own or control all rights in and
        to the User Contributions and have the right to grant the license
        granted above, and (ii) all of your User Contributions do and will
        comply with these Terms.
      </p>
      <p>
        You understand and acknowledge that you are responsible for any User
        Contributions you post, and you, not The Farmer’s Dog, have full
        responsibility for such content, including its legality, reliability,
        accuracy, and appropriateness.
      </p>
      <p>
        We are not responsible or liable to any third party for the content or
        accuracy of any User Contributions posted by you or any other user of
        the Website.
      </p>
      <p>
        We comply with the provisions of the Digital Millennium Copyright Act
        applicable to internet service providers (17 U.S.C. §512, as amended)
        (the <strong>“DMCA”</strong>). If you believe that any Content or the
        Website infringes your copyright, you can reach our notification agent
        at dmca.agent@thefarmersdog.com. In notifying us of any alleged
        copyright infringement, the DMCA requires that you include the following
        information: (i) description of the copyrighted work that is the subject
        of claimed infringement; (ii) description of the infringing material and
        information sufficient to permit us to locate the alleged material;
        (iii) contact information for you, including your address, telephone
        number and/or e-mail address; (iv) a statement by you that you have a
        good faith belief that the material in the manner complained of is not
        authorized by the copyright owner, or its agent, or by the operation of
        any law; (v) a statement by you, signed under penalty of perjury, that
        the information in the notification is accurate and that you have the
        authority to enforce the copyrights that are claimed to be infringed;
        and (vi) a physical or electronic signature of the copyright owner or a
        person authorized to act on the copyright owner’s behalf. Failure to
        include all of the above-listed information may result in the delay of
        the processing of your complaint.
      </p>
      <h2>9. Monitoring and Enforcement; Termination.</h2>
      <p>
        We have the right to (i) remove or refuse to post any User Contributions
        for any or no reason in our sole discretion, (ii) take any action with
        respect to any User Contribution that we deem necessary or appropriate
        in our sole discretion, including if we believe that such User
        Contribution violates the Terms, including the Content Standards,
        infringes any intellectual property right or other right of any person
        or entity, threatens the personal safety of users of the Website or the
        public, or could create liability for us, (iii) disclose your identity
        or other information about you to third parties if we receive a valid
        legal request or claim that material posted by you violates their
        rights, including their intellectual property rights or their right to
        privacy, and (iv) take appropriate legal action, including without
        limitation, referral to law enforcement, for any illegal or unauthorized
        use of the Website.
      </p>
      <p>
        Without limiting the foregoing, we have the right to cooperate fully
        with any law enforcement authorities or court order requesting or
        directing us to disclose the identity or other information of anyone
        posting any materials on or through the Services. YOU WAIVE AND AGREE TO
        HOLD HARMLESS US AND OUR AFFILIATES, FROM ANY CLAIMS RESULTING FROM ANY
        ACTION TAKEN BY ANY SUCH PERSONS DURING, OR TAKEN AS A CONSEQUENCE OF,
        INVESTIGATIONS BY SUCH PERSONS.
      </p>
      <p>
        We do not review all material before it is posted on the Services, and
        cannot ensure prompt removal of objectionable material after it has been
        posted. Accordingly, we assume no liability for any action or inaction
        regarding transmissions, communications, or content provided by any user
        or third party. We have no liability or responsibility to anyone for
        performance or nonperformance of the activities described in this
        section.
      </p>
      <h2>10. Content Standards.</h2>
      <p>
        These content standards apply to any and all User Contributions and use
        of Interactive Services. User Contributions must in their entirety
        comply with all applicable federal, state, local, and international laws
        and regulations. Without limiting the foregoing, User Contributions must
        not (i) contain any material that is defamatory, obscene, indecent,
        abusive, offensive, harassing, violent, hateful, inflammatory, or
        otherwise objectionable, (ii) promote sexually explicit or pornographic
        material, violence, or discrimination based on race, sex, religion,
        nationality, disability, sexual orientation, or age, (iii) infringe any
        patent, trademark, trade secret, copyright, or other intellectual
        property or other rights of any other person, (iv) violate the legal
        rights (including the rights of publicity and privacy) of others or
        contain any material that could give rise to any civil or criminal
        liability under applicable laws or regulations or that otherwise may be
        in conflict with these Terms and our{' '}
        <a
          href={`${publicUrl}${paths.PATH_PRIVACY}`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        , (v) be likely to deceive any person, (vi) promote any illegal
        activity, or advocate, promote, or assist any unlawful act, (vii) cause
        annoyance, inconvenience, or needless anxiety or be likely to upset,
        embarrass, alarm, or annoy any other person, (viii) impersonate any
        person, or misrepresent your identity or affiliation with any person or
        organization, (ix) involve commercial activities or sales (except as
        specifically provided for herein), such as contests, sweepstakes, and
        other sales promotions, barter, or advertising, or (x) give the
        impression that they emanate from or are endorsed by us or any other
        person or entity, if this is not the case.
      </p>
      <h2>11. Disclaimer and limitation of liability.</h2>
      <p>
        <strong>11.1</strong> <strong>GENERAL DISCLAIMER</strong>. WE MAKE NO
        WARRANTIES OR REPRESENTATIONS WHATSOEVER WITH RESPECT TO THE PRODUCTS OR
        THE WEBSITE, CONTENT AND SERVICES, INCLUDING THE AVAILABILITY OF THE
        WEBSITE OR THE ACCURACY, COMPLETENESS OR TIMELINESS OF ANY CONTENT,
        INFORMATION, OR MATERIALS AVAILABLE ON OR THROUGH THE WEBSITE. WE ALSO
        DO NOT WARRANT OR REPRESENT THAT YOUR ACCESS TO OR USE OF THE SERVICES
        WILL BE UNINTERRUPTED OR FREE OF ERRORS OR OMISSIONS, THAT DEFECTS WILL
        BE CORRECTED, OR THAT THE SERVICES AND ANY REFERRAL LINKS ARE FREE OF
        COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS. WITHOUT LIMITING THE
        FOREGOING, THE PRODUCTS AND THE SERVICES ARE PROVIDED TO USERS “AS IS,”
        WITH NO WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
        WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
        A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY, ACCURACY, OR
        THE AVAILABILITY OR QUALITY OF ANY PRODUCTS AVAILABLE THROUGH THE
        SERVICES. THE FOREGOING DISCLAIMERS ARE EXPRESSLY MADE A CONDITION OF
        ANY TRANSACTION ARISING THROUGH OR AS A RESULT OF THE SERVICES. Please
        note that some jurisdictions may not allow the exclusion of certain
        implied warranties. Solely to the extent that such laws apply to you,
        some or all of the above disclaimers, exclusions, or limitations may not
        apply to you. Check your local laws for any restrictions or limitations
        regarding the exclusion of implied warranties.
      </p>
      <p>
        <strong>11.2</strong> <strong>NO MEDICAL ADVICE</strong>. We may suggest
        a certain type of meal plan for your pet based on criteria specified by
        you through your account for the Services. YOU ACKNOWLEDGE THAT OUR
        RECOMMENDED MEAL PLAN IS INTENDED AS A GUIDE ONLY. IT IS YOUR
        RESPONSIBILITY AS A PET OWNER TO MONITOR YOUR PET’S WEIGHT AND
        CONDITION, AND TO LET US KNOW ANY CHANGES IN EITHER WEIGHT OR CONDITION
        AS THESE MAY IMPACT YOUR MEAL PLAN. IF YOU HAVE ANY CONCERNS ABOUT YOUR
        PET’S WEIGHT AND CONDITION, PLEASE CONSULT YOUR VETERINARIAN. ANY
        INFORMATION GIVEN BY ANY FARMER’S DOG TEAM MEMBER IS GENERAL AND
        INFORMATIONAL IN NATURE, AND NOT SPECIFIC TO YOUR PET AND WE ALWAYS
        RECOMMEND TO SPEAK TO YOUR LOCAL VETERINARIAN WHO KNOWS YOUR PET’S
        MEDICAL HISTORY AND CAN EXAMINE THEM IN PERSON. NOTHING IN THE WEBSITE
        OR SERVICES OR COMMUNICATED TO YOU BY US OR ANY OF OUR REPRESENTATIVES
        IS INTENDED TO BE, NOR SHOULD BE, TAKEN AS MEDICAL OR VETERINARY ADVICE
        AND WE ARE NOT RESPONSIBLE TO YOU OR ANYONE ELSE FOR ANY LOSS, DAMAGE,
        LIABILITY, COST OR EXPENSE SUFFERED IN CONNECTION WITH YOUR RELIANCE ON
        SUCH INFORMATION.
      </p>

      <p>
        <strong>11.3</strong> <strong>ALLERGENS</strong>. THE PRODUCTS WE SUPPLY
        MAY CONTAIN ALLERGENS. WE TRY TO IDENTIFY COMMON ALLERGENS CONTAINED IN
        THE PRODUCTS, BUT IT IS YOUR RESPONSIBILITY TO ENSURE THAT ANY FOOD YOU
        FEED TO YOUR PET DOES NOT CAUSE AN ADVERSE REACTION AND WE WILL NOT HAVE
        ANY LIABILITY FOR ANY ALLERGIC REACTION YOUR PET MAY HAVE OR ANY ADVERSE
        CONSEQUENCES RESULTING FROM AN ALLERGY.
      </p>
      <p>
        <strong>11.4</strong> <strong>PRODUCT IMAGES AND DESCRIPTIONS</strong>.
        The images and descriptions of our Products are for illustrative
        purposes only. The packaging of our Products may vary from that shown in
        images on the Website or otherwise made available through the Services.
      </p>
      <p>
        <strong>11.5</strong> <strong>LIMITATION OF LIABILITY</strong>. TO THE
        MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES SHALL WE, OUR
        SUPPLIERS, OR OUR OR THEIR RESPECTIVE AFFILIATES, DIRECTORS, OFFICERS,
        EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY LOST
        PROFITS, LOST REVENUE OR LOSS OF BUSINESS, OR ANY INDIRECT,
        CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, WHETHER IN
        CONTRACT OR IN TORT, INCLUDING NEGLIGENCE, ARISING IN ANY WAY OUT OF THE
        SERVICES, ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE
        SERVICES, OR THE PRODUCTS OR YOUR PURCHASE OR USE OF PRODUCTS FROM US,
        EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
        NO EVENT WILL THE COLLECTIVE LIABILITY OF THE FARMER’S DOG, AND OUR
        SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT
        PROVIDERS, SUPPLIERS, AND OUR AND THEIR RESPECTIVE EMPLOYEES, AGENTS,
        OFFICERS, AND DIRECTORS, REGARDLESS OF THE FORM OF ACTION (WHETHER IN
        CONTRACT, TORT, OR OTHERWISE), IN AGGREGATE EXCEED THE GREATER OF (A)
        $100 OR (B) THE AMOUNT YOU HAVE PAID TO US FOR ANY SERVICES WE PROVIDED
        IN THE TWELVE (12) MONTHS PRECEDING THE APPLICABLE CLAIM. IF ANY
        JURISDICTION PROHIBITS THE FOREGOING DISCLAIMERS, LIMITATIONS, AND
        EXCLUSIONS, IN SUCH JURISDICTIONS, THE FOREGOING DISCLAIMERS,
        LIMITATIONS, AND EXCLUSIONS SHALL BE LIMITED TO THE MINIMUM EXTENT
        REQUIRED BY THE JURISDICTION.
      </p>
      <h2>12. Indemnification.</h2>
      <p>
        You agree to defend, indemnify, and hold harmless us, our suppliers and
        its and their affiliates, directors, officers, employees, and agents
        from and against all claims, losses, damages, liabilities, and costs
        (including, without limitation, reasonable attorneys’ fees and court
        costs), arising out of or relating to your breach of these Terms, your
        access to or use of the Website, Content, Products or Services, your
        negligence or willful misconduct or your violation of any applicable law
        or third-party right. You are solely responsible for interactions with
        other users of the Services.
      </p>
      <h2>13. Dispute Resolution.</h2>
      <p>
        <strong>13.1</strong>{' '}
        <strong>Pre-Dispute Resolution Requirement</strong>. Before either party
        may initiate a legal proceeding against the other, the parties agree to
        engage in a good-faith effort to resolve any Claim (defined below) at
        issue, as defined in Section 13.2. The party asserting the Claim must
        notify the other party of the Claim by sending a written notice,
        including (i) the asserting party’s full name; (ii) the asserting
        party’s address, email, and phone number; (iii) a clear description of
        the Claim; and (iv) a clear description of the specific relief
        requested, pursuant to the notice provisions in Section 13.2. If
        requested by the other party, both parties agree to discuss the Claim in
        person, by telephone, or by video conference, and to attempt in good
        faith to resolve the Claim.
      </p>
      <p>
        If the parties are unable to resolve a Claim within thirty (30) days
        after receipt of a written notice pursuant to this provision, then the
        asserting party may pursue the Claim as otherwise set forth in these
        Terms. Failure to comply with this pre-dispute resolution requirement
        shall be grounds for dismissal of the Claim.
      </p>
      <p>
        <strong>13.2</strong>{' '}
        <strong>
          Agreement to Arbitrate and Waiver of Class, Representative, and
          Collective Actions
        </strong>
        . The parties agree that any and all claims, disputes, or controversies
        that have arisen or may arise between you and us, including without
        limitation any and all federal or state claims based in statute,
        contract, tort, fraud, or any other legal or equitable theory, as well
        as any dispute concerning the validity, enforceability, or scope of this
        agreement to arbitrate <strong>(“Claim(s)”)</strong>, shall be resolved
        exclusively through final, binding, and individual arbitration, and not
        in a court of law. This agreement shall not, however, require
        arbitration of (i) individual Claims brought in small claims court so
        long as the Claim remains in that court and proceeds only on an
        individual basis, or (ii) requests for injunctive or other equitable
        relief in a court of competent jurisdiction to prevent the actual or
        threatened infringement, misappropriation, or violation of a party’s
        copyrights, trademarks, trade secrets, patents, or other intellectual
        property rights.
      </p>
      <p>
        THERE IS NO JUDGE OR JURY IN ARBITRATION, DISCOVERY IS MORE LIMITED IN
        ARBITRATION THAN IN COURT, AND COURT REVIEW OF AN ARBITRATION AWARD IS
        LIMITED. THE ARBITRATOR MUST FOLLOW THIS AGREEMENT AND CAN AWARD ON AN
        INDIVIDUAL BASIS THE SAME RELIEF AS A COURT (INCLUDING ATTORNEYS’ FEES).
      </p>
      <p>
        Individual arbitrations commenced pursuant to this agreement shall be
        conducted by the American Arbitration Association{' '}
        <strong>(“AAA”)</strong> before a single arbitrator and shall be
        administered according to the AAA’s Consumer Arbitration Rules
        (available at{' '}
        <a href="https://www.adr.org/Rules" target="_blank" rel="noreferrer">
          https://www.adr.org/Rules
        </a>
        ) in effect at the time the arbitration commences. Any arbitration
        hearings shall be held in New York City or at another mutually
        agreed-upon location. In the event of any conflict between the
        arbitration rules and these Terms, these Terms shall govern. If for any
        reason the AAA is unavailable, the parties shall meet and confer to
        select another neutral arbitration provider.
      </p>
      <p>
        Each party is responsible for paying its share of any fees assessed by
        the arbitration provider and arbitrator. If, however, the value of the
        total relief sought in arbitration is $10,000 or less, you may ask us to
        consider advancing payment of your share of fees assessed by the
        arbitration provider and arbitrator, and we will consider the request in
        good faith. If the arbitrator determines that any Claim asserted by a
        party (including without limitation the damages or other relief
        requested) was frivolous or brought in bad faith, then the other party
        may seek to recover all attorneys’ fees and costs incurred in connection
        with the arbitration of that Claim.
      </p>
      <p>
        The parties agree that any arbitration between them shall be conducted
        only on an individual basis and not as a class, representative, or other
        collective action. Although the arbitrator can award on an individual
        basis the same relief as a court (including attorneys’ fees), the
        arbitrator shall have no authority to award relief to anyone who is not
        a party to the arbitration. Nothing in this agreement to arbitrate shall
        prevent either party from participating in a class, representative, or
        other collective settlement or release of claims.
      </p>
      <p>
        Unless the parties agree otherwise, the arbitrator may not consolidate,
        coordinate, or otherwise join together more than one individual
        arbitration under this agreement and may not preside over any such
        consolidated, coordinated, or joint proceeding. Each party agrees to not
        attempt to commence or join in any such consolidated, coordinated, or
        joint proceeding without the express consent of the other party.
        Notwithstanding anything else in this agreement to arbitrate, either
        party may ask a court to determine whether the other party has violated
        this prohibition on consolidated, coordinated, or joint proceedings, and
        may seek an order to enforce the terms of this prohibition.
      </p>
      <p>
        You may opt out of this arbitration agreement within thirty (30) days of
        your first acceptance of the Terms by sending us a written notice,
        including your (i) full name; (ii) address, email, and phone number; and
        (iii) a clear statement of your intent to opt out. The notice shall be
        sent to:{' '}
        <a href="mailto:legal@thefarmersdog.com">legal@thefarmersdog.com</a>.
      </p>
      <p>
        The Federal Arbitration Act governs the interpretation and enforcement
        of this agreement to arbitrate. To the extent state law applies, New
        York law (without regard to its choice of law provisions) shall govern.
        You and we agree that the United Nations Convention on Contracts for the
        International Sale of Goods will not apply to the interpretation or
        construction of these Terms. Your use of the Services may also be
        subject to other local, state, national, or international laws.
      </p>
      <p>
        With the exception of the class, representative, and collective action
        waiver above, if any part of this agreement to arbitrate is deemed by an
        arbitrator or court of competent jurisdiction to be invalid or
        unenforceable, the other parts of this agreement shall still apply. If a
        court decides that any part of this agreement to arbitrate is invalid or
        unenforceable because it would prevent the exercise of a non-waivable
        right to pursue public injunctive relief, then the parties agree that
        any dispute regarding entitlement to such relief (and only that relief)
        shall be severed from arbitration and may be litigated in court only
        after completion of arbitration. All other disputes subject to
        arbitration under this agreement shall proceed in arbitration pursuant
        to the terms of this agreement.
      </p>
      <p>
        <strong>13.3</strong> <strong>WAIVER OF JURY TRIAL</strong>. YOU AND THE
        FARMER’S DOG EACH EXPRESSLY WAIVE YOUR RIGHT TO GO TO COURT, TO A TRIAL
        BY JURY, AND TO PARTICIPATE IN A CLASS ACTION, CLASS ARBITRATION, OR
        OTHER REPRESENTATIVE PROCEEDING WITH RESPECT TO ANY CLAIM SUBJECT TO
        ARBITRATION. Notwithstanding the parties’ decision to resolve all
        disputes through arbitration, we may bring an action in a state,
        provincial or federal court to protect our intellectual property rights.
        Seeking such relief shall not waive our right to arbitration under these
        Terms.
      </p>
      <h2>14. General provisions.</h2>
      <p>
        <strong>14.1</strong> <strong>Termination</strong>. If you breach any of
        these Terms, all rights granted to you by us, including permission to
        use the Services, will terminate automatically. Additionally, we may
        suspend, disable, or cancel your access to the Website and/or Services,
        including your subscription and/or account with or without notice, for
        any or no reason. If we cancel your subscription or account for any
        suspected breach of these Terms by you, you are prohibited from
        re-registering for the Service under a different name. All sections
        which by their nature should survive the termination of these Terms
        shall continue in full force and effect subsequent to and
        notwithstanding any termination of your subscription, the Services or
        these Terms by us or you, including without limitation the provisions
        set forth in Sections 8, 9, 10, 11, 12, 13, and 14 of these Terms.
        Termination will not limit any of our other rights or remedies at law or
        in equity.
      </p>
      <p>
        <strong>14.2</strong> <strong>Injunctive Relief</strong>. You agree that
        your breach of these Terms will cause irreparable injury to us for which
        monetary damages would not be an adequate remedy and we will be entitled
        to equitable relief in addition to any remedies we may have hereunder or
        at law without a bond, other security or proof of damages.
      </p>
      <p>
        <strong>14.3</strong> <strong>Notices</strong>. We may give notice to
        you by means of a general notice on the Website, electronic mail,
        through your account, or by written communication sent by first class
        mail or pre-paid post. Such notice shall be deemed to have been given
        upon the expiration of 48 hours after mailing or posting (if sent by
        first class mail or pre-paid post) or 12 hours after sending (if sent by
        email or through your account). You may give notice to us by first class
        mail or pre-paid post to: The Farmer’s Dog, Inc. Attention: Legal
        Department, 201 Varick St., P.O. Box 984, New York, NY 10014, or at any
        time by sending an email to{' '}
        <a href="mailto:legal@thefarmersdog.com">legal@thefarmersdog.com</a>.
        Such notice shall be deemed to have been given upon the expiration of 48
        hours after mailing or posting (if sent by first class mail or pre-paid
        post) or 12 hours after sending (if sent by email or through your
        account). If sending by email, please specify the reason for the email
        in the subject line so it can be forwarded to the proper department.
      </p>
      <p>
        <strong>14.4</strong> <strong>Miscellaneous</strong>. No other act,
        document, usage or custom will be deemed to modify or amend these Terms.
        These Terms will inure to the benefit of and will be binding upon each
        party’s successors and permitted assigns. These Terms and the rights
        granted hereunder may be assigned by us but may not be assigned by you
        without our prior express written consent. Any attempt by you to assign
        these Terms without our written consent shall be null and void. If any
        provision hereof is or becomes, at any time or for any reason,
        unenforceable or invalid, then that provision will be deemed severable
        from these Terms and no other provision hereof will be affected thereby
        and the remaining provisions will continue with the same effect as if
        such unenforceable or invalid provisions will not have been inserted
        herein; to the maximum extent that the ability of either party to obtain
        substantially the bargained-for performance of the other will not have
        thereby been impaired. It is expressly understood that in the event
        either party on any occasion fails to perform any term hereof and the
        other party does not enforce that term, the failure to enforce on any
        occasion will not constitute a waiver of any term and will not prevent
        enforcement on any other occasion. Nothing contained in these Terms will
        be deemed to designate either party as the agent or representative of
        the other party or both parties as joint venturers or partners for any
        purpose. No provisions of these Terms create any third-party
        beneficiary. Except for any payment obligations hereunder, in the event
        that either party is prevented from performing, or is unable to perform,
        any of its obligations under these Terms due to any cause beyond the
        reasonable control of the party invoking this provision, the affected
        party’s performance will be extended for the period of delay or
        inability to perform due to such occurrence. The headings and captions
        contained herein will not be considered to be part of the Terms but are
        for convenience only.
      </p>
      <p>
        <strong>14.5</strong> <strong>California Residents</strong>. If you are
        a California resident, in accordance with Cal. Civ. Code &sect; 1789.3,
        you may report complaints to the Complaint Assistance Unit of the
        Division of Consumer Services of the California Department of Consumer
        Affairs by contacting them in writing at 1625 North Market Blvd., Suite
        N 112 Sacramento, CA 95834, or by telephone at <a>(800) 952-5210</a>.
      </p>
      <p>
        <strong>14.6</strong> <strong>Contact Us</strong>. If you have any
        questions regarding these Terms, the Services, or the Products, please
        contact us at 201 Varick St. PO Box 984, New York, NY 10014 or{' '}
        <a href={`mailto:${EMAIL_INFO}`}>{EMAIL_INFO}</a>, or by phone at{' '}
        <a>(646) 780-7957</a>.
      </p>
    </Container>
  );
}
