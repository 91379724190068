import type { ReactNode } from 'react';
import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useContext,
  createContext,
} from 'react';
import { reporter } from 'src/services/reporter';
import type { HandleStripeTokenEvent } from 'src/reducers/signup/checkout.types';

import { useStripe } from '@farmersdog/corgi-x';
import noop from 'lodash/noop';
import type {
  PaymentRequest,
  PaymentRequestShippingOption,
} from '@stripe/stripe-js';

export type CanMakePayment =
  | false
  | {
      applePay: boolean;
    };

type PaymentRequestContextValue = {
  paymentRequest: PaymentRequest | null;
  createPaymentRequest: (totalAmount: number, taxAmount: number) => void;
  updatePaymentRequest: (totalAmount: number, taxAmount: number) => void;
  canMakePayment: CanMakePayment | null;
  isApplePayEnabled: boolean;
  isExpressPayEnabled: boolean;
  fetchingCanMakePayment: boolean;
  setOnToken: (listener: (event: HandleStripeTokenEvent) => void) => void;
  setOnCancel: (listener: () => void) => void;
  paymentInterfaceOpen: boolean;
  openPaymentInterface: () => void;
  closePaymentInterface: () => void;
};

const PaymentRequestContext = createContext<PaymentRequestContextValue>({
  paymentRequest: null,
  createPaymentRequest: noop,
  updatePaymentRequest: noop,
  canMakePayment: null,
  isApplePayEnabled: false,
  isExpressPayEnabled: false,
  fetchingCanMakePayment: false,
  setOnToken: noop,
  setOnCancel: noop,
  paymentInterfaceOpen: false,
  openPaymentInterface: noop,
  closePaymentInterface: noop,
});

/**
 * Returns payment request options that require a cost amount. Used both
 * in creating a payment request and updating it.
 */
const getAmountOptions = (totalAmount: number, taxAmount: number) => ({
  displayItems: [
    {
      label: 'Trial Price',
      amount: totalAmount - taxAmount,
    },
    {
      label: 'Tax',
      amount: taxAmount,
    },
  ],
  total: {
    label: 'Today’s Total',
    amount: totalAmount,
  },
});

interface PaymentRequestContextProviderProps {
  children: ReactNode;
}

/**
 * Initializes Stripe API as well as utilities for creating a stripe payment
 * request which is needed for express payment options like Apple Pay.
 */
export function PaymentRequestContextProvider({
  children,
}: PaymentRequestContextProviderProps) {
  const { stripe } = useStripe();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );
  const [canMakePayment, setCanMakePayment] = useState<CanMakePayment | null>(
    null
  );
  const [fetchingCanMakePayment, setFetchingCanMakePayment] = useState(false);
  const [listenersAdded, setListenersAdded] = useState(false);
  const [paymentInterfaceOpen, setPaymentInterfaceOpen] = useState(false);
  const onTokenRef = useRef(noop);
  const onCancelRef = useRef(noop);
  const amountRef = useRef({ totalAmount: 0, taxAmount: 0 });

  /**
   * Create a new stripe payment request.
   */
  const createPaymentRequest = useCallback(
    (totalAmount: number, taxAmount: number) => {
      if (!stripe) {
        return;
      }

      // Amount ref to keep track of amount changes.
      amountRef.current = { totalAmount, taxAmount };

      setPaymentRequest(
        stripe.paymentRequest({
          country: 'US',
          currency: 'usd',
          requestPayerName: true,
          requestPayerEmail: true,
          requestPayerPhone: true,
          requestShipping: true,
          shippingOptions: [
            {
              label: 'FedEx 2-Day Shipping',
              amount: 0,
              // We're supposed to pass an `id` and `detail` here, but we
              // don't, and Stripe doesn't get mad, so ¯\_(ツ)_/¯ for now
            } as PaymentRequestShippingOption,
          ],
          ...getAmountOptions(totalAmount, taxAmount),
        })
      );
    },
    [stripe]
  );

  /**
   * Update payment request if either total or tax amount has changed.
   */
  const updatePaymentRequest = useCallback(
    (totalAmount: number, taxAmount: number) => {
      if (!paymentRequest) {
        return;
      }

      const { totalAmount: prevTotalAmount, taxAmount: prevTaxAmount } =
        amountRef.current;
      if (totalAmount !== prevTotalAmount || taxAmount !== prevTaxAmount) {
        amountRef.current = { totalAmount, taxAmount };
        try {
          paymentRequest.update(getAmountOptions(totalAmount, taxAmount));
        } catch (error) {
          // Errors on payment request update should only occur if a payment
          // interface is visible while trying to update.
          reporter.error(error);
        }
      }
    },
    [paymentRequest]
  );

  /**
   * Set listener for paymentRequest 'token' event.
   */
  const setOnToken = useCallback(
    (listener: (event: HandleStripeTokenEvent) => void) => {
      onTokenRef.current = listener;
    },
    []
  );

  /**
   * Set listener for paymentRequest 'cancel' event.
   */
  const setOnCancel = useCallback((listener: () => void) => {
    onCancelRef.current = listener;
  }, []);

  /**
   * Check if express checkout can be used by making call to canMakePayment.
   * If no alternative payment options are available the call will return
   * `null`. If available, either an object will be returned (e.g.
   * `{ applePay: true }` or `true`.
   * TODO: parse canMakePaymentResponse to handle the weird response from
   * stripe in one place.
   * https://app.clubhouse.io/farmersdog/story/32415/
   */
  useEffect(() => {
    if (
      !paymentRequest ||
      canMakePayment ||
      canMakePayment === false ||
      fetchingCanMakePayment
    ) {
      return;
    }
    setFetchingCanMakePayment(true);
    void (async () => {
      let canMakePaymentResponse;
      try {
        canMakePaymentResponse = await paymentRequest.canMakePayment();
      } catch (error) {
        reporter.error(error);
      }
      if (canMakePaymentResponse) {
        setCanMakePayment(canMakePaymentResponse as CanMakePayment);
      } else {
        // Explicitly set to false if can't make payment.
        setCanMakePayment(false);
      }
      setFetchingCanMakePayment(false);
    })();
  }, [paymentRequest, canMakePayment, fetchingCanMakePayment]);

  /**
   * Add event listeners to paymentRequest. Listeners are only added once since
   * they cannot be removed. Refs are used to store and update the listeners.
   */
  useEffect(() => {
    if (!paymentRequest || listenersAdded) {
      return;
    }
    setListenersAdded(true);
    paymentRequest.on('token', paymentResponse => {
      onTokenRef.current(paymentResponse);
    });
    paymentRequest.on('cancel', () => {
      setPaymentInterfaceOpen(false);
      onCancelRef.current();
    });
  }, [listenersAdded, paymentRequest]);

  const paymentRequestContextValue = useMemo(
    () => ({
      paymentRequest,
      createPaymentRequest,
      updatePaymentRequest,
      canMakePayment,
      isApplePayEnabled: !!(canMakePayment && canMakePayment.applePay),
      isExpressPayEnabled: !!(canMakePayment && !canMakePayment.applePay),
      fetchingCanMakePayment,
      setOnToken,
      setOnCancel,
      paymentInterfaceOpen,
      openPaymentInterface: () => setPaymentInterfaceOpen(true),
      closePaymentInterface: () => setPaymentInterfaceOpen(false),
    }),
    [
      paymentRequest,
      canMakePayment,
      fetchingCanMakePayment,
      createPaymentRequest,
      updatePaymentRequest,
      setOnToken,
      setOnCancel,
      paymentInterfaceOpen,
    ]
  );

  return (
    <PaymentRequestContext.Provider value={paymentRequestContextValue}>
      {children}
    </PaymentRequestContext.Provider>
  );
}

export function usePaymentRequestContext() {
  return useContext(PaymentRequestContext);
}
