import type { ApolloError } from '@apollo/client';
import type { GraphQLFormattedError } from 'graphql';

import * as errorCodes from '../errorCodes';

export function getCoreApiDataValidationError(
  error: ApolloError | undefined
): GraphQLFormattedError | undefined {
  if (!error) {
    return undefined;
  }

  try {
    return error.graphQLErrors.find(graphQlError => {
      const errorCode: unknown = graphQlError?.extensions?.code;

      return errorCode === errorCodes.CORE_API_DATA_VALIDATION_ERROR;
    });
  } catch {
    // ignore
    return undefined;
  }
}
