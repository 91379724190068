import type { ReactNode } from 'react';
import type { ModalProps } from '@farmersdog/corgi';
import { Modal } from '@farmersdog/corgi';
import { Button, Text } from '@farmersdog/corgi-x';

import ErrorMessage from './ErrorMessage';
import getDisplayTitle from './getDisplayTitle';

import styles from './ErrorModal.module.scss';
import { EmailSupportButton } from 'src/components/EmailSupportButton';
import { CHECKOUT_PRICE_NOT_MATCHING } from './errorCodes';
import { reloadLocation } from 'src/utils/dom';
import type { BackendError } from 'src/graphql/utils/getDeprecatedErrors';

export interface ErrorModalProps extends ModalProps {
  title: ReactNode;
  captureException: boolean;
  error: Error;
  code?: BackendError['code'];
}

const retryableErrorCodes = [CHECKOUT_PRICE_NOT_MATCHING];

/**
 * ErrorModal display `error` to the user. Usually you won't need to use this
 * component directly: dispatch the `ui.showError` action instead.
 */
function ErrorModal({
  error,
  code,
  captureException = true,
  title,
  ...props
}: ErrorModalProps) {
  const hasRetryableError = code && retryableErrorCodes.includes(code);

  return (
    <Modal {...props}>
      <Text {...props.labelProps} bold color="kale-3" variant="heading-40">
        {title || getDisplayTitle(error)}
      </Text>
      <ErrorMessage
        error={error}
        captureException={captureException}
        hasRetryableError={hasRetryableError}
      />
      <div className={styles.buttons}>
        <EmailSupportButton asLink subject="Support">
          <Text variant="heading-16">Contact Support</Text>
        </EmailSupportButton>
        {hasRetryableError ? (
          <Button type="button" onClick={reloadLocation}>
            Try Again
          </Button>
        ) : (
          <Button type="submit" autoFocus onClick={props.close}>
            Close
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default ErrorModal;
