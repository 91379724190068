import type { Maybe } from '../../graphql/types.core-api';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '../../graphql/types.core-api';

/**
 * Takes in an array of subscriptions and returns the one which is active.
 * If both DIY and FRESH are suspended it returns the FRESH subscription.
 * Note: This behavior deviates from Website's and APIs version of determineSubscription
 *
 * If no active or suspended subscription it returns the FRESH subscription,
 * since the customer is a manual activation.
 */
export function determineSubscription<
  T extends {
    status: SubscriptionStatus;
    type: Maybe<SubscriptionType>;
  },
>(subscriptions: T[]): T {
  const allSuspended = subscriptions.every(
    subscription => subscription?.status === SubscriptionStatus.Suspended
  );
  if (allSuspended) {
    const freshSubscription = subscriptions.find(
      subscription => subscription?.type === SubscriptionType.Fresh
    );
    if (freshSubscription) return freshSubscription;
  }

  const activeSubscription = subscriptions.find(
    subscription => subscription?.status === SubscriptionStatus.Active
  );
  if (activeSubscription) return activeSubscription;

  const suspendedSubscription = subscriptions.find(
    subscription => subscription?.status === SubscriptionStatus.Suspended
  );
  if (suspendedSubscription) return suspendedSubscription;

  const freshSubscription = subscriptions.find(
    subscription => subscription?.type === SubscriptionType.Fresh
  );
  if (freshSubscription) return freshSubscription;

  // This indicates bad data and should never happen in the customer account
  throw Error('determined subscription is undefined');
}
