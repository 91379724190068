import { useMutation } from '../../services/apollo';

import updateLead from './updateLead.graphql';

import type { UpdateLeadMutationVariables, UpdateLeadMutation } from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.MutationHookOptions<
  UpdateLeadMutation,
  UpdateLeadMutationVariables
>;

export function useUpdateLead(options?: Options) {
  return useMutation<UpdateLeadMutation, UpdateLeadMutationVariables>(
    updateLead,
    options
  );
}
