import type { Argument } from 'classnames';
import classNames from 'classnames';
import {
  LINK_PRIVACY_OPTOUT,
  LINK_PRIVACY_PREFERNCES,
} from '@farmersdog/constants/links';

import ContentPage from 'src/components/ContentPage';
import config from 'src/config';
import type { ViewportProps } from 'src/screen';
import { useViewport } from 'src/screen';
import styles from './Privacy.module.css';
import { EMAIL_PRIVACY_REQUESTS } from '@farmersdog/constants/emails';

const publicUrl = String(config('app.publicUrl'));
const FULLSTORY_PRIVACY_POLICY_URL =
  'https://www.fullstory.com/legal/privacy-policy/';
const GOOGLE_ANALYTICS_URL = 'https://Support.Google.com/Analytics';
const ALL_ABOUT_COOKIES_URL = 'https://www.AllAboutCookies.org';

const YOUR_CALIFORNIA_PRIVACY_RIGHTS = 'your-california-privacy-rights';
const TYPES_OF_PERSONAL_INFORMATION_WE_COLLECT =
  'types-of-personal-information-we-collect';
const OTHER_STATE_PRIVACY_RIGHTS = 'other-state-privacy-rights';
const COOKIES_AND_OTHER_TECHNOLOGIES = 'cookies-and-other-technologies';
const HOW_WE_USE_PERSONAL_INFORMATION = 'how-we-use-personal-information';
const HOW_WE_DISCLOSE_YOUR_PERSONAL_INFORMATION =
  'how-we-disclose-your-personal-information';
const SUBMITTING_A_PRIVACY_RIGHTS_REQUEST =
  'submitting-a-privacy-rights-request';

function Privacy() {
  return (
    <ContentPage
      title="Privacy Policy"
      className={classNames(
        styles.container,
        useViewport(styles as ViewportProps<Argument>)
      )}
    >
      <h1>The Farmer’s Dog Privacy Policy</h1>
      <p>Last Updated October 1, 2024.</p>
      <p>
        We at The Farmer’s Dog know that you care about your privacy. This
        Privacy Policy describes how we use and share the information we collect
        when you use our website{' '}
        <a href={publicUrl}>http://www.thefarmersdog.com</a> or otherwise
        interact with us.
      </p>
      <p>
        If you are a California resident, please{' '}
        <a href={`#${YOUR_CALIFORNIA_PRIVACY_RIGHTS}`}>click here</a> for more
        information about your privacy rights.
      </p>{' '}
      <p>
        {' '}
        We may update this Privacy Policy from time to time, so please revisit
        this page to stay up to date on our privacy practices.
      </p>
      <h2 id={TYPES_OF_PERSONAL_INFORMATION_WE_COLLECT}>
        Types of Personal Information We Collect
      </h2>
      <p>
        When we say “Personal Information” we mean information about you that we
        can use to identify you.
      </p>
      <p>
        The categories of Personal Information that we collect depend on your
        relationship with us, and we may disclose this Personal Information to
        our service providers and other third parties for the purposes described
        below. For example, we may collect:
      </p>
      <ul>
        <li>
          <strong>Identifiers</strong>, such as your name, email address,
          shipping address, billing address, phone number, IP Address, and User
          ID. We will also collect information about your pet, such as your
          pet’s name, medical information, and preferences. We collect this
          information to communicate with you, provide you with our products and
          services, and otherwise administer your account.
        </li>
        <li>
          <strong>Information Subject to Special Protections</strong>, such as
          the Identifiers above, and we or our service providers may collect
          your bank account number, credit card number, or debit card number. We
          use this information to provide you with our products and services,
          maintain your account, and otherwise communicate with you.
        </li>
        <li>
          <strong>
            Characteristics of Protected Classifications under California or
            Federal Law
          </strong>
          , such as race, color, ancestry, national origin, marital status,
          sexual orientation, gender identity, sex, pregnancy status,
          disability, religion, or military status. This information is
          collected through voluntary participation in surveys or other research
          activities for consumer research purposes. We use this data to better
          understand the diversity of our customer base and to ensure our
          products and services are inclusive and responsive to the needs of all
          communities.
        </li>
        <li>
          <strong>Professional or Employment Related Information</strong>,
          including details about your current or past employment, job title,
          employer, and employment history. This information may be gathered
          through surveys, questionnaires, or other interactions for consumer
          research purposes. The data helps us better understand the
          demographics and professional backgrounds of our customers, allowing
          us to improve and tailor our products and services to meet your needs.
        </li>
        <li>
          <strong>Education Information (Non-Public under FERPA)</strong>. We
          may collect non-public education information, including educational
          records, academic qualifications, and other details related to your
          academic history. This information is collected through voluntary
          participation in surveys or other research initiatives. We use this
          data for consumer research purposes to gain insights into the
          educational backgrounds of our customers and enhance our product
          offerings based on these findings.
        </li>
        <li>
          <strong>Commercial Information</strong>, such as a record of the
          products or services that you obtained or considered, or other
          information related to purchasing or consumer histories. We use this
          information to better understand your use of our products or services
          and for our internal business purposes.
        </li>
        <li>
          <strong>Audio and Electronic Information</strong>. When you call in to
          our customer service lines, we may record your call for quality
          assurances and security purposes.
        </li>
        <li>
          <strong>Internet Activity Information</strong>, such as your browsing
          history, search history, cookies, browser and device type, and
          information regarding your interactions with pages you visit on the
          website. We collect this information to understand your use of our
          website, products, and services. You can read more about our use of
          Cookies in our{' '}
          <a href={`#${COOKIES_AND_OTHER_TECHNOLOGIES}`}>
            Cookies and Other Technologies
          </a>{' '}
          section below.
        </li>
        <li>
          <strong>Geolocation Data</strong>. We collect non-specific geolocation
          data to provide you with our products and services and to better
          tailor our products and services to you depending on your geographic
          region.
        </li>
        <li>
          <strong>Profile Information</strong>, such as information about your
          preferences and characteristics. We collect this information to
          understand your preferences and tailor our products and services and
          communications to you. We do not disclose this information to third
          parties unless we are required to do so.
        </li>
      </ul>
      <p>
        Some of the information we collect may be considered{' '}
        <strong>Sensitive Personal Information</strong> under privacy laws, such
        as your financial information or user log in information. We use your
        Sensitive Personal Information only for legitimate business purposes,
        including to (i) perform services or provide goods reasonably expected
        by an average person; (ii) detect security incidents; (iii) resist
        malicious, deceptive, or illegal actions; (iv) ensure the physical
        safety of individuals; (v) for short-term, transient use; (vi) perform
        or provide internal business services; or (vii) verify or maintain the
        quality or safety of a service or device. We do not sell your Sensitive
        Personal Information.
      </p>
      <p>
        We may collect Personal Information directly from you, automatically
        through our use of cookies and other data collection technologies on our
        website, and from third parties (such as friends and family that may
        refer you). We may also permit third party advertisers to collect
        information about you when you visit our website.
      </p>
      <h2 id={HOW_WE_USE_PERSONAL_INFORMATION}>
        How We Use Personal Information
      </h2>
      <p>
        In addition to the purposes for collection described above, we also
        collect each category of Personal Information for the purpose of
        maintaining our relationship with you and performing services, which
        include:
      </p>
      <ul>
        <li>
          Opening your account, maintaining, or servicing your account,
          processing, and fulfilling your requests, and other activities that
          are part of providing our products and services to you.
        </li>
        <li>
          Communicating with you regarding our products and services and other
          products or services that may be of interest to you.
        </li>
        <li>Developing and implementing marketing and outreach plans.</li>
        <li>
          Improving the services and products we provide to you and other
          consumers, including through the use of AI and other machine learning
          technologies which may use your Personal Information.
        </li>
        <li>
          Enabling service providers and contractors to provide services or
          products on our behalf.
        </li>
        <li>
          Administering and improving our website, including to measure the
          effectiveness of the website, to customize the content and/or layout
          of our website for each individual visitor, help diagnose problems
          with our server, see where website traffic is coming from, to identify
          our website users, to track our visitors’ use of the website for
          internal market research and to notify visitors about updates to the
          website or services;{' '}
        </li>
        <li>
          Complying with our legal, regulatory and risk management obligations,
          including establishing, exercising and/or defending legal claims; and
        </li>
        <li>
          For any other business or lawful purpose to further our relationship
          with you or manage our business.
        </li>
      </ul>
      <h2 id={HOW_WE_DISCLOSE_YOUR_PERSONAL_INFORMATION}>
        How We Disclose Your Personal Information
      </h2>
      <p>
        We may disclose your Personal Information described above with service
        providers who act on our behalf, our partners, to third parties at your
        direction, or others pursuant to a legal or internal business purpose.
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may provide your Personal
          Information to our service providers that help us run and manage our
          business, such as technology service providers, analytics providers,
          customer service providers, and our credit card processors.
        </li>
        <li>
          <strong>Advertising and Analytics Partners:</strong> We may provide
          your Personal Information to our advertising and analytics partners to
          understand how you interact with our website and services. This
          information may be used, for example, to improve the functionality of
          our website and services and deliver more relevant advertisements to
          you.
        </li>
        <li>
          <strong>As Required By Law:</strong> We may disclose your Personal
          Information as required by law, such as to comply with a subpoena or
          similar legal process. Additionally, we may disclose Personal
          Information when we believe that disclosure is necessary to protect
          the safety and security of our property or the property or others. We
          may also disclose Personal Information in order to protect our rights,
          such as to investigate fraud or respond to a government request.
        </li>
        <li>
          <strong>Corporate Transaction:</strong> In the event The Farmer’s Dog
          is involved in a merger, reorganization, acquisition or sale of all or
          a portion of its assets, or other corporate transaction, we may
          transfer, assign, or disclose your Personal Information as part of
          that transaction.
        </li>
        <li>
          <strong>With your Consent:</strong> We may disclose your Personal
          Information to third parties where we have received your consent for
          doing so.
        </li>
      </ul>
      <p>
        While we have not sold Personal Information for any monetary value, our
        use of cookies and similar advertising technologies may be considered a
        “sale” under certain U.S. state consumer privacy laws. This use of
        tracking technologies may also be considered “sharing” under certain
        U.S. state consumer privacy laws. You can read more about your rights
        with respect to your Personal Information in the Your{' '}
        <a href={`#${YOUR_CALIFORNIA_PRIVACY_RIGHTS}`}>
          California Privacy Rights
        </a>{' '}
        or{' '}
        <a href={`#${OTHER_STATE_PRIVACY_RIGHTS}`}>
          Other State Privacy Rights
        </a>{' '}
        sections below.
      </p>
      <h2 id={COOKIES_AND_OTHER_TECHNOLOGIES}>
        Cookies and Other Technologies
      </h2>
      <p>
        We may use “cookies” and other technologies to help us personalize and
        enhance your experience on our website. Our cookies and other
        technologies include:
      </p>
      <ul>
        <li>
          <strong>Cookies.</strong> A cookie is a small string of text that is
          sent by our website to your browser and then stored by your browser on
          your computer’s hard drive. Our cookies allow us to personalize your
          experience on our website, make your visit more convenient, and
          understand how you and others are using our website so that we can
          improve your experience on the website. We use cookies to identify
          repeat visitors to our website and to determine the paths visitors
          take on our website. We also use cookies to determine whether you
          visited our website from a particular Internet link or advertisement.
          We commonly use conversion cookies to determine the number of times
          people who click on ads use our services or purchase products.
        </li>
        <li>
          <strong>Session Replay Technology:</strong> We use cookies that
          capture your interactions with our website and allow us to see your
          keystrokes and mouse movements when you visit our website. This
          service is provided through a third party. You can read more about
          their privacy practices at{' '}
          <a
            href={FULLSTORY_PRIVACY_POLICY_URL}
            target="_blank"
            rel="noreferrer"
          >
            {FULLSTORY_PRIVACY_POLICY_URL}
          </a>
          .
        </li>
        <li>
          <strong>Google Analytics.</strong> We use Google Analytics to help us
          understand how users engage with our services. Google Analytics uses
          cookies to track your interactions with our services, then collects
          that information and reports it to us, without identifying individual
          users. This information helps us improve our Services so that we can
          better serve users like you. For more information on Google Analytics,
          visit{' '}
          <a href={GOOGLE_ANALYTICS_URL} target="_blank" rel="noreferrer">
            {GOOGLE_ANALYTICS_URL}
          </a>
          .
        </li>
      </ul>
      <p>
        Most web browsers automatically accept cookies, but it is possible to
        change your browser setup so that it does not accept cookies. In order
        to create a profile on our website you must have your cookies on. To
        find out more information about cookies, visit{' '}
        <a href={ALL_ABOUT_COOKIES_URL} target="_blank" rel="noreferrer">
          {ALL_ABOUT_COOKIES_URL}
        </a>
        .
      </p>
      <h2>Browser Signals</h2>
      <h3 className={styles.doNotTrack}>Do Not Track</h3>
      <p>
        Some web browsers have “Do Not Track” or similar features that
        communicate to each website you visit that you do not want your
        activities on that website tracked. At present, our website does not
        respond to “Do Not Track” signals and so our website will continue to
        collect information about you even if your browser’s “Do Not Track”
        feature is activated.
      </p>
      <h2>Data Security & Retention</h2>
      <p>
        We are committed to implementing safeguards designed to maintain the
        privacy and integrity of your Personal Information. We also rely on you
        for the safety and security of your information. Where we have given you
        (or where you have chosen) a password for access to certain parts of the
        website, you are responsible for keeping this password confidential. We
        ask you not to share your password with anyone.
      </p>
      <p>
        Unfortunately, the transmission of information via digital means is not
        completely secure. Although we implement and maintain reasonable
        measures to protect your information, we cannot guarantee the security
        of the information transmitted to us; therefore, we do not assume any
        responsibility for any transmission of your information which you do at
        your own risk. We retain your Personal Information for as long as needed
        to fulfill the business purpose(s) for which we collected it. We also
        take into consideration the sensitivity of the data when determining its
        retention period.
      </p>
      <h2>Accessing and Modifying Your Personal Information</h2>
      <p>
        If you have registered for our services, you may be able to access,
        review, and make changes to information associated with your account by
        logging into your account and taking the desired actions. We may not be
        able to accommodate a request to change information if we believe the
        change would violate any law or legal requirement or cause the
        information to be incorrect.
      </p>
      <h2 id={YOUR_CALIFORNIA_PRIVACY_RIGHTS}>
        Your California Privacy Rights
      </h2>
      <p>
        The California Consumer Privacy Act (“CCPA”) requires us to provide
        specific disclosures about the Personal Information we collected in the
        preceding 12 months. This Privacy Policy describes the{' '}
        <a href={`#${TYPES_OF_PERSONAL_INFORMATION_WE_COLLECT}`}>
          categories of Personal Information we collect
        </a>{' '}
        and{' '}
        <a href={`#${TYPES_OF_PERSONAL_INFORMATION_WE_COLLECT}`}>
          sources of information
        </a>
        ,{' '}
        <a href={`#${HOW_WE_DISCLOSE_YOUR_PERSONAL_INFORMATION}`}>
          to whom we disclose that information
        </a>
        , and the{' '}
        <a href={`#${HOW_WE_USE_PERSONAL_INFORMATION}`}>
          business and commercial purposes for collecting Personal Information
        </a>
        .
      </p>
      <p>
        In the preceding 12 months, we have disclosed your Personal Information
        to each of the categories of third parties{' '}
        <a href={`#${HOW_WE_DISCLOSE_YOUR_PERSONAL_INFORMATION}`}>
          listed above
        </a>{' '}
        for business and commercial purposes. In addition:{' '}
      </p>
      <ul>
        <li>
          We may have sold or shared the following categories of your Personal
          Information with our advertising and analytics partners: Identifiers,
          Internet Activity Information, and Profile Information.
        </li>
        <li>
          We do not knowingly sell the Personal Information of users under 16
          years of age.
        </li>
      </ul>
      <p>
        We provide California residents the privacy rights required under
        applicable law, including the CCPA and Shine the Light law, as further
        described below.
      </p>
      <h3 className={styles.californiaIndividualPrivacyRights}>
        California Individual Privacy Rights:
      </h3>
      <ul>
        <li>
          <strong>The right to know.</strong> You have the right to request to
          know what Personal Information we have collected about you, including
          the categories of Personal Information we have collected, the
          categories of sources from which we collected it, the business or
          commercial purpose for collecting, selling, or sharing Personal
          Information, the categories of third parties to whom we disclosed
          Personal Information, and the specific pieces of Personal Information
          we collected.
        </li>
        <li>
          <strong>The right to correct.</strong> You have the right to request
          that we correct inaccurate Personal Information that we maintain about
          you.
        </li>
        <li>
          <strong>The right to deletion.</strong> You have the right to request
          that we delete the Personal Information that we have collected or
          maintain about you.
        </li>
        <li>
          <strong>
            The right to opt out of the sale or sharing of your Personal
            Information.
          </strong>{' '}
          You have the right to opt out of the sale or sharing of your Personal
          Information. You can opt out from the collection and sharing of
          certain cookies as described in{' '}
          <a href={`#${COOKIES_AND_OTHER_TECHNOLOGIES}`}>
            Cookies and Other Technologies
          </a>{' '}
          above or through the{' '}
          <a href={LINK_PRIVACY_OPTOUT} target="_blank" rel="noreferrer">
            Do Not Sell or Share My Personal Information
          </a>{' '}
          link.
        </li>
        <li>
          <strong>The right to equal service.</strong> If you choose to exercise
          any of these rights, we will not discriminate against you in any way.
          If you exercise certain rights, understand that you may be unable to
          use or access certain features of the Website or our Services.
        </li>
      </ul>
      <p>
        If you are a California resident, you can exercise your privacy rights
        as described in{' '}
        <a href={`#${SUBMITTING_A_PRIVACY_RIGHTS_REQUEST}`}>
          Submitting a Privacy Rights Request
        </a>{' '}
        below.
      </p>
      <h3 className={styles.shineTheLight}>Shine the Light:</h3>
      <p>
        California Civil Code Section 1798.83 gives California residents the
        right to request from us once per calendar year certain information
        regarding our disclosure of their Personal Information to third parties
        for those third parties’ direct marketing purposes. You may request
        information regarding the disclosure of your Personal Information to
        third parties for those third parties’ direct marketing purposes by
        contacting us at{' '}
        <a href={`mailto:${EMAIL_PRIVACY_REQUESTS}`}>
          {EMAIL_PRIVACY_REQUESTS}
        </a>
        .
      </p>
      <h2 id={OTHER_STATE_PRIVACY_RIGHTS}>Other State Privacy Rights</h2>
      <p>
        We provide residents of certain states with the privacy rights required
        under applicable law as further described below.
      </p>
      <ul>
        <li>
          <strong>The right to access.</strong> You have the right to confirm
          whether we are processing your Personal Information and to access such
          Personal Information.
        </li>
        <li>
          <strong>The right to correct.</strong> You have the right to request
          that we correct inaccurate Personal Information that we maintain about
          you. Upon receipt of a verifiable request from you, we will use
          commercially reasonable efforts to correct the inaccurate Personal
          Information.
        </li>
        <li>
          <strong>The right to delete.</strong> You have the right to request
          that we delete your Personal Information under specific circumstances.
        </li>
        <li>
          <strong>The right to opt-out.</strong> You have the right to object or
          opt out of certain types of processing, including: (1) processing for
          the purpose of targeted advertising, (2) processing for the purpose of
          the sale of Personal Information, and (3) processing for the purpose
          of certain types of profiling and automated decision-making.
        </li>
        <li>
          <strong>The right to data portability.</strong> You have the right to
          request a copy of your Personal Information in an accessible format.
        </li>
        <li>
          <strong>The right to equal service.</strong> If you choose to exercise
          any of these rights, we will not discriminate against you in any way.
          If you exercise certain rights, understand that you may be unable to
          use or access certain features of the website or our Services.
        </li>
        <li>
          <strong>The right to appeal.</strong> If we do not grant your consumer
          request, you may have the right to appeal that denial.
        </li>
      </ul>
      <p>
        You can exercise your privacy rights as described in{' '}
        <a href={`#${SUBMITTING_A_PRIVACY_RIGHTS_REQUEST}`}>
          Submitting a Privacy Rights Request
        </a>{' '}
        below.
      </p>
      <h2 id={SUBMITTING_A_PRIVACY_RIGHTS_REQUEST}>
        Submitting a Privacy Rights Request
      </h2>
      <p>
        Depending on where you reside, you may exercise your rights free of
        charge (subject to limited exceptions). To exercise your rights, contact
        us at <a href="tel:+18888144162">(888) 814-4162</a> or use our online
        form{' '}
        <a href={LINK_PRIVACY_PREFERNCES} target="_blank" rel="noreferrer">
          here
        </a>
        .
      </p>
      <p>
        Where required by applicable law, we will take steps to verify your
        identity before processing your request. We will not fulfill your
        request unless you have provided sufficient information for us to
        reasonably verify you are the individual about whom we collected
        Personal Information. We may request additional information about you so
        that we can match the information you provide to information we already
        maintain about you. We will only use the Personal Information provided
        in the verification process to verify your identity or authority to make
        a request and to track and document request responses unless you
        initially provided the information for another purpose.
      </p>
      <p>
        You may use an authorized agent to submit a request to know or a request
        to delete. When we verify your agent’s request, we may verify both your
        and your agent’s identity and request a signed document from you that
        authorizes your agent to make the request on your behalf. To protect
        your Personal Information, we reserve the right to deny a request from
        an agent that does not submit proof that they have been authorized by
        you to act on their behalf.
      </p>
      <h2>Voice Calls, Texts, and SMS messages</h2>
      <p>
        You may have the opportunity to receive occasional voice, text, and SMS
        messages from us. These are typically updates on our products and
        services or about products and services that we think may be of interest
        to you. Frequency will vary. By providing your phone number and opting
        in to receive text messages, you expressly consent to receive these
        communications, including any automated text messages to the phone
        number that you provide. Consent to receiving text messages is not
        required to receive products or services from us. Message and data rates
        may apply, and your carrier&apos;s rates will apply. You can opt out of
        receiving future text messages or receive help by following the
        directions in the text message you receive or accessing your customer
        account at our website. As we are in the United States, international
        rates may apply depending on your location. We may disclose your mobile
        phone number to service providers with whom we contract to send you
        automated text messages. However, we will not disclose your mobile phone
        number to third parties for their own marketing purposes without your
        express consent. We may still contact you about your account even if you
        opt out of calls, texts, or SMS.
      </p>
      <h2>Email Consent and Opt-Out</h2>
      <p>
        If you do not wish to have your email address used by us to promote our
        products or services, you can opt-out by managing your communication
        preferences in your customer account at our website or by clicking the
        unsubscribe link contained within any promotional email you received.
        This opt-out does not apply to transactional or relationship email
        messages we may send.{' '}
      </p>
      <h2>Persons Under 16 Years of Age</h2>
      <p>
        Our website is not intended for or directed to persons under 16 years of
        age. We do not knowingly collect or maintain information on our website
        from those we know are under 16, and we do not permit persons under 16
        years of age to become registered users of our website. If we become
        aware that we have collected information from an individual under 16
        years of age, we will immediately delete such information.
      </p>
      <h2>Links To Third-Party Web Sites</h2>
      <p>
        Our website may offer links to other websites which may have information
        policies and practices different from ours. We do not control and are
        not responsible for the privacy policies or practices or the content of
        any third party websites. We encourage you to review the privacy
        policies of any third party website prior to providing them with your
        Personal Information.
      </p>
      <h2>Changes to the Privacy Policy</h2>
      <p>
        We may occasionally update this Privacy Policy to reflect changes in our
        practices. When we post modifications to this Privacy Policy, we will
        revise the “Last Updated” date at the top of this web page. If the
        changes are material, we will endeavor to notify you in advance of such
        changes taking place. We encourage you to periodically review this page
        for the latest information on our privacy practices.
      </p>
      <h2>Non-U.S. Residents</h2>
      <p>
        The website is intended for use in the United States only. If you visit
        our website or contact us from outside of the United States, please be
        advised that (1) any information you provide to us or that we
        automatically collect will be transferred to the United States; and (2)
        that by using our website or submitting information, you explicitly
        authorize its transfer to and subsequent processing in the United States
        in accordance with this Privacy Policy. Do not provide your Personal
        Information to us if you do not want this information to be transferred
        outside of your country, or if the laws in your country restrict these
        types of transfers. We will treat your Personal Information in
        accordance with this Privacy Policy.
      </p>
      <h2>How To Contact Us</h2>
      <p>
        If you have questions or comments about this Privacy Policy or our
        privacy practices, please contact us at{' '}
        <a href={`mailto:${EMAIL_PRIVACY_REQUESTS}`}>
          {EMAIL_PRIVACY_REQUESTS}
        </a>
        .
      </p>
    </ContentPage>
  );
}

export default Privacy;
