import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { anonymousId } from '@farmersdog/lead-browser-storage';

import { useAttributes } from 'src/abTesting/useAttributes';

import { userFeatures } from './features';

import { useSplitClient } from './useSplitClient';
import { getTreatmentWithConfig } from './utils';
import type { ToggleTreatment } from './types';
import { selectTokenId } from 'src/reducers/auth';

export interface UseFeatureOptions<AttributesType extends SplitIO.Attributes> {
  /** Optional feature attributes to pass to split */
  attributes?: AttributesType;
  /** When true useFeature will not request treatment data from split until
   * getFeatureData is called. */
  lazy?: boolean;
}

export interface UseFeatureReturn<TreatmentType, ConfigType> {
  /** While false the split client is communicating with split. Split.IO
   * suggests that you render nothing while it is loading but the website will
   * instead return the control allowing the website to always be able to render
   * something. For details around isReady read Split's docs
   * [here](https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#basic-use)
   */
  isReady: boolean;
  /** The treatment returned from split or `control` if not ready. */
  treatment: TreatmentType;
  /** The config object from split or `{}` if not ready. */
  config: ConfigType;
  /** On demand call to split to get the treatment and config. */
  getFeatureData: () => {
    treatment: TreatmentType | 'control';
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    config: ConfigType | {};
  };
  /** Cached record of split clients - NOTE: This is returned temporarily for observability */
  cachedSplitClients?: Record<string, boolean>;
}

/**
 * A hook to wrap the Split.IO
 * [useClient](https://help.split.io/hc/en-us/articles/360038825091-React-SDK#advanced-instantiate-multiple-sdk-clients)
 * hook. The purpose is to extend useClient to support website patterns.
 *
 * - Connect the feature name with the correct segment id
 * - Replace Split's callback/promise implementation with react state values
 * - Provided support for a lazy treatment request
 * - Added query string debug support
 *
 * @param name - The name of the feature to request a treatment for
 * @param options - Options to modify the behavior
 */
export const useFeature = <
  TreatmentType = ToggleTreatment,
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  ConfigType extends {} = {},
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  AttributesType extends SplitIO.Attributes = {},
>(
  name: string,
  options: UseFeatureOptions<AttributesType> = {},
  splitKeyOverride?: string
): UseFeatureReturn<TreatmentType, ConfigType> => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userId = useSelector(selectTokenId);
  const tfdAnonymousId = anonymousId.get();

  const { attributes, attributesReady } = useAttributes<AttributesType>(
    options.attributes
  );
  const isUserFeature = Boolean(userFeatures[name]);
  const splitKey =
    splitKeyOverride ?? (isUserFeature ? String(userId) : tfdAnonymousId);
  const {
    isReady: splitIsReady,
    splitClient,
    cachedSplitClients,
  } = useSplitClient(splitKey);
  const [isReady, setIsReady] = useState<boolean>(splitIsReady);
  const initialState = options.lazy
    ? undefined
    : getTreatmentWithConfig(
        name,
        isReady,
        splitClient,
        attributes,
        attributesReady
      );

  const [treatment, setTreatment] = useState<TreatmentType>(
    (initialState?.treatment ?? 'control') as TreatmentType
  );
  const [config, setConfig] = useState<ConfigType>(
    (initialState?.config ?? {}) as ConfigType
  );

  const getFeatureData = useCallback(() => {
    const featureData = getTreatmentWithConfig<TreatmentType, ConfigType>(
      name,
      splitIsReady,
      splitClient,
      attributes,
      attributesReady
    );

    setTreatment(featureData.treatment as TreatmentType);
    setConfig((featureData.config ?? {}) as ConfigType);
    setIsReady(splitIsReady);
    return featureData;
  }, [attributes, attributesReady, splitIsReady, name, splitClient]);

  useEffect(() => {
    if (!options.lazy) {
      getFeatureData();
    }
  }, [getFeatureData, options.lazy]);

  return useMemo(
    () => ({
      isReady,
      treatment,
      config,
      getFeatureData,
      cachedSplitClients,
    }),
    [isReady, treatment, config, getFeatureData, cachedSplitClients]
  );
};
