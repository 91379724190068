import { useLocation } from 'react-router-dom';

import {
  PATH_REVIEWS,
  PATH_ABOUT_US,
  PATH_FAQ,
  PATH_LOGIN,
  PATH_WHY_FRESH,
  PATH_DIY,
} from '@farmersdog/constants/paths';

import config from 'src/config';

import { DIY_FAQ } from 'src/pages/DIY/components/FAQ/constants/ids';

import * as whyFreshData from 'src/pages/WhyFresh/data';
import type { AppNavItem } from '@farmersdog/corgi-x';

import styles from '../Navigation.module.scss';
import classNames from 'classnames';

interface useNavigationItemsProps {
  recommendVetPortal: boolean;
  vetOptimizationLinks?: {
    showPillOnly: boolean;
    showPillAndCard: boolean;
  };
}

function useNavigationItems({
  recommendVetPortal,
  vetOptimizationLinks = {
    showPillOnly: false,
    showPillAndCard: false,
  },
}: useNavigationItemsProps) {
  const location = useLocation();

  const leftItems = [
    {
      label: 'Reviews',
      to: PATH_REVIEWS,
    },
    {
      label: 'About Us',
      to: PATH_ABOUT_US,
    },
    {
      label: whyFreshData.pageTitle,
      to: PATH_WHY_FRESH,
    },
    {
      label: 'FAQ',
      to: location.pathname === PATH_DIY ? `#${DIY_FAQ}` : PATH_FAQ,
      // overrides react-router-dom NavLink default active class to consider complete match of path + hash as active
      isActive: () =>
        location.pathname === PATH_DIY && location.hash === `#${DIY_FAQ}`,
    },
  ];

  const getVetPortalLink = () => {
    if (
      vetOptimizationLinks.showPillAndCard ||
      vetOptimizationLinks.showPillOnly
    ) {
      return {
        label: 'For Vet Professionals',
        href: config('app.vetPortalUrl') as string,
        type: 'anchor',
        target: '_blank',
        rel: 'noopener',
        variant: 'special-kale-experimental',
        menuPosition: 'last',
        className: classNames(styles.vetLinkPill, {
          [styles.hideOnMobile]: vetOptimizationLinks.showPillAndCard,
        }),
      } satisfies AppNavItem;
    }

    return {
      label: 'For Vet Professionals',
      href: config('app.vetPortalUrl') as string,
      type: 'anchor',
      target: '_blank',
      rel: 'noopener',
    } satisfies AppNavItem;
  };

  const vetPortalLink = getVetPortalLink();

  return {
    leftItems:
      recommendVetPortal && vetPortalLink
        ? [...leftItems, vetPortalLink]
        : leftItems,
    rightItems: [
      {
        label: 'Log In',
        to: PATH_LOGIN,
        alwaysVisible: true,
      },
    ],
  };
}

export default useNavigationItems;
