import type { NodeName } from '@farmersdog/constants';

import type { Position } from '../types';

export function getNodeNameAndPosition(nodeName: string) {
  const [name, position] = nodeName.split('-');
  return {
    name: name as NodeName,
    position: position as Position,
  };
}
