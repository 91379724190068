import type { Context } from 'react';
import { useContext } from 'react';
import type { SplitContextValue } from '../context/SplitContext';
import { SplitContext } from '../context/SplitContext';

export function useSplitContext<T extends object, U extends object>() {
  const context = useContext(SplitContext as Context<SplitContextValue<T, U>>);

  if (!context) {
    throw Error('Cannot use split context without provider');
  }

  return context;
}
