import { ExperimentPickyTreatment } from '../../../../HomepagePersonalization/ExperimentPicky';
import type {
  SlotEligibility,
  SlotEligibilityProps,
} from '../../../../../../slots/types';
import type { ExperimentAttributes } from '../../../../../../app/lib/Split/features/OnbHomepagePersonalizationPicky';
import { reporter } from '../../../../../../errors/services';
import { useFetchHomepageData } from '../../../../../network';
import { usePickyEaterSplitTreatment } from './usePickyEaterSplitTreatment';

export function usePickyEaterNudgeEligibility(
  props: SlotEligibilityProps
): SlotEligibility {
  const shouldBlockSplit = props?.constraints.blockSplitEligibility || false;
  const { data, loading, error } = useFetchHomepageData({
    onError: (err: unknown) =>
      reporter.warn('Error retrieving ExperimentPicky data', {
        sourceError: err,
      }),
  });

  const attributes: ExperimentAttributes = {
    hasOverweightPet:
      data?.customer.userCharacteristics.hasOverweightPet ?? false,
    hasPickyEater: data?.customer.userCharacteristics.hasPickyEater ?? false,
    regularOrdersDeliveredCount:
      data?.customer.freshSubscription.regularOrdersDeliveredCount ?? 0,
  };

  const featureTreatment = usePickyEaterSplitTreatment({
    activatedAt: data?.customer.activatedAt ?? null,
    attributes,
    shouldBlockSplit,
  });

  const isReady = !loading && !error && !!data;

  if (!isReady) {
    return {
      isReady: false,
      isCandidate: false,
    };
  }

  const isCandidate = [
    // Variants: 1, 2
    [
      ExperimentPickyTreatment.PickyNudge,
      ExperimentPickyTreatment.PickyNudgeWithProgression,
    ].some(pickyTreatment => pickyTreatment === featureTreatment),
    // Box 4 is not delivered
    attributes.regularOrdersDeliveredCount < 3,
  ].every(Boolean);

  return { isCandidate, isReady };
}
