import styles from './BranchNode.module.css';
import { Node } from './Node';

import type { BranchNode as BranchNodeType } from '../../blueprint/types';
import type { TOSAComponentInput } from '../../types';
import type { SyntheticEvent } from 'react';

export function BranchNode(props: TOSAComponentInput<BranchNodeType>) {
  const { node, onComplete, formMethods } = props;
  const { isSubmitting } = formMethods.formState;

  const handleComplete = async (e: SyntheticEvent) => {
    if (onComplete) {
      await onComplete(e);
    }
  };

  const hasRoute = node.route;

  if (!hasRoute) {
    return (
      <>
        {node.children.map(childNode => (
          <Node
            {...props}
            node={childNode}
            onComplete={handleComplete}
            key={childNode.__self__}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <fieldset
        className={styles.fieldset}
        // @ts-expect-error this is actually valid
        disabled={isSubmitting ? 'disabled' : ''}
      >
        {node.children.map(childNode => (
          <Node
            {...props}
            node={childNode}
            onComplete={handleComplete}
            key={childNode.__self__}
          />
        ))}
      </fieldset>
    </>
  );
}
