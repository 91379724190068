import type { ReactNode, FC, Context } from 'react';
import { useEffect, useState, createContext, useCallback } from 'react';

import type { ScreenState } from './Screen';
import Screen from './Screen';
import type { Config, OrientationName, ViewportName } from 'src/screen/types';

export interface ScreenProviderProps {
  /* Platform type for guessing initial screen context */
  platform?: 'mobile' | 'tablet' | 'desktop';
  children: ReactNode;
}

export type ScreenContext = Context<ScreenState>;
export type ScreenProvider = FC<ScreenProviderProps>;

interface CreateScreenContextReturn {
  ScreenContext: ScreenContext;
  ScreenProvider: ScreenProvider;
}

const createScreenContext = ({
  defaultViewports,
  ...props
}: Config): CreateScreenContextReturn => {
  const screen = new Screen(props);
  const ScreenContext = createContext<ScreenState>(screen.getScreen());

  const ScreenProvider: FC<ScreenProviderProps> = ({
    platform = 'mobile',
    children,
  }) => {
    const [screenState, setScreenState] = useState<ScreenState>(
      screen.getScreen(defaultViewports[platform])
    );

    const handleOrientationChange = useCallback(
      (orientationQueries: Map<OrientationName, MediaQueryList>) => {
        const orientations: OrientationName[] = [];

        orientationQueries.forEach((mediaQuery, name) => {
          if (mediaQuery.matches) {
            orientations.unshift(name);
          }
        });

        setScreenState(prevScreenState => ({
          ...prevScreenState,
          orientation: orientations[0],
        }));
      },
      []
    );

    const handleViewportChange = useCallback(
      (viewportQueries: Map<ViewportName, MediaQueryList>) => {
        const matchedViewports: ViewportName[] = [];

        viewportQueries.forEach((mediaQuery, name) => {
          if (mediaQuery.matches) {
            matchedViewports.unshift(name);
          }
        });

        setScreenState(prevScreenState => ({
          ...prevScreenState,
          matchedViewports,
          matchedViewport: matchedViewports[0],
        }));
      },
      []
    );

    useEffect(() => {
      const unsubscribe = screen.subscribeViewportChange(handleViewportChange);
      return unsubscribe;
    }, [handleViewportChange]);

    useEffect(() => {
      const unsubscribe = screen.subscribeOrientationChange(
        handleOrientationChange
      );
      return unsubscribe;
    }, [handleOrientationChange]);

    return (
      <ScreenContext.Provider value={screenState}>
        {children}
      </ScreenContext.Provider>
    );
  };

  return { ScreenContext, ScreenProvider };
};

export default createScreenContext;
