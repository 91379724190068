import cloneDeep from 'lodash/cloneDeep';

import { blueprintTraverseApply } from '../../blueprint/utils';

import type {
  Blueprint,
  CompiledBlueprint,
  Fragment,
  Node,
} from '../../blueprint/types';
import type { GetComponent } from '../../schema/componentMap';

export interface CompileBlueprintComponentMapArgs<
  B extends Blueprint | CompiledBlueprint,
> {
  blueprint: B;
  getComponent: GetComponent;
}

interface AddComponentToNodeArgs {
  node: Node | Fragment;
  getComponent: GetComponent;
}

function addComponentToNode({ node, getComponent }: AddComponentToNodeArgs) {
  const componentName = node.component;

  if (typeof componentName !== 'string') {
    return;
  }

  // @ts-expect-error we can fix this when this all gets moved to the client https://app.shortcut.com/farmersdog/story/80277/map-client-concerns-onto-blueprint-by-node-name
  node.component = getComponent(componentName);
}

export function compileBlueprintComponentMap<
  B extends Blueprint | CompiledBlueprint,
>({ blueprint, getComponent }: CompileBlueprintComponentMapArgs<B>): B {
  const clonedBlueprint = cloneDeep(blueprint);
  blueprintTraverseApply(clonedBlueprint.root, node =>
    addComponentToNode({ node, getComponent })
  );

  clonedBlueprint.fragments.forEach((fragment, index) => {
    blueprintTraverseApply(fragment, node =>
      addComponentToNode({ node, getComponent })
    );

    clonedBlueprint.fragments[index] = fragment;
  });

  return clonedBlueprint;
}
