import { Text } from '@farmersdog/corgi-x';
import { formatDecimalToFraction } from '../../../utils/formatting/formatDecimalToFraction';
import { pluralizeString } from '../../../utils/formatting/pluralizeString';
import type { PetOrderDetails } from './ReactivationSuccess';

export function PetSummary({ pet }: { pet: PetOrderDetails }) {
  const numberOfPacks = pluralizeString(pet.totalPacks, 'pack', 'packs', {
    prependQuantity: true,
  });
  const portion = `${formatDecimalToFraction(pet.portion)} ${pluralizeString(
    pet.portion,
    'pack',
    'packs'
  )}`;
  return (
    <Text as="p" variant="heading-16" color="charcoal-3" vSpacing="xs">
      {pet.name}: {numberOfPacks}; feed {portion} ({pet.dailyCalories} calories)
      a day
    </Text>
  );
}
