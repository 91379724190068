import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { ExperimentPlanProgressionCriteriaQuery } from './graphql/ExperimentPlanProgressionCriteriaQuery.cgs.generated-types';
import ExperimentPlanProgressionCriteria from './graphql/ExperimentPlanProgressionCriteriaQuery.cgs.graphql';

export function useExperimentPlanProgressionDataQuery(): {
  loading: boolean;
  error?: ApolloError;
  data?: ExperimentPlanProgressionCriteriaQuery;
} {
  return useQuery<ExperimentPlanProgressionCriteriaQuery>(
    ExperimentPlanProgressionCriteria,
    {
      context: { endpoint: 'cgs' },
    }
  );
}
