import { useRef, useState, useEffect } from 'react';
import { ContentLoader } from '@farmersdog/corgi-x';
import { reporter } from '../errors/services';
import type { RecipeDetailsModalProps } from '../recipe-details-modal';
import { RecipeDetailsModal } from '../recipe-details-modal';
import { AddDog, PetCard, SummaryTitle } from './features';
import { useFetchMyDogsData } from './network';
import { useLocation } from 'react-router-dom';
import { PET_NAME_PARAM } from '../pet-fresh-food/features/Pet/Pet';
import { PendingPetsCard } from './features/PendingPetsCard';
import { isWithinInterval, parseISO, subHours } from 'date-fns';

export type HandleOpenRecipeDetailsModalProps = Pick<
  RecipeDetailsModalProps,
  'petName' | 'productName'
>;

//todo: Move 24hr logic to backend. https://app.shortcut.com/farmersdog/story/128591/add-24-hour-logic-to-pendingpet-resolver
export function isWithinLast24Hours(timestamp: string) {
  const now = new Date();
  const targetDate = parseISO(timestamp);

  return isWithinInterval(targetDate, { start: subHours(now, 24), end: now });
}

export function MyDogs() {
  const request = useFetchMyDogsData();

  const [modalState, setModalState] = useState<
    { isOpen: boolean } & HandleOpenRecipeDetailsModalProps
  >({
    isOpen: false,
    productName: '',
  });

  const params = new URLSearchParams(useLocation().search);
  const petNameParam: string | null = params.get(PET_NAME_PARAM);

  const LOADING_HEIGHT = 120;
  const APP_PAGE_MARGIN_TOP = 24;

  const sectionRef = useRef<HTMLDivElement>(null);
  const activePetCardRef = useRef<HTMLDivElement>(null);

  const headerOffsetTop = sectionRef.current
    ? sectionRef.current.offsetTop - APP_PAGE_MARGIN_TOP
    : 0;

  function handleOpenRecipeDetailsModal(
    petAndRecipe: HandleOpenRecipeDetailsModalProps
  ) {
    setModalState({ ...petAndRecipe, isOpen: true });
  }

  function handleCloseRecipeDetailsModal() {
    setModalState({ ...modalState, isOpen: false });
  }

  function scrollToActivePetCard() {
    setTimeout(() => {
      if (activePetCardRef.current) {
        activePetCardRef.current.scrollIntoView({
          block: 'start',
        });
      }
    }, 400);
  }

  useEffect(() => {
    if (request.loading || !activePetCardRef.current || !petNameParam) {
      return;
    }

    scrollToActivePetCard();
  }, [request.loading, activePetCardRef, petNameParam]);

  if (request.error) {
    reporter.error(new Error('Error loading MyDogs'), {
      sourceError: request.error,
    });
    return null;
  }

  const showPendingPetsCard =
    request.data?.customer.pendingPet &&
    isWithinLast24Hours(request.data.customer.pendingPet.createdAt);

  return (
    <section ref={sectionRef}>
      <ContentLoader loading={request.loading} height={50}>
        <SummaryTitle
          petNames={request.data?.customer.pets.map(pet => pet.name) || []}
        />
      </ContentLoader>
      <ContentLoader loading={request.loading} height={LOADING_HEIGHT}>
        {request.data?.customer.pets.map(pet => {
          const isActivePetCard =
            petNameParam === pet.name ? activePetCardRef : null;
          return (
            <PetCard
              key={pet.id}
              name={pet.name}
              bodyCondition={pet.bodyCondition}
              breedsForDisplay={pet.breedsForDisplay}
              ageInWeeks={pet.ageInWeeks}
              weightInPounds={pet.weightInPounds}
              plan={pet.plan}
              recipes={pet.foodRecipes}
              activityLevel={pet.activityLevel}
              handleOpenRecipeDetailsModal={handleOpenRecipeDetailsModal}
              activePetCardRef={isActivePetCard}
              scrollMarginTop={headerOffsetTop}
            />
          );
        })}
      </ContentLoader>
      <ContentLoader loading={request.loading} height={50}>
        {showPendingPetsCard && <PendingPetsCard />}
      </ContentLoader>
      <AddDog />
      {/* We want the modal to gracefully close but not submit a false recipe id request on initial render */}
      {modalState.productName !== '' && (
        <RecipeDetailsModal
          isOpen={modalState.isOpen}
          productName={modalState.productName}
          petName={modalState.petName}
          onClose={handleCloseRecipeDetailsModal}
        />
      )}
    </section>
  );
}
