import type { ApolloError } from '@apollo/client';
import { useToast } from '@farmersdog/corgi';
import isNil from 'lodash/isNil';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import type { TrackChangeOrderSizeSuccessProps } from '../analytics/trackChangeOrderSizeSuccess';
import { getToastMessage } from '../utils';
import type { JourneyStatus } from './../../graphql/types.cgs';
import { useChangeFreshOrderSize } from './changeFreshOrderSize';

export interface UseChangeFrequencyMutationProps {
  currentFrequency?: number;
  currentNextDate?: string | null;
  journeyStatus?: JourneyStatus;
  onCompleted: (
    suggestedFrequencies?: TrackChangeOrderSizeSuccessProps
  ) => void;
  onError: (error: ApolloError) => void;
}

export function useChangeFrequencyUpdate({
  currentFrequency: originalCurrentFrequency,
  currentNextDate,
  journeyStatus,
  onCompleted,
  onError,
}: UseChangeFrequencyMutationProps) {
  const dispatchToast = useToast();

  const [selectedFrequency, setSelectedFrequency] = useState(
    originalCurrentFrequency
  );
  const [currentFrequency, setCurrentFrequency] = useState(
    originalCurrentFrequency
  );

  const [originalNextDate, setNextDate] = useState(currentNextDate);

  useEffect(() => {
    setSelectedFrequency(currentFrequency);
  }, [currentFrequency]);

  useEffect(() => {
    setCurrentFrequency(originalCurrentFrequency);
  }, [originalCurrentFrequency]);

  useEffect(() => {
    setNextDate(currentNextDate);
  }, [currentNextDate, originalNextDate]);

  const { onSubmit, loading: isSubmitting } = useChangeFreshOrderSize({
    onCompleted: response => {
      const toastMessage = getToastMessage({
        response,
        originalNextDate,
        journeyStatus,
      });
      dispatchToast({
        variant: 'positive',
        children: toastMessage,
      });
      onCompleted();
    },
    onError: error => {
      setCurrentFrequency(originalCurrentFrequency);
      onError(error);
    },
  });

  const handleFrequencyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFrequency(Number(e.target.value));
  };

  const handleCancel = () => {
    setSelectedFrequency(currentFrequency);
  };

  const handleSubmit = () => {
    setCurrentFrequency(selectedFrequency);

    if (!isNil(selectedFrequency)) {
      onSubmit({
        input: { orderSize: selectedFrequency },
      });
    }
  };

  const canSubmit = currentFrequency !== selectedFrequency && !isSubmitting;

  return {
    selectedFrequency,
    canSubmit,
    isSubmitting,
    handleFrequencyChange,
    handleSubmit,
    handleCancel,
  };
}
