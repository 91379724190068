import { assign, createMachine } from 'xstate';
import type { SuggestOrderSizeUpEventMachineEvent } from './SuggestOrderSizeUpEvent';
import { SuggestOrderSizeUpEvent } from './SuggestOrderSizeUpEvent';
import { SuggestOrderSizeUpState } from './SuggestOrderSizeUpState';

export type SuggestOrderSizeUpMachineContext = {
  daysOfFood?: number;
  earliestDesiredArrivalDate?: string;
  latestDesiredArrivalDate?: string;
};

/**
 * Wrapper to create xState machine. Sets initial context.
 *
 * @returns xState machine
 */
export const suggestOrderSizeUpMachine = createMachine({
  id: 'suggestOrderSize',
  initial: SuggestOrderSizeUpState.Suggest,
  states: {
    [SuggestOrderSizeUpState.Suggest]: {
      on: {
        [SuggestOrderSizeUpEvent.Accept]: {
          actions: [
            assign(({ event }) => {
              return {
                daysOfFood: event.daysOfFood,
                earliestDesiredArrivalDate: event.earliestDesiredArrivalDate,
                latestDesiredArrivalDate: event.latestDesiredArrivalDate,
              };
            }),
          ],
          target: SuggestOrderSizeUpState.AcceptConfirmation,
        },
        [SuggestOrderSizeUpEvent.Reject]: {
          actions: [
            assign(({ event }) => {
              return {
                daysOfFood: event.daysOfFood,
                earliestDesiredArrivalDate: event.earliestDesiredArrivalDate,
                latestDesiredArrivalDate: event.latestDesiredArrivalDate,
              };
            }),
          ],
          target: SuggestOrderSizeUpState.RejectConfirmation,
        },
        [SuggestOrderSizeUpEvent.Close]: {
          target: SuggestOrderSizeUpState.Close,
        },
      },
    },
    [SuggestOrderSizeUpState.AcceptConfirmation]: {
      on: {
        [SuggestOrderSizeUpEvent.Close]: {
          target: SuggestOrderSizeUpState.Close,
        },
        [SuggestOrderSizeUpEvent.ContinueToPlan]: {
          target: SuggestOrderSizeUpState.ContinueToPlan,
        },
      },
    },
    [SuggestOrderSizeUpState.RejectConfirmation]: {
      on: {
        [SuggestOrderSizeUpEvent.Close]: {
          target: SuggestOrderSizeUpState.Close,
        },
        [SuggestOrderSizeUpEvent.ContinueToPlan]: {
          target: SuggestOrderSizeUpState.ContinueToPlan,
        },
      },
    },
    [SuggestOrderSizeUpState.Close]: {
      type: 'final',
    },
    [SuggestOrderSizeUpState.ContinueToPlan]: {
      type: 'final',
    },
  },
  types: {} as {
    events: SuggestOrderSizeUpEventMachineEvent;
  },
});
