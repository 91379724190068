import type {
  TikTokMounter,
  PowerInboxAttributes,
  NextDoorMounter,
  AmazonTagMounter,
  GTagMounter,
} from '../vendors';

/*
 * This global window object can be used throughout the pixel package. It contains methods/objects that vendors want to reference.
 * It also serves the purpose of preventing duplicate alterations to the global Window type wherever the package is imported.
 * If complex types are required for a window object key, they should be defined in the corresponding
 * local vendor folder and imported here.
 */

declare global {
  interface Window {
    // Pixel Class
    Image: typeof Image;
    // NextDoor
    ndp?: NextDoorMounter;
    // GoogleMaps
    google?: typeof google;
    // PowerInbox
    pi_params?: PowerInboxAttributes;
    // TikTok
    ttq?: TikTokMounter;
    // Amazon Tag
    amzn?: AmazonTagMounter;
    // Gtag
    gtag?: GTagMounter;
  }
}

/* We have to have some valid constants exported from this file in order for the global window properties above
 * to be accessible elsewhere.
 */
export const pixelRevenue = 0;

export type PixelRevenue = typeof pixelRevenue;
