import { Grid, GridItem, Button, PageWidth } from '@farmersdog/corgi-x';
import { HomepageTitle1 } from '../HomepageTitle';
import HowItWorksProgress from './HowItWorksProgress';

import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import styles from './SectionHowItWorks.module.scss';
import { track, events } from 'src/analytics';
import { CtaTracker } from '../CtaTracker';

function SectionHowItWorks() {
  const handleClickBuildMyPlan = () => {
    track(events.homepage_click_how_it_works_cta);
  };
  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <section className={styles.section}>
      <PageWidth>
        <Grid>
          <GridItem>
            <HomepageTitle1 center style={{ marginBottom: 0 }}>
              How your plan works
            </HomepageTitle1>
          </GridItem>
          <GridItem
            className={styles.infoContainer}
            justifyContent={{ xs: 'center' }}
          >
            <HowItWorksProgress />
          </GridItem>
          <GridItem
            className={styles.buttonContainer}
            justifyContent={{ xs: 'center' }}
          >
            <CtaTracker location="How your plan works" ctaCopy="Build My Plan">
              <Button
                type="link"
                to={PATH_SIGNUP_ME}
                onClick={handleClickBuildMyPlan}
                className={accessibilityThemeStyles.responsiveCta}
              >
                Build My Plan
              </Button>
            </CtaTracker>
          </GridItem>
        </Grid>
      </PageWidth>
    </section>
  );
}

export default SectionHowItWorks;
