import { useId } from 'react';

import type { ApolloError } from '@apollo/client';
import { ContentLoader, Form, Text, useViewport } from '@farmersdog/corgi-x';
import { ActionButtons, FrequencyHelp, FrequencySelector } from './features/';
import { useChangeFrequencyData } from './network/useChangeFrequencyData';
import { useChangeFrequencyUpdate } from './network/useChangeFrequencyUpdate';
import type { TrackChangeOrderSizeSuccessProps } from './analytics/trackChangeOrderSizeSuccess';

export interface ChangeFrequencyProps {
  onCompleted: (
    suggestedFrequencies?: TrackChangeOrderSizeSuccessProps
  ) => void;
  onError: (error: ApolloError) => void;
}

export function ChangeFrequency(props: ChangeFrequencyProps) {
  const labelId = useId();
  const { large } = useViewport();
  const loadingHeight = large ? 245 : 345;

  const {
    currentFrequency,
    suggestedFrequencies,
    currentNextDate,
    journeyStatus,
    loading,
  } = useChangeFrequencyData();
  const handleCompleted = () => {
    props.onCompleted(suggestedFrequencies);
  };

  const {
    selectedFrequency,
    handleFrequencyChange,
    handleSubmit,
    canSubmit,
    isSubmitting,
    handleCancel,
  } = useChangeFrequencyUpdate({
    currentFrequency,
    currentNextDate,
    journeyStatus,
    onCompleted: handleCompleted,
    onError: props.onError,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      enableSubmit={canSubmit}
      aria-labelledby={labelId}
    >
      <Text as="h2" variant="heading-28" color="kale-3" bold id={labelId}>
        Order Size
      </Text>

      <FrequencyHelp />

      <ContentLoader loading={loading} mode="dark" height={loadingHeight}>
        {suggestedFrequencies && (
          <FrequencySelector
            value={selectedFrequency}
            onChange={handleFrequencyChange}
            suggestedFrequencies={suggestedFrequencies}
          />
        )}

        <Text variant="heading-12" as="p" vSpacing="md">
          *Daily price does not include treats
        </Text>
        <ActionButtons
          loading={isSubmitting}
          canSubmit={canSubmit}
          onCancel={handleCancel}
        />
      </ContentLoader>
    </Form>
  );
}
