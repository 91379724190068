import classNames from 'classnames';
import Transition from 'react-transition-group/Transition';

import styles from './Animation.module.css';
import { ANIMATIONS } from './constants';

import type { Behavior, AnimationName } from './types';
import type { ReactNode } from 'react';

interface AnimationProps {
  /** Inner elements */
  children: ReactNode;
  /** The type of animation */
  name: AnimationName;
  /** Should the element appear inline */
  inline?: boolean;
  /** How this component is mounted and displayed. For more detailed description of possibilities check Animation.md file */
  behavior: Behavior;
  /** Changing this to true will render the animation */
  in: boolean;
}

function Animation({
  children,
  name,
  inline,
  behavior,
  in: show,
}: AnimationProps) {
  const { timeout } = ANIMATIONS[name];

  return (
    <Transition
      component={null}
      timeout={timeout}
      appear={behavior === 'visible'}
      mountOnEnter={behavior === 'hidden'}
      unmountOnExit={behavior === 'hidden'}
      in={show}
    >
      {state => (
        <div
          className={classNames(
            styles[name],
            styles[behavior],
            styles[state],
            inline ? styles.inline : ''
          )}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}

export default Animation;
