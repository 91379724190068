import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Page, Picture, Text } from '@farmersdog/corgi-x';
import { EmailSupportButton } from './EmailSupportButton';
import type { ApplicationError } from '../ApplicationError';
import type { ValidationError } from '../ValidationError';
import { VALIDATION_ERROR_NAME } from '../ValidationError';
import { errorDogSources } from '../assets';
import { trackShowErrorMessage } from '../analytics';
import { reporter } from '../services';
import styles from './ErrorPage.module.css';

export const DEFAULT_APPLICATION_ERROR_HEADING =
  'Our website is being a bad boy';
export const DEFAULT_VALIDATION_ERROR_HEADING = 'Data validation failed';
export const DEFAULT_ERROR_DISPLAY_MESSAGE = `We couldn’t process your request. While we are cleaning the mess, try going to the previous page or contacting our customer support.`;

interface ErrorPageProps {
  error: Error | ValidationError | ApplicationError;
}

/** A full screen error page. This page contains a CTA to navigate the customer
 * to their previous path, and a secondary CTA to contact customer support. The
 * header and body copy can be customized by the `title` and `displayMessage` of
 * our custom error classes, but will use default copy for others (Error,
 * ApolloError, GraphQLError, etc...) */
export function ErrorPage(props: ErrorPageProps) {
  const history = useHistory();

  useEffect(() => {
    console.error(props.error);
    reporter.error(props.error);
    trackShowErrorMessage(props.error);
  }, [props.error]);

  const goBack = () => history.goBack();

  let heading =
    props.error.name === VALIDATION_ERROR_NAME
      ? DEFAULT_VALIDATION_ERROR_HEADING
      : DEFAULT_APPLICATION_ERROR_HEADING;
  if ('title' in props.error && props.error.title) {
    heading = props.error.title;
  }

  let displayMessage: string | ReactNode = DEFAULT_ERROR_DISPLAY_MESSAGE;
  if ('displayMessage' in props.error && props.error.displayMessage) {
    displayMessage = props.error.displayMessage;
  }

  // only show the dog image if it's a non-user error
  const shouldShowDog = props.error.name !== VALIDATION_ERROR_NAME;

  return (
    <Page
      layout="base-centered"
      heading={heading}
      onBackClick={goBack}
      className={styles.page}
    >
      {shouldShowDog && (
        <div className={styles.pictureContainer}>
          <Picture
            sources={errorDogSources}
            alt="Bad Boy"
            className={styles.picture}
          />
        </div>
      )}
      <Text as="p" variant="article-16" className={styles.displayMessage}>
        {displayMessage}
      </Text>
      <div className={styles.buttonsContainer}>
        <Button type="button" onClick={goBack}>
          Go Back
        </Button>
        <EmailSupportButton
          asLink
          subject="Support"
          className={styles.contactSupport}
        >
          <Text variant="heading-16" bold>
            Contact Support
          </Text>
        </EmailSupportButton>
      </div>
    </Page>
  );
}
