import type { ButtonBaseProps } from '@farmersdog/corgi-x';
import { ButtonBase } from '@farmersdog/corgi-x';

import styles from './CircleButton.css';

import type { ReactNode } from 'react';

/**
 * Render a circular button. Works well with an icon as child!
 */

interface CircleButtonProps extends ButtonBaseProps {
  children: ReactNode;
}

export default function CircleButton({
  children,
  ...props
}: CircleButtonProps) {
  return (
    <ButtonBase type="button" {...props} className={styles.circleButton}>
      {children}
    </ButtonBase>
  );
}
