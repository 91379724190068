import { useQuery } from '../../services/apollo';

import validateBreedsWeight from './validateBreedsWeight.graphql';

import type {
  ValidateBreedsWeightQueryVariables,
  ValidateBreedsWeightQuery,
} from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.QueryHookOptions<
  ValidateBreedsWeightQuery,
  ValidateBreedsWeightQueryVariables
>;

export function useValidateBreedsWeight(options?: Options) {
  return useQuery<
    ValidateBreedsWeightQuery,
    ValidateBreedsWeightQueryVariables
  >(validateBreedsWeight, options);
}
