import classNames from 'classnames';

import { Text } from '@farmersdog/corgi-x';

import styles from './AnalysisTable.module.css';

import type { ReactNode } from 'react';

interface AnalysisTableProps {
  children: ReactNode;
  className?: string;
}

export function AnalysisTable({ className, children }: AnalysisTableProps) {
  return (
    <table className={classNames(styles.table, className)}>{children}</table>
  );
}

interface AnalysisHeaderProps {
  heading: string;
  subheading?: string;
}

export function AnalysisHeader({ heading, subheading }: AnalysisHeaderProps) {
  return (
    <>
      <caption className={styles.caption}>{heading}</caption>
      <thead>
        <tr className={classNames(styles.tr, styles.headerRow)}>
          <th colSpan={2} className={styles.th}>
            <Text
              as="p"
              variant="article-16"
              color="charcoal-3"
              bold
              className={styles.headerText}
            >
              {heading}
            </Text>
            {subheading && (
              <Text
                as="p"
                variant="article-16"
                color="charcoal-3"
                className={styles.headerText}
              >
                {subheading}
              </Text>
            )}
          </th>
        </tr>
      </thead>
    </>
  );
}

interface AnalysisBodyProps {
  children: ReactNode;
}

export function AnalysisBody({ children }: AnalysisBodyProps) {
  return <tbody>{children}</tbody>;
}

interface AnalysisRowProps {
  percentage: ReactNode;
  name: ReactNode;
}

export function AnalysisRow({ percentage, name }: AnalysisRowProps) {
  return (
    <Text as="tr" variant="article-16" className={styles.tr}>
      <td className={styles.name}>{name}</td>
      <td className={styles.percentage}>{percentage}</td>
    </Text>
  );
}
