import type { ProspectiveCxPhoneNumberConfig } from '../../../app/lib/Split';
import {
  AnonymousFeatureKeys,
  isProspectiveCxPhoneNumberConfig,
  usePawFeature,
} from '../../../app/lib/Split';

const DEFAULT_EMAIL = true;
const DEFAULT_PHONE = false;

interface UseProspectiveCxContactReturn {
  shouldDisplayPhone: boolean;
  shouldDisplayEmail: boolean;
}

/**
 * This hook determines the config status of the feature
 * prospective_cx_phone_number using the provided `configKey` This feature has a
 * rather unfortunate name and behavior, where the treatment on/off determines
 * the value of `shouldDisplayPhone` UNLESS the config value returns a value for
 * `phone`, which would override the treatment. Also, `shouldDisplayEmail` is
 * only toggleable via the config 🤦‍♂️. This is up for re-evaluation in
 * https://app.shortcut.com/farmersdog/story/91049
 * @param configKey - The config determines the feature in which we are toggling
 * links ie: 'footer'
 * @returns whether or not consumer should display phone or email cx contact links.
 */
export function useProspectiveCxContact(
  configKey?: keyof ProspectiveCxPhoneNumberConfig
): UseProspectiveCxContactReturn {
  const { treatment, config } = usePawFeature(
    AnonymousFeatureKeys.prospective_cx_phone_number
  );

  if (!isProspectiveCxPhoneNumberConfig(config)) {
    return {
      shouldDisplayPhone: treatment === 'on' || DEFAULT_PHONE,
      shouldDisplayEmail: DEFAULT_EMAIL,
    };
  }

  const configValue = configKey && config[configKey];

  const displayPhoneFromConfig = configValue?.phone ?? DEFAULT_PHONE;

  // Because the treatment is only for phone we should use default email
  const shouldDisplayEmail = configValue?.email ?? DEFAULT_EMAIL;

  const shouldDisplayPhone = configValue
    ? displayPhoneFromConfig
    : treatment === 'on';

  return {
    shouldDisplayPhone,
    shouldDisplayEmail,
  };
}
