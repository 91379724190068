import type { RedirectStatesEnumType } from './redirectStates';
import { getRedirectStates } from './redirectStates';
import type { TerminalStatesEnumType } from './terminalStates';
import type { CancellationResponsesEnumType } from './cancellationResponses';
import type { InitialStatesEnumType } from './initialStates';
import type { ReplacementFoodType } from './replacementFoodStates';

export type StatesUnion =
  | CancellationResponsesEnumType
  | TerminalStatesEnumType
  | InitialStatesEnumType
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | RedirectStatesEnumType
  | ReplacementFoodType;

export function isRedirectStateKey(
  key: unknown
): key is keyof typeof redirectStates {
  const redirectStates = getRedirectStates();
  return Object.hasOwnProperty.call(redirectStates, String(key));
}
