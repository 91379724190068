import { useState, useEffect } from 'react';

import { getDiscountTypeAndAmount, isReferrerValid } from './utils/discount';
import {
  getDiscountAmountCopy,
  getCouponBannerCopy,
  getBannerCtaCopy,
} from './utils/copy';
import type { ReferrerResponse } from './utils/getCoupon';
import { getCoupon } from './utils/getCoupon';
import * as couponCookie from './utils/couponCookie';
import type {
  DiscountType,
  DiscountTypeAndAmount,
  ReferrerType,
} from './types';

export const promises = new Set<Promise<void>>();
export const responses = new Map<string, ReferrerResponse>();
export const errors = new Map<string, Error>();

interface UseCouponReturn {
  /* Whether the referrer is valid */
  isValid: boolean;
  /* The discount amount */
  discountAmount: number;
  /* The discount type - fixed or percentage */
  discountType: DiscountType;
  /* The discount copy */
  discountCopy: string;
  /* The coupon code */
  couponCode?: string;
  /* The coupon banner copy  */
  bannerCopy: string;
  /* The banner CTA copy */
  bannerCtaCopy: string;
  /* The name of the user who made the referral */
  name?: string;
  /* Whether the coupon is loading */
  loading: boolean;
  /* Any errors return from fetching a coupon */
  error?: Error;
  /* The referrer type */
  referrerType: ReferrerType | undefined;
}

interface UseCouponArgs {
  apiUrl: string;
  overrideDiscount?: DiscountTypeAndAmount;
  defaultDiscount: DiscountTypeAndAmount;
}

/**
 * Return the current referrer
 */
export function useCoupon({
  apiUrl,
  overrideDiscount,
  defaultDiscount,
}: UseCouponArgs): UseCouponReturn {
  const [coupon, setCoupon] = useState<ReferrerResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const cookie = couponCookie.get();
  const cookieReferrerCode = cookie.referrerCode;
  const cookieReferrerType = cookie.referrerType;

  useEffect(() => {
    if (!cookieReferrerCode || !cookieReferrerType) {
      setLoading(false);
      return;
    }

    if (overrideDiscount) {
      setLoading(false);
      return;
    }

    const requestId = cookieReferrerCode;

    const get = async () => {
      setLoading(true);
      await Promise.allSettled(Array.from(promises));

      if (responses.has(requestId)) {
        setCoupon(responses.get(requestId));
        setLoading(false);
        return;
      }

      if (errors.has(requestId)) {
        setError(errors.get(requestId));
        setLoading(false);
        return;
      }

      try {
        const nextCoupon = await getCoupon({
          apiUrl,
          coupon: {
            code: cookieReferrerCode,
            type: cookieReferrerType,
          },
        });

        responses.set(requestId, nextCoupon);

        if (isReferrerValid(nextCoupon)) {
          setCoupon(nextCoupon);
        } else {
          couponCookie.remove();
        }
      } catch (caughtError: unknown) {
        const nextError =
          caughtError instanceof Error
            ? caughtError
            : new Error('Error fetching coupon');

        errors.set(requestId, nextError);
        setError(nextError);
        couponCookie.remove();
      } finally {
        setLoading(false);
      }
    };

    const promise = get();
    promises.add(promise);
    // Warning: Cookie based values don't change without a rerender of the consuming component
  }, [apiUrl, overrideDiscount, cookieReferrerCode, cookieReferrerType]);

  const isValid = isReferrerValid(coupon);
  const { referrerCode } = couponCookie.get();
  const { discountAmount, discountType } = getDiscountTypeAndAmount({
    coupon,
    overrideDiscount,
    defaultDiscount,
  });

  const discountCopy = getDiscountAmountCopy({
    discountType,
    discountAmount,
  });

  const bannerCopy = getCouponBannerCopy({
    coupon,
    discountAmount,
    discountType,
  });

  const bannerCtaCopy = getBannerCtaCopy({
    discountType,
    discountAmount,
  });

  return {
    loading,
    error,
    isValid,
    discountType,
    discountAmount,
    discountCopy,
    bannerCopy,
    bannerCtaCopy,
    couponCode: referrerCode,
    referrerType: cookieReferrerType,
  };
}
