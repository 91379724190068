/* CH#49502: Used by CVR */
import Picture from 'src/components/Picture';

import type { LogoProps } from './Logos.types';

import WSJPng from 'src/assets/images/logos/wsj.png';
import WSJWebP from 'src/assets/images/logos/wsj.webp';

export function WSJLogo(props: LogoProps) {
  return (
    <Picture
      sources={[WSJWebP, WSJPng]}
      alt="The Wall Street Journal Logo"
      {...props}
    />
  );
}
