import type { LightboxProps } from '@farmersdog/corgi';
import useGlobalLightbox from 'src/utils/useGlobalLightbox';

const DebugModal = 'debugModal';

export function useDebugModal(lightboxOptions?: LightboxProps) {
  const modalControls = useGlobalLightbox({
    ...lightboxOptions,
    id: DebugModal,
  });

  return modalControls;
}
