import ErrorPage from 'src/errors/ErrorPage';
import { useAsyncModule } from 'src/utils/useAsyncModule';
import Page from '../Page';
import type { PawModule } from './Paw';

export function LazyLoadedPaw() {
  const pawModule = useAsyncModule<PawModule>(() => import('./Paw'));

  if (pawModule.error) {
    return <ErrorPage error={pawModule.error} />;
  }

  if (pawModule.loading || !pawModule.called) {
    return <Page title="Loading…" loading />;
  }

  const PAW = pawModule.data?.Paw;
  return <>{PAW && <PAW />}</>;
}
