import { boxAssets, ringosPlanAssets, treatsAssets } from '../assets';

export type ColumnCopy = {
  title: string;
  body: string;
  secondParagraph?: string;
  quote: string;
  author: string;
  sources: string[];
};

export const columnCopies: ColumnCopy[] = [
  {
    title: 'Only the good stuff',
    body: 'Simply made with only a few, whole ingredients, our human-grade treats skip the junk and ultra-processing.',
    quote:
      "“I’ve tried to find healthy, usable treats for so long, and honestly gave up! I am so glad to have treats that are the same quality of food that I've come to know and love from The Farmer's Dog!”",
    author: '— Max’s person',
    sources: ringosPlanAssets,
  },
  {
    title: 'Treat as you need',
    body: 'The soft texture with grooves makes it easy to snap into low-calorie bites—making it great for training, at home, or on-the-go.',
    quote:
      '“My dogs love the taste of them. I love how I can either feed as a full stick or break off into training treats!”',
    author: '— Captain Jack Sparrow & Moosco’s Person',
    sources: treatsAssets,
  },
  {
    title: 'Better for them and easier for you',
    body: 'It’s easy to overfeed your treats. That’s why all portions are personalized by our on-staff nutritionists based on your dog’s plan.',
    secondParagraph:
      'Treats ship in your existing orders and you can edit, skip, or remove at any time.',
    quote:
      "“Lilly is a picky eater… Now whenever I stand where I keep her treats, she sits and lovingly looks up at me! She's NEVER done that before.”",
    author: '— Lilly’s person',
    sources: boxAssets,
  },
];
