import { GridContainer } from '@farmersdog/corgi-x';
import { Text, type TextProps } from '@farmersdog/corgi-x';

import type { PageSectionProps } from '../../DIY';

import styles from './VitalMix.module.scss';
import classNames from 'classnames';

import Picture from 'src/components/Picture';
import vitalMixTreeAndDogWebP from '../../assets/vital-mix-tree-and-dog.webp';
import vitalMixTreeAndDogPng from '../../assets/vital-mix-tree-and-dog.png';
import vitalMixCloudsAndSunWebP from '../../assets/vital-mix-clouds-and-sun.webp';
import vitalMixCloudsAndSunPng from '../../assets/vital-mix-clouds-and-sun.png';
import { useCorgiV2Viewport } from 'src/screen';

export function VitalMix({ className }: PageSectionProps) {
  const responsiveClassName = useCorgiV2Viewport({
    xs: styles.xs,
    lg: '',
  });

  const articleVariant = useCorgiV2Viewport<TextProps['variant']>({
    xs: 'article-16',
    lg: 'article-20',
  });
  const headingVariant = useCorgiV2Viewport<TextProps['variant']>({
    xs: 'heading-16',
    lg: 'heading-22',
  });

  return (
    <section
      className={classNames(styles.container, className, responsiveClassName)}
    >
      <Picture
        sources={[vitalMixCloudsAndSunWebP, vitalMixCloudsAndSunPng]}
        alt="Clouds and sun"
        className={styles.firstImage}
      />
      <GridContainer className={styles.content}>
        <Text variant="heading-40" color="white" as="h2" bold>
          Why a nutrient mix is vital for homemade dog food
        </Text>
        <Text variant={articleVariant} color="white" as="p">
          “Studies have shown 95% of home-prepared diets of well-intended owners
          have one or more significant nutrient deficiencies. Feeding an
          incomplete diet over time can result in serious health problems like
          immune dysfunction and degenerative muscle diseases.
        </Text>

        <Text variant={articleVariant} color="white" as="p">
          The Farmer’s Dog recipes and DIY supplement are an ideal way to
          optimize the home-prepared plan with proper complete and balanced
          nutrition.”
        </Text>
        <Text variant={headingVariant} color="white" as="p">
          Dr. Joseph Wakshlag, DVM, PhD, DACVN
        </Text>
      </GridContainer>
      <Picture
        sources={[vitalMixTreeAndDogWebP, vitalMixTreeAndDogPng]}
        alt="Tree and dog"
        className={styles.secondImage}
      />
    </section>
  );
}
