import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

import type { TreatSize } from '../../graphql/types';

type TrackSignupFreeTreatsRemoveTreatFromCartArgs = {
  name: string;
  size: TreatSize;
};

/** A Segment tracking event for when a user removes a treat from their cart in the checkout page. */

export function trackSignupFreeTreatsRemovedFromCart({
  name,
  size,
}: TrackSignupFreeTreatsRemoveTreatFromCartArgs) {
  track(segmentTrackEvents.signup_free_treats_remove_treat_from_cart, {
    name,
    size,
    page: 'treats',
  });
}
