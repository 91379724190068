import type { ChangeEvent } from 'react';
import { Input, Text } from '@farmersdog/corgi-x';
import type { CgsCreditCardFieldsFragment } from '../../../network';
import { CreditCardNumber } from './CreditCardNumber';
import { CreditCardCvc } from './CreditCardCvc';
import { CreditCardExpiration } from './CreditCardExpiration';
import type { UseCreditCardForm } from '../hooks/useCreditCardForm';
import styles from './CreditCard.module.css';

type CreditCardProps = CgsCreditCardFieldsFragment &
  UseCreditCardForm & {
    cardholderName: string;
    setCardholderName: (newName: string) => void;
  };

/**
 * We do not have complete customer credit card information. We only have safe
 * scraps returned from Stripe: name, last 4 digits, and expiration date.
 *
 * Besides the cardholder name which is controlled via parent state, the other
 * inputs are controlled by Corgi which wraps Stripe elements. The CreditCard
 * component will display the partial credit card details we have as placeholder
 * inputs, ie: <input placeholder='**** **** **** 1234' />. Once a customer
 * focuses any of these inputs, the placeholders are removed and Corgi
 * components take over to interact with Stripe elements.
 *
 * These inputs are validated with every keystroke, but no updates are saved
 * until calling the submit callback from props.
 */
export function CreditCard(props: CreditCardProps) {
  function handleCardholderNameChange(e: ChangeEvent<HTMLInputElement>) {
    props.setCardholderName(e.target.value);
  }
  return (
    <fieldset className={styles.fieldsetItem}>
      <legend className={styles.legend}>
        <Text color="charcoal-3" variant="heading-22" as="h2" bold>
          Credit Card
        </Text>
      </legend>
      <div className={styles.grid}>
        <Input
          className={styles.nameOnCard}
          label="Name on Card"
          value={props.cardholderName}
          onChange={handleCardholderNameChange}
        />
        <CreditCardNumber
          className={styles.creditCardNumber}
          last4Digits={props.last4Digits}
          isEditing={props.isEditing}
          onCreditCardNumberFocus={props.handleCreditCardDetailsFocus}
          onCreditCardNumberChange={props.handleCreditCardDetailsChange}
        />
        <CreditCardExpiration
          className={styles.creditCardExpiration}
          expirationMonth={props.expirationMonth}
          expirationYear={props.expirationYear}
          isEditing={props.isEditing}
          onCreditCardExpirationFocus={props.handleCreditCardDetailsFocus}
          onCreditCardExpirationChange={props.handleCreditCardDetailsChange}
        />
        <CreditCardCvc
          className={styles.creditCardCvc}
          isEditing={props.isEditing}
          onCreditCardCvcFocus={props.handleCreditCardDetailsFocus}
          onCreditCardCvcChange={props.handleCreditCardDetailsChange}
        />
      </div>
    </fieldset>
  );
}
