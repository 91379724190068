import type { InputCustomMask, InputMask } from '@farmersdog/corgi-x';
import * as Yup from 'yup';
import { isInputNumberLength } from '../../..//utils';

export enum AddClinicFormFieldName {
  Name = 'addClinic-name',
  StreetAddress1 = 'addClinic-streetAddress1',
  StreetAddress2 = 'addClinic-streetAddress2',
  City = 'addClinic-city',
  State = 'addClinic-state',
  ZipCode = 'addClinic-zipCode',
  VeterinarianName = 'addClinic-veterinarianName',
}

export interface AddClinicFormProps {
  [AddClinicFormFieldName.Name]: string;
  [AddClinicFormFieldName.StreetAddress1]: string;
  [AddClinicFormFieldName.StreetAddress2]: string;
  [AddClinicFormFieldName.City]: string;
  [AddClinicFormFieldName.State]: string;
  [AddClinicFormFieldName.ZipCode]: string;
  [AddClinicFormFieldName.VeterinarianName]: string;
}

export type InputType = {
  field: AddClinicFormFieldName;
  label: string;
  mask?: InputMask | InputCustomMask;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
};

export const addClinicFormInitialValues = {
  [AddClinicFormFieldName.Name]: '',
  [AddClinicFormFieldName.StreetAddress1]: '',
  [AddClinicFormFieldName.StreetAddress2]: '',
  [AddClinicFormFieldName.City]: '',
  [AddClinicFormFieldName.State]: '',
  [AddClinicFormFieldName.ZipCode]: '',
  [AddClinicFormFieldName.VeterinarianName]: '',
};

export const addClinicFormSchema = Yup.object({
  [AddClinicFormFieldName.Name]: Yup.string().required('Name is required'),
  [AddClinicFormFieldName.StreetAddress1]: Yup.string().required(
    'Street Address is required'
  ),
  [AddClinicFormFieldName.StreetAddress2]: Yup.string(),
  [AddClinicFormFieldName.City]: Yup.string().required('City is required'),
  [AddClinicFormFieldName.State]: Yup.string().required('State is required'),

  [AddClinicFormFieldName.ZipCode]: Yup.string()
    .required('Zip code is required')
    .test(
      'Zip code length',
      'Zip code must be exactly 5 or 9 digits',
      isInputNumberLength([5, 9])
    ),
  [AddClinicFormFieldName.VeterinarianName]: Yup.string(),
});

export const inputs: InputType[] = [
  {
    field: AddClinicFormFieldName.Name,
    label: 'Clinic Name',
  },
  {
    field: AddClinicFormFieldName.StreetAddress1,
    label: 'Street Address',
  },
  {
    field: AddClinicFormFieldName.StreetAddress2,
    label: 'Apt.., Floor, Unit, etc (Optional)',
  },
  {
    field: AddClinicFormFieldName.City,
    label: 'City',
  },
  {
    field: AddClinicFormFieldName.State,
    label: 'State',
  },
  {
    field: AddClinicFormFieldName.ZipCode,
    label: 'Zip Code',
    inputMode: 'numeric',
    mask: 'zip',
  },
  {
    field: AddClinicFormFieldName.VeterinarianName,
    label: 'Veterinarian Name (Optional)',
  },
];
