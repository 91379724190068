import { emails } from '@farmersdog/constants';
import { Button, Color, Page, Text } from '@farmersdog/corgi-x';
import { CheckCircle } from '@farmersdog/corgi-x/icons';

import { formatDateRange } from '../../../utils/formatting/formatDateRange';
import type { OrderDeliveryWindowFieldsFragment } from '../../network';

import { formatDateToMonthAndDay } from '../../../utils';
import type { CancellationResponsesEnumType } from '../../flow-state-management';
import { CancellationResponsesEnum } from '../../flow-state-management';
import styles from './CancellationSuccess.module.css';

export interface CancellationSuccessProps {
  deliveryWindow?: OrderDeliveryWindowFieldsFragment;
  cancellationReasons: CancellationResponsesEnumType[];
  isUnlocked: boolean;
  onExit: () => void;
}

const dogPassedCopy = `We're very sorry to hear about your dog. Our hearts go out to you and we know they will be very missed.`;

export function CancellationSuccess(props: CancellationSuccessProps) {
  let formattedDateCopy = '';
  if (props.deliveryWindow && !props.isUnlocked) {
    const formattedDateRange = props.deliveryWindow.estimatedDeliveryDate
      ? formatDateToMonthAndDay(props.deliveryWindow.estimatedDeliveryDate)
      : formatDateRange(
          props.deliveryWindow.earliestDesiredArrivalDate,
          props.deliveryWindow.latestDesiredArrivalDate,
          { abbreviatedMonth: false }
        );
    formattedDateCopy = `Your final order will be delivered ${
      props.deliveryWindow.estimatedDeliveryDate ? 'by' : 'between'
    } ${formattedDateRange}.`;
  }

  const hasDogPassed = hasDogPassedReason(props.cancellationReasons);
  const canceledCopy = `You’ve successfully cancelled your subscription. ${formattedDateCopy}`;
  const subHeadingCopy = hasDogPassed ? dogPassedCopy : canceledCopy;

  return (
    <Page
      layout="base-centered"
      image={<CheckCircle size={64} fill={Color.Carrot2} />}
      onCloseClick={props.onExit}
      className={styles.page}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Successfully Cancelled
      </Text>
      <Text variant="article-20" as="p" vSpacing="sm">
        {subHeadingCopy}
      </Text>
      <div className={styles.ctas}>
        <Button type="button" onClick={props.onExit} className={styles.button}>
          Done
        </Button>
        <Button
          type="anchor"
          variant="plain-text"
          href={`mailto:${emails.EMAIL_HELP}`}
          className={styles.link}
        >
          <Text variant="heading-16">Contact Us</Text>
        </Button>
      </div>
    </Page>
  );
}

function hasDogPassedReason(reasons: CancellationResponsesEnumType[]) {
  return reasons.includes(CancellationResponsesEnum.DogPassed);
}
