import type { ReactNode } from 'react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from './Apollo';
import { PawSplitProvider } from './Split';
import { HelmetProvider } from 'react-helmet-async';

interface LibProvidersProps {
  children: ReactNode;
  /** Use Website's Apollo Client when rendering App in Website */
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  /** Set this to false when rendering App in website, which has another router */
  withBrowserRouter?: boolean;
}

export function LibProviders({
  children,
  apolloClient,
  withBrowserRouter = true,
}: LibProvidersProps) {
  if (withBrowserRouter) {
    return (
      <BrowserRouter>
        <HelmetProvider>
          <ApolloProvider client={apolloClient}>
            <PawSplitProvider>{children}</PawSplitProvider>
          </ApolloProvider>
        </HelmetProvider>
      </BrowserRouter>
    );
  } else {
    return (
      <ApolloProvider client={apolloClient}>
        <PawSplitProvider>{children}</PawSplitProvider>
      </ApolloProvider>
    );
  }
}
