import { isSessionCurrent } from './is-session-current';
import type { SessionData } from './is-valid-session-data';
import { isValidSessionData } from './is-valid-session-data';
import { sessionHasCorrectVersion } from './session-has-correct-version';

export function isSessionValid(
  sessionData: SessionData | null,
  currentTrackerVersion: number
): sessionData is SessionData {
  if (!sessionData || !isValidSessionData(sessionData)) {
    return false;
  }
  const { expiration, version } = sessionData;
  return (
    isSessionCurrent(expiration) &&
    sessionHasCorrectVersion({ version, currentTrackerVersion })
  );
}
