import { useFormik } from 'formik';
import styles from './ClinicForm.module.css';
import {
  Button,
  FormControl,
  Grid,
  GridItem,
  Input,
} from '@farmersdog/corgi-x';
import type { ClinicFormProps } from './validationForm';
import {
  ClinicFormFieldName,
  clinicFormInitialValues,
  clinicFormSchema,
} from './validationForm';

export type VetClinicFormProps = {
  onSaveChanges: (vetName: ClinicFormProps) => void;
  loading: boolean;
};

export const ClinicForm = ({ onSaveChanges, loading }: VetClinicFormProps) => {
  const form = useFormik<ClinicFormProps>({
    initialValues: clinicFormInitialValues,
    onSubmit: values => {
      onSaveChanges(values);
    },
    validationSchema: clinicFormSchema,
  });

  return (
    <form onSubmit={form.handleSubmit} aria-label="clinic-form">
      <Grid>
        <GridItem className={styles.inputs}>
          <FormControl
            aria-live="polite"
            message={form.errors[ClinicFormFieldName.VetName]}
            invalid={Boolean(
              form.touched[ClinicFormFieldName.VetName] &&
                form.errors[ClinicFormFieldName.VetName]
            )}
          >
            <Input
              type="text"
              label="Veterinarian Name (Optional)"
              name={ClinicFormFieldName.VetName}
              value={form.values[ClinicFormFieldName.VetName]}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
          </FormControl>
        </GridItem>
      </Grid>
      <Button
        className={styles.saveChanges}
        aria-disabled={!form.isValid || loading}
        type="submit"
      >
        Save Clinic
      </Button>
    </form>
  );
};
