import fetch from 'cross-fetch';
import qs from 'qs';
import type { ReferrerType, DiscountType } from '../types';

export interface ReferrerIdRequest {
  id: number;
  type: ReferrerType;
}

export interface ReferrerCodeRequest {
  code: string;
  type: ReferrerType;
}

export type CouponRequest = ReferrerIdRequest | ReferrerCodeRequest;

export interface CouponResponse {
  id: number;
  bannerCopy: string | null;
  type: string;
  discountType: DiscountType;
  discountPercentage?: number;
  discountAmount: number;
  invalid: boolean;
}
export interface UserReferrerResponse {
  id: number;
  name: string;
  discountType: DiscountType;
  discountPercentage?: number;
  discountAmount: number;
  invalid: boolean;
}

export const REFERRAL_NOT_FOUND_RESPONSE_DATA = 'Not Found';

export type ReferrerResponse =
  | CouponResponse
  | UserReferrerResponse
  | typeof REFERRAL_NOT_FOUND_RESPONSE_DATA;

export interface ApiResponse<T> {
  data: T;
}

export interface GetCouponArgs {
  apiUrl: string;
  coupon: CouponRequest;
}

export async function getCoupon({
  apiUrl,
  coupon,
}: GetCouponArgs): Promise<ReferrerResponse> {
  const url = `${apiUrl}${qs.stringify(coupon, {
    addQueryPrefix: true,
  })}`;

  const response = await fetch(url);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const json: ApiResponse<ReferrerResponse> = await response.json();

  return json.data;
}
