import { Lightbox } from '@farmersdog/corgi';
import { SubscriptionType } from '@farmersdog/lead-browser-storage';

import {
  useGlobalLightbox,
  LightboxId,
  useDynamicCalculatingScreen,
} from '../../../hooks';

import { AssetPreloader } from './AssetPreloader';
import { Comparison as ComparisonComponent } from './Comparison';
import { DiggingDog as DiggingDogComponent } from './DiggingDog';
import { PlanCalculationWithLoader as PlanCalculationWithLoaderComponent } from './PlanCalculationWithLoader';

import type { CalculatingScreenTreatment } from '../../../hooks';
import type { UseFeatureHook } from '../../../types';
import type { DynamicCalculatingScreenTreatments } from '../../../utils';

export interface CalculatingScreenContentProps {
  onCompleted: () => void;
  treatment?:
    | CalculatingScreenTreatment
    | DynamicCalculatingScreenTreatments.off;
}

interface CalculatingScreenProps {
  productLine: SubscriptionType;
  useFeature: UseFeatureHook;
}

export function CalculatingScreen({
  productLine,
  useFeature,
}: CalculatingScreenProps) {
  const {
    shouldShowDynamicCalculatingScreen,
    dynamicCalculatingScreenTreatment,
  } = useDynamicCalculatingScreen({ useFeature });

  const { rootProps, close, setSuppressed } = useGlobalLightbox({
    id: LightboxId.CalculatingScreen,
    onOpen: () => {
      setSuppressed(true);
    },
  });

  const CalculatingScreenComponent = getCalculatingScreenComponent({
    productLine,
    shouldShowDynamicCalculatingScreen,
  });

  return (
    <>
      <AssetPreloader />
      <Lightbox withAnimation={false} {...rootProps}>
        <CalculatingScreenComponent
          onCompleted={close}
          treatment={dynamicCalculatingScreenTreatment}
        />
      </Lightbox>
    </>
  );
}

function getCalculatingScreenComponent({
  productLine,
  shouldShowDynamicCalculatingScreen,
}: {
  productLine: SubscriptionType;
  shouldShowDynamicCalculatingScreen: boolean;
}) {
  if (productLine === SubscriptionType.Diy) {
    return DiggingDogComponent;
  } else if (shouldShowDynamicCalculatingScreen) {
    return PlanCalculationWithLoaderComponent;
  }
  return ComparisonComponent;
}
