import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import { blueprintTraverseApply } from '../../../blueprint/utils';

import { findByTypename } from './findByTypename';
import { StaticDataTypeNames, isStaticDataTypeName } from './types';

import type { Blueprint, Fragment } from '../../../blueprint/types';
import type { StaticData } from '../../types';
import type { SetRequired } from 'type-fest';

export const seedPrefix = 'seed:';

interface SeedableField {
  field: string;
  typename: StaticDataTypeNames;
}

type NodeWithInput = SetRequired<Fragment, 'input'>;

function isNodeWithInput(node: Fragment): node is NodeWithInput {
  return !!node.input;
}

function getSeedableFields(node: NodeWithInput): SeedableField[] {
  const fields: SeedableField[] = [];

  if (node.input) {
    Object.entries(node.input).forEach(([field, value]) => {
      if (typeof value === 'string' && value.startsWith(seedPrefix)) {
        const typename = value.split(seedPrefix)[1];

        if (isStaticDataTypeName(typename)) {
          fields.push({ field, typename });
        } else {
          throw new Error(`${typename} is not a valid static data typename`);
        }
      }
    });
  }

  return fields;
}

function transformStaticData(
  typename: StaticDataTypeNames,
  staticData: StaticData
): unknown[] {
  if (typename === StaticDataTypeNames.Breed) {
    return findByTypename(typename, staticData).map(breed => {
      return omit(breed, 'weightMax');
    });
  }
  return findByTypename(typename, staticData);
}

function applySeedData(node: Fragment, staticData: StaticData): void {
  if (!isNodeWithInput(node)) {
    return;
  }

  const fields = getSeedableFields(node);

  fields.forEach(({ field, typename }) => {
    if (node.input) {
      const seedData = transformStaticData(typename, staticData);
      node.input[field] = seedData;
    }
  });
}

export function seedStaticData(
  blueprint: Blueprint,
  staticData: StaticData
): Blueprint {
  const seededBlueprint = cloneDeep(blueprint);

  blueprintTraverseApply(seededBlueprint.root, node => {
    applySeedData(node, staticData);
  });

  seededBlueprint.fragments.forEach(fragment => {
    blueprintTraverseApply(fragment, node => {
      applySeedData(node, staticData);
    });
  });

  return seededBlueprint;
}
