import { mountVendorWindowEvent } from '../utils';
import type { NextDoorEvent } from './nextDoorTypes';
import { NEXTDOOR_EVENT_TYPES } from './nextDoorTypes';

export const PIXEL_ID = '2b8a6c70-5739-4ed8-8d3e-903bf68120df';

/** Track a NextDoor pixel event */
function _trackNextDoorEvent(window: Window, eventName: NextDoorEvent) {
  if (window.ndp) {
    window.ndp(NEXTDOOR_EVENT_TYPES.track, eventName);
  }
}

export const trackNextDoorEvent = mountVendorWindowEvent(
  'NextDoor',
  _trackNextDoorEvent
);
