import type { ValidAcceptFrequencyUpdateSubscriptionMutationResponse } from './useSuggestOrderSizeUpMutation';
import { useSuggestOrderSizeUpMutation } from './useSuggestOrderSizeUpMutation';
import { useToast } from '@farmersdog/corgi';
import { reporter } from '../../errors/services';

interface UseSuggestOrderSizeSubmitProps {
  onCompleted: (
    response: ValidAcceptFrequencyUpdateSubscriptionMutationResponse
  ) => void;
}
/**
 * Wraps mutation that updates subscription frequency
 */

export function useSuggestOrderSizeUpSubmit(
  props: UseSuggestOrderSizeSubmitProps
) {
  const { onCompleted } = props;
  const dispatchToast = useToast();
  const { onSubmit } = useSuggestOrderSizeUpMutation({
    onCompleted,
    onError: () => {
      dispatchToast({
        variant: 'negative',
        children:
          'Something went wrong, and your subscription was not updated. Please reload the page and try again.',
      });
      reporter.error(
        new Error('Error updating subscription in SuggestOrderSizeUp')
      );
    },
  });
  return {
    callSubmit: onSubmit,
  };
}
