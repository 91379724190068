/* eslint-disable @typescript-eslint/unbound-method */
import { Component } from 'react';

import { Text } from '@farmersdog/corgi-x';

import type { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: unknown) {
    console.warn('Caught an error', { error, info });
  }

  render() {
    const error = this.state?.error;

    return (
      <>
        {this.props.children}
        {error && (
          <>
            <Text variant="article-16">Error: {error?.message}</Text>
          </>
        )}
      </>
    );
  }
}
