/* CH#49502: Used by CVR */
import { TextV2 } from '@farmersdog/corgi';
import { Nowrap } from '@farmersdog/corgi-x';

import type { Referrer } from 'src/hooks/useReferrer';
import { DiscountType } from 'src/graphql/enums';
import { getDiscountAmountCopy } from 'src/utils/referral';

import styles from './DiscountCopy.module.scss';

interface ReferrerWithBannerCopy {
  bannerCopy: string;
}

interface ReferrerWithName {
  name: string;
}

function isReferrerWithBannerCopy(
  value: unknown
): value is ReferrerWithBannerCopy {
  return (
    Object.prototype.hasOwnProperty.call(value, 'bannerCopy') &&
    typeof (value as Referrer).bannerCopy === 'string'
  );
}

function isReferrerWithName(value: unknown): value is ReferrerWithName {
  return (
    Object.prototype.hasOwnProperty.call(value, 'name') &&
    typeof (value as Referrer).name === 'string'
  );
}

interface DiscountCopyProps {
  discountAmount: number;
  fellBackToDefaultDiscount: boolean;
  referrer: Referrer | Record<string, unknown>;
  discountType: DiscountType;
  highlightedText: string;
}

export default function DiscountCopy({
  fellBackToDefaultDiscount,
  discountAmount,
  referrer,
  discountType,
  highlightedText,
}: DiscountCopyProps) {
  const discountAmountCopy = getDiscountAmountCopy({
    discountAmount,
    discountType,
  });
  let discountCopy;

  if (fellBackToDefaultDiscount) {
    discountCopy = (
      <>
        Uh-oh, looks like your referral link is expired but here’s{' '}
        {discountAmountCopy} <Nowrap>because dogs</Nowrap>.
      </>
    );
  } else if (isReferrerWithBannerCopy(referrer) && referrer.bannerCopy !== '') {
    discountCopy = referrer.bannerCopy;
  } else if (discountAmount === 0) {
    discountCopy = (
      <>
        Discover your dog’s vet-recommended <Nowrap>meal plan</Nowrap>.
      </>
    );
  } else if (
    discountAmount === 100 &&
    discountType === DiscountType.percentage
  ) {
    if (isReferrerWithName(referrer) && referrer.name !== '') {
      discountCopy = (
        <>
          {referrer.name} has given you a <Nowrap>free trial</Nowrap>!
        </>
      );
    } else {
      discountCopy = (
        <>
          You’ve been given a <Nowrap>free trial</Nowrap>!
        </>
      );
    }
  } else {
    if (isReferrerWithName(referrer) && referrer.name !== '') {
      discountCopy = (
        <>
          {referrer.name} has gifted you {discountAmountCopy} your{' '}
          <Nowrap>first purchase</Nowrap>!
        </>
      );
    } else {
      discountCopy = (
        <>
          You’ve been gifted {discountAmountCopy} your{' '}
          <Nowrap>first purchase</Nowrap>!
        </>
      );
    }
  }

  return (
    <TextV2 variant="heading-16" color="white">
      {discountCopy}{' '}
      <TextV2
        variant="heading-16"
        color="white"
        bold
        className={styles.discountCta}
      >
        <Nowrap>{highlightedText}</Nowrap>
      </TextV2>
    </TextV2>
  );
}
