import type { ChangeEvent } from 'react';
import { browserOnly } from './browserOnly';

/**
 * Event handler to reset cursor position after a react re-render cycle.
 *
 * This is used when a component is in a controlled state and we change
 * programmatically modify a value to be different then what it would be
 * following a change event
 *
 */
export const handleControlledCursor = (
  event: ChangeEvent<HTMLInputElement>
) => {
  const cursorPosition = event.target.selectionStart;
  const target = event.target;
  const changeValue = event.target.value;

  browserOnly(window => {
    window.requestAnimationFrame(() => {
      const controlledValue = target.value;
      const lengthDifference = controlledValue.length - changeValue.length;

      if (lengthDifference === 0) {
        target.setSelectionRange(cursorPosition, cursorPosition);
        return;
      }

      if (
        cursorPosition &&
        String(controlledValue).charAt(cursorPosition) !==
          String(changeValue).charAt(cursorPosition)
      ) {
        target.setSelectionRange(
          cursorPosition + lengthDifference,
          cursorPosition + lengthDifference
        );
        return;
      }

      target.setSelectionRange(cursorPosition, cursorPosition);
    });
  });
};
