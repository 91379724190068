import { useCustomerAccountData } from '@/account/app/hooks/useCustomerAccountData';
import { useCustomerAccountNavigation } from '@/account/app/hooks/useCustomerAccountNavigation';
import { usePrefetchData } from '@/account/app/hooks/usePrefetchData';
import type { Navigation } from '@/account/app/utils/getNavigation';
import { useSubscriptionStatus } from '@/account/graphql/core/queries/useSubscriptionStatus';
import type { SubscriptionType } from '@/account/graphql/core/types';

import type { ApolloError } from '@apollo/client';

export type UseCustomerAccount = {
  isLoading: boolean;
  loadingError: Error | ApolloError | undefined;

  navigation: Navigation | undefined;
  justInTimeEnabled: boolean | undefined;
  subscriptionType: SubscriptionType | undefined;
  isSubscriptionActive: boolean;
  withRegularOrder: boolean;
  hasTreatsInSubscription: boolean;
  bothSuspended: boolean;
};

/** Feature hook for the customer account page. */
export function useCustomerAccount(): UseCustomerAccount {
  const prefetchState = usePrefetchData();
  const data = useCustomerAccountData();
  const navigation = useCustomerAccountNavigation(data);
  const { bothSuspended } = useSubscriptionStatus();

  const customerAccount: UseCustomerAccount = {
    isLoading: data.isLoading || prefetchState.loading || !prefetchState.called,
    loadingError: data.loadingError || prefetchState.error,

    navigation,
    justInTimeEnabled: data?.subscription?.justInTimeEnabled ?? undefined,
    subscriptionType: data?.subscription?.type ?? undefined,
    isSubscriptionActive: data.isSubscriptionActive,
    withRegularOrder: data.withRegularOrder,

    hasTreatsInSubscription: data.hasTreatsInSubscription,
    bothSuspended: bothSuspended,
  };

  return customerAccount;
}
