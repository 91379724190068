import { track, events } from 'src/analytics';

interface CheckoutDailyPriceAndFrequencyPayload {
  regularDailyPrice?: number;
  frequency?: number;
}

/**
 * Send a tracking event recording regular daily price and frequency when a user checks out
 *
 * @param previousStartDate - The start date that was changed
 * @param selectedStartDate - The start date that was selected using the UI
 */
export function trackCheckoutDailyPriceAndFrequency({
  regularDailyPrice,
  frequency,
}: CheckoutDailyPriceAndFrequencyPayload): void {
  track(events.checkout_daily_price_and_frequency, {
    regularDailyPrice,
    frequency,
  });
}
