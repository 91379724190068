import { PATH_ORDERS } from '@farmersdog/constants/paths';
import { useToastDialog } from '@farmersdog/corgi';
import { Grid, GridItem, Page, Text } from '@farmersdog/corgi-x';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import { useHistory } from 'react-router';
import type { AppProps } from '../app';
import { Loading } from '../components';
import { getCoreApiDataValidationError, isCreditCardError } from '../errors';
import styles from './ReactivationFlow.module.css';
import { trackReactivationLoad } from './analytics';
import { ReactivateState } from './constants';
import {
  EditAccount,
  ReactivationSuccess,
  ReviewAccountInformation,
  ShippingAndQuoteDisplay,
  UpdateBillingErrorPage,
} from './features';
import { useReactivateData, useReactivateFreshSubscription } from './network';
import { useEditAccount } from './network/useEditAccount';
import { useReactivateMachine } from './state-machine';
import { isSameAddress } from './utils';

interface ReactivationFlowProps {
  dispatchErrorModal?: AppProps['dispatchErrorModal'];
}

export function ReactivationFlow(props: ReactivationFlowProps) {
  const history = useHistory();
  const dispatchToast = useToastDialog();
  const request = useReactivateData({ onCompleted: trackReactivationLoad });
  const machine = useReactivateMachine();

  const editAccount = useEditAccount({
    onSubmitCompleted: () => machine.onNext(),
    onSubmitError: (error: unknown) => {
      props.dispatchErrorModal?.(error);
    },
  });

  const reactivateSubmit = useReactivateFreshSubscription({
    onCompleted: () => {
      machine.onNext();
    },
    onError: () => {
      dispatchToast({
        variant: 'negative',
        message: 'Please try again! Our system was misbehaving.',
        buttonLabel: 'Close',
        close: noop,
      });
    },
  });

  function handleOnClose() {
    history.push(PATH_ORDERS);
  }

  if (request.loading) {
    return <Loading />;
  }

  if (isCreditCardError(getCoreApiDataValidationError(request.error))) {
    return (
      <Page
        layout="base"
        heading="Please update your billing information"
        onCloseClick={handleOnClose}
        subHeading={
          <Text variant="article-20">
            Sorry, we’re having trouble verifying the billing information you
            entered – mind updating it and trying again? If the issue keeps
            happening, please contact us.
          </Text>
        }
      >
        <UpdateBillingErrorPage onClose={handleOnClose} />
      </Page>
    );
  }

  if (request.error) {
    throw request.error;
  }

  if (!request.data) {
    throw new Error('Unknown exception occurred while loading data');
  }

  const { customer, states } = request.data;

  const hasTreats = Boolean(customer?.freshSubscription?.addOns.length);
  const shouldShowShopTreats = !hasTreats;

  if (machine.isState(ReactivateState.EditAccount)) {
    return (
      <Page
        layout="base"
        heading="Account Details"
        onBackClick={machine.onPrevious}
        onCloseClick={handleOnClose}
        className={styles.defaultPage}
      >
        <EditAccount
          initialValues={{
            accountInformation: customer.accountInformation,
            shippingAddress: omit(customer.shippingAddress, [
              '__typename',
              'id',
            ]),
            billingAddress: omit(customer.billingAddress, '__typename'),
            isResidential: true,
            cardholderName: customer.creditCard.nameOnCard,
            useShippingAsBillingAddress: isSameAddress(
              customer.shippingAddress,
              customer.billingAddress
            ),
          }}
          creditCardDetails={customer.creditCard}
          states={states}
          onSubmit={editAccount.updateAccountSubmit.submit}
          isSubmitting={editAccount.updateAccountSubmit.isSubmitting}
          onCancel={machine.onPrevious}
          suggestedAddressModalProps={editAccount.suggestedAddressModalProps}
          submittedAddress={
            editAccount.suggestedAddressFlowValues?.submittedAddress
          }
          suggestedAddress={
            editAccount.suggestedAddressFlowValues?.suggestedAddress
          }
          onSuggestedAddressCancel={editAccount.onSuggestedAddressCancel}
          onSuggestedAddressConfirm={editAccount.onSuggestedAddressConfirm}
        />
      </Page>
    );
  }

  if (machine.isState(ReactivateState.ReactivateSuccess)) {
    return (
      <Page
        layout="base"
        heading="Finished!"
        onCloseClick={handleOnClose}
        className={styles.successPage}
      >
        {reactivateSubmit.nextOrderToBeDelivered && (
          <ReactivationSuccess
            onExit={handleOnClose}
            nextOrderToBeDelivered={reactivateSubmit.nextOrderToBeDelivered}
            shouldShowShopTreats={shouldShowShopTreats}
          />
        )}
      </Page>
    );
  }

  return (
    <Page
      layout="base"
      onCloseClick={handleOnClose}
      className={styles.defaultPage}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Reactivate Your Plan
      </Text>
      <Text
        variant="article-20"
        as="p"
        color="charcoal-3"
        topSpacing="sm"
        bottomSpacing="lg"
      >
        Welcome back! Let’s make sure we have the correct shipping and billing
        info for your orders.
      </Text>
      <Grid>
        <GridItem xs={12} lg={6} className={styles.gridItem}>
          <ReviewAccountInformation
            accountInformation={customer.accountInformation}
            billingAddress={customer.billingAddress}
            shippingAddress={customer.shippingAddress}
            creditCard={customer.creditCard}
            onEdit={machine.onEdit}
          />
        </GridItem>
        <GridItem xs={12} lg={6} className={styles.gridItem}>
          <ShippingAndQuoteDisplay
            availableNextDates={customer.freshSubscription.availableNextDates}
            quote={customer.reactivationFreshQuote}
            addOns={customer.freshSubscription.addOns}
            submit={reactivateSubmit.submit}
            isSubmitting={reactivateSubmit.isSubmitting}
          />
        </GridItem>
      </Grid>
    </Page>
  );
}
