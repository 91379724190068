import classNames from 'classnames';

import type { CustomSelectorProps, ElementState } from '@farmersdog/corgi';
import { Selector, Text, withRef } from '@farmersdog/corgi';

import LineThroughDesignStyles from './LineThroughDesign.module.css';
import RadioDesignStyles from './RadioDesign.module.css';
import RadioSelectorStyles from './RadioSelector.module.css';

import type { ReactNode } from 'react';

export interface RichSelectorProps extends CustomSelectorProps {
  /** Content displayed directly beneath the input button */
  label: ReactNode;
  /** Image to display above the input button */
  icon?: ReactNode;
  /** Image to display above the input button on smaller screens **/
  iconSmall?: ReactNode;
  /** Extended content for the selector */
  description?: ReactNode;
  /** State of the input from useElementState  */
  state?: ElementState;
  /** Toggles design if selector is first in list of selectors */
  isFirst?: boolean;
  /** Toggles design if selector is last in list of selectors */
  isLast?: boolean;
}

/**
 * Selector component to display very detailed options.  Can display an image
 * and extended description.  Also set up to connect sibling options together
 * via the `lineThroughDesign`
 */
export const RadioSelector = withRef<HTMLInputElement, RichSelectorProps>(
  ({
    label,
    isFirst,
    isLast,
    icon,
    iconSmall,
    description,
    forwardedRef,
    state: stateProp,
    'aria-describedby': ariaDescribedBy,
    ...props
  }) => {
    return (
      <Selector
        type="radio"
        ref={forwardedRef}
        {...props}
        className={classNames(props.className, RadioDesignStyles.selector)}
      >
        {(state: ElementState): ReactNode => {
          const radioDesign = classNames(
            RadioDesignStyles.radio,
            RadioDesignStyles[stateProp ?? state],
            {
              [RadioDesignStyles.checked]: props.checked,
            }
          );

          return (
            <div
              className={classNames(
                RadioSelectorStyles.container,
                RadioSelectorStyles[state]
              )}
            >
              {icon && <div className={RadioSelectorStyles.icon}>{icon}</div>}
              {iconSmall && (
                <div className={RadioSelectorStyles.iconSmall}>{iconSmall}</div>
              )}
              <span
                className={classNames(LineThroughDesignStyles.line, {
                  [LineThroughDesignStyles.first]: isFirst,
                  [LineThroughDesignStyles.last]: isLast,
                })}
              >
                <span
                  className={classNames(
                    radioDesign,
                    RadioSelectorStyles.input,
                    {
                      [RadioSelectorStyles.largeInput]: !icon,
                    }
                  )}
                ></span>
              </span>
              <Text
                as="span"
                color="charcoal-3"
                id={ariaDescribedBy}
                variant="heading-16"
                className={classNames(
                  RadioSelectorStyles.label,
                  RadioDesignStyles.label
                )}
              >
                {label}
              </Text>
              {description}
            </div>
          );
        }}
      </Selector>
    );
  }
);
