import type { FC, ReactElement } from 'react';
import { useContext } from 'react';

import type { ScreenContext } from './createScreenContext';
import type { OrientationName } from 'src/screen/types';

interface RenderArgs {
  type: OrientationName;
}

interface OrientationProps {
  render: (args: RenderArgs) => ReactElement;
}

const createOrientation = (Context: ScreenContext) => {
  /**
   * Component that will render conditionally based on orientation.
   * Takes a render prop as a function of the orientation object
   */
  const Orientation: FC<OrientationProps> = ({ render }) => {
    const { orientation } = useContext(Context);
    return render({ type: orientation });
  };

  return Orientation;
};

export default createOrientation;
