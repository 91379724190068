import { SplitFactory } from '@splitsoftware/splitio';
import type { IClient } from '@splitsoftware/splitio/types/splitio';
import { serverSplitConfig } from './splitConfig';
import { Logger } from '@farmersdog/logger';

const logger = new Logger('app:server:splitFactory');

let client: IClient | undefined;

export async function getClient(): Promise<IClient> {
  return client || (await startClient());
}

function setClient(newClient: IClient): void {
  client = newClient;
}

export async function startClient(): Promise<IClient> {
  const factory = SplitFactory(serverSplitConfig);

  const newClient = factory.client();

  try {
    await newClient.ready().catch(e => {
      logger.error('Split SDK failed to connect', { error: e });
      throw e;
    });
    logger.info('Split SDK is ready');
  } catch (error) {
    logger.error('Split SDK timed out while connecting', { error });
  }

  setClient(newClient);

  return newClient;
}
