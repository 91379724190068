import type { MouseEvent } from 'react';

import { GladlyChatIcon } from './GladlyChatIcon';
import styles from './GladlyButton.module.scss';

import {
  GLADLY_CHAT_BUTTON_ID,
  GLADLY_UNREAD_MESSAGE_ID,
} from '@farmersdog/paw/src/cx/';

import classNames from 'classnames';

type IntrinsicButtonProps = JSX.IntrinsicElements['button'];

interface GladlyButtonProps extends IntrinsicButtonProps {
  isVisible?: boolean;
}

/**
 * Custom Sidekick button for Gladly live chat.
 *
 * The GladlySidekickScript scans for a unique ID to determine which
 * button to display - either ours or theirs. In case the feature flag
 * is off, we still want to display our button, but with hidden styling.
 * If we prohibit the button from appearing when the feature flag is off, the Gladly script
 * will still display its default button.
 *
 */
export function GladlyButton({
  isVisible,
  onClick,
  ...restProps
}: GladlyButtonProps) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick?.(e);
  };

  return (
    <button
      type="button"
      id={GLADLY_CHAT_BUTTON_ID}
      className={classNames(styles.root, {
        [styles.isVisible]: isVisible,
      })}
      onClick={handleClick}
      aria-label="Open live chat"
      {...restProps}
    >
      <div className={styles['icon-container']}>
        <GladlyChatIcon />
        <span id={GLADLY_UNREAD_MESSAGE_ID} className={styles.unread}></span>
      </div>
    </button>
  );
}
