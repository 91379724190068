import type { ReactNode } from 'react';
import type { WebsiteAuth } from '../App.types';
import { AuthProvider } from './Auth';

interface StateProvidersProps {
  children: ReactNode;
  websiteAuth?: WebsiteAuth;
}

export function StateProviders({ children, websiteAuth }: StateProvidersProps) {
  return <AuthProvider websiteAuth={websiteAuth}>{children}</AuthProvider>;
}
