import { ValidUtmParam } from '../types';

import type { RequestAttributes } from '../types';

interface ReadRequestAttributes {
  queryString: string;
  referrer_url?: string;
  logger: (msg: string, context?: Record<string, unknown>) => void;
}

export function readRequestAttributes({
  queryString,
  referrer_url,
  logger,
}: ReadRequestAttributes) {
  const searchParams = new URLSearchParams(queryString);

  for (const [key, value] of searchParams) {
    if (key.startsWith('utm_') && !isValidUtmParam(key)) {
      logger(`Request contained a malformed utm parameter`, {
        [key]: value,
      });
    }
  }

  const requestAttributes = Object.values(ValidUtmParam).reduce(
    (currentRequestAttributes, param) => {
      return searchParams.get(param)
        ? { ...currentRequestAttributes, [param]: searchParams.get(param) }
        : currentRequestAttributes;
    },
    {} as RequestAttributes
  );

  if (referrer_url) {
    requestAttributes.referrer_url = referrer_url;
  }

  return requestAttributes;
}

function isValidUtmParam(param: unknown): param is ValidUtmParam {
  return (
    typeof param === 'string' &&
    Object.values(ValidUtmParam).includes(param as ValidUtmParam)
  );
}
