import { Grid, Text } from '@farmersdog/corgi-x';
import styles from './InformationIcon.module.scss';
import type { FunctionComponent, SVGProps } from 'react';

interface InformationIconProps {
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  label: string;
  isMobile?: boolean;
}

export const InformationIcon = ({
  icon: Icon,
  label,
  isMobile = false,
}: InformationIconProps) => {
  return (
    <Grid
      className={styles.container}
      flexDirection="column"
      alignItems="center"
      bottomSpacing={isMobile ? 'md' : 'none'}
    >
      <Icon />
      <Text
        variant={isMobile ? 'heading-16' : 'heading-22'}
        bold
        topSpacing="md"
        className={styles.label}
      >
        {label}
      </Text>
    </Grid>
  );
};
