import type { ReactNode } from 'react';
import Details from 'src/components/Details';

interface StructuredFAQType {
  question: string;
  children: ReactNode;
  isOpen?: boolean;
}

/**
 * FAQ component with a html structure and attributes that conform to Google structured data
 * (see https://developers.google.com/search/docs/advanced/structured-data/faqpage)
 */
export function StructuredFAQ({
  question,
  children,
  isOpen = false,
}: StructuredFAQType) {
  return (
    <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <Details summary={question} itemProp="name" open={isOpen}>
        <div
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">{children}</div>
        </div>
      </Details>
    </div>
  );
}
