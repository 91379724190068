import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { tosaToken } from '@farmersdog/lead-browser-storage';

import createLead from '../../../graphql/mutations/createLead.graphql';
import { tokenUtils } from '../../../utils/tokenUtils';
import { errorCodes } from '../utils/errorCodes';
import { setOperationToken } from '../utils/setOperationToken';

import { createHttpMiddleware } from './createHttpMiddleware';

import type { CreateLeadMutation } from '../../../graphql/types';
import type { FetchResult } from '@apollo/client';
import type { GraphQLFormattedError } from 'graphql';

export function createErrorMiddleware(): ApolloLink {
  const retryHttpMiddleware = createHttpMiddleware();

  return onError(({ graphQLErrors, operation, forward }) => {
    if (!graphQLErrors) {
      return;
    }

    const invalidPetNameError = graphQLErrors.find(hasInvalidPetName);

    if (invalidPetNameError) {
      return forward(operation);
    }

    const unauthenticatedError = graphQLErrors.find(isUnauthenticatedError);

    if (!unauthenticatedError) {
      return;
    }

    const expiredToken = tosaToken.get();

    if (!expiredToken) {
      return;
    }

    // Attempt to refresh the token
    const payload = tokenUtils.decodeToken(expiredToken);
    const observable = ApolloLink.execute(retryHttpMiddleware, {
      query: createLead,
      variables: {
        email: payload.email,
      },
    });

    // @ts-expect-error We are executing the mutation above
    return observable.flatMap((result: FetchResult<CreateLeadMutation>) => {
      const refreshedToken = result?.data?.createLead.token;

      if (!refreshedToken) {
        return forward(operation);
      }

      tosaToken.set(refreshedToken);
      setOperationToken({
        operation,
        token: refreshedToken,
      });

      // Retry the failed operation with the new token
      return forward(operation);
    });
  });
}

function isUnauthenticatedError(error: GraphQLFormattedError) {
  return error.extensions?.code === errorCodes.UNAUTHENTICATED;
}

function hasInvalidPetName(error: GraphQLFormattedError) {
  return error.message === 'Invalid pet name';
}
