import type { HomepageEssentialsDocument } from '../../network';
import tryRecipes from './assets/tryRecipes.png';
import freshFood from './assets/freshFood.png';

export const fallbackContent: HomepageEssentialsDocument = {
  journey_status: 'ReceivedFirstBox',
  section_heading: [
    {
      type: 'paragraph',
      text: 'Getting Ready to Start Fresh',
      spans: [],
    },
  ],
  section_subheading: [
    {
      type: 'paragraph',
      text: '',
      spans: [],
    },
  ],
  guides: [
    {
      guide_heading: [
        {
          type: 'paragraph',
          text: 'The real value of fresh food',
          spans: [],
        },
      ],
      guide_subheading: [
        {
          type: 'paragraph',
          text: 'Dog food costs can vary wildly, despite products sounding similar on paper. Here’s how our food compares to kibble, and why those cheaper options may not be the best value for you and your dog in the long run.',
          spans: [],
        },
      ],
      guide_cta_text: [
        {
          type: 'paragraph',
          text: 'The Farmer’s Dog Cost—and Value',
          spans: [],
        },
      ],
      guide_image: {
        copyright: '',
        url: freshFood,
        dimensions: {
          width: 336,
          height: 198,
        },
        alt: 'Try Recipes',
      },
      guide_cta_link: {
        link_type: 'Web',
        url: 'https://thefarmersdog.com/digest/the-farmers-dog-cost-and-value/',
      },
    },
    {
      guide_heading: [
        {
          type: 'paragraph',
          text: 'Try a few recipes to find a favorite!',
          spans: [],
        },
      ],
      guide_subheading: [
        {
          type: 'paragraph',
          text: 'You know your dog best – if you think they’d enjoy a different recipe, you can select other ones for your next box within your account.',
          spans: [],
        },
      ],
      guide_cta_text: [
        {
          type: 'paragraph',
          text: 'Update My Recipes',
          spans: [],
        },
      ],
      guide_image: {
        copyright: '',
        url: tryRecipes,
        dimensions: {
          width: 336,
          height: 198,
        },
        alt: 'Try Recipes',
      },
      guide_cta_link: {
        link_type: 'Web',
        url: 'https://www.thefarmersdog.com/app/meals',
      },
    },
  ],
};
