import { events, track } from 'src/analytics';
import type { StockStatus } from 'src/graphql/types';
import type { CalibratePackPetDetails } from 'src/pages/Calibrate';

function getCalibrationSuccessPageInput(
  props: TrackCalibrationSuccessPageArgs
) {
  const {
    didPacksChange,
    didPortionsChange,
    didReschedule,
    didPlansChange,
    calibratePackPetDetails,
    stockStatus,
    canRescheduleNextOrder,
  } = props;
  const properties: CalibrationSuccessPagePayload = {
    didPacksChange,
    attemptedPortionChange: didPortionsChange,
    didReschedule,
    didPlansChange,
    calibratePackPetDetails,
    stockStatus: stockStatus ?? '',
    isNextOrderLocked: !canRescheduleNextOrder,
  };
  return properties;
}

export function trackCalibrationSuccessPage(
  props: TrackCalibrationSuccessPageArgs
) {
  track<CalibrationSuccessPagePayload>(
    events.calibration_success_page,
    getCalibrationSuccessPageInput(props)
  );
}

/** Properties of the "Calibration Success Page" event. */
export interface CalibrationSuccessPagePayload extends Record<string, unknown> {
  didPacksChange: boolean;
  attemptedPortionChange: boolean;
  didReschedule: boolean;
  didPlansChange: boolean;
  calibratePackPetDetails: CalibratePackPetDetails[];
  stockStatus: StockStatus | string;
  isNextOrderLocked: boolean;
}

interface TrackCalibrationSuccessPageArgs {
  didPacksChange: boolean;
  didPortionsChange: boolean;
  didReschedule: boolean;
  didPlansChange: boolean;
  calibratePackPetDetails: CalibratePackPetDetails[];
  stockStatus: StockStatus | null;
  // TODO: remove "null" below after https://app.clubhouse.io/farmersdog/story/62669
  canRescheduleNextOrder: boolean | null;
}
