import { useQuery } from '@apollo/client';

import { useThrowToErrorBoundary } from 'src/hooks/useThrowToErrorBoundary';
import { determineSubscription } from 'src/utils/determine-subscription';

import type { Maybe } from '@/account/graphql/core/types';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '@/account/graphql/core/types';

import subscriptionStatusQuery from './SubscriptionStatus.graphql';

import type { SubscriptionStatusQuery } from './SubscriptionStatus.generated-types';
import type { QueryResult } from '@apollo/client';

export interface UseSubscriptionStatus
  extends Omit<QueryResult<SubscriptionStatusQuery>, 'data'> {
  status: SubscriptionStatus | undefined;
  bothSuspended: boolean;
  subscriptionType: Maybe<SubscriptionType> | undefined;
}

export function useSubscriptionStatus(): UseSubscriptionStatus {
  const throwToErrorBoundary = useThrowToErrorBoundary();
  const { data, ...resultState } = useQuery<SubscriptionStatusQuery>(
    subscriptionStatusQuery,
    {
      onError: e => {
        throwToErrorBoundary(e);
      },
    }
  );

  let subscriptionType;
  let status: SubscriptionStatus | undefined;
  if (data?.me.subscriptions) {
    const subscription = determineSubscription(data?.me?.subscriptions);
    subscriptionType = subscription?.type;
    status = subscription?.status;
  }

  const freshSubscriptionStatus = data?.me?.subscriptions.find(
    sub => sub.type === SubscriptionType.Fresh
  )?.status;

  const diySubscriptionStatus = data?.me?.subscriptions.find(
    sub => sub.type === SubscriptionType.Diy
  )?.status;

  const bothSuspended =
    freshSubscriptionStatus === SubscriptionStatus.Suspended &&
    diySubscriptionStatus === SubscriptionStatus.Suspended;

  return {
    subscriptionType,
    status,
    bothSuspended,
    ...resultState,
  };
}
