import Cookie from 'js-cookie';
import {
  COUPON_COOKIE_V1_NAME,
  COUPON_COOKIE_VERSION,
  COUPON_COOKIE_EXPIRE_DAYS,
  MAX_COOKIE_EXPIRATION,
} from '../constants';

import type { ReferrerType } from '../types';
import { isPhoneSalesCoupon } from './isPhoneSalesCoupon';

export interface ValidCouponCookieV1 {
  referrerCode: string;
  referrerType: ReferrerType;
  referrerAssociatedAt: Date;
  version?: typeof COUPON_COOKIE_VERSION;
}

export interface InvalidCouponCookieV1 {
  referrerCode: undefined;
  referrerType: undefined;
  referrerAssociatedAt: undefined;
  version?: typeof COUPON_COOKIE_VERSION;
}

export type CouponCookieV1 = ValidCouponCookieV1 | InvalidCouponCookieV1;

export const invalidCouponCookie: InvalidCouponCookieV1 = {
  referrerCode: undefined,
  referrerType: undefined,
  referrerAssociatedAt: undefined,
  version: COUPON_COOKIE_VERSION,
};

export function get(): CouponCookieV1 {
  const cookieValue = Cookie.get(COUPON_COOKIE_V1_NAME);

  if (!cookieValue) {
    return invalidCouponCookie;
  }

  try {
    const couponCookie: unknown = JSON.parse(cookieValue);

    if (!isValidCouponCookie(couponCookie)) {
      return invalidCouponCookie;
    }

    return couponCookie;
  } catch {
    return invalidCouponCookie;
  }
}

function isValidCouponCookie(value: unknown): value is CouponCookieV1 {
  const requiredCouponCookieKeys: (keyof CouponCookieV1)[] = [
    'referrerAssociatedAt',
    'referrerType',
    'referrerCode',
  ];

  return Boolean(
    value &&
      requiredCouponCookieKeys.every(key =>
        Object.prototype.hasOwnProperty.call(value, key)
      )
  );
}

export function set(couponCookie: CouponCookieV1) {
  const cookieValue = JSON.stringify(couponCookie);

  const expires = isPhoneSalesCoupon(couponCookie?.referrerCode)
    ? MAX_COOKIE_EXPIRATION
    : COUPON_COOKIE_EXPIRE_DAYS;

  Cookie.set(COUPON_COOKIE_V1_NAME, cookieValue, {
    expires,
  });
}

export function remove(): void {
  Cookie.remove(COUPON_COOKIE_V1_NAME);
}

export const couponCookie = {
  get,
  set,
  remove,
};
