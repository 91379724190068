import { JourneyStatus } from './../../graphql/types.cgs';
import { formatDateRange, formatDateToMonthAndDay } from '../../utils';
import type { ChangeFreshOrderSizeMutation } from '../network/changeFreshOrderSize/changeFreshOrderSize.cgs.generated-types';

interface ToastMessageProps {
  response: ChangeFreshOrderSizeMutation;
  originalNextDate?: string | null;
  journeyStatus?: JourneyStatus;
}

/**
 *
 * Return the appropriate toast message based on Change Frequency mutation response.
 */
export const getToastMessage = (props: ToastMessageProps): string => {
  const { response, originalNextDate, journeyStatus } = props;

  const hasAddOns =
    response.changeFreshOrderSize.customer.freshSubscription.addOns.length > 0;

  let baseMessage: string;
  let dateChangeMessage: string;
  let treatsReminder: string;

  if (journeyStatus === JourneyStatus.ReceivedSecondBox) {
    baseMessage = 'Your order size has been updated. ';
    dateChangeMessage = '';

    const {
      freshSubscription: { nextDate },
      nextOrderToBeDelivered,
    } = response.changeFreshOrderSize.customer;

    const estimatedDeliveryDate =
      nextOrderToBeDelivered.deliveryWindow.estimatedDeliveryDate;
    const earliestDesiredArrivalDate =
      nextOrderToBeDelivered.deliveryWindow.earliestDesiredArrivalDate;
    const latestDesiredArrivalDate =
      nextOrderToBeDelivered.deliveryWindow.latestDesiredArrivalDate;

    if (originalNextDate !== nextDate) {
      const formattedDateRange = estimatedDeliveryDate
        ? formatDateToMonthAndDay(estimatedDeliveryDate)
        : formatDateRange(
            earliestDesiredArrivalDate,
            latestDesiredArrivalDate,
            { abbreviatedMonth: true }
          );
      dateChangeMessage = `Your next order will arrive ${formattedDateRange}. `;
    }

    treatsReminder = hasAddOns
      ? 'Don’t forget to update your Treats quantity!'
      : '';
  } else {
    baseMessage = 'Size updated. ';
    dateChangeMessage = '';
    treatsReminder = hasAddOns
      ? 'Don’t forget to update your Treats order quantity!'
      : '';
  }

  return `${baseMessage}${dateChangeMessage}${treatsReminder}`;
};
