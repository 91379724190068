import type { ReactNode, MouseEventHandler } from 'react';
import { Text, Link } from '@farmersdog/corgi-x';

import styles from './ActionHeader.module.css';

interface ActionHeader {
  actionText: ReactNode;
  headerText: ReactNode;
  onActionClick?: MouseEventHandler<HTMLAnchorElement>;
  actionTo: string;
  actionAriaLabel: string;
}

export function ActionHeader({
  actionText,
  headerText,
  onActionClick,
  actionTo,
  actionAriaLabel,
}: ActionHeader): JSX.Element {
  return (
    <div className={styles.header}>
      <Text variant="heading-22" color="kale-3" as="h4" bold vSpacing="xxs">
        {headerText}
      </Text>
      <Link aria-label={actionAriaLabel} to={actionTo} onClick={onActionClick}>
        <Text variant="heading-16" bold as="span">
          {actionText}
        </Text>
      </Link>
    </div>
  );
}
