import { useEffect } from 'react';

import { usePetRecipes } from '../../../graphql/queries/usePetRecipes';
import { useThrowToErrorBoundary } from '../../../hooks/useThrowToErrorBoundary';

import { usePetNamesAndRequestedCalories } from './usePetNamesAndRequestedCalories';

import type { FreshRecipeRecommendation } from '../../../graphql/types';
import type { PetRecipes } from '../../../types';
import type { Dispatch, SetStateAction } from 'react';

interface UseFetchPetsRecommendedRecipesArgs {
  currentPetName: string;
  petRecipes: PetRecipes;
  setPetRecipes: Dispatch<SetStateAction<PetRecipes>>;
}

export function useFetchPetsRecommendedRecipes({
  currentPetName,
  petRecipes,
  setPetRecipes,
}: UseFetchPetsRecommendedRecipesArgs) {
  const { refetch: petRecipesQuery, loading: petRecipesLoading } =
    usePetRecipes({
      errorPolicy: 'all',
      skip: true,
      fetchPolicy: 'network-only',
    });
  const throwToErrorBoundary = useThrowToErrorBoundary();
  const { petNamesAndRequestedCalories } = usePetNamesAndRequestedCalories();

  const currentPetRecipes = petRecipes[currentPetName] as
    | FreshRecipeRecommendation[]
    | undefined;

  const recommendedRecipes =
    currentPetRecipes?.filter(recipe => recipe.recommended) ?? [];

  const recommendedRecipesNames = recommendedRecipes.map(recipe => recipe.name);

  const allPetsCaloriesSum = (petNamesAndRequestedCalories ?? []).reduce(
    (acc, { requestedCalories }) => acc + requestedCalories,
    0
  );

  useEffect(() => {
    if (!petNamesAndRequestedCalories) return;

    petNamesAndRequestedCalories.forEach(({ petName }) => {
      if (!currentPetRecipes) {
        petRecipesQuery({ petName: petName.trim(), allPetsCaloriesSum })
          .then(response => {
            if (response.errors) {
              return throwToErrorBoundary(response.errors);
            }
            if (response.data) {
              setPetRecipes(prev => {
                return {
                  ...prev,
                  [petName]: response.data?.petRecipes,
                };
              });
            }
          })
          .catch(error => {
            return throwToErrorBoundary(error);
          });
      }
    });
    // Should include petsNames as dependencies if pet names could change when this component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPetsCaloriesSum]);

  return {
    petRecipesQueryState: { loading: petRecipesLoading },
    currentPetRecipes,
    recommendedRecipes,
    recommendedRecipesNames,
  };
}
