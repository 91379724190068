import { Input, Text } from '@farmersdog/corgi-x';
import { AddDogContent } from '../AddDogContent';

import type { AddDogFormChangeHandler } from '../../hooks';
import { fiveHappyDogs } from '../../../../../assets/add-dog';
import { PendingPetsDisclaimer } from './components';

interface NameSceneProps {
  name: string;
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
  /** Whether the user already has a pending pet. */
  hasPendingPet: boolean | null | undefined;
}

export const NameScene = ({
  onCtaClick,
  name,
  onChange,
  hasPendingPet,
}: NameSceneProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    const capitalizedName = newName.charAt(0).toUpperCase() + newName.slice(1);
    onChange('name', capitalizedName);
  };

  const heading = (
    <Text as="h1" variant="heading-28" bold color="kale-3">
      Tell Us About Your{' '}
      <Text
        as="span"
        variant="heading-28"
        bold
        color="kale-3"
        style={{ whiteSpace: 'nowrap' }}
      >
        New Dog
      </Text>
    </Text>
  );
  return (
    <AddDogContent
      image={{
        src: fiveHappyDogs,
        alt: 'Five happy dogs playing together',
      }}
      heading={heading}
      sectionAriaLabel="Tell Us About Your New Dog"
      subheading="Enter a few details and we'll reach out to confirm your new daily price before adding them to your plan."
      onCtaClick={onCtaClick}
      ctaDisabled={name === ''}
      ctaButtonVariant={hasPendingPet ? 'plain-text' : 'default'}
    >
      <Input
        type="text"
        label="Name"
        aria-label="Name"
        placeholder="What's your dog's name?"
        value={name}
        onChange={handleChange}
        max={250}
      />

      {hasPendingPet && <PendingPetsDisclaimer />}
    </AddDogContent>
  );
};
