import classNames from 'classnames';

import { Button } from '@farmersdog/corgi-x';

import styles from './Cta.module.css';

type CtaProps = {
  onAddToPlan: () => void;
  onSkip: () => void;
  currentlyLoadingButton?: 'primary' | 'secondary';
  areAnyAddedTreats: boolean;
};

export const Cta = ({
  onAddToPlan,
  onSkip,
  currentlyLoadingButton,
  areAnyAddedTreats,
}: CtaProps) => {
  return (
    <div className={styles.container}>
      <Button
        variant="secondary"
        className={classNames(styles.button, styles.secondary)}
        onClick={onSkip}
        type="submit"
        loading={currentlyLoadingButton === 'secondary'}
      >
        {areAnyAddedTreats ? 'Remove Treats' : 'Skip'}
      </Button>
      <Button
        className={styles.button}
        onClick={onAddToPlan}
        type="submit"
        loading={currentlyLoadingButton === 'primary'}
      >
        {areAnyAddedTreats ? 'Continue' : 'Add to Plan'}
      </Button>
    </div>
  );
};
