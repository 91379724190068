import type {
  ActivityLevel as ActivityLevelType,
  BodyCondition as BodyConditionType,
  Breed as BreedType,
  EatingStyle as EatingStyleType,
  FoodType as FoodTypeType,
  HealthIssue as HealthIssueType,
  PersonalityType as PersonalityTypeType,
  PrescriptionDiet as PrescriptionDietType,
  TreatUsageBucket as TreatUsageBucketType,
  Recipe as RecipeType,
} from '../../types';

export enum StaticDataTypeNames {
  ActivityLevel = 'ActivityLevel',
  BodyCondition = 'BodyCondition',
  Breed = 'Breed',
  EatingStyle = 'EatingStyle',
  FoodType = 'FoodType',
  HealthIssue = 'HealthIssue',
  PersonalityType = 'PersonalityType',
  PrescriptionDiet = 'PrescriptionDiet',
  TreatUsageBucket = 'TreatUsageBucket',
  FoodBrand = 'FoodBrand',
  Recipe = 'Recipe',
}

export type StaticDataEntities =
  | ActivityLevelType
  | BodyConditionType
  | BreedType
  | EatingStyleType
  | FoodTypeType
  | HealthIssueType
  | PersonalityTypeType
  | PrescriptionDietType
  | TreatUsageBucketType
  | RecipeType;

export type GraphQLType<
  O extends StaticDataEntities,
  T extends StaticDataTypeNames,
> = O & {
  __typename: T;
};

const staticDataTypenameValue = new Set(Object.values(StaticDataTypeNames));
export function isStaticDataTypeName(
  value: unknown
): value is StaticDataTypeNames {
  return staticDataTypenameValue.has(value as StaticDataTypeNames);
}
