import type { ReactNode } from 'react';
import { AuthContext, initialAuthContext } from './Provider';
import type { UseAuth } from './useAuth';

interface MockedAuthProviderProps {
  authMocks?: Partial<UseAuth>;
  children: ReactNode;
}

export function MockedAuthProvider({
  authMocks,
  children,
}: MockedAuthProviderProps): JSX.Element {
  return (
    <AuthContext.Provider value={{ ...initialAuthContext, ...authMocks }}>
      {children}
    </AuthContext.Provider>
  );
}
