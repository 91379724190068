import { GridContainer } from '@farmersdog/corgi-x';

import styles from './TosaLayout.module.css';

import type { ReactNode } from 'react';

export interface TosaLayoutProps {
  children: ReactNode;
}

export function TosaLayout({ children }: TosaLayoutProps) {
  return (
    <main>
      <GridContainer>
        <div className={styles.section}>{children}</div>
      </GridContainer>
    </main>
  );
}
