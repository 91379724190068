import { AddDogContent } from '../AddDogContent';
import {
  Text,
  Grid,
  GridItem,
  RadioButton,
  AnimateHeight,
  StatusMessage,
  Section,
  visuallyHidden,
  useIntersection,
} from '@farmersdog/corgi-x';
import styles from '../HealthIssueScene/HealthIssuesScene.module.css';
import type { AddDogFormChangeHandler, UseAddDogFormFields } from '../../hooks';
import {
  CTA_BUTTON,
  CTA_BUTTON_WITH_WARNING,
  PET_DIET_ISSUE_WARNING_MESSAGE,
  PetDietIssue,
} from './constants';
import { PetHealthCheckbox } from '../HealthIssueScene/components/PetHealthCheckbox';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';

export interface PetDietIssueSceneProps {
  name: string;
  value?: UseAddDogFormFields['petDietIssues'];
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

function containsIssueWithWarning(issues: PetDietIssue[]): boolean {
  return issues.some(issue => issue === PetDietIssue.LowFat);
}

export const getPetDietDefaultMessage = (dietName: string) => {
  const lowerCaseDietName = dietName.toLowerCase();
  return `While we do not offer official prescriptions, many veterinarians use our recipes to replace a ${lowerCaseDietName} food. If a prescription is absolutely required, please check with your veterinarian.`;
};

export const PetDietIssueScene = (props: PetDietIssueSceneProps) => {
  const { onCtaClick, onChange, name, value } = props;

  const [hasPetDietIssues, setHasPetDietIssues] = useState<boolean | null>();

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string>();

  const checkBoxContainerRef = useRef<HTMLDivElement>(null);
  const statusMessageRef = useRef<HTMLDivElement>(null);

  const isStatusMessageVisible = useIntersection(statusMessageRef?.current, {
    threshold: 1,
  })?.isIntersecting;

  const [allowScrollIntoView, setAllowScrollIntoView] = useState(false);

  const petDietIssues: string[] = Object.values(PetDietIssue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hasIssue = e.target.value === 'yes';

    if (!hasIssue) {
      setShowWarningMessage(false);
    }
    onChange('petDietIssues', []);
    setHasPetDietIssues(hasIssue);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const checkboxValue = e.target.value as PetDietIssue;

    const currentPetDietIssueNames: PetDietIssue[] = value ?? [];

    const newPetDietIssueNames: PetDietIssue[] = isChecked
      ? [...currentPetDietIssueNames, checkboxValue]
      : currentPetDietIssueNames.filter(issue => issue !== checkboxValue);

    // We always show a warning message if the user has selected an issue.
    if (newPetDietIssueNames.length > 0) {
      setShowWarningMessage(true);
    }
    // All issues have an associated warning message and we should always scroll to the status message.
    if (isChecked && !isStatusMessageVisible) {
      setAllowScrollIntoView(true);
    }

    // If the user unchecks all issues, hide the warning message.
    if (!isChecked && newPetDietIssueNames.length === 0) {
      setShowWarningMessage(false);
    }

    const hasWarning = containsIssueWithWarning(newPetDietIssueNames);

    // If the user has selected an issue with warning, we show the warning copy.
    // Otherwise, we show the default copy, with reference to the last selected issue.
    if (hasWarning) {
      setWarningMessage(PET_DIET_ISSUE_WARNING_MESSAGE);
      // only run this next block if there are issues selected
    } else if (newPetDietIssueNames.length > 0) {
      const lastSelectedIssue =
        newPetDietIssueNames[newPetDietIssueNames.length - 1];
      setWarningMessage(getPetDietDefaultMessage(lastSelectedIssue));
    }

    onChange('petDietIssues', newPetDietIssueNames);
  };

  const ctaDisabled =
    hasPetDietIssues === undefined ||
    (hasPetDietIssues === true && value && value?.length === 0);

  // The radio buttons and warning message state are managed internally.
  // We need to reflect the correct state based on the user's current value
  // when the component mounts.
  useEffect(() => {
    if (value && value.length > 0) {
      // if the user has selected a pet diet issue, we show the warning message.
      setHasPetDietIssues(true);
      setShowWarningMessage(true);

      const hasWarning = containsIssueWithWarning(value);

      // If the user has selected a pet diet issue with a warning, we show the warning message.
      // Otherwise, we show the default message, with reference to the last selected issue.
      if (hasWarning) {
        setWarningMessage(PET_DIET_ISSUE_WARNING_MESSAGE);
      } else {
        setWarningMessage(getPetDietDefaultMessage(value[value.length - 1]));
      }
    } else if (hasPetDietIssues === undefined && value && value.length === 0) {
      setHasPetDietIssues(false);
    }

    if (showWarningMessage && !isStatusMessageVisible && allowScrollIntoView) {
      setTimeout(() => {
        statusMessageRef.current?.scrollIntoView();
      }, 150);
      // After scrolling, set to false so it doesn't scroll again.
      setAllowScrollIntoView(false);
    }
  }, [
    hasPetDietIssues,
    isStatusMessageVisible,
    value,
    showWarningMessage,
    allowScrollIntoView,
  ]);
  return (
    <AddDogContent
      heading={`Does ${name} require a therapeutic diet?`}
      onCtaClick={onCtaClick}
      ctaDisabled={ctaDisabled}
      sectionWidthConstraint="lg"
      cta={showWarningMessage ? CTA_BUTTON_WITH_WARNING : CTA_BUTTON}
    >
      <fieldset className={styles.radiosContainer}>
        <legend
          className={visuallyHidden}
        >{`Does ${name} require a therapeutic diet?`}</legend>
        <RadioButton
          className={styles.radio}
          name="yes"
          label="Yes"
          value="yes"
          onChange={e => handleChange(e)}
          checked={hasPetDietIssues === true}
        />
        <RadioButton
          className={styles.radio}
          name="no"
          label="No"
          value="no"
          onChange={e => handleChange(e)}
          checked={hasPetDietIssues === false}
        />
      </fieldset>

      <AnimateHeight
        nodeRef={checkBoxContainerRef}
        animateIn={hasPetDietIssues === true}
      >
        <div ref={checkBoxContainerRef}>
          <Grid rowGap="md">
            <GridItem>
              <Text
                as="span"
                variant="article-16"
                fontStyle="italic"
                aria-hidden
                color="charcoal-2"
              >
                Tap All That Apply
              </Text>
            </GridItem>
            <GridItem>
              {petDietIssues?.map((petDietIssue, i) => {
                const isChecked = value?.includes(petDietIssue as PetDietIssue);

                return (
                  <PetHealthCheckbox
                    key={`pet-diet-issue-${i}`}
                    id={`pet-diet-issue-${i}`}
                    name={petDietIssue}
                    value={petDietIssue}
                    onChange={handleCheckboxChange}
                    checked={isChecked}
                    hasWarning={petDietIssue === PetDietIssue.LowFat}
                  >
                    {petDietIssue}
                  </PetHealthCheckbox>
                );
              })}
            </GridItem>
          </Grid>
        </div>
      </AnimateHeight>
      <AnimateHeight nodeRef={statusMessageRef} animateIn={showWarningMessage}>
        <div ref={statusMessageRef}>
          <Section as="div" widthConstraint="sm" topSpacing="md">
            <StatusMessage politeness="polite">
              <Text variant="article-16" color="error-red" fontStyle="italic">
                {warningMessage}
              </Text>
            </StatusMessage>
          </Section>
        </div>
      </AnimateHeight>
    </AddDogContent>
  );
};
