import type { QueryHookOptions } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

import query from './fetchQuote.graphql';
import type {
  FetchQuoteQuery,
  FetchQuoteQueryVariables,
} from 'src/graphql/tosa.types';
import { GraphQLEndpoints } from 'src/graphql/Apollo/constants';

type Options = QueryHookOptions<FetchQuoteQuery, FetchQuoteQueryVariables>;

export function useFetchQuote(options?: Options) {
  return useLazyQuery<FetchQuoteQuery, FetchQuoteQueryVariables>(query, {
    ...options,
    context: {
      endpoint: GraphQLEndpoints.TOSA,
    },
  });
}
