import type { HeadingColors } from '@farmersdog/corgi';
import type { RecipeNames } from '@farmersdog/constants';

// Treatments
export type ToggleTreatment = 'on' | 'off' | 'control';
export type RecipesBelowTheFoldTreatment =
  | 'control'
  | 'content'
  | 'reviews'
  | 'none';
export type FrequenciesAtCheckoutTreatment = 'trans_box' | '28_cap' | 'control';
export type PhoneOnMePageTreatment =
  | 'off'
  | 'required_phone'
  | 'optional_phone'
  | 'optional_dropdown_opt_in';
export type RecipeNamesTreatment = 'off' | 'vegetables' | 'ingredients';

export enum MixingPlanTreatments {
  off = 'off',
  control = 'control',
  treatmentControl = 'treatmentControl',
  treatmentA = 'treatmentA',
  treatmentB = 'treatmentB',
  treatmentC = 'treatmentC',
}

export enum ToggleTreatments {
  off = 'off',
  on = 'on',
  control = 'control',
}

export enum PostCheckoutReferralTreatments {
  off = 'off',
  control = 'control',
  treatmentA = 'treatmentA',
  treatmentB = 'treatmentB',
}

export enum PostCheckoutReferralPercentageTreatments {
  off = 'off',
  '20_percent_off' = '20_percent_off',
  '60_percent_off' = '60_percent_off',
  '100_percent_off' = '100_percent_off',
}

export enum PriceValueModalTreatments {
  off = 'off',
  'kibble_bash' = 'kibble_bash',
  'pro_fresh' = 'pro_fresh',
}

export enum DynamicCalculatingScreenTreatments {
  off = 'off',
  'vet_static' = 'vet_static',
  'kibble_or_fresh' = 'kibble_or_fresh',
  'fresh_benefit_weight_static' = 'fresh_benefit_weight_static',
  'fresh_benefit_weight_animation' = 'fresh_benefit_weight_animation',
  'fresh_benefit_healthier_poop_static' = 'fresh_benefit_healthier_poop_static',
  'fresh_benefit_healthier_poop_animation' = 'fresh_benefit_healthier_poop_animation',
}

export enum RecipePagePackOrderEnum {
  off = 'off',
  chicken_first = 'chicken_first',
}

export enum DefaultDiscountTreatments {
  off = 'off',
  control = 'control',
  discount20 = 'discount20',
  discount50 = 'discount50',
}

export type CheckoutSuccessExperimentTreatments =
  | 'confidence'
  | 'plan_info'
  | 'simplified'
  | 'account_cta'
  | 'off';

export enum CvrSmsOptInTreatments {
  off = 'off',
  transition = 'transition',
  perfect_plan = 'perfect_plan',
}

export enum CvrWeightlossPersonalizationTreatments {
  off = 'off',
  generic = 'generic',
  weight_loss = 'weight_loss',
}

export enum CvrZipEmailCopyTreatments {
  off = 'off',
  show_context = 'show_context',
}

export enum CvrSurpriseHigherDiscountTreatments {
  off = 'off',
  recipes = 'recipes',
  checkout = 'checkout',
}

export enum CvrPriorFoodTypeProgressionTreatments {
  off = 'off',
  on = 'on',
}

export enum HomepageHeroImageTreatments {
  Control = 'control',
  Off = 'off',
  LovedByHumans = 'loved_by_humans',
  LifestyleBowl = 'lifestyle_bowl',
  MeatAndVeggies = 'meat_and_veggies',
  BlackFriday = 'black_friday',
  CyberWeek = 'cyber_week',
  CtaOnly50Off = 'cta_only_50_off',
  CtaOnlySeePlansAndPricing = 'cta_only_see_plans_and_pricing',
}

export enum SkipGetStartedTreatments {
  off = 'off',
  same_copy = 'same_copy',
  new_copy = 'new_copy',
  no_copy = 'no_copy',
}

export enum OnbHomepagePersonalizationPickyTreatments {
  PickyNudge = 'picky_nudge',
  PickyNudgeWithProgression = 'picky_nudge_with_progression',
  Off = 'off',
}

export enum CustomerClinicSearchTreatments {
  off = 'off',
  on = 'on',
  hide_search = 'hide_search',
}

export enum CustomerVetSectionTreatments {
  control = 'control',
  off = 'off',
  checkmarks = 'checkmarks',
  icons = 'icons',
}

export enum ReferralsGiftABoxVariant {
  control = 'control',
  off = 'off',
  with_email = 'with_email',
  with_ucg = 'with_ucg',
  with_founders_story = 'with_founders_story',
}

// Configs

export interface RecipePagePackOrderConfig {
  recipeOrder: RecipeNames[];
}

export interface ShowPetsInterstitialConfig {
  type: string;
  headline: string;
  underline: string;
  color: (typeof HeadingColors)[number];
  background: string;
  loops: number;
}

export interface ShowHolidayAnnouncementConfig {
  headline: string;
  underline: string;
  endDate: string;
  cutoffStart: string;
  cutoffEnd: string;
}

export interface HomepageSectionHeadersConfig {
  sectionBowl: string;
  sectionWave: string;
  sectionPackage: string;
}

export interface HdyhauContentConfig {
  header: string;
  underline: string;
  offer: string;
}

export interface RecipeNamesConfig {
  TURKEY: string;
  BEEF: string;
  PORK: string;
  CHICKEN_AND_GREENS: string;
}

interface CustomerCxPhoneNumberConfigItem {
  email: boolean;
  phone: boolean;
}
export interface CustomerCxPhoneNumberConfig {
  footer: CustomerCxPhoneNumberConfigItem;
  faq: CustomerCxPhoneNumberConfigItem;
}

export interface RetPawToggleConfig {
  profile: boolean;
}

// Attributes
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export interface FrequenciesAtCheckoutAttributes extends SplitIO.Attributes {
  regularFrequency: number;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export interface GlobalDiscountAttributes extends SplitIO.Attributes {
  referrerCode: string;
  referrerType: string;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export interface FeatureWithCouponAttributes extends SplitIO.Attributes {
  couponCode: string;
  couponType: string;
}
