import { Grid, GridItem, PageWidth } from '@farmersdog/corgi-x';
import type { ReactNode } from 'react';

interface DetailsContainerProps {
  children: ReactNode;
}

export function DetailsContainer({ children }: DetailsContainerProps) {
  return (
    <Grid justifyContent="center">
      <PageWidth skip={['xs', 'sm', 'lg', 'xl']}>
        <GridItem flexDirection={'column'}>{children}</GridItem>
      </PageWidth>
    </Grid>
  );
}
