import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import freshFoodImage from '../../../../assets/reactivation/FreshFood.png';
import snapSticksImage from '../../../../assets/reactivation/SnapSticks.png';
import type { TreatSku } from '../../../../treats';
import { TreatsProductInfo } from '../../../../treats';
import { formatCurrency } from '../../../../utils';
import type {
  FreshSubscriptionFieldsFragment,
  ReactivationFreshQuoteFieldsFragment,
} from '../../../network';
import styles from './DailyPriceQuote.module.css';
import { DailyPriceSection } from './DailyPriceSection';
import { useTrackPricesSeen } from '../../../../analytics/useTrackPricesSeen';

export interface DailyPriceQuoteProps {
  quote: ReactivationFreshQuoteFieldsFragment;
  addOns: FreshSubscriptionFieldsFragment['addOns'];
}

export function DailyPriceQuote(props: DailyPriceQuoteProps) {
  const treatItems = props.addOns.map(addOn => {
    const treatSku = addOn.product.sku as TreatSku;
    const { recipeDisplayName, packageSize } = TreatsProductInfo[treatSku];

    return `${addOn.quantity} ${recipeDisplayName} (${packageSize})`;
  });

  const treatOrder = {
    items: treatItems,
    price: `${formatCurrency(props.quote.price.treatsTotal)}/order`,
  };

  const dailyPriceItems = props.quote.pets.map(
    ({ id, name, dailyConsumptionPrice }) => {
      return {
        name: `${name}'s Plan:`,
        petId: id,
        price: dailyConsumptionPrice,
      };
    }
  );

  return (
    <Grid flexDirection={'column'} rowGap={'md'} className={styles.root}>
      <DailyPriceSection heading="Treats" image={snapSticksImage}>
        <>
          {props.quote.price.treatsTotal > 0 && (
            <Grid rowGap={'xs'}>
              {treatOrder.items.map(name => (
                <GridItem key={`Treats-${name}`}>
                  <Text variant={'heading-16'} color={'charcoal-3'}>
                    {name}
                  </Text>
                </GridItem>
              ))}
              <GridItem>
                <Text variant={'heading-16'} color={'charcoal-3'}>
                  {treatOrder.price}
                </Text>
              </GridItem>
            </Grid>
          )}
          {props.quote.price.treatsTotal <= 0 && (
            <Text variant={'heading-16'} color={'charcoal-3'}>
              None Added
            </Text>
          )}
        </>
      </DailyPriceSection>

      <DailyPriceSection heading="Fresh Food" image={freshFoodImage}>
        <Grid rowGap={'xs'}>
          {dailyPriceItems.map(({ petId, name, price }, index) => (
            <DailyPriceItem
              name={name}
              petId={petId}
              price={price}
              key={index}
            />
          ))}
        </Grid>
      </DailyPriceSection>
    </Grid>
  );
}

function DailyPriceItem({
  name,
  petId,
  price,
  key,
}: {
  name: string;
  petId: number;
  price: number;
  key: number;
}) {
  useTrackPricesSeen({
    dailyPrice: price,
    petId,
  });

  return (
    <GridItem key={`FreshFood-${key}`}>
      <Text variant={'heading-16'} color={'charcoal-3'}>
        {name}
      </Text>{' '}
      <Text variant={'heading-16'} color={'charcoal-3'}>
        {`${formatCurrency(price)}/day`}
      </Text>
    </GridItem>
  );
}
