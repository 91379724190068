import { track } from '@farmersdog/analytics';

import { getPetDailySubtotal } from '../../utils/quote/getPetDailySubtotal';
import { segmentTrackEvents } from '../segmentTrackEventNames';

import type { FetchQuoteQuery, PetInputForQuote } from '../../graphql/types';

interface TrackRecipesPlanDetailsArgs {
  currentPetName: string;
  petsInput: Omit<PetInputForQuote, 'requestedCalories'>[];
  quote: FetchQuoteQuery['fetchQuote'];
  isRecommendedPlan?: boolean;
}

export function trackRecipesPlanDetails({
  currentPetName,
  petsInput,
  quote,
  isRecommendedPlan,
}: TrackRecipesPlanDetailsArgs) {
  const selectedRecipeNames = petsInput
    .find(input => input.name === currentPetName)
    ?.selection.fresh?.options.recipes.sort()
    .join(',');

  const { dailySubtotalWithDiscount } = getPetDailySubtotal({
    quote,
    petName: currentPetName,
  });

  track(segmentTrackEvents.recipes_plan_details, {
    recipesSelected: selectedRecipeNames,
    firstOrderDaysOfFood: quote.trial.daysOfFood,
    firstOrderDiscountPercentage: quote.trial.discount.percentage,
    dailyPlanPrice: dailySubtotalWithDiscount,
    petName: currentPetName,
    isRecommendedPlan,
  });
}
