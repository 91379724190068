import events from '../eventNames';
import track from '../track';

type TrackSubmittrackSubmitReferreeEmailEventInput = {
  email: string;
  firstName: string;
  petNames?: string[];
  referralCode: string;
  discountPercentage: number;
};

/**
 * A segment tracking event that is used to submit the referee email
 * along with referral attributes.
 */
export function trackSubmitReferreeEmailEvent({
  email,
  firstName,
  petNames,
  referralCode,
  discountPercentage,
}: TrackSubmittrackSubmitReferreeEmailEventInput) {
  track(events.portal_referral_email_submitted, {
    email,
    firstName,
    petNames,
    referralCode,
    discount: discountPercentage,
  });
}
