import { Select } from '@farmersdog/corgi-x';
import type { AddDogFormChangeHandler, PetSexOptions } from '../../../hooks';

interface GenderInputProps {
  value: PetSexOptions;
  onChange: AddDogFormChangeHandler;
}

export const GenderInput = (props: GenderInputProps) => {
  const { onChange, value } = props;

  const handleGenderChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
    onChange('sex', e.target.value as PetSexOptions);
  };
  return (
    <Select
      name="sex"
      label="Sex"
      onChange={handleGenderChange}
      value={value}
      aria-required
    >
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </Select>
  );
};
