import { useMutation } from '@apollo/client';
import type {
  AddPetMutation,
  AddPetMutationVariables,
} from './addPet.cgs.generated-types';
import AddPet from './addPet.cgs.graphql';
import type { UseAddDogFormFields } from '../hooks';

interface AddPetMutationArgs {
  onCompleted: () => void;
  onError: (error: Error) => void;
}

export const useAddPetMutation = (args: AddPetMutationArgs) => {
  const [callAddPet, state] = useMutation<
    AddPetMutation,
    AddPetMutationVariables
  >(AddPet, {
    context: { endpoint: 'cgs' },
    onCompleted: args.onCompleted,
    onError: args.onError,
  });

  const submit = (variables: UseAddDogFormFields) => {
    void callAddPet({ variables: { input: { ...variables } } });
  };

  return {
    submit,
    isSubmitting: state.loading,
  };
};
