import { isDiySignup } from '@farmersdog/lead-browser-storage';

import { handleAuthRegisterError } from './handleAuthRegisterError';

import type { UseCalculatingScreenReturn } from '../../../components/shared/CalculatingScreen';
import type { LeadInput } from '../../../graphql/types';
import type { RegisterLeadType } from '../../useLeadState/types';

interface HandleFinalPetsStepParams {
  leadInput: LeadInput;
  calculatingScreen: UseCalculatingScreenReturn;
  isTosaHighTrafficModeEnabled: boolean;
  shouldShowCalculatingScreen: boolean;
  throwToErrorBoundary: (error: Error) => void;
  onManagedError: (message: string) => void;
  clearAvailablePetRecipesCache: () => void;
  registerLead: RegisterLeadType;
  onPetsFormCompleted: () => void;
}

type RegisterLeadWithCalculatingScreenParams = Omit<
  HandleFinalPetsStepParams,
  'throwToErrorBoundary' | 'onManagedError' | 'onPetsFormCompleted'
>;

export const handleFinalPetsStep = async ({
  shouldShowCalculatingScreen,
  leadInput,
  calculatingScreen,
  clearAvailablePetRecipesCache,
  registerLead,
  throwToErrorBoundary,
  onManagedError,
  isTosaHighTrafficModeEnabled,
  onPetsFormCompleted,
}: HandleFinalPetsStepParams): Promise<void> => {
  const error = await registerLeadWithCalculatingScreen({
    leadInput,
    clearAvailablePetRecipesCache,
    registerLead,
    calculatingScreen,
    shouldShowCalculatingScreen,
    isTosaHighTrafficModeEnabled,
  });

  if (error) {
    handleAuthRegisterError({ error, throwToErrorBoundary, onManagedError });
    // need to return here because managed errors show a modal and we should not continue
    return;
  } else {
    onPetsFormCompleted();
  }
};

const registerLeadWithCalculatingScreen = async ({
  leadInput,
  clearAvailablePetRecipesCache,
  registerLead,
  calculatingScreen,
  shouldShowCalculatingScreen,
  isTosaHighTrafficModeEnabled,
}: RegisterLeadWithCalculatingScreenParams): Promise<Error | undefined> => {
  try {
    if (shouldShowCalculatingScreen) {
      calculatingScreen.open();
    }

    // if we are concerned about timeouts, run jitter to relieve request pressure
    if (isTosaHighTrafficModeEnabled) {
      await waitJitteredAmount();
    }

    if (isDiySignup()) {
      await registerLead({
        lead: leadInput,
      });
    }

    clearAvailablePetRecipesCache();
    return undefined;
  } catch (error) {
    return error as Error;
  }
};

const waitJitteredAmount = async () => {
  const MAX_WAIT = 2000;
  return new Promise(resolve => setTimeout(resolve, Math.random() * MAX_WAIT));
};
