import { useCallback } from 'react';

import {
  useCreateLead,
  useRecoverLead,
  useRegisterLead,
  useUpdateLead,
} from '../../graphql/mutations';
import {
  useLead,
  useLazyLeadView,
  useLazyRegisterLeadResponse,
} from '../../graphql/queries';
import { useHandleRequestTimeout } from '../experiments/useHandleRequestTimeout';

import {
  convertLeadToFormState,
  convertFormStateToLeadInput,
  convertCachedLeadToLeadInput,
} from './utils';
import { handleLeadConsent } from './utils/handleLeadConsent';

import type {
  GetLeadType,
  GetCachedLeadType,
  RequestStateReturn,
  WriteLeadArgs,
  WriteLeadType,
  CreateLeadArgs,
  CreateLeadType,
  RegisterLeadArgs,
  RegisterLeadType,
  GetCachedUser,
  RecoverLeadType,
  RecoverLeadArgs,
} from './types';
import type {
  ConvertLeadToFormStateType,
  ConvertFormStateToLeadInputType,
  ConvertCachedLeadToLeadInputType,
} from './utils';
import type { GraphQLError } from 'graphql';

interface UseLeadStateReturn {
  getLead: GetLeadType;
  getCachedUser: GetCachedUser;
  getCachedLead: GetCachedLeadType;
  convertLeadToFormState: ConvertLeadToFormStateType;
  convertFormStateToLeadInput: ConvertFormStateToLeadInputType;
  convertCachedLeadToLeadInput: ConvertCachedLeadToLeadInputType;
  writeLead: WriteLeadType;
  createLead: CreateLeadType;
  registerLead: RegisterLeadType;
  recoverLead: RecoverLeadType;
  getLeadState: RequestStateReturn;
  writeLeadState: RequestStateReturn;
  createLeadState: RequestStateReturn;
  registerLeadState: RequestStateReturn;
  recoverLeadState: RequestStateReturn;
}

export function useLeadState(): UseLeadStateReturn {
  const getRegisterLeadResponse = useLazyRegisterLeadResponse();
  const [getLeadQuery, getLeadQueryState] = useLead({
    // Disable cache when explicitly fetching lead
    fetchPolicy: 'network-only',
  });
  const [createLeadMutation, createLeadState] = useCreateLead();
  const [registerLeadMutation, registerLeadState] = useRegisterLead();
  const [updateLeadMutation, updateLeadState] = useUpdateLead();
  const handleRequestTimeout = useHandleRequestTimeout();
  const [recoverLeadMutation, recoverLeadState] = useRecoverLead();
  const fetchCachedLead = useLazyLeadView();

  const getCachedUser = useCallback(
    () => getRegisterLeadResponse()?.user || null,
    [getRegisterLeadResponse]
  );

  const getLead = useCallback(async () => {
    const lead = (await getLeadQuery()).data?.lead || null;

    if (lead) {
      handleLeadConsent(lead);
    }

    return lead;
  }, [getLeadQuery]);

  const getCachedLead = useCallback(() => {
    const lead = fetchCachedLead();

    if (lead) {
      handleLeadConsent(lead);
    }

    return lead;
  }, [fetchCachedLead]);

  const createLead = useCallback(
    async ({ email, daisyModeEnabled }: CreateLeadArgs) => {
      let createLeadResponse;

      try {
        createLeadResponse = await createLeadMutation({
          variables: {
            email,
            daisyModeEnabled,
          },
        });
      } catch (error) {
        handleRequestTimeout(error as GraphQLError);
        throw error;
      }

      return {
        isRecoverable: createLeadResponse.data?.createLead.recoverable || false,
        isAuthenticated: !!createLeadResponse.data?.createLead.token,
      };
    },
    [handleRequestTimeout, createLeadMutation]
  );

  const registerLead = useCallback(
    async ({ lead, disableRecipesUpdate = false }: RegisterLeadArgs) => {
      try {
        return await registerLeadMutation({
          variables: {
            lead,
            disableRecipesUpdate,
          },
        });
      } catch (error) {
        handleRequestTimeout(error as GraphQLError);
        throw error;
      }
    },
    [handleRequestTimeout, registerLeadMutation]
  );

  const writeLead = useCallback(
    ({ lead }: WriteLeadArgs) => {
      return updateLeadMutation({ variables: { lead } });
    },
    [updateLeadMutation]
  );

  const recoverLead = useCallback(
    async (variables: RecoverLeadArgs) => {
      try {
        const response = await recoverLeadMutation({
          variables,
        });

        const lead = response.data?.recoverLead;

        if (lead) {
          handleLeadConsent(lead);
        }

        return lead;
      } catch (error) {
        handleRequestTimeout(error as GraphQLError);
        throw error;
      }
    },
    [handleRequestTimeout, recoverLeadMutation]
  );

  return {
    convertLeadToFormState,
    convertFormStateToLeadInput,
    convertCachedLeadToLeadInput,
    getLead,
    getCachedUser,
    getCachedLead,
    writeLead,
    createLead,
    registerLead,
    recoverLead,

    getLeadState: {
      loading: getLeadQueryState.loading,
      called: getLeadQueryState.called,
    },
    writeLeadState: {
      loading: updateLeadState.loading,
      called: updateLeadState.called,
    },
    createLeadState: {
      loading: createLeadState.loading,
      called: createLeadState.called,
    },
    registerLeadState: {
      loading: registerLeadState.loading,
      called: registerLeadState.called,
    },
    recoverLeadState: {
      loading: recoverLeadState.loading,
      called: recoverLeadState.called,
    },
  };
}
