import { useEffect } from 'react';

import { scroller } from '../utils';

import type { UseFormNavigateReturn } from '../types';

interface UseScrollToTopArgs {
  formNavigation: UseFormNavigateReturn;
}

export function useScrollToTop({ formNavigation }: UseScrollToTopArgs) {
  const scrollKey = formNavigation.current;

  useEffect(() => {
    scroller.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [scrollKey]);
}
