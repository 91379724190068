import { getQuestionStates } from '../data';
import type { CancellationResponse } from '../data/cancellationResponses';
import { ReasonLabelEnum } from '../data/cancellationResponses';

/**
 * A util that returns the "reasons" for a given state
 * (if they exist).
 *
 * @remarks This util allows easier mocking for tests.
 *
 * @param state - the current state in the flow
 * @returns an array of cancellation reason objects
 */

export function getReasonsForState(state: string): CancellationResponse[] {
  const questionStates = getQuestionStates();
  if (questionStates[state]?.reasons) {
    return questionStates[state].reasons.map(reason => ({
      value: reason,
      label: ReasonLabelEnum[reason],
    }));
  }
  return [];
}
