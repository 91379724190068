import classNames from 'classnames';

import { PageWidth, useViewport } from '@farmersdog/corgi-x';

import styles from './Container.module.css';

import type { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
  withMobilePadding?: boolean;
}

export const Container = ({
  children,
  withMobilePadding = false,
}: ContainerProps) => {
  const containerClassname = classNames(styles.container, {
    [styles.withMobilePadding]: withMobilePadding,
  });

  const viewport = useViewport();
  const isMobile = !viewport.md;

  return (
    <PageWidth enabled={!isMobile} skip={['sm']} className={containerClassname}>
      {children}
    </PageWidth>
  );
};
