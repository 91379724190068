import classNames from 'classnames';

import type { RecipeNames } from '@farmersdog/constants';
import { heading } from '@farmersdog/corgi';
import { CHARCOAL_3 } from '@farmersdog/corgi/constants';

import { Picture } from '../../shared';

import { recipeImages } from './assets';
import styles from './RecipePack.module.css';

// Viewbox of the SVG element
const VIEWBOX_WIDTH = 400;
const VIEWBOX_HEIGHT = 700;
const PET_NAME_CHARACTER_LIMIT = 10;
const DISPLAY_NAME_CHARACTER_LIMIT = 12;

const titleTypography = heading.create({
  size: 28,
  weight: 'bold',
});

const subtitleTypography = heading.create({
  size: 21,
});

interface RecipePackArgs {
  petName: string;
  recipeName?: RecipeNames;
  displayName: string;
  className?: string;
}

export function RecipePack({
  recipeName,
  petName = 'Your dog',
  displayName,
  className,
  ...props
}: RecipePackArgs) {
  const safePetName =
    petName.length > PET_NAME_CHARACTER_LIMIT
      ? `${petName.slice(0, PET_NAME_CHARACTER_LIMIT).trim()}…`
      : `${petName}’s`;
  const shouldWrapRecipe = displayName.length > DISPLAY_NAME_CHARACTER_LIMIT;

  const recipeKey = recipeName ?? 'NO_RECIPE';
  const noRecipe = recipeKey === 'NO_RECIPE' || !recipeImages[recipeKey];

  return (
    <div className={classNames(styles.container, className)}>
      <Picture
        className={classNames(styles.image, { [styles.noRecipe]: noRecipe })}
        sources={recipeImages[recipeKey]}
        alt={`${displayName} recipe`}
      />
      <div className={styles.svgContainer}>
        <svg
          className={styles.svg}
          viewBox={`0 0 ${String(VIEWBOX_WIDTH)} ${String(VIEWBOX_HEIGHT)} `}
          {...props}
        >
          <g>
            <text
              fill={CHARCOAL_3}
              textAnchor="middle"
              className={titleTypography.className}
              x={String(VIEWBOX_WIDTH / 2)}
              y="320"
            >
              {safePetName}
            </text>
            <text
              fill={CHARCOAL_3}
              className={subtitleTypography.className}
              textAnchor="middle"
              x={String(VIEWBOX_WIDTH / 2)}
              y="357"
            >
              {!noRecipe && displayName}
              {!shouldWrapRecipe && ' Recipe'}
            </text>
            {shouldWrapRecipe && (
              <text
                fill={CHARCOAL_3}
                className={subtitleTypography.className}
                textAnchor="middle"
                x={String(VIEWBOX_WIDTH / 2)}
                y="382"
              >
                Recipe
              </text>
            )}
          </g>
        </svg>
      </div>
    </div>
  );
}
