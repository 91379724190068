import ORIENTATIONS from './orientations';
import DEFAULT_VIEWPORTS from './defaultViewports';
import type { Viewport, Config } from 'src/screen/types';

/**
 * Definitions for our breakpoints
 */
export const VIEWPORTS: Viewport[] = [
  {
    name: 'xs',
    minWidth: 0,
  },
  {
    name: 'sm',
    minWidth: 414,
  },
  {
    name: 'md',
    minWidth: 768,
  },
  {
    name: 'lg',
    minWidth: 992,
  },
  {
    name: 'xl',
    minWidth: 1200,
  },
];

export const legacyConfig: Config = {
  viewports: VIEWPORTS,
  defaultViewports: DEFAULT_VIEWPORTS,
  orientations: ORIENTATIONS,
};
