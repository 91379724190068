/* CH#49502: Used by RET and CVR */
import type { ReactNode } from 'react';
import { Component } from 'react';
import type { TextVariant } from '@farmersdog/corgi-x';
import { ButtonBase, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import cookie from 'js-cookie';
import { browserOnly } from '@farmersdog/utils';

import ArrowRight from 'src/assets/icons/arrow-right.svg';

import { DETAILS_COOKIE } from 'src/constants/cookie';

import styles from './Details.module.css';

/**
 * Get the open state for the component instances or for a specified `id`.
 */
function getOpenState(id: string) {
  if (!process.env.BROWSER) {
    return undefined;
  }
  const cookieValue = cookie.get(DETAILS_COOKIE);
  if (!cookieValue) {
    // Cookie not set yet
    return false;
  }
  if (!cookieValue.split(',').includes(id)) {
    return false;
  }
  return true;
}

/**
 * Save the open state for the specified Detail's `id`.
 */
function saveOpenState(id: string, open: boolean) {
  if (!process.env.BROWSER) {
    return;
  }

  const cookieValue = cookie.get(DETAILS_COOKIE);
  let opened: string[] = [];
  if (cookieValue) {
    opened = cookieValue.split(',');
  }

  const index = opened.indexOf(id);

  if (!open && index > -1) {
    opened.splice(index, 1); // Remove the id
  } else if (open && index === -1) {
    opened.push(id); // Add the id
  }

  if (opened.length === 0) {
    // Nothing open: remove the cookie
    cookie.remove(DETAILS_COOKIE);
    return;
  }

  cookie.set(DETAILS_COOKIE, opened.toString());
}

interface DetailsProps {
  /** Open the details content when rendering */
  open?: boolean;
  /** The summary content */
  summary?: ReactNode;
  /**
   * An unique ID for this component. If set, will preserve the component
   * state in the browser's local storage.
   */
  id?: string;
  children: ReactNode;
  /**
   * Optional prop for Google structured data
   *
   * @see https://developers.google.com/search/docs/advanced/structured-data/faqpage)
   */
  itemProp?: string;
  /**
   * Container className
   */
  className?: string;
  /**
   * Title variant
   */
  titleVariant?: TextVariant;
  /*
   *  Variant color
   */
  variant?: 'white' | 'charcoal';
  /**
   * Hide borders
   */
  isBorderless?: boolean;
}

/**
 * Used in FAQ pages. Render a summary that when clicked will open displaying
 * the children. Mimic the `<details>` `<summary>` HTML tags.
 *
 * @deprecated Use `<Accordion>` instead
 */
class Details extends Component<DetailsProps, { open: boolean }> {
  static defaultProps = {
    open: false,
    variant: 'charcoal',
    isBorderless: false,
    titleVariant: 'article-16',
  };

  constructor(props: DetailsProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      open: Boolean(props.id ? getOpenState(props.id) : props.open),
    };
  }

  componentDidMount() {
    browserOnly(window => {
      // Expand the details content when printing
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.addEventListener('beforeprint', this.open);
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.addEventListener('afterprint', this.close);
    });
  }

  componentDidUpdate(prevProps: DetailsProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.open();
      } else {
        this.close();
      }
    }
  }

  componentWillUnmount() {
    browserOnly(window => {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.removeEventListener('beforeprint', this.open);
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.removeEventListener('afterprint', this.close);
    });
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (this.props.id) {
      saveOpenState(this.props.id, true);
    }
    this.setState({ open: true });
  }

  close() {
    if (this.props.id) {
      saveOpenState(this.props.id, false);
    }
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const { variant, isBorderless } = this.props;

    const isWhiteVariant = variant === 'white';

    const className = classNames(this.props.className, styles.Details, {
      [styles.open]: open,
      [styles.borderless]: isBorderless,
    });

    const arrowClassNames = classNames(styles.arrowRight, {
      [styles.white]: isWhiteVariant,
    });

    const copyColorMap = {
      white: 'white',
      charcoal: 'charcoal-3',
    } as const;

    const color =
      copyColorMap[variant as keyof typeof copyColorMap] || 'charcoal-3';

    return (
      <div className={className}>
        <ButtonBase
          aria-expanded={open}
          className={styles.summary}
          // eslint-disable-next-line @typescript-eslint/unbound-method
          onClick={this.toggle}
          tabIndex={0}
        >
          {/* TODO: replace with corgi-x icon https://app.shortcut.com/farmersdog/story/103694 */}
          <ArrowRight className={arrowClassNames} />{' '}
          <Text
            as="h3"
            itemProp={this.props.itemProp}
            variant={this.props.titleVariant}
            color={color}
          >
            {this.props.summary}
          </Text>
        </ButtonBase>
        <AnimateHeight duration={150} height={this.state.open ? 'auto' : 0}>
          <div className={styles.content}>{this.props.children}</div>
        </AnimateHeight>
      </div>
    );
  }
}

export default Details;
