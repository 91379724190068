import type { ReactNode, FC, CSSProperties } from 'react';

import type { Config, ViewportName } from 'src/screen/types';
import type { ViewportComponentProps } from './createViewport';

interface DebugProps {
  matchedViewport: ViewportName;
}

const createViewportDebug = (
  Viewport: FC<ViewportComponentProps>,
  { viewports }: Config
) => {
  /**
   * Include this component to display a viewport debug layer on the bottom of the
   * window.
   */
  const ViewportDebug: FC = () => {
    if (process.env.NODE_ENV === 'development') {
      const containerStyle: CSSProperties = {
        fontFamily: '-apple-system, BlinkMacSystemFont',
        borderTop: '1px solid #f500cb',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: 15,
        zIndex: 100000,
        fontSize: 11,
        color: '#2196f3',
      };

      const viewportStyle = (i: number, minWidth: number): CSSProperties => ({
        top: 1,
        bottom: 1,
        zIndex: viewports.length - i,
        position: 'absolute',
        textAlign: 'right',
        width: i === viewports.length - 1 ? 4000 : minWidth,
        borderRight: '1px solid #f500cb',
        padding: '0px 3px',
      });

      const infoStyle: CSSProperties = {
        position: 'absolute',
        color: '#2196f3',
        left: 3,
        top: 1,
        bottom: 1,
      };

      return (
        <Viewport
          screenInfo={true}
          xs={
            (({ matchedViewport }: DebugProps) => (
              <div style={containerStyle}>
                <div style={infoStyle}>
                  Current: <strong>{matchedViewport}</strong>
                </div>
                {viewports.map(({ name, minWidth }, i) => (
                  <div key={i} style={viewportStyle(i, minWidth)}>
                    {name}
                  </div>
                ))}
              </div>
            )) as unknown as ReactNode // ugh, these types are incorrect but this works at runtime
          }
        />
      );
    }
    return null;
  };

  return ViewportDebug;
};

export default createViewportDebug;
