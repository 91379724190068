import { useEffect } from 'react';
import useAsyncFunction from '../useAsyncFunction';
import type {
  AsyncFunctionOptions,
  AsyncFunction,
} from '../useAsyncFunction/useAsyncFunction.types';

/**
 * Import an ES module asynchronously
 *
 * @param fn - A function that returns a dynamic import
 * @param options - Options to pass to useAsyncFunction
 *
 * Due to webpack limitations this hook must accept a function of a dynamic
 * import rather then a string of the module path.
 *
 * @example
 *
 * ```js
 * const myModule = useAsyncModule(() => import('./my-module-path'));
 *
 * if (myModule.loading) {
 *   return
 * }
 *
 * const Component = myModule.data.default; // es6 default exports
 * const { Component } = myModule.data; // es6 named exports
 * ```
 */
export function useAsyncModule<R>(
  fn: AsyncFunction<R, []>,
  options?: AsyncFunctionOptions<R>
) {
  const [fetchModule, asyncModule] = useAsyncFunction(fn, options);

  useEffect(() => {
    if (asyncModule.loading || asyncModule.called || asyncModule.error) {
      return;
    }

    void fetchModule();
  });

  return asyncModule;
}
