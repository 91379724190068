import { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectRequestAttributes } from 'src/reducers/referrer';

import { PATH_SIGNUP } from '@farmersdog/constants/paths';

import { trackDiyHomepageSuccess } from 'src/analytics';

import qs from 'qs';
import { QueryParameter } from '@farmersdog/constants';
import { SubscriptionType } from 'src/graphql/types';

let trackedSuccess = false;

export function useTrackHomepageSuccess() {
  const requestAttributes = useSelector(selectRequestAttributes);
  const history = useHistory();

  const callTrack = useCallback(
    (targetLocation: Location) => {
      const query = qs.parse(targetLocation.search, {
        ignoreQueryPrefix: true,
      });

      if (
        targetLocation.pathname.indexOf(PATH_SIGNUP) > -1 &&
        !trackedSuccess &&
        query?.[QueryParameter.ProductLine] === SubscriptionType.Diy
      ) {
        trackDiyHomepageSuccess({ requestAttributes });
        trackedSuccess = true;
      }
    },
    [requestAttributes]
  );

  // Effect to capture navigation from Home to Signup and
  // track Signup Conversion
  useEffect(
    () =>
      history.block(targetLocation =>
        callTrack(targetLocation as unknown as Location)
      ),
    [callTrack, history]
  );
}
