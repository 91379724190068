import {
  PATH_CUSTOMER_ACCOUNT_FAQ,
  PATH_DIY_RECIPES,
  PATH_GIFT,
  PATH_LOGIN,
  PATH_MEALS,
  PATH_PLAN,
  PATH_ORDERS,
  PATH_PROFILE,
  PATH_TREAT_PRODUCTS,
  PAW_PATH_MY_DOGS,
} from '@farmersdog/constants/paths';
import type {
  AppBarItemProps,
  VerticalNavigationItemProps,
} from '@farmersdog/corgi';
import { getHomepagePath } from '@farmersdog/paw';

import type { UseCustomerAccountData } from '@/account/app/hooks';
import {
  SubscriptionStatus,
  SubscriptionType,
} from '@/account/graphql/core/types';

export type FooterItemProps = AppBarItemProps & {
  rightAnnotation?: string;
};

export interface Navigation {
  /** All items on the left side of the top level nav **/
  leftItems: AppBarItemProps[];
  /** All items on the right side fo the top level nav **/
  rightItems?: AppBarItemProps[];
  /** Navigation designed to be the call to action **/
  callToAction?: AppBarItemProps;
  /** Items to be displayed for the vertical navigation **/
  verticalNavItems?: VerticalNavigationItemProps[];
  /** Items to be shown on the footer in the customer account **/
  footerAccountItems?: FooterItemProps[];
  /** Items to be displayed in the sub nav */
  subNavItems?: AppBarItemProps[];
}

export function getNavigation(
  data: UseCustomerAccountData,
  logout: () => void,
  isMobile: boolean
): Navigation {
  const isDiySubscription = data.subscription?.type === SubscriptionType.Diy;

  const referralItem = getReferralItem(data);

  const desktopItems = getDesktopItems(data, referralItem);

  const accountSubItems = getAccountSubItems(data);

  const treatsItem = getTreatsItem(data, isMobile);

  const faqItem = {
    to: PATH_CUSTOMER_ACCOUNT_FAQ,
    title: 'FAQ',
  };

  const logoutItem: AppBarItemProps = {
    to: PATH_LOGIN,
    title: 'Log Out',
    onClick: e => {
      e.preventDefault();
      logout();
    },
    role: 'button',
  };

  const leftItemsMobile = [...accountSubItems];
  if (treatsItem) {
    leftItemsMobile.push(treatsItem);
  }
  if (referralItem) {
    leftItemsMobile.push(referralItem);
  }
  leftItemsMobile.push(faqItem, logoutItem);

  const leftItemsDesktop = [...desktopItems];
  if (treatsItem) {
    leftItemsDesktop.push(treatsItem);
  }
  leftItemsDesktop.push(faqItem);

  const footerAccountItems: FooterItemProps[] = [
    ...accountSubItems,
    {
      title: 'Treats',
      to: PATH_TREAT_PRODUCTS,
    },
  ];

  if (!isDiySubscription) {
    footerAccountItems.push({
      title: 'Referral',
      to: PATH_GIFT,
      rightAnnotation: 'Gift a box!',
    });
  }

  return isMobile
    ? {
        leftItems: leftItemsMobile,
        footerAccountItems,
        subNavItems: accountSubItems,
      }
    : {
        leftItems: leftItemsDesktop,
        rightItems: [logoutItem],
        verticalNavItems: accountSubItems,
        footerAccountItems,
      };
}

function getReferralItem(
  data: UseCustomerAccountData
): AppBarItemProps | undefined {
  if (
    !data.subscription ||
    data.subscription.status !== SubscriptionStatus.Active ||
    data.subscription.type === SubscriptionType.Diy ||
    !data.referralStats ||
    !Number.isFinite(data.referralStats.discountPercentage)
  ) {
    return;
  }

  return {
    to: PATH_GIFT,
    title: 'Gift a Box',
  };
}

function getTreatsItem(
  data: UseCustomerAccountData,
  isMobile: boolean
): AppBarItemProps | undefined {
  const isFresh = data.subscription?.type === SubscriptionType.Fresh;

  if (!isFresh) return;

  return {
    to: PATH_TREAT_PRODUCTS,
    title: isMobile ? 'Shop Treats' : 'Treats',
  };
}

function getDesktopItems(
  data: UseCustomerAccountData,
  referralItem: AppBarItemProps | undefined
): AppBarItemProps[] {
  const desktopItems = [];

  const homepagePath = getHomepagePath({
    subscriptionType: data.subscription?.type,
    isSubscriptionActive: data.isSubscriptionActive,
  });

  desktopItems.push({
    to: homepagePath || PATH_ORDERS,
    title: 'Account',
  });

  if (referralItem) {
    desktopItems.push(referralItem);
  }

  return desktopItems;
}

function getAccountSubItems(data: UseCustomerAccountData): AppBarItemProps[] {
  const accountItems = [];

  const isDiySubscription = data.subscription?.type === SubscriptionType.Diy;

  const homepagePath = getHomepagePath({
    subscriptionType: data.subscription?.type,
    isSubscriptionActive: data.isSubscriptionActive,
  });

  if (homepagePath) {
    accountItems.push({
      title: 'Home',
      to: homepagePath,
    });
  }

  accountItems.push({
    title: 'Orders',
    to: PATH_ORDERS,
  });

  if (data.activePets && data.activePets.length) {
    const moreThanOnePet = data.activePets.length > 1;
    const firstPetsName = data.activePets[0].name;

    let mealsLabel = 'Box';
    if (isDiySubscription) mealsLabel = 'Plan';

    if (!isDiySubscription) {
      accountItems.push({
        title: 'Plan',
        to: PATH_PLAN,
      });
    } else {
      accountItems.push({
        title: moreThanOnePet
          ? `My Dogs’ ${mealsLabel}`
          : `${firstPetsName}’s ${mealsLabel}`,
        to: PATH_MEALS,
      });
    }
  }

  if (isDiySubscription) {
    accountItems.push({
      title: 'Recipes',
      to: PATH_DIY_RECIPES,
    });
  }

  if (
    data.isSubscriptionActive &&
    data.subscription?.type === SubscriptionType.Fresh
  ) {
    let title = 'My Dogs';

    if (data?.activePets?.length && data?.activePets?.length < 2) {
      title = 'My Dog';
    }

    accountItems.push({
      title,
      to: PAW_PATH_MY_DOGS,
    });
  }

  if (
    data.subscription &&
    data.subscription.status !== SubscriptionStatus.Pending
  ) {
    accountItems.push({
      title: 'Settings',
      to: PATH_PROFILE,
    });
  }

  return accountItems;
}
