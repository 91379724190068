import type { TextProps } from '@farmersdog/corgi-x';
import { Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';

export type TitleProps = Omit<TextProps, 'variant'>;

/**
 * Render a section title.
 */
export function Title(props: TitleProps) {
  const variant = useCorgiViewport<TextProps['variant']>({
    xs: 'heading-28',
    lg: 'heading-40',
  });

  return <Text as="h2" variant={variant} color="kale-3" bold {...props} />;
}
