import {
  Grid,
  GridItem,
  Toast,
  ToastSentiment,
  ToggleCard,
  visuallyHidden,
  Text,
} from '@farmersdog/corgi-x';
import type { AddDogFormChangeHandler, RecipeOptions } from '../../hooks';
import { AddDogContent } from '../AddDogContent';
import { RecipeNames, freshFoodPictureSources } from '../../../../../assets';
import styles from './RecipesScene.module.css';
import { reporter } from '../../../../../errors/services';
import { useState } from 'react';

interface RecipesSceneProps {
  name: string;
  availableRecipes: RecipeOptions | undefined;
  preSelectedRecipes: string[] | undefined;
  selectedRecipes: string[];
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

export const RecipesScene = (props: RecipesSceneProps) => {
  const {
    onCtaClick,
    name,
    onChange,
    selectedRecipes,
    preSelectedRecipes,
    availableRecipes,
  } = props;

  if (!availableRecipes) {
    reporter.error('Available recipes not defined');
    throw new Error('Available recipes are required for Add Pet form');
  }

  if (!preSelectedRecipes) {
    reporter.error('Pre-selected recipes not defined');
    throw new Error('Pre-selected recipes are required for Add Pet form');
  }

  const [showMaxRecipesToast, setShowMaxRecipesToast] = useState(false);

  const handleRecipeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    let formValue = selectedRecipes;

    // Max 3 recipes can be selected.
    if (selectedRecipes.length === 3 && isChecked) {
      setShowMaxRecipesToast(true);
      return;
    }

    if (isChecked && selectedRecipes.length < 3) {
      formValue = [...selectedRecipes, e.target.value];
    } else if (!isChecked) {
      formValue = selectedRecipes?.filter(v => v !== e.target.value);
    }
    onChange('recipes', formValue);
  };

  // Pork recipe should be the last option.
  const porkRecipe = availableRecipes.find(
    recipe => recipe.name === RecipeNames.PORK
  );
  const otherRecipes = availableRecipes.filter(
    recipe => recipe.name !== RecipeNames.PORK
  );
  const sortedRecipes = [...otherRecipes, porkRecipe];

  return (
    <AddDogContent
      heading={`Choose ${name}'s Recipes`}
      cta="Save Recipes"
      onCtaClick={onCtaClick}
      ctaDisabled={selectedRecipes?.length === 0}
      sectionWidthConstraint="lg"
    >
      <Text
        as="p"
        variant="article-16"
        fontStyle="italic"
        topSpacing="sm"
        bottomSpacing="none"
      >
        Select up to 3 recipes
      </Text>
      <fieldset className={styles.fieldset}>
        <legend className={visuallyHidden}>{`Choose ${name}'s Recipes`}</legend>

        <Grid
          columnGap="sm"
          rowGap="md"
          justifyContent="center"
          topSpacing="lg"
          bottomSpacing={{ xs: '3xl', md: 'md' }}
        >
          {sortedRecipes.map(recipe => {
            if (!recipe) return null;
            const isChecked = selectedRecipes.includes(recipe.name);
            const isPreSelected = preSelectedRecipes.includes(recipe.name);
            return (
              <GridItem
                key={recipe.id}
                lg={2}
                md={3}
                xs={6}
                alignItems="stretch"
                justifyContent="center"
              >
                <ToggleCard
                  key={recipe.id}
                  heading={recipe.displayName}
                  description={recipe.mainIngredients}
                  name={recipe.displayName}
                  value={recipe.name}
                  imageSources={freshFoodPictureSources[recipe.name]}
                  imageAlt={`Bowl of ${recipe.displayName} recipe food`}
                  contentClassName={styles.recipeContentContainer}
                  label="Add"
                  checkedLabel="Added"
                  className={styles.toggleCard}
                  checked={isChecked}
                  onChange={handleRecipeChange}
                  badge={isPreSelected ? 'Our Pick' : undefined}
                />
              </GridItem>
            );
          })}
        </Grid>
      </fieldset>
      <Toast
        isOpen={showMaxRecipesToast}
        sentiment={ToastSentiment.Neutral}
        onClose={() => setShowMaxRecipesToast(false)}
        message="You have 3 recipes selected. Please deselect a recipe before choosing another."
      />
    </AddDogContent>
  );
};
