import type { ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';

import type { TextProps, VariantColors } from '@farmersdog/corgi-x';
import { Text } from '@farmersdog/corgi-x';

import styles from './HomepageText.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  color?: VariantColors;
  center?: boolean;
  style?: CSSProperties;
  as?: TextProps['as'];
}

function HomepageText({
  children,
  className,
  color,
  center,
  as,
  ...props
}: Props) {
  return (
    <Text
      as={as ?? 'p'}
      variant="article-20"
      color={color ?? 'charcoal-3'}
      className={classNames(styles.margin, className ?? '', {
        [styles.center]: !!center,
      })}
      {...props}
    >
      {children}
    </Text>
  );
}

export default HomepageText;
