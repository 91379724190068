/* CH#49502: Used by RET and CVR */
import { Text } from '@farmersdog/corgi-x';
import type { MutableRefObject } from 'react';

import Menu from 'src/components/Menu';

import DogDigging from './assets/DogDigging.svg';

import styles from './FAQMenu.module.css';

interface MenuItem {
  name: string;
  label: string;
}

interface FAQMenuProps {
  menuItems: MenuItem[];
  menuItemRefs: { [key: string]: MutableRefObject<HTMLDivElement | null> };
  className?: string;
}

function scrollTo(ref: MutableRefObject<HTMLDivElement | null>) {
  const el = ref.current;

  if (el) {
    el.scrollIntoView();
  }
}

function FAQMenu({ menuItems, className, menuItemRefs }: FAQMenuProps) {
  return (
    <nav className={className} aria-label="Table of Contents">
      <Text as="h1" variant="heading-40" color="kale-3" bold>
        Frequently Asked Questions
      </Text>
      <Menu className={styles.menu}>
        {menuItems.map(menuItem => {
          return (
            <Menu.Item
              key={menuItem.name}
              onClick={() => scrollTo(menuItemRefs[menuItem.name])}
            >
              {menuItem.label}
            </Menu.Item>
          );
        })}
      </Menu>
      <div className={styles.dogContainer}>
        <DogDigging />
      </div>
    </nav>
  );
}

export default FAQMenu;
