import type { LightboxControlReturn } from '@farmersdog/corgi';
import { LabeledSelector, Modal } from '@farmersdog/corgi';
import {
  Button,
  Divider,
  Spacing,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import type { AddressFragment, AddressToUse } from '../../../network';
import { Address } from './Address';
import styles from './SuggestedAddressModal.module.css';

interface SuggestedAddressModalProps {
  submittedAddress: AddressFragment;
  suggestedAddress: AddressFragment;
  onConfirm: (selectedAddress: AddressToUse) => void;
  onCancel: () => void;
  modalProps: LightboxControlReturn;
}

export function SuggestedAddressModal(props: SuggestedAddressModalProps) {
  const [selectedAddress, setSelectedAddress] =
    useState<AddressToUse>('suggested');

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    // @ts-expect-error we have the right type
    setSelectedAddress(e.target.value);
  }

  function handleConfirm() {
    props.onConfirm(selectedAddress);
  }

  const isLargeViewport = useViewport().large;
  return (
    <Modal {...props.modalProps}>
      <fieldset className={styles.fieldset}>
        <legend>
          <Text
            as="h3"
            variant={isLargeViewport ? 'heading-40' : 'heading-28'}
            bold
            color="kale-3"
          >
            Please confirm your address
          </Text>
        </legend>
        <Text as="p" variant="article-16" vSpacing="md">
          Sorry, we’re having trouble verifying the address you entered – mind
          confirming that we’ve got the right place? Some information, such as
          an apartment or suite number, may have been missed or incorrectly
          captured.
        </Text>
        <Divider width={1} color="Charcoal0" spacing={Spacing.LG} />
        <div
          id="suggested-address"
          className={styles.selectors}
          onChange={handleChange}
        >
          <LabeledSelector
            type="radio"
            name="address"
            value="submitted"
            className={styles.labeledSelector}
            checked={selectedAddress === 'submitted'}
          >
            <Address label="Entered Address" address={props.submittedAddress} />
          </LabeledSelector>
          <LabeledSelector
            type="radio"
            name="address"
            value="suggested"
            className={styles.labeledSelector}
            checked={selectedAddress === 'suggested'}
          >
            <Address
              label="Verified Address"
              address={props.suggestedAddress}
            />
          </LabeledSelector>
        </div>
        <Divider width={1} color="Charcoal0" />
        <div className={styles.buttons}>
          <Button type="button" onClick={handleConfirm}>
            Confirm Address
          </Button>
          <Button
            className={styles.cancel}
            type="button"
            variant="plain-text"
            onClick={props.onCancel}
          >
            <Text variant="heading-16" bold>
              Cancel
            </Text>
          </Button>
        </div>
      </fieldset>
    </Modal>
  );
}
