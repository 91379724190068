import classNames from 'classnames';

import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import styles from './DidYouKnowBox.module.css';

import type { ReactSVGElement, SVGProps } from 'react';

interface DidYouKnowBoxProps {
  className?: string;
  Svg?: (props: SVGProps<SVGSVGElement>) => ReactSVGElement;
  children?: React.ReactNode;
  textColor?: 'green' | 'blue';
}

export function DidYouKnowBox({
  className,
  children,
  Svg,
  textColor = 'blue',
}: DidYouKnowBoxProps) {
  return (
    <Grid
      className={classNames(styles.container, className)}
      gap={{ xs: 'md', md: 'xl' }}
      flexDirection="column"
    >
      <GridItem flexDirection="column" className={styles.textsContainer}>
        <Text
          className={styles.title}
          bold
          as="h2"
          vSpacing="none"
          color={textColor === 'green' ? 'kale-3' : 'blueberry-3'}
          variant="heading-16"
          aria-label="Did you know?"
        >
          Did you know?
        </Text>
        <Text
          className={styles.subtext}
          variant="heading-22"
          color={textColor === 'green' ? 'kale-3' : 'blueberry-3'}
          bold
        >
          {children}
        </Text>
      </GridItem>
      {Svg && (
        <GridItem className={styles.icon}>
          <Svg aria-hidden="true" />
        </GridItem>
      )}
    </Grid>
  );
}
