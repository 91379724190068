import { Text } from '@farmersdog/corgi-x';
import type { ReactNode } from 'react';
import styles from './Badge.module.css';

export interface BadgeProps {
  children: ReactNode;
}

/**
 * Render a badge with a background and text intended to be positioned on top of a card.
 */
export function Badge({ children }: BadgeProps) {
  return (
    <div className={styles.background}>
      <Text variant="heading-12" color="white">
        {children}
      </Text>
    </div>
  );
}
