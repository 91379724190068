import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../blueprint/utils';
import { MAX_NUMBER_OF_PETS } from '../validation/constants';

import type { FormFieldsType } from '../types';

interface GeneratePetNamesArgs {
  formValues: FormFieldsType;
  currentNodeName: string;
}

export function getPetNamesFromFormEntries({
  formValues,
  currentNodeName,
}: GeneratePetNamesArgs) {
  const petNameRegExp = new RegExp(`${NodeNames.Name}-[0-9]*`);
  const petNames = [];
  const numActivePets = formValues[NodeNames.NumPets] ?? MAX_NUMBER_OF_PETS;

  for (const [key, value] of Object.entries(formValues)) {
    if (petNameRegExp.test(key) && petNames.length < numActivePets) {
      const petName = typeof value === 'string' ? value : String(value);
      petNames.push(petName);
    }
  }

  const { position } = getNodeNameAndPosition(currentNodeName);
  const petNameField = `name-${position}` as const;
  const currentPetName = formValues[petNameField] ?? '';

  return { petNames, currentPetName };
}
