/* CH#49502: Used by CVR */
import Picture from 'src/components/Picture';

import type { LogoProps } from './Logos.types';

import FoxPng from 'src/assets/images/logos/fox.png';
import FoxWebP from 'src/assets/images/logos/fox.webp';

export function FoxLogo(props: LogoProps) {
  return <Picture sources={[FoxWebP, FoxPng]} alt="Fox Logo" {...props} />;
}
