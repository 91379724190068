import { NodeNames } from '@farmersdog/constants';

import { formatBreeds } from '../../../utils/formatters/formatBreeds';

import type { Position } from '../../../blueprint/types';
import type { FormFieldsType } from '../../../types';

interface GetPetInfoArgs {
  formValues: FormFieldsType;
  petPosition: Position;
}

export interface GetPetInfoReturn {
  name: string;
  gender: string;
  birthday: {
    amount: number | null;
    unit: string | null;
  };
  breeds: string | null;
  weight: number | null;
  bodyCondition: string;
}

export function getPetInfo({
  formValues,
  petPosition,
}: GetPetInfoArgs): GetPetInfoReturn {
  return {
    name: formValues[`${NodeNames.Name}-${petPosition}`] ?? '',
    gender: formValues[`${NodeNames.Gender}-${petPosition}`] ?? '',
    birthday: {
      amount: formValues[`${NodeNames.BirthdayAmount}-${petPosition}`] ?? null,
      unit: formValues[`${NodeNames.BirthdayUnit}-${petPosition}`] ?? null,
    },
    breeds: formatBreeds(
      formValues[`${NodeNames.Breeds}-${petPosition}`] ?? null
    ),
    weight: formValues[`${NodeNames.Weight}-${petPosition}`] ?? null,
    bodyCondition:
      formValues[`${NodeNames.BodyCondition}-${petPosition}`] ?? '',
  };
}
