import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import {
  CLINIC_SELECTOR_DEFAULT_PLACEHOLDER,
  CLINIC_SEARCH_DELAY,
  MAX_RESULTS_FOR_INITIAL_LOADING,
} from '../../constants';
import { useFindClinic, useGetShippingAddress } from '../../network';
import classNames from 'classnames';
import styles from './ClinicSelector.module.css';
import { ActivityIndicator, Input, Text, Button } from '@farmersdog/corgi-x';
import type { FindClinicQuery } from '../../network/FindClinic.cgs.generated-types';
import { trackStartedSearch } from '../../analytics';

export type FoundClinic = FindClinicQuery['petHealth']['findClinic'][number];

export interface ClinicSelectorProps {
  onClinicSelected: (clinic: FoundClinic, searchTerm: string) => void;
  selectedClinic?: FoundClinic;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  searchResults: FoundClinic[];
  setSearchResults: (arg: FoundClinic[]) => void;
  className?: string;
  placeholder?: string;
  addVetClinic: () => void;
}

interface SearchResultProps {
  loadMore: boolean;
  searchTerm: string;
  searchResults: FoundClinic[];
  onClinicSelected: (clinic: FoundClinic, searchTerm: string) => void;
  selectedClinic?: FoundClinic;
}

export function ClinicSelector({
  onClinicSelected,
  selectedClinic,
  searchTerm,
  setSearchTerm,
  searchResults,
  setSearchResults,
  className,
  placeholder = CLINIC_SELECTOR_DEFAULT_PLACEHOLDER,
  addVetClinic,
}: ClinicSelectorProps) {
  const [loadMore, setLoadMore] = useState(false);
  const { findClinic, loading: findClinicLoading } = useFindClinic();
  const { data: shippingAddressResponse } = useGetShippingAddress();
  const zip = shippingAddressResponse?.customer?.shippingAddress?.zip || '';
  /* TODO: why setting zipcode null will cause invalid parameter error on graphql?
   * Ticket: https://app.shortcut.com/farmersdog/story/126419/investigate-a-string-type-param-used-by-graphql
   */

  const updateSearchTerm = useCallback(
    async (value: string) => {
      setSearchTerm(value);
      setSearchResults([]);
      setLoadMore(false);

      if (value.length >= 3) {
        try {
          const { data: resultClinics } = await findClinic({
            variables: {
              searchTerm: value,
              zipcode: zip,
            },
          });

          trackStartedSearch();

          const findClinicArray = resultClinics?.petHealth?.findClinic;

          if (!findClinicArray || findClinicArray?.length === 0) {
            setSearchResults([]);
            return;
          }

          setSearchResults(findClinicArray);
        } catch {
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    },
    [findClinic, setSearchResults, setSearchTerm, zip]
  );

  const changeValue = debounce(updateSearchTerm, CLINIC_SEARCH_DELAY);

  const toggleLoadMore = () => setLoadMore(!loadMore);

  return (
    <section className={classNames([styles.container, className])}>
      <div className={styles.selectorContainer}>
        <Input
          type="text"
          color="charcoal-3"
          label={placeholder}
          value={searchTerm}
          onChange={e => changeValue(e.target.value)}
          aria-controls="clinicInfo"
        />
        <div aria-live="polite" id="clinicInfo" aria-label="search results">
          {searchResults.length > 0 && (
            <SearchResults
              loadMore={loadMore}
              searchTerm={searchTerm}
              searchResults={searchResults}
              onClinicSelected={onClinicSelected}
              selectedClinic={selectedClinic}
            />
          )}
          {!loadMore &&
            searchResults.length > MAX_RESULTS_FOR_INITIAL_LOADING && (
              <LoadMoreButton toggleLoadMore={toggleLoadMore} />
            )}
          {!findClinicLoading &&
            searchResults.length === 0 &&
            searchTerm.length > 0 && <NoResults />}
          {findClinicLoading && <LoadingDots />}
          {searchTerm.length > 0 && !findClinicLoading && (
            <AddVetClinicSection addVetClinic={addVetClinic} />
          )}
        </div>
      </div>
    </section>
  );
}

const SearchResults = ({
  loadMore,
  searchTerm,
  searchResults,
  onClinicSelected,
  selectedClinic,
}: SearchResultProps) => (
  <ul className={styles.clinicList}>
    {(loadMore
      ? searchResults
      : searchResults.slice(0, MAX_RESULTS_FOR_INITIAL_LOADING)
    ).map(clinicItem => (
      <li
        key={clinicItem.id}
        onClick={() => onClinicSelected(clinicItem, searchTerm)}
        className={classNames(
          styles.clinic,
          clinicItem.id === selectedClinic?.id && styles.selected
        )}
        aria-label={clinicItem.name}
      >
        <div>
          <Text
            variant="heading-16"
            color="charcoal-3"
            className={styles.clinicTitle}
          >
            {clinicItem.name}
          </Text>
          <Text variant="heading-12" color="charcoal-2">
            {clinicItem.formattedAddress}
          </Text>
        </div>
      </li>
    ))}
  </ul>
);

const LoadMoreButton = ({ toggleLoadMore }: { toggleLoadMore: () => void }) => (
  <div className={styles.loadMore} onClick={toggleLoadMore}>
    <Text variant="heading-16" color="carrot-2" bold>
      Load More
    </Text>
  </div>
);

const NoResults = () => (
  <div className={styles.clinicList}>
    <div className={styles.center}>
      <Text variant="heading-16" color="charcoal-3">
        No results
      </Text>
    </div>
  </div>
);

const LoadingDots = () => (
  <div className={styles.clinicList} data-testid="findClinicLoading">
    <div className={styles.center}>
      <ActivityIndicator mode="dark" />
    </div>
  </div>
);

const AddVetClinicSection = ({
  addVetClinic,
}: {
  addVetClinic: () => void;
}) => (
  <div className={styles.clinicListFooter}>
    <Text as="h3" variant="heading-16" bold topSpacing="md">
      Not seeing your clinic?
    </Text>
    <Button
      variant="plain-text"
      className={styles.addVetClinic}
      onClick={addVetClinic}
    >
      Add Vet Clinic
    </Button>
  </div>
);
