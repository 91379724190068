import isEmpty from 'lodash/isEmpty';

import { getSegmentIntegrations } from './getSegmentIntegrations';

import type { SegmentLoggingProperties } from './getSegmentIntegrations';

export function getSegmentOpts({
  event,
  eventType,
}: SegmentLoggingProperties): SegmentAnalytics.SegmentOpts | undefined {
  const opts: SegmentAnalytics.SegmentOpts = {
    integrations: getSegmentIntegrations({ event, eventType }),
  };

  return isEmpty(opts) ? undefined : opts;
}
