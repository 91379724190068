import { useController } from 'react-hook-form';

import { NodeNames } from '@farmersdog/constants';
import { useToast } from '@farmersdog/corgi';

import { MAX_SELECTED_RECIPES } from '../RecipesSelection/RecipesSelection';

import type {
  IndexedPetFreshSelectionFields,
  Position,
} from '../../../blueprint/types';
import type { FormFieldsType, UseForm } from '../../../types';
import type { ChangeEvent } from 'react';

interface UseControlFreshSelectionArgs {
  petPosition: Position;
  control: UseForm['control'];
  currentSelection: string[];
  toggleSelection: () => void;
}

export function useControlFreshSelection({
  control,
  petPosition,
  currentSelection,
  toggleSelection,
}: UseControlFreshSelectionArgs) {
  /**
   * Since ToggleCard needs to control the html input checked attribute,
   * we use react-hook-form's controller feature to handle the state update
   * https://codesandbox.io/s/usecontroller-checkboxes-99ld4?file=/src/App.js
   */
  const { field } = useController<
    FormFieldsType,
    keyof IndexedPetFreshSelectionFields
  >({
    name: `${NodeNames.FreshSelection}-${petPosition}`,
    control,
  });

  const maxRecipesSelected = currentSelection.length >= MAX_SELECTED_RECIPES;

  const dispatchToast = useToast();

  const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newSelectedRecipes = [...currentSelection];
    const value = e.target.value;
    const isNewValue = !newSelectedRecipes.includes(value);

    if (isNewValue && maxRecipesSelected) {
      dispatchToast({
        variant: 'neutral',
        children: `You have ${MAX_SELECTED_RECIPES} recipes selected. Please deselect a recipe before choosing another.`,
      });
      return;
    }

    if (!isNewValue) {
      newSelectedRecipes.splice(newSelectedRecipes.indexOf(value), 1);
    } else {
      newSelectedRecipes.push(value);
    }

    toggleSelection();
    field.onChange(newSelectedRecipes);
  };

  return {
    freshSelectionControlledField: field,
    handleSelectionChange,
  };
}
