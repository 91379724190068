import type { ReactNode } from 'react';
import { Text, Divider } from '@farmersdog/corgi-x';

import styles from './Quote.module.scss';

export interface QuoteProps {
  /** The quote content */
  quote: ReactNode;
  /** The author of the quote */
  author: ReactNode;
}

export function Quote({ quote, author }: QuoteProps) {
  return (
    <blockquote className={styles.blockquote}>
      <Divider
        orientation="vertical"
        color="Kale1"
        width={1}
        className={styles.divider}
      />
      <div>
        <Text
          as="p"
          variant="heading-28"
          color="charcoal-1"
          bold
          className={styles.quote}
        >
          “<br />
          {quote}
        </Text>
        <Text
          as="cite"
          variant="heading-16"
          color="charcoal-1"
          className={styles.cite}
        >
          {author}
        </Text>
      </div>
    </blockquote>
  );
}
