import type { EligibilityHook, SlottableComponent } from '../types';

/**
 * Creates a "slottable" version of a given component,
 * enabling it for use within a Slot component.  If the
 * component for any reason already has the object-level
 * property `useEligibility`, it will be overwritten.
 */
export function createSlottableComponent<P extends NonNullable<unknown>>(
  Component: React.ComponentType<P>,
  useEligibility: EligibilityHook
): SlottableComponent<P> {
  // @ts-expect-error - This is a hack to clone the component function, which is poorly handled in TS
  const ClonedComponent = Component.bind(undefined) as React.ComponentType<P>;
  return Object.assign(ClonedComponent, { useEligibility });
}
