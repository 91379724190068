import { LINK_DIGEST_RECIPES_HOME } from '@farmersdog/constants/links';
import { PATH_RESCHEDULE } from '@farmersdog/constants/paths';
import { Link, Text } from '@farmersdog/corgi-x';
import { CheckCircle, CircleAlert } from '@farmersdog/corgi-x/icons';
import { CARROT_2, KALE_3 } from '@farmersdog/corgi/build/constants';
import type { ReactNode } from 'react';
import { StockStatus } from '../../../graphql/types.core-api';
import { pluralizeString, setPathQueries } from '../../../utils';
import styles from './StockStatusMessage.module.css';
import { QueryParameter } from '@farmersdog/constants';
import { useLocation } from 'react-router';

const ICON_SIZE = 36;

export interface StockStatusMessageProps {
  stockStatus: StockStatus;
  canRescheduleNextOrder: boolean;
  projectedStockAtDate: number | undefined;
  isNextOrderScheduledForRecommendation: boolean;
}
export function StockStatusMessage(props: StockStatusMessageProps) {
  const {
    canRescheduleNextOrder,
    stockStatus,
    projectedStockAtDate,
    isNextOrderScheduledForRecommendation,
  } = props;
  let content: ReactNode;
  let icon: ReactNode;
  let ctaButton: ReactNode;
  const location = useLocation();
  const RESCHEDULE_PATH = setPathQueries(PATH_RESCHEDULE, {
    [QueryParameter.PrevPath]: location.pathname,
    [QueryParameter.EntryPoint]: location.pathname,
  });
  const rescheduleLink = (
    <Link to={RESCHEDULE_PATH} className={styles.cta}>
      <Text variant="heading-16" bold color="carrot-2">
        Reschedule
      </Text>
    </Link>
  );

  switch (stockStatus) {
    case StockStatus.Warning: {
      break;
    }
    case StockStatus.Ok: {
      icon = <CheckCircle aria-hidden fill={KALE_3} size={ICON_SIZE} />;
      content = (
        <Text as="div" variant="heading-16">
          Your next order will arrive when you need it.
        </Text>
      );
      break;
    }
    case StockStatus.Shortage: {
      icon = <CircleAlert aria-hidden fill={CARROT_2} size={ICON_SIZE} />;
      content = (
        <>
          <Text as="div" variant="heading-16">
            {isNextOrderScheduledForRecommendation || !canRescheduleNextOrder
              ? 'It looks like you’ll run out of food before your next order!'
              : 'Rush your next order so you don’t run out of food.'}
            {(!canRescheduleNextOrder ||
              isNextOrderScheduledForRecommendation) && (
              <>
                {' '}
                <Link
                  href={LINK_DIGEST_RECIPES_HOME}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Check out these recipes
                </Link>{' '}
                to cook at home.
              </>
            )}
          </Text>
        </>
      );
      ctaButton =
        canRescheduleNextOrder &&
        !isNextOrderScheduledForRecommendation &&
        rescheduleLink;
      break;
    }
    case StockStatus.Overage: {
      if (!canRescheduleNextOrder) {
        return null;
      }
      const delayYourBoxText = 'Delay your box to free up freezer space.';
      const numPacksText = projectedStockAtDate
        ? `You'll have ${pluralizeString(
            projectedStockAtDate,
            'pack',
            'packs',
            { prependQuantity: true }
          )} when your next order arrives. ${delayYourBoxText}`
        : delayYourBoxText;
      ctaButton = rescheduleLink;
      icon = <CircleAlert aria-hidden fill={CARROT_2} size={ICON_SIZE} />;
      content = (
        <Text as="div" variant="heading-16">
          {numPacksText}
        </Text>
      );
      break;
    }
  }

  return (
    <section data-testid="stockStatusMessage" className={styles.root}>
      <div className={styles.flexbox}>
        <div className={styles.flexboxStart}>{icon}</div>
        <div>{content}</div>
      </div>
      {ctaButton}
    </section>
  );
}
