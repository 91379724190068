import { PAW_PATH_MY_DOGS } from '@farmersdog/constants/paths';
import { Link, Picture, Text } from '@farmersdog/corgi-x';
import { recipesGroupPictureSources } from '../../../assets/recipes-group';
import {
  formatCurrency,
  formatDecimalToFraction,
  pluralizeString,
} from '../../../utils';
import { trackPetManageClicked } from '../../analytics';
import type {
  PetFreshFoodPlanFieldsFragment,
  PetFreshFoodRecipeFieldsFragment,
} from '../../network';
import styles from './Pet.module.css';
import { useTrackPricesSeen } from '../../../analytics/useTrackPricesSeen';

export const PET_NAME_PARAM = 'petName';

interface PetProps {
  petId: number;
  name: string;
  plan: PetFreshFoodPlanFieldsFragment;
  recipes: PetFreshFoodRecipeFieldsFragment[];
  dailyConsumptionPrice: number;
}

export function Pet(props: PetProps) {
  const handleOnManageCLick = () => {
    trackPetManageClicked({
      petId: props.petId,
      petName: props.name,
    });
  };

  const linkToMyDogsCard = `${PAW_PATH_MY_DOGS}?${PET_NAME_PARAM}=${props.name}`;

  useTrackPricesSeen(
    props.dailyConsumptionPrice
      ? {
          petId: props.petId,
          dailyPrice: props.dailyConsumptionPrice,
        }
      : {}
  );

  return (
    <div>
      <Text
        variant="heading-22"
        bold
        color="kale-3"
        as="h3"
        className={styles['pet-name-top']}
      >
        For {props.name}
      </Text>
      <div className={styles.container}>
        <Picture
          sources={recipesGroupPictureSources}
          alt=""
          className={styles['recipes-group-picture']}
        />
        <div>
          <Text
            variant="heading-22"
            bold
            color="kale-3"
            className={styles['pet-name-bottom']}
          >
            For {props.name}
          </Text>
          <Text variant="heading-16" color="charcoal-3" as="p" vSpacing="none">
            {props.recipes.map(recipe => (
              <span key={recipe.id}>
                {recipe.displayName}
                {props.recipes.indexOf(recipe) !== props.recipes.length - 1
                  ? ', '
                  : ''}
              </span>
            ))}
          </Text>
          <Text variant="heading-16" color="charcoal-3" as="p" vSpacing="none">
            {`${props.plan.dailyFreshCalories} cals (${formatDecimalToFraction(
              props.plan.dailyPacksConsumed
            )}
            ${pluralizeString(
              props.plan.dailyPacksConsumed,
              'pack',
              'packs'
            )}/day)`}
          </Text>
          <Text variant="heading-16" color="charcoal-3" as="p" vSpacing="none">
            {Math.floor(props.plan.freshFoodRatio * 100)}% fresh food plan
          </Text>
          <Text variant="heading-16" color="charcoal-3" as="p" vSpacing="none">
            {`${formatCurrency(props.dailyConsumptionPrice)}/day`}
          </Text>
          <Link
            to={linkToMyDogsCard}
            onClick={handleOnManageCLick}
            aria-label={`Manage ${props.name}`}
          >
            <Text
              variant="heading-16"
              bottomSpacing="none"
              topSpacing="sm"
              as="p"
            >
              Manage
            </Text>
          </Link>
        </div>
      </div>
    </div>
  );
}
