import {
  Accordion,
  AccordionPanel,
  Button,
  ContentLoader,
  Section,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import { useId } from 'react';

import { PATH_CALIBRATE_PACKS } from '@farmersdog/constants/paths';
import { Content } from './features/';
import type {
  PacksAndPortionsFeedingGuideFragment,
  PacksAndPortionsFoodPlanFragment,
  PacksAndPortionsPetFragment,
} from './network/fragments';
import styles from './PacksAndPortions.module.css';

interface PacksAndPortionsSectionProps {
  /** The user's active pets */
  pets: PacksAndPortionsPetFragment[] | null | undefined;
  /** The pet's food plans */
  foodPlans: PacksAndPortionsFoodPlanFragment[] | null | undefined;
  /** The feeding guides associated to the pets */
  feedingGuides:
    | Array<PacksAndPortionsFeedingGuideFragment | null>
    | null
    | undefined;
  /** Loading state */
  loading: boolean;
}

export function PacksAndPortionsSection({
  pets,
  foodPlans,
  feedingGuides,
  loading,
}: PacksAndPortionsSectionProps) {
  const { medium } = useViewport();
  const loadingHeight = medium ? 241 : 261;

  const headingId = useId();
  const accordionId = useId();

  return (
    <Section variant="card" vSpacing="md" aria-labelledby={headingId}>
      <Text id={headingId} as="h2" color="kale-3" variant="heading-28" bold>
        How You’re Feeding
      </Text>
      <Text as="p" variant="article-16" topSpacing="sm" bottomSpacing="md">
        Keep this current so your orders arrive right when you need them.
      </Text>
      <ContentLoader mode="dark" height={loadingHeight} loading={loading}>
        <Accordion>
          {(pets || []).map((pet, i) => {
            const petFeedingGuide = feedingGuides?.find(
              feedingGuide => feedingGuide?.petId === pet.id
            );
            const petFoodPlan = foodPlans?.find(
              foodPlan => foodPlan.id === petFeedingGuide?.planId
            );
            return (
              <AccordionPanel
                expanded
                key={pet.name}
                id={`${accordionId}-${i}`}
                heading={pet.name}
              >
                <Content
                  petName={pet.name}
                  feedingGuide={petFeedingGuide}
                  foodPlan={petFoodPlan}
                />
              </AccordionPanel>
            );
          })}
        </Accordion>
        <Button
          type="link"
          variant="primary"
          to={PATH_CALIBRATE_PACKS}
          className={styles.cta}
        >
          Edit How You’re Feeding
        </Button>
      </ContentLoader>
    </Section>
  );
}
