import { hashSha } from '@farmersdog/utils';

import { Pixel } from '../../Pixel';

import { sharedPinterestAttributes } from './constants';

import type { VendorAttributes } from '../../Pixel';

/**
 * The base attributes for the Pinterest vendor
 */
export const vendorAttributes = {
  baseUrl: 'https://ct.pinterest.com/v3/',
  vendorName: 'Pinterest',
};

// just in case we need to add event data to any other events down the road
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface PinterestEventData {}

export interface PinterestCheckoutEventData extends PinterestEventData {
  value: string;
  currency: string;
  order_id: string | undefined;
}

/**
 * An interface representing the freeform attributes that can be submitted in a
 * Pinterest pixel event
 */
export interface PinterestEventAttributes extends Record<string, unknown> {
  tid: string;
  noscript: string;
  event: 'init' | 'checkout' | 'pagevisit' | 'lead';
  ed?: PinterestEventData;
}

export interface EnhancedMatchAttributes {
  pd?: {
    em?: string;
  };
}
export class PinterestPixel extends Pixel<PinterestEventAttributes> {
  private baseCodeTriggered = false;

  private enhancedMatchAttributes: EnhancedMatchAttributes = {};

  constructor(attributes: VendorAttributes) {
    super(attributes);
  }

  mount(attributes: PinterestEventAttributes) {
    if (!this.baseCodeTriggered) {
      this.mountBaseCode();
    }
    super.mount({ ...attributes, ...this.enhancedMatchAttributes });
  }

  async setEnhancedMatchAttributes(email: string | undefined) {
    if (email && email !== '') {
      this.enhancedMatchAttributes.pd = {
        em: await hashSha({ stringToHash: email }),
      };
    }
  }

  private mountBaseCode() {
    super.mount({
      ...sharedPinterestAttributes,
      ...this.enhancedMatchAttributes,
      event: 'init',
    });
    this.baseCodeTriggered = true;
  }
}

export const pinterest = new PinterestPixel(vendorAttributes);
