import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { useLocation } from 'react-router-dom';

import { browserOnly } from '@farmersdog/utils';

import { windowProperty } from './constants';
import { readRequestAttributes } from './utils/readRequestAttributes';

import type { RequestAttributes } from './types';

declare global {
  interface Window {
    [windowProperty]?: RequestAttributes;
  }
}

export function useRequestAttributes() {
  const windowRequestAttributes = window?.[windowProperty];
  const referrer_url = document?.referrer;

  const { search } = useLocation();
  // TODO: replace logger with DD when we add it
  const queryRequestAttributes = readRequestAttributes({
    queryString: search,
    referrer_url,
    logger: noop,
  });

  browserOnly(window => {
    if (!isEmpty(queryRequestAttributes)) {
      window[windowProperty] = queryRequestAttributes;
    }
  });

  return windowRequestAttributes ?? queryRequestAttributes;
}
