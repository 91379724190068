import type { ReferrerCookie } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { DIRECT_TRAFFIC_CODE } from '@farmersdog/tosa/src/hooks/useGlobalDiscountOverride';

const DEFAULT_REFERRER_COOKIE = {
  couponCode: DIRECT_TRAFFIC_CODE,
  referrerCode: DIRECT_TRAFFIC_CODE,
  referrerType: '',
};

export function getReferrerCookieSplitAttributes(
  referrerCookie: ReferrerCookie | undefined
) {
  if (!referrerCookie) {
    return DEFAULT_REFERRER_COOKIE;
  }

  const isReferrerCookieValid = isValidReferrerCookie(referrerCookie);

  if (!isReferrerCookieValid) {
    return DEFAULT_REFERRER_COOKIE;
  }

  return {
    // TODO: Eventually want to move to this nomenclature, but for now we need to keep referrerCode for backwards compatibility
    couponCode: referrerCookie.referrerCode ?? DIRECT_TRAFFIC_CODE,
    referrerCode: referrerCookie.referrerCode ?? DIRECT_TRAFFIC_CODE,
    referrerType: referrerCookie.referrerType ?? '',
  };
}

function isValidReferrerCookie(value: unknown): value is ReferrerCookie {
  return Boolean(
    value && Object.prototype.hasOwnProperty.call(value, 'referrerCode')
  );
}
