import type { LogsInitConfiguration } from '@datadog/browser-logs';
import { StatusType } from '@datadog/browser-logs';

type DatadogConfiguration = Omit<LogsInitConfiguration, 'site'> & {
  enabled: boolean;
};

// we're not currently supporting all the possible StatusType values from the DataDog logger package
export type LogLevel =
  | typeof StatusType.debug
  | typeof StatusType.info
  | typeof StatusType.warn
  | typeof StatusType.error;

export const LogLevel = {
  [StatusType.debug]: StatusType.debug,
  [StatusType.info]: StatusType.info,
  [StatusType.warn]: StatusType.warn,
  [StatusType.error]: StatusType.error,
} as const;

export interface TfdLoggerConfig {
  level: LogLevel;
  datadog: DatadogConfiguration | undefined;
}

/**
 * A class to keep track of logger configuration properties
 */
class Config {
  private levelValue: LogLevel;
  private datadogValue: DatadogConfiguration | undefined;
  private isSetValue: boolean;
  private setCallbacks: (() => void)[];

  constructor() {
    this.levelValue = 'debug';
    this.datadogValue = undefined;
    this.isSetValue = false;
    this.setCallbacks = [];
  }

  setConfig(config: TfdLoggerConfig) {
    this.levelValue = config.level;
    this.datadogValue = config.datadog;
    this.isSetValue = true;

    this.setCallbacks.forEach(callback => callback());
    this.setCallbacks = [];
  }

  onSet(callback: () => void) {
    if (this.isSet) {
      callback();
      return;
    }

    this.setCallbacks.push(callback);
  }

  get isSet() {
    return this.isSetValue;
  }

  get level() {
    return this.levelValue;
  }

  get datadog() {
    return this.datadogValue;
  }
}

/**
 * A singleton to remember config setting across logger instantiations
 */
export const config = new Config();
