/* This is a list of segment track event names for use in TOSA only. 
It is very important to note that for any existing events moved into TOSA from website,
the track event name must match EXACTLY. I.e. any event name taken from `eventNames.js`
in the website analytics folder must match anything in TOSA exactly. Any new values only
to be tracked in TOSA can be added here as well.
*/

export const segmentTrackEvents = {
  pets_success: 'Pets - Success',
  recipes_click_back_to_recommended_plan:
    'Recipes - Click Back To Recommended Plan',
  recipes_click_continue_with_recommendation:
    'Recipes - Click Continue With Recommendation',
  recipes_click_select_your_own_plan: 'Recipes - Click Select Your Own Plan',
  recipes_success: 'Recipes - Success',
  recipes_plan_details: 'Recipes - Plan Details',
  recipes_viewed_recipe_details: 'Recipes - Viewed Recipe Details',
  recipes_price_value_modal_click: 'Recipes Page - Opened Why This Price Modal',
  toggle_product_line_click: 'Toggle Product Line - Click',
  tiktok_page_view: 'TikTok Page View',
  redirect_if_node_removed: 'Redirect If Node Removed',
  suggest_email_correction: 'Suggest Email Correction',
  email_correction_accepted: 'Email Correction Accepted',
  signup_surprise_higher_discount: 'Signup - Surprise Higher Discount',
  signup_free_treats_add_treat_to_cart:
    'Signup Free Treats - Add Treat to Cart',
  signup_free_treats_clicked_skip: 'Signup Free Treats - Clicked Skip',
  signup_free_treats_remove_treat_from_cart:
    'Signup Free Treats - Remove Treat from Cart',
  signup_free_treats_open_nutrition_facts:
    'Signup Free Treats - Open Nutrition Facts',
  signup_free_treats_success: 'Treats - Success',
  signup_free_treats_scroll_testimonials:
    'Signup Free Treats - Scrolled Testimonials',
};
