import { useMemo } from 'react';
import * as yup from 'yup';

import { getNodeById } from '../blueprint/utils';
import { generateValidationRules } from '../validation/generateValidationRules';
import { validationSchema } from '../validation/yupValidation';

import type { NodeRegistry, BranchNode } from '../blueprint/types';

interface UseFormValidationProps {
  current: string | undefined;
  root: BranchNode | undefined;
  registry: NodeRegistry | undefined;
}

const emptyYupSchema = yup.object().shape({});

export function useFormValidation({
  current,
  root,
  registry,
}: UseFormValidationProps) {
  const formValidationSchema = useMemo(() => {
    if (!root) {
      return emptyYupSchema;
    }

    const validationRules = Object.fromEntries(
      generateValidationRules({
        node: root,
        validationSchema,
      })
    );

    return yup.object().shape(validationRules);
  }, [root]);

  const branchValidationSchema = useMemo(() => {
    if (!(current && registry)) {
      return emptyYupSchema;
    }

    const currentNode = getNodeById(current, registry);
    const validationRules = Object.fromEntries(
      generateValidationRules({
        node: currentNode,
        validationSchema,
      })
    );

    return yup.object().shape(validationRules);
  }, [current, registry]);

  return {
    branchValidationSchema,
    formValidationSchema,
  };
}
