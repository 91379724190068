import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';

import { ResponsiveText } from '@farmersdog/corgi';
import { KALE_3, CARROT_2 } from '@farmersdog/corgi/constants';
import { ContentReveal, Text } from '@farmersdog/corgi-x';

import { Picture } from '../Picture';

import { AnimatedEllipsis } from './AnimatedEllipsis';
import { turkeyBowls, kibbleBowls } from './assets';
import ArrowDown from './assets/arrow-down.svg';
import ArrowLeft from './assets/arrow-left.svg';
import ArrowRight from './assets/arrow-right.svg';
import ArrowUp from './assets/arrow-up.svg';
import CircleX from './assets/circle-x.svg';
import Food from './assets/food.svg';
import styles from './Comparison.module.css';

import type { CalculatingScreenContentProps } from './CalculatingScreen';

const ANIMATE_OUT_TIME = 300;
const CALCULATING_SCREEN_DURATION = 8000;

interface ContentRevealChildrenProps {
  className?: string;
}

function TheirFood({ className }: ContentRevealChildrenProps) {
  return (
    <div className={classNames(styles.content, styles.theirs, className)}>
      <CircleX
        className={classNames(styles.icon, styles.circle)}
        fill={KALE_3}
      />
      <Text variant="heading-22" color="kale-3" bold>
        Not Food
      </Text>
      <Text variant="article-16" color="kale-3">
        Highly-processed, burnt brown pellets.
      </Text>
      <ArrowLeft className={styles.arrowLeft} fill={KALE_3} />
      <ArrowUp className={styles.arrowUp} fill={KALE_3} />
    </div>
  );
}

function OurFood({ className }: ContentRevealChildrenProps) {
  return (
    <div className={classNames(styles.content, styles.ours, className)}>
      <Food className={classNames(styles.icon, styles.food)} fill={CARROT_2} />
      <Text variant="heading-22" color="kale-3" bold>
        Our Food
      </Text>
      <Text variant="article-16" color="kale-3">
        Made fresh. Quickly frozen.
      </Text>
      <ArrowRight className={styles.arrowRight} fill={KALE_3} />
      <ArrowDown className={styles.arrowDown} fill={KALE_3} />
    </div>
  );
}

export function Comparison({ onCompleted }: CalculatingScreenContentProps) {
  const [showOurFood, setShowOurFood] = useState(false);
  const onCompletedRef = useRef(onCompleted);

  useEffect(() => {
    onCompletedRef.current = onCompleted;
  }, [onCompleted, onCompletedRef]);

  useEffect(() => {
    const swapTimeout = setTimeout(() => {
      setShowOurFood(true);
    }, CALCULATING_SCREEN_DURATION / 2);

    const completeTimeout = setTimeout(() => {
      onCompletedRef.current();
    }, CALCULATING_SCREEN_DURATION);

    return () => {
      clearTimeout(completeTimeout);
      clearTimeout(swapTimeout);
    };
  }, [onCompletedRef]);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <Picture
          sources={kibbleBowls}
          alt="Bowl of kibble"
          className={classNames(styles.kibble, {
            [styles.inactive]: showOurFood,
          })}
        />
        <Picture
          sources={turkeyBowls}
          alt="Bowl of The Farmer's Dog turkey recipe"
          className={classNames(styles.turkey, {
            [styles.inactive]: !showOurFood,
          })}
        />
        <ResponsiveText
          className={styles.headline}
          color="kale-3"
          variant="heading-2"
        >
          Calculating
          <AnimatedEllipsis time={1000} />
        </ResponsiveText>
        <ContentReveal in={!showOurFood} inAnimation="fade">
          <TheirFood />
        </ContentReveal>
        <ContentReveal
          in={showOurFood}
          inDelay={ANIMATE_OUT_TIME}
          inAnimation="fade"
        >
          <OurFood />
        </ContentReveal>
      </div>
    </div>
  );
}
