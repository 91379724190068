import type { MedicalConditionConfig } from '../../../inputs/PetHealthCheckboxes/MedicalConditionMessage/constants';

interface DoAnyMedicalCOnditionsDisplaySkipOptionArgs {
  medicalConditions: string | string[];
  medicalConditionsConfig: MedicalConditionConfig;
}

export function doAnyMedicalConditionsSupportSkipping({
  medicalConditions,
  medicalConditionsConfig,
}: DoAnyMedicalCOnditionsDisplaySkipOptionArgs): boolean {
  const medicalConditionsArray = Array.isArray(medicalConditions)
    ? medicalConditions
    : [medicalConditions];

  if (medicalConditionsArray.length === 0) {
    return false;
  }

  const dietWithSkipOption = medicalConditionsArray.find(diet => {
    const dietConfig = medicalConditionsConfig[diet.toLowerCase()];
    return Boolean(dietConfig?.displaySkipOption);
  });
  return !!dietWithSkipOption;
}
