import type { ReactNode } from 'react';
import { Button, Link } from '@farmersdog/corgi-x';
import type { RequestedSupportDestinations } from 'src/analytics';
import { trackRequestedSupport } from 'src/analytics';

import { EMAIL_HELP } from '../../constants/emails';

interface EmailSupportButtonProps {
  asLink?: boolean;
  subject: string;
  children: ReactNode;
  email?: string;
  destination?: RequestedSupportDestinations;
}

export function EmailSupportButton({
  asLink,
  subject,
  children,
  email,
  destination,
}: EmailSupportButtonProps) {
  const _email = email ?? EMAIL_HELP;
  const href = `mailto:${_email}?subject=${subject}`;
  const Component = asLink ? Link : Button;

  const handleEmailSupportClick = () => {
    trackRequestedSupport({
      supportChannel: 'email',
      destination,
    });
  };

  return (
    <Component
      type="anchor"
      target="_blank"
      href={href}
      rel="noopener noreferrer"
      onClick={handleEmailSupportClick}
    >
      {children}
    </Component>
  );
}
