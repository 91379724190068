import { couponCookie } from '@farmersdog/coupons';

import type { DiscountInput } from '../../../../graphql/types';

export function getDiscountInput(): DiscountInput | undefined {
  const cookie = couponCookie.get();

  if (!(cookie && cookie.referrerCode && cookie.referrerType)) {
    return undefined;
  }

  return {
    code: cookie.referrerCode,
    // @ts-expect-error Need to consolidate these enums
    type: cookie.referrerType,
    associatedAt: cookie.referrerAssociatedAt,
  };
}
