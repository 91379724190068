import { useMutation } from '@apollo/client';
import { GraphQLEndpoints } from 'src/graphql/Apollo/constants';
import type {
  CreateLeadMutation,
  CreateLeadMutationVariables,
} from 'src/graphql/tosa.types';

import createLeadMutation from './createLead.graphql';

/*
 * Hook to create a lead. Importantly, it also returns a tosa token to do authorized requests to other TOSA queries/mutations.
 */
export function useCreateLead() {
  return useMutation<CreateLeadMutation, CreateLeadMutationVariables>(
    createLeadMutation,
    {
      context: {
        endpoint: GraphQLEndpoints.TOSA,
      },
    }
  );
}
