import qs from 'qs';

import config from 'src/config';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import type { Referrer } from 'src/hooks/useReferrer';

import identify from '../identify';

interface IdentifyMeArgs {
  referrer: Omit<Referrer, 'discountPercentage' | 'invalid'>;
  couponCode: string | undefined;
  user: {
    email: string;
    id: number;
    firstName: string | null;
    pets: Array<Record<string, unknown>>;
    shippingAddress: { zip: string | null };
  };
}

export const queryParams = {
  utm_source: 'phoneSales',
  utm_campaign: 'phoneOutboundSales',
  c: 'phone50',
};

/**
 * Identify the user based on referrer characteristics and other data from the
 * signup me page
 *
 * @param referrer - The user referrer
 * @param user - The user object
 */
export function identifyMePage({ referrer, couponCode, user }: IdentifyMeArgs) {
  const userResumeLink = `${
    config('app.publicUrl') as string
  }${PATH_SIGNUP_ME}/${user.email}?${qs.stringify(queryParams)}`;

  identify(user, {
    discountType: referrer?.discountType,
    discountAmount: referrer?.discountAmount,
    // TODO: figure out if this prop must be added to returned referrer when globalDiscountOverride is on
    couponType: referrer?.type,
    couponCode,
    userResumeLink,
    userCRMLink: getCRMLink(user.id),
  });
}

/**
 * Return the CRM link for a user based on their id
 *
 * @param userId - The id of the user
 */
function getCRMLink(userId: number): string {
  return `${config('app.crmUrl') as string}/user/${userId}/account`;
}
