import { events, track } from 'src/analytics';
import type { SubscriptionType } from 'src/graphql/types';

export interface CancelSubscriptionFlowExitPayload {
  exitReason?: string;
  subscriptionType?: SubscriptionType;
}

export function trackCancelSubscriptionFlowExit({
  exitReason,
  subscriptionType,
}: CancelSubscriptionFlowExitPayload) {
  track(events.cancel_subscription_flow_exit, {
    cancellationVersion: 1,
    exitReason,
    subscriptionType,
  });
}
