import type { LeadView } from '../exports';
import type { OnStepCompleted } from './useHandleFormCompleted';

export function useHandleStepCompleted(
  websiteHandleStepCompleted?: OnStepCompleted
) {
  const handleStepCompleted = (step: string, lead: LeadView | null) => {
    if (websiteHandleStepCompleted) {
      websiteHandleStepCompleted(step, lead);
    }
  };

  return handleStepCompleted;
}
