import type {
  AddDogFormStepsType,
  AddDogState,
} from './../state-machine/AddDogState';
import { useState, useEffect } from 'react';

interface UseStepProgressProps {
  formSteps: AddDogFormStepsType;
  currentState: AddDogState;
}

export function useAddDogStepProgress(props: UseStepProgressProps): {
  currentStep: number;
  stepProgress: number;
  totalSteps: number;
} {
  const { formSteps, currentState } = props;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepProgress, setStepProgress] = useState<number>(0);
  const totalSteps = formSteps.length;
  useEffect(() => {
    let progressIncrement: number;
    let foundStep: number = 0;
    let foundStepProgress: number = 0;
    let stepFound: boolean = false;

    for (const step of formSteps) {
      const subStepIndex = step.subSteps.indexOf(currentState);
      if (subStepIndex !== -1) {
        foundStep = step.step;
        progressIncrement = 100 / step.subSteps.length;
        foundStepProgress = progressIncrement * subStepIndex;
        stepFound = true;
        break;
      }
    }

    if (!stepFound) {
      foundStep = 0;
      foundStepProgress = 0;
    }

    setCurrentStep(foundStep);
    setStepProgress(foundStepProgress);
  }, [currentState, formSteps]);

  return { currentStep, stepProgress, totalSteps };
}
