import { Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from './AnalysisTable.module.css';

interface AnalysisTableProps {
  heading: string;
  subheading?: string;
  children: ReactNode;
}

export function AnalysisTable({
  heading,
  subheading,
  children,
}: AnalysisTableProps) {
  return (
    <table className={styles.table}>
      <caption className={styles.caption}>{heading}</caption>
      <thead>
        <tr className={classNames(styles.tr, styles.headerRow)}>
          <th colSpan={2} className={styles.th}>
            <Text
              as="p"
              variant="article-16"
              color="charcoal-3"
              bold
              role="heading"
              className={styles.headerText}
            >
              {heading}
            </Text>
            {subheading && (
              <Text
                as="p"
                variant="article-16"
                color="charcoal-3"
                className={styles.headerText}
              >
                {subheading}
              </Text>
            )}
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
