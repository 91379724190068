import { useEffect } from 'react';
import { Loading } from '../../../components';
import { trackEnterDownsellFlow } from '../../analytics/trackEnterDownsellFlow';
import type { CancellationResponsesEnumType } from '../../flow-state-management';
import type { PetFieldsFragment } from '../../network';
import {
  LpfDownsellState,
  MixingPlanDownsell,
  useMixingPlanDownsellProgress,
} from './components';
import { LpfDownsellWrapper } from './components/LpfDownsell/LpfDownsellWrapper';
import { useGetLpfDownsellEligibility } from './network/useGetLPFDownsellEligibilityData';

interface PriceDownsellProps {
  reasonsHistory: CancellationResponsesEnumType[];
  currentWeeklyPrice: number;
  onPrevious: () => void;
  onContinueToCancel: () => void;
  onClose: () => void;
  pets: PetFieldsFragment[];
}

/**
 * Determines which price downsell flow to show based on the user's treatment
 */
export function PriceDownsell(props: PriceDownsellProps) {
  const request = useGetLpfDownsellEligibility();
  useEffect(() => {
    trackEnterDownsellFlow({
      reason: 'price',
    });
  }, []);

  const isMultiPet = request.data?.isMultiPet;
  const eligibleForLPF = request.data?.eligibleForLPF;
  const eligibleForLPFForReasonDownsell =
    request.data?.eligibleForLPFForReasonDownsell;

  const eligibleForLpfDownsell =
    (!eligibleForLPF || eligibleForLPFForReasonDownsell) && !isMultiPet;

  const mixingPlanSaveFlow = useMixingPlanDownsellProgress({
    onBackToCancellationReasons: () => props.onPrevious(),
    onContinueToCancel: props.onContinueToCancel,
    cancellationReasonsHistory: props.reasonsHistory,
  });

  if (request.loading) {
    return <Loading />;
  } else if (request.error) {
    throw request.error;
  } else if (!request.data) {
    throw new Error('Unknown exception occurred while loading data');
  } else {
    return (
      <>
        {eligibleForLpfDownsell ? (
          <LpfDownsellWrapper
            initialState={LpfDownsellState.CostAssessment}
            onBackToCancellationReasons={() => props.onPrevious()}
            onContinueToCancel={props.onContinueToCancel}
            cancellationReasonsHistory={props.reasonsHistory}
            onClose={props.onClose}
            currentWeeklyPrice={props.currentWeeklyPrice}
            pet={props.pets[0]}
          />
        ) : (
          <MixingPlanDownsell
            {...mixingPlanSaveFlow}
            onClose={props.onClose}
            currentWeeklyPrice={props.currentWeeklyPrice}
          />
        )}
      </>
    );
  }
}
