import { useEffect } from 'react';

import config from '../config';

import type { UseFormReturn } from 'react-hook-form';

export interface UseDebugTosaArgs {
  formMethods: UseFormReturn;
}

export function useDebugTosa({ formMethods }: UseDebugTosaArgs) {
  const values = formMethods.watch();
  const { errors } = formMethods.formState;

  useEffect(() => {
    if (config.get('tosa.log.formValues')) {
      // eslint-disable-next-line no-console
      console.log('Updated hook form state', values);
    }

    if (config.get('tosa.log.formErrors')) {
      // eslint-disable-next-line no-console
      console.log('Updated hook form errors', errors);
    }
  }, [values, errors]);
}
