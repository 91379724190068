import type { NextFunction, Request, Response } from 'express';

import type { ReferrerCookie } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { Logger } from '@farmersdog/logger';

import { ValidationError } from 'src/errors';

import { anonymousExperiments } from './anonymousExperiments';
import { getReferralCookieFromRequest } from './getReferralCookieFromRequest';
import { getTreatmentsWithParsedConfigs } from './getTreatmentsWithParsedConfigs';

const log = new Logger('app:server:experimentsMiddleware');

export async function experimentsMiddleware(
  request: Request,
  _: Response,
  next: NextFunction
) {
  try {
    const referrerCookie = getReferralCookieFromRequest(request);
    const splitAnonymousTreatments = await getAnonymousTreatments({
      anonymousId: request.anonymousId,
      referrerCookie,
    });

    request.experiments = splitAnonymousTreatments;
  } catch (error) {
    log.error('SSR Split - Failed to fetch split treatments', {
      error,
      anonymousId: request.anonymousId,
    });
  } finally {
    next();
  }
}

interface GetAnonymousTreatmentsArgs {
  anonymousId: string | undefined;
  referrerCookie: ReferrerCookie | undefined;
}

async function getAnonymousTreatments({
  anonymousId,
  referrerCookie,
}: GetAnonymousTreatmentsArgs) {
  if (!anonymousId) {
    throw new ValidationError(
      'No anonymousId found on request in experimentsMiddleware'
    );
  }

  return await getTreatmentsWithParsedConfigs({
    anonymousId,
    anonymousExperiments,
    referrerCookie,
  });
}
