/* CH#49502: Used by RET */
import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import type { TextVariants } from '@farmersdog/corgi-x';
import { AppNav, PageWidth, Text } from '@farmersdog/corgi-x';
import Page from 'src/components/Page';
import { useCorgiViewport } from 'src/screen';
import useNavigationItems from 'src/components/Navigation/hooks/useNavigationItems';

import styles from './AuthPage.module.scss';
import { FullFooter } from '../Footer';
import type { ReactNode } from 'react';
import {
  TermsAndConditionsChangeBanner,
  TermsAndConditionsChangeBannerVariant,
} from '../TermsAndConditionsChangeBanner';

export const USER_NOT_ACTIVE_ERROR = 'User is not active';
export const USER_NOT_FOUND_ERROR = 'User not found';
export const USER_MISSING_PASSWORD_ERROR = 'User has no password';
export const NO_ACTIVE_FOODPLANS =
  'Plans are required to create subscription quote';

interface AuthPageProps {
  title: string;
  loading: boolean;
  children: ReactNode;
  header: JSX.Element | string;
  subHeader: JSX.Element | string;
  isTermsChangeNoticeEnabled?: boolean;
}

function AuthPage({
  title,
  loading,
  header,
  subHeader,
  children,
  isTermsChangeNoticeEnabled = false,
}: AuthPageProps) {
  const widescreen = useCorgiViewport({ xs: false, md: true });

  const headingVariant = useCorgiViewport<TextVariants>({
    xs: 'heading-40',
    md: 'heading-52',
  });

  const { leftItems } = useNavigationItems({ recommendVetPortal: false });

  return (
    <>
      <header className={styles.nav}>
        <AppNav leftItems={leftItems} />
      </header>
      {isTermsChangeNoticeEnabled && (
        <TermsAndConditionsChangeBanner
          variant={TermsAndConditionsChangeBannerVariant.AccountLogin}
        />
      )}
      <Page title={title} loading={loading}>
        <div
          className={classNames(styles.main, {
            [styles.widescreen]: widescreen,
          })}
        >
          <PageWidth>
            <Text
              className={styles.header}
              as="h1"
              variant={headingVariant}
              color="kale-3"
              bold
            >
              {header}
            </Text>
            <Text
              className={styles.subHeader}
              as="p"
              variant="heading-22"
              color="charcoal-1"
            >
              {subHeader}
            </Text>
            <ContentWidth fr={1} alignSelf="center" className={styles.content}>
              {children}
            </ContentWidth>
          </PageWidth>
        </div>
        <FullFooter />
      </Page>
    </>
  );
}

export default AuthPage;
