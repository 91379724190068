import { Card, Text, Link } from '@farmersdog/corgi';
import type { WaveProps } from '@farmersdog/corgi-x';
import { GridContainer, Wave } from '@farmersdog/corgi-x';

import { useRouteMatch } from 'react-router-dom';

import {
  PATH_SIGNUP_ME,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';
import { useSetSignupFlow } from 'src/pages/Signup/DIY/hooks/useSetSignupFlow';

import { trackToggleProductLineClick } from 'src/analytics';
import { SubscriptionType } from 'src/graphql/types';

import Picture from 'src/components/Picture';
import { useCorgiV2Viewport } from 'src/screen';

import styles from './FreshUpsell.module.scss';
import upsellCardImageJpg from './assets/upsell-card-image.jpg';
import UpsellListCheckmarkSvg from './assets/upsell-list-checkmark.svg';
import { QueryParameter } from '@farmersdog/constants';
import { encodePetUri } from 'src/utils/petUri';
import { scrollToTop } from 'src/utils/dom';

import { useDispatch, useSelector } from 'react-redux';
import { resetProgress } from 'src/actions/signup';
import { selectActivePets } from 'src/reducers/signup/user';
import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';

type FreshPlanBenefitsProps = Pick<FreshUpsellProps, 'valueProps'>;

function FreshPlanBenefits({ valueProps }: FreshPlanBenefitsProps) {
  return (
    <Text variant="heading-16" as="ul">
      {valueProps.map(valueProp => {
        return (
          <li key={valueProp}>
            <UpsellListCheckmarkSvg />
            {valueProp}
          </li>
        );
      })}
    </Text>
  );
}

type HeadingProps = Pick<FreshUpsellProps, 'headingText'>;

function Heading({ headingText }: HeadingProps) {
  return (
    <Text
      variant="heading-40"
      color="kale-3"
      as="h2"
      className={styles.heading}
      bold
    >
      {headingText}
    </Text>
  );
}

function CardImage() {
  return (
    <div>
      <Picture sources={[upsellCardImageJpg]} alt="" className={styles.image} />
    </div>
  );
}

type CardContentProps = Pick<FreshUpsellProps, 'valueProps' | 'ctaText'> & {
  isRecipesPage: boolean;
};

function CardContent({ isRecipesPage, valueProps, ctaText }: CardContentProps) {
  const dispatch = useDispatch();
  const activePets = useSelector(selectActivePets);

  const { enableFreshSignupFlow } = useSetSignupFlow();

  const queryString = activePets.length
    ? `?${QueryParameter.PetName}=${encodePetUri(activePets[0].name)}`
    : '';

  const link = isRecipesPage
    ? `${PATH_SIGNUP_RECIPES}${queryString}`
    : getLinkToFreshSignup(PATH_SIGNUP_ME);

  const handleClick = () => {
    if (isRecipesPage) {
      enableFreshSignupFlow();
      trackToggleProductLineClick(SubscriptionType.Fresh);
      dispatch(resetProgress());
      scrollToTop();
    }
  };

  return (
    <div className={styles.freshPlan}>
      <div>
        <Text variant="heading-28" bold as="h3" color="kale-3">
          Try Our Fresh Plan
        </Text>
        <Text variant="heading-16" color="kale-3">
          Starting at $2.48 per day
        </Text>
        <FreshPlanBenefits valueProps={valueProps} />
      </div>
      <Link to={link} onClick={handleClick} asButton variant="secondary">
        {ctaText}
      </Link>
    </div>
  );
}

export interface FreshUpsellProps {
  headingText: string;
  valueProps: string[];
  ctaText: string;
}

export function FreshUpsell({
  headingText,
  valueProps,
  ctaText,
}: FreshUpsellProps) {
  const padding = useCorgiV2Viewport<WaveProps['padding']>({
    xs: 'margin-xl',
    lg: 'margin-xxl',
  });

  const responsiveClassName = useCorgiV2Viewport<string>({
    xs: styles.xs,
    lg: styles.lg,
  });

  const isRecipesPage = Boolean(useRouteMatch(PATH_SIGNUP_RECIPES));

  return (
    <section className={responsiveClassName}>
      <Wave
        withTop
        withBottom={!isRecipesPage}
        padding={padding}
        className={responsiveClassName}
      >
        <GridContainer>
          <Heading headingText={headingText} />
          <div className={styles.content}>
            <Card elevation={1} className={styles.card}>
              <CardImage />
              <CardContent
                isRecipesPage={isRecipesPage}
                valueProps={valueProps}
                ctaText={ctaText}
              />
            </Card>
          </div>
        </GridContainer>
      </Wave>
    </section>
  );
}
