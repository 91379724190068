import { convertFormStateToLeadInput } from '../convertFormStateToLeadInput';
import { convertLeadToFormState } from '../convertLeadToFormState';

import type { LeadInput, LeadView } from '../../../../graphql/types';
import type { FormValidationSchema } from '../../../../types';

export type ConvertCachedLeadToLeadInputType = (
  args: ConvertCachedLeadToLeadInputArgs
) => LeadInput;

interface ConvertCachedLeadToLeadInputArgs {
  leadData: LeadView;
  formValidationSchema: FormValidationSchema;
}

export function convertCachedLeadToLeadInput({
  leadData,
  formValidationSchema,
}: ConvertCachedLeadToLeadInputArgs): LeadInput {
  const convertedFormData = convertLeadToFormState(leadData);
  return convertFormStateToLeadInput({
    formValidationSchema,
    formData: convertedFormData,
  });
}
