import classNames from 'classnames';
import { forwardRef, useCallback, useRef } from 'react';

import { NodeNames } from '@farmersdog/constants';
import { useViewport } from '@farmersdog/corgi-x';

import { useFreshFoodEducation } from '../../hooks/experiments/useFreshFoodEducation/useFreshFoodEducation';
import { FreshFoodConfidenceTreatmentEnum } from '../../utils';
import { PersonalizedEducation } from '../education/PersonalizedEducation';
import { DropdownInput } from '../inputs/base';

import styles from './FreshFoodConfidenceInput.module.css';

import type {
  TOSAComponentInput,
  TOSALeafNode,
  UseFeatureHook,
} from '../../types';
import type { FreshFoodConfidenceLevels } from '../../validation/constants';
import type { ForwardedRef } from 'react';

export function FreshFoodConfidenceInput(
  props: TOSAComponentInput<TOSALeafNode>
) {
  const viewport = useViewport();
  const { formMethods, useFeature } = props;
  const selectedConfidenceLevel = formMethods.getValues(
    NodeNames.FreshFoodConfidence
  );

  const educationRef = useRef<HTMLDivElement>(null);
  const handleChange = useCallback(() => {
    if (!viewport.lg) {
      requestAnimationFrame(() => {
        educationRef.current?.scrollIntoView({
          block: 'start',
        });
      });
    }
  }, [viewport.lg]);

  return (
    <>
      <DropdownInput {...props} onChange={handleChange} />
      {selectedConfidenceLevel && (
        <FreshFoodConfidenceEducation
          confidenceLevel={selectedConfidenceLevel as FreshFoodConfidenceLevels}
          useFeature={useFeature}
          ref={educationRef}
        />
      )}
    </>
  );
}

const getEducationDetails = (
  personalizedEducationTreatment: FreshFoodConfidenceTreatmentEnum
) => {
  switch (personalizedEducationTreatment) {
    case FreshFoodConfidenceTreatmentEnum.stat_believe:
      return {
        heading: 'Great, let’s get their plan started!',
        information: (
          <>
            88% of our customers report positive
            <br /> changes in their dogs. Let’s get started <br />
            with a plan for yours!
          </>
        ),
      };
    case FreshFoodConfidenceTreatmentEnum.benefits:
      return {
        heading: 'Great, let’s get their plan started!',
        information: (
          <>
            Better digestion, easy weight
            <br /> management, healthy skin & coat — <br /> just some of The
            Farmer’s Dog benefits.
          </>
        ),
      };
    case FreshFoodConfidenceTreatmentEnum.vet_formulated:
      return {
        heading: 'We’re Here to Help!',
        information: (
          <>
            Our board‒certified nutritionists <br /> formulate all of our
            recipes, so you can <br /> feed real food with confidence.
          </>
        ),
      };
    default:
      return {
        heading: 'We’re Here to Help!',
        information: (
          <>
            88% of our customers report positive
            <br /> changes in their dogs. Let’s get started <br />
            with a plan for yours!
          </>
        ),
      };
  }
};

interface FreshFoodConfidenceEducationProps {
  confidenceLevel: FreshFoodConfidenceLevels;
  useFeature: UseFeatureHook;
}

const FreshFoodConfidenceEducation = forwardRef(function (
  props: FreshFoodConfidenceEducationProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { useFeature, confidenceLevel } = props;
  const viewport = useViewport();

  const { shouldShowPersonalizedEducation, freshFoodEducationTreatment } =
    useFreshFoodEducation({
      useFeature,
      freshFoodConfidence: confidenceLevel,
    });

  if (!shouldShowPersonalizedEducation) {
    return;
  }

  const { information, heading } = getEducationDetails(
    freshFoodEducationTreatment
  );
  return (
    <div
      ref={ref}
      className={classNames([
        styles.container,
        {
          [styles.lg]: viewport.lg,
        },
      ])}
    >
      <PersonalizedEducation information={information} heading={heading} />
    </div>
  );
});
