import type { RichSelectorValue } from '@farmersdog/corgi-x';
import { RichSelector } from '@farmersdog/corgi-x';

import { AddDogContent } from '../AddDogContent';

import {
  bodyConditionResourcesSmall,
  bodyConditionResourcesExpanded,
} from '../../../../../assets/body-condition';
import type { AddDogFormChangeHandler } from '../../hooks';

export enum BodyConditionValues {
  TooSkinny = 'Too Skinny',
  JustRight = 'Just Right',
  Rounded = 'Rounded',
  Chunky = 'Chunky',
}

interface BodyConditionSceneProps {
  name: string;
  bodyCondition: string;
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

export const BodyConditionScene = ({
  onCtaClick,
  name,
  bodyCondition,
  onChange,
}: BodyConditionSceneProps) => {
  const handleChange = (val: string | RichSelectorValue) => {
    onChange('bodyCondition', val as string);

    // If the user selects 'Just Right', we should clear previous target weight values.
    // Target weight is only rendered on WeightScene when body condition is not 'Just Right'.
    if (val === BodyConditionValues.JustRight) {
      onChange('targetWeight', undefined);
    }
  };
  return (
    <AddDogContent
      heading={`What is ${name}'s body condition?`}
      onCtaClick={onCtaClick}
      sectionWidthConstraint="lg"
      ctaDisabled={bodyCondition === ''}
    >
      <RichSelector
        name="Body condition"
        descriptionAtBottom
        defaultDescription="Select the body type closest to your dog's"
        descriptionAtBottomColor="charcoal-2"
        value={bodyCondition}
        onChange={handleChange}
        options={[
          {
            label: 'Too Skinny',
            description: 'Visible rib cage, possible loss of muscle mass',
            imageAlt: 'Picture of a dog who is too skinny',
            image: {
              small: bodyConditionResourcesSmall.TooSkinny,
              large: bodyConditionResourcesExpanded.TooSkinny,
            },
            value: BodyConditionValues.TooSkinny,
          },
          {
            label: 'Just Right',
            description:
              'Clear waistline and tucked in belly. Last ribs may be visible',
            imageAlt: 'Picture of a dog who is just right',
            image: {
              small: bodyConditionResourcesSmall.JustRight,
              large: bodyConditionResourcesExpanded.JustRight,
            },
            value: BodyConditionValues.JustRight,
          },
          {
            label: 'Rounded',
            description:
              'Waistline is disappearing, difficult to feel ribs. Broad back.',
            imageAlt: 'Picture of a dog who is rounded',
            image: {
              small: bodyConditionResourcesSmall.Rounded,
              large: bodyConditionResourcesExpanded.Rounded,
            },
            value: BodyConditionValues.Rounded,
          },
          {
            label: 'Chunky',
            description:
              'Waistline and ribs are lost. Weight is a serious concern',
            imageAlt: 'Picture of a dog who is chunky',
            image: {
              small: bodyConditionResourcesSmall.Chunky,
              large: bodyConditionResourcesExpanded.Chunky,
            },
            value: BodyConditionValues.Chunky,
          },
        ]}
      />
    </AddDogContent>
  );
};
