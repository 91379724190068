import { forwardRef, useCallback, useRef } from 'react';

import { useViewport } from '@farmersdog/corgi-x';

import { getPositionedProperty } from '../../../blueprint/utils';
import {
  useGrainsQuestionExperiment,
  useWeightLossPersonalizationExperiment,
} from '../../../hooks';
import {
  getCompletedData,
  getBodyConditionEducationDetails,
  scrollOncePerSession,
} from '../../../utils';
import { PersonalizedEducation } from '../../education/PersonalizedEducation';
import { BranchNode } from '../../schema/BranchNode';
import { SubmitButton } from '../../shared';

import styles from './PetCurrentFoodForm.module.css';

import type { BranchNode as BranchNodeType } from '../../../blueprint/types';
import type { TOSAComponentInput } from '../../../types';
import type { ForwardedRef } from 'react';

export function PetCurrentFoodForm(props: TOSAComponentInput<BranchNodeType>) {
  const viewport = useViewport();

  const formRef = useRef<HTMLFormElement | null>(null);
  const educationRef = useRef<HTMLDivElement>(null);

  const formValues = props.formMethods.getValues();

  const { formMethods, formSubmitRequest, progress, useFeature, node } = props;

  const { shouldShowGrainsQuestion } = useGrainsQuestionExperiment({
    useFeature,
  });

  const bodyCondition = getPositionedProperty({
    formValues,
    nodeName: node.name,
    property: 'bodyCondition',
  });

  const isBranchCompleted = progress.getBranchComplete(node.__self__);

  const { showBodyConditionPersonalization, isWeightLossTreatment } =
    useWeightLossPersonalizationExperiment({
      useFeature,
      bodyCondition: bodyCondition as string,
    });

  const handleChange = useCallback(() => {
    if (!viewport.lg) {
      scrollOncePerSession({ ref: formRef, id: node.name });
    }
  }, [viewport.lg, node.name]);

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    if (props.onSubmit) {
      await props.onSubmit(data, e);
    }
  });

  return (
    <>
      <form
        className={shouldShowGrainsQuestion ? styles.withLineHeight : ''}
        id={props.node.name}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <BranchNode {...props} onChange={handleChange} />
        <SubmitButton
          smallWhiteSpace={!viewport.lg}
          loading={formSubmitRequest.loading}
          {...props}
        />
      </form>
      {isBranchCompleted &&
        showBodyConditionPersonalization &&
        isWeightLossTreatment && (
          <PetCurrentFoodFormBodyConditionEducation ref={educationRef} />
        )}
    </>
  );
}

const PetCurrentFoodFormBodyConditionEducation = forwardRef(function (
  props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { heading, information } = getBodyConditionEducationDetails({});

  return (
    <div className={styles.bodyConditionEducationBox} ref={ref}>
      <PersonalizedEducation heading={heading} information={information} />
    </div>
  );
});
