import type { ApolloError } from '@apollo/client';
import { ActivityIndicator, Section } from '@farmersdog/corgi-x';
import { reporter } from '../../../errors/services';
import { OrderDeliveryStatus } from '../../../graphql/types.cgs';
import { formatDateRange, formatDateToMonthAndDay } from '../../../utils';
import type { HomepageNextOrderToBeDeliveredFieldsFragment } from '../../network';
import { ScheduledInfoCard } from './ScheduledInfoCard';
import { InfoCard } from './InfoCard';
import { useShouldShowScheduleCard } from '../ScheduleCard/useShouldShowScheduleCard';
import { ScheduleCard, convertNextOrderToCalendarEvent } from '../ScheduleCard';
import { useTrackOrderDeliveryWindowDisplayed } from '../../analytics/useTrackOrderDeliveryWindowDisplayed';
import { PaymentFailedInfoCard } from './PaymentFailedInfoCard';

export enum OrderProgressCopy {
  BoxArriving = 'Box Arriving',
  EstimatedArrival = 'Estimated Arrival',
}

export interface HomepageOrdersSectionProps {
  nextOrderToBeDelivered:
    | HomepageNextOrderToBeDeliveredFieldsFragment
    | undefined;
  loading: boolean;
  error?: ApolloError;
}

export function HomepageOrdersSection(props: HomepageOrdersSectionProps) {
  useTrackOrderDeliveryWindowDisplayed({
    orderId: props.nextOrderToBeDelivered?.id,
    orderCreatedAt: props.nextOrderToBeDelivered?.createdAt,
    fromDate:
      props.nextOrderToBeDelivered?.deliveryWindow.earliestDesiredArrivalDate,
    toDate:
      props.nextOrderToBeDelivered?.deliveryWindow.latestDesiredArrivalDate,
    displayLocation: 'account-homepage',
  });

  const shouldShowScheduleCard = useShouldShowScheduleCard();

  if (!props.loading && !props.nextOrderToBeDelivered) {
    reporter.error(new Error('nextOrderToBeDelivered missing on homepage'));
    return null;
  }

  const nextOrderToBeDelivered = props.nextOrderToBeDelivered;
  const estimatedDeliveryDate =
    nextOrderToBeDelivered?.deliveryWindow.estimatedDeliveryDate;

  const earliestDate =
    nextOrderToBeDelivered?.deliveryWindow.earliestDesiredArrivalDate;
  const latestDate =
    nextOrderToBeDelivered?.deliveryWindow.latestDesiredArrivalDate;

  if (props.loading) {
    return <ActivityIndicator />;
  }

  if (!earliestDate || !latestDate) {
    return null;
  }

  const formattedDateRange = estimatedDeliveryDate
    ? formatDateToMonthAndDay(estimatedDeliveryDate)
    : formatDateRange(earliestDate, latestDate);

  const orderProgressCopy = estimatedDeliveryDate
    ? OrderProgressCopy.EstimatedArrival
    : OrderProgressCopy.BoxArriving;

  if (
    // this delivery status is behind a Split flag in the Core API
    nextOrderToBeDelivered?.deliveryStatus === OrderDeliveryStatus.PaymentFailed
  ) {
    return (
      <Section
        variant="card"
        bottomSpacing="lg"
        aria-labelledby="payment-failed-orders-info-card"
      >
        <PaymentFailedInfoCard
          orderId={nextOrderToBeDelivered.id}
          dateRange={formattedDateRange}
        />
      </Section>
    );
  }

  if (
    nextOrderToBeDelivered?.deliveryStatus === OrderDeliveryStatus.Scheduled
  ) {
    return (
      <Section
        variant="card"
        bottomSpacing="lg"
        aria-labelledby="scheduled-orders-info-card"
      >
        <ScheduledInfoCard
          dateRange={formattedDateRange}
          rescheduleCutoffDate={nextOrderToBeDelivered.rescheduleCutoffDate}
        />
        {shouldShowScheduleCard && (
          <ScheduleCard
            event={convertNextOrderToCalendarEvent(nextOrderToBeDelivered)}
          />
        )}
      </Section>
    );
  }

  if (
    nextOrderToBeDelivered?.deliveryStatus === OrderDeliveryStatus.Preparing ||
    nextOrderToBeDelivered?.deliveryStatus === OrderDeliveryStatus.Transit
  ) {
    return (
      <Section
        variant="card"
        bottomSpacing="lg"
        aria-labelledby="orders-info-card"
      >
        <InfoCard
          deliveryStatus={nextOrderToBeDelivered.deliveryStatus}
          dateRange={formattedDateRange}
          orderProgressCopy={orderProgressCopy}
          trackingUrls={nextOrderToBeDelivered.trackingUrls}
        />
      </Section>
    );
  }

  return null;
}
