import { PATH_PLAN, PATH_WEBSITE_HOMEPAGE } from '@farmersdog/constants/paths';
import { ActivityIndicator, Page } from '@farmersdog/corgi-x';
import { useHistory } from 'react-router';
import { reporter } from '../errors/services';
import {
  trackOrderSizeUpNudgeKeepPlan,
  trackOrderSizeUpNudgeSeePlanPage,
  trackOrderSizeUpNudgeUpdatedPlan,
} from './analytics/trackSuggestOrderSizeUp';
import { Confirmation, OrderSizes } from './features';
import type { ValidAcceptFrequencyUpdateSubscriptionMutationResponse } from './network';
import { useSuggestOrderSizeUpSubmit } from './network';
import {
  SuggestOrderSizeUpState,
  useSuggestOrderSizeUpMachine,
} from './state-machine';
import { useChangeFrequencyData } from '../change-frequency/network';
import styles from './SuggestOrderSizeUp.module.css';

interface SuggestOrderSizeUpProps {
  nextOrderDeliveryWindow?: {
    earliestDesiredArrivalDate: string;
    latestDesiredArrivalDate: string;
  };
}

export function SuggestOrderSizeUp({
  nextOrderDeliveryWindow,
}: SuggestOrderSizeUpProps) {
  const { loading, suggestedFrequencies, currentFrequency } =
    useChangeFrequencyData();

  const machine = useSuggestOrderSizeUpMachine();

  const { callSubmit } = useSuggestOrderSizeUpSubmit({
    onCompleted: (
      response: ValidAcceptFrequencyUpdateSubscriptionMutationResponse
    ) => {
      machine.onAccept({
        daysOfFood: response.updateMySubscription.subscription.frequency,
        earliestDesiredArrivalDate:
          response.updateMySubscription.subscription.nextOrderToBeDelivered
            .earliestDesiredArrivalDate,
        latestDesiredArrivalDate:
          response.updateMySubscription.subscription.nextOrderToBeDelivered
            .latestDesiredArrivalDate,
      });
    },
  });
  const history = useHistory();
  const handleOnClose = () => {
    history.push(PATH_WEBSITE_HOMEPAGE);
  };
  const handleOnContinueToPlan = () => {
    history.push(PATH_PLAN);
  };

  if (loading) {
    return (
      <Page layout="base" className={styles.container}>
        <div role="status" aria-live="polite" aria-label="loading" />
        <ActivityIndicator mode="dark" />
      </Page>
    );
  }
  if (!suggestedFrequencies || !currentFrequency || !nextOrderDeliveryWindow) {
    reporter.error(
      new Error('No frequency data returned in SuggestOrderSizeUp')
    );

    return null;
  }

  const handleOnReject = () => {
    trackOrderSizeUpNudgeKeepPlan();
    machine.onReject({
      daysOfFood: currentFrequency,
      earliestDesiredArrivalDate:
        nextOrderDeliveryWindow.earliestDesiredArrivalDate,
      latestDesiredArrivalDate:
        nextOrderDeliveryWindow.latestDesiredArrivalDate,
    });
  };
  const currentSubscription = {
    daysOfFood: currentFrequency,
    dailyConsumptionPrice: suggestedFrequencies.current.dailyConsumptionPrice,
    orderTotal: suggestedFrequencies.current.regularOrderTotalConsumptionPrice,
  };
  const suggestedSubscription = {
    daysOfFood: suggestedFrequencies.max.frequency,
    dailyConsumptionPrice: suggestedFrequencies.max.dailyConsumptionPrice,
    orderTotal: suggestedFrequencies.max.regularOrderTotalConsumptionPrice,
  };
  if (machine.isState(SuggestOrderSizeUpState.Close)) {
    handleOnClose();
  }
  if (machine.isState(SuggestOrderSizeUpState.ContinueToPlan)) {
    trackOrderSizeUpNudgeSeePlanPage();
    handleOnContinueToPlan();
  }
  if (machine.isState(SuggestOrderSizeUpState.Suggest)) {
    return (
      <Page onCloseClick={machine.onClose}>
        <OrderSizes
          currentSubscription={currentSubscription}
          suggestedSubscription={suggestedSubscription}
          callSubmit={() =>
            callSubmit({
              input: {
                frequency: suggestedFrequencies.max.frequency,
              },
            })
          }
          onReject={handleOnReject}
        />
      </Page>
    );
  }
  if (
    machine.context.daysOfFood &&
    machine.context.earliestDesiredArrivalDate &&
    machine.context.latestDesiredArrivalDate
  ) {
    if (machine.isState(SuggestOrderSizeUpState.AcceptConfirmation)) {
      trackOrderSizeUpNudgeUpdatedPlan({
        oldSubscription: currentSubscription,
        newSubscription: suggestedSubscription,
        oldNextOrderToBeDelivered: {
          daysOfFood: currentSubscription.daysOfFood,
          earliestDesiredArrivalDate:
            nextOrderDeliveryWindow.earliestDesiredArrivalDate,
          latestDesiredArrivalDate:
            nextOrderDeliveryWindow.latestDesiredArrivalDate,
        },
        newNextOrderToBeDelivered: {
          daysOfFood: machine.context.daysOfFood,
          earliestDesiredArrivalDate:
            machine.context.earliestDesiredArrivalDate,
          latestDesiredArrivalDate: machine.context.latestDesiredArrivalDate,
        },
      });
      {
        return (
          <Page onCloseClick={machine.onClose} layout="base-centered">
            <Confirmation
              daysOfFood={machine.context.daysOfFood}
              earliestDesiredArrivalDate={
                machine.context.earliestDesiredArrivalDate
              }
              latestDesiredArrivalDate={
                machine.context.latestDesiredArrivalDate
              }
              acceptedSuggestion={true}
              onContinueToPlan={machine.onContinueToPlan}
            />
          </Page>
        );
      }
    }
    if (machine.isState(SuggestOrderSizeUpState.RejectConfirmation)) {
      return (
        <Page onCloseClick={machine.onClose} layout="base-centered">
          <Confirmation
            daysOfFood={machine.context.daysOfFood}
            earliestDesiredArrivalDate={
              machine.context.earliestDesiredArrivalDate
            }
            latestDesiredArrivalDate={machine.context.latestDesiredArrivalDate}
            acceptedSuggestion={false}
            onContinueToPlan={machine.onContinueToPlan}
          />
        </Page>
      );
    }
  }

  return null;
}
