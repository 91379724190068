import { ApolloLink } from '@apollo/client';

import { tosaToken } from '@farmersdog/lead-browser-storage';

import createLead from '../../../graphql/mutations/createLead.graphql';
import { setOperationToken } from '../utils/setOperationToken';

import type { CreateLeadMutation } from '../../../graphql/types';
import type { FetchResult } from '@apollo/client';
import type { DocumentNode } from 'graphql';

/**
 * Authorization middleware
 *
 * Automatically handles setting authorization tokens in outgoing network
 * requests and setting authorization tokens in incoming responses.
 */
export function createAuthMiddleware(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    setOperationToken({
      operation,
      token: tosaToken.get(),
    });

    if (operation.operationName !== getOperationName(createLead)) {
      return forward(operation);
    }

    // @ts-expect-error We are checking for this result above
    return forward(operation).map((result: FetchResult<CreateLeadMutation>) => {
      const token = result?.data?.createLead.token;

      if (!token) {
        return result;
      }

      tosaToken.set(token);
      return result;
    });
  });
}

function getOperationName(document: DocumentNode): string {
  const [definition] = document.definitions;

  if (!definition) {
    return '';
  }

  if (definition.kind !== 'OperationDefinition') {
    return '';
  }

  if (definition.name?.kind !== 'Name') {
    return '';
  }

  return definition.name.value;
}
