import { useMemo } from 'react';
import styles from './SavedClinicCard.module.css';
import { Text, Grid, GridItem, Button } from '@farmersdog/corgi-x';
import type { GetClinicsQuery } from '../../network/GetClinics.cgs.generated-types';
import { Check } from '@farmersdog/corgi-x/icons';

export type CustomerClinic = GetClinicsQuery['petHealth']['clinics'][number];

interface SavedClinicCardProps extends CustomerClinic {
  recentlySaved: boolean;
  onRemove: (clinicId: string) => void;
  isRemoveLoading: boolean;
}

export function SavedClinicCard({
  clinic,
  clinicPhone,
  clinicEmail,
  vetName,
  recentlySaved,
  onRemove,
  isRemoveLoading,
}: SavedClinicCardProps) {
  const address = useMemo(() => {
    const { streetAddress1, streetAddress2, city, state, zip } = clinic;
    const firstLine = [streetAddress1, streetAddress2, city, state, zip]
      .filter(item => Boolean(item))
      .join(', ');

    return (
      <>
        <Text className={styles.informationItem} variant="heading-16">
          {firstLine}
        </Text>
      </>
    );
  }, [clinic]);

  return (
    <Grid>
      <GridItem className={styles.container}>
        {recentlySaved && (
          <div className={styles.recentlySaved}>
            <span>
              <Check className={styles.check} baseColor={'White'} />
              <Text variant="heading-16" bold color={'white'}>
                Saved
              </Text>
            </span>
          </div>
        )}
        <Grid className={styles.information}>
          <GridItem>
            <Text variant="heading-16" bold>
              Clinic Name
            </Text>
            <Text className={styles.informationItem} variant="heading-16">
              {clinic.name}
            </Text>
          </GridItem>
          <GridItem>
            <Text variant="heading-16" bold>
              Address
            </Text>
            {address}
          </GridItem>
          {clinicPhone && (
            <GridItem>
              <Text variant="heading-16" bold>
                Phone Number
              </Text>
              <Text className={styles.informationItem} variant="heading-16">
                {clinicPhone}
              </Text>
            </GridItem>
          )}
          {clinicEmail && (
            <GridItem>
              <Text variant="heading-16" bold>
                Clinic Email Address
              </Text>
              <Text className={styles.informationItem} variant="heading-16">
                {clinicEmail}
              </Text>
            </GridItem>
          )}
          {vetName && (
            <GridItem>
              <Text variant="heading-16" bold>
                Veterinarian Name
              </Text>
              <Text className={styles.informationItem} variant="heading-16">
                {vetName}
              </Text>
            </GridItem>
          )}
          <Button
            variant="plain-text"
            className={styles.removeClinic}
            onClick={() => onRemove(clinic.id!)}
            aria-disabled={isRemoveLoading}
          >
            Remove
          </Button>
        </Grid>
      </GridItem>
    </Grid>
  );
}
