import { useEffect } from 'react';
import { useStripe } from '@farmersdog/corgi-x';
import type { HandleStripeTokenEvent } from '../reducers/signup/checkout.types';
import { usePaymentRequestContext } from './PaymentRequestContext';

interface UsePaymentRequestArgs {
  totalAmount?: number;
  taxAmount?: number;
  onToken?: (token: HandleStripeTokenEvent) => void;
  onCancel?: () => void;
}

/**
 * Create or use an existing Stripe payment request. This hook should be used
 * to interface with the paymentRequest object in the Stripe context. Can only
 * be used within StripeContextProvider.
 */
export function usePaymentRequest({
  totalAmount,
  taxAmount,
  onToken,
}: UsePaymentRequestArgs) {
  const { stripe } = useStripe();
  const {
    paymentRequest,
    createPaymentRequest,
    updatePaymentRequest,
    setOnToken,
    paymentInterfaceOpen,
  } = usePaymentRequestContext();

  /** Create new payment request if none exists. */
  useEffect(() => {
    if (
      !stripe ||
      paymentRequest ||
      typeof totalAmount !== 'number' ||
      !Number.isFinite(totalAmount) ||
      typeof taxAmount !== 'number' ||
      !Number.isFinite(taxAmount)
    ) {
      return;
    }
    createPaymentRequest(totalAmount, taxAmount);
  }, [stripe, paymentRequest, totalAmount, taxAmount, createPaymentRequest]);

  /** Update payment request if amounts change. */
  useEffect(() => {
    if (
      !paymentRequest ||
      typeof totalAmount !== 'number' ||
      !Number.isFinite(totalAmount) ||
      typeof taxAmount !== 'number' ||
      !Number.isFinite(taxAmount) ||
      paymentInterfaceOpen
    ) {
      return;
    }
    updatePaymentRequest(totalAmount, taxAmount);
  }, [
    paymentRequest,
    updatePaymentRequest,
    totalAmount,
    taxAmount,
    paymentInterfaceOpen,
  ]);

  /** Update ref with changed onToken listener. */
  useEffect(() => {
    if (!onToken) {
      return;
    }
    setOnToken(onToken);
  }, [onToken, setOnToken]);
}
