import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

import type { TreatSize } from '../../graphql/types';

type TrackSignupTreatAddTreatToCartArgs = {
  name: string;
  size: TreatSize;
};

/** A Segment tracking event for when a user adds a treat to their cart in the checkout page. */

export function trackSignupFreeTreatAddTreatToCart({
  name,
  size,
}: TrackSignupTreatAddTreatToCartArgs) {
  track(segmentTrackEvents.signup_free_treats_add_treat_to_cart, {
    name,
    size,
  });
}
