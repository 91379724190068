import { track } from '@farmersdog/analytics';
import type { SubscriptionType } from '../../graphql/types.core-api';
import { eventNames } from './eventNames';

export interface CancelSubscriptionFlowSavePayload {
  saveReason:
    | 'change-meals-size'
    | 'delay-shipment'
    | 'precancel-delay-shipment'
    | 'lpf-downsell'
    | 'change-meals-size-lpf-flow';
  subscriptionType: SubscriptionType;
  cancellationReasonsHistory?: string[];
}

export function trackCancelSubscriptionFlowSave({
  saveReason,
  subscriptionType,
  cancellationReasonsHistory,
}: CancelSubscriptionFlowSavePayload) {
  track(eventNames.cancel_subscription_flow_save, {
    cancellationVersion: 2,
    saveReason,
    subscriptionType,
    cancellationReasonsHistory,
  });
}
