import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { ErrorPage } from '../errors';
import { Loading } from '../components';
import type { CancelSubscriptionFlowSavePayload } from '../cancellation-reasons';
import { trackCancelSubscriptionFlowSave } from '../cancellation-reasons';
import { RescheduledDateScene, SuccessScene } from './features';
import { useWorkflow, useScenes } from './state-machine';
import { useFetchTemporaryPause, usePauseFreshSubscription } from './network';
import {
  trackTemporaryPauseStart,
  trackTemporaryPauseSuccess,
} from './analytics';

export type RescheduleScene = 'RescheduledDate' | 'Success';

/** Sorted list of all scenes. */
export const rescheduleScenes: RescheduleScene[] = [
  'RescheduledDate',
  'Success',
];

/**
 * A workflow to reschedule the next order delivery date, framed as a "pause".
 */
export function TemporaryPause() {
  const scenes = useScenes(rescheduleScenes);
  const request = useFetchTemporaryPause();
  const workflow = useWorkflow(scenes);
  const location = useLocation<
    | { cancelSubscriptionFlowSavePayload: CancelSubscriptionFlowSavePayload }
    | undefined
  >();
  const [handleSubmit, submitState] = usePauseFreshSubscription({
    onCompleted: rescheduledShipDateResponse => {
      // In this case, reschedule completes a cancellation v2 save path
      if (location.state?.cancelSubscriptionFlowSavePayload) {
        trackCancelSubscriptionFlowSave(
          location.state.cancelSubscriptionFlowSavePayload
        );
      }

      // TODO: update track call to CGS
      trackTemporaryPauseSuccess({
        // Defaulting this to an empty string for TS due to the React design.
        // We would not have made it to a mutation success without this previous next date
        previousShipDate: request.data?.previousNextDate || '',
        rescheduledShipDate: rescheduledShipDateResponse,
      });

      workflow.goToNextScene();
    },
  });

  useEffect(() => {
    trackTemporaryPauseStart();
  }, []);

  if (submitState.error) {
    return <ErrorPage error={submitState.error} />;
  }

  if (request.error) {
    return <ErrorPage error={request.error} />;
  }
  if (request.loading) {
    return <Loading />;
  }
  if (!request.data) {
    throw new Error('Unknown exception occurred while loading data');
  }

  if (
    scenes.activeScene === 'Success' &&
    !submitState.isSubmitting &&
    !submitState.updatedNextDate
  ) {
    throw new Error('Missing updated shipping date');
  }

  if (scenes.activeScene === 'RescheduledDate')
    return (
      <RescheduledDateScene
        currentNextDate={request.data.currentNextDate}
        unavailableDate={request.data.unavailableDate}
        availablePauseResumeDates={request.data.availablePauseResumeDates}
        onSubmit={handleSubmit}
        onClose={workflow.close}
        isSubmitting={submitState.isSubmitting}
      />
    );

  if (scenes.activeScene === 'Success') {
    return (
      <SuccessScene
        updatedNextDate={submitState.updatedNextDate}
        onClose={workflow.close}
      />
    );
  }

  throw new Error('invalid scene');
}
