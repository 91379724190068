import { getCompletedData } from '../../utils';
import { BranchNode } from '../schema/BranchNode';

import type { BranchNode as BranchNodeType } from '../../blueprint/types';
import type { TOSAComponentInput } from '../../types';

export function Form(props: TOSAComponentInput<BranchNodeType>) {
  const { formMethods, node, onSubmit, progress } = props;

  const handleSubmit = formMethods.handleSubmit(async (formData, e) => {
    const completedData = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    await onSubmit?.(completedData, e);
  });

  return (
    <form id={node.name} onSubmit={handleSubmit}>
      <BranchNode {...props} />
    </form>
  );
}
