import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';
import type { OrderSizeSubscriptionDetails } from '../features/order-sizes/OrderSizes';

type NextOrderToBeDelivered = {
  daysOfFood: number;
  earliestDesiredArrivalDate: string;
  latestDesiredArrivalDate: string;
};
export function trackOrderSizeNudgeUpdatedPlan(args: {
  oldSubscription: OrderSizeSubscriptionDetails;
  newSubscription: OrderSizeSubscriptionDetails;
  oldNextOrderToBeDelivered: NextOrderToBeDelivered;
  newNextOrderToBeDelivered: NextOrderToBeDelivered;
}) {
  track(eventNames.success_updated_plan, args);
}

export function trackOrderSizeNudgeKeepPlan() {
  track(eventNames.clicked_keep_plan);
}

export function trackOrderSizeNudgeDisplayedPlanOptions(args: {
  currentSubscription: OrderSizeSubscriptionDetails;
  suggestedSubscription: OrderSizeSubscriptionDetails;
}) {
  track(eventNames.displayed_plan_options, args);
}

export function trackOrderSizeNudgeSeePlanPage() {
  track(eventNames.clicked_see_plan_page);
}
