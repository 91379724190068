import type { UseAddDogFormFields } from '../hooks';
import eventNames from './eventNames';
import { track } from '@farmersdog/analytics';

interface TrackPetDetailsCompleteArgs {
  name: string;
  ageNumber: number;
  ageUnit: UseAddDogFormFields['ageUnit'];
  sex: UseAddDogFormFields['sex'];
  breed: UseAddDogFormFields['breed'];
  sterilization: boolean;
  bodyCondition: UseAddDogFormFields['bodyCondition'];
  currentWeight: number;
  targetWeight?: number;
  activityLevel: UseAddDogFormFields['activityLevel'];
  eatingStyle: UseAddDogFormFields['eatingStyle'];
  healthIssues?: UseAddDogFormFields['healthIssues'];
  petDietIssues?: UseAddDogFormFields['petDietIssues'];
}
export function trackPetDetailsComplete(args: TrackPetDetailsCompleteArgs) {
  track(eventNames.onPetDetailsComplete, {
    ...args,
  });
}
