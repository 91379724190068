/* CH#49502: Used by CVR */
import Picture from 'src/components/Picture';

import type { LogoProps } from './Logos.types';

import GoogleReviewsPng from './assets/google.png';
import GoogleReviewsWebP from './assets/google.webp';

export function GoogleReviewsLogo(props: LogoProps) {
  return (
    <Picture
      sources={[GoogleReviewsWebP, GoogleReviewsPng]}
      alt="Google customer reviews five stars"
      {...props}
    />
  );
}
