import { Card, Text } from '@farmersdog/corgi-x';

import { useZipEmailCopyExperiment } from '../../../hooks/experiments/useZipEmailCopyExperiment';
import { useLabel } from '../../../hooks/useLabel';
import {
  denormalizeTextInputValue,
  getInputAttributes,
  getPetNamesFromFormEntries,
  parseTextInputValue,
} from '../../../utils';
import { InlineInput, BasicInput } from '../base';

import styles from './ZipInput.module.css';

import type {
  TOSAComponentInput,
  TOSALeafNode,
  ExtendedTextInputChangeEvent,
} from '../../../types';

export function ZipInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods, useFeature } = props;
  const inputAttributes = getInputAttributes(node);

  const { showZipEmailCopy } = useZipEmailCopyExperiment({ useFeature });

  const { register, setValue, getValues, trigger } = formMethods;
  const registerProps = register(node.name);
  const formValues = getValues();

  const { frontLabel, backLabel } = useLabel({ node, getValues });

  const handleBlur = async () => {
    if (showZipEmailCopy) {
      await trigger(node.name);
    }
  };

  const handleChange = (e: ExtendedTextInputChangeEvent) => {
    const zipValue = e.target.value?.trim();

    const parsedValue = parseTextInputValue({
      value: zipValue,
      node,
    });

    const parsedDenormalizedValue = denormalizeTextInputValue({
      name: node.name,
      value: parsedValue,
      event: e,
    });

    setValue(node.name, parsedDenormalizedValue, { shouldValidate: true });
  };

  const isShowingInitialPrompt = !getValues(node.name);

  const { petNames } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName: node.name,
  });

  const initialPromptFillInText =
    petNames.length === 1 ? `${petNames[0]}’s` : 'your dogs’';

  return showZipEmailCopy ? (
    <>
      <span>{frontLabel}</span>
      <InlineInput
        {...inputAttributes}
        {...registerProps}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <span>{backLabel}</span>
      {isShowingInitialPrompt && (
        <div className={styles.initialPrompt}>
          <Card variant="speech-bubble">
            <Text variant="article-16">
              Where are we shipping {initialPromptFillInText} food?
            </Text>
          </Card>
        </div>
      )}
    </>
  ) : (
    <div className={styles.container}>
      <BasicInput {...props} />
    </div>
  );
}
