import { Fragment } from 'react';

import { getLayoutClass } from './getLayoutClass';
import { getLayoutElement } from './getLayoutElement';

import type { Node } from '../../blueprint/types';
import type { TOSAComponentInput } from '../../types';
import type { ReactNode } from 'react';

export interface BranchNodeAnimationProps extends TOSAComponentInput<Node> {
  children: ReactNode;
}

export function BranchNodeAnimation({
  node,
  children,
}: BranchNodeAnimationProps) {
  const HTMLElement = getLayoutElement(node.layout);

  if (HTMLElement === Fragment) {
    return <HTMLElement>{children}</HTMLElement>;
  }

  return (
    <HTMLElement
      className={getLayoutClass({
        layout: node.layout,
      })}
    >
      {children}
    </HTMLElement>
  );
}
