import type { MySubscriptionView } from 'src/graphql/types';
import { SubscriptionStatus, SubscriptionType } from 'src/graphql/types';

type DetermineSubscription = Pick<MySubscriptionView, 'status' | 'type'>;

/**
 * Determine what the user's current subscription is since a customer
 * can have either a Fresh or DIY subscription
 */
export function determineSubscription<T extends DetermineSubscription>(
  subscriptions: T[]
): T | undefined {
  const activeSubscription = subscriptions.find(
    subscription => subscription?.status === SubscriptionStatus.Active
  );

  if (activeSubscription) return activeSubscription;

  const allSuspended = subscriptions.every(
    subscription => subscription?.status === SubscriptionStatus.Suspended
  );

  // If both DIY and FRESH are suspended then we should default to DIY.
  if (allSuspended) {
    const diySubscription = subscriptions.find(
      subscription => subscription?.type === SubscriptionType.Diy
    );

    if (diySubscription) return diySubscription;
  }

  const suspendedSubscription = subscriptions.find(
    subscription => subscription?.status === SubscriptionStatus.Suspended
  );

  if (suspendedSubscription) return suspendedSubscription;

  // If no active or suspended subscription, return the fresh subscription
  // since the customer is a manual activation customer
  const freshSubscription = subscriptions.find(
    subscription => subscription?.type === SubscriptionType.Fresh
  );

  return freshSubscription ?? undefined;
}
