import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type {
  CancellationFreshSubscriptionFieldsFragment,
  NextOrderToBeDeliveredFieldsFragment,
  PetFieldsFragment,
  FoodBrandFieldsFragment,
} from '../fragments';
import type { FetchCancellationReasonsDataQuery } from './fetchCancellationReasonsData.cgs.generated-types';
import fetchCancellationReasonsData from './fetchCancellationReasonsData.cgs.graphql';

interface FetchCancellationReasonsData {
  nextOrderToBeDelivered: NextOrderToBeDeliveredFieldsFragment;
  pets: PetFieldsFragment[];
  freshSubscription: CancellationFreshSubscriptionFieldsFragment;
  foodBrands: FoodBrandFieldsFragment[];
  isMultiPet: boolean;
}

interface UseFetchCancellationReasonsData {
  data: FetchCancellationReasonsData | undefined;
  error?: ApolloError;
  loading: boolean;
}

export function useFetchCancellationReasonsData(): UseFetchCancellationReasonsData {
  const response = useQuery<FetchCancellationReasonsDataQuery>(
    fetchCancellationReasonsData,
    {
      context: { endpoint: 'cgs' },
    }
  );

  return {
    loading: response.loading,
    error: response.error,
    data: response.data && {
      pets: response.data?.customer.pets ?? [],
      nextOrderToBeDelivered: response.data?.customer.nextOrderToBeDelivered,
      freshSubscription: response.data?.customer.freshSubscription,
      foodBrands: response.data?.foodBrands,
      isMultiPet: Boolean(
        response.data?.customer?.userCharacteristics?.isMultiPet
      ),
    },
  };
}
