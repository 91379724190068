import type { ChangeEvent } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import type { ListSelectOptionProps } from '@farmersdog/corgi';
import { ListSelectOption, useToast } from '@farmersdog/corgi';
import { formatDeliveryWindow } from '../../utils';
import { WeekdayNumber } from '../../constants';
import * as copy from './DateSelectOptionCopy';
import styles from './DateSelectOption.module.css';

export interface DateSelectOptionProps
  extends Omit<ListSelectOptionProps, 'ref'> {
  value: string;
  isScheduled?: boolean;
  isUnavailable?: boolean;
  isRecommended?: boolean;
  didUserSelectADate: boolean;
}

/**
 * Render a date "option" for the `DateSelect` component. Supports different
 * badges.
 */
export function DateSelectOption({
  value,
  isUnavailable,
  isScheduled,
  isRecommended,
  checked,
  onChange,
  didUserSelectADate,
  ...listSelectOptionProps
}: DateSelectOptionProps) {
  const dispatchToast = useToast();

  let badge: string | undefined;
  if (isScheduled) {
    badge = 'Currently scheduled';
  } else if (checked) {
    badge = 'Selected date';
  } else if (isUnavailable) {
    badge = 'Unavailable';
  } else if (isRecommended) {
    badge = 'Recommended';
  }

  const validateSelection = () => {
    return isUnavailable;
  };

  const handleClick = () => {
    if (isUnavailable) {
      dispatchToast({
        variant: 'neutral',
        children: copy.unavailableDate,
      });
    }

    if (isScheduled) {
      dispatchToast({
        variant: 'neutral',
        children: copy.alreadyScheduled,
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const isInvalid = validateSelection();
    if (!isInvalid) {
      onChange?.(e);
    }
  };

  const label = `Week of ${moment(value)
    .day(WeekdayNumber.Monday)
    .format('MMMM D')}`;

  return (
    <div
      className={classNames(styles.root, {
        [styles.unavailable]: isUnavailable,
      })}
      onClick={handleClick}
    >
      <ListSelectOption
        {...listSelectOptionProps}
        name="week"
        label={label}
        checked={
          (checked && didUserSelectADate) ||
          (isScheduled && !didUserSelectADate)
        }
        value={value || ''}
        disabled={isUnavailable}
        description={`Delivery window: ${formatDeliveryWindow(value)}`}
        onChange={handleChange}
        badge={badge}
        recommended={isRecommended}
      />
    </div>
  );
}
