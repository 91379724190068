import Fuse from 'fuse.js';

import type { BreedType } from '../../../types';

const fuseOptions = {
  findAllMatches: true,
  includeScore: true,
  shouldSort: true,
  isCaseSensitive: false,
  keys: ['name'],
};

interface FuzzySearchProps {
  items: BreedType[];
  inputValue: string;
}

interface FuzzySearchResult {
  item: BreedType;
  isExactMatch: boolean;
}

export const fuzzySearch = ({
  items,
  inputValue,
}: FuzzySearchProps): FuzzySearchResult[] => {
  return new Fuse(items, fuseOptions)
    .search(inputValue)
    .map(({ item, score = 1 }) => ({
      item,
      // Fuse returns values like 2.220446049250313e-16 rather than 0 for exact
      // matches, so I think we can just use a very low number here.
      isExactMatch: score < 1e-10,
    }));
};
