import { Text } from '@farmersdog/corgi-x';

import styles from './ProgressItem.module.css';

import type { ReactElement } from 'react';

interface ProgressItemProps {
  title: string;
  subtitle: string;
  descriptionItems: string[];
  number: number | ReactElement;
}
const NumberedCircle = ({ number }: { number: number | ReactElement }) => {
  return (
    <div className={styles.icon}>
      <Text color="white" variant="heading-28" bold>
        {number}
      </Text>
    </div>
  );
};

export const ProgressItem = ({
  title,
  subtitle,
  descriptionItems,
  number,
}: ProgressItemProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <NumberedCircle number={number} />
        <div className={styles.bar} />
      </div>
      <div className={styles.textsContainer}>
        <Text
          as="h3"
          className={styles.title}
          color="kale-3"
          variant="heading-28"
          bold
        >
          {title}
        </Text>
        <div className={styles.descriptionContainer}>
          <Text
            className={styles.description}
            color="charcoal-3"
            variant="heading-16"
            bold
          >
            {subtitle}:
          </Text>
          <ul className={styles.list}>
            {descriptionItems.map(item => (
              <Text
                key={item}
                className={styles.listItem}
                color="charcoal-3"
                as="li"
                variant="heading-16"
              >
                {item}
              </Text>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
