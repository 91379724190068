import { isLeafNode, isBranchNode } from '../blueprint/utils';

import { isValidationSchemaField } from './yupValidation';

import type { ValidationSchemaType } from './yupValidation';
import type { Node } from '../blueprint/types';
import type { Schema } from 'yup';

interface GenerateValidationRulesProps {
  node: Node | undefined;
  validationSchema: ValidationSchemaType;
  validation?: Record<string, Schema>;
}
/**
 * Recursively look up the validation field(s) defined for a node and its children (if any) to compile an object of validation rules
 * @param node - the entry node
 * @returns an object of validation rules
 */

export function generateValidationRules({
  node,
  validationSchema,
}: GenerateValidationRulesProps): [string, Schema][] {
  if (!node) {
    return [];
  }

  if (isBranchNode(node)) {
    return node.children
      .map(child => generateValidationRules({ node: child, validationSchema }))
      .flat(1);
  }

  if (
    isLeafNode(node) &&
    isValidationSchemaField(validationSchema, node.validation)
  ) {
    const validation = validationSchema[node.validation];

    return [[node.name, validation]];
  }

  return [];
}
