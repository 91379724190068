import type { RichSelectorValue } from '@farmersdog/corgi-x';
import { RichSelector } from '@farmersdog/corgi-x';
import { AddDogContent } from '../AddDogContent';

import { activityLevelResource } from '../../../../../assets/activity-level';
import type { AddDogFormChangeHandler } from '../../hooks/useAddDogForm';
interface ActivityLevelSceneProps {
  name: string;
  activityLevel: string;
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}
//TODO: Acivity level radio options only uses the large image even on large viewports. Update RichSelector to support this feature.
export const ActivityLevelScene = ({
  onCtaClick,
  name,
  activityLevel,
  onChange,
}: ActivityLevelSceneProps) => {
  const handleChange = (val: string | RichSelectorValue) => {
    onChange('activityLevel', val as string);
  };
  return (
    <AddDogContent
      heading={`What is ${name}'s activity level?`}
      onCtaClick={onCtaClick}
      sectionWidthConstraint="md"
      ctaDisabled={activityLevel === ''}
    >
      <RichSelector
        name="Activity level"
        value={activityLevel}
        onChange={handleChange}
        defaultImageIndex={1}
        defaultDescription="Select the activity level closest to your dog's."
        descriptionAtBottom
        descriptionAtBottomColor="charcoal-2"
        options={[
          {
            label: 'Not very active',
            description: 'Only moves when I come home',
            imageAlt: 'Dog laying down',
            image: {
              small: activityLevelResource.NotVeryActive,
              large: activityLevelResource.NotVeryActive,
            },
            value: 'Not Very Active',
          },
          {
            label: 'Active',
            description: 'Likes to play',
            imageAlt: 'Dog holding paw up',
            image: {
              small: activityLevelResource.Active,
              large: activityLevelResource.Active,
            },
            value: 'Active',
          },
          {
            label: 'Very active',
            description: 'Daily dog park trips and long walks',
            imageAlt: 'Dog with tennis ball',
            image: {
              small: activityLevelResource.VeryActive,
              large: activityLevelResource.VeryActive,
            },
            value: 'Very Active',
          },
          {
            label: 'Pro athlete',
            description: 'Daily jogs or 2 hour walks',
            imageAlt: 'Corgi on the grass',
            image: {
              small: activityLevelResource.ProAthlete,
              large: activityLevelResource.ProAthlete,
            },
            value: 'Pro Athlete',
          },
        ]}
      />
    </AddDogContent>
  );
};
