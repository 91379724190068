import { useGrainsQuestionExperiment } from '../../../hooks';
import { DropdownInput } from '../base/DropdownInput';

import styles from './GrainsPreferenceInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function GrainsPreferenceInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { useFeature } = props;

  const { shouldShowGrainsQuestion } = useGrainsQuestionExperiment({
    useFeature,
  });

  if (!shouldShowGrainsQuestion) return null;

  return (
    <div className={styles.container}>
      <DropdownInput {...props} />
    </div>
  );
}
