import noop from 'lodash/noop';

import { browserOnly } from '@farmersdog/utils';

interface MissingSegmentAnalyticsProps {
  initialized?: boolean;
}

declare global {
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS & MissingSegmentAnalyticsProps;
  }
}

const analytics = (callbackIfMethodIsUndefined?: () => void) => {
  const analyticsOrObject = browserOnly(
    window => window.analytics ?? {},
    () => ({})
  );

  return new Proxy(analyticsOrObject, {
    get(target, property) {
      const method: unknown = Reflect.get(target, property);

      if (typeof method === 'function') {
        return method;
      }

      if (property === 'user') {
        return () => ({
          id: noop,
        });
      }

      if (callbackIfMethodIsUndefined) {
        return callbackIfMethodIsUndefined;
      }

      return noop;
    },
  }) as SegmentAnalytics.AnalyticsJS;
};

export default analytics;
