import type { FullLeadViewFragment } from '../../../../graphql/types';

interface GetPetInfoToDisplayArgs {
  lead: FullLeadViewFragment | null;
}

interface GetPetInfoToDisplayReturn {
  formattedPetName: string;
  petInfoMap: string[];
}

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const formatBirthdayUnit = (unit: string, unitIsPlural: boolean) => {
  if (unit === 'years') {
    return unitIsPlural ? 'years' : 'year';
  } else if (unit === 'months') {
    return unitIsPlural ? 'months' : 'month';
  } else if (unit === 'weeks') {
    return unitIsPlural ? 'weeks' : 'week';
  }
  return;
};

export const staticFallback = [
  'Age',
  'Gender',
  'Neutered status',
  'Breed',
  'Body condition',
  'Activity level',
];

interface GetNeuturedDisplayInfoArgs {
  gender: string;
  petNeutered: boolean | null;
}

function getNeuturedDisplayInfo({
  gender,
  petNeutered,
}: GetNeuturedDisplayInfoArgs) {
  if (gender === 'female') {
    return petNeutered ? 'Spayed' : 'Not Spayed';
  }

  return petNeutered ? 'Neutered' : 'Not Neutered';
}

export function getPetInfoToDisplay({
  lead,
}: GetPetInfoToDisplayArgs): GetPetInfoToDisplayReturn {
  const isMultiPet = lead?.pets && lead?.pets.length > 1;

  if (isMultiPet) {
    return { formattedPetName: '', petInfoMap: staticFallback };
  }

  const petData = lead?.pets[0];
  const petNeutered = petData?.neutered;
  const petName = petData?.name ? petData.name : 'your dog';
  const numberOfBreeds = petData?.breeds ? petData?.breeds.length : undefined;
  const unitIsPlural = Boolean(
    petData?.birthdayInput && petData?.birthdayInput.amount > 1
  );
  const formattedBirthdayUnit =
    petData?.birthdayInput &&
    formatBirthdayUnit(petData?.birthdayInput.unit, unitIsPlural);

  const dynamicPetInfo =
    petData &&
    petData?.birthdayInput?.amount &&
    petData?.gender &&
    petData?.bodyCondition &&
    petData?.breeds[0] &&
    petData?.activityLevel &&
    formattedBirthdayUnit &&
    numberOfBreeds &&
    petNeutered !== undefined
      ? [
          `${petData.birthdayInput.amount} ${formattedBirthdayUnit} old`,
          petData.gender,
          getNeuturedDisplayInfo({ gender: petData.gender, petNeutered }),
          numberOfBreeds > 1
            ? `${petData.breeds[0]} + ${numberOfBreeds - 1} ${
                numberOfBreeds === 2 ? 'other' : 'others'
              }`
            : petData.breeds[0],
          `Body condition: ${capitalizeFirstLetter(petData.bodyCondition)}`,
          petData.activityLevel,
        ]
      : staticFallback;

  const formattedDynamicPetInfo = dynamicPetInfo.map(sentence => {
    return capitalizeFirstLetter(sentence);
  });

  return {
    formattedPetName: `${petName}’s`,
    petInfoMap: formattedDynamicPetInfo,
  };
}
