import { Nowrap, Text, useViewport } from '@farmersdog/corgi-x';

import { useLeadState } from '../../../../hooks';

import styles from './Title.module.css';

export const Title = () => {
  const viewport = useViewport();
  const isDesktop = viewport.md;
  const lead = useLeadState().getCachedLead();

  const dogNames =
    lead && lead.pets.length === 1 ? `${lead.pets[0].name}’s` : 'your dogs’';

  return (
    <>
      <Text
        align="center"
        as="h1"
        color="Kale3"
        topSpacing="sm"
        variant={isDesktop ? 'heading-40' : 'heading-28'}
        rightSpacing={isDesktop ? 'none' : 'md'}
        leftSpacing={isDesktop ? 'none' : 'md'}
        bold
      >
        Complete a Healthy Diet with Healthy Treats
      </Text>
      <Text
        as="h3"
        color="Kale3"
        align="center"
        variant={isDesktop ? 'article-20' : 'article-16'}
        topSpacing={isDesktop ? 'sm' : 'none'}
        rightSpacing={isDesktop ? 'none' : 'md'}
        leftSpacing={isDesktop ? 'none' : 'md'}
      >
        Our human-grade Snap Sticks are made with only a few whole ingredients{' '}
        <br className={styles.desktopBreakline} />
        and designed to work with {dogNames} <Nowrap>personalized plan.</Nowrap>
      </Text>
    </>
  );
};
