import reject from 'lodash/reject';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';

import type { ChangeFreshOrderSizeQuotesQuery } from '../../../network/fetchChangeFreshOrderSizeQuotes/fetchChangeFreshOrderSizeQuotes.cgs.generated-types';
import type { FrequencyInfo } from '../FrequencySelector';

export type FrequencySelectionProps = {
  frequencyInfo: FrequencyInfo;
  isMinFrequency: boolean;
  isMinPrice: boolean;
};

export function getFrequencySelections(
  suggestedFrequencies: ChangeFreshOrderSizeQuotesQuery['customer']['changeFreshOrderSizeQuotes']
): FrequencySelectionProps[] {
  const { min, max, current } = suggestedFrequencies;
  const nonNullFrequencies = reject(
    [min, max, current],
    value => value === null
  ) as FrequencyInfo[];

  const minFrequency = minBy(nonNullFrequencies, 'frequency');
  const minPrice = minBy(nonNullFrequencies, 'dailyConsumptionPrice');

  const sortedByFrequency = sortBy(nonNullFrequencies, obj => obj.frequency);
  const sortedUniqByFrequency = sortedUniqBy(
    sortedByFrequency,
    obj => obj.frequency
  );

  const result = sortedUniqByFrequency.map(frequencyInfo => ({
    frequencyInfo,
    isMinFrequency: minFrequency === frequencyInfo,
    isMinPrice: minPrice === frequencyInfo,
  }));

  return result;
}
