import useSignupRouter from 'src/pages/Signup/hooks/useSignupRouter';

import { useUpdateDiySubscription } from '../../Signup/DIY/hooks/useUpdateDiySubscription';

import { getSubscriptionByType } from 'src/pages/Signup/DIY/utils/getSubscriptionByType';

import type { UpdateDiySignupSubscriptionMutation } from 'src/graphql/types';
import { SubscriptionType } from 'src/graphql/types';

import { useDispatch, useSelector } from 'react-redux';
import { showError } from 'src/actions/ui';
import { setDiySubscription } from 'src/actions/signup';
import type { ApolloError } from '@apollo/client';
import { useDiyRecommendation } from 'src/pages/Signup/DIY/hooks/useDiyRecommendation';
import { selectDiySubscriptionCadence } from 'src/reducers/signup/user';
import { useTrackDiyRecipesSubmit } from './useTrackDiyRecipesSubmit';

export function useRecipeSubmit() {
  const dispatch = useDispatch();
  const { goToNext } = useSignupRouter();
  const { products } = useDiyRecommendation();
  const currentCadence = useSelector(selectDiySubscriptionCadence);
  const { trackDiyRecipesSubmit } = useTrackDiyRecipesSubmit();

  const onError = (error: ApolloError) => {
    dispatch(showError(error));
  };

  const onCompleted = (data?: UpdateDiySignupSubscriptionMutation | null) => {
    if (data) {
      const diySubscription = getSubscriptionByType({
        subscriptions: data.updateMySubscription.subscriptions,
        type: SubscriptionType.Diy,
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(setDiySubscription(diySubscription));
      trackDiyRecipesSubmit();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      goToNext();
    } else {
      dispatch(
        showError(new Error('Unable to fetch updated subscription data'))
      );
    }
  };

  const [updateSubscription, updateSubscriptionState] =
    useUpdateDiySubscription({ onCompleted, onError });

  const { loading, error } = updateSubscriptionState;

  const submit = () => {
    const diySubscription = {
      frequency: currentCadence,
      subscriptionProducts: products,
      type: SubscriptionType.Diy,
    };

    void updateSubscription({ variables: { input: diySubscription } });
  };

  return {
    loading,
    error,
    submit,
  };
}
