import { useQuery } from '@apollo/client';
import query from './fetchLpfEducationData.cgs.graphql';
import type {
  FetchLpfEducationDataQueryVariables,
  FetchLpfEducationDataQuery,
} from './fetchLpfEducationData.cgs.generated-types';
import type { PetFieldsFragment } from '../fragments';

interface UseFetchLpfEducationDataProps {
  pet: PetFieldsFragment;
  recipeName: string;
  recipeDisplayName: string;
  recipeId: number;
}
export function useFetchLpfEducationData(props: UseFetchLpfEducationDataProps) {
  return useQuery<
    FetchLpfEducationDataQuery,
    FetchLpfEducationDataQueryVariables
  >(query, {
    context: { endpoint: 'cgs' },
    variables: {
      petData: {
        petName: props.pet.name,
      },
      recipeData: {
        petId: props.pet.id,
        recipes: [
          {
            displayName: props.recipeDisplayName,
            name: props.recipeName,
            id: props.recipeId,
          },
        ],
      },
    },
  });
}
