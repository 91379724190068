import { Nowrap } from '@farmersdog/corgi-x';
import type { ReactNode } from 'react';

export interface Item {
  id: number;
  title: string;
  description: string | ReactNode;
  accessibleTitle?: string;
}

export const ITEMS: Array<Item> = [
  {
    id: 1,
    title: '“Meals”',
    description: (
      <>
        Powders like “Chicken Meal” can contain meat from{' '}
        <Nowrap>diseased livestock.</Nowrap>
      </>
    ),
    accessibleTitle: `No powdered meal from diseased livestock`,
  },
  {
    id: 2,
    title: 'Preservatives',
    description: (
      <>
        Natural and/or artificial preservatives can keep pet foods shelf-stable{' '}
        <Nowrap>for years.</Nowrap>
      </>
    ),
  },
  {
    id: 3,
    title: 'Brown Pellets',
    description: (
      <>
        Kibble is extremely processed regardless of the ingredient claims{' '}
        <Nowrap>(or pictures)</Nowrap>.
      </>
    ),
  },
  {
    id: 4,
    title: 'Label Tricks',
    description: (
      <>
        Foods can hide behind misleading bags. “Made with beef” can mean just{' '}
        <Nowrap>3% beef.</Nowrap>
      </>
    ),
  },
];
