import { useQuery } from '../../services/apollo';

import query from './petsWithRecommendedCalories.graphql';

import type {
  FetchPetsWithRecommendedCaloriesQuery,
  FetchPetsWithRecommendedCaloriesQueryVariables,
} from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.QueryHookOptions<
  FetchPetsWithRecommendedCaloriesQuery,
  FetchPetsWithRecommendedCaloriesQueryVariables
>;

export function usePetsWithRecommendedCalories(options?: Options) {
  return useQuery<
    FetchPetsWithRecommendedCaloriesQuery,
    FetchPetsWithRecommendedCaloriesQueryVariables
  >(query, options);
}
