import { NodeNames } from '@farmersdog/constants';

import type { UpdateSchemaFragments } from '../types';

interface FragmentConfig {
  fragments: UpdateSchemaFragments[];
  getFragmentCount: (val: unknown) => number;
}

interface FragmentConfigType {
  [key: string]: FragmentConfig;
}

export const fragmentConfig: FragmentConfigType = {
  [NodeNames.NumPets]: {
    fragments: [
      { source: 'Pet Name', target: 'Pet Names' },
      { source: 'Pet', target: 'Pets' },
      {
        source: 'Recipes Form',
        target: 'Recipes',
      },
    ],
    getFragmentCount: (value: unknown) => {
      if (typeof value === 'number') {
        return value;
      }

      if (typeof value === 'string') {
        return parseInt(value) ?? 1;
      }

      return 1;
    },
  },
  [NodeNames.BodyCondition]: {
    fragments: [
      {
        source: 'Pet Ideal Weight',
        target: 'Ideal Weight',
        indexedTarget: true,
      },
    ],
    getFragmentCount: (value: unknown) => (value === 'just right' ? 0 : 1),
  },
  [NodeNames.FoodType]: {
    fragments: [
      {
        source: 'Pet Food Brand',
        target: 'Food Brand',
        indexedTarget: true,
      },
    ],
    getFragmentCount: (value: unknown) => (value !== 'homemade food' ? 1 : 0),
  },
  [NodeNames.Healthy]: {
    fragments: [
      {
        source: 'Pet Health Issues',
        target: 'Health Issues',
        indexedTarget: true,
      },
    ],
    getFragmentCount: (value: unknown) => {
      return value === true ? 1 : 0;
    },
  },
  [NodeNames.PrescriptionDiet]: {
    fragments: [
      {
        source: 'Pet Prescription Diets',
        target: 'Prescription Diets',
        indexedTarget: true,
      },
    ],
    getFragmentCount: (value: unknown) => {
      return value === true ? 1 : 0;
    },
  },
};
