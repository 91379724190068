import type { LiveMessageProps } from '@farmersdog/corgi';
import { LiveMessage } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import styles from './InlineError.module.css';

import type { ReactNode } from 'react';

export interface InlineErrorProps extends Omit<LiveMessageProps, 'aria-live'> {
  children: ReactNode;
  in: boolean;
}

export function InlineError({
  children,
  in: show = false,
  ...props
}: InlineErrorProps) {
  return (
    <LiveMessage
      hidden={!show}
      className={styles.container}
      aria-live="assertive"
      {...props}
    >
      <Text variant="heading-16" color="carrot-2" className={styles.text}>
        {children}
      </Text>
    </LiveMessage>
  );
}
