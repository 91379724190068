import type { Operation } from '@apollo/client';
import config from 'src/config';
import { GraphQLEndpoints } from '../Apollo/constants';

export function getUriForHttpLink(operation: Operation): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const endpoint = operation.getContext().endpoint;
  const coreApiBaseUrl = config('app.apiUrl') as string;
  const tosaBaseApiUrl = config('tosa.app.api.url') as string;
  switch (endpoint) {
    case GraphQLEndpoints.CGS:
      return `${coreApiBaseUrl}/customer-graphql`;
    case GraphQLEndpoints.AUTH:
      return `${coreApiBaseUrl}/login`;
    case GraphQLEndpoints.TOSA:
      return `${tosaBaseApiUrl}/graphql`;
    default:
      return coreApiBaseUrl;
  }
}
