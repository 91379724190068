import type { UpdateSchemaFragments, UseFeatureHookReturn } from '../types';

interface ExperimentFragmentConfigType {
  [experimentName: string]: {
    [nodeName: string]: {
      fragments: UpdateSchemaFragments[];
      getFragmentCount: (
        value: unknown,
        experimentReturn: UseFeatureHookReturn | undefined
      ) => number;
    };
  };
}

export const experimentFragmentConfig: ExperimentFragmentConfigType = {};
