export const questions = [
  {
    question: 'What makes The Farmer’s Dog treats the best option for dogs?',
    answer:
      'We put dogs’ health first in every decision we made about our treats – just like our food. They’re made exclusively in safe, human food facilities, and contain only a few ingredients – nothing that dogs don’t need. And to give owners additional peace of mind when feeding our treats, we use your dog’s existing meal plan to calculate how many you can feed to help them maintain a healthy weight.',
  },
  {
    question: 'Why is feeding human-grade important?',
    answer:
      'It’s the most effective way to ensure what your dog is eating is safe and healthy for them! The pet food industry is shockingly deceptive. Pet food products do not have to meet the same quality standards as human food, but pet food manufacturers can make their products seem much healthier than they really are. Marketing terms like “Healthy”, “Premium”, and “Holistic” are everywhere on pet food packages – but are so broadly defined they’re effectively meaningless words. The biggest problem is the extreme processing used to create pet food – which is so harsh it severely damages ingredients’ natural nutrients. It’s also legal for pet food companies to use ingredients that are considered too low quality for human food. Diseased meats can be used in dog food without being shown anywhere on the label as they’re processed into “meals” or “fats”. Human-grade products are produced to much higher safety standards – meaning both the ingredients and the processes used to make them are significantly better for your dog.',
  },
  {
    question: 'Why is a personalized plan important for treats?',
    answer:
      'Excessive treating is one of the quickest ways to cause weight gain or throw off a complete and balanced diet for dogs. Overweight dogs can live on average 2.5 years less than dogs at healthy weights (and over 50% of dogs in the US are currently overweight). Using your dog’s existing meal plan, we can recommend exactly how many daily calories they can have from treats to help maintain a healthy weight, allowing for worry-free treating.',
  },
  {
    question:
      'Are treats only available as a subscription? Can I get a sample or one-off?',
    answer:
      'Currently our treats are only available as an add-on to your existing orders of fresh meals. Delivering them with your existing orders allows us to use your dog’s meal plan to calculate how many you can feed to help your dog maintain a healthy weight, and provide a recommendation for how many bags you should get so you don’t run out between orders. You can easily skip receiving treats in an upcoming order, edit which treats you get, or remove them from your plan entirely in your account. While we don’t currently offer samples, it’s simple to get a bag to try! Add one pack of treats of your choice to your plan, then skip or remove treats from your future orders until you’ve decided if they’re right for you and your dog.',
  },
  {
    question: 'How do I store treats? Do they need refrigeration?',
    answer:
      'To make our treats as convenient to use as possible, you can store them in a cool, dry place and they’ll be good for up to 30 days after opening. But if you do store them in the fridge, they can last up to 60 days after opening!',
  },
];
