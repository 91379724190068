import { useMemo } from 'react';

import { cookie } from '@farmersdog/lead-browser-storage';

import { FeatureName } from '../../utils/features';
import { useGlobalDiscountOverride } from '../useGlobalDiscountOverride';

import type { Experiments, UseFeatureHook } from '../../types';

export function useAnonymousExperiments(
  useFeature: UseFeatureHook
): Experiments {
  const isDefaultDiscountEnabled = useFeature(FeatureName.CVR_DEFAULT_DISCOUNT);
  const isTosaHighTrafficModeEnabled = useFeature(
    FeatureName.CVR_TOSA_HIGH_TRAFFIC_MODE
  );

  const { globalDiscountOverride } = useGlobalDiscountOverride(useFeature);

  const isDaisyModeEnabled = useFeature(FeatureName.CVR_DAISY_MODE_ENABLED);

  const utmSource = cookie.getUtmSource();
  const skipGetStarted = useFeature(FeatureName.CVR_SKIP_GET_STARTED, {
    attributes: { utmSource },
  });

  const experiments = useMemo(
    () => ({
      [FeatureName.CVR_DAISY_MODE_ENABLED]: isDaisyModeEnabled,
      [FeatureName.CVR_DEFAULT_DISCOUNT]: isDefaultDiscountEnabled,
      [FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE]: globalDiscountOverride,
      [FeatureName.CVR_TOSA_HIGH_TRAFFIC_MODE]: isTosaHighTrafficModeEnabled,
      [FeatureName.CVR_SKIP_GET_STARTED]: skipGetStarted,
    }),
    [
      isDaisyModeEnabled,
      isDefaultDiscountEnabled,
      globalDiscountOverride,
      isTosaHighTrafficModeEnabled,
      skipGetStarted,
    ]
  );

  return experiments;
}
