import type { ReactNode } from 'react';

import type { VariantColors } from '@farmersdog/corgi-x';
import { Text, Strikeout } from '@farmersdog/corgi-x';

import styles from './FoodItem.module.scss';

interface FoodItemProps {
  title: string;
  accessibleTitle?: string;
  children: ReactNode;
  color?: VariantColors;
  isTitleStrikeOut?: boolean;
  isTitleBold?: boolean;
}

function FoodItem({
  title,
  accessibleTitle,
  color = 'white',
  isTitleStrikeOut,
  isTitleBold,
  children,
}: FoodItemProps) {
  const ariaLabel =
    !accessibleTitle && isTitleStrikeOut ? `No ${title}` : accessibleTitle;
  return (
    <div className={styles.container}>
      <Text
        className={styles.title}
        variant="heading-28"
        as="h5"
        color={color}
        bold={isTitleBold}
        aria-label={ariaLabel}
      >
        {isTitleStrikeOut ? (
          <Strikeout className={styles.strikeout}>{title}</Strikeout>
        ) : (
          title
        )}
      </Text>
      <Text variant="article-16" as="p" color={color} vSpacing="none">
        {children}
      </Text>
    </div>
  );
}

export default FoodItem;
