import { events, track } from 'src/analytics';
import type { TreatPackageSize } from 'src/data/treats/constants/TreatPackageSize';
import type { SnapSticksRecipeDisplayName } from 'src/data/treats/treats';

interface TrackTreatsUnavailableOptionSelectedArgs {
  recipe: SnapSticksRecipeDisplayName;
  packageSize: TreatPackageSize;
}

export function trackTreatsUnavailableOptionSelected(
  props: TrackTreatsUnavailableOptionSelectedArgs
) {
  track(events.treats_unavailable_option_selected, { ...props });
}
