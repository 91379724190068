import { RecipeNames } from '@farmersdog/constants';

import beefJp2 from '../assets/beef.jp2';
import beefPng from '../assets/beef.png';
import beefWebP from '../assets/beef.webp';
import chickenGreensJp2 from '../assets/chicken-greens.jp2';
import chickenGreensPng from '../assets/chicken-greens.png';
import chickenGreensWebP from '../assets/chicken-greens.webp';
import chickenOatsJp2 from '../assets/chicken-oats.jp2';
import chickenOatsPng from '../assets/chicken-oats.png';
import chickenOatsWebP from '../assets/chicken-oats.webp';
import porkGrainJp2 from '../assets/pork-grain.jp2';
import porkGrainPng from '../assets/pork-grain.png';
import porkGrainWebP from '../assets/pork-grain.webp';
import porkJp2 from '../assets/pork.jp2';
import porkPng from '../assets/pork.png';
import porkWebP from '../assets/pork.webp';
import turkeyJp2 from '../assets/turkey.jp2';
import turkeyPng from '../assets/turkey.png';
import turkeyWebP from '../assets/turkey.webp';

export const bowlPictureSources: Record<string, string[]> = {
  [RecipeNames.TURKEY]: [turkeyWebP, turkeyJp2, turkeyPng],
  [RecipeNames.BEEF]: [beefWebP, beefJp2, beefPng],
  [RecipeNames.PORK]: [porkWebP, porkJp2, porkPng],
  [RecipeNames.CHICKEN_AND_GREENS]: [
    chickenGreensWebP,
    chickenGreensJp2,
    chickenGreensPng,
  ],
  [RecipeNames.CHICKEN_CHIA]: [
    chickenGreensWebP,
    chickenGreensJp2,
    chickenGreensPng,
  ],
  [RecipeNames.CHICKENL]: [
    chickenGreensWebP,
    chickenGreensJp2,
    chickenGreensPng,
  ],
  [RecipeNames.CHICKEN_OATS]: [chickenOatsWebP, chickenOatsJp2, chickenOatsPng],
  [RecipeNames.CHICKEN_OATS_COLLARDS]: [
    chickenOatsWebP,
    chickenOatsJp2,
    chickenOatsPng,
  ],
  [RecipeNames.PORK_GRAIN]: [porkGrainWebP, porkGrainJp2, porkGrainPng],
};
