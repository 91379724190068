import type { ReactNode } from 'react';
import classNames from 'classnames';
import { ContentWidth } from '@farmersdog/corgi';
import { Button, PageWidth, Text } from '@farmersdog/corgi-x';
import { useCorgiViewport } from 'src/screen';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import { Content } from '../Content';
import styles from './AlignedMediaContent.module.scss';
import type { PictureProps } from 'src/components/Picture';
import Picture, { LoadingOption } from 'src/components/Picture';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AlignMediaOptions = ['start', 'end'] as const;

export interface AlignedMediaContentProps {
  /** The title of this section */
  title: ReactNode;
  /** The content to display in this section  */
  content: ReactNode;
  /** Where to align the media node on the wide view */
  alignMedia: (typeof AlignMediaOptions)[number];
  /** A media asset to display. Usually image or video */
  media: PictureProps;
  /** The text to use for the call to action button */
  cta: ReactNode;
  /** Optional class name */
  className?: string;
}

/**
 * Renders a section of content that features an aligned media and title
 * followed by content. In smaller viewports the alignment collapses to a
 * vertically stacked flow.
 *
 * Large viewport
 *
 * Media * Title // or Title * Media
 * *  Content  *
 *
 * Small viewport
 *
 * *   Media   *
 * *   Title   *
 * *  Content  *
 */
export function AlignedMediaContent({
  title,
  content,
  media,
  cta,
  alignMedia = 'start',
  className,
}: AlignedMediaContentProps) {
  const responsiveClassName = useCorgiViewport({
    xs: styles.xs,
    lg: styles.lg,
  });

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  return (
    <section className={classNames(className, responsiveClassName)}>
      <div className={classNames(styles.mediaContainer, styles[alignMedia])}>
        <div className={styles.media}>
          <Picture {...media} loading={LoadingOption.lazy} />
        </div>
        {useCorgiViewport({
          xs: null,
          lg: (
            <ContentWidth fr={1}>
              <Text
                as="h2"
                variant="heading-40"
                color="kale-3"
                className={styles.title}
                bold
              >
                {title}
              </Text>
            </ContentWidth>
          ),
        })}
      </div>
      <PageWidth>
        <ContentWidth fr={2} alignSelf="center" withPadding>
          {useCorgiViewport({
            xs: (
              <Text
                as="h2"
                variant="heading-28"
                color="kale-3"
                className={styles.title}
                bold
              >
                {title}
              </Text>
            ),
            lg: null,
          })}
          <Content className={styles.content}>{content}</Content>
          {cta && (
            <Button
              variant="primary"
              type="link"
              to={PATH_SIGNUP_ME}
              className={classNames(styles.cta, {
                [styles.accessibilityTheme]: isAccessibilityThemeOn,
              })}
            >
              {cta}
            </Button>
          )}
        </ContentWidth>
      </PageWidth>
    </section>
  );
}
