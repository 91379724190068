import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useViewport } from 'src/screen';
import { Text } from '@farmersdog/corgi-x';

import styles from './IconHeadline.module.scss';

function IconHeadline({ children, icon, headline }) {
  return (
    <div className={classNames(styles.container, useViewport(styles))}>
      <div className={styles.icon}>{icon}</div>
      <div>
        <Text
          variant="heading-22"
          color="kale-3"
          as="h2"
          bold
          className={styles.headline}
        >
          {headline}
        </Text>
        <Text
          as="p"
          variant="article-16"
          color="charcoal-3"
          className={styles.text}
        >
          {children}
        </Text>
      </div>
    </div>
  );
}

IconHeadline.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  headline: PropTypes.node,
};

export default IconHeadline;
