import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import type { UseAuth } from './useAuth';
import { useAuth } from './useAuth';
import { getTokenFromCookie, getUserIdFromToken } from '../../../utils';
import type { WebsiteAuth } from '../../App.types';

export const initialAuthContext: UseAuth = {
  userId: getUserIdFromToken(getTokenFromCookie()),
  token: getTokenFromCookie(),
  setToken: () => undefined,
  isLoggedIn: Boolean(getTokenFromCookie()),
  logout: () => undefined,
};

export const AuthContext = createContext<UseAuth>(initialAuthContext);

interface AuthProviderProps {
  children: ReactNode;
  websiteAuth?: WebsiteAuth;
}

export function AuthProvider({ children, websiteAuth }: AuthProviderProps) {
  const auth = useAuth(websiteAuth);
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  return authContext;
}
