import boxJpg from './box.jpg';
import boxWebp from './box.webp';
import ringosPlanDogJpg from './ringosPlanDog.jpg';
import ringosPlanDogWebp from './ringosPlanDog.webp';
import treatsJpg from './treats.jpg';
import treatsWebp from './treats.webp';

export const ringosPlanAssets = [ringosPlanDogWebp, ringosPlanDogJpg];
export const boxAssets = [boxWebp, boxJpg];
export const treatsAssets = [treatsWebp, treatsJpg];
