import { useQuery } from '@apollo/client';
import getClinicsQuery from './GetClinics.cgs.graphql';
import type {
  GetClinicsQuery,
  GetClinicsQueryVariables,
} from './GetClinics.cgs.generated-types';

export function useGetClinics() {
  return useQuery<GetClinicsQuery, GetClinicsQueryVariables>(getClinicsQuery, {
    context: { endpoint: 'cgs' },
    fetchPolicy: 'cache-first',
  });
}
