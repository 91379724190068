import config from 'src/config';
import type { TreatmentWithConfig } from './types';
import { splitDebug } from 'src/debug';

/**
 * Attempts to get treatment data from query parameters
 *
 * @param name - Feature name
 */
export function getTreatmentFromDebug<TreatmentType, ConfigType>(
  name: string
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
): TreatmentWithConfig<TreatmentType, ConfigType | {}> | undefined {
  if (!config('splitio.override')) return;

  const splitDebugResult = splitDebug.get(name);

  // control is from a loading state; we should ignore it
  if (splitDebugResult && splitDebugResult.treatment !== 'control') {
    return splitDebugResult as unknown as TreatmentWithConfig<
      TreatmentType,
      // eslint-disable-next-line @typescript-eslint/no-empty-object-type
      ConfigType | {}
    >;
  }

  return undefined;
}
