import type {
  StaticDataTypeNames,
  GraphQLType,
  StaticDataEntities,
} from './types';
import type { StaticData } from '../../types';

export function findByTypename<
  O extends StaticDataEntities,
  T extends StaticDataTypeNames,
>(__typename: T, staticData: StaticData): GraphQLType<O, T>[] {
  const valuesOfTypeName = Object.values(staticData).find(values => {
    if (typeof values === 'string' || !Array.isArray(values)) {
      return false;
    }

    for (const value of values) {
      if (isGraphQLTypeOfTypeName(value as string, __typename)) {
        return true;
      }
    }
    return false;
  }) as GraphQLType<O, T>[];

  if (!valuesOfTypeName) {
    throw new Error(`Cannot find typename ${__typename}`);
  }

  return valuesOfTypeName;
}

function isGraphQLTypeOfTypeName<
  O extends StaticDataEntities,
  T extends StaticDataTypeNames,
>(
  value: { __typename?: string } | undefined | string,
  __typename: T
): value is GraphQLType<O, T> {
  if (typeof value === 'string' || !value) {
    return false;
  }

  return value.__typename === __typename;
}
