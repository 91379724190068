import { useContext, createContext } from 'react';

import type { Experiments } from '../types';
import type { ReactNode } from 'react';

interface ContextProviderProps {
  children: ReactNode;
  experiments: Experiments;
}

interface AnonymousExperimentsContextInterface {
  experiments: Experiments;
}

const AnonymousExperimentsContext =
  createContext<AnonymousExperimentsContextInterface>({
    experiments: {},
  });

export const AnonymousExperimentsContextProvider = ({
  children,
  experiments,
}: ContextProviderProps) => {
  return (
    <AnonymousExperimentsContext.Provider value={{ experiments }}>
      {children}
    </AnonymousExperimentsContext.Provider>
  );
};

export function useAnonymousExperimentsContext() {
  return useContext(AnonymousExperimentsContext);
}
