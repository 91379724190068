import { useLightboxControl } from '@farmersdog/corgi';
import { useState } from 'react';
import type { SuggestedAddressFlowValues } from './useEditAccountSubmit';
import { useEditAccountSubmit } from './useEditAccountSubmit';
import type { AddressToUse } from './validateShippingAddress';

interface UseEditAccountProps {
  onSubmitCompleted: () => void;
  onSubmitError: (error: unknown) => void;
}

export function useEditAccount(props: UseEditAccountProps) {
  /*
  If the customer receives a suggested address during shipping address
  validation, we store the submitted address, suggested address, and submitted
  form values. Then we can re-submit the update account submission once the
  customer confirms their preferred shipping address.
  */
  const [suggestedAddressFlowValues, setSuggestedAddressFlowValues] =
    useState<SuggestedAddressFlowValues>();

  const suggestedAddressModalProps = useLightboxControl();

  const updateAccountSubmit = useEditAccountSubmit({
    onSuggestedAddressReceived: (newValues: SuggestedAddressFlowValues) => {
      setSuggestedAddressFlowValues(newValues);
      suggestedAddressModalProps.open();
    },
    onCompleted: props.onSubmitCompleted,
    onError: props.onSubmitError,
  });

  function onSuggestedAddressCancel() {
    suggestedAddressModalProps.close();
    setSuggestedAddressFlowValues(undefined);
    void updateAccountSubmit.reset();
  }

  function onSuggestedAddressConfirm(addressToUse: AddressToUse) {
    suggestedAddressModalProps.close();
    if (!suggestedAddressFlowValues) {
      // note: this is impossible if only used within SuggestedAddressModal
      throw new Error('suggested address flow values is undefined');
    }

    void updateAccountSubmit.submit({
      values: {
        ...suggestedAddressFlowValues.editAccountFormValues,
        shippingAddress:
          addressToUse === 'submitted'
            ? suggestedAddressFlowValues.submittedAddress
            : suggestedAddressFlowValues.suggestedAddress,
      },
      hasEditedBilling: suggestedAddressFlowValues.hasEditedBilling,
      hasVerifiedShippingAddress: true,
    });
  }

  return {
    updateAccountSubmit,
    suggestedAddressModalProps,
    suggestedAddressFlowValues,
    onSuggestedAddressCancel,
    onSuggestedAddressConfirm,
  };
}
