/* CH#49502: Used by CVR */
import { useSelector } from 'react-redux';
import { Nowrap } from '@farmersdog/corgi-x';

import { selectReferrer } from 'src/reducers/referrer';
import { DiscountType } from 'src/graphql/enums';
import {
  getDiscountAmountCopy,
  getDiscountTypeAndAmount,
} from 'src/utils/referral';
import type { Referrer } from 'src/hooks/useReferrer';

enum DiscountCTATextTypes {
  createPlan = 'createPlan',
  freeTrial = 'freeTrial',
  percentage = 'percentage',
}

const CTA_TEXT = {
  [DiscountCTATextTypes.createPlan]: {
    trackableText: 'Create your plan',
    ui: <span>Create your plan</span>,
  },
  [DiscountCTATextTypes.freeTrial]: {
    trackableText: 'Redeem free trial',
    ui: (
      <span>
        Redeem <Nowrap>free trial</Nowrap>
      </span>
    ),
  },
  [DiscountCTATextTypes.percentage]: {
    trackableText: (discountAmountCopy: string) =>
      `Redeem ${discountAmountCopy}`,
    ui: (discountAmountCopy: string) => (
      <span>
        Redeem <Nowrap>{discountAmountCopy}</Nowrap>
      </span>
    ),
  },
};

export const getCtaText = (
  discountAmount: number,
  discountType: DiscountType
) => {
  if (discountAmount === 0) {
    return CTA_TEXT[DiscountCTATextTypes.createPlan];
  }

  if (discountType === DiscountType.percentage && discountAmount === 100) {
    return CTA_TEXT[DiscountCTATextTypes.freeTrial];
  }

  const discountAmountCopy = getDiscountAmountCopy({
    discountAmount,
    discountType,
  });

  const { trackableText: percentageText, ui: percentageUi } =
    CTA_TEXT[DiscountCTATextTypes.percentage];

  return {
    trackableText: percentageText(discountAmountCopy),
    ui: percentageUi(discountAmountCopy),
  };
};

export function DiscountCTAText() {
  const referrer = useSelector(selectReferrer) as Referrer;

  const { discountAmount, discountType } = getDiscountTypeAndAmount(referrer);

  return getCtaText(discountAmount, discountType).ui;
}
