import { useSelector } from 'react-redux';

import { useFeature } from 'src/abTesting';
import { CVR_GLOBALDISCOUNT_OVERRIDE } from 'src/abTesting/features';
import type {
  ToggleTreatment,
  GlobalDiscountAttributes,
} from 'src/abTesting/types';
import { selectReferrer, selectHasCalledFetch } from 'src/reducers/referrer';
import {
  getDiscountPercentage,
  getDiscountTypeAndAmount,
  isReferrerValid,
} from 'src/utils/referral';
import { getReferrerCookie } from 'src/utils/cookies';

import { DiscountType } from 'src/graphql/enums';

export interface DiscountConfig {
  /* The discount percentage */
  discountPercentage: number;
  /* The banner copy to display */
  bannerCopy: string;
}

export interface Referrer {
  /* Whether the referrer is valid */
  invalid: boolean;
  /* The discount percentage of the referrer */
  discountPercentage: number;
  /* The discount amount */
  discountAmount: number;
  /* The discount type - fixed or percentage */
  discountType: DiscountType;
  /* The banner copy to display */
  bannerCopy?: string;
  /* The Coupon type on the backend, eg 'influencer' or 'internal' */
  type?: string;
  /* If it's a user referral, the name of the user */
  name?: string;
}

interface UseReferrerReturn {
  /* Whether the referrer is valid */
  isValid: boolean;
  /* TODO: remove this after Coupon table deprecates discountPercentage:
   * https://app.clubhouse.io/farmersdog/story/51967/remove-deprecated-discountpercentage-field-from-coupons-table.
   * The discount percentage of the referrer
   * */
  discountPercentage: number;
  /* The discount amount */
  discountAmount: number;
  /* The discount type - fixed or percentage */
  discountType: DiscountType;
  /* Whether the referrer has been fetched */
  hasCalledFetch: boolean;
  /* The fetched referrer */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  referrer: Referrer | {};
  /* the coupon code */
  couponCode?: string;
}

/**
 * Validate the global discount treatment
 *
 * @param config - The treatment config object
 */

function isValidGlobalDiscountConfig(config: DiscountConfig) {
  if (!Number.isFinite(config.discountPercentage)) {
    return false;
  }

  if (config.discountPercentage < 0 || config.discountPercentage > 100) {
    return false;
  }

  if (!config.bannerCopy) {
    return false;
  }

  return true;
}
export interface ReferrerCookie {
  referrerType: string;
  referrerCode: string;
}

/**
 * Return if a value is a valid referrer cookie
 *
 * @param value - A parsed referrer cookie
 */
function isValidReferrerCookie(value: unknown): value is ReferrerCookie {
  return Boolean(
    value && Object.prototype.hasOwnProperty.call(value, 'referrerCode')
  );
}

export const DIRECT_TRAFFIC_CODE = 'no_pcode';

/**
 * Return the current referrer
 */
export function useReferrer(): UseReferrerReturn {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const referrer = useSelector(selectReferrer);
  const isValid = isReferrerValid(referrer as Referrer);
  const discountPercentage = getDiscountPercentage(referrer as Referrer);
  const { discountAmount, discountType } = getDiscountTypeAndAmount(
    referrer as Referrer
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const hasCalledFetch = useSelector(selectHasCalledFetch);

  const referrerCookie = getReferrerCookie();
  const isReferrerCookieValid = isValidReferrerCookie(referrerCookie);

  const splitAttributes = {
    attributes: {
      referrerCode: isReferrerCookieValid
        ? referrerCookie.referrerCode
        : DIRECT_TRAFFIC_CODE,
      referrerType: isReferrerCookieValid ? referrerCookie.referrerType : '',
    },
  };

  const { treatment, config } = useFeature<
    ToggleTreatment,
    DiscountConfig,
    GlobalDiscountAttributes
  >(CVR_GLOBALDISCOUNT_OVERRIDE, splitAttributes);

  const isGlobalDiscountOverrideOn =
    treatment !== 'off' && treatment !== 'control';

  if (isGlobalDiscountOverrideOn && isValidGlobalDiscountConfig(config)) {
    return {
      referrer: {
        invalid: false,
        discountPercentage: config.discountPercentage,
        bannerCopy: config.bannerCopy,
        discountAmount: config.discountPercentage,
        discountType: DiscountType.percentage,
      },
      isValid: true,
      discountPercentage: config.discountPercentage,
      discountAmount: config.discountPercentage,
      discountType: DiscountType.percentage,
      hasCalledFetch: true,
    };
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    referrer: referrer || {},
    isValid,
    discountPercentage,
    discountType,
    discountAmount,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    hasCalledFetch,
    couponCode: splitAttributes.attributes.referrerCode,
  };
}

// export default useReferrer;
