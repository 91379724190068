import { useEffect } from 'react';

import { NodeNames } from '@farmersdog/constants';
import { Button, Text, ContentReveal } from '@farmersdog/corgi-x';

import { getNodeNameAndPosition } from '../../../../blueprint/utils';
import { useRemovePet } from '../../../../hooks';
import {
  formatPossessive,
  getPetNamesFromFormEntries,
} from '../../../../utils';
import { getMedicalConditionMessageFromConfig } from '../../../inputs/PetHealthCheckboxes/getMedicalConditionMessageFromConfig';
import {
  HealthIssuesConfig,
  PrescriptionDietsConfig,
} from '../../../inputs/PetHealthCheckboxes/MedicalConditionMessage/constants';

import { computeButtonText } from './computeButtonText';
import { doAnyMedicalConditionsSupportSkipping } from './doAnyMedicalConditionsSupportSkipping';
import { hasPrescriptions } from './hasPrescriptions';
import styles from './SubmitButton.css';

import type { BranchNode } from '../../../../blueprint/types';
import type { TOSAComponentInput } from '../../../../types';

export interface SubmitButtonProps extends TOSAComponentInput<BranchNode> {
  loading: boolean;
  fixedButton?: boolean;
}

export function SubmitButton(props: SubmitButtonProps) {
  const { node, progress, formMethods, schema } = props;
  const branchCompleted = progress.getBranchComplete(node.__self__);
  const { isValid, isSubmitting } = formMethods.formState;
  const { name: nodeName, position } = getNodeNameAndPosition(node.name);
  const { removePet } = useRemovePet();

  const formValues = formMethods.getValues();
  const numPets = formValues[NodeNames.NumPets];
  const { currentPetName } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName: node.name,
  });
  const formattedPetName = formatPossessive(currentPetName);

  const prescriptionDietsField =
    `${NodeNames.PrescriptionDiets}-${position}` as const;
  const selectedPrescriptionDiets = formValues[prescriptionDietsField] || [];
  const healthIssuesField = `${NodeNames.Issues}-${position}` as const;
  const selectedHealthIssues = formValues[healthIssuesField] || [];

  const doPrescriptionDietsSupportSkipping =
    doAnyMedicalConditionsSupportSkipping({
      medicalConditions: selectedPrescriptionDiets,
      medicalConditionsConfig: PrescriptionDietsConfig,
    });

  const doHealthConditionsSupportSkipping =
    doAnyMedicalConditionsSupportSkipping({
      medicalConditions: selectedHealthIssues,
      medicalConditionsConfig: HealthIssuesConfig,
    });

  const healthIssueMessage = getMedicalConditionMessageFromConfig({
    medicalConditions: selectedHealthIssues,
    medicalConditionsConfig: HealthIssuesConfig,
  });

  const hasPrescriptionDiets = hasPrescriptions({ node, formMethods });

  const buttonText = computeButtonText({
    hasHealthIssueWarning: Boolean(healthIssueMessage),
    hasPrescriptions: hasPrescriptionDiets,
  });

  const shouldShowSkipButton =
    nodeName === NodeNames.Health &&
    numPets &&
    numPets > 1 &&
    (doPrescriptionDietsSupportSkipping || doHealthConditionsSupportSkipping);

  useEffect(() => {
    void formMethods.trigger();
  }, [schema, formMethods]);

  const shouldReveal = branchCompleted;

  if (!shouldReveal) {
    return null;
  }

  return (
    <div className={styles.additionalWhiteSpace}>
      <ContentReveal in={shouldReveal} inAnimation="fade">
        <div className={styles.innerContainer}>
          {
            <Button
              aria-disabled={!isValid || isSubmitting}
              className={styles.button}
              loading={props.loading}
              type="submit"
              variant="special"
            >
              {buttonText}
            </Button>
          }

          {shouldShowSkipButton && (
            // Overrides font-size from TOSA.tsx
            <Text variant="heading-16">
              <Button
                variant="plain-text"
                aria-disabled={!isValid || isSubmitting}
                className={styles.button}
                loading={props.loading}
                onClick={() =>
                  removePet({
                    position,
                    componentProps: props,
                  })
                }
              >
                Skip {formattedPetName} Plan
              </Button>
            </Text>
          )}
        </div>
      </ContentReveal>
    </div>
  );
}
