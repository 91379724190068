import type { CancellationResponsesEnumType } from './cancellationResponses';
import { CancellationResponsesEnum } from './cancellationResponses';
import type { InitialStatesEnumType } from './initialStates';
import { InitialStatesEnum } from './initialStates';
import type { ReplacementFoodType } from './replacementFoodStates';
import { REPLACEMENT_FOOD } from './replacementFoodStates';
import type { StatesUnion } from './types';

/**
 * The "question state" represents a step in the cancellation reasons flow
 * where we display (1) a question, and (2) a list of response options.
 *
 * Each response has a unique key that corresponds 1:1 with the next step in
 * the flow.
 */
export interface QuestionState {
  /** A unique identifier for the state. */
  value:
    | CancellationResponsesEnumType
    | InitialStatesEnumType
    | ReplacementFoodType;
  /** The question prompt */
  question: string;
  /** The options we show for this question (keys only) */
  reasons: CancellationResponsesEnumType[];
}

const primaryState: QuestionState = {
  value: InitialStatesEnum.Primary,
  question: `What's the main reason you're canceling?`,
  reasons: [
    CancellationResponsesEnum.Price,
    CancellationResponsesEnum.OrderIssue,
    CancellationResponsesEnum.TempPause,
    CancellationResponsesEnum.DontLikeSubscriptions,
    CancellationResponsesEnum.FeedingFreshTooDifficult,
    CancellationResponsesEnum.FoodDidntWork,
    CancellationResponsesEnum.NoLongerWithDog,
  ],
};

const priceState: QuestionState = {
  value: CancellationResponsesEnum.Price,
  question: 'Can you tell us a little more?',
  reasons: [
    CancellationResponsesEnum.FinancialSituationChanged,
    CancellationResponsesEnum.HadntRealizedTotalCost,
    CancellationResponsesEnum.OrderIssue,
    CancellationResponsesEnum.FoodDidntWork,
    CancellationResponsesEnum.FeedingFreshTooDifficult,
    CancellationResponsesEnum.SubscriptionDoesntWorkForMe,
    CancellationResponsesEnum.OtherPriceReason,
  ],
};

const noLongerWithDogState: QuestionState = {
  value: CancellationResponsesEnum.NoLongerWithDog,
  question: 'What best describes your situation?',
  reasons: [
    CancellationResponsesEnum.NoLongerFostering,
    CancellationResponsesEnum.SomeoneElseCaringForDog,
    CancellationResponsesEnum.DogPassed,
  ],
};

const tempPauseState: QuestionState = {
  value: CancellationResponsesEnum.TempPause,
  question: 'Why are you looking to pause your orders?',
  reasons: [
    CancellationResponsesEnum.TravelingMoving,
    CancellationResponsesEnum.TooMuchFood,
    CancellationResponsesEnum.PreferOrderingWhenNeeded,
    CancellationResponsesEnum.NeedMoreTimeOnTrial,
  ],
};

const foodDidntWorkState: QuestionState = {
  value: CancellationResponsesEnum.FoodDidntWork,
  question: 'In what way did the food not work?',
  reasons: [
    CancellationResponsesEnum.PortionsCalsIncorrect,
    CancellationResponsesEnum.DidNotSeeAChange,
    CancellationResponsesEnum.ConcernedWithNutrition,
    CancellationResponsesEnum.PhysicalReaction,
    CancellationResponsesEnum.DogDidntLikeIt,
    CancellationResponsesEnum.NeedLifeStageSpecificFood,
  ],
};

const concernedWithNutritionState: QuestionState = {
  value: CancellationResponsesEnum.ConcernedWithNutrition,
  question: 'What is your specific concern with the food?',
  reasons: [
    CancellationResponsesEnum.ConcernedAboutIngredients,
    CancellationResponsesEnum.DogIsAllergic,
    CancellationResponsesEnum.VetRecommendedDifferentFood,
    CancellationResponsesEnum.NeedMoreOrOtherRecipes,
    CancellationResponsesEnum.NeedLifeStageSpecificFood,
  ],
};

const vetRecommendedDifferentFoodState: QuestionState = {
  value: CancellationResponsesEnum.VetRecommendedDifferentFood,
  question: 'Which statement best describes your vet’s feedback?',
  reasons: [
    CancellationResponsesEnum.VetHasRecipeConcerns,
    CancellationResponsesEnum.VetHasFreshFoodConcerns,
    CancellationResponsesEnum.VetRecommendedPrescriptionDiet,
    CancellationResponsesEnum.VetRecommendedDifferentFoodNonPrescription,
  ],
};

const needMoreOrOtherRecipesState: QuestionState = {
  value: CancellationResponsesEnum.NeedMoreOrOtherRecipes,
  question: 'What kind of recipe are you most interested in?',
  reasons: [
    CancellationResponsesEnum.NeedFishRecipe,
    CancellationResponsesEnum.NeedLowerFatRecipe,
    CancellationResponsesEnum.NeedVegetarianRecipe,
    CancellationResponsesEnum.NeedRecipeWithGrain,
    CancellationResponsesEnum.NeedOtherRecipe,
  ],
};

const vetRecommendedPrescriptionDietState: QuestionState = {
  value: CancellationResponsesEnum.VetRecommendedPrescriptionDiet,
  question: 'Which prescription diet does your dog require?',
  reasons: [
    CancellationResponsesEnum.Diabetes,
    CancellationResponsesEnum.HydrolizedProtein,
    CancellationResponsesEnum.JointSupport,
    CancellationResponsesEnum.LiverSupport,
    CancellationResponsesEnum.LowFat,
    CancellationResponsesEnum.NeoplasiaDiet,
    CancellationResponsesEnum.RenalDisease,
    CancellationResponsesEnum.SkinSupport,
    CancellationResponsesEnum.WeightLoss,
    CancellationResponsesEnum.WeightManagement,
  ],
};

const physicalReactionState: QuestionState = {
  value: CancellationResponsesEnum.PhysicalReaction,
  question: 'What kind of reaction did your dog have?',
  reasons: [
    CancellationResponsesEnum.VomitingDigestiveStool,
    CancellationResponsesEnum.AllergicReaction,
    CancellationResponsesEnum.OtherPhysicalReaction,
  ],
};

const feedingFreshTooDifficultState: QuestionState = {
  value: CancellationResponsesEnum.FeedingFreshTooDifficult,
  question: 'What aspect of feeding fresh food do you find most difficult?',
  reasons: [
    CancellationResponsesEnum.ServingTooMessy,
    CancellationResponsesEnum.PortioningTooDifficult,
    CancellationResponsesEnum.StoringThawingInconvenient,
    CancellationResponsesEnum.ReceivingDeliveriesInconvient,
  ],
};

const orderIssueState: QuestionState = {
  value: CancellationResponsesEnum.OrderIssue,
  question: 'What issue did you experience?',
  reasons: [
    CancellationResponsesEnum.FoodArrivedWarm,
    CancellationResponsesEnum.TooMuchFood,
    CancellationResponsesEnum.RanOutOfFood,
    CancellationResponsesEnum.DeliveryCarrierIssue,
    CancellationResponsesEnum.ItemsDamagedIncorrect,
    CancellationResponsesEnum.PortionsCalsIncorrect,
    CancellationResponsesEnum.CustomerServiceIssue,
    CancellationResponsesEnum.FoodQuality,
  ],
};

const deliverCarrierIssueState: QuestionState = {
  value: CancellationResponsesEnum.DeliveryCarrierIssue,
  question: 'What delivery/carrier issues did you experience?',
  reasons: [
    CancellationResponsesEnum.ScheduleUnpredictable,
    CancellationResponsesEnum.BoxesNotProperlyHandled,
    CancellationResponsesEnum.BoxesDelayed,
    CancellationResponsesEnum.OtherDeliveryIssue,
  ],
};

const storingThawingInconvenient: QuestionState = {
  value: CancellationResponsesEnum.StoringThawingInconvenient,
  question: 'Which aspect is most inconvenient?',
  reasons: [
    CancellationResponsesEnum.DontHaveStorageSpace,
    CancellationResponsesEnum.TransferringToFridgeDifficult,
    CancellationResponsesEnum.PreferShelfStable,
  ],
};

const dontLikeSubscriptions: QuestionState = {
  value: CancellationResponsesEnum.DontLikeSubscriptions,
  question: 'What is your issue with our subscription service?',
  reasons: [
    CancellationResponsesEnum.WantToFeedOneBoxAtATime,
    CancellationResponsesEnum.SubscriptionServicesDontWorkForMe,
    CancellationResponsesEnum.UnawareItWasASubscription,
  ],
};

const replacementFoodState: QuestionState = {
  value: REPLACEMENT_FOOD,
  question: `What will you be replacing The Farmer's Dog with?`,
  reasons: [
    CancellationResponsesEnum.DryFood,
    CancellationResponsesEnum.IllCookAtHome,
    CancellationResponsesEnum.OtherFreshFood,
    CancellationResponsesEnum.RawFood,
    CancellationResponsesEnum.WetFood,
    CancellationResponsesEnum.DehydratedFood,
    CancellationResponsesEnum.StillTheFarmersDog,
  ],
};

const dogDidntLikeItState: QuestionState = {
  value: CancellationResponsesEnum.DogDidntLikeIt,
  question: `What was your dog's experience with the food?`,
  reasons: [
    CancellationResponsesEnum.DogNeverTouchedFood,
    CancellationResponsesEnum.DogAteSome,
    CancellationResponsesEnum.DogUsedToLikeIt,
  ],
};

const questionStatesEnum: Record<string, QuestionState> = {
  [InitialStatesEnum.Primary]: primaryState,
  [CancellationResponsesEnum.Price]: priceState,
  [CancellationResponsesEnum.OrderIssue]: orderIssueState,
  [CancellationResponsesEnum.NoLongerWithDog]: noLongerWithDogState,
  [CancellationResponsesEnum.TempPause]: tempPauseState,
  [CancellationResponsesEnum.FoodDidntWork]: foodDidntWorkState,
  [CancellationResponsesEnum.ConcernedWithNutrition]:
    concernedWithNutritionState,
  [CancellationResponsesEnum.VetRecommendedDifferentFood]:
    vetRecommendedDifferentFoodState,
  [CancellationResponsesEnum.VetRecommendedPrescriptionDiet]:
    vetRecommendedPrescriptionDietState,
  [CancellationResponsesEnum.PhysicalReaction]: physicalReactionState,
  [CancellationResponsesEnum.FeedingFreshTooDifficult]:
    feedingFreshTooDifficultState,
  [CancellationResponsesEnum.DeliveryCarrierIssue]: deliverCarrierIssueState,
  [CancellationResponsesEnum.StoringThawingInconvenient]:
    storingThawingInconvenient,
  [CancellationResponsesEnum.DontLikeSubscriptions]: dontLikeSubscriptions,
  [REPLACEMENT_FOOD]: replacementFoodState,
  [CancellationResponsesEnum.DogDidntLikeIt]: dogDidntLikeItState,
  [CancellationResponsesEnum.NeedMoreOrOtherRecipes]:
    needMoreOrOtherRecipesState,
};

export const finalQuestionStates: StatesUnion[] = [
  CancellationResponsesEnum.NoLongerWithDog,
  REPLACEMENT_FOOD,
];

export const getQuestionStates = () => questionStatesEnum;
export const getPrescriptionDiets = () =>
  vetRecommendedPrescriptionDietState.reasons;
