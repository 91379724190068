import { PATH_SIGNUP_PETS } from '@farmersdog/constants/paths';

import type { BranchNode } from '../blueprint/types';

interface GetFirstPetFormStepArgs {
  formSteps: BranchNode[];
}

export function getFirstPetFormStep({ formSteps }: GetFirstPetFormStepArgs) {
  return formSteps.find(formStep => {
    return formStep.route === PATH_SIGNUP_PETS;
  });
}
