import type { ReactNode } from 'react';
import { useCorgiViewport } from 'src/screen';

import { Content } from '../Content';
import { Title } from '../Title';
import { CTA } from '../CTA';

import styles from './TitledContent.module.scss';

export interface TitledContentProps {
  title?: ReactNode;
  content?: ReactNode;
  cta?: ReactNode;
}

export function TitledContent({ title, content, cta }: TitledContentProps) {
  return (
    <div className={useCorgiViewport({ xs: styles.xs, lg: styles.lg })}>
      {title && (
        <Title color="kale-3" className={styles.title}>
          {title}
        </Title>
      )}
      {content && <Content className={styles.content}>{content}</Content>}
      {cta && <CTA>{cta}</CTA>}
    </div>
  );
}
