import { useSelector } from 'react-redux';

import { anonymousId } from '@farmersdog/lead-browser-storage';
import type { ReferrerCookie } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { getReferrerCookie } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { Logger } from '@farmersdog/logger';
import { browserOnly } from '@farmersdog/utils';

import { HomepageHeroImageTreatments, useFeature } from 'src/abTesting';
import { CVR_HOMEPAGE_HERO_IMAGE } from 'src/abTesting/features';
import { selectTokenId } from 'src/reducers/auth';
import { getReferrerCookieSplitAttributes } from 'src/server/middlewares/experimentsMiddleware';

interface UseCheckForHomepageHeroTreatmentMismatchProps {
  serverTreatment: HomepageHeroImageTreatments;
}

export function useCheckForHomepageHeroTreatmentMismatch({
  serverTreatment,
}: UseCheckForHomepageHeroTreatmentMismatchProps) {
  const userId = useSelector(selectTokenId) as string | undefined;
  const referrerCookie = getReferrerCookie() as ReferrerCookie | undefined;
  const homepageHeroExperimentAttributes =
    getReferrerCookieSplitAttributes(referrerCookie);

  const { treatment: clientTreatment, isReady } = useFeature(
    CVR_HOMEPAGE_HERO_IMAGE,
    {
      attributes: { ...homepageHeroExperimentAttributes },
    }
  );

  browserOnly(() => {
    if (
      isReady &&
      clientTreatment !== HomepageHeroImageTreatments.Control &&
      clientTreatment !== serverTreatment
    ) {
      const tfdAnonymousId = anonymousId.get();
      const log = new Logger('website:checkForHomepageHeroTreatmentMismatch');

      log.warn('Homepage Hero Experiment Treatment Mismatch: ', {
        clientTreatment,
        serverTreatment,
        userId,
        tfdAnonymousId,
        homepageHeroExperimentAttributes,
      });
    }
  });
}
