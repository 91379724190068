import { NodeNames } from '@farmersdog/constants';

import { blueprintTraverseApply, isLeafNode } from '../../blueprint/utils';
import {
  validationSchema,
  isValidationSchemaField,
} from '../../validation/yupValidation';

import type {
  Node,
  CompiledBlueprint,
  BranchNode,
} from '../../blueprint/types';
import type { UseFeatureHook, UseFormNavigateReturn } from '../../types';
import type { UseFormReturn } from 'react-hook-form';

export interface NodeProgress {
  id: string;
  name: string;
  complete: boolean;
  children?: NodeProgress[];
  blocked?: boolean;
}

export interface GetNodeComplete {
  (id: string): boolean;
}

export interface GetComplete {
  (): boolean;
}

export interface GetNextIncompleteBranch {
  (): NodeProgress;
}

export interface GetLastCompletedBranch {
  (): NodeProgress;
}

export interface GetFirstBlockedBranch {
  (): NodeProgress | undefined;
}

interface GetCompletedNodes {
  (): NodeProgress[];
}

export interface UseProgressArgs {
  schema?: CompiledBlueprint;
  formMethods: UseFormReturn;
  formSteps: BranchNode[];
  formNavigation: UseFormNavigateReturn;
  useFeature: UseFeatureHook;
}

export interface Progress {
  getPreviousLeafComplete: GetNodeComplete;
  getLeafComplete: GetNodeComplete;
  getBranchComplete: GetNodeComplete;
  getPreviousBranchComplete: GetNodeComplete;
  getPreviousBranchBlocked: GetNodeComplete;
  getComplete: GetComplete;
  getNextIncompleteBranch: GetNextIncompleteBranch;
  getLastCompletedBranch: GetLastCompletedBranch;
  getFirstBlockedBranch: GetFirstBlockedBranch;
  getCompletedLeafNodes: GetCompletedNodes;
}

export function useProgress({
  formMethods,
  formSteps,
  formNavigation,
}: UseProgressArgs): Progress {
  const branchNodes: NodeProgress[] = [];
  const leafNodes: NodeProgress[] = [];

  formSteps.forEach(branch => {
    const branchLeafNodes = getLeafNodeProgress(branch, formMethods);
    leafNodes.push(...branchLeafNodes);
    branchNodes.push({
      id: branch.__self__,
      name: branch.name,
      complete: branchLeafNodes.every(node => node.complete),
      blocked: branchLeafNodes.some(node => node.blocked),
      children: branchLeafNodes,
    });
  });

  return {
    getComplete() {
      const [lastBranchNode] = branchNodes.slice(-1);

      return (
        branchNodes.every(node => node.complete) &&
        formNavigation.current === lastBranchNode.id
      );
    },
    getPreviousLeafComplete(id: string) {
      const [nodeIndex, firstIncompleteIndex] = getCompletedIndices(
        id,
        leafNodes
      );

      if (firstIncompleteIndex < 0) {
        return true;
      }

      return nodeIndex - 1 < firstIncompleteIndex;
    },
    getLeafComplete(id: string) {
      const [nodeIndex, firstIncompleteIndex] = getCompletedIndices(
        id,
        leafNodes
      );

      if (firstIncompleteIndex < 0) {
        return true;
      }

      return nodeIndex < firstIncompleteIndex;
    },
    getPreviousBranchComplete(id: string) {
      const [nodeIndex, firstIncompleteIndex] = getCompletedIndices(
        id,
        branchNodes
      );

      if (firstIncompleteIndex < 0) {
        return true;
      }

      return nodeIndex - 1 < firstIncompleteIndex;
    },
    getPreviousBranchBlocked(id: string) {
      const [nodeIndex, firstBlockedIndex] = getBlockedIndices(id, branchNodes);

      // If there are no blocked nodes, return false
      if (firstBlockedIndex < 0) {
        return false;
      }

      return nodeIndex > firstBlockedIndex;
    },
    getBranchComplete(id: string) {
      const [nodeIndex, firstIncompleteIndex] = getCompletedIndices(
        id,
        branchNodes
      );

      if (firstIncompleteIndex < 0) {
        return true;
      }

      return nodeIndex < firstIncompleteIndex;
    },
    getNextIncompleteBranch() {
      return (
        branchNodes.find(branchNode => !branchNode.complete) ??
        branchNodes.slice(-1)[0]
      );
    },
    getLastCompletedBranch() {
      for (let i = 0; i < branchNodes.length; i++) {
        if (!branchNodes[i].complete) {
          if (i > 0) {
            return branchNodes[i - 1];
          } else if (i === 0) {
            return branchNodes[0]; // if no branches are complete, return the first branch
          }
        }
      }

      return branchNodes[branchNodes.length - 1]; // if all branch nodes were complete, return the last branch
    },
    getFirstBlockedBranch() {
      return branchNodes.find(branchNode => branchNode.blocked);
    },
    getCompletedLeafNodes() {
      return leafNodes.filter(leafNode => leafNode.complete);
    },
  };
}

function getLeafNodeProgress(root: Node, formMethods: UseFormReturn) {
  const leafNodes: NodeProgress[] = [];

  blueprintTraverseApply(root, node => {
    if (!isExcluded(node)) {
      const inputHasCurrentValue = isNotNullish(
        formMethods.getValues(node.name)
      );
      const { invalid } = formMethods.getFieldState(
        node.name,
        formMethods.formState
      );
      const hasValidInputValue = inputHasCurrentValue && !invalid;
      const isOptional = isFieldOptional(node);
      const hasDefaultValue = isNotNullish(node.input?.default); // default value is treated as isDirty=false

      let isComplete = hasValidInputValue || isOptional || hasDefaultValue;

      // temporary fix to force impacted users to fill out the breeds input which has been emptied out due to the bug in https://github.com/farmersdog/services/pull/2011
      if (node.name.startsWith(NodeNames.Breeds)) {
        const breedsValue = formMethods.getValues(node.name) as unknown;
        isComplete = Boolean(
          breedsValue && Array.isArray(breedsValue) && breedsValue.length > 0
        );
      }

      leafNodes.push({
        id: node.__self__,
        name: node.name,
        complete: isComplete,
      });
    }
  });

  return leafNodes;
}

function isExcluded(node: Node) {
  if (!isLeafNode(node)) {
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return Boolean(node.input?.excludeFromProgress);
}

/*
 * Return the specified node's index and the index of the first blocked node
 */
function getBlockedIndices(
  nodeId: string,
  nodes: NodeProgress[]
): [nodeIndex: number, firstBlockedIndex: number] {
  const nodeIndex = nodes.findIndex(node => node.id === nodeId);
  const firstBlockedIndex = nodes.findIndex(node => node.blocked === true);
  return [nodeIndex, firstBlockedIndex];
}

function getCompletedIndices(
  nodeId: string,
  nodes: NodeProgress[]
): [nodeIndex: number, firstIncompleteIndex: number] {
  const nodeIndex = nodes.findIndex(node => node.id === nodeId);
  const firstIncompleteIndex = nodes.findIndex(node => node.complete === false);

  return [nodeIndex, firstIncompleteIndex];
}

function isNotNullish(value: unknown) {
  if (value === '') {
    return false;
  }

  if (value === null) {
    return false;
  }

  if (value === undefined) {
    return false;
  }

  return true;
}

function isFieldOptional(node: Node) {
  const validation = node.validation;
  return isValidationSchemaField(validationSchema, validation)
    ? validationSchema[validation].spec.nullable
    : false;
}
