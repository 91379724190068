import type { RecipeNames } from '@farmersdog/constants';

export const cvr_weight_override = 'cvr_weight_override';
export const cvr_global_discount_override = 'cvr_globaldiscount_override';
export const cvr_daisy_mode_enabled = 'cvr_daisy_mode_enabled';
export const cvr_dynamic_calculating_screen = 'cvr_dynamic_calculating_screen';
export const cvr_grains_question = 'cvr_grains_question';
export const cvr_default_discount = 'cvr_default_discount';
export const cvr_price_value_modal = 'cvr_price_value_modal';
export const cvr_tosa_high_traffic_mode = 'cvr_tosa_high_traffic_mode';
export const cvr_fresh_food_confidence_education =
  'cvr_fresh_food_confidence_education';
export const cvr_breed_dropdown_improvements =
  'cvr_breed_dropdown_improvements';
export const cvr_treats_in_checkout_ui = 'cvr_treats_in_checkout_ui';

export enum FeatureName {
  CVR_DAISY_MODE_ENABLED = 'cvr_daisy_mode_enabled',
  CVR_DYNAMIC_CALCULATING_SCREEN = 'cvr_dynamic_calculating_screen',
  CVR_RECIPE_PAGE_PACK_ORDER = 'cvr_recipe_page_pack_order',
  CVR_GRAINS_QUESTION = 'cvr_grains_question',
  CVR_DEFAULT_DISCOUNT = 'cvr_default_discount',
  CVR_PRICE_VALUE_MODAL = 'cvr_price_value_modal',
  CVR_GLOBAL_DISCOUNT_OVERRIDE = 'cvr_globaldiscount_override',
  CVR_TOSA_HIGH_TRAFFIC_MODE = 'cvr_tosa_high_traffic_mode',
  CVR_FRESH_FOOD_CONFIDENCE_EDUCATION = 'cvr_fresh_food_confidence_education',
  CVR_SKIP_GET_STARTED = 'cvr_skip_get_started',
  CVR_BREED_DROPDOWN_IMPROVEMENTS = 'cvr_breed_dropdown_improvements',
  CVR_WEIGHTLOSS_PERSONALIZATION = 'cvr_weightloss_personalization',
  CVR_ZIP_EMAIL_COPY = 'cvr_zip_email_copy',
  CVR_SURPRISE_HIGHER_DISCOUNT = 'cvr_surprise_higher_discount',
  CVR_WEIGHT_OVERRIDE = 'cvr_weight_override',
  CVR_TREATS_IN_CHECKOUT_UI = 'cvr_treats_in_checkout_ui',
  CVR_SIGNUP_ICON_UPDATE = 'cvr_signup_icon_update',
  CVR_PRIOR_FOOD_TYPE_PROGRESSION = 'cvr_prior_food_type_progression',
}

export enum ToggleTreatments {
  off = 'off',
  on = 'on',
  control = 'control',
}

export enum FreshFoodConfidenceTreatmentEnum {
  off = 'off',
  control = 'control',
  benefits = 'benefits',
  vet_formulated = 'vet_formulated',
  stat = 'stat',
  stat_believe = 'stat_believe',
}

export enum RecipePagePackOrderEnum {
  off = 'off',
  chicken_first = 'chicken_first',
}

export enum PriceValueModalTreatments {
  off = 'off',
  'kibble_bash' = 'kibble_bash',
  'pro_fresh' = 'pro_fresh',
}

export enum DynamicCalculatingScreenTreatments {
  off = 'off',
  'vet_static' = 'vet_static',
  'kibble_or_fresh' = 'kibble_or_fresh',
  'fresh_benefit_weight_static' = 'fresh_benefit_weight_static',
  'fresh_benefit_weight_animation' = 'fresh_benefit_weight_animation',
  'fresh_benefit_healthier_poop_static' = 'fresh_benefit_healthier_poop_static',
  'fresh_benefit_healthier_poop_animation' = 'fresh_benefit_healthier_poop_animation',
}

export enum SkipGetStartedTreatments {
  off = 'off',
  same_copy = 'same_copy',
  new_copy = 'new_copy',
  no_copy = 'no_copy',
}

export enum CvrWeightlossPersonalizationTreatments {
  off = 'off',
  generic = 'generic',
  weight_loss = 'weight_loss',
}

export enum CvrZipEmailCopyTreatments {
  off = 'off',
  show_context = 'show_context',
}

export enum CvrSurpriseHigherDiscountTreatments {
  off = 'off',
  recipes = 'recipes',
  checkout = 'checkout',
}

export enum CvrTreatsInCheckoutUiTreatments {
  control = 'control',
  off = 'off',
  page = 'page',
}

export enum CvrPriorFoodTypeProgressionTreatments {
  off = 'off',
  on = 'on',
}

// Configs
export interface RecipePagePackOrderConfig {
  recipeOrder: RecipeNames[];
}
