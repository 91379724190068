import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';

export function trackReactivationSelectedAvailableNextDateChosen(args: {
  isEarliestAvailableDate: boolean;
}) {
  track(eventNames.reactivate_selected_available_next_date_chosen, {
    reactivationVersion: '2',
    isEarliestAvailableDate: args.isEarliestAvailableDate,
    productType: 'fresh',
  });
}
