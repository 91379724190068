import classNames from 'classnames';
import { useState, useRef } from 'react';

import { AnimateHeight, ButtonBase, Text } from '@farmersdog/corgi-x';

import ArrowRight from './assets/arrow-right.svg';
import styles from './Details.module.css';

import type { ReactNode } from 'react';

interface DetailsProps {
  id?: string;
  summary: string;
  itemProp?: string;
  children: ReactNode;
}

export const Details = ({ summary, children, itemProp }: DetailsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const toggleDetails = () => {
    setIsOpen(prevOpen => !prevOpen);
  };

  const className = classNames(styles.details, { [styles.open]: isOpen });

  return (
    <div className={className}>
      <ButtonBase
        aria-expanded={isOpen}
        className={styles.summary}
        onClick={toggleDetails}
        tabIndex={0}
      >
        <ArrowRight className={styles.arrowRight} />{' '}
        <Text
          as="h3"
          vSpacing="none"
          variant="article-16"
          color="white"
          itemProp={itemProp}
        >
          {summary}
        </Text>
      </ButtonBase>
      <AnimateHeight nodeRef={divRef} animateIn={isOpen}>
        <div ref={divRef} className={styles.content}>
          <Text
            as="p"
            topSpacing="xs"
            bottomSpacing="none"
            variant="article-16"
            color="white"
            align="left"
          >
            {children}
          </Text>
        </div>
      </AnimateHeight>
    </div>
  );
};
