import { useMachine } from '@xstate/react';
import { EditFreshRecipeEvent } from './EditFreshRecipeEvent';
import { editFreshRecipeMachine } from './editFreshRecipeMachine';
import type { EditFreshRecipeState } from './EditFreshRecipeState';

export interface UseEditFreshRecipeMachine {
  isState: (state: EditFreshRecipeState) => boolean;
  onNext: () => void;
  onPrevious: () => void;
  onClose: () => void;
}

export function useEditFreshRecipeMachine() {
  const [state, send] = useMachine(editFreshRecipeMachine);

  const isState = (stateInput: EditFreshRecipeState) => {
    return state.matches(stateInput);
  };

  const onNext = () => {
    send({ type: EditFreshRecipeEvent.Next });
  };

  const onPrevious = () => {
    send({ type: EditFreshRecipeEvent.Previous });
  };

  const onClose = () => {
    send({ type: EditFreshRecipeEvent.Close });
  };

  return {
    isState,
    onNext,
    onPrevious,
    onClose,
  };
}
