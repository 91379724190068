import { validationSchema } from '../validation/yupValidation';

import { getInputMode } from './getInputMode';

import type { TOSALeafNode } from '../types';

export function getInputAttributes(node: TOSALeafNode) {
  const inputMode = getInputMode({ node, validationSchema });
  const placeholder = node.input?.placeholder;
  const accessibilityAttributes = {
    ['aria-describedby']: node.input?.ariaDescribedBy,
    accessibleLabel: node.input?.accessibleLabel || node.name,
  };

  return { type: 'text', inputMode, placeholder, ...accessibilityAttributes };
}
