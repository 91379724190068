import type { DocumentNode } from 'graphql';
import { print } from 'graphql/language/printer';
import { prepareGlobalRequestHeaders } from 'src/graphql/utils/prepareGlobalRequestHeaders';

/**
 * Create a GET request action, intercepted by the request middleware. When
 * dispatched, they will return a Promise.
 *
 * @example
 * ```ts
 *    const fetchUser = id => get('USER_FETCH', `/user/${id}`);
 *    const response = await dispatch(fetchUser(2));
 * ```
 *
 * @param type - The action type
 * @param url - The relative API URL
 * @param meta - Metadata to pass to the action
 */
export function get<M = undefined>(type: string, url: string, meta?: M) {
  return {
    type,
    payload: {
      request: {
        url,
        method: 'GET',
        headers: prepareGlobalRequestHeaders(),
      },
    },
    meta,
  };
}

/**
 * Create a POST request action, intercepted by the request middleware. When
 * dispatched, they will return a Promise.
 *
 * @example
 * ```ts
 *    const createUser = user =>
 *      post('USER_CREATE', `/user`, user);
 *    const response = await dispatch(createUser({ email: 'foo{at}example.com' }));
 * ```
 *
 * @param type - The action type
 * @param url - The relative API URL
 * @param data - Payload to pass as body to the POST request
 * @param meta - Metadata to pass to the action
 */

export function post<D, M = undefined>(
  type: string,
  url: string,
  data: D,
  meta?: M
) {
  return {
    type,
    payload: {
      request: {
        url,
        method: 'POST',
        headers: prepareGlobalRequestHeaders(),
        data,
      },
    },
    meta,
  };
}

/**
 * Create a PUT request action, intercepted by the request middleware. When
 * dispatched, they will return a Promise.
 *
 * @example
 * ```ts
 *    const updateUser = user =>
 *      put('USER_UPDATE', `/user/${user.id}`, user);
 *    const response = await dispatch(
 *      updateUser({ id: 2, email: 'bar{at}example.com' })
 *    );
 * ```
 *
 * @param type - The action type
 * @param url - The relative API URL
 * @param data - Payload to pass as body to the PUT request
 * @param meta - Metadata to pass to the action
 */

export function put<D, M = undefined>(
  type: string,
  url: string,
  data: D,
  meta?: M
) {
  return {
    type,
    payload: {
      request: {
        url,
        method: 'PUT',
        headers: prepareGlobalRequestHeaders(),
        data,
      },
    },
    meta,
  };
}

/**
 * Create a DELETE request action, intercepted by the request middleware. When
 * dispatched, they will return a Promise.
 *
 * @example
 * ```ts
 *    const deleteUser = id => put('USER_DELETE', `/user/${id}`);
 *    const response = await dispatch(deleteUser(2));
 * ```
 *
 * @param type - The action type
 * @param url - The relative API URL
 * @param meta - Metadata to pass to the action
 */
export function del<M = undefined>(type: string, url: string, meta?: M) {
  return {
    type,
    payload: {
      request: {
        url,
        method: 'DELETE',
        headers: prepareGlobalRequestHeaders(),
      },
    },
    meta,
  };
}

/**
 * Create a TFD GraphQL POST request action, intercepted by the request middleware.
 * When dispatched, it will return a Promise.
 *
 * @example
 * ```ts
 *    const createUser = user =>
 *      graphql(
 *        'USER_CREATE',
 *        `
 *          mutation CreateUser($user: User) {
 *            createUser(user: $user) {
 *              id, email
 *            }
 *          }
 *        ',
 *        { user }
 *      );
 *    const response = await dispatch(createUser({ email: 'foo{at}example.com' }));
 * ```
 *
 * @param type - The action type
 * @param query - The GraphQL query
 * @param variables - Variables to use as parameters for the query
 * @param meta - Metadata to pass to the action
 */
export function graphql<V = undefined, M = undefined>(
  type: string,
  query: DocumentNode,
  variables?: V,
  meta?: M
) {
  if (typeof query === 'object') {
    return post<{ query: string; variables: V | undefined }, M>(
      type,
      '/',
      { query: print(query), variables },
      meta
    );
  }
  return post<{ query: string; variables: V | undefined }, M>(
    type,
    '/',
    { query, variables },
    meta
  );
}
