import { Text, Slideshow } from '@farmersdog/corgi-x';

import { trackSignupFreeTreatsScrolledTestimonials } from '../../../../analytics';
import { Container } from '../Container';

import { columnCopies } from './data';
import styles from './HumanGradeTreats.module.css';
import { Quote } from './Quote';
import { SplitSection } from './SplitSection';

const splitSectionClassnameMap = {
  0: styles['split-top-overlay'],
  1: styles['split-middle-overlay'],
  2: styles['split-bottom-overlay'],
};

export const HumanGradeTreats = () => {
  const sliderQuotes = columnCopies.map(column => ({
    quote: column.quote,
    author: column.author,
  }));

  return (
    <section className={styles.container}>
      <Container withMobilePadding>
        <div>
          <Text
            bold
            as="h2"
            color="kale-3"
            align={{
              xs: 'left',
              lg: 'center',
            }}
            variant="heading-40"
            bottomSpacing={{ xs: 'xs', lg: 'md' }}
            topSpacing={{ xs: 'xs', lg: 'md' }}
          >
            Everything your dog eats should be healthy.
          </Text>
          <Text
            align={{
              xs: 'left',
              lg: 'center',
            }}
            bottomSpacing="xl"
            variant="article-16"
            as="p"
            topSpacing="none"
          >
            That’s why our Snap Sticks are human-grade and low-calorie, with
            <br className={styles.desktopBreakline} /> portions tailored for
            your dog.
          </Text>
          {columnCopies.map(({ ...column }, index) => {
            const isIndexEven = index % 2 !== 0;

            return (
              <SplitSection
                {...column}
                key={column.title}
                isInverted={isIndexEven}
                className={
                  splitSectionClassnameMap[
                    index as keyof typeof splitSectionClassnameMap
                  ]
                }
              />
            );
          })}
        </div>
      </Container>

      <div className={styles.slider}>
        <Slideshow
          withDots
          label="Users testimonials"
          onSlideChange={trackSignupFreeTreatsScrolledTestimonials}
        >
          {sliderQuotes.map(quote => (
            <Quote {...quote} key={quote.quote} />
          ))}
        </Slideshow>
      </div>
    </section>
  );
};
