import { useRef } from 'react';

import { getCompletedData } from '../../../utils';
import { BranchNode } from '../../schema/BranchNode';

import { SubmitButton } from './SubmitButton';

import type { BranchNode as BranchNodeType } from '../../../blueprint/types';
import type { TOSAComponentInput } from '../../../types';

export function HealthForm(props: TOSAComponentInput<BranchNodeType>) {
  const { formMethods, formSubmitRequest, progress } = props;

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    if (props.onSubmit) {
      await props.onSubmit(data, e);
    }
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  return (
    <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
      <BranchNode {...props} />
      <SubmitButton loading={formSubmitRequest.loading} {...props} />
    </form>
  );
}
