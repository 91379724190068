import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { cookie } from '@farmersdog/lead-browser-storage';

type ChannelGroupingAttributes = {
  [key: string]: string | boolean;
};

export function useGetTargetingAttributesInQueryParams() {
  const { search } = useLocation();
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  setChannelGroupingAttributesFromQueryParams(queryParams);
  setUtmSourceFromQueryParams(queryParams);
}

function setChannelGroupingAttributesFromQueryParams(queryParams: qs.ParsedQs) {
  if (!queryParams['channelGrouping']) {
    return;
  }

  const channelGrouping = queryParams[
    'channelGrouping'
  ] as ChannelGroupingAttributes;

  Object.entries(channelGrouping).forEach(([key, value]) => {
    cookie.setChannelGroupingAttributes({ [key]: value });
  });
}

function setUtmSourceFromQueryParams(queryParams: qs.ParsedQs) {
  if (!queryParams['utm_source']) {
    return;
  }

  const utmSource = queryParams['utm_source'] as string;

  cookie.setUtmSource(utmSource);
}
