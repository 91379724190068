/* eslint-disable @typescript-eslint/no-empty-object-type */
import type { ViewportName } from 'src/screen/types';

/** Check if a component can safely be instantiated as an element.
 *
 * @param renderable - A renderable react function or class.
 * @param viewportNames - An array of valid viewport names.
 *
 * @returns Whether or not the name matches a viewport name.
 */
function isNamedComponent(
  renderable: {} | null | undefined,
  viewportNames: ViewportName[]
): boolean {
  return (
    typeof renderable === 'function' &&
    ![...viewportNames, ''].includes(renderable.name)
  );
}

export default isNamedComponent;
