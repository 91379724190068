import { useQuery } from '@apollo/client';

import type {
  PaymentMethodChangedForOrderQuery,
  PaymentMethodChangedForOrderQueryVariables,
} from './paymentMethodChangedForOrder.cgs.generated-types';
import paymentMethodChangedForOrderQuery from './paymentMethodChangedForOrder.cgs.graphql';

export function usePaymentMethodChangedForOrder({
  orderId,
}: {
  orderId?: number;
}) {
  const { data, error, loading } = useQuery<
    PaymentMethodChangedForOrderQuery,
    PaymentMethodChangedForOrderQueryVariables
  >(paymentMethodChangedForOrderQuery, {
    context: { endpoint: 'cgs' },
    skip: orderId === undefined,
    variables: { orderId: orderId ?? 0 },
  });

  return {
    paymentMethodChangedForOrder: data?.customer.paymentMethodChangedForOrder,
    loading,
    error,
  };
}
