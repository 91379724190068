import { useCustomerAccount } from '@/account/app/hooks/useCustomerAccount';

import { CustomerAccountContext } from './CustomerAccountContext';

import type { ReactNode } from 'react';

export type CustomerAccountProviderProps = {
  children: ReactNode;
};

/**
 * Add the Customer Account Global context to its children. To get the value of the context,
 * use `useCustomerAccountContext` hook.
 */
export function CustomerAccountProvider(
  props: CustomerAccountProviderProps
): JSX.Element {
  const customerAccount = useCustomerAccount();

  return (
    <CustomerAccountContext.Provider value={customerAccount}>
      {props.children}
    </CustomerAccountContext.Provider>
  );
}
