import type { FC } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';

import { clientConfig } from 'src/abTesting/splitConfig';

interface SplitProviderProps {
  children: JSX.Element;
}

/**
 * This is a simple provider that attaches the application to Split's React
 * Context. Any code that needs to use `useFeature` should be under the
 * `SplitProvider`.
 *
 * The `SplitProvider` imports the `clientConfig` and passes it to Split's
 * `SplitFactory` component. This `SplitFactory` component generates Split's
 * Context and Provider.
 *
 */
export const SplitProvider: FC<SplitProviderProps> = ({ children }) => {
  return <SplitFactory config={clientConfig}>{children}</SplitFactory>;
};
