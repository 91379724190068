import { ApplicationError } from 'src/errors';

import * as errorCodes from 'src/errors/errorCodes';
import { getDeprecatedErrors } from './getDeprecatedErrors';
import { reporter } from 'src/services/reporter';
import type { ErrorResponse } from '@apollo/client/link/error';

/**
 * A global error handler for apollo requests. This handler will process all
 * network errors by logging them as human readable messages to our error
 * reporting service (currently DataDog).
 *
 * {@link https://www.apollographql.com/docs/link/links/error/}
 *
 */
export function handleRequestError({
  networkError,
  operation,
  forward,
  graphQLErrors,
}: ErrorResponse) {
  const { operationName } = operation;

  const deprecatedErrors = getDeprecatedErrors(networkError) ?? [];
  const apiVersion = operation.getContext().apiVersion as string;

  for (const error of deprecatedErrors) {
    switch (error?.code) {
      case errorCodes.INVALID_GRAPHQL_INPUT: {
        return reporter.error(
          new ApplicationError(
            `GraphQL operation "${operationName}" received invalid input`,
            { apiVersion }
          )
        );
      }

      case errorCodes.INVALID_GRAPHQL_SYNTAX: {
        return reporter.error(
          new ApplicationError(
            `GraphQL operation "${operationName}" has a syntax error`,
            { apiVersion }
          )
        );
      }

      case errorCodes.UNRECOGNIZED_GRAPHQL_FIELD: {
        return reporter.error(
          new ApplicationError(
            `GraphQL operation "${operationName}" contains an unrecognized field`,
            { apiVersion }
          )
        );
      }
    }
  }

  if (networkError) {
    reporter.error(
      new ApplicationError('GraphQL request resulted in network error', {
        operationName,
        networkError,
      }),
      {
        operationName,
        networkError,
      }
    );
    return forward(operation);
  }

  if (graphQLErrors) {
    const errorDetails = graphQLErrors.map(error => ({
      message: error.message,
      locations: error.locations,
      path: error.path,
      code: error.extensions?.code,
    }));
    reporter.error(new ApplicationError('GraphQL error'), {
      operationName,
      graphQLErrors: errorDetails,
    });
  }

  return;
}
