import { Text } from '@farmersdog/corgi-x';

import type { ReactNode, HTMLAttributes } from 'react';

interface FormHintProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  id: string | undefined;
}

export function FormHint({ children, id, className }: FormHintProps) {
  if (!id) {
    return null;
  }

  return (
    <Text
      as="div"
      variant="article-16"
      fontStyle="italic"
      id={id}
      className={className}
    >
      {children}
    </Text>
  );
}
