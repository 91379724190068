import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { FullFooter } from 'src/components/Footer';
import Page from 'src/components/Page';

import HomepageHero from './components/HomepageHero';
import SectionLogos from './components/SectionLogos';
import SectionBowl from './components/SectionBowl';
import SectionFood from './components/SectionFood';
import SectionPackage from './components/SectionPackage';
import SectionDog from './components/SectionDog';
import SectionHowItWorks from './components/SectionHowItWorks';
import SectionQuotes from './components/SectionQuotes';
import SectionReviews from './components/SectionReviews';
import { SectionVet } from './components/SectionVet/SectionVet';

import { Navigation } from 'src/components/Navigation';
import schema from './schema.json';
import { AssetPreloader } from './components/AssetPreloader/AssetPreloader';
import { useVetSection } from './hooks/useVetSection';

function Home() {
  const ctaReveal = useRef(null);
  const { shouldShowVetSection } = useVetSection();

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Navigation ctaTrigger={ctaReveal} />
      <HomepageHero />
      <AssetPreloader />
      <div ref={ctaReveal}>
        <SectionLogos />
        <Page
          className="Home-sections"
          style={{ overflowX: 'hidden' }}
          description={schema.description}
        >
          <SectionBowl />
          <SectionFood />
          <SectionPackage />
          <SectionDog />
          <SectionHowItWorks />
          {!shouldShowVetSection && <SectionQuotes />}
          {shouldShowVetSection && <SectionVet />}
          <SectionReviews />
        </Page>
        <FullFooter />
      </div>
    </>
  );
}

export default Home;
