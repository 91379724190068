import { useEffect } from 'react';
import { datadog } from '../../../analytics/vendors/datadogRum';
import { GladlyService } from '../../../cx/vendors/gladly';
import type { CustomerAccountData } from '../useCustomerAccountQuery';

export function useIdentifyCustomer(
  customerAccountData:
    | Pick<CustomerAccountData, 'userId' | 'firstName' | 'email'>
    | undefined
) {
  useEffect(() => {
    if (!customerAccountData?.userId) {
      return;
    }
    datadog.identify({
      id: customerAccountData.userId,
    });
  }, [customerAccountData?.userId]);

  useEffect(() => {
    if (!customerAccountData?.email || !customerAccountData?.firstName) {
      return;
    }
    GladlyService.identify({
      name: customerAccountData.firstName,
      email: customerAccountData.email,
    });
  }, [customerAccountData?.email, customerAccountData?.firstName]);
}
