import { safeJsonParse } from './safeJsonParse';
import { getTreatmentFromQueryString } from './getTreatmentFromQueryString';
import type { FeatureMap, GetTreatmentConfigType } from '../types';

export interface GetTreatmentWithConfigArgs<
  T extends object,
  K extends keyof FeatureMap<T>,
> {
  treatmentName: K;
  isReady: boolean;
  splitClient: SplitIO.IClient | null;
  attributes?: SplitIO.Attributes;
  attributesReady: boolean;
  queryString: string;
  allowOverride: boolean;
}

/**
 * Gets a treatment and config from either split or a query string.  If split is
 * not ready will return a control treatment.
 *
 * @param treatment - Name of the treatment
 * @param isReady -  Determines if the split client is ready
 * @param splitClient - The split client to request the treatment from
 * @param attributes - Attributes to send with the request to split
 * @param attributesReady - Determines if the attributes are ready to be sent to split
 * @param queryString - Query string from the location
 * @param allowOverride - Whether to allow override of treatments via query parameters
 */
export function getTreatmentWithConfig<
  T extends object,
  K extends keyof FeatureMap<T>,
>({
  treatmentName,
  isReady,
  splitClient,
  attributes,
  attributesReady,
  queryString,
  allowOverride,
}: GetTreatmentWithConfigArgs<T, K>): GetTreatmentConfigType<T, K> {
  if (!isReady || !splitClient || !attributesReady) {
    return {
      treatment: 'control',
      config: {},
    };
  }

  const fromQueryString = getTreatmentFromQueryString<T, K>({
    treatmentName,
    queryString,
  });

  if (allowOverride && fromQueryString) {
    return fromQueryString;
  }

  const fromSplit = splitClient.getTreatmentWithConfig(
    treatmentName as string,
    attributes
  );

  return {
    treatment: fromSplit.treatment,
    config: safeJsonParse(fromSplit.config),
  };
}
