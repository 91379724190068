import { forwardRef } from 'react';

import { LiveMessage } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';

import styles from './MedicalConditionMessage.module.css';

import type { ForwardedRef } from 'react';

export const MEDICAL_CONDITION_MESSAGE_TEST_ID = 'medical-condition-message';

interface MedicalConditionMessageProps {
  message?: string;
  ariaLabel?: string;
}

export const MedicalConditionMessage = forwardRef<
  HTMLSpanElement,
  MedicalConditionMessageProps
>(MedicalConditionMessage_);

function MedicalConditionMessage_(
  { message, ariaLabel }: MedicalConditionMessageProps,
  ref: ForwardedRef<HTMLSpanElement>
) {
  return (
    <LiveMessage aria-live="polite" hidden={!message} aria-label={ariaLabel}>
      {message && (
        <span ref={ref}>
          <Text
            className={styles.text}
            variant="article-16"
            color="error-red"
            fontStyle="italic"
            as="p"
            data-testid={MEDICAL_CONDITION_MESSAGE_TEST_ID}
          >
            {message}
          </Text>
        </span>
      )}
    </LiveMessage>
  );
}
