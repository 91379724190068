import type { ReferrerCookie } from '@farmersdog/lead-browser-storage/src/cookie/cookie';
import { Logger } from '@farmersdog/logger';

import type {
  AnonymousExperimentFlag,
  AnonymousExperimentFlags,
} from './flags';
import { getReferrerCookieSplitAttributes } from './getReferrerCookieSplitAttributes';
import { getClient } from './splitFactory';

interface GetTreatmentsWithParsedConfigsArgs {
  anonymousId: string;
  anonymousExperiments: string[];
  referrerCookie?: ReferrerCookie;
}

export async function getTreatmentsWithParsedConfigs<
  F extends AnonymousExperimentFlag,
>({
  anonymousId,
  anonymousExperiments,
  referrerCookie,
}: GetTreatmentsWithParsedConfigsArgs): Promise<AnonymousExperimentFlags> {
  const splitClient = await getClient();

  const attributes = getAttributes(referrerCookie);

  const treatmentsWithConfig = splitClient.getTreatmentsWithConfig(
    anonymousId,
    anonymousExperiments,
    { ...attributes }
  );

  return Object.keys(treatmentsWithConfig).reduce((acc, experiment) => {
    const result = treatmentsWithConfig[experiment];

    if (!result) {
      return acc;
    }

    const treatment =
      result.treatment as AnonymousExperimentFlags[F]['treatment'];

    const config = result.config
      ? (parseSplitConfig(
          result.config
        ) as AnonymousExperimentFlags[F]['config'])
      : undefined;

    return {
      ...acc,
      [experiment as F]: {
        treatment,
        config,
      },
    };
  }, {} as AnonymousExperimentFlags);
}

/**
 * Parses the configuration string from Split
 * @param configString - The configuration string from Split
 */
function parseSplitConfig(configString: string): unknown {
  try {
    return JSON.parse(configString) as unknown;
  } catch (error) {
    const log = new Logger('app:server:getTreatmentsWithParsedConfigs');
    log.error('Failed to parse Split config', { error, configString });
    return undefined;
  }
}

function getAttributes(referrerCookie?: ReferrerCookie) {
  return getReferrerCookieSplitAttributes(referrerCookie);
}
