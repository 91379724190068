import { useCallback } from 'react';
import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { GraphQLEndpoints } from 'src/graphql/Apollo/constants';

import requestPasswordResetMutation from './requestPasswordReset.auth.graphql';
import type {
  RequestPasswordResetInput,
  RequestPasswordResetResponse,
  MutationRequestPasswordResetArgs,
} from 'src/graphql/auth.types';

interface UseResetPasswordArgs {
  onCompleted?: (data: RequestPasswordResetResponse) => void;
  onError?: (error: ApolloError) => void;
}

type UseRequestPasswordReset = [
  (input: RequestPasswordResetInput) => Promise<void>,
  {
    loading: boolean;
    error?: ApolloError;
  },
];

export function useRequestPasswordReset({
  onCompleted,
  onError,
}: UseResetPasswordArgs = {}): UseRequestPasswordReset {
  const [requestPasswordReset, requestPasswordResetState] = useMutation<
    RequestPasswordResetResponse,
    MutationRequestPasswordResetArgs
  >(requestPasswordResetMutation, {
    context: { endpoint: GraphQLEndpoints.AUTH },
    onCompleted,
    onError,
  });

  const submit = useCallback(
    async (input: RequestPasswordResetInput) => {
      await requestPasswordReset({
        variables: { input },
      });
    },
    [requestPasswordReset]
  );

  return [submit, requestPasswordResetState];
}
