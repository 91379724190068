import { Text } from '@farmersdog/corgi-x';
import type { AddressFragment } from '../../../network';
import styles from './Address.module.css';

export function Address(props: { label: string; address: AddressFragment }) {
  return (
    <div className={styles.address}>
      <Text
        className={styles.addressLabel}
        as="p"
        variant="heading-16"
        bold
        color="charcoal-3"
      >
        {props.label}
      </Text>
      <Text
        className={styles.addressLine}
        as="p"
        variant="heading-16"
        color="charcoal-3"
      >
        {props.address.addressLine1}
      </Text>
      {props.address.addressLine2 && (
        <Text
          className={styles.addressLine}
          as="p"
          variant="heading-16"
          color="charcoal-3"
        >
          {props.address.addressLine2}
        </Text>
      )}
      <Text
        className={styles.addressLine}
        as="p"
        variant="heading-16"
        color="charcoal-3"
      >
        {props.address.city}, {props.address.stateAbbreviation}{' '}
        {props.address.zip}
      </Text>
    </div>
  );
}
