import { getAllRecipes } from '../diyRecipes';
import type { DiyRecipe } from '../';

export function getRecipesById(ids: string[]) {
  const recipes = getAllRecipes();

  const requestedRecipes = ids.map(id =>
    recipes.find(recipe => recipe.id === id)
  );

  if (requestedRecipes.some(requestedRecipe => requestedRecipe === undefined)) {
    throw new Error(`Invalid id argument(s): ${ids.join(', ')}`);
  }

  return requestedRecipes as DiyRecipe[];
}
