import classNames from 'classnames';
import { useState } from 'react';

import { Modal } from '@farmersdog/corgi';
import { Button, Grid, GridItem } from '@farmersdog/corgi-x';

import { LightboxId, useGlobalLightbox } from '../../hooks';

import { RecipeDetails } from './RecipeDetails';
import styles from './RecipeModal.module.css';

import type { FreshRecipeRecommendation } from '../../graphql/types';

export interface MultiRecipeModalProps {
  recipes: FreshRecipeRecommendation[];
  petName?: string;
}

export function MultiRecipeModal({ recipes, petName }: MultiRecipeModalProps) {
  const { rootProps } = useGlobalLightbox({
    id: LightboxId.MultiRecipeDetailModal,
  });

  const [currentFocusedRecipe, setCurrentFocusedRecipe] =
    useState<FreshRecipeRecommendation>(recipes[0]);

  return (
    // @ts-expect-error Types are incorrect
    <Modal className={classNames(styles.main)} {...rootProps}>
      <Grid className={styles.nav} justifyContent="space-around">
        {recipes.map(recipe => (
          <GridItem xs={1} justifyContent="center" key={recipe.name}>
            <Button
              type="button"
              variant="plain-text"
              onClick={() => setCurrentFocusedRecipe(recipe)}
              className={classNames(styles.recipeNavButtons, {
                [styles.unfocusedRecipe]:
                  currentFocusedRecipe.name !== recipe.name,
              })}
            >
              {recipe.content.displayName}
            </Button>
          </GridItem>
        ))}
      </Grid>
      <RecipeDetails
        key={currentFocusedRecipe.name}
        recipe={currentFocusedRecipe}
        petName={petName}
        withNav
      />
    </Modal>
  );
}
