import type { DiscountTypeAndAmount } from '../types';
import type {
  ReferrerResponse,
  CouponResponse,
  UserReferrerResponse,
} from './getCoupon';
import { REFERRAL_NOT_FOUND_RESPONSE_DATA } from './getCoupon';

interface GetDiscountTypeAndAmountArgs {
  coupon?: ReferrerResponse;
  defaultDiscount: DiscountTypeAndAmount;
  overrideDiscount?: DiscountTypeAndAmount;
}

export function getDiscountTypeAndAmount({
  coupon,
  defaultDiscount,
  overrideDiscount,
}: GetDiscountTypeAndAmountArgs): DiscountTypeAndAmount {
  if (overrideDiscount) {
    return overrideDiscount;
  }

  if (!isReferrerValid(coupon)) {
    return defaultDiscount;
  }

  if (isDiscountTypeAndAmount(coupon)) {
    return {
      discountAmount: coupon.discountAmount,
      discountType: coupon.discountType,
    };
  }

  return defaultDiscount;
}

export function isReferrerValid(referrer?: ReferrerResponse) {
  if (!referrer) {
    return false;
  }

  const referrerHasInvalidProp = Object.prototype.hasOwnProperty.call(
    referrer,
    'invalid'
  );

  if (referrer === REFERRAL_NOT_FOUND_RESPONSE_DATA) {
    return false;
  }

  if (!referrerHasInvalidProp) {
    // assume valid referrer if not supplied with invalid prop
    return true;
  }

  return !referrer.invalid;
}

export function isDiscountTypeAndAmount(
  referrer: unknown
): referrer is DiscountTypeAndAmount {
  const keys: (keyof DiscountTypeAndAmount)[] = [
    'discountType',
    'discountAmount',
  ];

  if (
    !keys.every(key => {
      return Object.prototype.hasOwnProperty.call(referrer, key);
    })
  ) {
    return false;
  }

  // @ts-expect-error we checked this key above
  return typeof referrer?.discountAmount === 'number';
}

export function isCouponResponse(
  referrer: ReferrerResponse
): referrer is CouponResponse {
  return Object.prototype.hasOwnProperty.call(referrer, 'type');
}

export function isUserReferrerResponse(
  referrer: ReferrerResponse
): referrer is UserReferrerResponse {
  return Object.prototype.hasOwnProperty.call(referrer, 'name');
}
