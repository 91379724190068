import { PATH_ADJUST_DAILY_CALORIES } from '@farmersdog/constants/paths';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { SubscriptionType } from '../../../../../graphql/types.core-api';
import type { CancelSubscriptionFlowSavePayload } from '../../../../analytics';
import type { CancellationResponsesEnumType } from '../../../../flow-state-management/data';

export enum MixingPlanState {
  CostAssessment = 'CostAssessment',
  IntroMixingPlan = 'IntroMixingPlan',
  MixingPlanEducation = 'MixingPlanEducation',
}

interface MixingPlanControls {
  onPrevious: () => void;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
}

type MixingPlanNavigation = Record<MixingPlanState, MixingPlanControls>;

interface UseMixingPlanDownsellProgressProps {
  onBackToCancellationReasons: () => void;
  onContinueToCancel: () => void;
  initialState?: MixingPlanState;
  cancellationReasonsHistory: CancellationResponsesEnumType[];
}

export function useMixingPlanDownsellProgress(
  props: UseMixingPlanDownsellProgressProps
) {
  const history = useHistory<{
    cancelSubscriptionFlowSavePayload: CancelSubscriptionFlowSavePayload;
  }>();

  const [currentState, setCurrentState] = useState(
    props.initialState ?? MixingPlanState.CostAssessment
  );

  const onMixingPlanEducationPrimaryClick = useCallback(() => {
    history.push(
      PATH_ADJUST_DAILY_CALORIES,
      // Save tracking payload in router state to be fired on change calories completion
      {
        cancelSubscriptionFlowSavePayload: {
          saveReason: 'change-meals-size',
          subscriptionType: SubscriptionType.Fresh,
          cancellationReasonsHistory: props.cancellationReasonsHistory,
        },
      }
    );
  }, [history, props.cancellationReasonsHistory]);

  const navigation: MixingPlanNavigation = useMemo(
    () => ({
      [MixingPlanState.CostAssessment]: {
        onPrevious: () => props.onBackToCancellationReasons(),
        onPrimaryClick: () => setCurrentState(MixingPlanState.IntroMixingPlan),
      },
      [MixingPlanState.IntroMixingPlan]: {
        onPrevious: () => setCurrentState(MixingPlanState.CostAssessment),
        onPrimaryClick: () => props.onContinueToCancel(),
        onSecondaryClick: () =>
          setCurrentState(MixingPlanState.MixingPlanEducation),
      },
      [MixingPlanState.MixingPlanEducation]: {
        onPrevious: () => setCurrentState(MixingPlanState.IntroMixingPlan),
        onPrimaryClick: onMixingPlanEducationPrimaryClick,
      },
    }),
    [onMixingPlanEducationPrimaryClick, props]
  );

  return {
    currentState,
    ...navigation[currentState],
  };
}
