import { PATH_WEBSITE_HOMEPAGE } from '@farmersdog/constants/paths';
import { ActivityIndicator, Page } from '@farmersdog/corgi-x';
import { useHistory } from 'react-router';
import { useOrderSizeUpCtaData } from '../homepage/features/HomepageSlot/Hero/OrderSizeUpDismissibleCta/hooks';
import { SuggestOrderSizeUp } from './SuggestOrderSizeUp';
import styles from './SuggestOrderSizeUp.module.css';

// Gate to ensure that the user is eligible to see Order Size Up modal
export function OrderSizeUpEligibilityWrapper() {
  const history = useHistory();

  const handleOnClose = () => {
    history.push(PATH_WEBSITE_HOMEPAGE);
  };

  const {
    isReady,
    eligibleByCriteria: isEligible,
    nextOrderDeliveryWindow,
  } = useOrderSizeUpCtaData();
  if (!isReady)
    return (
      <Page layout="base" className={styles.container}>
        <div role="status" aria-live="polite" aria-label="loading" />
        <ActivityIndicator mode="dark" />
      </Page>
    );

  if (!isEligible) {
    handleOnClose();
    return;
  }

  return (
    <SuggestOrderSizeUp nextOrderDeliveryWindow={nextOrderDeliveryWindow} />
  );
}
