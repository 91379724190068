import { useContext, createContext } from 'react';

import { useRequestAttributes } from './useRequestAttributes';

import type { RequestAttributes } from './types';
import type { ReactNode } from 'react';

const initialRequestAttributesState = {};

const RequestAttributesContext = createContext<RequestAttributes>(
  initialRequestAttributesState
);

const { Provider } = RequestAttributesContext;

interface RequestAttributesProviderProps {
  children: ReactNode;
}

function RequestAttributesProvider({
  children,
}: RequestAttributesProviderProps) {
  const contextValue = useRequestAttributes();

  return <Provider value={contextValue}>{children}</Provider>;
}

function useRequestAttributesContext() {
  return useContext(RequestAttributesContext);
}

export {
  RequestAttributesContext,
  RequestAttributesProvider,
  useRequestAttributesContext,
};
