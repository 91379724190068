import fetch from 'cross-fetch';
import type { ApolloLink } from '@apollo/client';
import { from, split, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import config from '../../../config/config';
import { removeTokenCookie, isServerStatusError } from '../../../utils';
import { setupAuthLink } from './setupAuthLink';

export interface OperationContext {
  endpoint: 'api' | 'cgs';
}

export function setupApolloLink(): ApolloLink {
  const coreApiLink = new HttpLink({
    uri: config.get('app.apiUrl'),
    fetch,
  });

  const cgsApiLink = new HttpLink({
    uri: `${config.get('app.apiUrl')}/customer-graphql`,
    fetch,
  });

  const combinedHttpLink = split(
    operation => {
      const context = operation.getContext() as OperationContext;
      return context.endpoint === 'cgs';
    },
    cgsApiLink,
    coreApiLink
  );

  const link: ApolloLink = from([
    setupAuthLink(),
    onError(({ networkError }) => {
      if (
        isServerStatusError(networkError) &&
        networkError.statusCode === 401
      ) {
        removeTokenCookie();
        location.replace('/login');
      }
    }),
    combinedHttpLink,
  ]);

  return link;
}
