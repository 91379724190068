import type { ApolloError, MutationResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { LoginInput } from '../../graphql/types.core-api';
import { loginMutation } from './graphql';
import type {
  LoginMutation,
  LoginMutationVariables,
} from './graphql/mutations/Login.core-api.generated-types';

export interface UseLoginSubmitProps {
  onCompleted: (data: LoginMutation) => void;
  onError: (error: ApolloError) => void;
}

export interface UseLoginSubmit {
  login: (input: LoginInput) => void;
  state: MutationResult<LoginMutation>;
}

export function useLoginSubmit({
  onCompleted,
  onError,
}: UseLoginSubmitProps): UseLoginSubmit {
  const [callMutation, state] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(loginMutation, { onCompleted, onError });

  function login(input: LoginInput) {
    void callMutation({ variables: { input } });
  }

  return { login, state };
}
