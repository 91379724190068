import { useQuery } from '@apollo/client';
import petConfigurationQuery from './petConfiguration.graphql';

import type { FetchPetConfigurationQuery } from '../../types';

/**
 * Fetch the configuration data that is required for modifying details about a
 * pet.
 */
export function usePetConfiguration() {
  return useQuery<FetchPetConfigurationQuery>(petConfigurationQuery);
}
