import blackFridayDesktopJpg from './black-friday-desktop.jpg';
import blackFridayDesktopWebp from './black-friday-desktop.webp';

import blackFridayMobileJpg from './black-friday-mobile.jpg';
import blackFridayMobileWebp from './black-friday-mobile.webp';

export const mobileBlackFriday = {
  webP: blackFridayMobileWebp,
  jpg: blackFridayMobileJpg,
};

export const desktopBlackFriday = {
  webP: blackFridayDesktopWebp,
  jpg: blackFridayDesktopJpg,
};
