import { Picture, Text } from '@farmersdog/corgi-x';

import { useSignupUpdatedAssets } from '../../../hooks';
import { getPetNamesFromFormEntries } from '../../../utils';

import PetDetailsPng from './assets/petDetails.png';
import PetDetailsSvg from './assets/petDetails.svg';
import PetDetailsWebp from './assets/petDetails.webp';
import styles from './PetDetailsImage.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function PetDetailsImage(props: TOSAComponentInput<TOSALeafNode>) {
  const currentNodeName = props.node.name;
  const formValues = props.formMethods.getValues();
  const { currentPetName } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName,
  });
  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature: props.useFeature,
  });

  return (
    <div className={styles.container}>
      {isSignupUpdatedAssetsOn ? (
        <Picture
          className={styles.image}
          sources={[PetDetailsWebp, PetDetailsPng]}
          alt="Pet details"
        />
      ) : (
        <PetDetailsSvg className={styles.image} />
      )}
      <Text
        className={styles.text}
        variant="article-16"
        fontStyle="italic"
        color="charcoal-3"
        as="p"
      >
        Time to tell us about {currentPetName ?? 'your pet'} (the best part!)
      </Text>
      <Text
        className={styles.text}
        variant="article-16"
        fontStyle="italic"
        color="charcoal-3"
        as="p"
      >
        This will only take ~2 mins.
      </Text>
    </div>
  );
}
