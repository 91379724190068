import { NodeNames } from '@farmersdog/constants';

import { getMedicalConditionMessageFromConfig } from './getMedicalConditionMessageFromConfig';
import {
  HealthIssuesConfig,
  PrescriptionDietsConfig,
} from './MedicalConditionMessage/constants';

import type { ValidNodeName } from './types';

export interface MessageData {
  message?: string;
  ariaLabel?: string;
  isImportant: boolean;
}

type MedicalConditionMessageGetter = (selectedOptions: string[]) => MessageData;

const getIssuesMessage: MedicalConditionMessageGetter = selectedOptions => {
  const healthIssueMessage = getMedicalConditionMessageFromConfig({
    medicalConditions: selectedOptions,
    medicalConditionsConfig: HealthIssuesConfig,
  });

  return {
    message: healthIssueMessage,
    ariaLabel: 'health issues selection message',
    isImportant: !!healthIssueMessage,
  };
};

const getPrescriptionDietMessage: MedicalConditionMessageGetter =
  selectedOptions => {
    const defaultPrescriptionDietMessage = `While we do not offer official prescriptions, many veterinarians use our recipes to replace a processed ${selectedOptions[0]} food. If a prescription is absolutely required, please check with your veterinarian.`;
    const customPrescriptionDietMessage = getMedicalConditionMessageFromConfig({
      medicalConditions: selectedOptions,
      medicalConditionsConfig: PrescriptionDietsConfig,
    });

    return {
      message:
        customPrescriptionDietMessage ??
        (selectedOptions.length > 0
          ? defaultPrescriptionDietMessage
          : undefined),
      isImportant: !!customPrescriptionDietMessage,
    };
  };

const medicalConditionsMessageGetters: {
  [key in ValidNodeName]: MedicalConditionMessageGetter;
} = {
  [NodeNames.Issues]: getIssuesMessage,
  [NodeNames.PrescriptionDiets]: getPrescriptionDietMessage,
};

export function getMedicalConditionMessage(
  nodeName: ValidNodeName,
  selectedOptions: string[]
): MessageData {
  const medicalConditionsMessageGetter =
    medicalConditionsMessageGetters[nodeName];
  return medicalConditionsMessageGetter(selectedOptions);
}
