import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type {
  PauseFreshSubscriptionMutation,
  PauseFreshSubscriptionMutationVariables,
} from './PauseFreshSubscription.cgs.generated-types';
import pauseFreshSubscriptionMutation from './PauseFreshSubscription.cgs.graphql';

export type UsePauseFreshSubscription = [
  /** Submit the workflow by running the reschedule mutation. */
  (resumeDate: string) => void,
  {
    /** Whether the mutation is still running */
    isSubmitting: boolean;
    /** An error while submitting */
    error?: Error | ApolloError;
    /** The updated shipping date after the workflow has been submitted.  */
    updatedNextDate?: string | null;
  },
];

export type UsePauseFreshSubscriptionArgs = {
  onCompleted: (response: string) => void;
};

/**
 * Hook for submitting the user input to backend
 */
export function usePauseFreshSubscription({
  onCompleted,
}: UsePauseFreshSubscriptionArgs): UsePauseFreshSubscription {
  const [pauseFreshSubscription, submitState] = useMutation<
    PauseFreshSubscriptionMutation,
    PauseFreshSubscriptionMutationVariables
  >(pauseFreshSubscriptionMutation, {
    context: { endpoint: 'cgs' },
    onCompleted: response => {
      if (!response) {
        throw new Error('Missing response data');
      }

      const rescheduledShipDate =
        response.pauseFreshSubscription.customer.freshSubscription.nextDate;
      if (!rescheduledShipDate) {
        throw new Error(
          'next date is missing from pause fresh subscription mutation success'
        );
      }

      // Continue with the workflow
      onCompleted(rescheduledShipDate);
    },
  });

  const handleSubmit = (resumeDate: string) => {
    void pauseFreshSubscription({ variables: { input: { resumeDate } } });
  };

  const updatedNextDate =
    submitState.data &&
    submitState.data.pauseFreshSubscription.customer.freshSubscription.nextDate;

  return [
    handleSubmit,
    {
      error: submitState.error,
      isSubmitting: submitState.loading,
      updatedNextDate,
    },
  ];
}
