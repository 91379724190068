import { AppBar, ContentWidth, Link } from '@farmersdog/corgi';
import { useId } from 'react';
import { Helmet } from 'react-helmet-async';
import { paths } from '@farmersdog/constants';
import { LoginForm } from './features';
import styles from './LoginPage.module.css';
import { useNavigationItems } from '../utils';
import { useAuthContext } from '../app';
import type { LoginMutation } from './network/graphql/mutations/Login.core-api.generated-types';
import { useLoginSubmit } from './network/useLoginSubmit';
import { FullFooter } from '../components/FullFooter/FullFooter';
import { Divider, PageWidth, Text, useViewport } from '@farmersdog/corgi-x';
import { useProspectiveCxContact } from '../components/FullFooter/hooks/useProspectiveCxContact';
import { trackLoginFrontendSuccess } from './analytics';

export function LoginPage() {
  const { setToken, logout } = useAuthContext();

  const submit = useLoginSubmit({
    onCompleted: (result: LoginMutation) => {
      setToken(result.loginNew.token);
      trackLoginFrontendSuccess({ method: 'password' });
    },
    onError: () => {
      logout();
    },
  });

  const isMediumViewport = useViewport().medium;
  const headingVariant = isMediumViewport ? 'heading-52' : 'heading-40';

  const title = submit.state.loading ? 'Logging in…' : 'Log in';

  const { leftItems } = useNavigationItems();
  const prospectiveCxContact = useProspectiveCxContact('footer');

  const headingId = useId();
  return (
    <>
      <Helmet title={title} />
      <AppBar large={isMediumViewport} leftItems={leftItems} />
      <main aria-labelledby={headingId} className={styles.root}>
        <PageWidth>
          <Text
            id={headingId}
            className={styles.header}
            as="h1"
            variant={headingVariant}
            color="kale-3"
            bold
          >
            Welcome!
          </Text>
          <Text
            className={styles.subHeader}
            as="p"
            variant="heading-22"
            color="charcoal-1"
          >
            Please log in to continue
          </Text>
          <ContentWidth fr={1} alignSelf="center" className={styles.content}>
            <LoginForm
              login={submit.login}
              loading={submit.state.loading}
              error={submit.state.error}
            />
            <Divider orientation="horizontal" color="Oyster0" margin={0} />
            <Text
              as="h2"
              className={styles.signupCTAHeader}
              variant="heading-16"
              color="kale-3"
              bold
            >
              New to The Farmer’s Dog?
            </Text>
            <Link
              asButton
              to={paths.PAW_PATH_SIGNUP}
              variant="secondary"
              className={styles.signupCTAButton}
            >
              Build Your Plan
            </Link>
          </ContentWidth>
        </PageWidth>
      </main>
      <FullFooter
        shouldShowEmailLink={prospectiveCxContact.shouldDisplayEmail}
        shouldShowPhoneLink={prospectiveCxContact.shouldDisplayPhone}
      />
    </>
  );
}
