import type { Job } from './useJobs';

export interface JobsByDepartment {
  [name: string]: Job[];
}

/**
 * Return an array of jobs sorted by matching department names
 *
 * @param jobs - An array of jobs returned from greenhouse API
 */
export function sortJobsByDepartment(jobs: Job[]): JobsByDepartment {
  const jobsByDepartment: JobsByDepartment = {};

  if (!Array.isArray(jobs)) {
    return jobsByDepartment;
  }

  jobs.forEach(job => {
    if (Array.isArray(job.departments)) {
      job.departments.forEach(department => {
        const departmentJobs = jobsByDepartment[department.name] ?? [];
        departmentJobs.push(job);
        jobsByDepartment[department.name] = departmentJobs;
      });
    }
  });

  return jobsByDepartment;
}
