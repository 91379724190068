/* CH#49502: Used by RET and CVR */
import { Lightbox, useLightboxControl } from '@farmersdog/corgi';
import { forwardRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { titleTemplate } from 'src/config/helmet';
import ErrorBoundary from 'src/errors/ErrorBoundary';
import ErrorPage from 'src/errors/ErrorPage';

import { LoadingIndicator } from './components/LoadingIndicator/LoadingIndicator';
import type { PageProps } from './Page.types';

const Page = forwardRef<HTMLElement, PageProps>((props, ref) => {
  const { title, description, children, loading, error, ...mainProps } = props;
  const { open, close, rootProps } = useLightboxControl({
    initialIsOpen: loading,
  });

  const content = (() => {
    if (error) {
      return <ErrorPage error={error} />;
    }

    return loading ? null : <ErrorBoundary>{children}</ErrorBoundary>;
  })();

  useEffect(() => {
    if (content) {
      close();
    } else {
      open();
    }
  }, [close, content, open]);

  return (
    <main data-name="Page" aria-label={title} {...mainProps} ref={ref}>
      {title && (
        <Helmet>
          <title>{title}</title>
          <meta
            name="twitter:title"
            content={titleTemplate.replace('%s', title)}
          />
          <meta
            property="og:title"
            content={titleTemplate.replace('%s', title)}
          />
        </Helmet>
      )}
      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta itemProp="description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
      )}
      <Lightbox withAnimation={false} {...rootProps}>
        <LoadingIndicator />
      </Lightbox>
      {content}
    </main>
  );
});

export default Page;
