import classNames from 'classnames';
import { useEffect } from 'react';

import { Grid, GridItem, Text } from '@farmersdog/corgi-x';

import { useLeadState } from '../../../../hooks';
import { DynamicCalculatingScreenTreatments } from '../../../../utils';
import { getPetInfoToDisplay } from '../utils';

import { HealthierPoopAnimated } from './HealthierPoopAnimated';
import { HealthierPoopStatic } from './HealthierPoopStatic';
import { IdealWeightAnimation } from './IdealWeightAnimation';
import { IdealWeightStatic } from './IdealWeightStatic';
import { KibbleOrFresh } from './KibbleOrFresh';
import { Loader } from './Loader';
import styles from './PlanCalculationWithLoader.module.css';
import { VetStatic } from './VetStatic';

import type { CalculatingScreenTreatment } from '../../../../hooks';
import type { CalculatingScreenContentProps } from '../CalculatingScreen';

const CALCULATING_SCREEN_DURATION = 8000;

type TreatmentToElement = {
  [key in CalculatingScreenTreatment]: JSX.Element;
};

const withAnimationVariants: DynamicCalculatingScreenTreatments[] = [
  DynamicCalculatingScreenTreatments.kibble_or_fresh,
  DynamicCalculatingScreenTreatments.fresh_benefit_weight_animation,
  DynamicCalculatingScreenTreatments.fresh_benefit_healthier_poop_animation,
];

const treatmentToElement: TreatmentToElement = {
  [DynamicCalculatingScreenTreatments.vet_static]: (
    <GridItem className={styles.variationContainer} justifyContent="center">
      <VetStatic />
    </GridItem>
  ),
  [DynamicCalculatingScreenTreatments.fresh_benefit_weight_static]: (
    <GridItem className={styles.variationContainer} justifyContent="center">
      <IdealWeightStatic />
    </GridItem>
  ),
  [DynamicCalculatingScreenTreatments.fresh_benefit_weight_animation]: (
    <GridItem justifyContent="center">
      <IdealWeightAnimation />
    </GridItem>
  ),
  [DynamicCalculatingScreenTreatments.kibble_or_fresh]: (
    <GridItem justifyContent="center">
      <KibbleOrFresh />
    </GridItem>
  ),
  [DynamicCalculatingScreenTreatments.fresh_benefit_healthier_poop_static]: (
    <GridItem className={styles.variationContainer} justifyContent="center">
      <HealthierPoopStatic />
    </GridItem>
  ),
  [DynamicCalculatingScreenTreatments.fresh_benefit_healthier_poop_animation]: (
    <GridItem justifyContent="center">
      <HealthierPoopAnimated />
    </GridItem>
  ),
};

export function PlanCalculationWithLoader({
  onCompleted,
  treatment,
}: CalculatingScreenContentProps) {
  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();

  const { formattedPetName } = getPetInfoToDisplay({ lead });

  const isScreenWithAnimation = withAnimationVariants.includes(
    treatment as DynamicCalculatingScreenTreatments
  );

  const isKibbleOrFresh =
    treatment === DynamicCalculatingScreenTreatments.kibble_or_fresh;
  const isStaticLoader = isScreenWithAnimation && !isKibbleOrFresh;

  const staticOrKibbleAnimation = !isScreenWithAnimation || isKibbleOrFresh;

  const textColor = isScreenWithAnimation ? 'kale-3' : 'blueberry-3';
  const loaderVariant = isScreenWithAnimation ? 'green' : 'blue';

  useEffect(() => {
    const completeTimeout = setTimeout(() => {
      onCompleted();
    }, CALCULATING_SCREEN_DURATION);
    return () => {
      clearInterval(completeTimeout);
    };
  }, [onCompleted]);

  return (
    <Grid
      className={classNames(styles.background, {
        [styles.chickpea]: isKibbleOrFresh,
      })}
      alignItems={{
        xs: staticOrKibbleAnimation ? 'flex-start' : 'center',
        md: 'center',
      }}
      justifyContent="center"
    >
      <Grid
        className={styles.content}
        flexDirection="column"
        alignItems="center"
        rowGap={{ xs: isStaticLoader ? 'lg' : 'xxl', md: 'xxl' }}
      >
        <GridItem
          className={styles.titleContainer}
          flexDirection="column"
          alignItems="center"
        >
          <Loader variant={loaderVariant} isStatic={isStaticLoader} />
          <Text
            className={styles.title}
            variant="heading-52"
            color={textColor}
            bold
          >
            Calculating {formattedPetName ? formattedPetName : 'Your Dogs’'}{' '}
            Plan
          </Text>
        </GridItem>
        {treatmentToElement[treatment as CalculatingScreenTreatment]}
      </Grid>
    </Grid>
  );
}
