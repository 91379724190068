import {
  PATH_CUSTOMER_ACCOUNT,
  PATH_RESCHEDULE,
  PAW_PATH_CANCEL_REASONS,
} from '@farmersdog/constants/paths';
import { Button, Page, Text } from '@farmersdog/corgi-x';
import { useHistory, useLocation } from 'react-router';
import type { CancelSubscriptionFlowSavePayload } from '../../cancellation-reasons/analytics';
import { SubscriptionType } from '../../graphql/types.core-api';
import styles from './PreCancel.module.css';
import { setPathQueries } from '../../utils';
import { QueryParameter } from '@farmersdog/constants';

interface PreCancelUnlockedProps {
  onClose: () => void;
}

export function PreCancelUnlocked(props: PreCancelUnlockedProps) {
  const location = useLocation();

  const history = useHistory<{
    cancelSubscriptionFlowSavePayload: CancelSubscriptionFlowSavePayload;
  }>();

  function handleDelay() {
    history.push(
      setPathQueries(PATH_RESCHEDULE, {
        [QueryParameter.EntryPoint]: location.pathname,
      }),
      // Save tracking payload in router state to be fired on reschedule completion
      {
        cancelSubscriptionFlowSavePayload: {
          saveReason: 'precancel-delay-shipment',
          subscriptionType: SubscriptionType.Fresh,
        },
      }
    );
  }

  function handleContinue() {
    history.push(PAW_PATH_CANCEL_REASONS);
  }

  function handleClose() {
    props.onClose();
    history.push(PATH_CUSTOMER_ACCOUNT);
  }

  return (
    <Page className={styles.page} layout="base" onCloseClick={handleClose}>
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Cancel My Subscription
      </Text>
      <Text
        variant="article-20"
        as="p"
        color="charcoal-3"
        topSpacing="sm"
        bottomSpacing="lg"
      >
        It may take over a week to process a new order if you choose to
        reactivate. If you have too much food at home or just need a pause, you
        can delay the next shipment instead.
      </Text>
      <div className={styles.ctas}>
        <Button type="button" className={styles.button} onClick={handleDelay}>
          Delay My Shipment
        </Button>
        <Button type="button" variant="plain-text" onClick={handleContinue}>
          Continue to Cancellation
        </Button>
      </div>
    </Page>
  );
}
