import { useMutation } from '../../services/apollo';

import registerLead from './registerLead.graphql';

import type {
  RegisterLeadMutationVariables,
  RegisterLeadMutation,
} from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.MutationHookOptions<
  RegisterLeadMutation,
  RegisterLeadMutationVariables
>;

export function useRegisterLead(options?: Options) {
  return useMutation<RegisterLeadMutation, RegisterLeadMutationVariables>(
    registerLead,
    options
  );
}
