import classNames from 'classnames';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useLabel } from '../../../hooks/useLabel';
import {
  getInputAttributes,
  parseTextInputValue,
  normalizeTextInputValue,
  denormalizeTextInputValue,
} from '../../../utils';

import styles from './BasicInput.module.css';
import { InlineInput } from './InlineInput/InlineInput';

import type {
  TOSAComponentInput,
  TOSALeafNode,
  ExtendedTextInputChangeEvent,
} from '../../../types';

export function BasicInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, formMethods } = props;
  const { register, getValues, setValue } = formMethods;
  const registerProps = register(node.name);
  const { frontLabel, backLabel } = useLabel({ node, getValues });
  const inputAttributes = getInputAttributes(node);

  const handleChange = async (event: ExtendedTextInputChangeEvent) => {
    event.persist();
    const value = event.target.value;
    await registerProps.onChange(event);

    const parsedValue = parseTextInputValue({
      value,
      node,
    });
    const parsedDenormalizedValue = denormalizeTextInputValue({
      name: node.name,
      value: parsedValue,
      event,
    });

    setValue(node.name, parsedDenormalizedValue, { shouldValidate: true });
  };

  const { name: nonIndexedNodeName } = getNodeNameAndPosition(node.name);
  const className = classNames(styles.input, styles[nonIndexedNodeName]);

  return (
    <>
      {frontLabel}
      <InlineInput
        className={className}
        {...inputAttributes}
        {...registerProps}
        onChange={handleChange}
        value={normalizeTextInputValue({
          name: node.name,
          // @ts-expect-error unable to infer type
          value: getValues(node.name),
        })}
      />
      {backLabel}
    </>
  );
}
