import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import noop from 'lodash/noop';
import { useDeepMemo } from '@farmersdog/utils';

import type { SplitImpressionListener } from '../utils/ImpressionLogger';
import { ImpressionLogger } from '../utils/ImpressionLogger';
import type { CompleteSplitContextValue } from './SplitContext';
import { SplitContext } from './SplitContext';
import type { ID, FeatureMap, Feature } from '../types';

type ExcludedConfigKeys = 'features' | 'impressionListener';
type SplitConfig = Omit<SplitIO.IBrowserSettings, ExcludedConfigKeys>;

export interface SplitProviderProps<T extends object, U extends object, G> {
  children?: ReactNode;
  anonymousFeatures: FeatureMap<T>;
  anonymousId: ID;
  userFeatures: FeatureMap<U>;
  userId: ID | undefined;
  trackImpression?: SplitImpressionListener;
  globalAttributes?: G;
  splitConfig: SplitConfig;
  allowOverride: boolean;
}

export function SplitProvider<
  T extends object,
  U extends object,
  G extends Record<string, unknown>,
>({
  children,
  anonymousFeatures,
  anonymousId,
  userFeatures,
  userId,
  trackImpression,
  globalAttributes,
  splitConfig,
  allowOverride,
}: SplitProviderProps<T, U, G>) {
  const impressionLogger = useMemo(() => {
    return new ImpressionLogger({
      ignoreList: [
        ...getIgnoredFeatures(userFeatures),
        ...getIgnoredFeatures(anonymousFeatures),
      ],
    });
  }, [userFeatures, anonymousFeatures]);

  const value = useDeepMemo<CompleteSplitContextValue<T, U, G>>({
    anonymousFeatures,
    anonymousId,
    userFeatures,
    userId,
    globalAttributes,
    allowOverride,
  });

  useEffect(() => {
    if (trackImpression) {
      const removeListener = impressionLogger.addListener(trackImpression);
      return () => removeListener();
    }

    return noop;
  }, [impressionLogger, trackImpression]);

  const clientConfig: SplitConfig = useMemo(() => {
    const handleImpression = (impressionData: SplitIO.ImpressionData): void => {
      impressionLogger.logImpression(impressionData);
    };

    const isDev = splitConfig.core.authorizationKey === 'localhost';

    return {
      ...splitConfig,
      impressionListener: {
        logImpression: handleImpression,
      },
      features: isDev
        ? {
            ...getMockedFeatureMap(userFeatures),
            ...getMockedFeatureMap(anonymousFeatures),
          }
        : undefined,
    };
  }, [splitConfig, anonymousFeatures, userFeatures, impressionLogger]);

  return (
    <SplitFactory config={clientConfig}>
      <SplitContext.Provider value={value}>{children}</SplitContext.Provider>
    </SplitFactory>
  );
}

type DefaultFeatureMap = Record<string, Feature<string>>;

/**
 * Convert a feature config into an object that can be
 * used by the split factory for local testing.
 *
 * @param features - Array of features to get mock treatment data from
 */
function getMockedFeatureMap<T extends DefaultFeatureMap>(
  features: T
): SplitIO.MockedFeaturesMap {
  const asLocal: SplitIO.MockedFeaturesMap = {};

  return Object.entries(features).reduce((acc, [treatmentName, feature]) => {
    acc[treatmentName] = {
      treatment: feature.mock.treatment,
      config: JSON.stringify(feature.mock.config),
    };

    return acc;
  }, asLocal);
}

function getIgnoredFeatures<T extends DefaultFeatureMap>(
  features: T
): string[] {
  return Object.entries(features)
    .filter(([_, featureConfig]) => !featureConfig.track)
    .map(([feature]) => feature);
}
