import { useExperimentContext } from '../../../../../experiments';
import type {
  ExperimentAttributes,
  UseFeatureType,
} from '../../../../../app/lib/Split/features/OnbHomepagePersonalizationPicky';
import {
  SplitKey,
  Treatment,
} from '../../../../../app/lib/Split/features/OnbHomepagePersonalizationPicky';
import { useExperimentPickyDataQuery } from './useExperimentPickyData';
import { isEligibleForExperiment } from '../utils/isEligibleForExperiment';
import { reporter } from '../../../../../errors/services';

export interface ExperimentPickyFeature {
  treatment: Treatment;
  attributes: ExperimentAttributes;
}

/**
 * Gathers the data for the Picky Eater personalization experiment,
 * and returns the treatment for the user
 */
export function useExperimentPickyFeature(): ExperimentPickyFeature {
  // useFeature needs to source from experiment context
  const { useFeature } = useExperimentContext() as {
    useFeature: UseFeatureType;
  };
  // Execute query to retrieve data
  const { data, loading, error } = useExperimentPickyDataQuery({
    onError: (err: unknown) =>
      reporter.warn('Error retrieving ExperimentPicky data', {
        sourceError: err,
      }),
  });

  // Fill criteria against network-dependent data
  const attributes: ExperimentAttributes = {
    hasOverweightPet:
      data?.customer.userCharacteristics.hasOverweightPet ?? false,
    hasPickyEater: data?.customer.userCharacteristics.hasPickyEater ?? false,
    regularOrdersDeliveredCount:
      data?.customer.freshSubscription.regularOrdersDeliveredCount ?? 0,
  };

  // Check eligibility
  const isEligible = isEligibleForExperiment(attributes);
  const activatedAt = new Date(data?.customer.activatedAt ?? 0).getTime();

  // Retrieve treatment (as string)
  const { treatment: featureTreatment } = useFeature?.(SplitKey, {
    attributes: { isEligible, activatedAt },
  }) ?? { treatment: Treatment.Off };

  // Assign "off" to any incomplete data case
  const treatment =
    data === undefined || loading || !!error
      ? Treatment.Off
      : (featureTreatment as Treatment);

  // Return with default "off" treatment
  return { treatment, attributes };
}
