import { ValidationError } from '../../errors/ValidationError';
import type { AddressValidationErrorProps } from './AddressValidationError';
import { AddressValidationError } from './AddressValidationError';

interface CreateAddressValidationProps extends AddressValidationErrorProps {
  title?: string;
}

/**
 * Return a generic customer friendly address validation error.
 */
export function createAddressValidationError({
  title,
  ...props
}: CreateAddressValidationProps): ValidationError {
  const error = new ValidationError('Address is not valid', { title });
  error.displayMessage = <AddressValidationError {...props} />;

  return error;
}
