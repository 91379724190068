import { mountVendorWindowEvent } from '../utils';
import type { X_Events } from './XScript';

interface TrackXEventParams {
  eventName: X_Events;
}
export function _trackXEvents(
  window: Window,
  { eventName }: TrackXEventParams
) {
  const sdk = window.twq;
  sdk?.('event', eventName);
}

export const trackXEvent = mountVendorWindowEvent('X', _trackXEvents);
