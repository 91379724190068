import type { PinterestEventAttributes } from './pinterest';
import { pinterest } from './pinterest';
import { sharedPinterestAttributes } from './constants';

/** The attributes required for a Pinterest lead event */
export const attributes: PinterestEventAttributes = {
  ...sharedPinterestAttributes,
  event: 'lead',
};

/**
 * Track a Pinterest lead event.
 */

export async function trackPinterestLead(email: string | undefined) {
  await pinterest.setEnhancedMatchAttributes(email);
  pinterest.track(attributes);
}
