import { ApolloError, useApolloClient } from '@apollo/client';
import omit from 'lodash/omit';
import { getShippingError, isAddressError } from '../../../errors';
import type { CgsAddressInput } from '../../../graphql/types.cgs';
import type {
  ValidateShippingAddressQuery,
  ValidateShippingAddressQueryVariables,
} from './validateShippingAddress.cgs.generated-types';
import validateShippingAddressQuery from './validateShippingAddress.cgs.graphql';

export type AddressToUse = 'submitted' | 'suggested';

export const ADDRESS_POTENTIAL_BAD_DATA_ERROR =
  'ADDRESS_POTENTIAL_BAD_DATA_ERROR';

export function useValidateShippingAddress() {
  const client = useApolloClient();

  async function submit(address: CgsAddressInput) {
    try {
      const response = await client.query<
        ValidateShippingAddressQuery,
        ValidateShippingAddressQueryVariables
      >({
        query: validateShippingAddressQuery,
        variables: {
          input: address,
        },
        context: { endpoint: 'cgs' },
      });
      return {
        submittedAddress: omit(
          response.data.validateAddress.submittedAddress,
          '__typename'
        ),
        suggestedAddress:
          response.data.validateAddress.suggestedAddress === null
            ? null
            : omit(
                response.data.validateAddress.suggestedAddress,
                '__typename'
              ),
      };
    } catch (e: unknown) {
      let error = e;
      // If the error is a known address error we need to reassign it to a
      // custom error with customer-facing messaging.
      if (e instanceof ApolloError) {
        const graphqlError = e.graphQLErrors[0];
        // graphQLErrors array can be empty
        if (graphqlError && isAddressError(graphqlError)) {
          error = getShippingError(graphqlError);
        }
      }
      throw error;
    }
  }

  return {
    submit,
  };
}
