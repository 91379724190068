import type { SetStateAction, ChangeEvent as ReactChangeEvent } from 'react';
import { useState } from 'react';
import { Button, Input, FormControl, TextArea } from '@farmersdog/corgi';

import type { ChangeEvent } from '@farmersdog/corgi-x';
import { SearchableSelect, StyledOption, Text } from '@farmersdog/corgi-x';

import type { Result, Results } from '../splitDebug';
import { splitDebug } from '../splitDebug';
import { userFeatures, anonymousFeatures } from 'src/abTesting/features';
import styles from './SplitDebugForm.module.scss';

function Feature({
  name,
  result,
  onChange,
}: {
  name: string;
  result: Result;
  onChange: (name: string, result: Result) => void;
}) {
  // This state is needed to store invalid config values while the JSON is being
  // written
  const [configValue, setConfigValue] = useState(
    JSON.stringify(result.config, null, 2)
  );
  const [configInvalid, setConfigInvalid] = useState('');

  const handleLockClick = () => {
    // This needs to be stored in a const or the splitDebug.lock can flip the
    // result.locked value before we toggle it in the onChange event
    const currentLockedState = result.locked;
    if (currentLockedState) splitDebug.unlock(name);
    else splitDebug.lock(name);
    onChange(name, { ...result, locked: !currentLockedState });
  };

  const handleTreatmentChange = (e: ReactChangeEvent<HTMLInputElement>) => {
    onChange(name, { ...result, treatment: e.target.value });
  };

  const handleConfigChange = (e: ReactChangeEvent<HTMLTextAreaElement>) => {
    setConfigValue(e.target.value);
    const inputValue = e.target.value;
    let parsed = {};

    // If there is no input value, user wants to remove th config
    if (inputValue) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        parsed = JSON.parse(inputValue);
        setConfigInvalid('');
      } catch (err) {
        parsed = result.config;
        // @ts-expect-error validate error
        setConfigInvalid(err.message as SetStateAction<string>);
      }
    } else {
      setConfigInvalid('');
    }

    onChange(name, { ...result, config: parsed });
  };

  return (
    <div className={styles.formGrid}>
      <Text variant="heading-16" bold>
        {name}
      </Text>
      <FormControl aria-live="polite">
        <Input
          label="Treatment"
          type={'text'}
          value={result.treatment}
          disabled={result.locked}
          onChange={handleTreatmentChange}
        />
      </FormControl>
      <FormControl
        aria-live="polite"
        invalid={Boolean(configInvalid)}
        message={configInvalid}
      >
        <TextArea
          label="Config"
          value={configValue}
          disabled={result.locked}
          onChange={handleConfigChange}
        />
      </FormControl>
      <Button
        onClick={handleLockClick}
        disabled={Boolean(configInvalid)}
        className={styles.button}
      >
        {result.locked ? 'Unlock' : 'Lock'}
      </Button>
    </div>
  );
}

function LockedSplits({
  results,
  onClick,
}: {
  results: Results;
  onClick: (e?: ChangeEvent) => void;
}) {
  const lockedResults = Object.keys(results).filter(
    name => results[name]?.locked
  );

  if (!lockedResults.length) return null;

  return (
    <div className={styles.lockedSplits}>
      <Text as="h2" variant="heading-22" bold>
        Locked splits
      </Text>
      {lockedResults.map((name, index) => (
        <span key={name}>
          <Button
            asLink
            onClick={() => onClick({ value: name, checked: true })}
          >
            {name}
          </Button>
          {index !== lockedResults.length - 1 ? ', ' : null}
        </span>
      ))}
    </div>
  );
}

export function SplitDebugForm() {
  const [results, setResults] = useState(splitDebug.results);
  const [selectedSplit, setSelectedSplit] = useState('');

  const handleSplitChange = (name: string, result: Result) => {
    setResults(currentResults => ({
      ...currentResults,
      [name]: result,
    }));
    splitDebug.set(name, result);
  };

  const handleSplitSelect = (e?: ChangeEvent) => {
    setSelectedSplit(String(e?.value ?? ''));
  };

  return (
    <div>
      <Text as="p" variant="heading-16" className={styles.instructions}>
        A split will only have a treatment or config if it has been fetched or
        is locked.
        <br />
        Input a treatment and/or config to use that value this session.
        <br />
        Click the lock button to persist the treatment and config, even after
        closing the browser. Locked splits can not be edited.
      </Text>

      <LockedSplits results={results} onClick={handleSplitSelect} />

      <SearchableSelect
        label="Splits"
        onChange={handleSplitSelect}
        value={selectedSplit}
        className={styles.splitSelect}
      >
        {Object.keys({ ...userFeatures, ...anonymousFeatures })
          .sort()
          .map(name => (
            <StyledOption key={name} value={name}>
              {name}
            </StyledOption>
          ))}
      </SearchableSelect>

      {selectedSplit && (
        <Feature
          name={selectedSplit}
          result={
            results[selectedSplit] ?? {
              treatment: '',
              config: {},
              locked: false,
            }
          }
          onChange={handleSplitChange}
        />
      )}
    </div>
  );
}
