import type { ArticleColors, HeadingColors } from '@farmersdog/corgi-x';
import {
  Button,
  Grid,
  GridItem,
  Modal,
  Picture,
  Text,
} from '@farmersdog/corgi-x';
import styles from './MaybeLaterModal.module.css';

export type MaybeLaterProps = {
  imageSources: string[];
  headlineText: string;
  bodyText: string;
  closeCtaText: string;
  imageAltText: string;
  headlineColor: HeadingColors;
  bodyTextColor: ArticleColors;
};

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * Modal that appears when the user clicks the "Maybe Later" button in
 * the Order Size Nudge
 */
export function MaybeLaterModal({
  isOpen,
  onClose,
  imageAltText,
  imageSources,
  headlineText,
  bodyText,
  closeCtaText,
  headlineColor,
  bodyTextColor,
}: MaybeLaterProps & ModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnBackdropClick
      aria-label="Maybe Later"
      withCloseButton
      contentClassName={styles.modal}
    >
      <Grid
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        className={styles.grid}
      >
        <GridItem>
          <Picture
            sources={imageSources}
            alt={imageAltText}
            className={styles.image}
          />
        </GridItem>
        <GridItem className={styles.heading}>
          <Text variant="heading-28" color={headlineColor} bold role="heading">
            {headlineText}
          </Text>
        </GridItem>
        <GridItem>
          <Text color={bodyTextColor} variant="article-16">
            {bodyText}
          </Text>
        </GridItem>
        <GridItem>
          <Button type="button" onClick={onClose} className={styles.button}>
            {closeCtaText}
          </Button>
        </GridItem>
      </Grid>
    </Modal>
  );
}
