import { events, track } from 'src/analytics';
import { getDeliveryWindow } from 'src/utils/order/getDeliveryWindow';
import type { RescheduleReason } from 'src/pages/Reschedule/RescheduleReasons';
import { reporter } from 'src/services/reporter';
import type { SubscriptionType } from 'src/graphql/types';

/** Properties of the `reschedule_success` event. */
type TrackRescheduleSuccessProperties = {
  subscription_type: string;
  reason_id: string;
  reason_description: string;
  update_plan_recommended: boolean;
  from_date: string;
  to_date: string;
  entry_point: string | undefined;
};

export function trackRescheduleSuccess(
  subscriptionType: SubscriptionType,
  reason: RescheduleReason,
  updatedNextShipDate: string | null,
  entryPoint: string | undefined
) {
  if (!updatedNextShipDate) {
    reporter.warn(
      `Segment event "${events.reschedule_success}" has missing parameter "updatedNextShipDate"`
    );
    return;
  }

  const [from, to] = getDeliveryWindow(updatedNextShipDate);
  const from_date = from.format('YYYY-MM-DD');
  const to_date = to.format('YYYY-MM-DD');

  const properties: TrackRescheduleSuccessProperties = {
    subscription_type: subscriptionType,
    reason_id: reason.id,
    reason_description: reason.description,
    update_plan_recommended: reason.updateInventory,
    from_date,
    to_date,
    entry_point: entryPoint,
  };
  return track(events.reschedule_success, properties);
}
