import { track } from '@farmersdog/analytics';
import {
  cookie,
  getCurrentSignupSubscriptionType,
} from '@farmersdog/lead-browser-storage';

import { segmentTrackEvents } from '../segmentTrackEventNames';

import type { FullLeadRegisterResponseFragment } from '../../graphql/types';

interface TrackPetsSuccessArgs {
  requestAttributes: Record<string, unknown>;
  user: FullLeadRegisterResponseFragment['user'] | null;
  humanId?: string | null;
}

/**
 * A tracking event that is used to mark a customer completing the /signup/pets page for 1 pet.
 *
 * This fires for every pet, but we may only send events to specific vendors for the last pet.
 *
 */

export function trackPetsSuccess({
  requestAttributes,
  user,
  humanId,
}: TrackPetsSuccessArgs) {
  const currentSignupSubscriptionType = getCurrentSignupSubscriptionType();

  track(segmentTrackEvents.pets_success, {
    ...requestAttributes,
    phone: user?.phone,
    firstName: user?.firstName,
    email: user?.email,
    product_line: currentSignupSubscriptionType,
    label: currentSignupSubscriptionType,
    fbp: cookie.getFacebookPixelCookie(),
    humanId,
  });
}
