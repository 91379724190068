import { safeJsonParse } from 'src/abTesting/utils/safeJsonParse';
import { getTreatmentFromDebug } from 'src/abTesting/utils/getTreatmentFromDebug';
import type { TreatmentWithConfig } from './types';

import { log } from 'src/abTesting';

/**
 * Gets a treatment and config from either split or a query string.  If split is
 * not ready will return a control treatment.
 *
 * @param name - Name of the treatment
 * @param isReady -  Determines if the split client is ready
 * @param splitClient - The split client to request the treatment from
 * @param attributes - Attributes to send with the request to split
 * @param attributesReady - Determines if the attributes are ready to be sent to split
 */
export function getTreatmentWithConfig<TreatmentType, ConfigType>(
  name: string,
  isReady: boolean,
  splitClient: SplitIO.IClient | null,
  attributes: SplitIO.Attributes,
  attributesReady: boolean
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
): TreatmentWithConfig<TreatmentType | 'control', ConfigType | {}> {
  if (!isReady || !splitClient || !attributesReady) {
    return { treatment: 'control', config: {} };
  }

  const fromDebug = getTreatmentFromDebug<TreatmentType, ConfigType>(name);
  if (fromDebug) return fromDebug;

  const fromSplit = splitClient.getTreatmentWithConfig(name, attributes);
  log.debug(`Treatment response`, {
    name,
    treatment: fromSplit.treatment,
    config: fromSplit.config,
  });

  return {
    treatment: fromSplit.treatment as unknown as TreatmentType,
    config: safeJsonParse(fromSplit.config),
  };
}
