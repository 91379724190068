import {
  Button,
  FormControl,
  Grid,
  GridItem,
  Input,
  Select,
} from '@farmersdog/corgi-x';
import styles from './AddClinic.module.css';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import type { AddClinicFormProps } from './validationForm';
import {
  AddClinicFormFieldName,
  addClinicFormInitialValues,
  addClinicFormSchema,
  inputs,
} from './validationForm';
import { useGetStates } from '../../network';

export type AddClinicProps = {
  onSaveChanges: (clinic: AddClinicFormProps) => void;
  loading: boolean;
};

export const AddClinic = ({ onSaveChanges, loading }: AddClinicProps) => {
  const form = useFormik({
    initialValues: addClinicFormInitialValues,
    validationSchema: addClinicFormSchema,
    onSubmit: (values: AddClinicFormProps) => {
      onSaveChanges(values);
    },
  });

  const { data, loading: loadingStates } = useGetStates({
    onCompleted: async response => {
      await form.setFieldValue(
        AddClinicFormFieldName.State,
        response.states[0].abbreviation
      );
    },
  });

  const fields = useMemo(
    () =>
      inputs.map(({ field, label, mask, inputMode }) => {
        const fieldHasErrorAndIsTouched = Boolean(
          form.touched[field] && form.errors[field]
        );

        return (
          <FormControl
            key={field}
            politeness="polite"
            message={fieldHasErrorAndIsTouched && form.errors[field]}
            invalid={fieldHasErrorAndIsTouched}
          >
            {field === AddClinicFormFieldName.State && !loadingStates ? (
              <Select
                label={label}
                value={form.values[field]}
                name={field}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              >
                {data?.states.map(state => {
                  return (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  );
                })}
              </Select>
            ) : (
              <Input
                type="text"
                label={label}
                name={field}
                mask={mask}
                value={form.values[field]}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                inputMode={inputMode}
              />
            )}
          </FormControl>
        );
      }),
    [
      data?.states,
      form.errors,
      form.handleBlur,
      form.handleChange,
      form.touched,
      form.values,
      loadingStates,
    ]
  );
  return (
    <Grid>
      <form onSubmit={form.handleSubmit}>
        <GridItem className={styles.inputs}>{fields}</GridItem>
        <Button
          className={styles.saveChanges}
          aria-disabled={!(form.isValid && form.dirty) || loading}
          type="submit"
        >
          Save Clinic
        </Button>
      </form>
    </Grid>
  );
};
