import * as actionTypes from 'src/constants/actionTypes';
import type { ValidationError, ApplicationError, UserError } from 'src/errors';

export type CustomError =
  | Error
  | ValidationError
  | ApplicationError
  | UserError;

export interface ShowErrorOptions {
  /** The title to give to the error modal. */
  title?: string;
  errorCode?: string;
  /** Whether to send the error to DataDog. */
  captureException?: boolean;
}

/**
 * Return a redux action to show the error in a modal dialog.
 *
 * @param error - An error instance
 * @param options - Options to pass to the error modal
 */
export function showError(error: CustomError, options?: ShowErrorOptions) {
  return {
    type: actionTypes.UI_ERROR_MODAL_SHOW,
    payload: {
      error,
      options,
    },
  };
}

/**
 * Return a redux action to hide the modal dialog showing an error
 */
export function hideError() {
  return {
    type: actionTypes.UI_ERROR_MODAL_HIDE,
  };
}

/**
 * Show a lightbox by id. By default this action will have no effect if there is
 * another lightbox open. Passing the override parameter will change this
 * behavior so that the specified lightbox always opens and closes all others.
 *
 * @param id - The ID of the lightbox to show.
 * @param override - Whether to override the default lightbox behavior.
 */
export function showLightboxById(id: string, { override = false } = {}) {
  return {
    type: actionTypes.UI_LIGHTBOX_SHOW,
    payload: {
      id,
      override,
    },
  };
}

/**
 * Return a redux action to hide a specific lightbox by id.
 *
 * @param id - The ID of the lightbox to hide.
 */
export function hideLightboxById(id: string) {
  return {
    type: actionTypes.UI_LIGHTBOX_HIDE,
    payload: {
      id,
    },
  };
}

/**
 * Return a redux action to hide all lightboxes.
 */
export function hideAllLightboxes() {
  return {
    type: actionTypes.UI_LIGHTBOX_HIDE_ALL,
  };
}

/**
 * Set the `shouldShow` property of a lightbox by id. If the `shouldShow`
 * property is `false` then the lightbox will not show under any circumstance.
 *
 * @param id - The ID of the lightbox to show.
 * @param shouldShow - Whether to enabling showing of the lightbox.
 */
export function setShouldShowLightboxById(id: string, shouldShow: boolean) {
  return {
    type: actionTypes.UI_LIGHTBOX_SET,
    payload: {
      id,
      changes: {
        shouldShow,
      },
    },
  };
}
