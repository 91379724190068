import { Text } from '@farmersdog/corgi-x';

import styles from './SubHeader.module.css';

import type { ReactNode } from 'react';

export interface SubHeaderProps {
  children: ReactNode;
}

export function SubHeader({ children }: SubHeaderProps) {
  return (
    <Text
      className={styles.text}
      variant="heading-22"
      color="kale-3"
      bold
      as="h3"
    >
      {children}
    </Text>
  );
}
