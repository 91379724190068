import { labelCase } from 'src/utils/formatters';
import type { FetchPetConfigurationQuery } from '../../types';

type PetConfigurationData = Partial<FetchPetConfigurationQuery>;

/**
 * Return all the available eating styles.
 *
 * @param data - The response from the usePetConfigurationQuery
 */
export function selectEatingStyles(data?: PetConfigurationData) {
  const eatingStyles = data?.eatingStyles ?? [];

  return eatingStyles.map(eatingStyle => ({
    ...eatingStyle,
    name: labelCase(eatingStyle.name),
  }));
}

/**
 * Return all the available body conditions.
 *
 * @param data - The response from the usePetConfigurationQuery
 */
export function selectBodyConditions(data?: PetConfigurationData) {
  return data?.bodyConditions ?? [];
}

/**
 * Return all the available prescription diets
 *
 * @param data - The response from the usePetConfigurationQuery
 */
export function selectPrescriptionDiets(data?: PetConfigurationData) {
  return data?.prescriptionDiets ?? [];
}
