import { useQuery } from '@apollo/client';
import type {
  FetchHomepageEssentialsCustomerDataQuery,
  FetchHomepageEssentialsCustomerDataQueryVariables,
} from './fetchHomepageEssentialsCustomerData.cgs.generated-types';
import FetchHomepageEssentialsCustomerData from './fetchHomepageEssentialsCustomerData.cgs.graphql';

export function useFetchHomepageEssentialsCustomerData() {
  return useQuery<
    FetchHomepageEssentialsCustomerDataQuery,
    FetchHomepageEssentialsCustomerDataQueryVariables
  >(FetchHomepageEssentialsCustomerData, {
    context: { endpoint: 'cgs' },
  });
}
