import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type {
  UseLightboxControlProps,
  LightboxControlReturn,
} from '@farmersdog/corgi';
import { useLightboxControl, ANIMATION_DURATION } from '@farmersdog/corgi';
import noop from 'lodash/noop';

import wait from 'src/utils/wait';

import { showLightboxById, hideLightboxById } from 'src/actions/ui';
import { selectLightboxIsOpen } from 'src/reducers/ui';
import { useAppSelector } from 'src/store/useAppSelector';

interface GlobalLightboxProps extends Omit<UseLightboxControlProps, 'id'> {
  id: string;
}

/**
 * Create a controller for a lightbox or modal with a global context based on an
 * id attribute. Use it the same way you would use the hook `useLightboxControl`
 * with the exception that the the id attribute will map your controller to any
 * lightbox with the same id.
 *
 * @example
 * ```ts
 * function MyModal() {
 *   const { rootProps } = useGlobalLightbox({ id: 'unique-id' })
 *
 *   return <Lightbox {...rootProps}><p>Yay!</p></Lightbox>
 * }
 *
 * function INeedToOpenTheModal() {
 *   const { open } = useGlobalLightbox({ id: 'unique-id' })
 *
 *   return <button onClick={open}>Open your Modal!</button>
 * }
 * ```
 *
 */
function useGlobalLightbox({
  id,
  onClose,
  onOpen,
  initialIsOpen,
  ...props
}: GlobalLightboxProps): LightboxControlReturn {
  const dispatch = useDispatch();
  const globalIsOpen = useAppSelector(state => selectLightboxIsOpen(state, id));

  const globalOpen = useCallback(
    ({ override = true }: { override?: boolean } = {}) => {
      dispatch(showLightboxById(id, { override }));
    },
    [dispatch, id]
  );

  const globalClose = useCallback(() => {
    dispatch(hideLightboxById(id));
    return wait(ANIMATION_DURATION, noop);
  }, [dispatch, id]);

  const handleOpen = () => {
    if (!globalIsOpen) {
      globalOpen();
    }

    if (onOpen) {
      onOpen();
    }
  };

  const handleClose = () => {
    if (globalIsOpen) {
      void globalClose();
    }

    if (onClose) {
      onClose();
    }
  };

  const { isOpen, open, close, ...controlProps } = useLightboxControl({
    onOpen: handleOpen,
    onClose: handleClose,
    initialIsOpen: Boolean(globalIsOpen),
    id,
    ...props,
  });

  useEffect(() => {
    if (globalIsOpen && !isOpen) {
      open();
    }

    if (!globalIsOpen && isOpen) {
      close();
    }
  }, [globalIsOpen, isOpen, open, close]);

  // On initialization if the consumer sets initialIsOpen to true we need to set
  // open on the global state.
  useEffect(() => {
    if (!globalIsOpen && initialIsOpen) {
      globalOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...controlProps,
    open: globalOpen,
    close: globalClose,
    isOpen: globalIsOpen,
  };
}

export default useGlobalLightbox;
