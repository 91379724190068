import { useMemo } from 'react';

import { couponCookie, ReferrerType } from '@farmersdog/coupons';

import { FeatureName } from '../../../utils';
import { useTosaUserFeature } from '../../useTosaUserFeature';

import type {
  UseFeatureHook,
  UseTreatsInCheckoutUiReturn,
} from '../../../types';
import type { CvrTreatsInCheckoutUiTreatments } from '../../../utils';

interface UseTreatsInCheckoutUiProps {
  useFeature: UseFeatureHook;
}

export const useTreatsInCheckoutUi = ({
  useFeature,
}: UseTreatsInCheckoutUiProps): UseTreatsInCheckoutUiReturn => {
  const { referrerCode, referrerType } = couponCookie.get();
  const pcode =
    referrerCode && referrerType == ReferrerType.Partner ? referrerCode : '';

  const { treatment, isReady } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_TREATS_IN_CHECKOUT_UI,
    attributes: { pcode },
  }) as { treatment: CvrTreatsInCheckoutUiTreatments; isReady: boolean };

  const shouldShowTreatsPage = treatment === 'page';

  return useMemo(
    () => ({
      shouldShowTreatsPage,
      shouldShowTreatsOnCheckoutPage: !shouldShowTreatsPage,
      shouldRemoveTreatsPage: isReady && !shouldShowTreatsPage,
    }),
    [shouldShowTreatsPage, isReady]
  );
};
