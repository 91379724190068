import path from 'path';
import { logImpression } from 'src/abTesting/logImpression';
import config from 'src/config';

const authorizationKey = config('server.splitKey') as string;
const debug = config('splitio.debug') as boolean;

export const serverSplitConfig: SplitIO.INodeSettings = {
  debug,
  features: path.join(__dirname, 'split.development.yaml'),
  core: {
    authorizationKey,
  },
  impressionListener: {
    logImpression,
  },
};
