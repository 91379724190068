import { useQuery } from '@apollo/client';
import getShippingAddressQuery from './GetShippingAddress.cgs.graphql';
import type {
  GetShippingAddressQuery,
  GetShippingAddressQueryVariables,
} from './GetShippingAddress.cgs.generated-types';

export function useGetShippingAddress() {
  return useQuery<GetShippingAddressQuery, GetShippingAddressQueryVariables>(
    getShippingAddressQuery,
    {
      context: { endpoint: 'cgs' },
      fetchPolicy: 'cache-first',
    }
  );
}
