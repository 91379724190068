import type { ReactNode } from 'react';

export enum PrimaryProtein {
  Beef = 'Beef',
  Chicken = 'Chicken',
  Turkey = 'Turkey',
  Pork = 'Pork',
  Tilapia = 'Tilapia',
}

export enum RecipesCategory {
  LowFat = 'LowFat',
}

export interface DiyRecipeTestimonial {
  /** picture of the testimonial pet */
  image: ReactNode;
  /** name of the testimonial pet */
  name: string;
  /** title of the testimonial dog */
  title: string;
  /** content */
  quote: string;
  /** name of the pet owner */
  human: string;
}

export interface DiyRecipeInstruction {
  /** Instruction header */
  name: string;
  /** Instruction content */
  description: string;
  /** Notes about the instruction */
  note?: string;
}

export interface DiyRecipeIngredient {
  /** number of ingredient */
  quantity: number;
  /** unit of ingredient in a singular form (ie. tablespoon, g, lb, packet) */
  unit: string;
  /** name of the ingredient */
  name: string;
  /** alternative unit to show (in a singular form) */
  alternativeUnit?: string;
  /** alternative quantity to show */
  alternativeQuantity?: number;
  /** Same ingredient but in different forms */
  alternativeIngredients?: DiyRecipeIngredient[];
}

export enum RecipeTag {
  Adult = 'Adult',
  Puppy = 'Puppy',
}

export interface DiyRecipe {
  /** a unique identifier for this recipe in kebab-case */
  id: string;
  /** human-friendly name for this recipe */
  name: string;
  /** amount of time to cook the recipe */
  cookTime: string;
  /** an array of ingredients used to cook the recipe */
  ingredients: DiyRecipeIngredient[];
  /** a simplified summary of the ingredients used to cook the recipe */
  ingredientSummary?: string;
  /** Notes about the ingredients */
  ingredientsNote?: string;
  /** tools needed to prepare the recipe */
  utensils: string[];
  /** amount of food this recipe yields, in lbs */
  yieldWeight: number;
  /** recipe image */
  image: string;
  /** Wistia ID of video with recipe demonstration */
  videoId?: string;
  /** list of steps needed to prepare this recipe */
  instructions: DiyRecipeInstruction[];
  /** information used to provide social proof for this recipe */
  testimonial?: DiyRecipeTestimonial;
  /** how many calories this recipe yields */
  calories: number;
  /** primary protein (used to filter) */
  primaryProtein: PrimaryProtein;
  /** popularity (used to sort) */
  popularity?: number;
  /** Adult or Puppy recipe */
  isPuppy?: boolean;
  /** which filter group does this recipe fall under */
  filterBy?: RecipesCategory;
}
