import { useLightboxControl } from '@farmersdog/corgi';
import { UserFeatureKeys } from '../../../app/lib/Split';
import { useExperimentContext } from '../../../experiments';
import { Button, Text, Select } from '@farmersdog/corgi-x';
import type { ChangeEvent, FormEvent } from 'react';
import { useId, useState } from 'react';
import { SecurePaymentMessage } from '../../../components';
import { formatDateToWeekOf } from '../../../utils';
import type {
  FreshSubscriptionFieldsFragment,
  ReactivationFreshQuoteFieldsFragment,
} from '../../network';
import { ModifyAccountMessage } from '../ModifyAccountMessage';
import { DailyPriceQuote } from './QuoteDisplay';
import { trackReactivationSelectedAvailableNextDateChosen } from '../../analytics/trackReactivationSelectedAvailableNextDateChosen';
import styles from './ShippingAndQuoteDisplay.module.css';
import { TermsOfUseModal } from './TermsOfUseModal';

export interface ShippingAndQuoteDisplayProps {
  availableNextDates: string[];
  quote: ReactivationFreshQuoteFieldsFragment;
  addOns: FreshSubscriptionFieldsFragment['addOns'];
  submit: (selectedAvailableNextDate: string) => void;
  isSubmitting: boolean;
}

export function ShippingAndQuoteDisplay(props: ShippingAndQuoteDisplayProps) {
  const deliveryWeekText = 'Choose Delivery Week';

  const chargeWeekText = () => {
    if (selectedAvailableNextDate === props.availableNextDates[0]) {
      return 'You may be charged immediately when you reactivate.';
    }
    return null;
  };

  const { useFeature } = useExperimentContext();

  const isRemoveAsapOnReactivateEnabled =
    useFeature?.(UserFeatureKeys.adj_remove_asap_on_reactivate, {})
      .treatment === 'on';

  const startingState = () => {
    if (isRemoveAsapOnReactivateEnabled) {
      return null;
    } else {
      return props.availableNextDates[0];
    }
  };

  const [selectedAvailableNextDate, setSelectedAvailableNextDate] =
    useState(startingState());

  const [showError, setShowError] = useState(false);

  const termsOfUseModalControl = useLightboxControl();

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === props.availableNextDates[0]) {
      trackReactivationSelectedAvailableNextDateChosen({
        isEarliestAvailableDate: true,
      });
    } else {
      trackReactivationSelectedAvailableNextDateChosen({
        isEarliestAvailableDate: false,
      });
    }
    setSelectedAvailableNextDate(e.target.value);
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (!selectedAvailableNextDate) {
      return;
    }
    props.submit(selectedAvailableNextDate);
  }

  const orderSummaryId = useId();

  return (
    <section aria-labelledby={orderSummaryId} className={styles.root}>
      <form onSubmit={handleSubmit}>
        <Text
          id={orderSummaryId}
          as="h2"
          variant="heading-28"
          bold
          color="charcoal-3"
        >
          Order Summary
        </Text>
        <Text
          as="p"
          variant="article-16"
          color="charcoal-3"
          topSpacing="md"
          bottomSpacing="sm"
        >
          Start your orders again ASAP or select a later week that you’d prefer.
        </Text>
        {isRemoveAsapOnReactivateEnabled && (
          <Select
            name="selectedAvailableNextDate"
            label="Delivery Week"
            onChange={handleChange}
            defaultValue={deliveryWeekText}
            value={selectedAvailableNextDate || ''}
            className={styles.selectAvailableNextDate}
          >
            {!selectedAvailableNextDate && (
              <option value={deliveryWeekText}>{deliveryWeekText}</option>
            )}
            {props.availableNextDates.map(date => (
              <option key={date} value={date}>
                {formatDateToWeekOf(date)}
              </option>
            ))}
          </Select>
        )}
        {!isRemoveAsapOnReactivateEnabled && (
          <Select
            name="selectedAvailableNextDate"
            label="Delivery Week"
            onChange={handleChange}
            value={selectedAvailableNextDate || ''}
            className={styles.selectAvailableNextDate}
          >
            {props.availableNextDates.map(date => {
              return (
                <option key={date} value={date}>
                  {formatDateToWeekOf(date)}
                </option>
              );
            })}
          </Select>
        )}
        {isRemoveAsapOnReactivateEnabled && selectedAvailableNextDate && (
          <Text variant="heading-16" color="charcoal-3" topSpacing="sm">
            {chargeWeekText()}
          </Text>
        )}
        <DailyPriceQuote quote={props.quote} addOns={props.addOns} />
        <ModifyAccountMessage />
        <Button
          type="submit"
          loading={props.isSubmitting}
          className={styles.submit}
          aria-disabled={props.isSubmitting}
          onClick={() => setShowError(true)}
        >
          Reactivate
        </Button>
        {isRemoveAsapOnReactivateEnabled &&
          !selectedAvailableNextDate &&
          showError && (
            <Text variant="heading-16" color="error-red" topSpacing="sm">
              Please choose a delivery week.
            </Text>
          )}
        <div className={styles.subText}>
          <div className={styles.subTextItem}>
            <Text variant="heading-12" color="charcoal-3">
              Pause or cancel anytime.
            </Text>
          </div>
          <div className={styles.subTextItem}>
            <Text variant="heading-12" color="charcoal-3">
              By reactivating, you agree to our{' '}
            </Text>
            <Button
              type="button"
              variant="plain-text"
              onClick={termsOfUseModalControl.open}
            >
              <Text variant="heading-12" color="kale-3" bold>
                Terms of Use.
              </Text>
            </Button>{' '}
            <Text variant="heading-12" color="charcoal-3">
              The Farmer’s Dog is an auto-renewing subscription with no
              long-term commitment. Prices shown above do not include tax. You
              can cancel unprocessed orders anytime.
            </Text>
          </div>
          <SecurePaymentMessage />
        </div>
      </form>
      {termsOfUseModalControl.isOpen && (
        <TermsOfUseModal {...termsOfUseModalControl} />
      )}
    </section>
  );
}
