import { useMutation } from '@apollo/client';
import type {
  UpdateNotificationPreferencesMutation,
  UpdateNotificationPreferencesMutationVariables,
} from './updateNotificationPreferences.cgs.generated-types';
import updateNotificationPreferences from './updateNotificationPreferences.cgs.graphql';

interface Props {
  onCompleted: (
    response: UpdateNotificationPreferencesMutation['updateCustomerNotificationPreferences']['customer']['notificationPreferences']
  ) => void;
  onError: () => void;
}

export function useUpdateNotificationPreferences(props: Props) {
  const [submit, state] = useMutation<
    UpdateNotificationPreferencesMutation,
    UpdateNotificationPreferencesMutationVariables
  >(updateNotificationPreferences, {
    context: { endpoint: 'cgs' },
    onCompleted: response => {
      const data =
        response.updateCustomerNotificationPreferences.customer
          .notificationPreferences;

      props.onCompleted(data);
    },
    onError: props.onError,
  });

  async function callSubmit(
    input: UpdateNotificationPreferencesMutationVariables['input']
  ) {
    return await submit({ variables: { input } });
  }

  return {
    callSubmit,
    isSubmitting: state.loading,
  };
}
