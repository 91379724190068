import type { TextVariants } from '@farmersdog/corgi-x';
import { Text } from '@farmersdog/corgi-x';

import styles from './ProgressStep.module.scss';
import { useCorgiViewport } from 'src/screen';

interface ProgressStepProps {
  title: string;
  description: string;
  step?: number;
}

function ProgressStep({ title, description, step }: ProgressStepProps) {
  const variant = useCorgiViewport({
    xs: 'article-12',
    sm: 'article-16',
    md: 'article-20',
  });

  return (
    <div className={styles.stepContainer}>
      <Text
        aria-label={step ? `Step ${step}. ${title}.` : title}
        variant="heading-28"
        as="h5"
        className={styles.stepTitle}
        bold
      >
        {title}
      </Text>
      <Text
        variant={variant as TextVariants}
        as="p"
        className={styles.stepDescription}
      >
        {description}
      </Text>
    </div>
  );
}
export default ProgressStep;
