import { couponCookie } from '@farmersdog/coupons';

import { useQuery } from '../../../services/apollo';
import { GraphQLEndpoints } from '../../utils';

import fetchAvailableProductsQuery from './fetchAvailableProducts.graphql';

import type {
  DiscountType,
  FetchAvailableProductsQuery,
  FetchAvailableProductsQueryVariables,
} from '../../types';

export function useFetchAvailableProducts() {
  const discount = couponCookie.get();
  const discountCode = discount?.referrerCode || null;
  const discountType = discount?.referrerType
    ? (discount?.referrerType as unknown as DiscountType)
    : null;

  const { data, loading } = useQuery<
    FetchAvailableProductsQuery,
    FetchAvailableProductsQueryVariables
  >(fetchAvailableProductsQuery, {
    variables: { discountCode, discountType },
    context: {
      endpoint: GraphQLEndpoints.TOSA,
    },
  });

  return { data, loading };
}
