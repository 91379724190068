import { tosaToken } from '@farmersdog/lead-browser-storage';

import { restoreSignupFlow } from '../utils';

import { useLeadState } from './useLeadState';

import type { RestoreCachedLeadArgs } from '../types';

export function useCachedLead() {
  const { convertLeadToFormState, getCachedLead } = useLeadState();

  function restoreCachedLead({
    reset,
    blueprint,
    setFormSteps,
    experiments,
  }: RestoreCachedLeadArgs) {
    // If there is no token, there should not be any cache
    // Also updateLead will not be fired without a token so there is no need to restore form values
    const token = tosaToken.get();
    if (!token) {
      return;
    }

    const cachedLeadView = getCachedLead();

    if (!cachedLeadView) {
      return;
    }

    restoreSignupFlow({
      data: convertLeadToFormState(cachedLeadView),
      reset,
      resetOptions: {
        keepDirty: true,
        keepErrors: true,
        keepIsValid: true,
      },
      blueprint,
      setFormSteps,
      experiments,
    });
  }

  return restoreCachedLead;
}
