import { useMemo } from 'react';

import { NodeNames } from '@farmersdog/constants';

import { Placeholder } from '../blueprint/Placeholder';
import {
  PET_NAMES_PLACEHOLDER,
  PET_NAME_PLACEHOLDER,
  getNodeNameAndPosition,
} from '../blueprint/utils';
import { StyledLabel } from '../components/StyledLabel/StyledLabel';
import { getPetNamesFromFormEntries } from '../utils';

import type { TOSALeafNode, UseForm } from '../types';

interface UseLabelProps {
  node: TOSALeafNode;
  getValues: UseForm['getValues'];
  checkIfPlural?: boolean;
  overrideLabel?: string;
}
// TODO - to be imported from tosa-npm
export const INPUT_PLACEHOLDER = '%input%';

interface ParseLabelProps {
  label: string;
  isPlural: boolean;
  gender: string;
  currentPetName: string;
  petNames: string[];
}
function parseLabel({
  label,
  isPlural,
  gender,
  currentPetName,
  petNames,
}: ParseLabelProps) {
  const placeholder = new Placeholder(label)
    .handlePlural(isPlural)
    .handlePronoun(gender)
    .handlePetName(currentPetName)
    .handlePetNames(petNames)
    .handlePetSterilized(gender);

  return placeholder.modified;
}

export function useLabel({
  node,
  getValues,
  checkIfPlural,
  overrideLabel,
}: UseLabelProps) {
  const labelPlaceholder = overrideLabel ? overrideLabel : node.input?.label;
  const { position } = getNodeNameAndPosition(node.name);

  const genderField = `${NodeNames.Gender}-${position}` as const;

  const formValues = getValues();
  const petGender = formValues[genderField];
  const thisNodeValue = formValues[node.name];

  const { petNames, currentPetName } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName: node.name,
  });

  const isPlural = checkIfPlural ? parseIsPlural(thisNodeValue) : true;

  const { parsedLabel, frontLabelContainsPetname, backLabelContainsPetname } =
    useMemo(() => {
      if (!labelPlaceholder) {
        return {
          parsedLabel: '',
          frontLabelContainsPetname: false,
          backLabelContainsPetname: false,
        };
      }

      const [frontLabelPlaceholder, backLabelPlaceholder] =
        labelPlaceholder && labelPlaceholder.split(INPUT_PLACEHOLDER);

      return {
        // We re-parse the label when there is a value change to the fields that label placeholders depends on
        parsedLabel: parseLabel({
          label: labelPlaceholder,
          isPlural,
          gender: petGender ?? 'female',
          currentPetName,
          petNames,
        }),
        frontLabelContainsPetname: Boolean(
          frontLabelPlaceholder &&
            (frontLabelPlaceholder.includes(PET_NAME_PLACEHOLDER) ||
              frontLabelPlaceholder.includes(PET_NAMES_PLACEHOLDER))
        ),
        backLabelContainsPetname: Boolean(
          backLabelPlaceholder &&
            (backLabelPlaceholder.includes(PET_NAME_PLACEHOLDER) ||
              backLabelPlaceholder.includes(PET_NAMES_PLACEHOLDER))
        ),
      };
    }, [petGender, petNames, currentPetName, isPlural, labelPlaceholder]);

  const [frontLabel, backLabel] = parsedLabel.split(INPUT_PLACEHOLDER);

  return {
    parsedLabel,
    frontLabel: (
      <StyledLabel
        label={frontLabel}
        petNames={petNames}
        shouldApplyPetNameStyle={frontLabelContainsPetname}
      />
    ),
    backLabel: (
      <StyledLabel
        label={backLabel}
        petNames={petNames}
        shouldApplyPetNameStyle={backLabelContainsPetname}
      />
    ),
  };
}

// Existing behavior is to default to plural
function parseIsPlural(input: unknown) {
  if (!input) {
    return true;
  }

  if (typeof input === 'string') {
    return parseInt(input) > 1;
  } else if (typeof input === 'number') {
    return input > 1;
  }

  return true;
}
