import { useMutation } from '@apollo/client';
import type {
  UpdateCustomerWithSuspendedSubscriptionMutation,
  UpdateCustomerWithSuspendedSubscriptionMutationVariables,
} from './updateCustomerWithSuspendedSubscription.cgs.generated-types';
import updateCustomerMutation from './updateCustomerWithSuspendedSubscription.cgs.graphql';

interface Props {
  onCompleted: (
    result: UpdateCustomerWithSuspendedSubscriptionMutation
  ) => void;
  onError: (error: unknown) => void;
}

export function useUpdateCustomerWithSuspendedSubscription(props: Props) {
  const [callMutation, state] = useMutation<
    UpdateCustomerWithSuspendedSubscriptionMutation,
    UpdateCustomerWithSuspendedSubscriptionMutationVariables
  >(updateCustomerMutation, {
    context: {
      endpoint: 'cgs',
    },
    onCompleted: props.onCompleted,
    onError: props.onError,
  });

  async function submit(
    input: UpdateCustomerWithSuspendedSubscriptionMutationVariables['input']
  ) {
    return await callMutation({ variables: { input } });
  }

  return { submit, state };
}
