import { useLeadState } from '../useLeadState';

import type { UseFeatureHook, UseFeatureHookReturn } from '../../types';
import type { FeatureName } from '../../utils';

interface UseTosaUserFeatureProps {
  featureName: FeatureName;
  attributes?: Record<string, unknown>;
  useFeature: UseFeatureHook;
}

export function useTosaUserFeature({
  useFeature,
  featureName,
  attributes,
}: UseTosaUserFeatureProps): UseFeatureHookReturn {
  const { getCachedLead } = useLeadState();
  const lead = getCachedLead();

  const userId = lead?.corePostgresUserId
    ? String(lead.corePostgresUserId)
    : undefined;

  return useFeature(featureName, { attributes }, userId);
}
