import { toggleFragment } from '../../../schema/actions/toggleFragment';
import { DropdownInput } from '../base/DropdownInput';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function FoodTypeInput(props: TOSAComponentInput<TOSALeafNode>) {
  const {
    node,
    schema,
    setFormSteps,
    formValidationSchema,
    registry,
    formMethods,
  } = props;

  const handleChange: TOSAComponentInput<TOSALeafNode>['onChange'] = e => {
    const value = e.target.value;

    toggleFragment({
      value,
      node,
      schema,
      setFormSteps,
      formValidationSchema,
      registry,
      unregister: formMethods.unregister,
    });
  };

  return <DropdownInput {...props} onChange={handleChange} />;
}
