import type { FetchCountriesStateFragment } from 'src/graphql/types';
import type { Address } from './types';

/**
 * Determines if the results from google maps are valid.
 * Since the values are read from the dom, falsy values
 * such as null or undefined will be a string, so we also have
 * to check for these specific string values.
 */
function isGeocoderAddressComponent(
  value: google.maps.GeocoderAddressComponent | undefined,
  nameType: keyof google.maps.GeocoderAddressComponent
): value is google.maps.GeocoderAddressComponent {
  return Boolean(
    value &&
      value[nameType] &&
      value[nameType] !== 'undefined' &&
      value[nameType] !== 'null'
  );
}

/**
 * Parses the google maps response into our address object
 */
export function getAddressFromPlaceResult(
  place: google.maps.places.PlaceResult,
  states: FetchCountriesStateFragment[]
) {
  const [
    streetNumber,
    route,
    locality,
    sublocality,
    administrativeArea,
    postalCode,
  ] = [
    'street_number',
    'route',
    'locality',
    'sublocality_level_1',
    'administrative_area_level_1',
    'postal_code',
  ].map(addressComponentType =>
    place.address_components?.find(c => c.types.includes(addressComponentType))
  );
  const address: Address = {};
  if (
    isGeocoderAddressComponent(streetNumber, 'short_name') &&
    isGeocoderAddressComponent(route, 'short_name')
  ) {
    address.addressLine1 = `${streetNumber.short_name} ${route.short_name}`;
  } else if (isGeocoderAddressComponent(route, 'short_name')) {
    address.addressLine1 = route.short_name;
  }
  if (isGeocoderAddressComponent(locality, 'short_name')) {
    address.city = locality.short_name;
  } else if (isGeocoderAddressComponent(sublocality, 'short_name')) {
    address.city = sublocality.short_name;
  }
  if (isGeocoderAddressComponent(administrativeArea, 'long_name')) {
    address.state = states.find(s => s.name === administrativeArea.long_name);
  }
  if (isGeocoderAddressComponent(postalCode, 'short_name')) {
    address.zip = postalCode.short_name;
  }

  return address;
}
