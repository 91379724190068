import type { ReactNode } from 'react';
import type { Extra, Severity, ApplicationErrorArgs } from './ApplicationError';
import ApplicationError from './ApplicationError';

/**
 * A `UserError` is an error whose message is meant to appear in the UI.
 * Avoid initializing `UserError` with technical messages!
 *
 * @example
 *
 * ```ts
 *  new UserError('Something went wrong');
 *  new UserError(
 *    "A user friendly message",
 *    <p>A more complex <a href="/">message</a></p>
 *  );
 * ```
 */
class UserError extends ApplicationError {
  displayMessage: ReactNode;

  /** Overload for a simple constructor with just a message */
  constructor(message: string);

  /** Overload for a constructor with a message and display message */
  constructor(message: string, displayMessage: ReactNode);

  /** Overload for a constructor with a message, display message and extra */
  constructor(message: string, displayMessage: ReactNode, extra: Extra);

  /** Overload for a constructor with a message, display message and severity */
  constructor(message: string, displayMessage: ReactNode, severity: Severity);

  /**
   * Overload for a constructor with a message, display message, extra and
   * severity
   */
  constructor(
    message: string,
    displayMessage: ReactNode,
    extra: Extra,
    severity: Severity
  );

  /**
   * Initialize a new instance of a user error.
   *
   * TODO: Revise these errors to be less confusing
   * https://app.clubhouse.io/farmersdog/story/35490/chore-create-an-architecture-proposal-for-better-client-error-handling
   *
   * @param message - A simple error message
   * @param displayMessage - A message that supports react
   * @param extra - Extra data to add to the event
   * @param severity - The event's severity level
   */
  constructor(
    message: string,
    displayMessage?: ReactNode,
    ...args: ApplicationErrorArgs
  ) {
    // @ts-expect-error https://github.com/microsoft/TypeScript/issues/28010
    super(message, ...args);
    this.name = 'UserError';
    if (displayMessage) {
      this.displayMessage = displayMessage;
    }
  }
}

export default UserError;
