import { useQuery } from '@apollo/client';
import type {
  FetchRecipeDetailsQuery,
  FetchRecipeDetailsQueryVariables,
} from './fetchRecipeDetails.cgs.generated-types';
import fetchRecipeDetailsQuery from './fetchRecipeDetails.cgs.graphql';

export function useFetchRecipeDetails(productName: string) {
  return useQuery<FetchRecipeDetailsQuery, FetchRecipeDetailsQueryVariables>(
    fetchRecipeDetailsQuery,
    {
      context: {
        endpoint: 'cgs',
      },
      variables: {
        recipeInput: { productName },
      },
    }
  );
}
