import { useHistory } from 'react-router-dom';
import { PATH_ORDERS, PATH_PROFILE } from '@farmersdog/constants/paths';
import type { RescheduleScene } from '../TemporaryPause';
import { trackTemporaryPauseCancel } from '../analytics';
import type { UseScenes } from './useScenes';

export type UseWorkflowScenes = UseScenes<RescheduleScene>;

export type UseWorkflow = {
  /** Close the workflow by redirecting to the subscription page. */
  close: () => void;
  /** The number of steps to display. The number may change according to the user's answers. */
  numberOfSteps: number;
  /** The display number of the active step. */
  activeStep: number;
  /** Whether the user is allowed to go back in the workflow. */
  allowBack: boolean;
  /** Go to the previous scene. The scene may change according to the user's answers. */
  goToPreviousScene: () => void;
  /** Go to the next scene. The scene may change according to the user's answers. */
  goToNextScene: () => void;
};

/** A hook to handle the the workflow between reschedule and scenes */
export function useWorkflow(scenes: UseWorkflowScenes): UseWorkflow {
  const history = useHistory();

  // Scene navigation
  const goToNextScene = () => {
    if (!scenes.nextScene) return;
    scenes.setActiveScene(scenes.nextScene);
  };

  const goToPreviousScene = () => {
    if (!scenes.previousScene) return;
    scenes.setActiveScene(scenes.previousScene);
  };

  // Event handler when closing the workflow
  const close = () => {
    if (!scenes.isLastScene) {
      trackTemporaryPauseCancel();
      history.push(`${PATH_PROFILE}#subscription`);
    } else {
      return history.push(PATH_ORDERS);
    }
  };

  const numberOfSteps = scenes.scenes.length;

  // Success scene and initial scene aren't allowed to go back
  const allowBack = !scenes.isFirstScene && !scenes.isLastScene;
  const activeStep = Math.min(scenes.activeIndex + 1, numberOfSteps);

  return {
    close,
    goToNextScene,
    goToPreviousScene,
    numberOfSteps,
    activeStep,
    allowBack,
  };
}
