import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';

import type { UseFormNavigateReturn, UseSchemaReturn } from '../../../types';

interface GoToFinalMeStepArgs {
  formSteps: UseSchemaReturn['formSteps'];
  goToStep: UseFormNavigateReturn['goToStep'];
}
export function goToFinalMeStep({ formSteps, goToStep }: GoToFinalMeStepArgs) {
  const finalMeStep = [...formSteps].reverse().find(formStep => {
    return formStep.route === PATH_SIGNUP_ME;
  });
  if (finalMeStep) goToStep(finalMeStep?.__self__);
}
