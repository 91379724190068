import { useQuery } from '../../services/apollo';

import query from './petRecipes.graphql';

import type { PetRecipesQuery, PetRecipesQueryVariables } from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.QueryHookOptions<
  PetRecipesQuery,
  PetRecipesQueryVariables
>;

export function usePetRecipes(options?: Options) {
  return useQuery<PetRecipesQuery, PetRecipesQueryVariables>(query, options);
}
