import { Fragment } from 'react';

import { Node } from '../schema/Node';

import type { BranchNode } from '../../blueprint/types';
import type { TOSAComponentInput } from '../../types';

export function RecipesFormWrapper(props: TOSAComponentInput<BranchNode>) {
  const { formNavigation, node } = props;

  return (
    <>
      {node.children.map(childNode => {
        return childNode.__self__ === formNavigation.current ? (
          <Fragment key={'recipes-' + childNode.__self__}>
            <Node {...props} node={childNode} key={childNode.__self__} />
          </Fragment>
        ) : null;
      })}
    </>
  );
}
