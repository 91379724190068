import pick from 'lodash/pick';
import get from 'lodash/get';

import { isValidationFailure, RequestError, ValidationError } from './errors';
import type { AxiosError } from 'axios';

export function parseSuccess(payload: {
  data?:
    | {
        links?: string;
        data?: unknown;
        [k: string]: unknown;
      }
    | string;
}) {
  const { data } = payload;
  if (!data) {
    return payload;
  }
  return {
    ...payload,
    links: typeof data === 'object' ? data.links : undefined,
    data: get(data, 'data', data),
  };
}

export function parseFailure<T extends Omit<AxiosError, 'status' | 'cause'>>(
  failure: T
): RequestError | ValidationError {
  let error;
  if (isValidationFailure(failure)) {
    error = new ValidationError(failure);
  } else {
    error = new RequestError(failure);
  }

  // Add additional error info (they will be captured from DataDog)
  const configFields = ['baseURL', 'data', 'headers', 'method', 'url'];
  const responseFields = ['headers', 'status', 'data'];
  error.extra = {
    request_config: pick(error.config, configFields),
    response: pick(error.response, responseFields),
    retry_count: error.retryCount,
  };

  return error;
}
