import { mountVendorWindowEvent } from '../utils';
import type { PEACOCK_EVENTS } from './PeacockScript';

export const PRODUCT_ID = '830824';
export const ACCESS_TOKEN = 'aea4b8d1-b8c2-459a-ad3f-18166cdd7577';

interface TrackPeacockEventParams {
  eventName: PEACOCK_EVENTS;
}
export function _trackPeacockEvent(
  window: Window,
  { eventName }: TrackPeacockEventParams
) {
  window.pubSuite?.sendConversion(eventName);
}

export const trackPeacockEvent = mountVendorWindowEvent(
  'Peacock',
  _trackPeacockEvent
);
