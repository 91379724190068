import { useQuery } from '@apollo/client';
import fetchChangeFreshOrderSizeQuotes from './fetchChangeFreshOrderSizeQuotes.cgs.graphql';
import type {
  ChangeFreshOrderSizeQuotesQuery,
  ChangeFreshOrderSizeQuotesQueryVariables,
} from './fetchChangeFreshOrderSizeQuotes.cgs.generated-types';

export function useFetchChangeFreshOrderSizeQuotes() {
  return useQuery<
    ChangeFreshOrderSizeQuotesQuery,
    ChangeFreshOrderSizeQuotesQueryVariables
  >(fetchChangeFreshOrderSizeQuotes, {
    context: { endpoint: 'cgs' },
  });
}
