import type { FreshRecipeRecommendation } from '../../../graphql/types';

interface GetSelectedAvailableRecipesArgs {
  currentPetRecipes: Pick<FreshRecipeRecommendation, 'name'>[] | undefined;
  currentSelection: string[] | undefined;
}

export function getSelectedAvailableRecipes({
  currentPetRecipes,
  currentSelection,
}: GetSelectedAvailableRecipesArgs): string[] | undefined {
  const availableRecipes = currentPetRecipes?.map(recipe => recipe.name);
  if (currentSelection && availableRecipes) {
    return currentSelection.filter(recipe =>
      availableRecipes.find(
        availableRecipe =>
          availableRecipe.toLowerCase() === recipe.toLowerCase()
      )
    );
  }
  return undefined;
}
