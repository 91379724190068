import type { anonymousFeatures, userFeatures } from './features';
import { SplitKey as OnbHomepagePersonalizationPickySplitKey } from './features/OnbHomepagePersonalizationPicky';
import { SplitKey as OnbHomepagePlanProgressionSplitKey } from './features/OnbHomepagePlanProgression';

export type BaseTreatment = 'on' | 'off' | 'control';
export type WeekdayTreatment =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

export type UserTreatment = BaseTreatment | WeekdayTreatment;
type AnonymousTreatment = BaseTreatment;
export type Treatment = UserTreatment | AnonymousTreatment;

type CustomerCxPhoneNumberConfigItem = {
  email: boolean;
  phone: boolean;
};

export type CustomerCxPhoneNumberConfig = {
  footer: CustomerCxPhoneNumberConfigItem;
  faq: CustomerCxPhoneNumberConfigItem;
  diySupport: CustomerCxPhoneNumberConfigItem;
};

export function isCustomerCxPhoneNumberConfig(
  config: unknown
): config is CustomerCxPhoneNumberConfig {
  return Boolean(
    config &&
      Object.prototype.hasOwnProperty.call(config, 'footer') &&
      Object.prototype.hasOwnProperty.call(config, 'faq') &&
      Object.prototype.hasOwnProperty.call(config, 'diySupport')
  );
}

export type RetPawToggleConfig = {
  profile: boolean;
};

type ProspectiveCxPhoneNumberConfigItem = {
  email: boolean;
  phone: boolean;
};

export type ProspectiveCxPhoneNumberConfig = {
  footer: ProspectiveCxPhoneNumberConfigItem;
  diySupport: ProspectiveCxPhoneNumberConfigItem;
};

export function isProspectiveCxPhoneNumberConfig(
  config: unknown
): config is ProspectiveCxPhoneNumberConfig {
  return Boolean(
    config &&
      Object.prototype.hasOwnProperty.call(config, 'footer') &&
      Object.prototype.hasOwnProperty.call(config, 'diySupport')
  );
}

export type UserConfig =
  | CustomerCxPhoneNumberConfig
  | RetPawToggleConfig
  | null;
export type AnonymousConfig = ProspectiveCxPhoneNumberConfig | null;
export type Config = UserConfig | AnonymousConfig;

export enum UserFeatureKeys {
  cancel_inflight_warning = 'cancel_inflight_warning',
  customer_cx_phone_number = 'customer_cx_phone_number',
  ret_addpet_toggle = 'ret_addpet_toggle',
  ret_holidaycontent_toggle = 'ret_holidaycontent_toggle',
  ret_holidayfaqs_toggle = 'ret_holidayfaqs_toggle',
  ret_pet_calibration_toggle = 'ret_pet_calibration_toggle',
  tsx_orderstatus_animation = 'tsx_orderstatus_animation',
  ssp_schedule_card = 'ssp_schedule_card',
  adj_payment_retry = 'adj_payment_retry',
  onb_homepage_personalization_picky = `${OnbHomepagePersonalizationPickySplitKey}`,
  onb_homepage_plan_progression = `${OnbHomepagePlanProgressionSplitKey}`,
  HEALTH_CUSTOMER_CLINIC_SEARCH = 'HEALTH_CUSTOMER_CLINIC_SEARCH',
  adj_add_dog_form = 'adj_add_dog_form',
  ssp_free_first_treat_enabled = 'ssp_free_first_treat_enabled',
  adj_pre_grace_period = 'adj_pre_grace_period',
  tsx_essentials_guides = 'tsx_essentials_guides',
  adj_remove_asap_on_reactivate = 'adj_remove_asap_on_reactivate',
}

export enum AnonymousFeatureKeys {
  prospective_cx_phone_number = 'prospective_cx_phone_number',
}

export type AnonymousFeatures = typeof anonymousFeatures;
export type UserFeatures = typeof userFeatures;
export type FeaturesMap = AnonymousFeatures & UserFeatures;
export type FeatureKeys = keyof FeaturesMap;
