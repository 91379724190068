import type * as errorCodes from 'src/errors/errorCodes';

/**
 * The severity of the error event. These severities are sent to the
 * error logging service so that error can be triaged according to severity.
 */
export enum Severity {
  fatal = 'fatal',
  error = 'error',
  warning = 'warning',
  info = 'info',
  debug = 'debug',
}

/**
 * Freeform data to add the the error event. This data is sent along with the
 * error to the logging service.
 */
export interface Extra {
  [name: string]: unknown;
}

export type ApplicationErrorArgs =
  | [Extra, Severity]
  | [Extra]
  | [Severity]
  | [];

/**
 * Use the `ApplicationError` object to create an application error. Application
 * errors may contains more data and features useful to debug them.
 *
 * @example
 *
 * ```ts
 *  throw new ApplicationError('Something went wrong');
 *  throw new ApplicationError('Something went wrong', { userId: 100 });
 *  throw new ApplicationError('Something went wrong', 'warning');
 *  throw new ApplicationError('Something went wrong', { userId: 100 }, 'info');
 * ```
 */
class ApplicationError extends Error {
  severity?: Severity;
  extra?: Extra;
  code?: (typeof errorCodes)[keyof typeof errorCodes];

  /** Overload for a simple constructor with just a message */
  constructor(message: string);

  /** Overload for a constructor with a message and extra */
  constructor(message: string, extra: Extra);

  /** Overload for a constructor with a message and severity */
  constructor(message: string, severity: Severity);

  /** Overload for a constructor with a message, severity and extra */
  constructor(message: string, extra: Extra, severity: Severity);

  /**
   * Initialize a new instance of an application error.
   *
   * TODO: Revise these errors to be less confusing
   * https://app.clubhouse.io/farmersdog/story/35490/chore-create-an-architecture-proposal-for-better-client-error-handling
   *
   * @param message - The error message
   * @param extra - Extra data to add to the event
   * @param severity - The event's severity level
   */
  constructor(message: string, ...args: ApplicationErrorArgs) {
    super(message);
    this.name = 'ApplicationError';

    if (isSeverity(args[0])) {
      this.severity = args[0];
    }

    if (isSeverity(args[1])) {
      this.severity = args[1];
    }

    if (isExtra(args[0])) {
      this.extra = args[0];
    }

    if (isExtra(args[1])) {
      this.extra = args[1];
    }
  }

  getContext() {
    return {
      ...this.extra,
      code: this.code,
      severity: this.severity,
    };
  }
}

/**
 * Return whether a value is an extra object to send to the reporter. This can be
 * removed if this error class is converted to use named arguments.
 *
 * @param value - An unknown value that may be an extra object.
 */
function isExtra(value: unknown): value is Extra {
  return typeof value === 'object';
}

/**
 * Return whether a value is a severity parameter to send to the reporter. This can be
 * removed if this error class is converted to use named arguments.
 *
 * @param value - An unknown value that may be a severity parameter.
 */
function isSeverity(value: unknown): value is Severity {
  return typeof value === 'string' && value in Severity;
}

export default ApplicationError;
