import type { DiyRecipe } from '../types';
import { PrimaryProtein, RecipesCategory } from '../types';
import genericDiyRecipeImageWebp from '../assets/generic-diy-recipe-image.webp';

export const lowFatChickenAndRice: DiyRecipe = {
  name: 'Low Fat Chicken & Rice',
  id: 'low-fat-chicken-&-rice',
  primaryProtein: PrimaryProtein.Chicken,
  filterBy: RecipesCategory.LowFat,
  ingredients: [
    {
      name: '98% lean ground chicken',
      quantity: 1200,
      unit: 'g',
      alternativeQuantity: 2.6,
      alternativeUnit: 'lb',
    },
    {
      name: 'rice, cooked',
      quantity: 875,
      unit: 'g',
      alternativeQuantity: 1.9,
      alternativeUnit: 'lb',
    },
    {
      name: 'zucchini',
      quantity: 175,
      unit: 'g',
      alternativeQuantity: 0.39,
      alternativeUnit: 'lb',
    },
    {
      name: 'spinach, chopped',
      quantity: 175,
      unit: 'g',
      alternativeQuantity: 0.39,
      alternativeUnit: 'lb',
    },
    {
      name: 'canned pumpkin',
      quantity: 123,
      unit: 'g',
      alternativeQuantity: 0.5,
      alternativeUnit: 'cup',
    },
    {
      name: 'The Farmers Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'sunflower oil',
      quantity: 9,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 4.5,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  yieldWeight: 4.5,
  cookTime: '40 mins',
  ingredientSummary:
    'Ground Chicken, White Rice, Zucchini, Spinach, Canned Pumpkin, Sunflower Oil, Salmon Oil',
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: genericDiyRecipeImageWebp,
  instructions: [
    {
      name: 'Step 1',
      description:
        'Cook the rice according to the instructions on the packaging and set aside.',
    },
    {
      name: 'Step 2',
      description:
        'Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop.',
    },
    {
      name: 'Step 3',
      description:
        'Add the ground chicken to the pot. Break apart and stir until the meat is no longer pink and is broken into small pieces.',
    },
    {
      name: 'Step 4',
      description:
        "Add the cooked rice, zucchini, spinach, and canned pumpkin. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork. Do not drain any liquid or fat that's a result of the cooking process.",
      note: "note: 'Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. Large chunks may require additional cooking time.",
    },
    {
      name: 'Step 5',
      description:
        'If using a food safe thermometer, check the temperature at several places throughout the pot to verify that a food safe temperature of 165°F is reached.',
      note: 'In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.',
    },
    {
      name: 'Step 6',
      description:
        "Remove the pot from the heat and add sunflower oil and salmon oil. Then, sprinkle {numberOfPackets} of The Farmer's Dog DIY Nutrient Mix evenly across the surface. Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.",
    },
  ],
  calories: 2430,
};
