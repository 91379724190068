import type { VariantColors } from '@farmersdog/corgi-x';
import {
  Button,
  Grid,
  GridItem,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';
import styles from './Nudge.module.css';

import type { ReactNode } from 'react';

export interface NudgeProps {
  onMaybeLaterClick: () => void;
  setNudgeDismissed: () => void;
  imageSources: string[];
  imageAltText: string;
  headlineText: string;
  subheadText: string;
  body: ReactNode;
  ctaText: string;
  ctaLinkDestination: string;
  ctaOnClick: () => void;
  componentTitle: string;
  dismissCtaText?: string;
  trackDismissCta: () => void;
  bodyColor?: VariantColors;
}
/**
 * Renders a nudge on the Homepage to encourage users to take an action
 */
export function Nudge(props: NudgeProps) {
  return (
    <Section aria-labelledby={props.componentTitle} className={styles.nudge}>
      <Grid className={styles.nudgeContainer}>
        <Picture
          sources={props.imageSources}
          alt={props.imageAltText}
          className={styles.image}
        />

        <div>
          <GridItem className={styles.headline}>
            <Text variant="heading-12" bold role="heading">
              {props.headlineText}
            </Text>
          </GridItem>
          <GridItem className={styles.subhead}>
            <Text variant="heading-28" bold color="white" role="heading">
              {props.subheadText}
            </Text>
          </GridItem>
          <GridItem className={styles.bodyText}>
            <Text variant="article-16" color={props.bodyColor}>
              {props.body}
            </Text>
          </GridItem>
          <GridItem className={styles.buttonCTAContainer}>
            <Button
              to={props.ctaLinkDestination}
              type="link"
              variant="secondary"
              className={styles.buttonCTA}
              style={{ border: 0 }}
              onClick={props.ctaOnClick}
            >
              {props.ctaText}
            </Button>
          </GridItem>
          {props.dismissCtaText && (
            <GridItem className={styles.buttonDismissContainer}>
              <Button
                type="button"
                variant="plain-text"
                style={{ color: 'white' }}
                onClick={() => {
                  props.onMaybeLaterClick();
                  setTimeout(props.setNudgeDismissed, 1);
                  props.trackDismissCta();
                }}
              >
                {props.dismissCtaText}
              </Button>
            </GridItem>
          )}
        </div>
      </Grid>
    </Section>
  );
}
