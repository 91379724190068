import { Picture, Text } from '@farmersdog/corgi-x';
import type { ReactNode } from 'react';
import styles from './ProgressItem.module.css';

interface ProgressItemProps {
  iconSrc: string[];
  heading: string;
  content: ReactNode;
}

export function ProgressItem({ iconSrc, heading, content }: ProgressItemProps) {
  return (
    <div className={styles.root} tabIndex={0}>
      <Picture
        sources={iconSrc}
        alt="hidden"
        className={styles.icon}
        aria-hidden="true"
      />
      <div className={styles.bar} />
      <Text
        variant="heading-22"
        bold
        as="h3"
        color="kale-3"
        bottomSpacing="xxs"
      >
        {heading}
      </Text>
      <Text variant="heading-16">{content}</Text>
    </div>
  );
}
