import type { EngagementTrackerVersion } from '@farmersdog/utils';
import type { SessionData } from './utils';
import { isSessionValid, updateSession, createSession } from './utils';

import { SessionDataStorage } from './storage';

export function refreshSession(
  currentTrackerVersion: EngagementTrackerVersion
): SessionData {
  const sessionDataStorage = new SessionDataStorage();

  const currentSession = sessionDataStorage.read();

  const isCurrentSessionValid = isSessionValid(
    currentSession,
    currentTrackerVersion
  );
  const nextSession = isCurrentSessionValid
    ? updateSession(currentSession)
    : createSession(currentTrackerVersion);

  sessionDataStorage.write(nextSession);
  return nextSession;
}
