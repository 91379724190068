import { Input } from '@farmersdog/corgi-x';
import type { AddDogFormChangeHandler } from '../../../hooks';

interface AgeInputProps {
  ageNumber: number;
  onChange: AddDogFormChangeHandler;
}

export const AgeInput = (props: AgeInputProps) => {
  const { ageNumber, onChange } = props;

  const handleAgeNumberChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    onChange('ageNumber', parseFloat(e.target.value));
  };
  return (
    <Input
      name="age-number"
      type="number"
      label="Age"
      value={ageNumber}
      onChange={handleAgeNumberChange}
      aria-required
      withSpinButtons={false}
    />
  );
};
