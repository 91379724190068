import { NodeNames } from '@farmersdog/constants';

import { handlePhoneBackspaceChange } from './handlePhoneBackspaceChange';
import { denormalizePhone, denormalizeZipCode } from './normalizers';

import type { ExtendedTextInputChangeEvent } from '../types';
interface DenormalizeTextInputValueProps {
  name: string;
  value: string;
  event: ExtendedTextInputChangeEvent;
}
export function denormalizeTextInputValue({
  name,
  value,
  event,
}: DenormalizeTextInputValueProps) {
  switch (name) {
    case NodeNames.Phone: {
      let denormalizedPhone = denormalizePhone(value);
      const nativeEvent = event.nativeEvent;
      if (
        nativeEvent.inputType === 'deleteContentBackward' &&
        !nativeEvent.data
      ) {
        denormalizedPhone = handlePhoneBackspaceChange({
          phoneInput: value,
          denormalizedPhone,
        });
      }

      return denormalizedPhone;
    }
    case NodeNames.Zip: {
      return denormalizeZipCode({ value, digits: 5 });
    }
    default:
      return value;
  }
}
