import { useState, useEffect, useRef } from 'react';
import type { WebsiteAuth } from '../../App.types';
import {
  getTokenFromCookie,
  removeTokenCookie,
  setTokenCookie,
  getUserIdFromToken,
} from '../../../utils';

export interface UseAuth {
  token: string | undefined;
  userId: number | undefined;
  setToken: (newToken: string | undefined) => void;
  isLoggedIn: boolean;
  logout: () => void;
}

export function useAuth(websiteAuth?: WebsiteAuth): UseAuth {
  const initialToken = websiteAuth ? websiteAuth.token : getTokenFromCookie();
  const [token, setToken] = useState(initialToken);
  const prevTokenRef = useRef(initialToken);
  let userId;

  if (token) {
    userId = getUserIdFromToken(token);
  }

  useEffect(() => {
    if (prevTokenRef.current === token) return;
    prevTokenRef.current = token;

    if (websiteAuth) {
      if (token) {
        websiteAuth.onSetToken(token);
      } else {
        websiteAuth.onRemoveToken();
      }
    }

    if (!websiteAuth) {
      if (token) {
        setTokenCookie(token);
      } else {
        removeTokenCookie();
      }
    }
  }, [token, websiteAuth]);

  function logout() {
    setToken(undefined);
  }

  return {
    token,
    userId,
    setToken,
    isLoggedIn: Boolean(token),
    logout,
  };
}
