import {
  Grid,
  GridItem,
  Link,
  Nowrap,
  PageWidth,
  Picture,
  Text,
} from '@farmersdog/corgi-x';
import FoodItem from '../FoodItem';
import type { ReactNode } from 'react';

import { foodPackagesSources } from './assets';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';
import { getLinkToFreshSignup } from 'src/pages/Signup/DIY/utils/getLinkToSignupFlow';

import styles from './SectionPackage.module.scss';
import classNames from 'classnames';
import { PATH_SIGNUP } from '@farmersdog/constants/paths';

function SectionPackage() {
  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();

  interface Item {
    id: number;
    title: string;
    description: string | ReactNode;
    accessibleTitle?: string;
  }

  const ITEMS: Array<Item> = [
    {
      id: 1,
      title: 'Tested on Humans',
      description: (
        <>
          Human-grade food in human-friendly packaging. Less processing, higher
          safety standards, and easy serving — just open{' '}
          <Nowrap>and pour!</Nowrap>
        </>
      ),
    },
    {
      id: 2,
      title: 'Personalized Plans',
      description: (
        <>
          A vet-designed plan is customized to your dog’s individual needs.
          Meals are pre-made and pre-portioned to their calorie needs{' '}
          <Link
            to={getLinkToFreshSignup(PATH_SIGNUP)}
            className={
              isAccessibilityThemeOn ? styles.accessibilityThemeLink : ''
            }
          >
            based on the profile <Nowrap>you create.</Nowrap>
          </Link>
        </>
      ),
    },
    {
      id: 3,
      title: 'Delivered Within Days',
      description: (
        <>
          Your food is made fresh and quickly frozen to lock in all the
          nutrients. No preservatives. Never stored on a shelf{' '}
          <Nowrap>for years</Nowrap>.
        </>
      ),
    },
  ];

  return (
    <section
      className={classNames(styles.section, {
        [styles.accessibilityTheme]: isAccessibilityThemeOn,
      })}
    >
      <PageWidth>
        <Grid
          className={styles.container}
          rowGap={{ xs: 'xl', lg: 'xxs' }}
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          bottomSpacing={{ xs: 'xxl', lg: '3xl' }}
          columnGap="xl"
        >
          <GridItem
            className={styles.imageContainer}
            flexDirection="column"
            lg={6}
          >
            <Picture
              sources={foodPackagesSources}
              loading="lazy"
              className={styles.image}
              alt="Beef and Turkey food packages"
            />
          </GridItem>
          <GridItem
            className={styles.itemsSection}
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: 'center', lg: 'flex-end' }}
            lg={6}
          >
            <Text variant="heading-40" color="kale-3" bold>
              How we made <br />
              pet food healthy:
            </Text>
            <GridItem
              className={styles.itemsContainer}
              flexDirection={{ xs: 'row', lg: 'column' }}
              justifyContent="center"
            >
              {ITEMS.map(({ title, description, id }) => (
                <FoodItem title={title} color="kale-3" isTitleBold key={id}>
                  {description}
                </FoodItem>
              ))}
            </GridItem>
          </GridItem>
        </Grid>
      </PageWidth>
    </section>
  );
}

export default SectionPackage;
