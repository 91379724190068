import { Instagram } from './Instagram/Instagram';
import instagramData from './Instagram/instagramData';
import { Testimonial } from './Testimonial/Testimonial';
import testimonialData from './Testimonial/testimonialData';

import type { IntstagramSlideData } from './Instagram/instagramData';
import type { TestimonialSlideData } from './Testimonial/testimonialData';
import type { FC } from 'react';

const instagramFragments: SlideFragment[] = instagramData.map(data => ({
  data,
  component: Instagram,
}));

const testimonialFragments: SlideFragment[] = testimonialData.map(data => ({
  data,
  component: Testimonial,
}));

interface SlideFragment {
  data: IntstagramSlideData | TestimonialSlideData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FC<any>;
}

export const getSlides = () => {
  const interleavedSlides: SlideFragment[] = [];
  const arrays = [instagramFragments, testimonialFragments];

  const longestArray = arrays.reduce(
    (prev, next) => (prev.length > next.length ? prev : next),
    []
  );

  longestArray.forEach((_: SlideFragment, j: number) => {
    arrays.forEach((__, i) => {
      const item = arrays[i][j];

      if (item) {
        interleavedSlides.push(item);
      }
    });
  });

  return interleavedSlides;
};

export const DOT_GROUP_LENGTH = 12;

export function getDotGroup(
  totalSlides: number,
  currentSlide: number,
  scrollBy: number,
  visibleSlides: number
): number {
  const remainder = (totalSlides / scrollBy) % DOT_GROUP_LENGTH;
  const maxDots = Math.min(totalSlides, DOT_GROUP_LENGTH);

  if (remainder === 0) {
    return maxDots;
  }

  if ((totalSlides - currentSlide) / scrollBy <= remainder) {
    return visibleSlides > 1 ? remainder - 1 : remainder;
  }

  return maxDots;
}
