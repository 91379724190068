import cloneDeep from 'lodash/cloneDeep';

import {
  findBranchNodeByName,
  appendChildren,
  truncateChildren,
} from '../schemaHelpers';

import type { UpdateSchemaWithFragmentsProps } from '../../types';

export function updateSchemaWithFragments({
  fragmentCount,
  fragments,
  index,
  schema,
}: UpdateSchemaWithFragmentsProps) {
  const clonedSchema = cloneDeep(schema);

  for (const fragment of fragments) {
    const { source, target } = fragment;

    const fragmentSource = clonedSchema.fragments.find(f => f.name === source);
    let targetName = target;
    if (fragment.indexedTarget && index) {
      targetName = `${targetName}-${index}`;
    }
    const nodeTarget = findBranchNodeByName({
      name: targetName,
      node: clonedSchema.root,
    });

    if (nodeTarget && fragmentSource) {
      const difference = fragmentCount - nodeTarget.children.length;
      if (difference > 0) {
        const updatedNodeTarget = appendChildren({
          fragmentSource,
          nodeTarget,
          difference:
            index !== undefined && fragmentCount !== nodeTarget.children.length
              ? fragmentCount
              : difference,
          index,
        });

        nodeTarget.children = updatedNodeTarget.children;
      } else if (difference < 0) {
        const updatedNodeTarget = truncateChildren({
          nodeTarget,
          difference,
        });
        nodeTarget.children = updatedNodeTarget.children;
      }
    }
  }

  return clonedSchema;
}
