import type { CSSProperties } from 'react';
import { useState, useEffect, useRef } from 'react';
import { reporter } from 'src/services/reporter';
import { useStripeElements } from '@farmersdog/corgi-x';
import { usePaymentRequestContext } from './PaymentRequestContext';
import { PAYMENT_REQUEST_BUTTON } from './constants';
import noop from 'lodash/noop';
import type {
  StripePaymentRequestButtonElement,
  StripePaymentRequestButtonElementClickEvent,
} from '@stripe/stripe-js';

interface PaymentRequestButtonProps {
  id: string;
  style?: CSSProperties;
  className?: string;
  onClick?: (event: StripePaymentRequestButtonElementClickEvent) => void;
  height?: string;
}

/**
 * Creates a dynamic payment request button from the Stripe Elements API. Can
 * only be used within both StripeContextProvider and StripeElementsProvider.
 */
function PaymentRequestButton({
  id,
  style,
  className,
  onClick,
  height = '50px',
}: PaymentRequestButtonProps) {
  const elements = useStripeElements();
  const { paymentRequest, canMakePayment, openPaymentInterface } =
    usePaymentRequestContext();
  const [isMounted, setIsMounted] = useState(false);
  const [stripeElement, setStripeElement] =
    useState<StripePaymentRequestButtonElement>();
  const onClickRef = useRef(noop);
  const stripeElementId = `${id}-payment-request-button`;

  /**
   * Create payment request button Stripe Element. Requires paymentRequest
   * object to already exist.
   */
  useEffect(() => {
    if (!elements || !paymentRequest) {
      return;
    }

    const cachedElement = elements.getElement(PAYMENT_REQUEST_BUTTON);
    if (cachedElement) {
      setStripeElement(cachedElement);
      return;
    }

    setStripeElement(
      elements.create(PAYMENT_REQUEST_BUTTON, {
        paymentRequest,
        style: {
          paymentRequestButton: { height },
        },
      })
    );
  }, [elements, paymentRequest, height]);

  /**
   * Mount element and add click listener. The listener is only added once
   * and a ref is used to update it since Stripe currently doesn't support
   * removing event listeners.
   */
  useEffect(() => {
    if (!stripeElement || !canMakePayment || isMounted) {
      return;
    }

    stripeElement.on('click', event => {
      openPaymentInterface();
      onClickRef.current(event);
    });

    try {
      // Note: using `#${stripeElementId}` as a selector here throws an error because
      // the element id contains characters that cannot be used in a selector
      // eslint-disable-next-line no-undef
      const element = document.getElementById(stripeElementId);
      if (element) {
        stripeElement.mount(element);
      }
    } catch (error) {
      // Attempting to mount the button before calling canMakePayment on the
      // paymentRequest instance will throw a Stripe integration error.
      reporter.error(error);
    }
    setIsMounted(true);
  }, [
    stripeElement,
    canMakePayment,
    isMounted,
    stripeElementId,
    openPaymentInterface,
  ]);

  /** Update ref with changed onClick listener. */
  useEffect(() => {
    if (!onClick) {
      return;
    }
    onClickRef.current = onClick;
  }, [onClick]);

  // Returns a wrapper that Stripe appends a button to.
  return <div id={stripeElementId} style={style} className={className}></div>;
}

export default PaymentRequestButton;
