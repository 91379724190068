import type { RefObject } from 'react';
import { CollapsibleCard, Text, Divider } from '@farmersdog/corgi-x';
import { PAW_PATH_RECIPES } from '@farmersdog/constants/paths';
import type {
  CgsActivityLevel,
  CgsBodyCondition,
} from '../../../graphql/types.cgs';
import { setPathQueries } from '../../../utils';
import type {
  MyDogsFoodRecipeFieldsFragment,
  MyDogsPlanFieldsFragment,
} from '../../network';
import type { HandleOpenRecipeDetailsModalProps } from '../../MyDogs';
import { SelectedFoodRecipes } from '../SelectedFoodRecipes';
import { DailyPortion } from '../DailyPortion';
import { PetProfile } from '../PetProfile';
import { ActionHeader } from '../ActionHeader';
import { trackRecipesEditClicked } from '../../analytics';

import styles from './PetCard.module.css';

export interface PetCardProps {
  name: string;
  breedsForDisplay: string[];
  bodyCondition: CgsBodyCondition | null;
  ageInWeeks: number;
  weightInPounds: number;
  plan: MyDogsPlanFieldsFragment;
  recipes: MyDogsFoodRecipeFieldsFragment[];
  activityLevel: CgsActivityLevel | null;
  handleOpenRecipeDetailsModal: (
    props: HandleOpenRecipeDetailsModalProps
  ) => void;
  activePetCardRef: RefObject<HTMLDivElement> | null;
  scrollMarginTop: number;
}

export function PetCard(props: PetCardProps) {
  const DEFAULT_IS_OPEN = true;

  return (
    <div
      ref={props.activePetCardRef}
      style={{ scrollMarginTop: props.scrollMarginTop }}
      data-testid={props.activePetCardRef ? 'active-pet-card' : 'pet-card'}
    >
      <CollapsibleCard heading={props.name} open={DEFAULT_IS_OPEN}>
        <PetProfile
          name={props.name}
          breedsForDisplay={props.breedsForDisplay}
          bodyCondition={props.bodyCondition}
          ageInWeeks={props.ageInWeeks}
          weightInPounds={props.weightInPounds}
          activityLevel={props.activityLevel}
        />
        <Divider color="Charcoal0" width={1} spacing={24} />
        <DailyPortion
          name={props.name}
          dailyFreshCalories={props.plan.dailyFreshCalories}
          freshFoodRatio={props.plan.freshFoodRatio}
          dailyPacksConsumed={props.plan.dailyPacksConsumed}
        />
        <Divider color="Charcoal0" width={1} spacing={24} />
        <section>
          <ActionHeader
            headerText="Recipes"
            actionText="Edit"
            actionAriaLabel={`Edit ${props.name} fresh recipes`}
            onActionClick={trackRecipesEditClicked}
            actionTo={setPathQueries(PAW_PATH_RECIPES, {
              pet: props.name,
            })}
          />
          <Text
            className={styles.instructions}
            variant="heading-16"
            color="charcoal-3"
            as="p"
          >
            Choose up to 3 recipes.
          </Text>
          <SelectedFoodRecipes
            petName={props.name}
            recipes={props.recipes}
            handleOpenRecipeDetailsModal={props.handleOpenRecipeDetailsModal}
          />
        </section>
      </CollapsibleCard>
    </div>
  );
}
