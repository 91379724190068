import type { Debugger } from 'debug';
import { debug } from 'debug';

import type { Logger as DataDogLogger } from '@datadog/browser-logs';
import { HandlerType, datadogLogs } from '@datadog/browser-logs';
import { browserOnly } from '@farmersdog/utils';
import { LogLevel, config } from '../config';

export type LogContext = Record<string | number | symbol, unknown>;

interface LoggerOptions {
  /** Enable internal Logger debug messages */
  debug?: boolean;
}

const levelPriority: LogLevel[] = [
  LogLevel.debug,
  LogLevel.info,
  LogLevel.warn,
  LogLevel.error,
];

/**
 * Use to create logger which will control if specificated log levels can be
 * written and if the logs should be written to the console or to a third party
 * logging service.
 *
 * @example
 * ```ts
 * const logger = new Logger('example:namespace', { userId: 1 });
 *
 * logger.debug('Unexpected sum', { sum: 1 + 1 });
 * logger.info('First render complete', { renderTime });
 * logger.warn('User entered address before google maps loaded', { address });
 * logger.error('Submit called before data ready');
 * ```
 */
export class Logger {
  /** The unique name to identify the module calling this logger */
  readonly namespace: string;
  /** The highest enabled log level, level below this level will not log */
  readonly logLevel: LogLevel;
  /** Data that will be attached to all logs from this logger */
  readonly context: LogContext | undefined;
  /** Options to adjust how the logger works */
  readonly options: LoggerOptions | undefined;

  /** The instance of the Datadog client logger */
  private ddLogger: DataDogLogger | undefined;
  /** The instance of the console logger */
  private consoleLogger: Debugger | undefined;
  /** Config has enabled datadog */
  private datadogEnabled: boolean | undefined;

  constructor(
    /** The unique name to identify the module calling this logger */
    namespace: string,
    /** Data that will be attached to all logs from this logger */
    context?: LogContext,
    /** Options to adjust how the logger works */
    options?: LoggerOptions
  ) {
    this.namespace = namespace;
    this.logLevel = config.level;
    this.context = context;
    this.options = options;

    config.onSet(() => this.setupLoggers());
  }

  private setupLoggers() {
    this.consoleLogger = debug(this.namespace);
    this.datadogEnabled = Boolean(config.datadog?.enabled);
    if (this.datadogEnabled) {
      browserOnly(() => {
        this.ddLogger = datadogLogs.createLogger(this.namespace, {
          handler: HandlerType.http,
          context: this.context,
        });
      });
    }
  }

  /** If logger is not defined fallback to this logger */
  private logToFallback(
    message: string,
    context: LogContext | undefined,
    status: LogLevel
  ) {
    if (!config.isSet) {
      config.onSet(() => {
        if (status === LogLevel.error) {
          this.error(message, context);
        }

        if (status === LogLevel.warn) {
          this.warn(message, context);
        }

        if (status === LogLevel.info) {
          this.info(message, context);
        }

        if (status === LogLevel.debug) {
          this.debug(message, context);
        }
      });

      if (this.options?.debug) {
        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify({
            message: 'Attempted to use a logger before calling `loggerInit`',
            status: LogLevel.debug,
            namespace: this.namespace,
            context: { ...this.context, log: { message, status, context } },
          })
        );
      }

      return;
    }

    const fullContext = { ...context, ...this.context };
    // eslint-disable-next-line no-console
    return console.log(JSON.stringify({ message, status, ...fullContext }));
  }

  /** Isomorphic Datadog log function */
  private logToDatadog(
    message: string,
    context: LogContext | undefined,
    status: LogLevel
  ) {
    /** If ddLogger is not initialized we should write to stdout */
    if (!this.ddLogger) {
      return this.logToFallback(message, context, status);
    }

    this.ddLogger.log(message, context, status);
  }

  /** Execute the console logger log function if instantiated. Formats context
   * for pretty print */
  private logToConsole(message: string, context: LogContext | undefined) {
    if (!this.consoleLogger) {
      return this.logToFallback(message, context, LogLevel.debug);
    }

    const fullContext =
      context || this.context ? { ...context, ...this.context } : undefined;

    if (fullContext) {
      return this.consoleLogger(`${message}: %o`, fullContext);
    }

    this.consoleLogger(message);
  }

  /** Determine if the provided log level should execute in this environment */
  private canLogLevel(level: LogLevel) {
    const minLogLevel = levelPriority.indexOf(this.logLevel);
    const currentLogLevel = levelPriority.indexOf(level);

    return (
      minLogLevel !== -1 &&
      currentLogLevel !== -1 &&
      minLogLevel <= currentLogLevel
    );
  }

  /** Write a debug log if debug logs are enabled */
  debug(message: string, context?: LogContext) {
    if (!this.canLogLevel(LogLevel.debug)) return;
    this.logToConsole(message, context);
  }

  /** Write a info log if info logs are enabled */
  info(message: string, context?: LogContext) {
    if (!this.canLogLevel(LogLevel.info)) return;
    if (this.datadogEnabled) {
      return this.logToDatadog(message, context, LogLevel.info);
    }
    this.logToConsole(message, context);
  }

  /** Write a warn log if warn logs are enabled */
  warn(message: string, context?: LogContext) {
    if (!this.canLogLevel(LogLevel.warn)) return;
    if (this.datadogEnabled) {
      return this.logToDatadog(message, context, LogLevel.warn);
    }
    this.logToConsole(message, context);
  }

  /** Write an error log if error logs are enabled */
  error(message: string, context?: LogContext) {
    if (!this.canLogLevel(LogLevel.error)) return;
    if (this.datadogEnabled) {
      return this.logToDatadog(message, context, LogLevel.error);
    }
    this.logToConsole(message, context);
  }
}
