import { NodeNames } from '@farmersdog/constants';

import { getNodeNameAndPosition } from '../../../blueprint/utils';
import { useSignupUpdatedAssets } from '../../../hooks';
import { useLabel } from '../../../hooks/useLabel';
import { toggleFragment } from '../../../schema/actions/toggleFragment';
import { RadioSelectorGroup } from '../base';

import { bodyConditionAssets, bodyConditionNewAssets } from './assets';
import styles from './BodyConditionInput.module.css';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';
import type { ChangeEvent } from 'react';

export function BodyConditionInput(props: TOSAComponentInput<TOSALeafNode>) {
  const {
    node,
    schema,
    setFormSteps,
    formMethods,
    formValidationSchema,
    registry,
    useFeature,
  } = props;
  const { getValues, register } = formMethods;
  const { position } = getNodeNameAndPosition(node.name);

  const registerProps = register(node.name);
  const bodyConditionField = `${NodeNames.BodyCondition}-${position}` as const;
  const currentValue = getValues(bodyConditionField) ?? null;
  const { frontLabel } = useLabel({ node, getValues });

  const handleComplete = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value;
    void registerProps.onChange(e);

    toggleFragment({
      value,
      node,
      schema,
      setFormSteps,
      formValidationSchema,
      unregister: formMethods.unregister,
      registry,
    });
  };

  const options =
    node.input?.options?.map(({ name, value, description }) => ({
      label: name,
      value,
      description,
    })) ?? [];

  const { isSignupUpdatedAssetsOn } = useSignupUpdatedAssets({
    useFeature,
  });

  return (
    <>
      <span>{frontLabel}</span>
      <div className={styles.wrapper}>
        <RadioSelectorGroup
          registerProps={registerProps}
          options={options}
          icons={
            isSignupUpdatedAssetsOn
              ? bodyConditionNewAssets
              : bodyConditionAssets
          }
          legend="Body Condition"
          currentValue={currentValue}
          onChange={handleComplete}
          defaultDescription="Select the body type closest to your dog's."
        />
      </div>
    </>
  );
}
