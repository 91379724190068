import type { RouteData } from '../useSignupRouter';

export enum SignupStep {
  me = 'me',
  pets = 'pets',
  recipes = 'recipes',
  plans = 'plans',
  checkout = 'checkout',
  unknown = 'unknown',
}

export const getCurrentStepFromRoute = (
  currentRoute: RouteData
): SignupStep => {
  if (currentRoute?.me) {
    return SignupStep.me;
  } else if (currentRoute?.pets) {
    return SignupStep.pets;
  } else if (currentRoute?.recipes) {
    return SignupStep.recipes;
  } else if (currentRoute?.plans) {
    return SignupStep.plans;
  } else if (currentRoute?.checkout) {
    return SignupStep.checkout;
  } else {
    return SignupStep.unknown;
  }
};
