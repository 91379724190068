import { useQuery } from '@apollo/client';

import type {
  PaymentMethodChangedForOrderQuery,
  PaymentMethodChangedForOrderQueryVariables,
} from './PaymentMethodChangedForOrder.generated-types';
import paymentMethodChangedForOrderQuery from './PaymentMethodChangedForOrder.graphql';

export function usePaymentMethodChangedForOrder({
  orderId,
}: {
  orderId?: number;
}) {
  const { data, error, loading } = useQuery<
    PaymentMethodChangedForOrderQuery,
    PaymentMethodChangedForOrderQueryVariables
  >(paymentMethodChangedForOrderQuery, {
    context: {
      // TODO: import CGS endpoint enum; currently not available in PAW and
      // linter complains
      endpoint: 'cgs',
    },
    variables: orderId ? { orderId } : undefined,
    skip: !orderId,
  });

  return {
    paymentMethodChangedForOrder: data?.customer.paymentMethodChangedForOrder,
    loading,
    error,
  };
}
