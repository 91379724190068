import type { Destination } from '../config';
import { Path } from '../config';
import qs from 'qs';

interface BuildUtmsArgs {
  path: Path;
  destination: Destination;
}

interface DynamicUtms {
  utm_source: 'ooh' | 'direct_mail' | 'field_marketing';
  utm_medium: 'billboards' | 'postcards' | 'street_teams';
  utm_campaign:
    | 'citytakeovers_minneapolisjune22'
    | 'citytakeovers_seattlejune22';
}

type PathToUtms = Record<Path, DynamicUtms>;

interface Utms extends DynamicUtms {
  utm_content: Destination;
}

const pathToUtms: PathToUtms = {
  [Path.SeattleBillboard]: {
    utm_source: 'ooh',
    utm_medium: 'billboards',
    utm_campaign: 'citytakeovers_seattlejune22',
  },
  [Path.SeattleDirectMail]: {
    utm_source: 'direct_mail',
    utm_medium: 'postcards',
    utm_campaign: 'citytakeovers_seattlejune22',
  },
  [Path.SeattleStreetTeam]: {
    utm_source: 'field_marketing',
    utm_medium: 'street_teams',
    utm_campaign: 'citytakeovers_seattlejune22',
  },
  [Path.MinneapolisBillboard]: {
    utm_source: 'ooh',
    utm_medium: 'billboards',
    utm_campaign: 'citytakeovers_minneapolisjune22',
  },
  [Path.MinneapolisDirectMail]: {
    utm_source: 'direct_mail',
    utm_medium: 'postcards',
    utm_campaign: 'citytakeovers_minneapolisjune22',
  },
  [Path.MinneapolisStreetTeam]: {
    utm_source: 'field_marketing',
    utm_medium: 'street_teams',
    utm_campaign: 'citytakeovers_minneapolisjune22',
  },
};

export function buildUtms({ path, destination }: BuildUtmsArgs) {
  const utms: Utms = {
    ...pathToUtms[path],
    utm_content: destination,
  };

  return qs.stringify(utms);
}
