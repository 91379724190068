import type { SearchableSelectProps } from '@farmersdog/corgi-x';
import {
  SearchableSelect,
  StyledOption,
  Button,
  Text,
} from '@farmersdog/corgi-x';
import type { AddDogFormChangeHandler, BreedOptions } from '../../../hooks';

import type { FetchAddDogFormDataQuery } from '../../../network/fetchAddDogFormData.cgs.generated-types';
import { useEffect, useState } from 'react';

interface BreedInputProps {
  name: string;
  breedFormOptions: FetchAddDogFormDataQuery['petCharacteristics']['breeds'];
  value: BreedOptions[];
  onChange: AddDogFormChangeHandler;
}

export function findUnknownBreed(
  items: FetchAddDogFormDataQuery['petCharacteristics']['breeds']
): BreedOptions {
  const unknownBreed = items.find(
    item => item !== null && item.name !== null && /^unknown/i.test(item.name)
  );
  if (!unknownBreed) {
    throw new Error('Cannot find unknown breed');
  }

  return unknownBreed.name;
}

export const BreedInput = (props: BreedInputProps) => {
  const { breedFormOptions, value, onChange, name } = props;

  const [showUnknownMixBreedCopy, setShowUnknownMixBreedCopy] = useState(true);

  const handleBreedChange: SearchableSelectProps['onChange'] = e => {
    if (!e || typeof e.value === 'undefined') return;

    const selectedBreed = e.value as string;

    if (e.checked) {
      if (value.includes(selectedBreed)) return;
      onChange('breed', [...value, selectedBreed]);
    } else {
      if (!value.includes(selectedBreed)) return;
      onChange(
        'breed',
        value.filter(breedName => breedName !== selectedBreed)
      );
    }
  };

  const handleUnknownMixBreedClick = () => {
    const unknownBreed = findUnknownBreed(breedFormOptions);

    onChange('breed', [unknownBreed]);
    setShowUnknownMixBreedCopy(false);
  };

  useEffect(() => {
    const isUnknownMixBreedSelected = value.some(selectedBreed =>
      /^unknown/i.test(selectedBreed)
    );

    if (isUnknownMixBreedSelected) {
      setShowUnknownMixBreedCopy(false);
    } else {
      setShowUnknownMixBreedCopy(true);
    }
  }, [value]);

  return (
    <>
      <SearchableSelect
        max={5}
        aria-required
        multi
        label="Breed"
        value={value}
        onChange={handleBreedChange}
      >
        {breedFormOptions.map(breed => (
          <StyledOption value={breed?.name} key={breed?.id}>
            {breed?.name}
          </StyledOption>
        ))}
      </SearchableSelect>

      {showUnknownMixBreedCopy && (
        <Text
          data-testid="unknown-mixed-breed-copy"
          as="p"
          variant="article-16"
          fontStyle="italic"
          topSpacing="sm"
        >
          {`If ${name} is a mix, you can select multiple breeds, or`}{' '}
          <Button variant="plain-text" onClick={handleUnknownMixBreedClick}>
            <Text
              data-testid="unknown-mix-breed-button"
              as="span"
              variant="article-16"
              fontStyle="italic"
            >
              unknown mix-breed
            </Text>
          </Button>
          .
        </Text>
      )}
    </>
  );
};
