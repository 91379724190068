import { useScoredCandidates } from './hooks/useScoredCandidates';
import type { SlotProps, SlottableComponent } from './types';

export function Slot<P extends NonNullable<unknown>>({
  slotId,
  candidates,
  props = {} as P,
}: SlotProps<P>) {
  const RenderedCandidate: SlottableComponent<P> | undefined =
    useScoredCandidates(slotId, candidates);

  if (!RenderedCandidate) return;

  return <RenderedCandidate {...props} />;
}
