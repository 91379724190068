import type * as Apollo from '@apollo/client';

import { useCountries } from './useCountries';
import { DEFAULT_ISO_CODE } from './constants';

/**
 * Return a country that is associated with an iso code
 *
 * @param isoCode - A valid ISO-3166 alpha-2 code
 */
export function useCountryByIsoCode(
  isoCode: string = DEFAULT_ISO_CODE,
  options?: Apollo.QueryOptions
) {
  const query = useCountries(options);

  if (!query.data) {
    return undefined;
  }

  return query.data.countries.find(country => country.iso === isoCode);
}
