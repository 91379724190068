import type { ChangeEvent } from 'react';
import {
  Button,
  Grid,
  GridItem,
  Page,
  Text,
  TextArea,
} from '@farmersdog/corgi-x';

import styles from './FreeResponse.module.css';
import classNames from 'classnames';

/**
 * Limit due to Segment string limit of 512.
 * @see https://segment.com/docs/connections/storage/warehouses/schema/#column-sizing
 */
const DEFAULT_CHAR_LIMIT = 500;

export const DESCRIPTION_TESTID = 'description-text';

export interface FreeResponseProps {
  charLimit?: number;
  onNext: () => void;
  onPrevious: () => void;
  onClose: () => void;
  freeResponse: string | undefined;
  setFreeResponse: (response: string) => void;
  showSpecifyRecipeText: boolean;
}

/**
 * Renders page with text area intended for users to share additional feedback
 * on why they are cancelling.
 */
export function FreeResponse({
  charLimit = DEFAULT_CHAR_LIMIT,
  onNext,
  onPrevious,
  onClose,
  freeResponse = '',
  setFreeResponse,
  showSpecifyRecipeText = false,
}: FreeResponseProps) {
  function onChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setFreeResponse(e.target.value);
  }

  const isInvalid = freeResponse.length > charLimit;

  return (
    <Page
      className={styles.page}
      layout="base"
      onBackClick={onPrevious}
      onCloseClick={onClose}
    >
      <Text variant="heading-40" bold as="h1" color="kale-3">
        Cancel My Subscription
      </Text>
      {showSpecifyRecipeText ? (
        <Text
          as="p"
          variant="article-20"
          vSpacing="sm"
          data-testid={DESCRIPTION_TESTID}
        >
          Please share any additional feedback — we’d love to work on it! Let us
          know the <strong>specific recipe(s)</strong> you have concerns with,
          if applicable.
        </Text>
      ) : (
        <Text
          as="p"
          variant="article-20"
          vSpacing="sm"
          data-testid={DESCRIPTION_TESTID}
        >
          Please share any additional feedback — we’d love to work on it!
        </Text>
      )}
      <TextArea
        className={styles.textArea}
        label="Message"
        invalid={isInvalid}
        value={freeResponse}
        onChange={onChange}
      />
      <Text
        className={classNames({
          [styles.charLimitError]: isInvalid,
        })}
        variant="heading-12"
        color="charcoal-1"
      >
        {freeResponse.length} of {charLimit} max characters
      </Text>
      <Grid topSpacing="xl">
        <GridItem md={3} />
        <GridItem growContent md={6} justifyContent="center">
          <Button type="button" onClick={onNext} disabled={isInvalid}>
            Continue
          </Button>
        </GridItem>
        <GridItem md={3} />
      </Grid>
    </Page>
  );
}
