import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';

import { NodeNames } from '@farmersdog/constants';

import { updatePetCount } from '../../../schema/actions/updatePetCount';
import {
  ActivityLevel,
  BodyCondition,
  EatingStyle,
  TreatsQuantity,
} from '../../../validation/constants';

import type { CompiledBlueprint, NodeRegistry } from '../../../blueprint/types';
import type { UseSchemaReturn, Experiments, UseForm } from '../../../types';

type FormEntries = Record<
  string,
  string | number | string[] | undefined | boolean
>;

const petNames = shuffle([
  'Micki',
  'Buster',
  'Jelly',
  'Sadie',
  'Ruby',
  'Cassie',
  'Buddy',
  'Jada',
  'Yadi',
  'Bubbles',
  'Darla',
]);
const firstNames = [
  'Mario',
  'Luigi',
  'Peach',
  'Daisy',
  'Yoshi',
  'Larry',
  'Bob',
  'Steve',
  'Martin',
];
const firstName = sample(firstNames);
const breedNames = [
  'Shiba Inu',
  'Golden Retriever',
  'Miniature Poodle',
  'Pembroke Welsh Corgi',
  'Mini Aussie',
  'Unknown mix-breed',
];
function generateRandom(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export interface AutofillSignupArgs {
  formMethods: UseForm;
  schema: CompiledBlueprint;
  registry: NodeRegistry;
  setFormSteps: UseSchemaReturn['setFormSteps'];
  experiments: Experiments;
  numPets: number;
}

export async function autofillSignup({
  formMethods,
  experiments,
  registry,
  schema,
  setFormSteps,
  numPets,
}: AutofillSignupArgs) {
  const numPetsNode = NodeNames.NumPets;

  formMethods.setValue(numPetsNode, numPets, { shouldValidate: true });
  const valid = await formMethods.trigger(numPetsNode);
  if (valid) {
    updatePetCount({
      value: numPets,
      schema,
      setFormSteps,
      nodeName: numPetsNode,
      registry,
      setValue: formMethods.setValue,
      unregister: formMethods.unregister,
      experiments,
    });
  }

  const meCardEntries = {
    [`${NodeNames.Email}`]: `test${Date.now()}@gmail.com`,
    [`${NodeNames.NumPets}`]: numPets,
    [`${NodeNames.FirstName}`]: firstName,
    [`${NodeNames.FreshFoodConfidence}`]: 'already believe',
    [`${NodeNames.Zip}`]: '11211',
  };
  const petCardEntries: FormEntries = {};
  for (let i = 1; i <= numPets; i++) {
    petCardEntries[`${NodeNames.Name}-${i}`] = petNames[i - 1];
    petCardEntries[`${NodeNames.ActivityLevel}-${i}`] = sample(
      Object.values(ActivityLevel)
    );
    petCardEntries[`${NodeNames.Gender}-${i}`] = sample(['female', 'male']);
    petCardEntries[`${NodeNames.Neutered}-${i}`] = sample([true, false]);
    petCardEntries[`${NodeNames.Nature}-${i}`] = 'cutest';
    petCardEntries[`${NodeNames.BodyCondition}-${i}`] = BodyCondition.JustRight;
    petCardEntries[`${NodeNames.BirthdayAmount}-${i}`] = generateRandom(1, 12);
    petCardEntries[`${NodeNames.BirthdayUnit}-${i}`] = 'years';
    petCardEntries[`${NodeNames.Weight}-${i}`] = generateRandom(20, 50);
    petCardEntries[`${NodeNames.EatingStyle}-${i}`] = sample(
      Object.values(EatingStyle)
    );
    petCardEntries[`${NodeNames.FoodType}-${i}`] = 'dry food';
    petCardEntries[`${NodeNames.FoodBrand}-${i}`] = 'garbotron';
    petCardEntries[`${NodeNames.TreatsQuantity}-${i}`] = sample(
      Object.values(TreatsQuantity)
    );
    petCardEntries[`${NodeNames.Breeds}-${i}`] = [String(sample(breedNames))];
    petCardEntries[`${NodeNames.Healthy}-${i}`] = false;
    petCardEntries[`${NodeNames.PrescriptionDiet}-${i}`] = false;
  }
  const multiDogFormEntries = {
    ...meCardEntries,
    ...petCardEntries,
  };

  formMethods.reset(multiDogFormEntries, {
    keepValues: false,
    keepDirty: true,
  });
}
