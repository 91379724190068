import { useState } from 'react';
import type { CgsUpdateCustomerWithSuspendedSubscriptionInput } from '../../graphql/types.cgs';
import type { EditAccountFormValues } from '../features/EditAccount/';
import type { TokenData } from './createStripeToken';
import { useCreateStripeToken } from './createStripeToken';
import { useUpdateCustomerWithSuspendedSubscription } from './updateCustomerWithSuspendedSubscription/useUpdateCustomerWithSuspendedSubscription';
import type { AddressFragment } from './validateShippingAddress';
import { useValidateShippingAddress } from './validateShippingAddress';

export interface SubmitOptions {
  values: EditAccountFormValues;
  hasEditedBilling: boolean;
  hasVerifiedShippingAddress?: boolean;
}

export interface SuggestedAddressFlowValues {
  submittedAddress: AddressFragment;
  suggestedAddress: AddressFragment;
  editAccountFormValues: EditAccountFormValues;
  hasEditedBilling: boolean;
}

interface UseEditAccountSubmitProps {
  onSuggestedAddressReceived: (
    suggestedAddressFlowValues: SuggestedAddressFlowValues
  ) => void;
  onCompleted: () => void;
  onError: (e: unknown) => void;
}
/**
 * Returns submit function that calls mutation and runs validations
 */
export function useEditAccountSubmit(props: UseEditAccountSubmitProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createStripeToken = useCreateStripeToken();
  const validateShippingAddress = useValidateShippingAddress();
  const updateCustomer = useUpdateCustomerWithSuspendedSubscription({
    onCompleted: props.onCompleted,
    onError: props.onError,
  });

  function reset() {
    setIsSubmitting(false);
  }

  async function submit({
    values,
    hasEditedBilling,
    hasVerifiedShippingAddress = false,
  }: SubmitOptions) {
    setIsSubmitting(true);
    try {
      // TODO: Skip if customer has not edited shipping address
      if (!hasVerifiedShippingAddress) {
        /*
        If a suggested address is returned from validation, we will return from
        submit and display the SuggestedAddressModal. The customer will still be
        in a loading state (beneath the modal), which will be reset if they
        cancel/close the modal. In this case, they will go through shipping
        address validation again the next time they click "Submit".

        If a suggested address is NOT returned from validation, submit will
        continue. 
      */
        const addresses = await validateShippingAddress.submit(
          values.shippingAddress
        );
        if (addresses.suggestedAddress !== null) {
          return props.onSuggestedAddressReceived({
            submittedAddress: addresses.submittedAddress,
            suggestedAddress: addresses.suggestedAddress,
            editAccountFormValues: values,
            hasEditedBilling,
          });
        }
      }

      let billingInformation: CgsUpdateCustomerWithSuspendedSubscriptionInput['billingInformation'];

      if (hasEditedBilling) {
        const tokenData = getTokenDataFromValues(values);
        const stripeToken = await createStripeToken(tokenData);
        const billingAddress = values.billingAddress;
        billingInformation = {
          billingAddress,
          stripeToken,
        };
      }

      // TODO: Only submit updated values
      const updateInput: CgsUpdateCustomerWithSuspendedSubscriptionInput = {
        firstName: values.accountInformation.firstName,
        lastName: values.accountInformation.lastName,
        email: values.accountInformation.email,
        phone: values.accountInformation.phone,
        shippingAddress: values.shippingAddress,
        billingInformation,
      };
      await updateCustomer.submit(updateInput);
      reset();
    } catch (error) {
      props.onError(error);
      reset();
    }
  }

  return {
    submit,
    reset,
    isSubmitting,
  };
}

function getTokenDataFromValues(values: EditAccountFormValues): TokenData {
  const address = values.useShippingAsBillingAddress
    ? values.shippingAddress
    : values.billingAddress;
  return {
    name: values.cardholderName,
    address_line1: address.addressLine1,
    address_line2: address.addressLine2 ?? '',
    address_city: address.city,
    address_state: address.stateAbbreviation,
    address_zip: address.zip,
    address_country: 'US',
  };
}
