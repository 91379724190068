import { getImpactRadiusSegmentContext } from 'src/utils/referrer';

import getPreTaxTotal from '../getPreTaxTotal';
import events from '../eventNames';
import track from '../track';
import type { Referrer } from 'src/hooks/useReferrer';

/**
 * A segment tracking event that is used to mark the start of a trial. This
 * event is used solely to pass data to impact radius for conversion tracking.
 */

interface TrackImpactRadiusTrialConversionArgs {
  subtotalAmount: number;
  referrer?: Referrer | Record<string, unknown>;
}

export function trackImpactRadiusTrialConversion({
  subtotalAmount,
  referrer,
}: TrackImpactRadiusTrialConversionArgs) {
  const value = getPreTaxTotal(subtotalAmount);
  const impactIDData =
    referrer && referrer.type === 'user' ? {} : getImpactRadiusSegmentContext();

  /**
   * TODO: Rename this event
   * https://app.clubhouse.io/farmersdog/story/28926/chore-rename-starttrial-event-to-impact-radius-trial-conversion
   *
   * This event name is very unclear as it mirrors our `checkout_success` event
   * and is solely used for impact radius attribution. We will change the name
   * in a future story.
   */
  track(
    events.starttrial,
    {
      currency: 'USD',
      value,
    },
    impactIDData
  );
}
