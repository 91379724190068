import type { Orientation } from 'src/screen/types';

/**
 * Definitions for our orientations.
 */
const ORIENTATIONS: Orientation[] = [
  {
    name: 'portrait',
    cssQueryString: 'portrait',
    minAspectRatio: 0,
  },
  {
    name: 'landscape',
    cssQueryString: 'landscape',
    minAspectRatio: 1,
  },
];

export default ORIENTATIONS;
