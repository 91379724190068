import { Text } from '@farmersdog/corgi-x';

import { Details } from '../../../shared/Details';
import { Container } from '../Container';

import { questions } from './data';
import styles from './FAQ.module.css';

export const FAQ = () => {
  return (
    <section className={styles.section}>
      <Container withMobilePadding>
        <div className={styles.container}>
          <Text
            bold
            as="h2"
            color="white"
            bottomSpacing="lg"
            align={{
              xs: 'center',
              lg: 'left',
            }}
            variant="heading-40"
          >
            Frequently <br className={styles.mobileBreakline} />
            Asked Questions
          </Text>
          {questions.map(({ question, answer }) => (
            <Details key={question} summary={question}>
              {answer}
            </Details>
          ))}
        </div>
      </Container>
    </section>
  );
};
