import {
  PATH_CUSTOMER_ACCOUNT,
  PAW_PATH_ACCOUNT,
} from '@farmersdog/constants/paths';
import { PrismicProvider } from '@prismicio/react';
import { Switch, Route } from 'react-router-dom';
import { AccountRoutes } from './AccountRoutes';
import type { AppProps } from '../app/App.types';
import { ErrorBoundary, ErrorPage } from '../errors';
import { Loading } from '../components';
import {
  useCustomerAccountQuery,
  useIdentifyCustomer,
  useHideGladlySidekickForDIY,
} from './network';
import { usePrismicClient } from '../content';

export interface AuthenticatedRoutesProps {
  CustomerAccountContent: AppProps['CustomerAccountContent'];
  dispatchErrorModal?: AppProps['dispatchErrorModal'];
}

export function AuthenticatedRoutes(props: AuthenticatedRoutesProps) {
  const prismicClient = usePrismicClient();

  const customerRequest = useCustomerAccountQuery();

  useIdentifyCustomer(customerRequest.data);
  useHideGladlySidekickForDIY(customerRequest.data);

  if (customerRequest.loading) {
    return <Loading />;
  }

  if (customerRequest.error) {
    return <ErrorPage error={customerRequest.error} />;
  }

  if (!customerRequest.data) {
    return (
      <ErrorPage
        error={new Error('Unknown exception occurred while loading data')}
      />
    );
  }

  return (
    <ErrorBoundary>
      <PrismicProvider client={prismicClient}>
        <Switch>
          <Route path={PAW_PATH_ACCOUNT}>
            <AccountRoutes
              dispatchErrorModal={props.dispatchErrorModal}
              customerAccountData={customerRequest.data}
            />
          </Route>
          {props.CustomerAccountContent && (
            <Route path={PATH_CUSTOMER_ACCOUNT}>
              {<props.CustomerAccountContent />}
            </Route>
          )}
        </Switch>
      </PrismicProvider>
    </ErrorBoundary>
  );
}
