import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../../analytics';
import { determineSkipGetStarted } from '../experiments';
import { FeatureName } from '../features';

import type { BranchNode } from '../../blueprint/types';
import type { LeadView } from '../../exports';
import type { Progress } from '../../hooks/useProgress';
import type {
  Experiments,
  UseFormNavigateReturn,
  UseUserExperimentsHookReturn,
} from '../../types';

interface RedirectIfNodeRemovedArgs {
  current: string | undefined;
  formSteps: BranchNode[];
  formNavigation: UseFormNavigateReturn;
  getNextIncompleteBranch: Progress['getNextIncompleteBranch'];
  anonymousExperiments: Experiments;
  userExperiments: UseUserExperimentsHookReturn | undefined;
  lead: LeadView | null;
}

export function redirectIfNodeRemoved({
  current,
  formSteps,
  formNavigation,
  getNextIncompleteBranch,
  anonymousExperiments,
  userExperiments,
  lead,
}: RedirectIfNodeRemovedArgs) {
  if (current && formSteps.length > 0) {
    const currentIndex = formNavigation.findCurrentIndex({ formSteps });
    if (currentIndex === -1) {
      const shouldNotShowGetStartedPage = determineSkipGetStarted({
        experiments: anonymousExperiments,
      }).shouldSkipGetStarted;

      const shouldNotShowTreatsPage =
        userExperiments &&
        userExperiments[FeatureName.CVR_TREATS_IN_CHECKOUT_UI]
          .shouldRemoveTreatsPage;

      if (shouldNotShowGetStartedPage || shouldNotShowTreatsPage) {
        const nextIncompleteBranch = getNextIncompleteBranch();
        formNavigation.goToStep(nextIncompleteBranch.id, {
          routingAction: 'replace',
        });
      } else {
        track(segmentTrackEvents.redirect_if_node_removed, {
          anonymousId: lead?.anonymousId,
          corePostgresUserId: lead?.corePostgresUserId,
          email: lead?.email,
        });
      }
    }
  }
}
