import { Logger } from '@farmersdog/logger';

import {
  desktopLovedByHumans,
  mobileLovedByHumans,
} from '../../pages/Home/components/AssetPreloader/assets/loved-by-humans';
import {
  desktopLifestyleBowl,
  mobileLifestyleBowl,
} from '../../pages/Home/components/AssetPreloader/assets/lifestyle-bowl';
import {
  useSSRAnonymousExperiments,
  getHasSSRAnonymousExperiment,
} from '../../server/context/experimentsContext';
import type { MobileAndDesktopSources } from 'src/pages/Home/components/AssetPreloader/assets/shared';
import {
  desktopMeatAndVeggies,
  mobileMeatAndVeggies,
} from '../../pages/Home/components/AssetPreloader/assets/meat-and-veggies';
import {
  desktopBlackFriday,
  mobileBlackFriday,
} from '../../pages/Home/components/AssetPreloader/assets/black-friday';

import { HomepageHeroImageTreatments } from 'src/abTesting';
import { CVR_HOMEPAGE_HERO_IMAGE } from 'src/abTesting/features';

const assets: Record<string, MobileAndDesktopSources> = {
  [HomepageHeroImageTreatments.LovedByHumans]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.LifestyleBowl]: {
    mobileSources: mobileLifestyleBowl,
    desktopSources: desktopLifestyleBowl,
  },
  [HomepageHeroImageTreatments.MeatAndVeggies]: {
    mobileSources: mobileMeatAndVeggies,
    desktopSources: desktopMeatAndVeggies,
  },
  [HomepageHeroImageTreatments.BlackFriday]: {
    mobileSources: mobileBlackFriday,
    desktopSources: desktopBlackFriday,
  },
  [HomepageHeroImageTreatments.CyberWeek]: {
    mobileSources: mobileBlackFriday,
    desktopSources: desktopBlackFriday,
  },
  [HomepageHeroImageTreatments.CtaOnly50Off]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
  [HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing]: {
    mobileSources: mobileLovedByHumans,
    desktopSources: desktopLovedByHumans,
  },
};

const CURRENT_TREATMENTS = [
  HomepageHeroImageTreatments.LifestyleBowl,
  HomepageHeroImageTreatments.MeatAndVeggies,
  HomepageHeroImageTreatments.LovedByHumans,
  HomepageHeroImageTreatments.BlackFriday,
  HomepageHeroImageTreatments.CyberWeek,
  HomepageHeroImageTreatments.CtaOnly50Off,
  HomepageHeroImageTreatments.CtaOnlySeePlansAndPricing,
];

type UseHomepageHeroExperimentReturn = MobileAndDesktopSources & {
  treatment: HomepageHeroImageTreatments;
};

const DEFAULT_TREATMENT = HomepageHeroImageTreatments.LovedByHumans;

export function useHomepageHeroExperiment(): UseHomepageHeroExperimentReturn {
  const experiments = useSSRAnonymousExperiments();

  const hasHomepageHeroExperiment = getHasSSRAnonymousExperiment(
    experiments,
    CVR_HOMEPAGE_HERO_IMAGE
  );

  if (!hasHomepageHeroExperiment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error(
      'No SSR Anonymous Experiments found in useHomepageHeroExperiment',
      {
        experiments,
      }
    );

    return {
      ...assets[DEFAULT_TREATMENT],
      treatment: HomepageHeroImageTreatments.Control,
    };
  }

  const homepageHeroExperiment = experiments.cvr_homepage_hero_image;
  if (!homepageHeroExperiment?.treatment) {
    const log = new Logger('website:useHomepageHeroExperiment');
    log.error('No treatment found for homepage hero experiment', {
      experiment: homepageHeroExperiment,
    });

    return {
      ...assets[DEFAULT_TREATMENT],
      treatment: HomepageHeroImageTreatments.Control,
    };
  }

  const treatment = homepageHeroExperiment.treatment;

  if (CURRENT_TREATMENTS.includes(treatment)) {
    return {
      ...assets[treatment],
      treatment,
    };
  }

  // Return the default assets if we don't actively recognize the treatment
  return {
    ...assets[DEFAULT_TREATMENT],
    treatment,
  };
}
