import classNames from 'classnames';

import { Modal } from '@farmersdog/corgi-x';

import { SnapSticksRecipeName } from '../../graphql/utils';
import { treatNutritionFactsByName } from '../../types';
import { NutritionFacts } from '../NutritionFacts/NutritionFacts';

import styles from './TreatsNutritionFactsModal.module.css';

import type { TreatsInCheckoutTreatName } from '../../types';

interface TreatsNutritionFactsModalProps {
  treat: TreatsInCheckoutTreatName;
  handleOnClose: () => void;
}

export const TreatsNutritionFactsModal = ({
  treat,
  handleOnClose,
}: TreatsNutritionFactsModalProps) => {
  if (!treat) return;

  const modalClassName = classNames(styles.nutritionFactsModal, {
    [styles.chicken]: treat === SnapSticksRecipeName.ChickenV2,
    [styles.turkey]: treat === SnapSticksRecipeName.TurkeyV2,
  });

  return (
    <Modal
      isOpen
      onClose={handleOnClose}
      closeOnBackdropClick
      withCloseButton
      contentClassName={modalClassName}
    >
      <NutritionFacts {...treatNutritionFactsByName[treat]} />
    </Modal>
  );
};
