import { useLazyQuery } from '../../services/apollo';

import leadQuery from './lead.graphql';

import type { FetchLeadQuery, FetchLeadQueryVariables } from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.QueryHookOptions<FetchLeadQuery, FetchLeadQueryVariables>;

export function useLead(options?: Options) {
  return useLazyQuery<FetchLeadQuery, FetchLeadQueryVariables>(
    leadQuery,
    options
  );
}
