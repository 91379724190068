import { useState, useRef, useEffect } from 'react';

import { ResponsiveText } from '@farmersdog/corgi';
import { LG } from '@farmersdog/corgi/constants';
import { ContentReveal, PageWidth, Text } from '@farmersdog/corgi-x';

import { diggingDogs } from './assets';
import styles from './DiggingDog.module.css';

import type { CalculatingScreenContentProps } from './CalculatingScreen';

const GIF_LENGTH = 4530;
const GIF_DELAY = 300;
const GIF_LOOPS = 1;
const GIF_LOOP_TIME = GIF_LENGTH - GIF_DELAY;
const ANIMATE_OUT_TIME = 300;

export function DiggingDog({ onCompleted }: CalculatingScreenContentProps) {
  const [showContent, setShowContent] = useState(true);
  const onCompletedRef = useRef(onCompleted);

  const [diggingDogSm, diggingDogMd] = diggingDogs;
  const srcSet = `${diggingDogSm} 315w, ${diggingDogMd} 630w`;
  const sizes = `(min-width: ${LG}) 630px, 315px`;

  useEffect(() => {
    onCompletedRef.current = onCompleted;
  }, [onCompleted, onCompletedRef]);

  useEffect(() => {
    setShowContent(true);
    const showTime = GIF_LOOPS * GIF_LOOP_TIME;
    const contentDisplayTime = showTime - ANIMATE_OUT_TIME;

    const contentTimeoutId = setTimeout(() => {
      setShowContent(false);
    }, contentDisplayTime);

    const timeoutId = setTimeout(() => {
      onCompletedRef.current();
    }, showTime);

    return () => {
      clearTimeout(contentTimeoutId);
      clearTimeout(timeoutId);
    };
  }, [onCompletedRef]);

  return (
    <div data-name="DiggingDog" className={styles.background}>
      <PageWidth className={styles.content}>
        <ContentReveal
          in={showContent}
          inAnimation="springUp"
          inDelay={GIF_DELAY * 0.33}
          outAnimation="slideDown"
        >
          <ResponsiveText
            variant="heading-2"
            color="kale-3"
            className={styles.headline}
          >
            Calculating…
          </ResponsiveText>
        </ContentReveal>
        <ContentReveal
          in={showContent}
          inAnimation="springUp"
          inDelay={GIF_DELAY * 0.66}
          outAnimation="slideDown"
        >
          <Text
            variant="heading-22"
            color="kale-3"
            className={styles.underline}
          >
            One moment while we retrieve the ideal meal plan for your dog!
          </Text>
        </ContentReveal>
        <ContentReveal
          in={showContent}
          inAnimation="springUp"
          inDelay={GIF_DELAY}
          outAnimation="slideDown"
        >
          <div className={styles.animationContainer}>
            <picture className={styles.animation}>
              <img
                srcSet={srcSet}
                sizes={sizes}
                src={diggingDogSm}
                alt="Dog digging"
              />
            </picture>
          </div>
        </ContentReveal>
      </PageWidth>
    </div>
  );
}
