import { paths } from '@farmersdog/constants';
import { useCoupon, DiscountType } from '@farmersdog/coupons';

import { trackPetsSuccess } from '../analytics';
import config from '../config';
import { isContinueToNextPet } from '../utils';
import { FeatureName } from '../utils/features';

import { useLeadState } from './useLeadState';
import { useRequestAttributesContext } from './useRequestAttributes';

import type { BranchNode } from '../blueprint/types';
import type {
  LeadView,
  FullLeadRegisterResponseFragment,
  FetchQuoteQuery,
} from '../graphql/types';
import type { Experiments } from '../types';

export interface OnTosaCompletedArgs {
  registeredUserResponse: FullLeadRegisterResponseFragment['user'];
}

export interface OnTosaCompleted {
  (args: OnTosaCompletedArgs): void;
}

export interface OnMeStepCompletedData {
  lead: LeadView | null;
  couponData: Pick<
    ReturnType<typeof useCoupon>,
    'couponCode' | 'discountAmount' | 'discountType' | 'referrerType'
  >;
  requireCorePostgresId?: boolean;
  discountOverride: number | null;
}

export interface OnStepCompleted {
  (step: string, lead: LeadView | null): void;
}

export interface OnMeStepCompleted {
  (step: string, data: OnMeStepCompletedData): void;
}

export interface OnPetStepCompleted {
  (): void;
}

export interface OnRecipesCompletedArgs {
  isLastPet: boolean;
  lead: LeadView | null;
  quote: FetchQuoteQuery['fetchQuote'] | null;
}

export interface OnRecipesCompleted {
  (args: OnRecipesCompletedArgs): void;
}

export interface OnFormCompleted {
  (step: string): void;
}

export interface UseHandleStepCompletedArgs {
  formSteps: BranchNode[];
  experiments: Experiments;
  onMeCompleted?: OnMeStepCompleted;
  onPetsCompleted?: OnPetStepCompleted;
  onTosaCompleted?: OnTosaCompleted;
  onStepCompleted?: OnStepCompleted;
}

export function useHandleFormCompleted({
  formSteps,
  experiments,
  onMeCompleted,
  onPetsCompleted,
  onTosaCompleted,
  onStepCompleted,
}: UseHandleStepCompletedArgs): OnFormCompleted {
  const requestAttributes = useRequestAttributesContext();
  const { getCachedLead, getCachedUser } = useLeadState();

  const { url, referrerRoute } = config.get('tosa.app.coreApi');
  const useCouponData: Parameters<typeof useCoupon>[0] = {
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config.get('features.defaultTrialDiscount'),
    },
  };

  const isDaisyModeEnabled =
    experiments[FeatureName.CVR_DAISY_MODE_ENABLED]?.treatment === 'on' ||
    !experiments[FeatureName.CVR_DAISY_MODE_ENABLED]?.isReady;

  if (isDaisyModeEnabled) {
    useCouponData.overrideDiscount = {
      discountAmount: 50,
      discountType: DiscountType.percentage,
    };
  }
  const { couponCode, discountAmount, discountType, referrerType } =
    useCoupon(useCouponData);

  const finalMeStep = [...formSteps].reverse().find(formStep => {
    return formStep.route === paths.PATH_SIGNUP_ME;
  });

  const finalPetsStep = [...formSteps].reverse().find(formStep => {
    return formStep.route === paths.PATH_SIGNUP_PETS;
  });

  const [finalStep] = formSteps.slice(-1);

  const handleFormCompleted: OnFormCompleted = step => {
    const lead = getCachedLead();
    const user = getCachedUser();

    if (step === finalMeStep?.name && onMeCompleted) {
      const globalDiscountOverride =
        experiments[FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE];
      const discountPercentage = globalDiscountOverride?.config
        ?.discountPercentage as number | null;
      const isGlobalDiscountOverrideOn =
        globalDiscountOverride?.treatment !== 'off' &&
        globalDiscountOverride?.treatment !== 'control';
      const discountOverride = isGlobalDiscountOverrideOn
        ? discountPercentage
        : null;

      const couponData = {
        couponCode,
        discountAmount,
        discountType,
        referrerType,
      };

      onMeCompleted(step, {
        lead,
        couponData,
        requireCorePostgresId: !isDaisyModeEnabled,
        discountOverride,
      });
    }

    const currentBranch = formSteps.find(formStep => formStep.name === step);
    const isPetStep = currentBranch?.route === paths.PATH_SIGNUP_PETS;
    if (isPetStep && onPetsCompleted) {
      const isLastPet = step === finalPetsStep?.name;

      if (isLastPet) {
        onPetsCompleted();
        // user object and humanId is needed if it is the last pet
        trackPetsSuccess({ user, humanId: lead?.humanId, requestAttributes });
      } else if (isContinueToNextPet({ formSteps, currentBranchName: step })) {
        onPetsCompleted();
        // user object is not needed if it is not the last pet
        trackPetsSuccess({ user: {}, requestAttributes });
      }
    }

    if (onStepCompleted) {
      onStepCompleted(step, lead);
    }

    if (step === finalStep.name && onTosaCompleted) {
      if (!user) {
        throw new Error('Unexpected error in TOSA registration');
      }

      onTosaCompleted({
        registeredUserResponse: user,
      });
    }
  };

  return handleFormCompleted;
}
