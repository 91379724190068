import { format, startOfWeek, parseISO } from 'date-fns';

/**
 * The week days number according to the date-fns week-date system.
 */
export enum WeekdayNumber {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export function formatDateToWeekOf(date: string): string {
  const day = startOfWeek(parseISO(date), {
    weekStartsOn: WeekdayNumber.Monday,
  });
  return `Week of ${format(day, 'MMMM d')}`;
}

export function formatDateToWeekOfLowerCase(date: string): string {
  const day = startOfWeek(parseISO(date), {
    weekStartsOn: WeekdayNumber.Monday,
  });
  return `week of ${format(day, 'MMMM d')}`;
}
