import { eventNames } from './eventNames';
import { track } from '@farmersdog/analytics';
import type { OrderSizeSubscriptionDetails } from '../features/order-sizes/OrderSizes';

type NextOrderToBeDelivered = {
  daysOfFood: number;
  earliestDesiredArrivalDate: string;
  latestDesiredArrivalDate: string;
};
export function trackOrderSizeUpNudgeUpdatedPlan(args: {
  oldSubscription: OrderSizeSubscriptionDetails;
  newSubscription: OrderSizeSubscriptionDetails;
  oldNextOrderToBeDelivered: NextOrderToBeDelivered;
  newNextOrderToBeDelivered: NextOrderToBeDelivered;
}) {
  track(eventNames.order_size_up_nudge_success_updated_plan, args);
}

export function trackOrderSizeUpNudgeKeepPlan() {
  track(eventNames.order_size_up_nudge_clicked_keep_plan);
}

export function trackOrderSizeUpNudgeDisplayedPlanOptions(args: {
  currentSubscription: OrderSizeSubscriptionDetails;
  suggestedSubscription: OrderSizeSubscriptionDetails;
}) {
  track(eventNames.order_size_up_nudge_displayed_plan_options, args);
}

export function trackOrderSizeUpNudgeSeePlanPage() {
  track(eventNames.order_size_up_nudge_clicked_see_plan_page);
}
