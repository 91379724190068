import { useMutation } from '../../services/apollo';

import recoverLead from './recoverLead.graphql';

import type {
  RecoverLeadMutationVariables,
  RecoverLeadMutation,
} from '../types';
import type * as Apollo from '@apollo/client';

type Options = Apollo.MutationHookOptions<
  RecoverLeadMutation,
  RecoverLeadMutationVariables
>;

export function useRecoverLead(options?: Options) {
  return useMutation<RecoverLeadMutation, RecoverLeadMutationVariables>(
    recoverLead,
    options
  );
}
