import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { showError } from 'src/actions/ui';
import useAsyncFunction from 'src/utils/useAsyncFunction';

import type { JobsByDepartment } from './sortJobsByDepartment';
import { sortJobsByDepartment } from './sortJobsByDepartment';

export interface Department {
  child_ids: string[];
  id: number;
  name: string;
  parent_id: null | number;
}

export interface Job {
  title: string;
  location: {
    name: string;
  };
  absolute_url: string;
  departments: Department[];
}

const GREENHOUSE_URL =
  'https://boards-api.greenhouse.io/v1/boards/thefarmersdog/jobs?content=true';

interface JobsData {
  data: {
    jobs: Job[];
  };
}

const defaultJobsByDepartment: JobsByDepartment = {};

export interface UseJobsReturn {
  loading: boolean;
  called: boolean;
  error?: Error;
  data?: JobsData;
  jobsByDepartment: JobsByDepartment;
}

export function useJobs(): UseJobsReturn {
  const dispatch = useDispatch();

  const [callGetJobs, jobsRequest] = useAsyncFunction(
    () => axios.get(GREENHOUSE_URL),
    {
      onError: (err: Error) => dispatch(showError(err)),
    }
  );

  useEffect(() => {
    if (jobsRequest.called) {
      return;
    }
    void callGetJobs();
  }, [jobsRequest.called, callGetJobs]);

  const jobsByDepartment = !jobsRequest.data
    ? defaultJobsByDepartment
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      sortJobsByDepartment(jobsRequest.data.data.jobs as Job[]);

  return {
    ...jobsRequest,
    jobsByDepartment,
  };
}
