import { PATH_PLAN, PATH_WEBSITE_HOMEPAGE } from '@farmersdog/constants/paths';
import { ActivityIndicator, Page } from '@farmersdog/corgi-x';
import { useHistory } from 'react-router';
import { reporter } from '../errors/services';
import {
  trackOrderSizeNudgeKeepPlan,
  trackOrderSizeNudgeSeePlanPage,
  trackOrderSizeNudgeUpdatedPlan,
} from './analytics/trackSuggestOrderSize';
import { Confirmation, OrderSizes } from './features';
import type { ValidAcceptFrequencyUpdateSubscriptionMutationResponse } from './network';
import { useSuggestOrderSizeData, useSuggestOrderSizeSubmit } from './network';
import {
  SuggestOrderSizeState,
  useSuggestOrderSizeMachine,
} from './state-machine';

export function SuggestOrderSize() {
  const MINIMUM_ELIGIBLE_ORDER_SIZE = 35;
  const EXPERIMENTAL_ORDER_SIZE = 28;
  const request = useSuggestOrderSizeData(EXPERIMENTAL_ORDER_SIZE);
  const machine = useSuggestOrderSizeMachine();

  const { callSubmit } = useSuggestOrderSizeSubmit({
    onCompleted: (
      response: ValidAcceptFrequencyUpdateSubscriptionMutationResponse
    ) => {
      machine.onAccept({
        daysOfFood: response.updateMySubscription.subscription.frequency,
        earliestDesiredArrivalDate:
          response.updateMySubscription.subscription.nextOrderToBeDelivered
            .earliestDesiredArrivalDate,
        latestDesiredArrivalDate:
          response.updateMySubscription.subscription.nextOrderToBeDelivered
            .latestDesiredArrivalDate,
      });
    },
  });
  const history = useHistory();
  const handleOnClose = () => {
    history.push(PATH_WEBSITE_HOMEPAGE);
  };
  const handleOnContinueToPlan = () => {
    history.push(PATH_PLAN);
  };

  if (request.loading) {
    return (
      <Page layout="base-centered">
        <ActivityIndicator mode="dark" />
      </Page>
    );
  }
  if (request.error) {
    reporter.error(new Error('Error loading SuggestOrderSize'), {
      sourceError: request.error,
    });

    return null;
  }
  if (!request.data) {
    reporter.error(
      new Error(
        'Unknown exception occurred while loading data in SuggestOrderSize'
      ),
      {
        sourceError: request.error,
      }
    );

    return null;
  }
  const handleOnReject = () => {
    trackOrderSizeNudgeKeepPlan();
    machine.onReject({
      daysOfFood: request.data?.customer.freshSubscription.frequency,
      earliestDesiredArrivalDate:
        request.data?.customer.nextOrderToBeDelivered.deliveryWindow
          .earliestDesiredArrivalDate,
      latestDesiredArrivalDate:
        request.data?.customer.nextOrderToBeDelivered.deliveryWindow
          .latestDesiredArrivalDate,
    });
  };
  const currentSubscription = {
    daysOfFood: request.data.customer.freshSubscription.frequency,
    dailyConsumptionPrice:
      request.data.customer.freshSubscription.lastQuotedPrice
        .dailyConsumptionPrice,
    orderTotal:
      request.data.customer.freshSubscription.lastQuotedPrice
        .regularOrderTotalConsumptionPrice,
  };
  const suggestedSubscription = {
    daysOfFood: EXPERIMENTAL_ORDER_SIZE,
    dailyConsumptionPrice:
      request.data.customer.suggestOrderSizeQuote.dailyConsumptionPrice,
    orderTotal:
      request.data.customer.suggestOrderSizeQuote
        .regularOrderTotalConsumptionPrice,
  };
  if (
    machine.isState(SuggestOrderSizeState.Close) ||
    currentSubscription.daysOfFood < MINIMUM_ELIGIBLE_ORDER_SIZE
  ) {
    handleOnClose();
  }
  if (machine.isState(SuggestOrderSizeState.ContinueToPlan)) {
    trackOrderSizeNudgeSeePlanPage();
    handleOnContinueToPlan();
  }
  if (
    machine.isState(SuggestOrderSizeState.Suggest) &&
    currentSubscription.daysOfFood >= MINIMUM_ELIGIBLE_ORDER_SIZE
  ) {
    return (
      <Page onCloseClick={machine.onClose}>
        <OrderSizes
          currentSubscription={currentSubscription}
          suggestedSubscription={suggestedSubscription}
          callSubmit={() =>
            callSubmit({
              input: {
                frequency: EXPERIMENTAL_ORDER_SIZE,
              },
            })
          }
          onReject={handleOnReject}
        />
      </Page>
    );
  }
  if (
    machine.context.daysOfFood &&
    machine.context.earliestDesiredArrivalDate &&
    machine.context.latestDesiredArrivalDate
  ) {
    if (machine.isState(SuggestOrderSizeState.AcceptConfirmation)) {
      trackOrderSizeNudgeUpdatedPlan({
        oldSubscription: currentSubscription,
        newSubscription: suggestedSubscription,
        oldNextOrderToBeDelivered: {
          daysOfFood: currentSubscription.daysOfFood,
          earliestDesiredArrivalDate:
            request.data.customer.nextOrderToBeDelivered.deliveryWindow
              .earliestDesiredArrivalDate,
          latestDesiredArrivalDate:
            request.data.customer.nextOrderToBeDelivered.deliveryWindow
              .latestDesiredArrivalDate,
        },
        newNextOrderToBeDelivered: {
          daysOfFood: machine.context.daysOfFood,
          earliestDesiredArrivalDate:
            machine.context.earliestDesiredArrivalDate,
          latestDesiredArrivalDate: machine.context.latestDesiredArrivalDate,
        },
      });
      {
        return (
          <Page onCloseClick={machine.onClose} layout="base-centered">
            <Confirmation
              daysOfFood={machine.context.daysOfFood}
              earliestDesiredArrivalDate={
                machine.context.earliestDesiredArrivalDate
              }
              latestDesiredArrivalDate={
                machine.context.latestDesiredArrivalDate
              }
              acceptedSuggestion={true}
              onContinueToPlan={machine.onContinueToPlan}
            />
          </Page>
        );
      }
    }
    if (machine.isState(SuggestOrderSizeState.RejectConfirmation)) {
      return (
        <Page onCloseClick={machine.onClose} layout="base-centered">
          <Confirmation
            daysOfFood={machine.context.daysOfFood}
            earliestDesiredArrivalDate={
              machine.context.earliestDesiredArrivalDate
            }
            latestDesiredArrivalDate={machine.context.latestDesiredArrivalDate}
            acceptedSuggestion={false}
            onContinueToPlan={machine.onContinueToPlan}
          />
        </Page>
      );
    }
  }

  return null;
}
