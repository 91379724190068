import { useRef, useState } from 'react';

import { AnimateHeight, ButtonBase, Text } from '@farmersdog/corgi-x';
import { Caret } from '@farmersdog/corgi-x/icons';

import styles from './Question.module.css';

import type { ReactNode } from 'react';

interface QuestionProps {
  title?: ReactNode;
  id?: string;
  children: ReactNode;
  itemProp?: string;
}

export const Question = ({ title, children, itemProp }: QuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const rowGroupRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <ButtonBase
        onClick={() => setIsOpen(prev => !prev)}
        aria-expanded={isOpen}
        className={styles.summary}
        aria-label="Toggle question dropdown"
      >
        <Caret
          size={32}
          aria-hidden
          baseColor="Kale3"
          className={isOpen ? styles.arrowDown : styles.arrowRight}
        />
        <Text
          as="h3"
          vSpacing="xs"
          color="charcoal-3"
          itemProp={itemProp}
          variant="article-16"
          className={styles.title}
        >
          {title}
        </Text>
      </ButtonBase>
      <AnimateHeight animateIn={isOpen} nodeRef={rowGroupRef}>
        <div ref={rowGroupRef} className={styles.content}>
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};
