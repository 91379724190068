import type { ChangeEvent } from 'react';
import { useEffect, useId, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { useToast } from '@farmersdog/corgi';
import {
  Button,
  Grid,
  GridItem,
  Text,
  ToggleCard,
  Wave,
  useHtmlElement,
  useIntersection,
  useViewport,
} from '@farmersdog/corgi-x';

import { freshFoodPictureSources } from '../../../assets';
import type { ChangeFreshRecipesPlanQuoteInput } from '../../../graphql/types.cgs';
import type { RecipeDetailsModalHandlerArgs } from '../../EditFreshRecipes';
import type { PetsFieldsFragment } from '../../network';
import styles from './SelectRecipes.module.css';
import { useSelectRecipesForm } from './useSelectRecipesForm';

export interface SelectRecipesProps {
  pet: PetsFieldsFragment;
  onClose: () => void;
  onContinue: (args: ChangeFreshRecipesPlanQuoteInput) => void;
  handleOpenRecipeDetailsModal: (props: RecipeDetailsModalHandlerArgs) => void;
}

const MAX_NUMBER_ALLOWED_RECIPES = 3;

export function SelectRecipes(props: SelectRecipesProps) {
  const { extraSmall, small, medium, large, extraLarge } = useViewport();
  const isSmallViewport =
    (extraSmall || small) && !large && !extraLarge && !medium;
  const [cancelButtonElement, cancelButtonElementRef] = useHtmlElement();
  const cancelButtonIntersection = useIntersection(cancelButtonElement);
  const [showStickyButton, setShowStickyButton] = useState(false);
  const dispatchToast = useToast();

  const headingId = useId();
  const form = useSelectRecipesForm(
    props.pet.foodRecipes,
    props.pet.availableFreshFoodProducts
  );

  function handleOnContinue() {
    const selectedRecipes = form.values
      .filter(recipe => recipe.selected === true)
      .map(selectedRecipe => ({
        id: selectedRecipe.id,
        displayName: selectedRecipe.displayName,
        name: selectedRecipe.name,
      }));

    void props.onContinue({ petId: props.pet.id, recipes: selectedRecipes });
  }

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    const targetValue = e.target.value;
    const foundProduct = form.values.find(
      val => String(val.id) === targetValue
    );
    const totalSelected = form.values.filter(
      val => val.selected === true
    ).length;

    if (!foundProduct) {
      throw new Error(
        'Customer somehow selected an invalid fresh food product'
      );
    }

    // Can only allow at max 3 products
    if (
      totalSelected >= MAX_NUMBER_ALLOWED_RECIPES &&
      foundProduct.selected === false
    ) {
      dispatchToast({
        variant: 'neutral',
        children:
          'You have 3 recipes selected. Please deselect a recipe before choosing another.',
      });

      return;
    }

    // Cannot remove all products
    if (totalSelected === 1 && foundProduct.selected === true) {
      return;
    }

    // Default behavior is to set new form state and change the selected field of the product
    return form.setValues(
      form.values.map(val => {
        const updated = { ...val };

        if (updated.id === foundProduct.id) {
          updated.selected = !updated.selected;
        }

        return updated;
      })
    );
  }

  useEffect(() => {
    if (!cancelButtonIntersection?.isIntersecting && isSmallViewport) {
      setShowStickyButton(true);
    } else {
      setShowStickyButton(false);
    }
  }, [
    cancelButtonIntersection?.isIntersecting,
    cancelButtonElementRef,
    setShowStickyButton,
    isSmallViewport,
  ]);

  return (
    <section aria-labelledby={headingId}>
      <Wave withBottom withTop className={styles.waveContainer}>
        <Grid flexDirection="column" alignItems="center">
          <GridItem justifyContent="center" sm={12} lg={12}>
            <Text
              variant="heading-40"
              color="kale-3"
              id={headingId}
              as="h2"
              bold
              className={styles.heading}
            >
              Select Recipes
            </Text>
          </GridItem>
          <GridItem justifyContent="center" sm={12} lg={12}>
            <Text variant="article-20" color="charcoal-3">
              Choose up to 3 Recipes
            </Text>
          </GridItem>
        </Grid>
        <Grid
          columnGap="md"
          rowGap="md"
          justifyContent="center"
          bottomSpacing="md"
          topSpacing="md"
          hSpacing="md"
          className={styles.recipesContainer}
        >
          {sortBy(form.values, 'displayName').map(value => (
            <GridItem
              key={value.id}
              lg={2}
              md={3}
              xs={6}
              alignItems="stretch"
              className={styles.gridItem}
            >
              <ToggleCard
                className={styles.toggleCard}
                heading={value.displayName}
                imageSources={freshFoodPictureSources[value.name]}
                imageAlt={`bowl of ${value.displayName} recipe food`}
                label={value.selected ? 'Added' : 'Add'}
                description={value.mainIngredients}
                buttonLabel={'See Details'}
                onButtonClick={() =>
                  props.handleOpenRecipeDetailsModal({
                    productName: value.name,
                    petName: props.pet.name,
                  })
                }
                checked={value.selected}
                onChange={handleOnChange}
                value={value.id}
                name={value.displayName}
                contentClassName={styles.selectRecipeContentContainer}
              />
            </GridItem>
          ))}
        </Grid>
      </Wave>
      <Grid flexDirection="column" alignItems="center" rowGap="lg">
        {!showStickyButton && (
          <GridItem justifyContent="center" sm={12} lg={12}>
            <Button
              type="button"
              onClick={handleOnContinue}
              className={styles.selectRecipesCta}
              disabled={!form.dirty}
            >
              Continue
            </Button>
          </GridItem>
        )}
        <div ref={cancelButtonElementRef}>
          <GridItem justifyContent="center" sm={12} lg={12}>
            <Button
              type="button"
              onClick={props.onClose}
              variant="plain-text"
              className={styles.selectRecipesCta}
            >
              Cancel
            </Button>
          </GridItem>
        </div>
      </Grid>
      {showStickyButton && (
        <Grid
          flexDirection="column"
          alignItems="center"
          rowGap="lg"
          className={styles.stickyCta}
        >
          <GridItem justifyContent="center" sm={12} lg={12}>
            <Button
              type="button"
              onClick={handleOnContinue}
              className={styles.selectRecipesCta}
              disabled={!form.dirty}
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      )}
    </section>
  );
}
