import type { GlobalLightboxState } from './context';
import type { LightboxId } from './lightboxIds';

/**
 * Return if there is an open global lightbox.
 *
 * @param  state - The current ui state object.
 *
 */
export function getIsAnotherLightboxOpen(state: GlobalLightboxState) {
  return Object.values(state.lightboxes).some(lightbox => lightbox.isOpen);
}

/**
 * Return a new state of with all lightboxes closed.
 *
 * @param  state - The UI State
 */
export function closeAllLightboxes(state: GlobalLightboxState) {
  const lightboxes = {} as GlobalLightboxState['lightboxes'];

  Object.entries(state.lightboxes).forEach(([id, lightboxState]) => {
    lightboxes[id] = {
      ...lightboxState,
      isOpen: false,
    };
  });

  return lightboxes;
}

/**
 * Return whether an individual lightbox is currently open or not
 *
 * @param state - the UI State
 * @param id - the id of the lightbox
 *
 **/
export function getIsLightboxCurrentlyOpen(
  state: GlobalLightboxState,
  id: LightboxId
) {
  return state.lightboxes[id]?.isOpen;
}

/** Return whether an individual lightbox is currently suppressed
 *
 * @param state - the UI State
 * @param id - the id of the lightbox
 *
 **/
export function getIsLightboxCurrentlySuppressed(
  state: GlobalLightboxState,
  id: LightboxId
) {
  return state.lightboxes[id]?.suppressed;
}

/**
 * Return a promise that resolves when certain amount of milliseconds has
 * elapsed. This is used so we can wait the same duration as our css animation.
 * Will also optionally call a callback function at the same point.
 *
 * TODO: See if we can listen to `transitionend` event in a better way.
 * http://reactcommunity.org/react-transition-group/transition#Transition-prop-addEndListener
 */
export const wait = (duration: number, callback?: () => void): Promise<void> =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
      if (callback) {
        callback();
      }
    }, duration);
  });
