import { useContext, useState, createContext } from 'react';

import type { ReactNode, Dispatch, SetStateAction } from 'react';

interface ContextProviderProps {
  children: ReactNode;
}

interface StaticDataFetchContextInterface {
  fetchAttempted: boolean;
  setFetchAttempted: Dispatch<SetStateAction<boolean>>;
}

const StaticDataFetchContext = createContext(
  {} as StaticDataFetchContextInterface
);

export const StaticDataFetchContextProvider = ({
  children,
}: ContextProviderProps) => {
  const [fetchAttempted, setFetchAttempted] = useState(false);

  return (
    <StaticDataFetchContext.Provider
      value={{ fetchAttempted, setFetchAttempted }}
    >
      {children}
    </StaticDataFetchContext.Provider>
  );
};

export function useStaticDataFetchContext() {
  return useContext(StaticDataFetchContext);
}
