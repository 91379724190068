import type { DiyRecipe } from '../types';
import { PrimaryProtein } from '../types';
import beefAndBeefLiverWebp from '../assets/beef-and-beef-liver.webp';

export const beefAndBeefLiver: DiyRecipe = {
  name: 'Beef and Beef Liver',
  primaryProtein: PrimaryProtein.Beef,
  ingredients: [
    {
      name: '90% lean ground beef',
      quantity: 1138,
      unit: 'g',
      alternativeQuantity: 2.5,
      alternativeUnit: 'lb',
    },
    {
      name: 'sweet potato, shredded or finely diced',
      quantity: 249,
      unit: 'g',
      alternativeQuantity: 1.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'broccoli, cut into 1/4 inch dice',
      quantity: 249,
      unit: 'g',
      alternativeQuantity: 2.75,
      alternativeUnit: 'cup',
    },
    {
      name: 'spinach, chopped',
      quantity: 174,
      unit: 'g',
      alternativeIngredients: [
        { name: 'fresh', quantity: 5.75, unit: 'cup' },
        { name: 'frozen', quantity: 1, unit: 'cup' },
      ],
    },
    {
      name: 'beef liver',
      quantity: 172,
      unit: 'g',
      alternativeQuantity: 0.5,
      alternativeUnit: 'lb',
    },
    {
      name: 'The Farmers Dog DIY Nutrient Mix',
      quantity: 1,
      unit: 'packet',
    },
    {
      name: 'shredded Parmesan cheese',
      quantity: 10,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'tablespoon',
    },
    {
      name: 'sunflower oil',
      quantity: 9,
      unit: 'g',
      alternativeQuantity: 2,
      alternativeUnit: 'teaspoon',
    },
    {
      name: 'salmon oil',
      quantity: 1,
      unit: 'g',
      alternativeQuantity: 0.25,
      alternativeUnit: 'teaspoon',
    },
  ],
  ingredientsNote:
    'Fresh or frozen vegetables may be used in these recipes. For best results, weigh by grams and use food safe thermometer.',
  ingredientSummary:
    'Ground Beef, Sweet Potato, Broccoli, Spinach, Beef Liver, DIY Nutrient Mix, Parmesan Cheese, Sunflower Oil, Salmon Oil',
  id: 'beef-and-beef-liver',
  yieldWeight: 4.5,
  cookTime: '45 mins',
  calories: 2430,
  utensils: [
    '5+ quart pot/skillet/dutch oven',
    'knife and cutting board or food processor (or buy pre-cut frozen)',
    'spatula',
    'kitchen scale (optional)',
    'food safe thermometer (optional)',
  ],
  image: beefAndBeefLiverWebp,
  instructions: [
    {
      name: 'Step 1',
      description: `Place pot/skillet/dutch oven over medium to medium-high heat on the stovetop. 
      Pour the sunflower oil in and spread it to evenly coat the bottom.`,
    },
    {
      name: 'Step 2',
      description: `Add the ground beef and chopped beef liver. Break apart and stir until the meat is no longer pink and is broken into small pieces.`,
    },
    {
      name: 'Step 3',
      description: `Add the sweet potato and broccoli. Cook until the vegetables are soft and easily break apart or are tender when pierced with a fork.`,
      note: `Be sure to check that all ingredients are soft and easily broken in order to avoid choking hazards. 
      Large chunks may require additional cooking time. Do not drain any liquid or fat that’s a result of the cooking process.`,
    },
    {
      name: 'Step 4',
      description: `Add the spinach, cook until wilted, and stir to evenly distribute throughout the mixture.`,
    },
    {
      name: 'Step 5',
      description: `Add the salmon oil and Parmesan cheese to the pot and stir to evenly distribute. Cook until cheese has melted. `,
    },
    {
      name: 'Step 6',
      description: `If using a food safe thermometer, check the temperature at several places throughout the pot to 
      verify that a food safe temperature of 165°F is reached.`,
      note: `In order to maintain safe, ready-to-eat meals it is important that all food reaches the USDA guidelines for safety.`,
    },
    {
      name: 'Step 7',
      description: `Remove the pot from the heat and sprinkle the {numberOfPackets} of The Farmer's Dog DIY Nutrient Mix evenly across the surface. 
      Stir to evenly distribute throughout the food until you can no longer see the nutrient mix.`,
    },
  ],
};
