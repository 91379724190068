import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { determineSkipGetStarted, FeatureName } from '../../utils';
import { blueprintTraverseApply } from '../utils';

import type { Experiments, UseUserExperimentsHookReturn } from '../../types';
import type { Blueprint, CompiledBlueprint } from '../types';

export interface FilterBlueprintForExperimentationArgs<
  B extends Blueprint | CompiledBlueprint,
> {
  blueprint: B;
  anonymousExperiments: Experiments;
  userExperiments: UseUserExperimentsHookReturn | undefined;
}

export function filterBlueprintForExperimentation<
  B extends Blueprint | CompiledBlueprint,
>({
  blueprint,
  anonymousExperiments,
  userExperiments,
}: FilterBlueprintForExperimentationArgs<B>): B {
  const { shouldSkipGetStarted } = determineSkipGetStarted({
    experiments: anonymousExperiments,
  });

  const treatsUiTreatment =
    userExperiments && userExperiments[FeatureName.CVR_TREATS_IN_CHECKOUT_UI];
  const shouldRemoveTreatsPage = treatsUiTreatment?.shouldRemoveTreatsPage;

  const componentsToRemove = [
    shouldSkipGetStarted && 'GetStarted',
    shouldRemoveTreatsPage && 'Treats',
  ].filter((c): c is string => Boolean(c));

  if (componentsToRemove.length === 0) {
    return blueprint;
  }

  const clonedBlueprint = cloneDeep(blueprint);

  componentsToRemove.map(componentName => {
    removeNodeFromBlueprintByName(clonedBlueprint, componentName);
  });

  // This is to make sure that if we haven't modified the blueprint, we return
  // the original blueprint so that it can `===` itself and thus be used as a
  // hook dependency safely.
  return isEqual(blueprint, clonedBlueprint) ? blueprint : clonedBlueprint;
}

function removeNodeFromBlueprintByName(
  blueprint: Blueprint | CompiledBlueprint,
  componentName: string
): void {
  blueprintTraverseApply(blueprint.root, (node, parent) => {
    if (node.name === componentName && parent) {
      const index = parent?.children.findIndex(childNode => {
        return childNode.name === node.name;
      });

      if (typeof index === 'number' && index > -1) {
        parent?.children.splice(index, 1);
      }
    }
  });
}
