import type { RichSelectorValue } from '@farmersdog/corgi-x';
import { RichSelector } from '@farmersdog/corgi-x';
import { AddDogContent } from '../AddDogContent';
import {
  eatingStyleResources,
  EatingStyle,
} from '../../../../../assets/eating-style';
import type { AddDogFormChangeHandler } from '../../hooks';

interface EatingStyleSceneProps {
  name: string;
  eatingStyle: string;
  onChange: AddDogFormChangeHandler;
  onCtaClick: () => void;
}

export const EatingStyleScene = ({
  onCtaClick,
  name,
  eatingStyle,
  onChange,
}: EatingStyleSceneProps) => {
  const handleChange = (val: string | RichSelectorValue) => {
    onChange('eatingStyle', val as string);
  };
  return (
    <AddDogContent
      heading={`What kind of eater is ${name}?`}
      onCtaClick={onCtaClick}
      sectionWidthConstraint="md"
      ctaDisabled={eatingStyle === ''}
    >
      <RichSelector
        name="Eating style"
        value={eatingStyle}
        onChange={handleChange}
        defaultImageIndex={1}
        descriptionAtBottom
        descriptionAtBottomColor="charcoal-2"
        options={[
          {
            label: 'Very Picky',
            description: '',
            imageAlt: 'Picture of a dog who is very picky',
            image: {
              small: eatingStyleResources[EatingStyle.VeryPicky],
              large: eatingStyleResources[EatingStyle.VeryPicky],
            },
            value: 'Very Picky',
          },
          {
            label: 'Can be picky',
            description: '',
            imageAlt: 'Picture of a dog who is can be picky',
            image: {
              small: eatingStyleResources[EatingStyle.CanBePicky],
              large: eatingStyleResources[EatingStyle.CanBePicky],
            },
            value: 'Can Be Picky',
          },
          {
            label: 'Good Eater',
            description: '',
            imageAlt: 'Picture of a dog who is good eater',
            image: {
              small: eatingStyleResources[EatingStyle.GoodEater],
              large: eatingStyleResources[EatingStyle.GoodEater],
            },
            value: 'Good Eater',
          },
          {
            label: 'Will eat anything',
            description: '',
            imageAlt: 'Picture of a dog who is will eat anything',
            image: {
              small: eatingStyleResources[EatingStyle.WillEatAnything],
              large: eatingStyleResources[EatingStyle.WillEatAnything],
            },
            value: 'Will Eat Anything',
          },
        ]}
      />
    </AddDogContent>
  );
};
