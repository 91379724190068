import { useMutation } from '@apollo/client';
import type { BillNowInput } from '../../graphql/types.cgs';
import type {
  BillNowMutation,
  BillNowMutationVariables,
} from './BillNow.cgs.generated-types';

import billNowMutation from './BillNow.cgs.graphql';
import PaymentRetryOrderDataQuery from './PaymentRetryOrderData.cgs.graphql';

type UseMutationProps = NonNullable<
  Parameters<typeof useMutation<BillNowMutation, BillNowMutationVariables>>[1]
>;

export interface SubmitParams {
  orderId: BillNowInput['orderId'];
}

export type SubmitBillNowMutation = (params: SubmitParams) => void;
export interface UseBillNowMutation {
  submit: SubmitBillNowMutation;
  isSubmitting: boolean;
}

export function useBillNowMutation(
  props: Pick<UseMutationProps, 'onCompleted' | 'onError'>
): UseBillNowMutation {
  const [callBillNow, state] = useMutation<
    BillNowMutation,
    BillNowMutationVariables
  >(billNowMutation, {
    refetchQueries: [
      {
        query: PaymentRetryOrderDataQuery,
        context: {
          endpoint: 'cgs',
        },
      },
    ],
    context: {
      endpoint: 'cgs',
    },
    onCompleted: props.onCompleted,
    onError: props.onError,
  });

  const submit: SubmitBillNowMutation = ({ orderId }) => {
    void callBillNow({
      variables: {
        input: {
          orderId,
        },
      },
    });
  };

  return {
    submit,
    isSubmitting: state.loading,
  };
}
