import { useLazyQuery } from '@apollo/client';
import findClinicQuery from './FindClinic.cgs.graphql';
import type {
  FindClinicQuery,
  FindClinicQueryVariables,
} from './FindClinic.cgs.generated-types';

export function useFindClinic() {
  const [findClinic, { loading }] = useLazyQuery<
    FindClinicQuery,
    FindClinicQueryVariables
  >(findClinicQuery, {
    context: { endpoint: 'cgs' },
    fetchPolicy: 'cache-first',
  });

  return {
    findClinic,
    loading,
  };
}
