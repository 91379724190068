import {
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_TREATS,
  PATH_SIGNUP_CHECKOUT,
} from '@farmersdog/constants/paths';

import { QueryParameter } from '@farmersdog/constants';

import { SubscriptionType } from 'src/graphql/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const allowedPaths = [
  PATH_SIGNUP,
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
  PATH_SIGNUP_TREATS,
  PATH_SIGNUP_CHECKOUT,
] as const;

type AllowedPaths = (typeof allowedPaths)[number];

export interface GetLinkToSignupFlowArgs {
  path: AllowedPaths;
  flow: SubscriptionType;
}

function getLinkToSignupFlow({ path, flow }: GetLinkToSignupFlowArgs) {
  return `${path}?${String(QueryParameter.ProductLine)}=${flow}`;
}

export function getLinkToFreshSignup(path: AllowedPaths) {
  return getLinkToSignupFlow({ path, flow: SubscriptionType.Fresh });
}

export function getLinkToDiySignup(path: AllowedPaths) {
  return getLinkToSignupFlow({ path, flow: SubscriptionType.Diy });
}
