import { localStorageSessionData } from './localStorageSessionData';
import { cookieStorageSessionData } from './cookieStorageSessionData';
import type { SessionData } from '../utils';

export class SessionDataStorage {
  public read() {
    return cookieStorageSessionData.get();
  }

  public write(sessionData: SessionData) {
    localStorageSessionData.set(sessionData);
    cookieStorageSessionData.set(sessionData);
  }
}
