import type { ReactElement } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectRequestAttributes } from 'src/reducers/referrer';

import { trackHomepageSuccess } from 'src/analytics';

type CtaTrackerProps = {
  children: ReactElement<{ onClick: (event: MouseEvent) => void }>;
  location: string;
  ctaCopy: string;
};

export const CtaTracker = ({
  children,
  location,
  ctaCopy,
}: CtaTrackerProps) => {
  const requestAttributes = useSelector(selectRequestAttributes);

  const onClickHandler = () => {
    trackHomepageSuccess({ requestAttributes, location, ctaCopy });
  };

  return React.cloneElement(children, {
    onClick: (event: MouseEvent) => {
      onClickHandler();
      if (children.props.onClick) {
        children.props.onClick(event);
      }
    },
  });
};
