import { useLocation } from 'react-router-dom';

import { useAttributes } from './useAttributes';
import { useSplitContext } from './useSplitContext';
import { useSplitClient } from './useSplitClient';

import { getTreatmentWithConfig } from '../utils';
import type { FeatureMap, GetTreatmentType, GetConfigType } from '../types';

export interface UseFeatureOptions {
  /** Optional feature attributes to pass to split */
  attributes?: SplitIO.Attributes;
  /** When true useFeature will not request treatment data from split until
   * getFeatureData is called. */
  lazy?: boolean;
}

interface UseFeatureReturn<
  T extends object,
  U,
  K extends keyof FeatureMap<T & U>,
> {
  isReady: boolean;
  /** The treatment returned from split or `control` if not ready. */
  treatment: GetTreatmentType<T & U, K>;
  /** The config object from split or `{}` if not ready. */
  config: GetConfigType<T & U, K>;
}

/**
 * A hook to wrap the Split.IO
 * [useClient](https://help.split.io/hc/en-us/articles/360038825091-React-SDK#advanced-instantiate-multiple-sdk-clients)
 * hook. The purpose is to extend useClient to support website patterns.
 *
 * - Connect the feature name with the correct segment id
 * - Replace Split's callback/promise implementation with react state values
 * - Add query string support
 *
 * @param name - The name of the feature to request a treatment for
 * @param options - Options to modify the behavior
 */
export const useFeature = <
  T extends object,
  U extends object,
  K extends keyof FeatureMap<T & U>,
>(
  treatmentName: K,
  options: UseFeatureOptions = {}
): UseFeatureReturn<T, U, K> => {
  const location = useLocation();
  const contextValue = useSplitContext<T, U>();
  const { userId, anonymousId, userFeatures, allowOverride } = contextValue;
  const { attributes, attributesReady } = useAttributes(options.attributes);
  // @ts-expect-error no need to discriminate the union here
  const isUserFeature = Boolean(userFeatures[treatmentName]);
  const splitKey = String(isUserFeature ? userId : anonymousId);
  const { isReady, splitClient } = useSplitClient(splitKey);

  const { treatment, config } = getTreatmentWithConfig<T & U, K>({
    treatmentName,
    isReady,
    splitClient,
    attributes,
    attributesReady,
    queryString: location.search,
    allowOverride,
  });

  return {
    treatment,
    config,
    isReady,
  };
};
