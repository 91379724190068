import type { LazyQueryResultTuple } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import type {
  FetchChangeFreshRecipesQuoteQuery,
  FetchChangeFreshRecipesQuoteQueryVariables,
} from './lazyFetchChangeFreshRecipesQuote.cgs.generated-types';
import fetchChangeFreshRecipesQuote from './lazyFetchChangeFreshRecipesQuote.cgs.graphql';

interface UseLazyFetchEditRecipesConfirmationDataProps {
  onCompleted: () => void;
  onError?: () => void;
}

export type UseLazyFetchChangeFreshRecipesQuoteProps = LazyQueryResultTuple<
  FetchChangeFreshRecipesQuoteQuery,
  FetchChangeFreshRecipesQuoteQueryVariables
>;

export function useLazyFetchChangeFreshRecipesQuote(
  props: UseLazyFetchEditRecipesConfirmationDataProps
): UseLazyFetchChangeFreshRecipesQuoteProps {
  return useLazyQuery<
    FetchChangeFreshRecipesQuoteQuery,
    FetchChangeFreshRecipesQuoteQueryVariables
  >(fetchChangeFreshRecipesQuote, {
    context: {
      endpoint: 'cgs',
    },
    errorPolicy: 'all',
    onCompleted: props.onCompleted,
    onError: props.onError,
  });
}
